/* eslint-disable import/no-cycle */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import {Person} from '@material-ui/icons';

import authUserProps from '../../propTypes/authUserProps';
import customerProps from '../../propTypes/customerProps';

import SearchCustomer from './components/SearchCustomer';
import EditCustomer from './components/EditCustomer';
import SelectCustomer from './components/SelectCustomer';
import CreateCustomer from './components/CreateCustomer';
import AlertChangeAddress from './components/modal/AlertChangeAddress';
import CustomerActions from './components/CustomerActions';
import MenuBase from '../rkr2-components/Menu/MenuBase';
import CartCustomerMenu from '../rkr2-components/cart/CartCustomerMenu';

const Customer = ({
  authUser,
  cart,
  customer,
  dispatchAddAddressByCustomer,
  dispatchCreateCustomer,
  dispatchDeleteAddressByCustomer,
  dispatchEditAddressByCustomer,
  dispatchEditCustomer,
  dispatchEditEnvioFactura,
  dispatchEditOrder,
  dispatchFetchAllCustomers,
  dispatchFilterCustomers,
  dispatchGetAddressCustomer,
  dispatchOpenModal,
  dispatchRemoveCustomer,
  dispatchSelectCustomer,
  dispatchSetDefaultCustomer,
  dispatchSelectAddres,
  dispatchToggleModal,
  loads,
  modals,
}) => {
  useEffect(() => {
    const defaultCustomer = authUser.user.account_default;
    dispatchSetDefaultCustomer(defaultCustomer);

    const { selectedCustomer } = customer;
    if (selectedCustomer.accountname === undefined) {
      dispatchRemoveCustomer();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const { selectedCustomer } = customer;
  const { orderSelected } = cart;
  return (
    <div className="customer">
      <div className="w-100 d-flex justify-content-end align-items-center p1">
        <div style={{ minWidth: '70%', width: '100%', marginLeft: 10 }}>
          <SearchCustomer orderSelected={orderSelected} dispatchEditOrder={dispatchEditOrder} />
        </div>
        {/* <CustomerActions
          cart={cart}
          customer={customer}
          dispatchOpenModal={dispatchOpenModal}
          dispatchRemoveCustomer={dispatchRemoveCustomer}
          dispatchSelectCustomer={dispatchSelectCustomer}
          dispatchToggleModal={dispatchToggleModal}
        /> */}
        <MenuBase menuIcon={<Person />} styles={{ top: 52, left: '-8px' }}>
          <CartCustomerMenu />
        </MenuBase>
      </div>
      <SelectCustomer
        customer={customer}
        dispatchEditOrder={dispatchEditOrder}
        dispatchFetchAllCustomers={dispatchFetchAllCustomers}
        dispatchFilterCustomers={dispatchFilterCustomers}
        dispatchSelectCustomer={dispatchSelectCustomer}
        dispatchToggleModal={dispatchToggleModal}
        loads={loads}
        modals={modals}
        orderSelected={orderSelected}
      />
      <CreateCustomer
        loads={loads}
        modals={modals}
        customer={customer}
        dispatchToggleModal={dispatchToggleModal}
        dispatchCreateCustomer={dispatchCreateCustomer}
      />
      <EditCustomer
        title={`Editar al cliente: ${selectedCustomer.accountname}`}
        loads={loads}
        modals={modals}
        address={customer.address || []}
        selectedCustomer={selectedCustomer}
        dispatchToggleModal={dispatchToggleModal}
        dispatchEditCustomer={dispatchEditCustomer}
        dispatchGetAddressCustomer={dispatchGetAddressCustomer}
        dispatchEditAddressByCustomer={dispatchEditAddressByCustomer}
        dispatchAddAddressByCustomer={dispatchAddAddressByCustomer}
        dispatchEditEnvioFactura={dispatchEditEnvioFactura}
        deleteAddress={dispatchDeleteAddressByCustomer}
      />
      <AlertChangeAddress
        open={Boolean(modals.alertAddressCustomerModalIsOpen)}
        handleClose={() => dispatchToggleModal('alertAddressCustomer')}
        customer={customer}
        setAddress={(facturaId, envioId) => {
          dispatchSelectAddres(facturaId, envioId);
          dispatchToggleModal('alertAddressCustomer');
        }}
      />
    </div>
  );
};

Customer.propTypes = {
  authUser: authUserProps.isRequired,
  cart: PropTypes.object.isRequired,
  customer: customerProps.isRequired,
  dispatchAddAddressByCustomer: PropTypes.func.isRequired,
  dispatchCreateCustomer: PropTypes.func.isRequired,
  dispatchDeleteAddressByCustomer: PropTypes.func.isRequired,
  dispatchEditAddressByCustomer: PropTypes.func.isRequired,
  dispatchEditCustomer: PropTypes.func.isRequired,
  dispatchEditEnvioFactura: PropTypes.func.isRequired,
  dispatchEditOrder: PropTypes.func.isRequired,
  dispatchFetchAllCustomers: PropTypes.func.isRequired,
  dispatchFilterCustomers: PropTypes.func.isRequired,
  dispatchGetAddressCustomer: PropTypes.func.isRequired,
  dispatchOpenModal: PropTypes.func.isRequired,
  dispatchRemoveCustomer: PropTypes.func.isRequired,
  dispatchSelectCustomer: PropTypes.func.isRequired,
  dispatchSetDefaultCustomer: PropTypes.func.isRequired,
  dispatchToggleModal: PropTypes.func.isRequired,
  dispatchSelectAddres: PropTypes.func.isRequired,
  loads: PropTypes.object.isRequired,
  modals: PropTypes.object.isRequired,
};

export default Customer;
