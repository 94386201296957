import React from 'react';
import PropTypes from 'prop-types';
/* Reatcstrap */
import {
  Modal,
  ModalHeader,
  ModalBody,
} from 'reactstrap';
import {
  LinearProgress,
  Collapse,
} from '@material-ui/core';
/* Components */
import ListAddress from '../ListAddress';

const CustomerModal = ({
  addAddress,
  cart,
  customer,
  getAddress,
  isOpen,
  loading,
  setAddress,
  title,
  toggle,
}) => {
  React.useEffect(() => {
    if (isOpen) {
      getAddress(customer.selectedCustomer.crmid);
    }
    // eslint-disable-next-line
  }, [isOpen]);
  const orderSelect = Boolean(Object.keys(cart.orderSelected).length > 0);
  const isCustomer = Boolean(Object.keys(customer.selectedCustomer).length > 0);
  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      sizer="lg"
    >
      <ModalHeader toggle={toggle}>{title}</ModalHeader>
      <ModalBody>
        <Collapse in={loading}>
          <LinearProgress />
        </Collapse>
        <Collapse in={!loading && orderSelect} unmountOnExit>
          <ListAddress
            addAddress={addAddress}
            address={customer.address}
            cart={cart}
            customer={isCustomer ? customer.selectedCustomer : {}}
            setAddress={setAddress}
          />
        </Collapse>
      </ModalBody>
    </Modal>
  );
};

CustomerModal.propTypes = {
  addAddress: PropTypes.func.isRequired,
  cart: PropTypes.object.isRequired,
  customer: PropTypes.object.isRequired,
  getAddress: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  setAddress: PropTypes.func.isRequired,
  title: PropTypes.string,
  toggle: PropTypes.func.isRequired,
};

CustomerModal.defaultProps = {
  title: 'Direcciones',
};

export default CustomerModal;
