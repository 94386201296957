/* eslint-disable no-nested-ternary */
import { userHelper, pricebookHelper } from '../helpers';

class ConfigurationAPI {
  constructor() {
    const authUser = userHelper.getStorage();
    const pricebook = pricebookHelper.getStorage();
    const existPricebook = Boolean(Object.keys(pricebook).length > 0);
    this.pricebook = authUser.isAuth && existPricebook ? pricebook : '';
    this.store = authUser.isAuth ? authUser.user.config.store : '';
    this.token = authUser.isAuth ? authUser.token : '';
    this.domain = authUser.isAuth ? authUser.domain : '';

    const currencyId = window.localStorage.getItem('currencyId') !== 'undefined' ? window.localStorage.getItem('currencyId') : (this.store.pos?.pos_currency_id ? this.store.pos?.pos_currency_id : 1);

    this.protocol = 'https';
    this.module = 'comercia_ws';
    this.credentials = {
      method: 'GET',
      credentials: 'include',
      mode: 'cors',
      headers: {
        Authorization: `Basic ${this.token}`,
        almacen: this.store.crmid,
        pricebook: this.pricebook.crmid,
        'currency-id': currencyId || 1,
      },
    };

    this.url = `${this.protocol}://${this.domain}/${this.module}`;
    this.mainUrl = `${this.protocol}://${this.domain}`;
  }
}

export default ConfigurationAPI;
