/* eslint-disable no-prototype-builtins */
import React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFileSignature,
  faDolly,
  faFolderOpen,
  faHandHoldingUsd,
  faMapMarkerAlt,
  faPenSquare,
  faTruck,
  faComment
} from '@fortawesome/free-solid-svg-icons';
import { Swipeable } from 'react-swipeable';
import { useSelector } from 'react-redux';

const SwipeOrderOptions = (props) => {
  const {
    activeTab, handleChange, onSwipeAction, load,
  } = props;
  const cart = useSelector(state => state.cart);
  const { orderSelected } = cart;
  const isQuote = Boolean(orderSelected.order && orderSelected.order.hasOwnProperty('quote_no')) || false;
  return (
    <>
      <div className="w-100">
        <Swipeable
          className="d-flex flex-column f-1"
          onSwipedLeft={load ? null : eventData => onSwipeAction(eventData)}
          onSwipedRight={load ? null : eventData => onSwipeAction(eventData)}
          style={{ borderBottom: '1px solid #efeff4' }}
        >
          <div>
            <Tabs
              value={activeTab}
              onChange={handleChange}
              variant="fullWidth"
              indicatorColor="primary"
              textColor="primary"
              scrollButtons="on"
              TabIndicatorProps={{
                style: { background: '#313C52', height: 3, borderBottom: '1px solid #e0e0e0' },
              }}
            >
              <Tab
                style={{ height: 60 }}
                className="tab"
                icon={(
                  <FontAwesomeIcon
                    style={{ fontSize: 15 }}
                    color={`${activeTab === 0 ? '#313C52' : '#B6BEC6'}`}
                    icon={faFolderOpen}
                  />
                )}
                disabled={!!load}
              />
              <Tab
                style={{ height: 60, display: isQuote ? 'none' : 'flex' }}
                className="tab"
                icon={(
                  <FontAwesomeIcon
                    style={{ fontSize: 15 }}
                    color={`${activeTab === 1 ? '#313C52' : '#B6BEC6'}`}
                    icon={faMapMarkerAlt}
                  />
                )}
                disabled={!!load}
              />
              <Tab
                style={{ height: 60, display: isQuote ? 'none' : 'flex' }}
                className="tab"
                icon={(
                  <FontAwesomeIcon
                    style={{ fontSize: 15 }}
                    color={`${activeTab === 2 ? '#313C52' : '#B6BEC6'}`}
                    icon={faFileSignature}
                  />
                )}
                disabled={!!load}
              />
              <Tab
                style={{ height: 60, display: isQuote ? 'none' : 'flex' }}
                className="tab"
                icon={(
                  <FontAwesomeIcon
                    style={{ fontSize: 15 }}
                    color={`${activeTab === 3 ? '#313C52' : '#B6BEC6'}`}
                    icon={faHandHoldingUsd}
                  />
                )}
                disabled={!!load}
              />
              <Tab
                style={{ height: 60, display: isQuote ? 'none' : 'flex' }}
                className="tab"
                icon={(
                  <FontAwesomeIcon
                    style={{ fontSize: 15 }}
                    color={`${activeTab === 4 ? '#313C52' : '#B6BEC6'}`}
                    icon={faDolly}
                  />
                )}
                disabled={!!load}
              />
              <Tab
                style={{ height: 60, display: isQuote ? 'none' : 'flex' }}
                className="tab"
                icon={(
                  <FontAwesomeIcon
                    style={{ fontSize: 15 }}
                    color={`${activeTab === 5 ? '#313C52' : '#B6BEC6'}`}
                    icon={faTruck}
                  />
                )}
                disabled={!!load}
              />
              <Tab
                style={{ height: 60, display: isQuote ? 'none' : 'flex' }}
                className="tab"
                icon={(
                  <FontAwesomeIcon
                    style={{ fontSize: 15 }}
                    color={`${activeTab === 6 ? '#313C52' : '#B6BEC6'}`}
                    icon={faPenSquare}
                  />
                )}
                disabled={!!load}
              />
              <Tab
                style={{ height: 60, display: isQuote ? 'none' : 'flex' }}
                className="tab"
                icon={(
                  <FontAwesomeIcon
                    style={{ fontSize: 15 }}
                    color={`${activeTab === 7 ? '#313C52' : '#B6BEC6'}`}
                    icon={faComment}
                  />
                )}
                disabled={!!load}
              />
            </Tabs>
          </div>
        </Swipeable>
      </div>
    </>
  );
};

SwipeOrderOptions.defaultProps = {};
SwipeOrderOptions.propTypes = {
  activeTab: PropTypes.number.isRequired,
  handleChange: PropTypes.func.isRequired,
  onSwipeAction: PropTypes.func.isRequired,
  load: PropTypes.bool.isRequired,
};
export default SwipeOrderOptions;
