/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import {
  Button, Collapse, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress,
} from '@material-ui/core';
import React, { useRef, useState } from 'react';
import { toast } from 'react-toastify';
import ProductAPI from '../../../../../api/ProductAPI';

const AddBarCode = ({ item, updateBarCode }) => {
  const productApi = new ProductAPI();
  const inputRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const addBarCode = async () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const sendBarCode = async () => {
    setIsLoading(true);
    const response = await productApi.addProductBarCode(item.id || item.productid, inputRef.current.value);
    const { success } = response;
    if (success) {
      // Update product in the list
      updateBarCode(item, inputRef.current.value);
      setOpen(false);
      inputRef.current.value = '';
      toast.success('Código de barras agregado');
    } else {
      toast.error('No se pudo agregar el código de barra');
    }
    setIsLoading(false);
  };
  return (
    <>
      <div className="d-flex flex-column justify-content-center align-items-center mb-2">
        <div>
          <span>Código de barra</span>
        </div>
        <input
          className="scanInput"
          type="text"
          readOnly
          value={item.codigo_barras}
        />
        {!item.codigo_barras && (
        <button
          onClick={() => addBarCode()}
          style={{
            border: '1px solid gray',
            borderRadius: 10,
            width: '100%',
            marginTop: 2,
          }}
          type="button"
        >
          Adicionar código
        </button>
        )}
      </div>
      <Dialog open={open} onClose={handleClose} aria-labelledby="simple-dialog-title">
        <DialogTitle id="simple-dialog-title">Adicionar código de barra</DialogTitle>
        <DialogContent>
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            gap: 15,
          }}
          >
            <div>
              <p style={{ fontSize: 15 }}>
                Producto:
              </p>
              <p style={{ fontSize: 14 }}>
                {item?.producto || item.productname}
              </p>
            </div>
            <div>
              <p style={{ fontSize: 15 }}>Código barra:</p>
              <div style={{
                border: '1px solid #d8d8d8',
                borderRadius: 5,
                display: 'flex',
                overflow: 'hidden',
              }}
              >
                <input
                  style={{
                    width: '100%',
                    height: 36,
                    border: 0,
                    paddingLeft: 10,
                    outline: 'none',
                  }}
                  disabled={isLoading}
                  ref={inputRef}
                  type="text"
                  placeholder="Agrega el código de barra"
                />
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          {
            isLoading ? (
              <Collapse in style={{ width: '100%' }}>
                <LinearProgress />
              </Collapse>
            ) : (
              <>
                <Button onClick={handleClose}>
                  Cancelar
                </Button>
                <Button onClick={sendBarCode} color="primary">
                  Agregar
                </Button>
              </>
            )
          }
        </DialogActions>
      </Dialog>
    </>
  ); };

export default AddBarCode;
