/* eslint-disable import/no-cycle */
/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Button, Collapse, IconButton, LinearProgress, List, ListItem, ListItemText, Menu, MenuItem,
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { Edit, MoreVert, Share } from '@material-ui/icons';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import SatLogo from '../../../../Icons/SatLogo';
import { toolsHelper } from '../../../../../helpers';
import VerificarCobro from '../timbrar/VerificarCobro';
import Sharemenu from '../timbrar/Sharemenu';
import EditFde from '../EditFde';


const FdeList = ({ fde, isLoading, order }) => {
  const disableMenu = !(order?.estadosat !== 'Cancelado' && order?.metodopago === 'PPD' && fde?.xmlpago === undefined);
  const config = useSelector(state => state.authUser.user.config);
  const { pos_no_facturar } = config;
  const canMakeInvoice = pos_no_facturar === '1' ? true : disableMenu;

  const [openVerification, setOpenVerification] = React.useState(false);
  const [openShare, setOpenShare] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [selectedFde, setSelectedFde] = React.useState(null);
  let ids = '';

  const handleClickOpen = () => {
    setOpenVerification(true);
  };
  const handleClickOpenShare = () => {
    setOpenShare(true);
  };

  const handleCloseVerificacion = () => {
    setOpenVerification(false);
  };
  const handleCloseShare = () => {
    setOpenShare(false);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [fdeSelected, setFdeSelected] = React.useState(null);
  const [fdeSelectedObj, setFdeSelectedObj] = React.useState(null);
  const [optionSelected, setOptionSelected] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event, item) => {
    event.stopPropagation();
    setFdeSelected(item.crmid);
    setSelectedFde(item);
    setFdeSelectedObj(item);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (option) => {
    setOptionSelected(option);
    setAnchorEl(null);
    if (option === 'Timbrar') {
      handleClickOpen();
    }
    if (option === 'Compartir') {
      handleClickOpenShare();
    }
  };

  const closeAll = () => {
    handleCloseVerificacion();
    handleCloseShare();
    /* dispatch(closeModal('fde')); */
    /* onClose(); */
  };

  const timbrarTodo = () => {
    if (fde.length) {
      // eslint-disable-next-line array-callback-return
      fde.map((f, idx) => {
        if (idx === fde.length - 1) {
          ids = `${ids + f.crmid}`;
        } else {
          ids = `${ids + f.crmid}|`;
        }
      });
      setFdeSelected(ids);
    }
    handleClickOpen();
  };

  const handleEdit = async (flujo) => {
    //await setSelectedFde(flujo);
    await setOpenEdit(true);
    await setAnchorEl(null);
  };

  const updatedFde = (newFde) => {
    const index = fde.findIndex(f => f.crmid === newFde.crmid);
    if (index !== -1) {
      fde.splice(index, 1, newFde);
    } else {
      toast.error('No se encontro el flujo que intenta editar');
    }
  };
  return (
    <div className="w-100 h-auto">
      <Collapse in={isLoading}>
        <LinearProgress />
      </Collapse>
      <Collapse in={!isLoading} className="d-flex flex-column">
        {fde.length > 0 ? (
          <Button size="small" color="primary" className="align-self-end" onClick={() => timbrarTodo()} disabled={canMakeInvoice}>
            Timbrar todos
          </Button>
        ) : null}
        {fde.length > 0 ? (
          <List className="fde_list">
            {fde.map((x, index )=> (
              <ListItem key={`key_${x.crmid}`}>
                <div className="w-100 h-100 d-flex justify-content-between align-items-center">
                  <div
                    className={
                      `fde_list_icon ${
                        x.tfde_tipo === 'Ingreso' ? (
                          'fde_list_icon_in'
                        ) : (
                          'fde_list_icon_out'
                        )
                      }`
                    }
                  >
                    {x.tfde_tipo === 'Ingreso' ? (
                      <FontAwesomeIcon icon="sign-out-alt" />
                    ) : (
                      <FontAwesomeIcon
                        icon="sign-in-alt"
                        style={{
                          transform: 'rotate(180deg)',
                        }}
                      />
                    )}
                  </div>
                  <div className="d-flex flex-column justify-content-between mr-auto ml-2">
                    <span className="fde_list_meth">{x.tfde_metodo === 'LBL_PUNTOS_RECOMPENSA' ? 'Puntos' : x.tfde_metodo}</span>
                    <span className="fde_list_acco">{x.cuenta}</span>
                  </div>
                  <div className="d-flex flex-column justify-content-between">
                    <span className="fde_list_mont">
                      {`$ ${toolsHelper.formatMoney(
                        x.monto,
                      )}`}
                    </span>
                    <span className="fde_list_date">
                      {`${x.createdtime} - ${moment(
                        x.createdtime,
                      ).fromNow()}`}
                    </span>
                  </div>
                  <div>
                    <IconButton edge="end" aria-label="comments" onClick={event => handleClick(event, x)}>
                      <MoreVert />
                    </IconButton>
                    <Menu
                      id={`menu_${index}`}
                      className="horiz-menu"
                      anchorEl={anchorEl}
                      keepMounted
                      open={open}
                      onClose={() => handleClose()}
                    >
                      <MenuItem onClick={() => handleClose('Compartir')}>
                        <Share fill="#65707a" />
                        Compartir
                      </MenuItem>
                      <MenuItem disabled={canMakeInvoice} onClick={() => handleClose('Timbrar')}>
                        <SatLogo fill="#65707a" />
                        Timbrar
                      </MenuItem>
                      <MenuItem onClick={() => handleEdit(x)}>
                        <Edit fill="#65707a" />
                        Editar
                      </MenuItem>
                    </Menu>
                  </div>
                </div>
              </ListItem>
            ))}
          </List>
        ) : (
          <List dense>
            <ListItem>
              <ListItemText
                primary="Sin flujos de efectivo"
                secondary="La orden no cuenta con movimientos de efectivo"
              />
            </ListItem>
          </List>
        )}
      </Collapse>
      <VerificarCobro open={openVerification} handleClose={handleCloseVerificacion} typo={optionSelected} fde={fdeSelected} closeAll={closeAll} />
      <Sharemenu open={openShare} handleClose={handleCloseShare} typo={optionSelected} fde={fdeSelectedObj} />
      <EditFde isOpen={openEdit} handleClose={() => setOpenEdit(false)} fde={selectedFde} updatedFde={updatedFde} />
    </div>
  );
};


FdeList.propTypes = {
  fde: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  order: PropTypes.object.isRequired,
};

export default FdeList;
