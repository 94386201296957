import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  Tooltip,
  IconButton,
} from '@material-ui/core';
import { Receipt } from '@material-ui/icons';

const TicketsActions = ({
  open,
  enable,
  tooltip,
  disableTooltip,
}) => {
  const loads = useSelector(state => state.loads);
  return (
    <Tooltip
      title={enable ? tooltip : disableTooltip}
      placement="bottom"
    >
      <span>
        <IconButton
          disabled={!enable || loads.orderIsLoading}
          onClick={open}
          className="pos_menu_setDir"
        >
          <Receipt />
          <span className="font-size-10">Imprimir Tickets</span>
        </IconButton>
      </span>
    </Tooltip>
  ); };

TicketsActions.propTypes = {
  tooltip: PropTypes.string,
  open: PropTypes.func.isRequired,
  disableTooltip: PropTypes.string,
  enable: PropTypes.bool.isRequired,
};
TicketsActions.defaultProps = {
  tooltip: 'Imprimir Tickets',
  disableTooltip: 'Debe haber una Orden de Venta o Cotización seleccionada, al menos productos en el carrito',
};

export default TicketsActions;
