import React from 'react';
import PropTypes from 'prop-types';
import {
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import { Clear } from '@material-ui/icons';
import CCTipoItem from './CCTipoItem';
import DocType from '../documents/document';

const CCTipo = ({ elements, click }) => {
  if (elements.length > 0) {
    return (
      <div className="balance__contenedor-hijo balance__lista" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
        {elements.map((el) => {
          const {
            cantidad,
            enable,
            label,
            movimientos,
            documentos,
            id,
            type,
            // total,
          } = el;
          let countCheck = 0;
          let totalCheck = 0.0;
          movimientos.forEach((mov) => {
            mov.detalles.forEach((det) => {
              if (det.enable === true) {
                countCheck += 1;
                if (det.mantener_efe && det.monto > 0) {
                  totalCheck -= parseFloat(det.monto);
                } else {
                  totalCheck += parseFloat(det.monto);
                }
              }
            });
          });
          return (
            label !== 'DOCS' ? (
              <CCTipoItem
                cantidad={cantidad}
                click={click}
                countCheck={countCheck}
                enable={enable}
                id={id}
                key={id}
                label={label}
                movimientos={movimientos}
                totalCheck={totalCheck}
                type={type}
              />
            ) : (
              // New docs component
              <DocType
                cantidad={cantidad}
                click={click}
                enable={enable}
                id={id}
                key={id}
                label={label}
                totalCheck={totalCheck}
                type={type}
                movimientos={movimientos}
              />
            )
          );
        })}
      </div>
    );
  }
  return (
    <div className="balance__contenedor-hijo balance__lista">
      <ListItem>
        <ListItemIcon>
          <Clear />
        </ListItemIcon>
        <ListItemText
          primary="Sin cortes de caja"
          secondary="Cortes de caja vacios"
        />
      </ListItem>
    </div>
  );
};

CCTipo.propTypes = {
  elements: PropTypes.array,
  click: PropTypes.func,
};

CCTipo.defaultProps = {
  elements: [],
  click: () => {},
};

export default CCTipo;
