/* eslint-disable array-callback-return */
/* eslint-disable import/no-cycle */
import {
  Avatar, Collapse, Divider, List, ListItem, ListItemAvatar, ListItemText, Paper,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { toast } from 'react-toastify';
import MdaAPI from '../../../../api/MdaApi';
import { createOrderRef, formatString } from '../../../../helpers/tools';
import FullDialog from '../../../FullDialog';
import ListMdaItem from '../../../Mda/components/ListMda/ListMdaItem';
import OrderMda from '../../OrderMda';
import ExternalDeliverProducts from '../ExternalDeliverProducts';

const ExternalDeliver = () => {
  const history = useHistory();
  const params = useParams();
  const authUser = useSelector(state => state.authUser);

  const [isDeliver, setIsDeliver] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingMda, setLoadingMda] = useState(false);
  const [products, setProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [order, setOrder] = useState({});
  const [mda, setMda] = useState([]);

  const mdaApi = new MdaAPI();
  const {
    user: {
      config: { store, id },
    },
  } = authUser;

  const move = 'SCP';
  const ref = `${move}-${createOrderRef()}-${id || '-1'}`;

  const orderDeliverDetails = async () => {
    setLoading(true);
    const response = await mdaApi.getPendingDeliveryDetail(
      store.crmid,
      params.orderId,
    );

    if (response.success === false) {
      toast.error('Error cargando la orden');
      setLoading(false);

      return;
    }

    if (response.result.length) {
      if (response.result[0].data.length) {
        setProducts(response.result[0].data);
      }
      const { data, ...rest } = response.result[0];
      setOrder({ ...rest });
    }

    setLoading(false);
  };

  const orderMda = async () => {
    setLoadingMda(true);
    const response = await mdaApi.get(params.orderId);
    const { success, result } = response;

    if (success && result.length) {
      setMda(result);
      setLoadingMda(false);
    }

    if (!success) {
      toast.error('Ocurrio un error obteniendo los movimientos de almacen');
    }
    setLoadingMda(false);
  };

  const selectAll = async () => {
    await setSelectedProducts([]);
    const selectProducts = [];
    products.map((prod) => {
      if (prod.por_devolver > 0 && prod.qtyinstock >= prod.por_devolver) {
        selectProducts.push(prod.treebesmdaid);
      }
    });

    setSelectedProducts([...selectProducts]);
  };

  const selectNone = () => {
    setSelectedProducts([]);
  };

  const changeInput = (prod) => {
    if (!selectedProducts.includes(prod.treebesmdaid)) {
      const selectedIds = [...selectedProducts, prod.treebesmdaid];
      setSelectedProducts(selectedIds);
    } else {
      const filtered = selectedProducts.filter(p => p !== prod.treebesmdaid);
      setSelectedProducts(filtered);
    }
  };

  const deliverOrder = async () => {
    if (!selectedProducts.length) {
      toast.warn('Debe seleccionar al menos un producto antes de entregar');

      return;
    }

    setIsDeliver(true);
    const response = await mdaApi.deliverTransfer(selectedProducts);

    if (response.message.success) {
      toast.success(response.message.success);
    }
    if (response.message.error) {
      toast.error(response.message.error);
    }
    setIsDeliver(false);
    history.goBack();
  };

  useEffect(() => {
    if (params.orderId && store.crmid) {
      orderDeliverDetails();
      orderMda();
    }
  }, []);

  return (
    <FullDialog
      title={`Entregar orden ${order?.salesorder_no || ''}`}
      isOpen
      isLoading={isDeliver}
      onClose={() => history.goBack()}
      onSave={deliverOrder}
      enableAction={!loading && !loadingMda && !isDeliver && products.length > 0}
    >
      <div className="deliver">
        <Collapse in={Boolean(!loading)} className="w-100">
          <Paper className="deliver__client">
            {Object.keys(order).length > 0 && (
              <ListMdaItem
                item={{
                  createdtime: order.f_venta,
                  entity_label: ref,
                  de_documentoid_label: order?.salesorder_no,
                  a_documentoid_label: store.name,
                }}
                isCollapse={false}
                sc="Entrega"
              />
            )}
            <Divider />
            {Object.keys(order).length > 0 && (
              <List className="w-100">
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>{order.accountname.substring(0, 1)}</Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={formatString(order.accountname)}
                    secondary="Cliente"
                  />
                </ListItem>
              </List>
            )}
          </Paper>
        </Collapse>
        {
          products.length > 0 && (
            <ExternalDeliverProducts
              products={products}
              changeInput={changeInput}
              loading={loading}
              selectAll={() => selectAll()}
              selectNone={() => selectNone()}
              selectedProducts={selectedProducts}
            />
          )
        }
        <OrderMda mda={mda} loading={loadingMda} />
      </div>
    </FullDialog>
  );
};

export default ExternalDeliver;
