import React from 'react';
import PropTypes from 'prop-types';
/* MaskedInput */
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';


export default function TextMaskCustom(props) {
  const { inputRef, ...other } = props;

  const percMask = createNumberMask({
    autocomplete: 'off',
    prefix: '',
    suffix: '',
  });

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={percMask}
      placeholderChar={'\u2000'}
      maxLength={2}
      max={100}
    />
  );
}

TextMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
};
