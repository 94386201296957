import React from 'react';
import PropTypes from 'prop-types';
import { Collapse } from '@material-ui/core';
import DeliverListItem from './DeliverListItem';

const DeliverList = ({
  table,
  loading,
  setFieldChange,
}) => (
  <div className="w-100 d-flex flex-column border-bottom">
    <Collapse in={!loading && table.items.length > 0} className="w-100">
      <div className="refund_products_head border-bottom my-1">Productos a entregar</div>
      <div className="deliver_products">
        {table.items.map(item => (
          <DeliverListItem
            key={`key_deliver_option_${item.crmid}`}
            item={{ ...item, setFieldChange }}
          />
        ))}
      </div>
    </Collapse>
  </div>
);

DeliverList.propTypes = {
  table: PropTypes.object,
  loading: PropTypes.bool,
  setFieldChange: PropTypes.func,
};

DeliverList.defaultProps = {
  table: {},
  loading: false,
  setFieldChange: () => {},
};

export default DeliverList;
