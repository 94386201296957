import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
// import PropTypes from 'prop-types';

import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEnvelope,
} from '@fortawesome/free-solid-svg-icons';
import { fetchAccountContactsEmails } from '../../../../../redux/actions/accountActions';

const ContactEmailList = withRouter(({ match, ...props }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAccountContactsEmails(match.params.crmid));
    // eslint-disable-next-line
  }, []);

  const contact = useSelector(state => state.account.contact.emails);

  return (
    <List classes={{ root: 'py-0' }}>
      {contact ? contact.map(item => (item.email ? (
        <a href={`mailto:${item.email}`} key={item.crmid} target="_blank" rel="noopener noreferrer">
          <ListItem button classes={{ root: 'py-0 pl-0' }}>
            <ListItemAvatar>
              <Avatar variant="square" className="modal-avatar">
                <FontAwesomeIcon style={{ fontSize: 26 }} icon={faEnvelope} />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={`${item.firstname} ${item.lastname}`} secondary={item.email} classes={{ primary: 'modal-primary-text', secondary: 'modal-secondary-text' }} />
          </ListItem>
        </a>
      )
        : (
          <ListItem key={Math.random()}>
            <ListItemText primary="Sin contactos con correo" />
          </ListItem>
        )))
        : (
          <ListItem>
            <ListItemText primary="Sin contactos con correo" />
          </ListItem>
        )}
    </List>
  ); });
ContactEmailList.prototype = {};
export default ContactEmailList;
