/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Paper } from '@material-ui/core';
import { Redeem } from '@material-ui/icons';
import ChequeIcon from '../../../../Icons/ChequeIcon';
import CashIcon from '../../../../Icons/CashIcon';
import CreditCardIcon from '../../../../Icons/CreditCardIcon';
import DebitCardIcon from '../../../../Icons/DebitCardIcon';
import TransferIcon from '../../../../Icons/TransferIcon';

const MethodCheckOut = ({ payment, selectPayment, rewardsMethod = {} }) => {
  const metohod = {
    efectivo: 'Efectivo',
    debito: 'Tarjeta de Debito',
    credito: 'Tarjeta de Credito',
    transferencia: 'Transferencia (SPEI)',
    cheque: 'Cheque',
  };

  const paymentIcon = {
    Efectivo: <CashIcon height={60} width={60} fill={payment?.method === 'Efectivo' ? '#8252a0' : '#b7bfc6'} />,
    'Tarjeta de Debito': <DebitCardIcon height={60} width={60} fill={payment?.method === 'Tarjeta de Debito' ? '#8252a0' : '#b7bfc6'} />,
    'Tarjeta de Credito': <CreditCardIcon height={60} width={60} fill={payment?.method === 'Tarjeta de Credito' ? '#8252a0' : '#b7bfc6'} />,
    'Transferencia (SPEI)': <TransferIcon height={60} width={60} fill={payment?.method === 'Transferencia (SPEI)' ? '#8252a0' : '#b7bfc6'} />,
    Cheque: <ChequeIcon height={60} width={60} fill={payment?.method === 'Cheque' ? '#8252a0' : '#b7bfc6'} />,
    'LBL_PUNTOS_RECOMPENSA': <Redeem style={{ fontSize: 45, fill: payment?.method === 'LBL_PUNTOS_RECOMPENSA' ? '#8252a0' : '#b7bfc6' }} />,
  };
  const authUser = useSelector(state => state.authUser);
  const { user: { metodos_pagos: metodoPagos } } = authUser;

  useEffect(() => {
    if (rewardsMethod) {
      const findIndex = metodoPagos.findIndex(m => m.method === 'LBL_PUNTOS_RECOMPENSA');
      if (findIndex === -1) {
        metodoPagos.push(rewardsMethod);
      }
    }
  }, [rewardsMethod]);

  return (
    <>
      <div>
        <h6>Selecciona metodo de pago</h6>
      </div>
      <div className="d-flex" style={{ gap: 10, overflowX: 'auto', padding: 8 }}>
        {
          metodoPagos.map((method) => {
            if (method.method && method.method !== 'Intermediario pagos') {
              return (
                <Paper
                  elevation={1}
                  key={Math.random()}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    cursor: 'pointer',
                    padding: '5px',
                    border: payment?.method === method.method ? '2px solid #8252a0' : '',
                  }}
                  onClick={() => selectPayment(method)}
                >
                  {paymentIcon[`${method.method}`]}
                  <div className="d-flex justify-content-center align-items-center">
                    <span style={{ fontSize: 11, textAlign: 'center' }}>{method.method === 'LBL_PUNTOS_RECOMPENSA' ? 'Puntos Recompensa' : method.method}</span>
                  </div>
                </Paper>
              );
            }
          })
        }
      </div>
    </>
  );
};

export default MethodCheckOut;
