import React from 'react';
import PropTypes from 'prop-types';

import CCMovimientoItem from './CCMovimientoItem';

const CCMovimiento = ({ movimientos, click }) => (
  <div className="balance__contenedor-hijo balance__lista flex-column" style={{justifyContent: 'center', alignItems: 'center'}}>
    {movimientos.map((el) => {
      const {
        cantidad,
        detalles,
        enable,
        id,
        label,
        type,
      } = el;
      let countCheck = 0;
      let totalCheck = 0.0;
      detalles.forEach((det) => {
        if (det.enable === true) {
          countCheck += 1;
          if (det.mantener_efe && det.monto > 0) {
            totalCheck -= parseFloat(det.monto);
          } else {
            totalCheck += parseFloat(det.monto);
          }
        }
      });
      return (
        <>
          {
              el.label?.includes('Puntos Recompensa') ? null
                : (
                  <CCMovimientoItem
                    cantidad={cantidad}
                    click={click}
                    countCheck={countCheck}
                    detalles={detalles}
                    enable={enable}
                    id={id}
                    key={id}
                    label={label}
                    totalCheck={totalCheck}
                    type={type}
                  />
                )
            }
        </>
      );
    })}
  </div>
);

CCMovimiento.propTypes = {
  click: PropTypes.func.isRequired,
  movimientos: PropTypes.array,
};

CCMovimiento.defaultProps = {
  movimientos: [],
};

export default CCMovimiento;
