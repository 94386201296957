import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
} from '@fortawesome/free-solid-svg-icons';
import Typography from '@material-ui/core/Typography';
import Search from '../../../../components/Search';

const Transition = React.forwardRef((props, ref) => <Slide direction="left" ref={ref} {...props} />);


const SearchByType = ({ open, handleClose, type }) => (
  <Dialog
    fullScreen
    open={open}
    onClose={handleClose}
    TransitionComponent={Transition}
    classes={{ paperFullScreen: 'dialog-bg' }}
    style={{
      width: 520,
      position: 'absolute',
      left: '50%',
      marginLeft: -260,
    }}
  >
    <AppBar className="appBar" color="inherit" style={{ position: 'relative', boxShadow: 'none' }}>
      <Toolbar variant="dense" disableGutters elevation={0}>
        <IconButton edge="start" onClick={handleClose} aria-label="close">
          <FontAwesomeIcon style={{ fontSize: 16 }} icon={faChevronLeft} />
        </IconButton>
        <Typography variant="h6" className="toolbar-title title-type">
          {`Buscar ${type}`}
        </Typography>
      </Toolbar>
    </AppBar>
    <Search type={type} />
  </Dialog>

);

export default SearchByType;
