/* eslint-disable import/no-cycle */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import IconButtonInput from '../../Input/IconButton';
import ChangeDefaultCart from '../../Cart/components/controls/components/ChangeDefaultCart';
import SeeOnCrmAction from '../../Cart/components/controls/components/SeeOnCrmAction';
import CustomFields from '../../Cart/components/controls/components/CustomFields';
import appModules from '../../../constant/appModules';
import { openModal, toggleModal } from '../../../redux/actions/modalActions';
import { removeCustomer, selectCustomer } from '../../../redux/actions/customerActions';

const CartCustomerMenu = () => {
  const dispatch = useDispatch();
  const cart = useSelector(state => state.cart);
  const customer = useSelector(state => state.customer);
  const { orderSelected } = cart;
  const { selectedCustomer } = customer;
  const { quotestage } = orderSelected;

  const enableChangeCustomer = Boolean(Object.keys(orderSelected).length === 0
    || (orderSelected.estadosat === 'Sin Factura' || quotestage !== null));

  const enableEditCustomer = Boolean(selectedCustomer.crmid !== '54572' && enableChangeCustomer);
  const enableRemoveCustomer = Boolean(selectedCustomer.crmid !== '54572' && Object.keys(orderSelected).length === 0);

  const rest = { width: '60px', height: '60px', iconSize: '18px' };
  return (
    <>
      <IconButtonInput
        icon="user-plus"
        label="Crear Cliente"
        enable={enableChangeCustomer}
        onClick={() => {
          dispatch(openModal('customerCreate'));
        // handleClose();
        }}
        tooltip={enableChangeCustomer
          ? 'Crear Cliente'
          : 'Ordenes de Venta que tengan una factura no podran cambiar de cliente'
          }
        {...rest}
      />
      <IconButtonInput
        icon="user-cog"
        enable={enableEditCustomer}
        onClick={async () => {
          await dispatch(selectCustomer(selectedCustomer, false));
          await dispatch(toggleModal('customerEdit'));
          // await dispatchSelectCustomer(selectedCustomer, false);
          // await dispatchToggleModal('customerEdit');
          // handleClose();
        }}
        label="Editar Cliente"
        tooltip={enableEditCustomer
          ? 'Editar Cliente'
          : 'Público en General no puede editarse al igual que con ordenes facturadas, si necesita editar al cliente dirijase a Caja'
          }
        {...rest}
      />
      {/* <IconButtonInput
        icon="user-times"
        enable={enableRemoveCustomer}
        onClick={() => {
          dispatch(removeCustomer());
          // dispatchRemoveCustomer();
          // handleClose();
        }}
        label="Remover"
        tooltip={enableRemoveCustomer ? 'Regresar cliente a Público en General' : ''}
        {...rest}
      /> */}
      <ChangeDefaultCart handleClose={() => {}} />
      <SeeOnCrmAction enable appLocation="client" tooltip="Ver cliente en sistema" />
      <CustomFields enable tooltip="Campos Personalizados" title="Campos" module={appModules.Accounts} />
    </>
  ); };

export default CartCustomerMenu;
