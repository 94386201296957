/* eslint-disable consistent-return */
import React from 'react';
import PropTypes from 'prop-types';
/* Alerts */
import SimpleAlert from './SimpleAlert';

const Alert = ({
  open,
  type,
  desc,
  width,
  title,
  handleClose,
  isCancellable,
  primaryAction,
  cancelButton,
  secondaryAction,
  isPrimaryAction,
  isSecondaryAction,
  primaryActionButton,
  secondaryActionButton,
}) => {
  function renderAlertByType() {
    switch (type) {
      case 'simple':
        return (
          <SimpleAlert
            open={open}
            desc={desc}
            width={width}
            title={title}
            handleClose={handleClose}
            cancelButton={cancelButton}
            isCancellable={isCancellable}
            primaryAction={primaryAction}
            secondaryAction={secondaryAction}
            isPrimaryAction={isPrimaryAction}
            isSecondaryAction={isSecondaryAction}
            primaryActionButton={primaryActionButton}
            secondaryActionButton={secondaryActionButton}
          />
        );
      default:
        return (
          <SimpleAlert
            open={open}
            desc={desc}
            width={width}
            title={title}
            handleClose={handleClose}
            cancelButton={cancelButton}
            isCancellable={isCancellable}
            primaryAction={primaryAction}
            secondaryAction={secondaryAction}
            isPrimaryAction={isPrimaryAction}
            isSecondaryAction={isSecondaryAction}
            primaryActionButton={primaryActionButton}
            secondaryActionButton={secondaryActionButton}
          />
        );
    }
  }
  return <>{renderAlertByType()}</>;
};

Alert.propTypes = {
  desc: PropTypes.string,
  width: PropTypes.string,
  title: PropTypes.string,
  isCancellable: PropTypes.bool,
  primaryAction: PropTypes.func,
  cancelButton: PropTypes.string,
  open: PropTypes.bool.isRequired,
  secondaryAction: PropTypes.func,
  isPrimaryAction: PropTypes.bool,
  isSecondaryAction: PropTypes.bool,
  primaryActionButton: PropTypes.string,
  handleClose: PropTypes.func.isRequired,
  secondaryActionButton: PropTypes.string,
  type: PropTypes.oneOf(['simple', 'default']),
};
Alert.defaultProps = {
  desc: '',
  width: 'sm',
  type: 'simple',
  title: 'Alerta ...',
  isCancellable: false,
  isPrimaryAction: false,
  primaryAction: (props, propName) => {
    if ((props.isPrimaryAction === true && (props[propName] === undefined || typeof (props[propName]) !== 'function'))) {
      return new Error('Please provide a primaryAction function!');
    }
  },
  isSecondaryAction: false,
  secondaryAction: (props, propName) => {
    if ((props.isSecondaryAction === true && (props[propName] === undefined || typeof (props[propName]) !== 'function'))) {
      return new Error('Please provide a secondary function!');
    }
  },
  cancelButton: 'Cancelar',
  primaryActionButton: 'Aceptar',
  secondaryActionButton: 'Invalidar acción',
};

export default Alert;
