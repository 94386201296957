import { useState, useEffect } from 'react';
import FdeAPI from '../../api/FdeAPI';

export default function useFdeOrder(orderId) {
  const [fdeList, setFdeList] = useState([]);
  const [errorFde, setErrorFde] = useState(null);
  const [isLoadingFde, setIsLoadingFde] = useState(false);

  const getOrderFde = async () => {
    setIsLoadingFde(true);
    const fdeApi = new FdeAPI();
    const response = await fdeApi.get(orderId);
    const { success, result } = response;

    if (success) {
      setFdeList(result);
    } else {
      setErrorFde('Mientras cargabamos los flujos de efectivo ocurrió un error');
    }

    setIsLoadingFde(false);
  };

  useEffect(() => {
    getOrderFde();
  }, [orderId]);

  return { fdeList, isLoadingFde, errorFde };
}
