/* eslint-disable import/no-cycle */
import { connect } from 'react-redux';
import { fetchFactura } from '../../redux/actions/orderActions';
import { closeModal } from '../../redux/actions/modalActions';
import Factura from './constructor';

const mapStateToProps = (state) => {
  const { order, loads, cart } = state;
  const { facturas } = order;
  const { orderSelected } = cart;
  return ({
    loading: Boolean(loads.orderIsLoading),
    modals: state.modals,
    facturas,
    orderSelected,
  });
};

const mapDispatchToProps = dispatch => ({
  dispatchCloseDiscountModal: () => dispatch(closeModal('factura')),
  dispatchFetchFacturas: () => dispatch(fetchFactura()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Factura);
