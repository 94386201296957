const formatPhone = (value) => {
  const isValidPhone = /^(\([0-9]{3}\)|[0-9]{3}-|[0-9]{3})([0-9]{3}-|[0-9]{3})[0-9]{4}$/;

  if (isValidPhone.test(value)) {
    if (/^\(([0-9]{3})\)[-.\s]([0-9]{3})[-.\s]([0-9]{4})$/.test(value)) {
      return value;
    }

    if (/^[0-9]{3}-[0-9]{3}-[0-9]{4}$/.test(value)) {
      return value
        .replace(/(\d{3}-)(\d{3}-)(\d{4})/, '($1)-$2-$3')
        .replace('-', '');
    }
    return value.replace(/(\d{3})(\d{3})(\d{4})/, '($1)-$2-$3');
  }
  return value;
};


export default {
  formatPhone,
};
