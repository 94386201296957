/* eslint-disable import/no-cycle */
import { connect } from 'react-redux';
import { fetchAllClients } from '../../redux/actions/clientActions';
import { fetchListVendors, fetchListExpensesType, fetchListAlmacen } from '../../redux/actions/expensesActions';
import { fetchAllProject } from '../../redux/actions/taskActions';
import Search from './constructor/Search';

const mapStateToProps = (state) => {
  const {
    loads, gastos,
  } = state;
  const { gastoSeleccionado } = gastos;
  return ({
    loading: Boolean(loads.orderIsLoading),
    gasto: gastoSeleccionado,
  });
};

const mapDispatchToProps = dispatch => ({
  dispatchFetchListVendors: () => dispatch(fetchListVendors()),
  dispatchFetchListExpensesType: () => dispatch(fetchListExpensesType()),
  dispatchFetchListAlmacen: () => dispatch(fetchListAlmacen()),
  dispatchFetchListUser: () => dispatch(fetchAllClients()),
  dispatchFetchListProjects: () => dispatch(fetchAllProject()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Search);
