import { RESET_TICKET_DATA, SET_TICKET_DATA } from '../types/ticketActions.type';

export const setTicketData = (
  customer = {},
  order = {},
  products = [],
  subTotal = 0,
  totalIva = 0,
  description = '',
  ticketType = '',
) => (
  async (dispatch) => {
    dispatch({
      type: SET_TICKET_DATA,
      payload: {
        customer,
        order,
        products,
        subTotal,
        totalIva,
        description,
        ticketType,
      },
    });
  }
);

export const resetTicketData = () => (
  async (dispatch) => {
    dispatch({
      type: RESET_TICKET_DATA,
    });
  }
);
