import fetch from 'cross-fetch';
import ConfigurationAPI from './ConfigurationAPI';

class CustomerAPI extends ConfigurationAPI {
  create = async (customer) => {
    this.credentials.method = 'POST';

    const data = {
      account_custom_field: {
        110: '110',
        120: customer.razonSocial,
        130: customer.rfc,
        140: customer.calle,
        150: customer.numExt,
        160: customer.numInt,
        170: customer.colonia,
        180: customer.pais,
        190: customer.estado,
        200: customer.delegacion,
        210: customer.codigoPostal,
      },
      email: customer.email,
      firstname: customer.nombre,
      lastname: customer.apellidos,
      telephone: customer.telefono,
      c_regimenfiscal: customer.c_regimenfiscal,
      c_codigopostal: customer.c_codigopostal,
      point_card_number: customer.point_card_number,
    };

    this.credentials.body = JSON.stringify(data);

    try {
      const response = await fetch(`${this.url}/Accounts`, this.credentials);
      const json = await response.json();
      return { ...json };
    }

    catch {
      return { success: false, message: 'Error en el Host' };
    }
  }

  edit = async (customer) => {
    this.credentials.method = 'PUT';

    const data = {
      ...customer,
      account_no: customer.account_no,
      bill_street: customer.calle,
      bill_num_ext: customer.numExt,
      bill_num_int: customer.numInt,
      bill_pobox: customer.colonia,
      bill_code: customer.codigoPostal,
      bill_city: customer.delegacion,
      bill_state: customer.estado,
      bill_country: customer.pais,
      siccode: customer.rfc,
      razon_social: customer.razonSocial,
      email1: customer.email,
      crmid: customer.crmid,
      firstname: customer.nombre,
      lastname: customer.apellidos,
      phone: customer.telefono,
      accountname: customer.accountname,
      treebesdireccionfacturaid: customer.treebesdireccionfacturaid,
      treebesdireccionenvioid: customer.treebesdireccionenvioid,
      c_regimenfiscal: customer.c_regimenfiscal,
      c_codigopostal: customer.c_codigopostal,
    };

    this.credentials.body = JSON.stringify(data);

    try {
      const response = await fetch(`${this.url}/list/Accounts`, this.credentials);
      const json = await response.json();
      return { ...json };
    }

    catch {
      return { success: false, message: 'Error en el Host' };
    }
  }

  getAll = async (page = '1') => {
    try {
      const response = await fetch(`${this.url}/list/Accounts?page=${page}`, this.credentials);
      const json = await response.json();
      return json;
    }

    catch {
      return { success: false, message: 'Error en el Host' };
    }
  }

  getByAccointId = async (id) => {
    try {
      const response = await fetch(`${this.url}/id/${id}`, this.credentials);
      const json = await response.json();
      return json;
    } catch {
      return { success: false, message: 'Error en el Host' };
    }
  }

  getSchema = async () => {
    try {
      const response = await fetch(`${this.url}/Accounts/schema`, this.credentials);
      const json = await response.json();
      return json;
    } catch {
      return { success: false, message: 'Error en el Host' };
    }
  }

  getAccountByName = async (name) => {
    try {
      const response = await fetch(`${this.url}/list/Accounts?search=${name}`, this.credentials);
      const json = await response.json();
      return json;
    } catch (e) {
      return { success: false, message: 'Error en el Host' };
    }
  }

  getRegionList = async () => {
    try {
      const response = await fetch(`${this.url}/CatalogoSat?table=c_regimenfiscal`, this.credentials);
      const json = await response.json();
      return json;
    } catch (e) {
      return { success: false, message: 'Error en el Host' };
    }
  }
}

export default CustomerAPI;
