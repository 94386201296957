/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';

/* Material */
import {
  Button,
  Collapse,
} from '@material-ui/core';

const AmountCheckOut = ({
  title,
  onSubmit,
  enableSubmit,
}) => (
  <Collapse in={enableSubmit}>
    <div className="w-100 pt-4 h-auto amount_checkout">
      <Button
        id="checkout_submit"
        onClick={() => onSubmit()}
        tabIndex={-1}
      >
        {title}
      </Button>
    </div>
  </Collapse>
);
AmountCheckOut.propTypes = {
  title: PropTypes.string,
  enableSubmit: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
};

AmountCheckOut.defaultProps = {
  title: 'Enter para Cobrar',
  enableSubmit: false,
};

export default AmountCheckOut;
