/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Checkbox,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from '@material-ui/core';
import classnames from 'classnames';
import { formatMoney } from '../../../../../../helpers/tools';

const CCDetalle = ({ detalles, parentName, click }) => (
  <List dense>
    {detalles.map((el, index) => {
      const {
        banco,
        cliente,
        enable,
        fecha_realizado,
        id,
        monto,
        referencia,
        type,
        mantener_efe,
        sum_discount,
      } = el;
      const labelId = `${parentName}-${id}`;
      return (
        <ListItem
          style={(mantener_efe || referencia.includes('MANTENER EFECTIVO')) ? { display: 'none' } : {}}
          key={labelId}
          className={classnames('detalle_tipos_separador', {
            'detalle_tipos_separador-pair': index % 2 === 0,
            'detalle_tipos_separador-none': index % 2 !== 0,
          })}
          data-id={id}
          data-kind={type}
          /* onClick={(e) => {
            const { currentTarget } = e;
            click(currentTarget);
            e.preventDefault();
            e.stopPropagation();
          }} */
        >
          <ListItemText
            id={labelId}
            primary={(
              <>
                <Typography
                  component="span"
                  variant="body2"
                  color="textPrimary"
                >
                  <div className="balance-detalles__flex-space">
                    <strong>{referencia}</strong>
                    {
                      mantener_efe
                        ? (<strong>{`$ -${formatMoney(`${monto}`)}`.replace('--', '-')}</strong>)
                        : (<strong>{`$ ${formatMoney(monto)}`}</strong>)
                    }

                  </div>
                </Typography>
              </>
            )}
            secondary={(
              <>
                <span className="balance-detalles__flex-group">
                  <span>{fecha_realizado}</span>
                  <span>{banco}</span>
                  <span>{cliente}</span>
                </span>
                {
                  sum_discount && formatMoney(sum_discount) !== '0.00' && (

                    <span className="d-flex justify-content-between align-items-center">
                      <span><strong> Descuento </strong></span>
                      <span>
                        <strong>
                          {' '}
                          {`$ ${formatMoney(sum_discount)}`}
                          {' '}
                        </strong>
                      </span>
                    </span>
                  )
                }
              </>
            )}
          />
          <ListItemSecondaryAction style={(mantener_efe || referencia.includes('MANTENER EFECTIVO')) ? { display: 'none' } : {}}>
            {/* <Checkbox
              inputProps={{ 'aria-labelledby': labelId }}
              className="balance-detalles__top-up"
              checked={Boolean(enable)}
              data-id={id}
              data-kind={type}
              onClick={(e) => {
                const { currentTarget } = e;
                click(currentTarget);
                e.preventDefault();
                e.stopPropagation();
              }}
            /> */}
          </ListItemSecondaryAction>
        </ListItem>
      );
    })}
  </List>
);

CCDetalle.propTypes = {
  click: PropTypes.func.isRequired,
  detalles: PropTypes.array,
  parentName: PropTypes.string,
};

CCDetalle.defaultProps = {
  detalles: [],
  parentName: '',
};

export default CCDetalle;
