import React from 'react';
import PropTypes from 'prop-types';
import {
  Collapse,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  Divider,
} from '@material-ui/core';
import InvoiceItem from './InvoiceItem';
import './invoice-list.styles.scss';


const InvoiceList = ({
  invoices,
  isLoading,
}) => (
  <div className="w-100 h-auto">
    <Collapse in={isLoading}>
      <LinearProgress />
    </Collapse>
    <Collapse in={!isLoading}>
      {invoices.length > 0 ? (
        <List>
          {invoices.map((item, i) => (
            <div key={`key_factura_${item.crmid}`} className="w-100">
              {i !== 0 && (
              <Divider />
              )}
              <InvoiceItem item={item} />
            </div>
          ))}
        </List>
      ) : (
        <List dense>
          <ListItem>
            <ListItemText
              primary="Sin facturas"
              secondary="La orden no cuenta con facturas relacionadas"
            />
          </ListItem>
        </List>
      )}
    </Collapse>
  </div>
);

InvoiceList.propTypes = {
  invoices: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default InvoiceList;
