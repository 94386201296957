import { toast } from 'react-toastify';
import FinanceAPI from '../../api/FinanceAPI';
import { loading, loaded } from './loadActions';
// copiaaaaaaaa;
import {
  ADD_SALES_ORDER,
  ADD_SALES_ORDER_SUMMARY,
  ADD_LIST_INVOICE,
  ADD_TRANSFERS,
  ADD_CREDIT_NOTES,
  ADD_CASH_FLOW,
  ADD_SALES_ORDER_PAGINATOR,
  ADD_SALES_ORDER_SUMMARY_PAGINATOR,
  ADD_LIST_INVOICE_PAGINATOR,
  ADD_TRANSFERS_PAGINATOR,
  ADD_CREDIT_NOTES_PAGINATOR,
  ADD_CASH_FLOW_PAGINATOR,
  ADD_QUOTES,
  ADD_QUOTES_PAGINATOR,
  ADD_MDA,
  ADD_MDA_PAGINATOR,
} from '../types/financeActions.type';

export function AddQuotes(quotes, pagination) {
  return {
    type: pagination ? ADD_QUOTES_PAGINATOR : ADD_QUOTES,
    payload: quotes,
  };
}

export function AddSalesOrder(sales, pagination) {
  return {
    type: pagination ? ADD_SALES_ORDER_PAGINATOR : ADD_SALES_ORDER,
    payload: sales,
  };
}

export function AddSalesOrderSummary(sales, pagination) {
  return {
    type: pagination ? ADD_SALES_ORDER_SUMMARY_PAGINATOR : ADD_SALES_ORDER_SUMMARY,
    payload: sales,
  };
}

export function AddListInvoice(invoices, pagination) {
  return {
    type: pagination ? ADD_LIST_INVOICE_PAGINATOR : ADD_LIST_INVOICE,
    payload: invoices,
  };
}

export function AddListInvoiceTransfers(transfers, pagination) {
  return {
    type: pagination ? ADD_TRANSFERS_PAGINATOR : ADD_TRANSFERS,
    payload: transfers,
  };
}

export function AddMda(mda, pagination) {
  return {
    type: pagination ? ADD_MDA_PAGINATOR : ADD_MDA,
    payload: mda,
  };
}

export function AddCreditNotes(notes, pagination) {
  return {
    type: pagination ? ADD_CREDIT_NOTES_PAGINATOR : ADD_CREDIT_NOTES,
    payload: notes,
  };
}

export function AddCashFlow(cashFlow, pagination) {
  return {
    type: pagination ? ADD_CASH_FLOW_PAGINATOR : ADD_CASH_FLOW,
    payload: cashFlow,
  };
}

export const fetchListQuotes = (page = 1, search = '', accountId = 0, pagination = false) => async (dispatch) => {
  const financeApi = new FinanceAPI();
  dispatch(loading('update'));
  try {
    const response = await financeApi.getListQuotes(page, search, accountId);
    const { success, result, pages } = response;
    if (process.env.NODE_ENV === 'development') {
      console.log('===================fetchListQuotes=================');
      console.log({ result });
      console.log('====================================');
    }
    if (success) {
      dispatch(AddQuotes({ result, pages }, pagination));
    }
    else {
      console.error('Error obteniendo datos de cotización');
      // toast.error('Error obteniendo datos de cotización');
    }
  } catch (err) {
    toast.error(err);
  }
  finally {
    dispatch(loaded('update'));
  }
};

export const fetchSalesOrder = (page = 1, search = '', accountId = 0, pagination = false) => async (dispatch) => {
  const financeApi = new FinanceAPI();
  dispatch(loading('update'));
  try {
    const response = await financeApi.getListSalesOrder(page, search, accountId);
    const { success, result, pages } = response;
    if (process.env.NODE_ENV === 'development') {
      console.log('===================fetchSalesOrder=================');
      console.log({ result });
      console.log('====================================');
    }
    if (success) {
      dispatch(AddSalesOrder({ result, pages }, pagination));
    }
    else {
      console.error('Error obteniendo datos de ventas');
      // toast.error('Error obteniendo datos de ventas');
    }
  } catch (err) {
    toast.error(err);
  }
  finally {
    dispatch(loaded('update'));
  }
};

export const fetchSalesOrderSummary = (page = 1, search = '', accountId = 0, pagination = false) => async (dispatch) => {
  const financeApi = new FinanceAPI();
  dispatch(loading('update'));
  try {
    const response = await financeApi.getListSalesOrderSummary(page, search, accountId);
    const { success, result, pages } = response;
    if (process.env.NODE_ENV === 'development') {
      console.log('===================fetchSalesOrderSummary=================');
      console.log({ result });
      console.log('====================================');
    }
    if (success) {
      dispatch(AddSalesOrderSummary({ result, pages }, pagination));
    }
    else {
      console.error('Error obteniendo datos de resumen de ventas');
      // toast.error('Error obteniendo datos de resumen de ventas');
    }
  } catch (err) {
    toast.error(err);
  }
  finally {
    dispatch(loaded('update'));
  }
};

export const fetchListInvoice = (page = 1, search = '', accountId = 0, pagination = false) => async (dispatch) => {
  const financeApi = new FinanceAPI();
  dispatch(loading('update'));
  try {
    const response = await financeApi.getListTreebesInvoices(page, search, accountId);
    if (process.env.NODE_ENV === 'development') {
      console.log('===================fetchListInvoice=================');
      console.log({ response });
      console.log('====================================');
    }
    const { success, result, pages } = response;
    if (success) {
      dispatch(AddListInvoice({ result, pages }, pagination));
    }
    else {
      console.error('Error obteniendo datos de facturas');
      // toast.error('Error obteniendo datos de facturas');
    }
  } catch (err) {
    toast.error(err);
  }
  finally {
    dispatch(loaded('update'));
  }
};

export const fetchListInvoiceTransfers = (page = 1, search = '', accountId = 0, pagination = false) => async (dispatch) => {
  const financeApi = new FinanceAPI();
  dispatch(loading('update'));
  try {
    const response = await financeApi.getListTreebesTransfersInvoices(page, search, accountId);
    if (process.env.NODE_ENV === 'development') {
      console.log('===================fetchListInvoiceTransfers=================');
      console.log({ response });
      console.log('====================================');
    }
    const { success, result, pages } = response;
    if (success) {
      dispatch(AddListInvoiceTransfers({ result, pages }, pagination));
    }
    else {
      console.error('Error obteniendo datos de traslados de facturas');
      // toast.error('Error obteniendo datos de traslados de facturas');
    }
  } catch (err) {
    toast.error(err);
  }
  finally {
    dispatch(loaded('update'));
  }
};

export const fetchListMda = (page = 1, search = '', accountId = 0, pagination = false) => async (dispatch) => {
  const financeApi = new FinanceAPI();
  dispatch(loading('update'));
  try {
    const response = await financeApi.getListTreebesMdA(page, search, accountId);
    if (process.env.NODE_ENV === 'development') {
      console.log('===================fetchListMda=================');
      console.log({ response });
      console.log('====================================');
    }
    const {
      success, result, pages, count,
    } = response;
    if (success) {
      dispatch(AddMda({ result, pages, count }, pagination));
    }
    else {
      console.error('Error obteniendo datos de mda');
      //toast.error('Error obteniendo datos de mda');
    }
  } catch (err) {
    toast.error(err);
  }
  finally {
    dispatch(loaded('update'));
  }
};

export const fetchListCashFlow = (page = 1, search = '', accountId = 0, pagination = false) => async (dispatch) => {
  const financeApi = new FinanceAPI();
  dispatch(loading('update'));
  try {
    const response = await financeApi.getListTreebesCashFlow(page, search, accountId);
    if (process.env.NODE_ENV === 'development') {
      console.log('===================fetchListCashFlow=================');
      console.log({ response });
      console.log('====================================');
    }
    const { success, result, pages } = response;
    if (success) {
      dispatch(AddCashFlow({ result, pages }, pagination));
    }
    else {
      console.error('Error obteniendo datos de flujo de efectivo');
      // toast.error('Error obteniendo datos de flujo de efectivo');
    }
  } catch (err) {
    toast.error(err);
  }
  finally {
    dispatch(loaded('update'));
  }
};

export const fetchListCreditNotes = (page = 1, search = '', accountId = 0, pagination = false) => async (dispatch) => {
  const financeApi = new FinanceAPI();
  dispatch(loading('update')); // cambio de commit;
  try {
    const response = financeApi.getListTreebesCreditNotes(page, search, accountId);
    if (process.env.NODE_ENV === 'development') {
      console.log('===================fetchListCreditNotes=================');
      console.log({ response });
      console.log('====================================');
    }
    const { success, result, pages } = response;

    if (success) {
      dispatch(AddCreditNotes({ result, pages }, pagination));
    }
    else {
      console.error('Error obteniendo datos de notas de crédito');
      // toast.error('Error obteniendo datos de notas de crédito');
    }
  } catch (err) {
    toast.error(err);
  }
  finally {
    dispatch(loaded('update'));
  }
};

export function fetchDataResume() {
  return async (dispatch) => {
    dispatch(loading('resume'));
    await setTimeout(() => {
      dispatch(loaded('resume'));
    }, 3000);
  };
}
export function fetchDataUpdates() {
  return async (dispatch) => {
    dispatch(loading('update'));
    await setTimeout(() => {
      // dispatch(loaded('update'));
    }, 3000);
  };
}
