import React from 'react';
import PropTypes from 'prop-types';
import {
  Tooltip,
  IconButton,
} from '@material-ui/core';
import { Replay } from '@material-ui/icons';

const RefundAction = ({
  enable,
  tooltip,
  refundProducts,
}) => (
  <Tooltip
    title={tooltip}
    placement="left"
  >
    <span>
      <IconButton
        aria-label="refun"
        aria-haspopup="true"
        disabled={!enable}
        onClick={() => refundProducts()}
        className="pos_menu_setDir"
      >
        <Replay />
        <span className="font-size-10">Devolución</span>
      </IconButton>
    </span>
  </Tooltip>
);

RefundAction.propTypes = {
  tooltip: PropTypes.string,
  enable: PropTypes.bool.isRequired,
  refundProducts: PropTypes.func.isRequired,
};
RefundAction.defaultProps = {
  tooltip: 'Realizar devolución',
};

export default RefundAction;
