import { useState } from 'react';
import { toast } from 'react-toastify';
import DownloadAPI from '../../api/DownloadAPI';
import OrderAPI from '../../api/OrderAPI';

export default function useFile() {
  const orderApi = new OrderAPI();
  const [loading, setLoading] = useState(false);
  const [isRemoved, setIsRemoved] = useState(false);


  const deleteFile = async (crmid) => {
    const response = await orderApi.deleteFile(crmid);
    const { success } = response;

    if (success) {
      setIsRemoved(true);
      setTimeout(() => {
        setIsRemoved(false);
      }, 2000);
      toast.success('Archivo eliminado correctamente');
    } else {
      toast.error('Mientras se eliminaba el archivo ocurrió un error');
    }
  };

  const download = async (url, name) => {
    setLoading(true);
    const downloadApi = new DownloadAPI();
    try {
      await downloadApi.download(url, name);
      toast.success('Archivo descargado correctamente');
    } catch (error) {
      toast.error('Mientras se descaraba el archivo ocurrió un error');
    }

    setLoading(false);
  };

  const preview = async (url) => {
    setLoading(true);
    const downloadApi = new DownloadAPI();
    try {
      const file = await downloadApi.preview(url);
      toast.success('Archivo cargado correctamente');
      console.log('file', file);
      return file;
    } catch (error) {
      toast.error('Mientras se cargaba el archivo ocurrió un error');
    }

    setLoading(false);
  };

  return {
    loading, isRemoved, deleteFile, download, preview,
  };
}
