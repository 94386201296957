/* eslint-disable no-restricted-syntax */
/* eslint-disable no-case-declarations */
import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  Tooltip,
  IconButton,
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Badge from '@material-ui/core/Badge';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { withStyles } from '@material-ui/core/styles';
import ProductAPI from '../../../../../api/ProductAPI';
import { getStorage } from '../../../../../helpers/pricebook';

const StyledBadge = withStyles(() => ({
  badge: {
    right: -3,
    top: 13,
    padding: '0 4px',
  },
}))(Badge);

const AppliedPromotionsAction = ({
  tooltip,
  product,
}) => {
  const ProductApi = new ProductAPI();
  const priceBookSelected = getStorage();
  const cart = useSelector(state => state.cart);
  const authUser = useSelector(state => state.authUser);
  const currentStore = authUser.isAuth ? authUser.user.config.store : {};
  const [count, setCount] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [promotionNames, setPromotionNames] = React.useState([]);

  const findPromotions = (productsWidthpromotions, promotions) => {
    let promos = [];
    /* if (cart.activePromotions && productsWidthpromotions && productsWidthpromotions.length) { */
    if (authUser?.user?.config.pos_promo_cart === '1' && productsWidthpromotions && productsWidthpromotions.length) {
      const index = productsWidthpromotions.findIndex(prod => prod.productid === product.crmid);
      if (index !== -1 && productsWidthpromotions[index].TreebesPromociones.length) {
        productsWidthpromotions[index].TreebesPromociones.map((promoId) => {
          promos.push(promotions.applied_promos[promoId].nombre);
        });
      }

      // Buscando precio por volumen
      if (productsWidthpromotions[index] && productsWidthpromotions[index].TreebesPromociones_unit_price_name !== null) {
        promos.push(productsWidthpromotions[index].TreebesPromociones_unit_price_name);
      }

      // Buscando regalo por subtotal
      for (const property in promotions.applied_promos) {
        if (promotions.applied_promos[property].pl_promociones_tipo_promo === 'regalo_por_monto_subtotal') {
          promos.push(promotions.applied_promos[property].nombre);
        }
      }
    }
    promos = [...new Set([...promos])];
    setPromotionNames([...promos]);
    setCount(promos.length);
  };

  const verifyPromotions = async () => {
    const data = {
      origen: 'TPV',
      treebesalmid: currentStore.crmid,
      pricebookid: priceBookSelected.crmid || 0,
      products: [],
    };
    cart.products.map((producto) => {
      // debugger;
      /* if (producto.unit_price < 0.02) {
        return;
      } */
      const productid = producto.productid && producto.productid.indexOf('x') !== -1
        ? producto.productid.split('x').pop()
        : producto.productid;

      data.products.push({
        productid: producto.crmid || productid,
        quantity: producto.quantity,
        unit_price: producto.unit_price,
      });
    });
    if (data.products.length) {
      const promotion = await ProductApi.getPromotions(data);
      const {
        result: { TreebesPromociones, products },
      } = promotion;
      const productsWidthpromotions = products;
      const promotions = TreebesPromociones;
      findPromotions(productsWidthpromotions, promotions);
    }
  };

  const handleClose = () => {
    setOpen(!open);
  };

  React.useEffect(() => {
    if (authUser?.user?.config.pos_promo_cart === '1' && cart.products.length) {
      verifyPromotions();
    }
  }, []);

  return (
    <>
      <Tooltip
        title={tooltip}
        placement="left"
      >
        <span>
          <StyledBadge badgeContent={count} color="primary">
            <IconButton
              id="action_control_see"
              disabled={!promotionNames.length}
              onClick={() => setOpen(true)}
              className="pos_menu_setDir"
            >
              <FontAwesomeIcon
                icon="certificate"
                style={{ width: '21px', heigth: '21px' }}
              />
            </IconButton>
          </StyledBadge>

        </span>
      </Tooltip>
      <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
        <DialogTitle id="simple-dialog-title">Promociones aplicadas</DialogTitle>
        <List>
          {/* {cart.activePromotions && promotionNames.length && promotionNames.map(prom => ( */}
          {authUser?.user?.config.pos_promo_cart === '1' && promotionNames.length && promotionNames.map(prom => (
            <ListItem button key={prom}>
              <ListItemText primary={prom} />
            </ListItem>
          ))}
        </List>
      </Dialog>
    </>
  ); };

AppliedPromotionsAction.propTypes = {
  tooltip: PropTypes.string,
  product: PropTypes.object,
};
AppliedPromotionsAction.defaultProps = {
  tooltip: 'Promociones aplicadas',
  product: {},
};

export default AppliedPromotionsAction;
