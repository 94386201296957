import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import { Refresh } from '@material-ui/icons';

/* Nota: se puede reutilizar */
const PosActionReLoad = ({ handleOnClick }) => (
  <IconButton
    aria-label="Refresh"
    onClick={handleOnClick}
    onFocus={async () => {
      const topSearchBar = document.querySelector('#search_bar_query');
      // @ts-ignore
      await topSearchBar.focus();
      // @ts-ignore
      topSearchBar.select();
    }}
  >
    <Refresh fontSize="small" />
  </IconButton>
);

PosActionReLoad.propTypes = {
  handleOnClick: PropTypes.func.isRequired,
};

export default PosActionReLoad;
