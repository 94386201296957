/* eslint-disable import/no-cycle */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Pagination from '../pagination';
import { fetchAllProducts } from '../../../redux/actions/productActions';

const ProductPaginator = () => {
  const [pageLimit, setpageLimit] = React.useState(0);
  const dispatch = useDispatch();
  const loads = useSelector(state => state.loads);
  const products = useSelector(state => state.product);
  const {
    totalProducts, currentPage, allPages, productsByArray,
  } = products;
  if (pageLimit < productsByArray.length) {
    setpageLimit(productsByArray.length);
  }
  const onPageChanged = (paginator) => {
    const searchValue = document.getElementById('search_bar_query')?.value;
    if (currentPage !== paginator.currentPage) {
      dispatch(fetchAllProducts('', paginator.currentPage, searchValue));
    }
  };

  return (
    <div className="paginator-container">
      {loads.productIsLoading && (
      <div className="loading-bg" />
      )}
      <div className="w-100 d-flex flex-row flex-wrap align-items-center justify-content-between">
        {
          totalProducts !== 0 && (
            <>
              <div className="d-flex flex-row align-items-center">
                <h4>
                  <strong className="text-secondary">{totalProducts}</strong>
                  {' '}
                  Productos
                </h4>
                {currentPage && (
                <span className="current-page d-inline-block h-100 pl-4 text-secondary">
                  Página
                  {' '}
                  <span className="font-weight-bold">{currentPage}</span>
                  /
                  {' '}
                  <span className="font-weight-bold">{allPages || 1}</span>
                </span>
                )}
              </div>
              <div className="d-flex flex-row align-items-center">
                {
                      totalProducts && (
                        <Pagination totalRecords={totalProducts} pageLimit={pageLimit} pageNeighbours={1} onPageChanged={onPageChanged} actualPage={currentPage} />
                      )
                    }
              </div>
            </>
          )
        }
      </div>
    </div>
  );
};

export default ProductPaginator;
