/* eslint-disable no-unused-expressions */
/* eslint-disable radix */
/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  CircularProgress,
} from '@material-ui/core';
import CCTipo from './components/tipo';
import FullDialog from '../../../FullDialog';
import BalanceDatePicker from '../BalanceDatePicker';
import Efectivo from './components/efectivo';
import { element } from 'prop-types';

const CC = ({
  corteDeCaja,
  isLoading,
  isOpen,
  onClose,
  reloadCC,
  onSave,
}) => {
  /* State */
  const now = moment();
  const [dateI, setDateI] = React.useState(
    now
      .clone()
      .startOf('year')
      .format('YYYY-MM-DD'),
  );
  const [dateO, setDateO] = React.useState(
    now
      .clone()
      .format('YYYY-MM-DD'),
  );
  const [elements, setElements] = React.useState([]);
  const [soloEfectivo, setSoloEfectivo] = React.useState([]);
  const [isChangeCash, setIsChangeCash] = React.useState(false);
  /* Functions */
  const setInitElements = (input) => {
    setSoloEfectivo([]);
    let arr = [...input];
    arr = arr.map((type) => {
      const el = { ...type };
      el.enable = true;
      el.id = `id_${el.label}`;
      el.type = 'tipo';
      el.movimientos = type.movimientos ? type.movimientos.map((method) => {
        const _method = { ...method };
        _method.enable = true;
        _method.id = `id_${el.label}_${method.label}`;
        _method.type = 'metodo';
        _method.detalles = method.detalles.map((detail) => {
          const _detail = { ...detail };
          _detail.enable = true;
          _detail.type = 'detalle';
          return _detail;
        });
        return _method;
      }) : type.documentos.map((method) => {
        const _method = { ...method };
        _method.enable = true;
        _method.id = `id_${el.label}_${method.id}`;
        _method.type = 'docs';
        _method.detalles = [];
        return _method;
      });
      return el;
    });
    setElements(arr);
    const soloEfe = [];
    const efectivo = arr.map((corte) => {
      const mov = corte.movimientos;
      mov.map((m) => {
        const { detalles } = m;
        const ef = detalles?.find(d => d.mantener_efe === true);
        if (ef) {
          soloEfe.push(ef);
        }
      });
    });
    if (soloEfe.length) {
      setSoloEfectivo(soloEfe);
    }
  };

  const checkTipo = (id) => {
    let arr = [];
    arr = elements.map((x) => {
      const el = { ...x };
      if (el.id === id) {
        const flag = !x.enable;
        el.enable = flag;
        el.movimientos = x.movimientos.map((mov) => {
          const movimiento = { ...mov };
          movimiento.enable = flag;
          movimiento.detalles = mov.detalles.map((det) => {
            const detail = { ...det };
            detail.enable = flag;
            return detail;
          });
          return movimiento;
        });
      }
      return el;
    });
    setElements(arr);
  };

  const checkMovimiento = (id) => {
    let arr = [];
    arr = elements.map((x) => {
      const el = { ...x };
      el.enable = true;
      el.movimientos = x.movimientos.map((mov) => {
        const movimiento = { ...mov };
        if (movimiento.id === id) {
          const flag = !mov.enable;
          movimiento.enable = flag;
          movimiento.detalles = mov.detalles.map((det) => {
            const detail = { ...det };
            detail.enable = flag;
            return detail;
          });
        }
        return movimiento;
      });
      return el;
    });
    setElements(arr);
  };

  const checkDocs = (id) => {
    let arr = [];
    arr = elements.map((x) => {
      const el = { ...x };
      el.enable = true;
      el.movimientos = x.movimientos.map((mov) => {
        const movimiento = { ...mov };
        if (movimiento.id === id) {
          const flag = !mov.enable;
          movimiento.enable = flag;
        }
        return movimiento;
      });
      return el;
    });
    setElements(arr);
  };

  const checkDetalle = (id) => {
    let arr = [];
    arr = elements.map((x) => {
      const el = { ...x };
      el.movimientos = x.movimientos.map((mov) => {
        const movimiento = { ...mov };
        movimiento.detalles = mov.detalles.map((det) => {
          const detail = { ...det };
          if (detail.id === parseInt(id)) {
            const flag = !det.enable;
            detail.enable = flag;
          }
          return detail;
        });
        if (movimiento.detalles.every(det => det.enable === false)) {
          movimiento.enable = false;
        } else {
          movimiento.enable = true;
        }
        return movimiento;
      });
      if (el.movimientos.every(mov => mov.enable === false)) {
        el.enable = false;
      } else {
        el.enable = true;
      }
      return el;
    });
    setElements(arr);
  };

  const onClickCheck = (target) => {
    const { kind, id } = target.dataset;
    console.log('onClickCheck', kind, id);
    switch (kind) {
      case 'tipo':
        checkTipo(id);
        break;
      case 'metodo':
        checkMovimiento(id);
        break;
      case 'detalle':
        checkDetalle(id);
        break;
      case 'docs':
        checkDocs(id);
        break;
      default:
        break;
    }
  };

  const changeDate = (date, id) => {
    if (id === 'dateI') {
      setDateI(date);
    } else {
      setDateO(date);
    }
  };

  const onSaveCC = (_elements) => {
    const els = [..._elements];
    let selectedCheckboxes = [];
    let unselectedCheckboxes = [];
    els.forEach((el) => {
      el.movimientos.forEach((mov) => {
        let order = [];
        let unOrder = [];

        if (el.label === 'DOCS') {
          const id = mov.id.split('_').pop();
          const parseId = parseInt(id);
          mov.enable ? selectedCheckboxes.push(parseId) : unselectedCheckboxes.push(parseId);
        } else {
          order = mov.detalles.filter(det => det.enable === true).map(x => x.id);
          unOrder = mov.detalles.filter(det => det.enable === false).map(x => x.id);
        }

        selectedCheckboxes = [...selectedCheckboxes, ...order];
        unselectedCheckboxes = [...unselectedCheckboxes, ...unOrder];
      });
    });

    const sendObject = {
      selectedCheckboxes,
      unselectedCheckboxes,
      startDate: dateI,
      endDate: dateO,
    };

    soloEfectivo.map((efectivo) => {
      sendObject.selectedCheckboxes = sendObject.selectedCheckboxes.filter(s => s !== efectivo.id);
      sendObject.unselectedCheckboxes = sendObject.unselectedCheckboxes.filter(s => s !== efectivo.id);

      sendObject[`tfde_solefe_${efectivo.id}`] = Math.abs(efectivo.monto);
    });
    console.log('sendObject', sendObject);

    onSave({
      ...sendObject,
    });
  };

  const findItem = (item) => {
    let corteIndex;
    let bancoIndex;
    let detalleIndex;

    elements.map((corte, index) => {
      const mov = corte.movimientos;
      const movIndex = mov.findIndex(b => b.bancoid == item.id);
      if (movIndex !== -1) {
        corteIndex = index;
        bancoIndex = movIndex;
        const detalle = corte.movimientos[bancoIndex].detalles.findIndex(d => d.id == item.id);
        if (detalle !== -1) {
          detalleIndex = detalle;
        }
      }
    });
    const changeElements = elements;
    changeElements[corteIndex].movimientos[bancoIndex].detalles[detalleIndex] = item;
    setInitElements(changeElements);
  };

  const changeCash = (item) => {
    findItem(item);
  };

  const calculateMaxValue = (item) => {
    let maxValue = 0;
    elements.map(flujo => {
      flujo.movimientos.map(mov => {
        mov.detalles.map(d => {
          if (d.id === item.id) {
            maxValue = mov.total + item.monto;
            return maxValue;
          }
        });
      });
    });
    return maxValue;
  };

  /* Effect */
  React.useEffect(() => {
    if (!isLoading) {
      setInitElements(corteDeCaja);
    }
  }, [isLoading, corteDeCaja]);

  return (
    <FullDialog
      title="Solicitar un Corte de Caja"
      isOpen={isOpen}
      isLoading={isLoading}
      onClose={onClose}
      onSave={() => onSaveCC(elements)}
      enableAction={!isLoading}
    >
      {!isLoading ? (
        <div className="balance__contenedor">
          <BalanceDatePicker
            dateI={dateI}
            dateO={dateO}
            handleDateChange={changeDate}
            handleOnClick={() => {
              const range = `${dateI} to ${dateO}`;
              reloadCC(range);
            }}
          />
          {
            elements.length > 0 && (
              <div style={{ maxWidth: 950, width: '100%', margin: '0px auto' }}>
                {
                  soloEfectivo.map(e => (
                    <Efectivo item={e} changeCash={changeCash} maxValue={calculateMaxValue(e)} />
                  ))
                }
              </div>
            )
          }

          <CCTipo
            elements={elements}
            click={onClickCheck}
          />

        </div>
      ) : (
        <div className="balance__contenedor">
          <CircularProgress />
        </div>
      )}
    </FullDialog>
  );
};

CC.propTypes = {
  corteDeCaja: PropTypes.array,
  isLoading: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  reloadCC: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

CC.defaultProps = {
  corteDeCaja: [],
};

export default CC;
