/* eslint-disable no-nested-ternary */
/* eslint-disable array-callback-return */
/* eslint-disable import/no-cycle */
import React, { useEffect, useState } from 'react';
import {
  Avatar,
  Collapse,
  Divider,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Typography,
  withStyles,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { toast } from 'react-toastify';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ProductAPI from '../../../../api/ProductAPI';
import { formatString, createOrderRef } from '../../../../helpers/tools';
import ListMdaItem from '../../../Mda/components/ListMda/ListMdaItem';

import '../../deliver/OrderDeliver/deliver.styles.scss';
import MdaAPI from '../../../../api/MdaApi';
import OrderMda from '../../OrderMda';
import FullDialog from '../../../FullDialog';
import ReceiverOrderProducts from '../ReceiverOrderProducts';

const ExpansionPanell = withStyles({
  expanded: {
    margin: 'auto',
  },
})(ExpansionPanel);

const Receiver = () => {
  const history = useHistory();
  const params = useParams();
  const authUser = useSelector(state => state.authUser);
  const [open, setOpen] = useState(false);

  const [isDeliver, setIsDeliver] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingMda, setLoadingMda] = useState(false);
  const [products, setProducts] = useState([]);
  const [stock, setStock] = useState([]);
  const [entity, setEntity] = useState({});
  const [order, setOrder] = useState({});
  const [mda, setMda] = useState([]);

  const productApi = new ProductAPI();
  const mdaApi = new MdaAPI();
  const {
    user: {
      config: { store, id },
    },
  } = authUser;

  const move = 'EP';
  const ref = `${move}-${createOrderRef()}-${id || '-1'}`;

  const orderDeliverDetails = async () => {
    setLoading(true);
    const response = await productApi.getOrderDetail(
      params.orderId,
      params.orderId,
      store.crmid,
      'EP',
    );

    if (response.success === false) {
      toast.error('Error cargando la orden');
      setLoading(false);

      return;
    }

    if (response.PRODUCTOS.length) {
      const formatProducts = [];
      response.PRODUCTOS.map((prod, index) => {
        formatProducts.push({
          ...prod,
          cantidad: parseFloat(prod.cantidad),
          por_recibir: 0,
          in_stock: response.stock[index].qtyinstock,
          lotesSeries: [],
        });
      });
      setProducts(formatProducts);
    }

    if (Object.keys(response.EMPRESA).length) {
      setEntity(response.EMPRESA);
    }

    if (response.DATOS_PO && Object.keys(response.DATOS_PO).length) {
      setOrder({ ...response.DATOS_PO, crmid: response.CRMID });
    }

    if (response.stock.length) {
      setStock(response.stock);
    }

    setLoading(false);
  };

  const orderMda = async () => {
    setLoadingMda(true);
    const response = await mdaApi.get(params.orderId);
    const { success, result } = response;

    if (success && result.length) {
      setMda(result);
      setLoadingMda(false);
    }

    if (!success) {
      toast.error('Ocurrio un error obteniendo los movimientos de almacen');
    }
    setLoadingMda(false);
  };

  const rest = (prodId) => {
    const prods = products;
    const prodIndex = prods.findIndex(p => p.id === prodId);
    prods[prodIndex].por_recibir -= 1;
    setProducts([...prods]);
  };

  const add = (prodId) => {
    const prods = products;
    const prodIndex = prods.findIndex(p => p.id === prodId);
    prods[prodIndex].por_recibir += 1;

    setProducts([...prods]);
  };

  const selectAll = () => {
    const prods = products;
    prods.map((prod, index) => {
      prods[index].por_recibir = parseFloat(prod['por recibir']);
    });

    setProducts([...prods]);
  };

  const selectNone = () => {
    const prods = products;
    prods.map((prod, index) => {
      prods[index].por_recibir = 0;
    });

    setProducts([...prods]);
  };

  const changeInput = (value, prodId) => {
    const prods = products;
    const prodIndex = prods.findIndex(p => p.id === prodId);

    if (
      parseFloat(value) > prods[prodIndex]['por recibir']
      || parseFloat(value < 1)
    ) {
      setProducts([...prods]);
      toast.warn(
        `El valor no puede ser mayor que ${parseFloat(
          prods[prodIndex]['por recibir'],
        )} o menor que 0`,
      );

      return;
    }

    prods[prodIndex].por_recibir = value;

    setProducts([...prods]);
  };

  const receiverOrder = async () => {
    setIsDeliver(true);
    const response = await mdaApi.receiver(
      products,
      ref,
      store.crmid,
      order.crmid,
      entity.id,
      new Date(),
      '',
      'EP',
    );

    if (response.message.success) {
      toast.success(response.message.success);
    }
    if (response.message.error) {
      toast.error(response.message.error);
    }
    setIsDeliver(false);
    history.goBack();
  };

  const updateBarCode = (item, barCode) => {
    const prods = [...products];
    const prodIndex = prods.findIndex(p => p.id === item.id);
    prods[prodIndex].codigo_barras = barCode;
    setProducts([...prods]);
  };

  const updatePorRecibir = (item) => {
    const updateditem = { ...item, por_recibir: 0 };
    if (item.lotesSeries.length) {
      item.lotesSeries.map((l) => {
        updateditem.por_recibir += l.qty;
      });
    }

    return updateditem;
  };

  const updateProduct = (item) => {
    const prods = [...products];
    const prodIndex = prods.findIndex(p => p.id === item.id);
    prods[prodIndex] = updatePorRecibir(item);
    setProducts([...prods]);
  };


  useEffect(() => {
    if (params.orderId && store.crmid) {
      orderDeliverDetails();
      orderMda();
    }
  }, []);

  return (
    <FullDialog
      title={`Recibir orden ${order?.purchaseorder_no || ''}`}
      isOpen
      isLoading={isDeliver}
      onClose={() => {
        history.goBack();
        localStorage.setItem('selectedInput', '');
        localStorage.setItem('scanedProductId', '');
      }}
      onSave={receiverOrder}
      enableAction={!loading && !loadingMda && !isDeliver && products.length > 0}
    >
      <div className="deliver" style={{ display: 'flex', flexDirection: 'column' }}>
        <div>
          <Collapse in={Boolean(!loading)} className="w-100">
            <Paper className="deliver__client">
              {Object.keys(order).length > 0 && (
                <ListMdaItem
                  item={{
                    createdtime: order.f_compra,
                    entity_label: ref,
                    de_documentoid_label: order?.purchaseorder_no,
                    a_documentoid_label: store.name,
                  }}
                  isCollapse={false}
                  sc="Entrega"
                />
              )}
              <Divider />
              {Object.keys(entity).length > 0 && (
                <List className="w-100">
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar>{entity.nombre.substring(0, 1)}</Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={formatString(entity.nombre)}
                      secondary="Cliente"
                    />
                  </ListItem>
                </List>
              )}
            </Paper>
          </Collapse>
        </div>
        <ReceiverOrderProducts
          stock={stock}
          products={products}
          changeInput={changeInput}
          rest={rest}
          add={add}
          loading={loading}
          selectAll={selectAll}
          selectNone={selectNone}
          updateBarCode={updateBarCode}
          updateProduct={updateProduct}
          receiverOrder={receiverOrder}
        />
        <div style={{ with: '100%' }}>
          <ExpansionPanell
            classes={{ expanded: { margin: 'auto', with: '100%' } }}
            square
            expanded={open}
          >
            <ExpansionPanelSummary
              expandIcon={
                <ExpandMoreIcon onClick={() => setOpen(!open)} />
                  }
            >
              <Typography>Movimientos de almacén</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <div style={{ width: '100%' }}>
                <OrderMda mda={mda} loading={loadingMda} />
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanell>
        </div>
      </div>
    </FullDialog>
  );
};

export default Receiver;
