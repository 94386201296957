/* eslint-disable no-unused-vars */
/* eslint-disable import/no-cycle */
/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';

import {
  Tooltip,
  IconButton,
} from '@material-ui/core';
import {
  Edit,
  Build,
} from '@material-ui/icons';
import CheckOut from '../../CheckOut';

/* Controlles */
import ActionControls from './controls/ActionControls';
import ReleatedControls from './controls/ReleatedControls';
import CustomTooltip from '../../rkr2-components/custom-tooltip/custom-tooltip';

const CartStateControls = ({
  alertProducts,
  authUser,
  cart,
  customer,
  dispatchAdjustStock,
  dispatchSetOrderCustomer,
  dispatchChangeSalesOrderStatus,
  dispatchCloseCheckOutModal,
  dispatchCreateSell,
  dispatchOpenCheckOutModal,
  editOrder,
  enableEdit,
  handleEditOrder,
  handleMakeBill,
  isCotizacion,
  isOrder,
  loads,
  makeBill,
  modals,
  orderNo,
  posCheckoutBill,
  tabs,
}) => {
  const { orderSelected } = cart;
  const {
    pos_botoncaja, sostatus, pos_no_editar_so, pos_allowed_sostatus,
  } = authUser.user.config;
  const allowOrderStatus = pos_allowed_sostatus.split(' |##| ');
  const paymentTypes = authUser.user.metodos_pagos;
  const orderStatuses = authUser.user.sostatus;
  const mostrarCobrar = Boolean(pos_botoncaja === 'Cobrar');
  const mostrarCotizar = Boolean(pos_botoncaja === 'Cotizar');
  const isCotización = Boolean(tabs.posTypeTab === 'Cotizaciones');
  const existOrder = Object.keys(orderSelected).length > 0;
  const soState = Boolean(existOrder && orderSelected.order.sostatus !== null && (isCotización ? true : allowOrderStatus.includes(orderSelected.order.sostatus)));
  /* const soState = Boolean(existOrder && orderSelected.order.sostatus !== null && (orderSelected.order.sostatus === 'Creado' || orderSelected.order.sostatus === 'Created')); */
  const soIsCancelled = Boolean(existOrder && orderSelected.order.sostatus !== null && orderSelected.order.sostatus === 'Cancelado');
  const mdaState = Boolean(existOrder && orderSelected.order.estado_mda !== null && (orderSelected.order.estado_mda === 'Sin Entregar' || orderSelected.order.estado_mda === 'N/A' || orderSelected.order.estado_mda === 'Sin Recibir'));
  /* const fdeState = Boolean(existOrder && orderSelected.order.estado_fde !== null && orderSelected.order.estado_fde === 'Sin Pago/Cobro'); */
  const satState = Boolean(existOrder && (orderSelected.order.estadosat === null || orderSelected.estadosat === 'Cancelado' || orderSelected.estadosat === 'Sin Factura'));
  const isDisableEdition = pos_no_editar_so === '1' ? true : !((isOrder && enableEdit) || (isCotizacion && orderNo));

  return (
    <div className="cart_controls d-flex align-item-center justify-content-end">
      {alertProducts.length > 0 && editOrder && (
        <Tooltip title="Ajustar Existencias">
          <IconButton onClick={() => dispatchAdjustStock()}>
            <Build />
          </IconButton>
        </Tooltip>
      )}

      <div className="vertical_divider" />
      {
        tabs.posTypeTab !== 'Vender' && (
          <ReleatedControls />
        )
      }

      {/* <CustomTooltip
        text={isCotización ? 'Editar Cotización' : (
          existOrder ? (
            soIsCancelled ? ('Orden cancelada') : (
              !soState ? ('Para editar la orden debe tener el estatus: Creado') : (
                !mdaState ? ('Para editar la orden es necesario que el estatus de entrega sea igual a Sin Entregar') : (
                  !satState ? ('Para editar la orden es necesario que la orden no tenga facturas') : (
                    'Editar Orden'
                  )
                )
              )
            )
          ) : ('Editar solo habilitado en una orden o cotización previamente seleccionada')
        )}
        aria-label="edit"
        placement="right"
      > */}
      <div
        style={{ width: '50px' }}
        className="d-flex justify-content-end align-items-center"
      >
        <IconButton
          color={editOrder ? 'primary' : 'inherit'}
          onClick={() => handleEditOrder()}
          disabled={isDisableEdition}
        >
          <Edit color={editOrder ? 'primary' : 'inherit'} />
        </IconButton>
      </div>
      {/* </CustomTooltip> */}

      <ActionControls />
    </div>
  );
};

CartStateControls.propTypes = {
  alertProducts: PropTypes.array.isRequired,
  authUser: PropTypes.object.isRequired,
  cart: PropTypes.object.isRequired,
  customer: PropTypes.object.isRequired,
  dispatchAdjustStock: PropTypes.func.isRequired,
  dispatchChangeSalesOrderStatus: PropTypes.func.isRequired,
  dispatchCloseCheckOutModal: PropTypes.func.isRequired,
  dispatchCreateSell: PropTypes.func.isRequired,
  dispatchSetOrderCustomer: PropTypes.func.isRequired,
  dispatchOpenCheckOutModal: PropTypes.func.isRequired,
  editOrder: PropTypes.bool.isRequired,
  enableEdit: PropTypes.bool.isRequired,
  handleEditOrder: PropTypes.func.isRequired,
  handleMakeBill: PropTypes.func.isRequired,
  isCotizacion: PropTypes.bool.isRequired,
  isOrder: PropTypes.bool.isRequired,
  loads: PropTypes.object.isRequired,
  makeBill: PropTypes.bool.isRequired,
  modals: PropTypes.object.isRequired,
  orderNo: PropTypes.string.isRequired,
  posCheckoutBill: PropTypes.string.isRequired,
  tabs: PropTypes.object.isRequired,
};

export default CartStateControls;
