/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import { Search } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';
/* import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSlidersH,
} from '@fortawesome/free-solid-svg-icons'; */
import { fetchAllClients } from '../../../../../redux/actions/clientActions';

let timeout = null;

const SearchFab = (props) => {
  const { dispatch } = props;
  const inputRef = React.createRef();
  const [toggle, setToggle] = React.useState(false);
  const [search, setSearch] = React.useState('');

  const slickSearchIcon = () => {
    if (!toggle) inputRef.current.focus();
    setToggle(!toggle);
  };

  const handleChangeSearch = (event) => {
    setSearch(event.target.value);
  };

  /* const onClickVerticalIcon = (event) => {
    history.push('clients/configuration');
  }; */

  const searchClient = () => {
    dispatch(fetchAllClients(1, search));
  };

  const classAppear = 'searchFab__circle d-flex align-items-center justify-content-start';

  return (
    <div className="searchFab d-flex align-items-center justify-content-between w-100 h-100">

      <div className={toggle ? `${classAppear} extended` : classAppear}>
        <IconButton onClick={() => { slickSearchIcon(); }}>
          <Search id="actions_search_tag" />
        </IconButton>
        <input
          id="mobile_input_search"
          autoComplete="false"
          ref={inputRef}
          type="text"
          value={search}
          placeholder="Nombre del cliente"
          onFocus={() => { setToggle(true); }}
          onChange={(event) => { handleChangeSearch(event); }}
          onBlur={() => { slickSearchIcon(); }}
          onKeyUp={(e) => {
            clearTimeout(timeout);
            if (e.keyCode !== 13) {
              timeout = setTimeout(() => {
                searchClient();
              }, 750);
            } else {
              searchClient();
            }
          }}
        />
        {/* <FontAwesomeIcon style={{ fontSize: 26 }} icon={faSlidersH} /> */}
        {/* <IconButton onClick={() => { onClickVerticalIcon(); }}>
          <MoreVert />
        </IconButton> */}
      </div>
    </div>
  );
};

SearchFab.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

export default SearchFab;
