/* eslint-disable import/no-cycle */
/* eslint-disable camelcase */
/* eslint-disable import/no-named-as-default */
import {
  faDolly,
  faFileInvoice,
  faHandHoldingUsd,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Menu, MenuItem, Tooltip } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import {
  getColorStatusFDE,
  getColorStatusMDA,
  getColorStatusQuote,
  getColorStatusSAT,
  geteColorSosStatus,
} from '../../../../helpers/tools';
import IconWidthBG from '../../../Icons/IconWidthBG/IconWidthBG';
import SatLogo from '../../../Icons/SatLogo';
import { changeOrderStatus } from '../../../../redux/actions/orderActions';

const OrderStatusBlock = ({ order={}, searchPagination, tab }) => {
  const dispatch = useDispatch();
  const loads = useSelector(state => state.loads);
  const [anchorStatusMenu, setStatusMenu] = React.useState(null);
  const anchorStatusMenuIsOpen = Boolean(anchorStatusMenu);
  const {
    user: { sostatus: orderStatus, quotestage },
  } = useSelector(state => state.authUser);
  const {
    estadosat, estado_fde, estado_mda, sostatus,
  } = order;
  const isBilled = Boolean(
    estadosat && estadosat !== 'Sin Factura' && estadosat !== 'No Encontrado',
  );
  const isLoading = Boolean(loads.orderIsLoading);

  const updateOrderStatus = async (status) => {
    // debugger;
    setStatusMenu(null);
    // await dispatch(selectOrder(order.crmid));
    await dispatch(changeOrderStatus(status.id, true, order));
    setTimeout(() => {
      toast.success('Actualizando listado');
      searchPagination();
    }, 500);
  };

  return (
    <div className="d-flex">
      {(sostatus || order.quotestage) && (
        <>
          <IconWidthBG
            primaryColor={tab === 'Cotizaciones' ? getColorStatusQuote(order.quotestage) : geteColorSosStatus(sostatus)}
            secondaryColor="white"
            width={27}
            height={27}
            classes="ml-2"
            enabled={!isBilled && !isLoading}
            square
            rounded
            actionClick={(e) => {
              const { currentTarget } = e;
              setStatusMenu(currentTarget);
            }}
          >
            <Tooltip title={sostatus || order.quotestage}>
              <FontAwesomeIcon style={{ fontSize: 15 }} icon={faFileInvoice} />
            </Tooltip>
          </IconWidthBG>

          <Menu
            id="simple-menu"
            anchorEl={anchorStatusMenu}
            open={Boolean(anchorStatusMenuIsOpen)}
            onClose={() => {
              setStatusMenu(null);
            }}
          >
            {
              tab === 'Historico' && (
                orderStatus.map(status => (
                  <MenuItem onClick={() => updateOrderStatus(status)}>
                    {status.value}
                  </MenuItem>
                ))
              )
            }
            {
              tab === 'Cotizaciones' && (
                Object.entries(quotestage).map(status => (
                  <MenuItem onClick={() => updateOrderStatus({ id: status[0], value: status[1] })}>
                    {status[1]}
                  </MenuItem>
                ))
              )
            }
          </Menu>
        </>
      )}

      {estado_fde && (
        <IconWidthBG
          primaryColor={getColorStatusFDE(estado_fde)}
          secondaryColor="white"
          width={27}
          height={27}
          classes="ml-2"
          enabled
          square
          rounded
          actionClick={() => {}}
        >
          <Tooltip title={estado_fde}>
            <FontAwesomeIcon style={{ fontSize: 15 }} icon={faHandHoldingUsd} />
          </Tooltip>
        </IconWidthBG>
      )}

      {estado_mda && (
        <IconWidthBG
          primaryColor={getColorStatusMDA(estado_mda)}
          secondaryColor="white"
          width={27}
          height={27}
          classes="ml-2"
          enabled
          square
          rounded
          actionClick={() => {}}
        >
          <Tooltip title={estado_mda}>
            <FontAwesomeIcon style={{ fontSize: 15 }} icon={faDolly} />
          </Tooltip>
        </IconWidthBG>
      )}

      {estadosat && (
        <IconWidthBG
          primaryColor={getColorStatusSAT(estadosat)}
          secondaryColor="white"
          width={27}
          height={27}
          classes="ml-2"
          enabled
          square
          rounded
          actionClick={() => {}}
        >
          <Tooltip title={estadosat}>
            <SatLogo
              fill={`${estadosat == null ? '#B6BEC6' : 'white'}`}
              width="18px"
              height="18px"
            />
          </Tooltip>
        </IconWidthBG>
      )}
    </div>
  );
};

OrderStatusBlock.propTypes = {
  order: PropTypes.object.isRequired,
  searchPagination: PropTypes.func.isRequired,
  tab: PropTypes.string.isRequired,
};

export default OrderStatusBlock;
