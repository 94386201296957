import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import WhatsapIcon from '../../../../../../../components/Icons/WhatsappIcon';
import EmptyContact from './emptyContact';

const Whatsap = (contact) => {
  const {
    phone, mobile, firstname, lastname, crmid,
  } = contact.contact;

  return (
    <>
      {
        <>
          {phone ? (
            <a href={`whatsapp://send?text=&phone=${phone}`} key={crmid} target="_blank" rel="noopener noreferrer">
              <ListItem button classes={{ root: 'py-0 pl-0' }}>
                <ListItemAvatar>
                  <Avatar variant="square" className="modal-avatar">
                    {
                      <WhatsapIcon fill="white" width="26px" height="26px" />
                    }
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={`${firstname} ${lastname}`} secondary={phone} classes={{ primary: 'modal-primary-text', secondary: 'modal-secondary-text' }} />
              </ListItem>
            </a>
          ) : (<EmptyContact action="whatsap" />)}

          {
            mobile ? (
              <a href={`whatsapp://send?text=&phone=${phone}`} key={crmid} target="_blank" rel="noopener noreferrer">
                <ListItem button classes={{ root: 'py-0 pl-0' }}>
                  <ListItemAvatar>
                    <Avatar variant="square" className="modal-avatar">
                      {
                        <WhatsapIcon fill="white" width="26px" height="26px" />
                          }
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={`${firstname} ${lastname}`} secondary={mobile} classes={{ primary: 'modal-primary-text', secondary: 'modal-secondary-text' }} />
                </ListItem>
              </a>
            ) : null
        }
        </>

    }
    </>
  );
};
Whatsap.prototype = {
  contact: PropTypes.object,
};
export default Whatsap;
