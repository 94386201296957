/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-first-prop-new-line */
/* eslint-disable no-unused-expressions */
import { Checkbox } from '@material-ui/core';
import React, { useEffect, useState } from 'react';

const PrintMode = () => {
  const [value, setvalue] = useState(false);
  useEffect(() => {
    localStorage.getItem('printMode') === 'true' ? setvalue(true) : setvalue(false);
  }, []);

  return (
    <div>
      <Checkbox
        checked={value}
        onChange={() => {
          localStorage.setItem('printMode', !value);
          setvalue(!value);
        }}
        value
        color="primary"
      />
      <label htmlFor="check">Activar mobile mode</label>
    </div>
  );
};

export default PrintMode;
