/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Menu, MenuItem, Tooltip } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { IconWidthBG } from '../../Icons/IconWidthBG/IconWidthBG';

const OrderStatus = ({
  statusName, statusColorFn, statusIcon, filterList, setFilterStoreFn,
}) => {
  const dispatch = useDispatch();
  const [headFilter, setHeadFilter] = useState(null);
  const { orderData } = useSelector(state => state.order);
  const { headers } = orderData;
  const [statusSelected, setStatusSelected] = useState([]);
  const [anchorStatusMenu, setStatusMenu] = React.useState(null);
  const anchorStatusMenuIsOpen = Boolean(anchorStatusMenu);

  const setFilters = (value) => {
    let filters = [...filterList];
    if (!value.length) {
      filters = filterList.filter(o => o[0] !== headFilter.name);
      dispatch(setFilterStoreFn(filters));
      return;
    }
    const newSostatusFilter = [headFilter.name, headFilter.comparator, value.join(',')];
    const existFilter = filters.findIndex(f => f[0] === headFilter.name);
    if (existFilter !== -1) {
      const updateFilters = [...filters];
      updateFilters[existFilter] = newSostatusFilter;
      filters = [...updateFilters];
    } else {
      filters.push(newSostatusFilter);
    }
    dispatch(setFilterStoreFn(filters));
  };

  const setSelectedFilter = () => {
    if (headFilter) {
      const findIndex = filterList.findIndex(o => o[0] === headFilter.name);
      if (findIndex !== -1) {
        const filters = [...new Set([...statusSelected, ...filterList[findIndex][2].split(',')])];
        setStatusSelected(filters);
      } else {
        setStatusSelected([]);
      }
    }
  };

  const selectSostatusHeader = () => {
    const index = headers.findIndex(head => head.name === statusName && head.native_type === 'picklist');
    if (index !== -1) {
      setHeadFilter(headers[index]);
    }
  };

  const selectStatus = (status) => {
    if (statusSelected.includes(status.value)) {
      const statusList = statusSelected.filter(f => f !== status.value);
      setStatusSelected(statusList);
      setFilters(statusList);
      return;
    }

    const filters = [...new Set([...statusSelected, status.value])];
    setStatusSelected(filters);
    setFilters(filters);
  };

  const getHeaderValue = (value) => {
    const headerValue = headFilter.picklist_values.find(k => k.value === value);

    return headerValue.label || '';
  };

  const getDefaultMessage = () => {
    let message = '';
    switch (statusName) {
      case 'sostatus':
        message = 'Estado de la orden';
        break;
      case 'estado_fde':
        message = 'Flujo de efectivo';
        break;
      case 'estado_mda':
        message = 'Movimientos almacén';
        break;
      case 'quotestage':
        message = 'Estado de la cotización';
        break;

      default:
        break;
    }

    return message;
  };

  useEffect(() => {
    if (headers.length) {
      selectSostatusHeader();
    }
  }, [headers]);

  useEffect(() => {
    setSelectedFilter();
  }, [headFilter, filterList]);


  return (
    <>
      {headFilter && headFilter.picklist_values && (
        <>
          <div
            className="ml-1"
            style={{
              display: 'flex', flexWrap: 'wrap', width: '30px', cursor: 'pointer',
            }}
            onClick={(e) => {
              const { currentTarget } = e;
              setStatusMenu(currentTarget);
            }}
          >
            {
                statusSelected.length > 0 && statusSelected.map(status => (
                  <IconWidthBG
                    key={status}
                    primaryColor={!status ? '#ebebeb' : statusColorFn(status)}
                    secondaryColor="white"
                    width={15}
                    height={15}
                    square
                    rounded
                  >
                    <Tooltip title={getHeaderValue(status)}>
                      <FontAwesomeIcon style={{ fontSize: 7, color: !status ? 'gray' : 'white' }} icon={statusIcon} />
                    </Tooltip>
                  </IconWidthBG>
                ))
              }
            {
                statusSelected.length === 0 && (
                  <IconWidthBG
                    primaryColor="#ebebeb"
                    secondaryColor="white"
                    width={27}
                    height={27}
                    classes="ml-2 mr-2"
                    square
                    rounded
                  >
                    <Tooltip title={getDefaultMessage()}>
                      <FontAwesomeIcon style={{ fontSize: 15, color: 'white' }} icon={statusIcon} />
                    </Tooltip>
                  </IconWidthBG>
                )
              }
          </div>
          <Menu
            id="simple-menu"
            anchorEl={anchorStatusMenu}
            open={Boolean(anchorStatusMenuIsOpen)}
            onClose={() => {
              setStatusMenu(null);
            }}
          >
            {
              headFilter.picklist_values.length > 0 && (
                headFilter.picklist_values.map(status => !['Sin Recibir', 'Parcialmente Recibido', 'Recibido'].includes(status.label) && (
                  <MenuItem onClick={() => selectStatus(status)} style={statusSelected.includes(status.value) ? { backgroundColor: statusColorFn(status.value), color: 'white' } : {}}>
                    <div className="d-flex">
                      <IconWidthBG
                        key={status}
                        primaryColor={!status.value ? '#ebebeb' : statusColorFn(status.value)}
                        secondaryColor="white"
                        width={27}
                        height={27}
                        classes="mr-2"
                        square
                        rounded
                      >
                        <FontAwesomeIcon style={{ fontSize: 15, color: !status.value ? 'gray' : 'white' }} icon={statusIcon} />
                      </IconWidthBG>
                      <span>{status.label}</span>
                    </div>
                  </MenuItem>
                ))
              )
            }
          </Menu>
        </>
      )}
    </>
  );
};
OrderStatus.propTypes = {
  statusName: PropTypes.string.isRequired,
  statusColorFn: PropTypes.func.isRequired,
  statusIcon: PropTypes.object.isRequired,
  filterList: PropTypes.array.isRequired,
  setFilterStoreFn: PropTypes.func.isRequired,
};

export default OrderStatus;
