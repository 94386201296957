/* eslint-disable jsx-a11y/no-autofocus */
import React from 'react';
import './Money.scss';
import PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const InputMoney = ({
  autoFocus,
  className,
  color,
  icon,
  inputEl,
  number,
  onChange,
  disabled = false,
}) => {
  const [numberChange, setNumberChange] = React.useState(number);
  const [onFocusInput, setOnFocusInput] = React.useState(false);
  let classes = 'input_money';
  if (className !== '') classes += ` ${className}`;

  const moneyMask = createNumberMask({
    prefix: '',
    suffix: '',
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: ',',
    allowDecimal: true,
    decimalSymbol: '.',
    decimalLimit: 2,
    allowNegative: false,
    allowLeadingZeroes: false,
    integerLimit: null,
  });

  function isLetter(c) {
    return c.toLowerCase() !== c.toUpperCase();
  }

  const prevToChange = (e) => {
    let { target: { value } } = e;

    if (!isLetter(value) && !Number.isNaN(value)) {
      value = value.replace(/[$_'_,]/g, '');
      value = Number(value).toString();
      let newValue = (Number.parseFloat(value));
      if (value === '.') newValue = 0.0;
      onChange(newValue);
    }
  };

  React.useEffect(() => {
    setNumberChange(number);
  }, [number]);

  return (
    <div className="input_money_container">
      <MaskedInput
        ref={inputEl}
        autoFocus={autoFocus}
        autoComplete="off"
        value={numberChange}
        className={classes}
        onFocus={(e) => {
        // @ts-ignore
          e.target.select();
          setOnFocusInput(true);
          e.preventDefault();
        }}
        mask={moneyMask}
        type="text"
        name="input_monye"
        id="input_money"
        onBlur={() => setOnFocusInput(false)}
        onChange={prevToChange}
        disabled={disabled}
      />
      <span>
        {typeof (icon) === 'string' ? (
          <FontAwesomeIcon style={{ color: onFocusInput ? '#303c52' : color }} icon={icon} />
        ) : (icon)}
      </span>
    </div>
  );
};

InputMoney.propTypes = {
  autoFocus: PropTypes.bool,
  className: PropTypes.string,
  color: PropTypes.string,
  icon: PropTypes.any,
  number: PropTypes.number,
  onChange: PropTypes.func,
  inputEl: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.object }),
  ]),
};

InputMoney.defaultProps = {
  autoFocus: false,
  className: '',
  color: '#B6BEC6',
  icon: 'dollar-sign',
  inputEl: () => {},
  onChange: () => {},
  number: 0,
};

export default InputMoney;
