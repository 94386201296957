/* eslint-disable no-param-reassign */
/* eslint-disable import/no-cycle */
/* eslint camelcase: 0 */
/* eslint-disable no-underscore-dangle */

import {
  ADD_PRODUCTS,
  FILTER_PRODUCTS,
  FAST_OPTIONS_CLEAN,
  FAST_SEARCH_OPTIONS,
  GET_SUBPRODUCTS_LIST,
  GET_PRODUCTS_BY_PAGE,
  DEFINE_COLUMN_PRODUCT,
  SET_COLUMN_DEF_PRODUCT,
  GET_COLUMN_DEF_PRODUCT,
  SET_MULTIFILTER_PRODUCT,
  RESET_COLUMN_DEF_PRODUCT,
  CONFIG_PRODUCT_COLUMN_DEF,
  CLEAR_MULTIFILTER_PRODUCT,
  UPDATE_PRODUCTS_WITH_CART,
  REMOVE_MULTIFILTER_PRODUCT,
  SET_ALL_PAGES,
  SET_CURRENT_PAGES,
  SET_TOTAL_PRODUCTS,
  ADD_PRODUCTS_ID,
  SET_PRODUCTS_FILTERS,
} from '../actions/productActions';

import { productHelper, storageHelper } from '../../helpers';
import { numberFormat, formatMoney } from '../../helpers/tools';

const totalItemsForPage = 50;
const defaultState = () => ({
  actualPage: 1,
  totalPages: 1,
  totalProducts: 0,
  currentPage: 1,
  allPages: 1,
  products: [],
  productSelected: '',
  productChildrens: [],
  productOptions: [],
  productsByArray: [],
  productsById: {},
  subProducts: [],
  filters: [],
  tableFilter: '',
  categories: [{
    title: 'Todos',
    word: 'general',
    cats: [
      { title: 'ID', column: 'crmid', type: 'general' },
      { title: 'Categorias', column: 'categorias', type: 'general' },
      { title: 'Nombre', column: 'nombre', type: 'general' },
      { title: 'Marca', column: 'marca', type: 'general' },
      { title: 'Precio Unitario', column: 'unit_price', type: 'general' },
    ],
  }],
  columnsDefs: [
    {
      headerName: 'ID',
      children: [
        {
          headerName: 'ID',
          field: 'crmid',
          hide: false,
        },
      ],
    },
    {
      headerName: 'Categorias',
      children: [
        {
          headerName: 'Categoria',
          field: 'categorias',
          width: 150,
          hide: true,
        },
        {
          headerName: 'Marca',
          field: 'marca',
          width: 80,
          hide: true,
        },
        {
          headerName: 'SKU',
          field: 'sku',
          width: 75,
          hide: false,
        },
      ],
    },
    {
      headerName: 'Producto',
      children: [
        {
          headerName: 'Nombre',
          field: 'nombre',
          hide: false,
        },
        {
          headerName: 'Modelo',
          field: 'modelo',
          width: 100,
          hide: true,
        },
        {
          headerName: 'Existencia',
          field: 'existencia',
          width: 100,
          hide: false,
        },
      ],
    },
    {
      headerName: 'Precios',
      children: [
        {
          headerName: 'Precio Unitario',
          field: 'unit_price',
          cellStyle: { textAlign: 'right', paddingRight: '2em' },
          valueGetter: params => (`$ ${formatMoney(params.data.unit_price)}`),
          hide: false,
        },
      ],
    },
    {
      headerName: 'Otros',
      children: [
        {
          headerName: 'Codigos de Barras',
          field: 'codigo_barras',
          width: 60,
          hide: true,
        },
        {
          headerName: 'ISBN',
          field: 'isbn',
          width: 75,
          hide: true,
        },
        {
          headerName: 'EAN',
          field: 'ean',
          width: 75,
          hide: true,
        },
        {
          headerName: 'JAN',
          field: 'jan',
          width: 75,
          hide: true,
        },
        {
          headerName: 'UPC',
          field: 'upc',
          width: 75,
          hide: true,
        },
      ],
    },
  ],
});

const setAllPages = (state, action) => ({ ...state, allPages: action.allPages });

const setTotalProducts = (state, action) => ({ ...state, totalProducts: action.totalProducts });

const setCurrentPage = (state, action) => ({ ...state, currentPage: action.currentPage });

const getProductsByPage = (state, page = 0) => {
  const { productsByArray } = state;
  const index = page > 0 ? page - 1 : page;
  const paginatedProducts = productHelper.paginatedProducts(productsByArray, totalItemsForPage, index);
  return { ...state, ...paginatedProducts };
};

const filterProduct = (state, word) => {
  const { productsByArray } = state;
  const products = word.length < 3 ? productsByArray : productHelper.findProducts(productsByArray, word);
  const paginatedProducts = productHelper.paginatedProducts(products, totalItemsForPage, 0);
  return { ...state, ...paginatedProducts };
};

const addProducts = (state, productsById) => {
  let productsByArray = productHelper.makeProductsArray(productsById);
  // eslint-disable-next-line no-nested-ternary
  productsByArray = productsByArray.sort((a, b) => ((a.sort_order > b.sort_order) ? 1 : ((b.sort_order > a.sort_order) ? -1 : 0)));
  const productChildrens = productHelper.makeProductChildrens(productsByArray);
  const paginatedProducts = productHelper.paginatedProducts(productsByArray, totalItemsForPage, 0);
  return {
    ...state,
    ...paginatedProducts,
    productChildrens,
    productsById,
    productsByArray,
  };
};

const addProductsIds = (state, productsId) => ({
  ...state,
  productsById: { ...state.productsById, ...productsId },
});

const subproductsList = (state, { subProducts, crmid }) => ({
  ...state,
  subProducts,
  productSelected: crmid,
});

/* Refactorizar error mio... e.e */
const updateProductsWithCart = (state, cartProductsById) => {
  const { products, productsById, productsByArray } = state;

  const updatedProducts = products.map((_product) => {
    const product = { ..._product };
    product.Products = productHelper.subtractStock(product.Products, productsById, cartProductsById);
    return product;
  });

  const updateAllProducts = productsByArray.map((_product) => {
    const product = { ..._product };
    const Products = [...product.Products];

    product.Products = Products.map((_option) => {
      const option = { ..._option };
      const { parentId, crmid } = option;

      if (crmid in cartProductsById) {
        option.qtyinstock = numberFormat(cartProductsById[crmid].qtyinstock) - numberFormat(cartProductsById[crmid].quantity);
      }

      else if (parentId in productsById && crmid in productsById[parentId].Products) {
        option.qtyinstock = numberFormat(productsById[parentId].Products[crmid].qtyinstock);
      }

      return option;
    });

    return product;
  });

  return { ...state, products: updatedProducts, productsByArray: updateAllProducts };
};

const setFilter = (state, filters, element) => {
  const array = filters.filter(x => x.type === element.type);
  if (array.length === 0) {
    filters.push(element);
  } else {
    filters.forEach((x, index) => {
      if (x.type === element.type) { filters[index] = element; }
    });
  }
  return { ...state, filters };
};

const removeFilter = (state, filters, word) => {
  const array = filters.filter(x => x.type === word);
  if (array.length !== 0) {
    filters.forEach((filter, i) => {
      if (filter.type === word) filters.splice(i, 1);
    });
  }
  return { ...state, filters };
};

const clearFilter = (state) => {
  const filters = [];
  return { ...state, filters };
};

const setColumnDefs = (state, defs) => {
  let newState;
  if (defs !== {}) {
    newState = { columnsDefs: defs };
  } else {
    const relativeState = defaultState();
    const { columnsDefs } = relativeState;
    newState = { columnsDefs };
  }
  return { ...state, ...newState };
};

const configProductColumnDef = (state, field) => {
  const mainArray = [];
  state.columnsDefs.forEach((main) => {
    const columnDef = [];
    main.children.forEach((def) => {
      const newDef = def;
      if (newDef.field === field) {
        newDef.hide = !(def.hide);
      }
      columnDef.push(newDef);
    });
    const newMain = { ...main, children: columnDef };
    mainArray.push(newMain);
  });
  storageHelper.setItem(CONFIG_PRODUCT_COLUMN_DEF, mainArray, true);
  const newState = { columnsDefs: mainArray };
  return { ...state, ...newState };
};

const resetColumnDefs = (state) => {
  const newState = defaultState();
  return { ...state, ...newState };
};

const getConfigColumns = (state) => {
  const element = storageHelper.findItem('CONFIG_PRODUCT_COLUMN_DEF');
  let newState;
  if (JSON.stringify(element) !== JSON.stringify({})) {
    newState = { columnsDefs: element };
    return { ...state, ...newState };
  }
  return state;
};

const defineColumnsProduct = (state, { config }) => {
  const { columnsDefs } = defaultState();
  const { pos_cols_prods } = config;
  const columns = pos_cols_prods.split(' |##| ');
  const newsColumnsDefs = columnsDefs.map((header) => {
    const { headerName, children } = header;
    const newChildren = children.map((a) => {
      a.hide = true;
      if (columns.some(column => column === a.headerName)) {
        a.hide = false;
      }
      return a;
    });
    const newHeader = {
      headerName,
      children: newChildren,
    };
    return newHeader;
  });
  return { ...state, columnsDefs: newsColumnsDefs };
};

const setProductOptions = (state, { options }) => {
  const productOptions = options;
  return { ...state, productOptions };
};

const fastOptionsClean = state => ({ ...state, productOptions: [] });

export default (state = defaultState(), action) => {
  const { type } = action;
  switch (type) {
    case FILTER_PRODUCTS:
      return filterProduct(state, action.word);
    case GET_PRODUCTS_BY_PAGE:
      return getProductsByPage(state, action.page);
    case ADD_PRODUCTS:
      return addProducts(state, action.products);
    case ADD_PRODUCTS_ID:
      return addProductsIds(state, action.products);
    case UPDATE_PRODUCTS_WITH_CART:
      return updateProductsWithCart(state, action.cartProductsById);
    case GET_SUBPRODUCTS_LIST:
      return subproductsList(state, action);
    case SET_MULTIFILTER_PRODUCT:
      return setFilter(state, action.filters, action.item);
    case REMOVE_MULTIFILTER_PRODUCT:
      return removeFilter(state, action.filters, action.word);
    case CLEAR_MULTIFILTER_PRODUCT:
      return clearFilter(state);
    case SET_COLUMN_DEF_PRODUCT:
      return setColumnDefs(state, action.defs);
    case RESET_COLUMN_DEF_PRODUCT:
      return resetColumnDefs(state);
    case GET_COLUMN_DEF_PRODUCT:
      return getConfigColumns(state);
    case CONFIG_PRODUCT_COLUMN_DEF:
      return configProductColumnDef(state, action.field);
    case DEFINE_COLUMN_PRODUCT:
      return defineColumnsProduct(state, action);
    case FAST_SEARCH_OPTIONS:
      return setProductOptions(state, action);
    case FAST_OPTIONS_CLEAN:
      return fastOptionsClean(state);
    case SET_ALL_PAGES:
      return setAllPages(state, action);
    case SET_CURRENT_PAGES:
      return setCurrentPage(state, action);
    case SET_TOTAL_PRODUCTS:
      return setTotalProducts(state, action);
    case SET_PRODUCTS_FILTERS:
      return {
        ...state,
        tableFilter: action.payload,
      };
    default:
      return state;
  }
};
