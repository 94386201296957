import React from 'react';
import PropTypes from 'prop-types';
import { Tab, Tabs } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const PosActionTabs = ({
  tabs,
  handleTab,
}) => {
  const { posTypeTab } = tabs;
  return (
    <div className={posTypeTab === 'Vender' ? 'pos__center-container pos__tab-contaniner pos__tab-type' : 'pos__tab-contaniner pos__tab-type'}>
      <Tabs
        className="pos_type_tabs"
        data-general-tab={posTypeTab}
        value={posTypeTab || 'Vender'}
        onChange={handleTab}
      >
        <Tab
          data-tab="0"
          label="Caja"
          value="Vender"
          className="prueba"
          icon={<FontAwesomeIcon icon="cash-register" />}
        />
        <Tab
          data-tab="1"
          label="Ventas"
          value="Historico"
          icon={<FontAwesomeIcon icon="file-invoice" />}
        />
        <Tab
          data-tab="2"
          value="Cotizaciones"
          label="Cotizaciones"
          icon={<FontAwesomeIcon icon="file-invoice-dollar" />}
        />
      </Tabs>
    </div>
  );
};

PosActionTabs.propTypes = {
  handleTab: PropTypes.func,
  tabs: PropTypes.object.isRequired,
};
PosActionTabs.defaultProps = {
  handleTab: () => {},
};

export default PosActionTabs;
