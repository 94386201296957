/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable import/no-cycle */
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSlidersH,
} from '@fortawesome/free-solid-svg-icons';
import Filter from '../../../Filter';
import SearchBtn from '../SearBtn';

const SearchTopBar = ({ type }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const makeCategories = (type) => {
    let categories = [
      /* {
        name: 'search',
        values: ['Nombre'],
      }, */ {
        name: 'order',
      },
    ];

    switch (type) {
      case 'proveedor':
        categories[0].values = [];
        break;
      case 'gasto':
        categories[0].values = [];
        break;
      case 'almacen':
        categories[0].values = ['Nombre', 'Almacen', 'Domicilio', 'Email'];
        break;
      case 'task':
        categories = [
          {
            name: 'filtrar',
            values: ['Abierto', 'En progreso', 'Completed', 'Diferido', 'Cancelado', 'Sin filtrar'],
          },
          {
            name: 'ordenar',
            values: ['Mas nueva', 'Mas antigua'],
          },
        ];
        break;
      default:
        break;
    }

    return categories;
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;


  return (
    <div
      className="main_actions_container w-100 d-flex align-items-center px-2 sticky-top"
    >
      <SearchBtn type={type} />
      <FontAwesomeIcon style={{ fontSize: 26 }} icon={faSlidersH} aria-describedby={id} onClick={handleClick} />

      <Filter
        id={id}
        open={open}
        anchorEl={anchorEl}
        close={() => handleClose}
        categories={makeCategories(type)}
        type={type}
      />
    </div>
  ); };

SearchTopBar.propTypes = {};

export default SearchTopBar;
