import fetch from 'cross-fetch';
import ConfigurationAPI from './ConfigurationAPI';

class FinanceAPI extends ConfigurationAPI {
    getListSalesOrder = async (page = 1, search = '', accountId = 0) => {
      try {
        // delete this.credentials.headers.almacen;
        const response = await fetch(`${this.url}/list/SalesOrder?page=${page}&search=${search}&related_to=${accountId}`, this.credentials);
        const json = await response.json();
        return json;
      }

      catch {
        return { success: false, message: 'Error en el Host' };
      }
    };

    getListSalesOrderSummary = async (page = 1, search = '', accountId = 0) => {
      try {
        // delete this.credentials.headers.almacen;
        const response = await fetch(`${this.url}/list/SalesOrder?page=${page}&search=${search}&related_to=${accountId}&ignora_almacen=${1}`, this.credentials);
        const json = await response.json();
        return json;
      }

      catch {
        return { success: false, message: 'Error en el Host' };
      }
    };

    getListQuotes = async (page = 1, search = '', accountId = 0) => {
      try {
        // delete this.credentials.headers.almacen;
        const response = await fetch(`${this.url}/list/Quotes?page=${page}&search=${search}&related_to=${accountId}`, this.credentials);
        const json = await response.json();
        return json;
      }

      catch {
        return { success: false, message: 'Error en el Host' };
      }
    };

    getListTreebesInvoices = async (page = 1, search = '', accountId = 0) => {
      try {
        // delete this.credentials.headers.almacen;
        const response = await fetch(`${this.url}/list/TreebesFacturas?page=${page}&search=${search}&related_to=${accountId}`, this.credentials);
        const json = await response.json();
        return json;
      }

      catch {
        return { success: false, message: 'Error en el Host' };
      }
    };

    getListTreebesTransfersInvoices = async (page = 1, search = '', accountId = 0) => {
      try {
        // delete this.credentials.headers.almacen;
        const response = await fetch(`${this.url}/list/TreebesFT?page=${page}&search=${search}&related_to=${accountId}`, this.credentials);
        const json = await response.json();
        return json;
      }

      catch {
        return { success: false, message: 'Error en el Host' };
      }
    };

    getListTreebesCreditNotes = (page = 1, search = '', accountId = 0) => {
      try {
        // delete this.credentials.headers.almacen;
        const response = fetch(`${this.url}/list/TreebesNdC?page=${page}&search=${search}&related_to=${accountId}`, this.credentials);
        const json = response.json();
        return json;
      }

      catch {
        return { success: false, message: 'Error en el Host' };
      }
    };

    getListTreebesCashFlow = async (page = 1, search = '', accountId = 0) => {
      try {
        // delete this.credentials.headers.almacen;
        const response = await fetch(`${this.url}/list/TreebesFdE?page=${page}&search=${search}&related_to=${accountId}`, this.credentials);
        const json = await response.json();
        return json;
      }

      catch {
        return { success: false, message: 'Error en el Host' };
      }
    };

    getListTreebesMdA = async (page = 1, search = '', accountId = 0) => {
      try {
        // delete this.credentials.headers.almacen;
        const response = await fetch(`${this.url}/list/TreebesMdA?page=${page}&search=${search}&related_to=${accountId}`, this.credentials);
        const json = await response.json();
        return json;
      }

      catch {
        return { success: false, message: 'Error en el Host' };
      }
    };

  getFilteredOrders = async (filters) => {
    try {
      const response = await fetch(`${this.url}/list/SalesOrder/?search_col=${filters}`, this.credentials);
      const json = await response.json();
      return json;
    } catch (err) {
      return { succes: false, result: [], message: `Error en la petición del producto: ${err}` };
    }
  };
}

export default FinanceAPI;
