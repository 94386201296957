/* eslint-disable import/no-cycle */
import { connect } from 'react-redux';
import { openModal } from '../../../../../redux/actions/modalActions';
/* Component */
import ReleatedControls from './ReleatedControls';

const mapStateToProps = state => ({ cart: state.cart });
const mapDispatchToProps = dispatch => ({
  /* Modal */
  dispatchOpenModal: modal => dispatch(openModal(modal)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReleatedControls);
