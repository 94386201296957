/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable no-case-declarations */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import IconButtonInput from '../../../../Input/IconButton';
import CustomerAPI from '../../../../../api/CustomerAPI';
import BsModal from '../../../../rkr2-components/BsModal/BsModal';
import { editCustomer } from '../../../../../redux/actions/customerActions';

const PointOfCard = ({
  handleClose,
}) => {
  const dispatch = useDispatch();
  const customer = useSelector(state => state.customer);
  const { selectedCustomer } = customer;
  const [openBsModal, setOpenBsModal] = useState(false);
  const [cardPoints, setCardPoints] = useState(null);

  const rest = { width: '60px', height: '60px', iconSize: '18px' };

  useEffect(() => {
    if (selectedCustomer && selectedCustomer.point_card_number) {
      setCardPoints(selectedCustomer?.point_card_number);
    }
  }, [selectedCustomer]);

  const updateClient = () => {
    const customerUpdate = { ...selectedCustomer };
    customerUpdate.point_card_number = cardPoints;
    dispatch(editCustomer(customerUpdate));
    setOpenBsModal(false);
    setCardPoints(null);
  };
  return (
    <>
      <IconButtonInput
        icon="trophy"
        enable={selectedCustomer && selectedCustomer.crmid !== '54572'}
        onClick={() => {
          setOpenBsModal(true);
          handleClose();
        }}
        label="Punto Tarjeta"
        tooltip="Punto de Tarjeta"
        {...rest}
      />
      <BsModal title="Punto de tarjeta" isOpen={openBsModal} onClose={() => setOpenBsModal(false)}>
        <form className="d-flex flex-column">
          <label htmlFor="card-num">Nùmero de tarjeta de puntos </label>
          <input
            style={{
              padding: '8px',
              marginBottom: '10px',
              borderRadius: '5px',
              border: '1px solid gray',
            }}
            value={cardPoints}
            type="number"
            name="cardNum"
            id="card-num"
            onChange={e => setCardPoints(e.target.value)}
          />
          <button
            style={{
              padding: '8px 4px',
              border: '1px solid #24a1db',
              borderRadius: '5px',
              backgroundColor: '#24a1db',
              color: 'white',
            }}
            disabled={!cardPoints}
            type="button"
            onClick={() => updateClient()}
          >
            Enviar

          </button>
        </form>
      </BsModal>
    </>
  ); };

PointOfCard.propTypes = {
  handleClose: PropTypes.func,
};
PointOfCard.defaultProps = {
  handleClose: () => {},
};

export default PointOfCard;
