import fetch from 'cross-fetch';
import ConfigurationAPI from './ConfigurationAPI';

class ClientAPI extends ConfigurationAPI {
  getAll = async (page = 1, search = '') => {
    try {
      const response = await fetch(`${this.url}/list/Accounts?page=${page}&search=${search}`, this.credentials);
      const json = await response.json();
      return json;
    }

    catch {
      return { success: false, message: 'Error en el Host' };
    }
  };

  getAccount = async (id) => {
    try {
      const response = await fetch(`${this.url}/Accounts?function=get_account&id=${id}`, this.credentials);
      const json = await response.json();
      return json;
    } catch (e) {
      return { success: false, message: 'Error en el Host' };
    }
  };

  getAccountByName = async (name) => {
    try {
      const response = await fetch(`${this.url}/Accounts?search=${name}`, this.credentials);
      const json = await response.json();
      return json;
    } catch (e) {
      return { success: false, message: 'Error en el Host' };
    }
  }

  getRewardPoints = async (clientId) => {
    try {
      const response = await fetch(`${this.url}/Accounts/${clientId}/PuntosRecompensa`, this.credentials);
      const json = await response.json();
      return json;
    } catch (e) {
      return { success: false, message: 'Error en el Host' };
    }
  }
}

export default ClientAPI;
