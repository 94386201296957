/* eslint-disable radix */
import React from 'react';
import PropTypes from 'prop-types';

// import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  Tooltip,
  IconButton,
  Divider,
} from '@material-ui/core';

import { KeyboardArrowDown } from '@material-ui/icons';

const ListMdaItem = ({
  item,
  isCollapse,
  sc,
  ec,
}) => {
  const [open, setOpen] = React.useState(false);
  const date = new Date(`${item.createdtime} UTC`);
  const day = date.toLocaleDateString('es-ES', { day: 'numeric' });
  const month = date.toLocaleDateString('es-ES', { month: 'long' });
  const clock = date.toLocaleDateString('es-ES', { hour: 'numeric', minute: 'numeric' });
  const dateDisplay = `${day} ${month} ${clock.substring(10, clock.length)}`;
  const title = item?.entity_label?.substring(0, 2) === 'SC' ? sc : ec;
  return (
    <div key={`key_${item.createdtime}`} className="w-100">
      <div className="p-2 w-100 h-auto d-flex flex-column mda_list_el">
        <div className="mda_list_detail">
          <div className="w-100 mda_list_mont">{title}</div>
          <div className="w-100 mda_list_submont">{item.entity_label}</div>
        </div>
        <div className="mda_product_detail">
          <div className="mda_list_date">{dateDisplay}</div>
          <div className="w-100 d-flex align-items-center">
            <div className="mda_fromTo">
              <div className="w-100 d-flex align-items-center">
                <div className="mda_dots_fr" />
                <span className="ml-2 mda_list_acco">{item.de_documentoid_label}</span>
              </div>
              <div className="w-100 d-flex align-items-center">
                <div className="mda_dots_bl" />
                <div className="mda_black" />
              </div>
              <div className="w-100 d-flex align-items-center">
                <div className="mda_dots_to" />
                <span className="ml-2 mda_list_acco">{item.a_documentoid_label}</span>
              </div>
            </div>
          </div>
          {isCollapse && (
            <div className="d-flex align-items-center justify-content-center">
              <IconButton className="mda_drop_button" onClick={() => setOpen(!open)}>
                <KeyboardArrowDown
                  className={open ? 'mda_drop' : 'mda_drop noDropDown'}
                />
              </IconButton>
            </div>
          )}
        </div>
        {isCollapse && (
          <Collapse in={open}>
            <List>
              {item.items.map((x, i) => (
                <ListItem key={`key_${x.crmid}`} className="d-flex flex-column pl-4 py-0">
                  <div className="w-100 d-flex mb-2">
                    <Tooltip
                      placement="left"
                      title={
                      x.referencia.substring(0, 2) === 'SC'
                        ? 'Salida de Almacén'
                        : 'Entrada de Almacén'
                    }
                    >
                      <ListItemIcon className="d-flex align-items-center">
                        {x.referencia.substring(0, 2) === 'SC' ? (
                          <FontAwesomeIcon icon="minus" />
                        ) : (
                          <FontAwesomeIcon icon="plus" />
                        )}
                      </ListItemIcon>
                    </Tooltip>
                    <div className="w-100 h-100 d-flex flex-column">
                      <span className="mda_list_meth">{x.productoid_label}</span>
                      <span className="mda_list_now">
                        {`Movimiento: ${parseInt(x.cantidad)}`}
                      </span>
                    </div>
                  </div>
                  {i < (item.items.length - 1) && (
                    <Divider className="w-100 my-1" />
                  )}
                </ListItem>
              ))}
            </List>
          </Collapse>
        )}
      </div>
    </div>
  );
};

ListMdaItem.propTypes = {
  item: PropTypes.object.isRequired,
  isCollapse: PropTypes.bool,
  sc: PropTypes.string,
  ec: PropTypes.string,
};

ListMdaItem.defaultProps = {
  isCollapse: true,
  sc: 'Venta',
  ec: 'Devolución',
};

export default ListMdaItem;
