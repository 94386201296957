/* eslint-disable jsx-a11y/no-autofocus */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Tooltip,
  IconButton,
} from '@material-ui/core';
import {
  Add,
  Close,
  Remove,
} from '@material-ui/icons';
import { useSelector } from 'react-redux';
import productOptionProps from '../../../../../propTypes/productOptionProps';
import ProductOptionsReloadQty from './ProductOptionsReloadQty';
import { getProductPriceWithTaxes } from '../../../../../helpers/product';
import { formatMoney } from '../../../../../helpers/tools';

let timeout = null;

const ProductOptionMobileBack = ({
  addElement,
  addInStock,
  addSingleProductToCart,
  crmid,
  focusQty,
  getSubProducts,
  handleOnChangeInStock,
  isReloading,
  options,
  removeElement,
  removeInStock,
  select,
}) => {
  const { products } = useSelector(state => state.product);
  const getProductImage = (crmid) => {
    const index = products.findIndex(item => item.crmid === crmid);

    if (index !== -1 && products[index].images.length) {
      return products[index].images[0];
    }

    return `${process.env.PUBLIC_URL}/img/no_image.jpg`;
  };
  React.useEffect(() => {
    console.log('optionssss', options);
  }, [options]);

  return (
    <div className="w-100 h-100 os-y">
      <div className="w-100 d-flex flex-column justify-content-center align-items-center">
        <ProductOptionsReloadQty reload={getSubProducts} loading={isReloading} />
        <div className="w-100 h-auto d-flex flex-column optionsContainerRes">
          {options.map((x, i) => (
            <Tooltip
              key={`optionKey_${x.crmid}`}
              title={crmid.includes(x) ? 'Seleccionado - Click en SUPR para anular la selección' : 'Selecciona con ENTER'}
              placement="left"
              open={Boolean(i === select)}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              PopperProps={{
                disablePortal: true,
              }}
            >
              <div className="py-2 w-100 optionCard">
                <div
                  onTouchStart={async () => {
                    timeout = await setTimeout(() => {
                      if (crmid.includes(x)) {
                        removeElement(x);
                      }
                    }, 750);
                  }}
                  onTouchEnd={() => {
                    clearTimeout(timeout);
                  }}
                  className={
                  crmid.includes(x)
                    ? 'optionCard_main_selected h-100 py-3 px-2'
                    : 'optionCard_main h-100 py-3 px-2'
                }
                >
                  <div
                    tabIndex={i}
                    role="button"
                    className="optionCard_desc w-100"
                    onClick={() => {
                      if (!(crmid.includes(x))) {
                        addElement(x);
                      } else {
                        removeElement(x);
                      }
                    }}
                    onKeyDown={() => {
                      if (!(crmid.includes(x))) {
                        addElement(x);
                      } else {
                        removeElement(x);
                      }
                    }}
                  >
                    <div className="d-flex align-items-center">
                      <div className="mr-2">
                        <img style={{ width: 80, borderRadius: 5, marginRight: 2 }} src={x.image ? x.image : getProductImage(x.parentId)} onError={`this.onerror=null;this.src=${process.env.PUBLIC_URL}/img/no_image.jpg;`} alt="Imagen del producto" />
                      </div>
                      <div>
                        <div className="optionCard_option">
                          <span>{x.productname}</span>
                        </div>
                        <div className="optionCard_price">{`$ ${x.unit_price || x.totalPrice} - Con impuestos: $${formatMoney(getProductPriceWithTaxes(x))}`}</div>
                      </div>
                    </div>
                  </div>
                  {
                  !(crmid.includes(x))
                    ? (
                      <div className="optionCard_qty">
                        <p>{x.qtyinstock}</p>
                        <span>STOCK</span>
                      </div>
                    ) : (
                      <>
                        <div className="optionCard_change">
                          <div
                            className="optionCard_change_btn"
                          >
                            <IconButton
                              onClick={() => {
                                if (x.quantity !== 1) {
                                  removeInStock(x.crmid);
                                } else {
                                  removeElement(x);
                                }
                              }}
                            >
                              {
                                x.quantity !== 1
                                  ? <Remove />
                                  : <Close />
                              }
                            </IconButton>
                          </div>
                          <div className="optionCard_quantity">
                            <span>
                              <input
                                id={`input_qty_${x.crmid}`}
                                type="number"
                                value={x.quantity}
                                onChange={(e) => {
                                  const { value } = e.target;
                                  handleOnChangeInStock(x.crmid, value);
                                }}
                                onFocus={event => event.target.select()}
                                autoFocus
                              />
                            </span>
                          </div>
                          <div
                            className="optionCard_change_btn"
                          >
                            <IconButton
                              disabled={x.quantity === x.qtyinstock}
                              onClick={() => addInStock(x.crmid)}
                            >
                              <Add />
                            </IconButton>
                          </div>
                        </div>
                      </>
                    )
                }
                </div>
                <Button
                  disabled={crmid.length > 0}
                  onClick={async () => {
                    await addSingleProductToCart(x);
                    await focusQty(x);
                  }}
                  onTouchStart={async () => {
                    timeout = await setTimeout(() => {
                      if (!(crmid.includes(x))) {
                        addElement(x);
                      } else {
                        removeElement(x);
                      }
                    }, 750);
                  }}
                  onTouchEnd={() => {
                    clearTimeout(timeout);
                  }}
                  className={
                  crmid.includes(x)
                    ? 'optionCard_add_selected h-100 p-2'
                    : 'optionCard_add h-100 p-2'
                }
                >
                  <Add />
                </Button>
              </div>
            </Tooltip>
          ))}
        </div>
      </div>
    </div>
  ); };

ProductOptionMobileBack.propTypes = {
  addElement: PropTypes.func.isRequired,
  addInStock: PropTypes.func.isRequired,
  addSingleProductToCart: PropTypes.func.isRequired,
  crmid: PropTypes.array.isRequired,
  focusQty: PropTypes.func.isRequired,
  getSubProducts: PropTypes.func.isRequired,
  handleOnChangeInStock: PropTypes.func.isRequired,
  isReloading: PropTypes.bool.isRequired,
  options: productOptionProps.isRequired,
  removeElement: PropTypes.func.isRequired,
  removeInStock: PropTypes.func.isRequired,
  select: PropTypes.number.isRequired,
};

export default ProductOptionMobileBack;
