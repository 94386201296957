/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import {
  Menu,
  MenuItem,
  // Select,
  Collapse,
  Tooltip,
} from '@material-ui/core';

import Flag from '../../Flag';
import { getColorStatusQuote, geteColorSosStatus } from '../../../helpers/tools';

const CartStateOrder = ({
  cart,
  loads,
  posType,
  orderNo,
  orderStatuses,
  currentOrderStatus,
  dispatchSetOrderStatus,
  isCotizacion,
}) => {
  // Init State
  const [anchorStatusMenu, setStatusMenu] = React.useState(null);
  const anchorStatusMenuIsOpen = Boolean(anchorStatusMenu);
  const config = useSelector(state => state.authUser.user.config);
  const { pos_no_editar_so, pos_allowed_sostatus } = config;
  const canChangeStatus = Boolean(pos_no_editar_so === '0');
  const allowedStatuses = pos_allowed_sostatus.split(' |##| ');

  const { orderSelected } = cart;
  // eslint-disable-next-line no-prototype-builtins
  const isQuote = orderSelected.order && orderSelected.order.hasOwnProperty('quote_no');
  const filteredOrderStatuses = isQuote ? orderStatuses : orderStatuses.filter(status => allowedStatuses.includes(status.value));

  const renderStatus = () => {
    if (Object.keys(orderSelected).length !== 0) {
      return orderSelected.order.sostatus;
    }
    const status = orderStatuses.find(estatus => estatus.id === currentOrderStatus);
    return status.value;
  };

  const renderQuoteStatus = () => {
    if (Object.keys(orderSelected).length > 0) {
      return orderSelected.order.quotestage;
    }
    return 'Selecciona una cotización';
  };

  const setBackground = () => {
    if (orderSelected.order && orderSelected.order.quotestage) {
      return getColorStatusQuote(orderSelected?.order?.quotestage);
    }
    const status = renderStatus();
    return geteColorSosStatus(status);
  };

  const setColor = () => {
    switch (renderStatus()) {
      case 'Creado':
        return '#B6BEC6';
      default:
        return '#FFFFFF';
    }
  };

  const setIcon = () => {
    switch (posType) {
      case 'Vender':
        return 'cash-register';
      case 'Cotizaciones':
        return 'file-invoice-dollar';
      default:
        return 'file-invoice';
    }
  };

  const setTitle = () => {
    switch (posType) {
      case 'Vender':
        return 'Realizando venta';
      case 'Cotizaciones':
        return renderQuoteStatus();
      default: {
        return `Estatus: ${renderStatus()}`;
      }
    }
  };

  const isOrder = (orderNo !== '' && orderNo !== undefined);
  const isLoading = Boolean(loads.orderIsLoading);
  const isBilled = Boolean(orderSelected.estadosat && orderSelected.estadosat !== 'Sin Factura' && orderSelected.estadosat !== 'No Encontrado');

  return (
    <div className="cart-state__order w-90 d-flex justify-content-start">
      <Flag
        icon={setIcon()}
        title={setTitle()}
        color={setColor()}
        backgroundColor={setBackground()}
        disabled={isLoading || isBilled || !(Object.keys(orderSelected).length !== 0)}
        onClick={(e) => {
          const { currentTarget } = e;
          setStatusMenu(currentTarget);
        }}
        width={15}
        height={15}
        isLoading={isLoading}
      />
      {
        !isOrder && (
          <Collapse in={isOrder}>
            <Tooltip title={orderNo}>
              <div className="cart-title">{orderNo}</div>
            </Tooltip>
          </Collapse>
        )
      }
      {
        canChangeStatus && (
        <Menu
          id="statusControl"
          anchorEl={anchorStatusMenu}
          open={anchorStatusMenuIsOpen}
          onClose={() => {
            setStatusMenu(null);
          }}
        >
          {
            isQuote
              ? Object.entries(orderStatuses).map(([key, value]) => (
                <MenuItem
                  key={`key_${key}`}
                  dense
                  onClick={async () => {
                    dispatchSetOrderStatus(key);
                    setStatusMenu(null);
                  }}
                >
                  {value}
                </MenuItem>
              ))
              : filteredOrderStatuses.map(status => (
                <MenuItem
                  key={`key_${status.id}`}
                  dense
                  onClick={async () => {
                    dispatchSetOrderStatus(status.id);
                    setStatusMenu(null);
                  }}
                >
                  {status.value}
                </MenuItem>
              ))}
        </Menu>
        )
      }
    </div>
  );
};

CartStateOrder.propTypes = {
  cart: PropTypes.object.isRequired,
  loads: PropTypes.object.isRequired,
  orderNo: PropTypes.string.isRequired,
  posType: PropTypes.string.isRequired,
  isCotizacion: PropTypes.bool.isRequired,
  orderStatuses: PropTypes.array.isRequired,
  currentOrderStatus: PropTypes.string.isRequired,
  dispatchSetOrderStatus: PropTypes.func.isRequired,
};

export default CartStateOrder;
