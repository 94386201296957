import React from 'react';
import PropTypes from 'prop-types';

import { 
  Button,
  Collapse,
  TextField,
} from '@material-ui/core';

import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';

const Comment = ({
  done,
  title,
  label,
  close,
  isOpen,
  button,
  sendDesc,
  labelStill,
  description,
  enableEditOrder,
}) => {
  /* Hooks */
  const [desc, setDesc] = React.useState('');
  const onChange = (e) => {
    const { value } = e.target;
    setDesc(value);
  };
  const handleDesc = () => {
    sendDesc(desc);
  };
  React.useEffect(() => {
    setDesc(description);
  }, [description]);
  /* Render */
  return (
    <Modal
      size="lg"
      isOpen={isOpen}
      toggle={close}
    >
      <ModalHeader toggle={close}>{title}</ModalHeader>
      <Collapse in={enableEditOrder}>
        <ModalBody>
          <TextField
            id="text_description"
            color="primary"
            label={label}
            multiline
            rowsMax="4"
            fullWidth
            autoFocus
            value={desc}
            onChange={onChange}
          />
        </ModalBody>
      </Collapse>
      <Collapse in={description !== ''}>
        <ModalBody>
          <TextField
            color="secondary"
            label={labelStill}
            fullWidth
            multiline
            disabled
            value={description}
          />
        </ModalBody>
      </Collapse>
      <Collapse className="w-100" in={(description !== desc)}>
        <ModalFooter className="w-100">
          <Button
            fullWidth
            color="secondary"
            variant="contained"
            onClick={handleDesc}
          >
            {button}
          </Button>
        </ModalFooter>
      </Collapse>
      <Collapse in={(desc === description)}>
        <ModalFooter className="w-100">
          <Button
            fullWidth
            color="primary"
            variant="contained"
            onClick={close}
          >
            {done}
          </Button>
        </ModalFooter>
      </Collapse>
    </Modal>
  );
};

Comment.propTypes = {
  done: PropTypes.string,
  title: PropTypes.string,
  label: PropTypes.string,
  button: PropTypes.string,
  labelStill: PropTypes.string,
  close: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  sendDesc: PropTypes.func.isRequired,
  description: PropTypes.string.isRequired,
  enableEditOrder: PropTypes.bool.isRequired,
};

Comment.defaultProps = {
  button: 'Añadir',
  done: 'Listo',
  title: 'Descripción',
  label: 'Añade una Descripción',
  labelStill: 'Descripción actual',
};

export default Comment;
