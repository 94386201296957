import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ClientAPI from '../api/ClientAPI';

export default function useRewardPoints() {
  const { selectedCustomer } = useSelector(state => state.customer);
  const [points, setPoints] = useState(null);

  const getCustomerPoints = async () => {
    const clientApi = new ClientAPI();
    const response = await clientApi.getRewardPoints(selectedCustomer.crmid);
    const { success, result } = response;
    if (success && Object.keys(result).length) {
      /**
         * {
            puntos_asignados: 0,
            puntos_saldo: 0,
            puntos_usados: 0,
         * }
         */
      setPoints(result);
    }
  };

  return {
    points,
    getCustomerPoints,
  };
}
