/* eslint-disable import/no-cycle */
/* eslint-disable react/prop-types */
import React from 'react';
import { withRouter } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import DialogExpenseDetail from './components/DialogExpenseDetail';
import { fetchSelectedExpense } from '../../../../redux/actions/expensesActions';

const DetailExpense = ({ match, history }) => {
  const dispatch = useDispatch();
  const handleCloseDetail = () => {
    history.goBack();
  };

  React.useEffect(() => {
    dispatch(fetchSelectedExpense(match.params.crmid));
    // eslint-disable-next-line
  }, []);

  return (
    <DialogExpenseDetail open handleClose={handleCloseDetail} />
  );
};

export default withRouter(DetailExpense);
