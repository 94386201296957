/* eslint-disable import/no-cycle */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Paper,
  Collapse,
  LinearProgress,
} from '@material-ui/core';

import { useDispatch } from 'react-redux';
import tabsProps from '../../propTypes/tabsProps';
import loadsProps from '../../propTypes/loadsProps';

import ProductGrid from './components/ProductGrid';
import ProductTable from './components/ProductTable';
import ProductOptions from './components/ProductOptions';
import ProductPaginatior from '../Pagination/products';
import { defineProductColumns } from '../../redux/actions/productActions';


const Product = ({
  tabs,
  loads,
  product,
  parentProduct,
  setParentProduct,
  product: { subProducts },
  authUser,
  modals,
  getSubProducts,
  addProducts,
  /*  getProductsByPage, */
  toggleModal,
  handleOnClick,
  dispatchFetchAllProducts,
  dispatchFilterAllProducts,
  dispatchToggleRefresh,
}) => {
  // const userAuth = useState(state => state.authUser);
  const { user: { config } } = authUser;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(defineProductColumns(config));
  }, []);

  return (
    <>
      {tabs.posDisplayTab === 'grid' && (
        <>
          <Paper className="grid__container">
            <Collapse in={loads.productIsLoading}>
              <LinearProgress color="secondary" />
            </Collapse>
            <div className="d-flex flex-column" style={{ gap: '16px', height: '100%' }}>
              <ProductGrid
                product={product}
                authUser={authUser}
                addProducts={addProducts}
                handleOnClick={handleOnClick}
                setParentProduct={setParentProduct}
                dispatchFetchAllProducts={dispatchFetchAllProducts}
              />
              <ProductPaginatior />
            </div>
          </Paper>
        </>
      )}
      {tabs.posDisplayTab === 'list' && (
        <>
          <Paper className="table__container">
            <Collapse in={loads.productIsLoading}>
              <LinearProgress color="primary" />
            </Collapse>
            <div className="d-flex flex-column" style={{ gap: '16px', height: '100%' }}>
              <ProductTable
                modals={modals}
                product={product}
                authUser={authUser}
                addProducts={addProducts}
                handleOnClick={handleOnClick}
                setParentProduct={setParentProduct}
                isLoading={Boolean(loads.productIsLoading)}
                dispatchFilterAllProducts={dispatchFilterAllProducts}
                loads={loads}
                dispatchToggleRefresh={dispatchToggleRefresh}
              />
              <ProductPaginatior />
            </div>
          </Paper>
        </>
      )}
      <ProductOptions
        authUser={authUser}
        modals={modals}
        toggleModal={toggleModal}
        addProducts={addProducts}
        options={subProducts}
        loads={loads}
        getSubProducts={getSubProducts}
        parentProduct={parentProduct}
      />
    </>
  ); };

Product.propTypes = {
  tabs: tabsProps.isRequired,
  loads: loadsProps.isRequired,
  modals: PropTypes.object.isRequired,
  product: PropTypes.object.isRequired,
  authUser: PropTypes.object.isRequired,
  addProducts: PropTypes.func.isRequired,
  toggleModal: PropTypes.func.isRequired,
  getSubProducts: PropTypes.func.isRequired,
  /* getProductsByPage: PropTypes.func.isRequired, */
  parentProduct: PropTypes.string.isRequired,
  setParentProduct: PropTypes.func.isRequired,
  /* news */
  handleOnClick: PropTypes.func.isRequired,
  dispatchFetchAllProducts: PropTypes.func.isRequired,
  dispatchFilterAllProducts: PropTypes.func.isRequired,
};

export default Product;
