/* eslint-disable import/no-cycle */
import { connect } from 'react-redux';
import {
  saveSell,
  editOrder,
  changeSalesOrderStatus,
} from '../../../../../redux/actions/sellActions';
import { cloneQuote, cotizar } from '../../../../../redux/actions/cotizarActions';
import { openModal } from '../../../../../redux/actions/modalActions';
import { fetchTimbrado } from '../../../../../redux/actions/TimbrarActions';
import { addItemsToTable } from '../../../../../redux/actions/tableActions';
import { evaluateStock } from '../../../../../redux/actions/cartActions';
/* Component */
import ActionControls from './ActionControls';

const mapStateToProps = state => ({
  loads: state.loads,
  tabs: state.tabs,
  cart: state.cart,
  customer: state.customer,
  authUser: state.authUser,
  keyBoard: state.keyBoard,
});

const mapDispatchToProps = dispatch => ({
  /* Sells Order */
  dispatchMakeSell: (orderStatus) => {
    dispatch(changeSalesOrderStatus(orderStatus));
    dispatch(saveSell());
  },
  dispatchEditOrder: () => dispatch(editOrder()),
  /* Quote Order */
  dispatchCloneQuote: () => dispatch(cloneQuote()),
  /* Quote Order */
  dispatchCotizar: () => dispatch(cotizar()),
  /* Modal */
  dispatchOpenModal: modal => dispatch(openModal(modal)),
  /* Timbrar */
  dispatchFetchTimbrado: crmid => dispatch(fetchTimbrado(crmid)),
  /* Refund */
  dispatchOpenRefundModal: () => {
    dispatch(openModal('refund'));
    dispatch(addItemsToTable('refund'));
  },
  /* Cart */
  dispatchSyncCart: () => dispatch(evaluateStock()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ActionControls);
