/* eslint-disable import/no-cycle */
import React, { memo, useEffect, useState } from 'react';
import '../../warehouse.styles.scss';
import moment from 'moment';
import {
  faDolly,
  faFileInvoice,
  faHandHoldingUsd,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  Collapse,
  ListItemIcon,
  IconButton,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import { Search } from '@material-ui/icons';
import { useHistory } from 'react-router';
import Iconwidthbg from '../../../../components/Icons/IconWidthBG/IconWidthBG';
import {
  getColorStatusFDE,
  getColorStatusMDA,
  geteColorSosStatus,
} from '../../../../helpers/tools';
import WharehouseExpand from './WPurchaseExpand';
import ShareModal from '../../../../components/Share/components/ShareModal';
import appModules from '../../../../constant/appModules';
import MdaAPI from '../../../../api/MdaApi';
import CustomDatePicker from '../../../../components/CustomInputs/components/custom-date-picker';

const WPurchases = memo(() => {
  const history = useHistory();
  const [purchase, setPurchase] = useState({});
  const [loading, setLoading] = useState(false);
  const [salesReceive, setSalesReceive] = useState([]);
  const [salesReceiveFiltered, setSalesReceiveFiltered] = useState([]);
  const [search, setSearch] = useState('purchaseorder_no');

  const loads = useSelector(state => state.loads);
  const authUser = useSelector(state => state.authUser);
  const isLoading = Boolean(loads && loads.emailTemplateIsLoading);
  const [openShare, setOpenShare] = React.useState(false);

  const {
    user: {
      config: { store },
    },
  } = authUser;

  const handleShareClose = () => {
    setOpenShare(false);
  };

  const shareModal = async (item) => {
    await setPurchase(item);
    setOpenShare(true);
  };

  const selectLocalOrder = (sale) => {
    if (purchase && sale.crmid === purchase.crmid) {
      setPurchase(null);
    } else {
      setPurchase(sale);
    }
  };

  const salesToReceive = async () => {
    setLoading(true);
    const mdaApi = new MdaAPI();
    // const response = await mdaApi.salesToReceive(70867);
    const response = await mdaApi.salesToReceive(store.crmid);
    const { success, result } = response;

    if (success) {
      setSalesReceive(result);
      setSalesReceiveFiltered(result);
    }

    setLoading(false);
  };

  const makeSearch = (searchValue) => {
    if (search === 'assigned_user_data') {
      const filter = salesReceive.filter(
        item => item.assigned_user_data.first_name
          .toLowerCase()
          .includes(searchValue.toLowerCase())
          || item.assigned_user_data.last_name
            .toLowerCase()
            .includes(searchValue.toLowerCase()),
      );
      setSalesReceiveFiltered([...filter]);

      return;
    }
    const filter = salesReceive.filter(item => item[`${search}`].toLowerCase().includes(searchValue.toLowerCase()));
    setSalesReceiveFiltered([...filter]);
  };

  const handleChangeDate = (name, date) => {
    const sales = [...salesReceive];

    if (date[0] === null && date[1] === null) {
      setSalesReceiveFiltered([...sales]);

      return;
    }

    if (date[0] !== null && date[1] !== null) {
      const filter = sales.filter(item => moment(item[`${search}`]).isBetween(
        moment(date[0]).startOf('days'),
        moment(date[1]).endOf('day'),
        undefined, '[]',
      ));
      setSalesReceiveFiltered([...filter]);
    } else {
      const filter = sales.filter(item => moment(item[`${search}`]).isBetween(
        moment(date[0]).startOf('days'),
        moment(date[0]).endOf('day'),
        undefined, '[]',
      ));
      setSalesReceiveFiltered([...filter]);
    }
  };

  const recibirOrden = () => {
    history.push(`/almacenes/recibir/${purchase.crmid}`);
  };

/*   useEffect(() => {
    if (store) {
      salesToReceive();
    }
  }, []); */

  React.useEffect(() => {
    salesToReceive();
  }, [store]);

  return (
    <>
      <div className="bg-white w-100 d-flex justify-content-between align-items-center px-2 py-4 sticky-top">
        <div
          className="d-flex w-100 px-2 py-2"
          style={{ backgroundColor: '#ebebeb', borderRadius: 20 }}
        >
          <select
            style={{
              border: 0,
              borderRadius: 10,
              padding: '0 5px',
              marginRight: '0.5rem',
              outline: 'none',
            }}
            onChange={e => setSearch(e.target.value)}
          >
            <option
              selected={search === 'purchaseorder_no'}
              value="purchaseorder_no"
            >
              No. compra
            </option>
            <option
              selected={search === 'vendorid_label'}
              value="vendorid_label"
            >
              Vendedor
            </option>
            <option
              selected={search === 'assigned_user_data'}
              value="assigned_user_data"
            >
              Usuario
            </option>
            <option selected={search === 'f_compra'} value="f_compra">
              Fecha compra
            </option>
          </select>

          {search === 'f_compra' ? (
            <CustomDatePicker
              style={{ height: 30 }}
              onChange={handleChangeDate}
              headerName="date"
            />
          ) : (
            <input
              autoFocus
              style={{
                border: 0,
                width: '100%',
                backgroundColor: 'transparent',
                outline: 'none',
              }}
              onChange={e => makeSearch(e.target.value)}
              type="text"
            />
          )}
          <IconButton style={{ height: 30, color: '#313c52' }}>
            <Search />
          </IconButton>
        </div>
      </div>

      {loading && (
        <Collapse in={loading}>
          <LinearProgress />
        </Collapse>
      )}

      <div>
        {salesReceiveFiltered.length ? (
          <div style={{ paddingBottom: 66 }}>
            {salesReceiveFiltered.map(sale => (
              <div
                key={sale.crmid}
                style={{ backgroundColor: '#fff', cursor: 'pointer' }}
              >
                <ListItem
                  divider
                  className="quote"
                  onClick={() => selectLocalOrder(sale)}
                >
                  <ListItemText
                    classes={{
                      primary: 'quote__title',
                      secondary: 'quote__subtitle',
                    }}
                    primary={sale.purchaseorder_no}
                    secondary={(
                      <>
                        <span>{sale.vendorid_label}</span>
                        <br />
                        <span>
                          {moment(sale.f_compra).format('DD MMM YYYY h:mm a')}
                        </span>
                      </>
                    )}
                  />
                  <div className="d-flex flex-column">
                    <ListItemText
                      classes={{ primary: 'quote__money' }}
                      primary={
                        Object.keys(sale.assigned_user_data).length
                          ? `${sale.assigned_user_data.first_name} ${sale.assigned_user_data.last_name}`
                          : ''
                      }
                      style={{ textAlign: 'right', padding: 0 }}
                    />
                    <div className="d-flex justify-content-end">
                      <ListItemIcon className="m-0">
                        <Iconwidthbg
                          primaryColor={geteColorSosStatus(sale.postatus)}
                          secondaryColor="white"
                          width={27}
                          height={27}
                          classes="ml-2"
                          enabled
                          square
                          rounded
                          actionClick={() => {}}
                        >
                          <FontAwesomeIcon
                            style={{ fontSize: 15 }}
                            icon={faFileInvoice}
                          />
                        </Iconwidthbg>
                      </ListItemIcon>
                      <ListItemIcon className="m-0">
                        <Iconwidthbg
                          primaryColor={getColorStatusMDA(sale.estado_mda)}
                          secondaryColor="white"
                          width={27}
                          height={27}
                          classes="ml-2"
                          enabled
                          square
                          rounded
                          actionClick={() => {}}
                        >
                          <FontAwesomeIcon
                            style={{ fontSize: 15 }}
                            icon={faDolly}
                          />
                        </Iconwidthbg>
                      </ListItemIcon>
                      <ListItemIcon className="m-0">
                        <Iconwidthbg
                          primaryColor={getColorStatusFDE(sale.estado_fde)}
                          secondaryColor="white"
                          width={27}
                          height={27}
                          classes="ml-2"
                          enabled
                          square
                          rounded
                          actionClick={() => {}}
                        >
                          <FontAwesomeIcon
                            style={{ fontSize: 15 }}
                            icon={faHandHoldingUsd}
                          />
                        </Iconwidthbg>
                      </ListItemIcon>
                    </div>
                  </div>
                </ListItem>
                <Collapse
                  in={purchase && purchase.crmid === sale.crmid}
                  style={{ backgroundColor: '#fafafa', marginBottom: 10 }}
                >
                  <WharehouseExpand purchase={sale} openShare={shareModal} orderReceiver={recibirOrden} />
                </Collapse>
              </div>
            ))}
          </div>
        ) : (
          <List dense>
            <ListItem classes={{ root: 'bg-white' }}>
              <ListItemText primary="No se encontraron ventas" />
            </ListItem>
          </List>
        )}
      </div>
      <ShareModal
        open={openShare}
        title={`Compartir ${purchase ? purchase.entity_label : ''}`}
        handleClose={handleShareClose}
        order={purchase || {}}
        template={appModules.PurchaseOrder}
        isLoading={isLoading}
      />
    </>
  );
});

export default WPurchases;
