import React from 'react';
import PropTypes from 'prop-types';
/* Material */
import { SwipeableDrawer, Collapse, LinearProgress } from '@material-ui/core';
/* Reatcstrap */
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
/* Device */
import { isMobile } from 'react-device-detect';

import DiscountConstructor from './constructor';

const DiscountModal = ({
  title,
  isOpen,
  toggle,
  onSave,
  products,
  isLoading,
  totalToPay,
  dispatchSetDiscount,
}) => {
  return (
    <>
      {isMobile ? (
        <SwipeableDrawer
          anchor="top"
          open={isOpen}
          onClose={toggle}
          onOpen={toggle}
        >
          <Collapse in={isLoading}>
            <LinearProgress />
          </Collapse>
        </SwipeableDrawer>
      ) : (
        <Modal
          isOpen={isOpen}
          toggle={toggle}
          size="sm"
          className="checkout_modal"
        >
          <ModalHeader toggle={toggle}>{title}</ModalHeader>
          <ModalBody className="modifix-modal">
            <Collapse in={isLoading}>
              <LinearProgress />
            </Collapse>
            <Collapse in={!isLoading}>
              <DiscountConstructor
                onSave={onSave}
                products={products}
                totalToPay={totalToPay}
                dispatchSetDiscount={dispatchSetDiscount}
              />
            </Collapse>
          </ModalBody>
        </Modal>
      )}
    </>
  );
};

DiscountModal.defaultProps = {
  title: 'Descuento',
};

DiscountModal.propTypes = {
  title: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
  toggle: PropTypes.func.isRequired,
  products: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  totalToPay: PropTypes.number.isRequired,
  dispatchSetDiscount: PropTypes.func.isRequired,
};

export default DiscountModal;
