import React from 'react';
import PropTypes from 'prop-types';
import FileModal from '../modal/FileModal';
import FileList from '../list/FileList';

const File = ({
  dispatchCloseDiscountModal,
  dispatchDeleteFile,
  dispatchDownloadFile,
  dispatchGetFilesByOrder,
  dispatchUploadFilesToOrder,
  dispatchGetDocumentsByExpense,
  dispatchDeleteDocuments,
  dispatchUploadFilesToDocument,
  dispatchUploadFilesToTask,
  dispatchGetDocumentsByTask,
  files,
  documentos,
  documents,
  loading,
  modals,
  type,
}) => (
  <div className="file">
    {type === 'modal' && (
      <FileModal
        files={files}
        remove={dispatchDeleteFile}
        getFiles={dispatchGetFilesByOrder}
        download={dispatchDownloadFile}
        upload={dispatchUploadFilesToOrder}
        toggle={dispatchCloseDiscountModal}
        isOpen={Boolean(modals.fileModalIsOpen)}
        loading={loading}
      />
    )}

    {type === 'list' && (
      <FileList
        files={files}
        remove={dispatchDeleteFile}
        getFiles={dispatchGetFilesByOrder}
        download={dispatchDownloadFile}
        upload={dispatchUploadFilesToOrder}
        toggle={dispatchCloseDiscountModal}
        isOpen
        loading={loading}
      />
    )}

    {type === 'gastos' && (
      <FileList
        files={documentos}
        remove={dispatchDeleteDocuments}
        getFiles={dispatchGetDocumentsByExpense}
        download={dispatchDownloadFile}
        upload={dispatchUploadFilesToDocument}
        toggle={dispatchCloseDiscountModal}
        isOpen
        loading={loading}
      />
    )}

    {type === 'task' && (
      <FileList
        files={documents}
        remove={dispatchDeleteDocuments}
        getFiles={dispatchGetDocumentsByTask}
        download={dispatchDownloadFile}
        upload={dispatchUploadFilesToTask}
        toggle={dispatchCloseDiscountModal}
        isOpen
        loading={loading}
      />
    )}
  </div>
);

File.propTypes = {
  dispatchCloseDiscountModal: PropTypes.func.isRequired,
  dispatchDeleteFile: PropTypes.func.isRequired,
  dispatchDownloadFile: PropTypes.func.isRequired,
  dispatchGetFilesByOrder: PropTypes.func.isRequired,
  dispatchUploadFilesToOrder: PropTypes.func.isRequired,
  dispatchGetDocumentsByExpense: PropTypes.func.isRequired,
  dispatchGetDocumentsByTask: PropTypes.func.isRequired,
  dispatchDeleteDocuments: PropTypes.func.isRequired,
  dispatchUploadFilesToDocument: PropTypes.func.isRequired,
  dispatchUploadFilesToTask: PropTypes.func.isRequired,
  files: PropTypes.object.isRequired,
  documentos: PropTypes.object.isRequired,
  documents: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  modals: PropTypes.object.isRequired,
  type: PropTypes.string,
};

File.defaultProps = {
  type: 'modal',
};

export default File;
