/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import {
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Radio,
} from '@material-ui/core';
import InputButton from '../../Input/Button';
import CreateAddress from '../../Customer/components/CreateAddress';

const ListAddress = ({
  addAddress,
  address,
  cart,
  customer,
  labelOrder,
  setAddress,
}) => {
  const [addModal, setAddModal] = React.useState(false);
  const {
    orderSelected: { order },
  } = cart;
  const facturaId = order && order.treebesdireccionfacturaid !== undefined
    ? order.treebesdireccionfacturaid
    : '';
  const envioId = order && order.treebesdireccionenvioid !== undefined
    ? order.treebesdireccionenvioid
    : '';
  const idFactura = facturaId.split('x').pop();
  const idEnvio = envioId.split('x').pop();
  /* const enableChange = Boolean(cart.orderSelected.status === 'Creado'); */
  const enableChange = true;

  const addAddressFromModal = (add) => {
    addAddress(customer.crmid, add);
    setAddModal(false);
  };

  return (
    <List>
      <ListItem dense>
        <ListItemText
          primary={`Enlistamos las direcciones del clientezz: ${customer.entity_label}.`}
          secondary={enableChange
            ? labelOrder
            : 'No puedes cambiar las direcciones de la orden/cotización hasta cambiar el estado de la orden en Creado.'
          }
        />
      </ListItem>
      {idFactura === '' && idEnvio === '' && (
        <ListItem dense>
          <ListItemText
            secondary={`Parece que la orden/cotización: ${order ? order.label : ''} no tiene ninguna dirección envío anexa.`}
          />
        </ListItem>
      )}
      <div className="w-100 h-auto d-flex align-items-center border-bottom mt-1">
        <span
          style={{ width: '20%' }}
          className="font-size-10 text-dark pr-1"
        >
          Dirección de Envío
        </span>
        <span className="font-size-10 text-dark w-50 mr-auto pl-1">
          Dirección
        </span>
      </div>
      {address.map(el => (
        <ListItem
          key={`key_place_${el.direccion}_${el.num_ext}_${Math.random()}`}
          dense
          button
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            if (enableChange) setAddress(idFactura || el.crmid, el.crmid);
            else toast.info('Solo se pueden modificar las direcciones cuando la orden está en estatus: Creado, no tengan entregas o no se tenga flujos de efectivo');
          }}
        >
          <ListItemIcon>
            <Radio
              checked={el.crmid === idEnvio}
              color="secondary"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                if (enableChange) setAddress(idFactura || el.crmid, el.crmid);
                else toast.info('Solo se pueden modificar las direcciones cuando la orden está en estatus: Creado, no tengan entregas o no se tenga flujos de efectivo');
              }}
            />
          </ListItemIcon>
          <ListItemText
            primary={(
              <div className="w-100 d-flex flex-column">
                {`${el.direccion} #${el.num_ext}`}
              </div>
            )}
            secondary={`${el.city} - ${el.state}, ${el.country}`}
          />
        </ListItem>
      ))}
      <Divider className="w-100 my-1" />
      <div className="w-100 h-auto d-flex align-items-center justify-content-center">
        <InputButton
          title={`Añade una dirección a ${customer.entity_label}`}
          disabled={!enableChange}
          click={() => setAddModal(!addModal)}
        />
      </div>
      <CreateAddress
        open={addModal}
        toggle={() => setAddModal(!addModal)}
        onSave={addAddressFromModal}
        title="Crear Dirección"
      />
    </List>
  );
};

ListAddress.propTypes = {
  addAddress: PropTypes.func.isRequired,
  address: PropTypes.array,
  cart: PropTypes.object.isRequired,
  customer: PropTypes.object.isRequired,
  labelOrder: PropTypes.string,
  setAddress: PropTypes.func.isRequired,
};

ListAddress.defaultProps = {
  address: [],
  labelOrder: 'Puedes seleccionar la direccion de envío para esta orden/cotización, esto no cambiará la dirección de envío predeterminada del cliente, unicamente de la orden/cotización.',
};

export default ListAddress;
