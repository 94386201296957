/* eslint-disable import/no-cycle */
import { connect } from 'react-redux';
import { changeTab } from '../../../../redux/actions/tabActions';
import { handleFastMode } from '../../../../redux/actions/authUserActions';
import { updateProductQuantityInCart } from '../../../../redux/actions/cartActions';
import {
  filterProducts,
  selectFastOption,
  setMultiFilterProduct,
  fastOptionProductSearch,
  removeMultiFilterProduct,
  fetchAllProducts,
} from '../../../../redux/actions/productActions';
import {
  filterOrder,
  resetEditOrder,
  setMultiFilterOrder,
  removeMultiFilterOrder,
  fetchListOrders,
  clearAllFiltersOrder,
} from '../../../../redux/actions/orderActions';
import {
  refreshOrders,
  refreshProducts,
  clearAllFilters,
  refreshCotizaciones,
  changePosTypeTabToOrdersAndClearCart,
  changePosTypeTabToProductsAndClearCart,
  changePosTypeTabToCotizacionesAndClearCart,
} from '../../../../redux/actions/posActions';
import {
  saveSell,
  changeSalesOrderStatus,
} from '../../../../redux/actions/sellActions';
import { cotizar, filterCotizacion, fetchAllCotizaciones } from '../../../../redux/actions/cotizarActions';
import PosItemContainerAction from './PosItemContainerAction';
/* import { filterCotizacion } from '../../../../redux/actions/cotizarActions'; */

import { selectCustomer } from '../../../../redux/actions/customerActions';

import { openModal, closeModal } from '../../../../redux/actions/modalActions';

const mapStateToProps = state => ({
  tabs: state.tabs,
  cart: state.cart,
  order: state.order,
  modals: state.modals,
  product: state.product,
  customer: state.customer,
  authUser: state.authUser,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangePosDisplayTabToGrid: () => dispatch(changeTab('posDisplay', 'grid')),
  dispatchChangePosDisplayTabToList: () => dispatch(changeTab('posDisplay', 'list')),
  dispatchFilterProducts: word => dispatch(filterProducts(word)),
  dispatchFilterOrders: word => dispatch(filterOrder(word)),
  dispatchMultipleFiltersOrder: item => dispatch(setMultiFilterOrder(item)),
  dispatchMultipleFiltersProduct: item => dispatch(setMultiFilterProduct(item)),
  dispatchRemoveMultipleFiltersOrder: word => dispatch(removeMultiFilterOrder(word)),
  dispatchRemoveMultipleFiltersProduct: word => dispatch(removeMultiFilterProduct(word)),
  dispatchClearAllFiltersOrder: () => dispatch(clearAllFiltersOrder()),

  /* news */
  dispatchRefreshProducts: () => dispatch(refreshProducts()),
  dispatchRefreshOrders: () => dispatch(refreshOrders()),
  dispatchClearAllFilters: () => dispatch(clearAllFilters()),
  dispatchChangePosTypeTabToProductsAndClearCart: () => dispatch(changePosTypeTabToProductsAndClearCart()),
  dispatchChangePosTypeTabToOrdersAndClearCart: () => dispatch(changePosTypeTabToOrdersAndClearCart()),

  dispatchChangePosTypeTabToCotizacionesAndClearCart: () => dispatch(changePosTypeTabToCotizacionesAndClearCart()),
  dispatchFilterCotizacion: word => dispatch(filterCotizacion(word)),
  dispatchRefreshCotizaciones: () => dispatch(refreshCotizaciones()),
  dispatchSelectCustomer: customer => dispatch(selectCustomer(customer)),

  dispatchResetOrder: () => dispatch(resetEditOrder()),
  dispatchFastOptionProductSearch: search => dispatch(fastOptionProductSearch(search)),

  dispatchHandleFastMode: (mode = null) => dispatch(handleFastMode(mode)),
  dispatchSelectFastOption: searchQty => dispatch(selectFastOption(searchQty)),
  dispatchUpdateProductQuantityInCart: (row, qty) => dispatch(updateProductQuantityInCart(row, qty)),

  /* Modals and alerts */
  dispatchOpenModal: modal => dispatch(openModal(modal)),
  dispatchCloseModal: modal => dispatch(closeModal(modal)),

  /* Sells */
  dispatchSaveSell: (orderStatus) => {
    dispatch(changeSalesOrderStatus(orderStatus));
    dispatch(saveSell());
  },
  /* Cotizar */
  dispatchCotizar: () => dispatch(cotizar()),
  /* List order for older orders */
  dispatchOlderOrders: search => dispatch(fetchListOrders(search)),
  dispatchOlderProducts: (search) => {
    dispatch(fetchAllProducts('', 1, search));
  },
  dispatchOlderQuotes: (search) => {
    dispatch(fetchAllCotizaciones(1, null, search));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PosItemContainerAction);
