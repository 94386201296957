import React from 'react';
import PropTypes from 'prop-types';
import { Fab } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const TopMenuItem = ({
  color,
  size,
  icon,
  title,
  fabColor,
  disabled,
  onClick,
  disabledColor,
}) => (
  <div className="d-flex flex-column align-items-center w-25">
    <Fab
      disabled={disabled}
      onClick={onClick}
      style={{ backgroundColor: !disabled ? fabColor : disabledColor }}
    >
      {typeof icon === 'string'
        ? <FontAwesomeIcon style={{ color }} size={size} icon={icon} />
        : (icon)}
    </Fab>
    <div className="mt-1 font-size-12 w-80">
      {title}
    </div>
  </div>
);

TopMenuItem.propTypes = {
  icon: PropTypes.any,
  size: PropTypes.string,
  title: PropTypes.string,
  color: PropTypes.string,
  fabColor: PropTypes.string,
  disabledColor: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};

TopMenuItem.defaultProps = {
  icon: '',
  size: '2x',
  title: '',
  color: '#ffffff',
  fabColor: '#313C52',
  disabledColor: '#c6c6c6',
  disabled: false,
  onClick: () => {},
};

export default TopMenuItem;
