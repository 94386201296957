/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  Tooltip,
  IconButton,
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const GeneralDiscountAction = ({
  enable,
  tooltip,
  add,
}) => {
  const config = useSelector(state => state.authUser.user.config);
  const { pos_no_descuentos } = config;
  const canApplyDiscount = pos_no_descuentos === '1' ? true : !enable;
  return (
    <Tooltip
      title={tooltip}
      placement="left"
    >
      <span>
        <IconButton
          id="action_control_discount"
          disabled={canApplyDiscount}
          onClick={add}
          className="pos_menu_setDir"
        >
          <FontAwesomeIcon
            icon="percent"
            style={{ fontSize: '0.9em' }}
          />
          <span className="font-size-10">Descuento General</span>
        </IconButton>
      </span>
    </Tooltip>
  ); };

GeneralDiscountAction.propTypes = {
  tooltip: PropTypes.string,
  add: PropTypes.func.isRequired,
  enable: PropTypes.bool.isRequired,
};
GeneralDiscountAction.defaultProps = {
  tooltip: 'Añade Descuento General',
};

export default GeneralDiscountAction;
