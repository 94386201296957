/* eslint-disable react/prop-types */
/* eslint-disable import/no-cycle */
import React from 'react';
import { useSelector } from 'react-redux';
import {
  Collapse,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core';
import SearchTopBar from '../SearchTopBar';
import ListData from '../ListData';
import { fetchSelectedExpenseType } from '../../../../redux/actions/expensesActions';

const ExpenseType = ({
  type,
  fetchData,
}) => {
  React.useEffect(() => {
    fetchData();
  }, []);

  const list = useSelector(state => state.gastos.listaTipoGastos);
  const loads = useSelector(state => state.loads);
  return (
    <div>
      <SearchTopBar type={type} />
      { loads.updateIsLoading && (
        <Collapse in={loads.updateIsLoading}>
          <LinearProgress />
        </Collapse>
      )}
      { !loads.updateIsLoading && list.data.length === 0 && (
        <Collapse in={!loads.updateIsLoading && list.data.length === 0}>
          <List dense>
            <ListItem classes={{ root: 'bg-white' }}>
              <ListItemText
                primary="Sin tipo de gastos"
                secondary="No cuenta con tipos de gastos"
              />
            </ListItem>
          </List>
        </Collapse>
      )}
      { !loads.updateIsLoading && list.data.length > 0 && (
        <Collapse in={!loads.updateIsLoading && list.data.length > 0}>
          <ListData data={list.data} fetchData={fetchSelectedExpenseType} type={type} />
        </Collapse>
      )}
    </div>
  );
};

export default ExpenseType;
