/* eslint-disable no-nested-ternary */
/* eslint-disable no-undef */
/* eslint-disable react/destructuring-assignment */
import {
  Divider, IconButton, List, ListItem, MuiThemeProvider, Paper, createMuiTheme,
} from '@material-ui/core';
import { PersonPinRounded } from '@material-ui/icons';
import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import './styles.css';
import { formatMoney } from '../../../helpers/tools';
import useOnClickOutside from '../../../hooks/useClickOutside';


const CartSellMenu = () => {
  const ref = useRef();
  const [open, setOpen] = useState(false);
  useOnClickOutside(ref, () => setOpen(false));
  const theme = createMuiTheme({
    props: {
      // Name of the component
      MuiMenuItem: {
        // The properties to apply
        disableRipple: true, // No more ripple, on the whole application!
        disableTouchRipple: true,
      },
      MuiMenu: {
        // The properties to apply
        disableRipple: true, // No more ripple, on the whole application!
        disableTouchRipple: true,
      },
    },
  });
  const selectedCustomer = useSelector(state => state.customer.selectedCustomer);

  const handleClose = () => {
    setOpen(false);
  };

  const handleClick = () => {
    if (selectedCustomer?.crmid === '54572') {
      return;
    }
    // Validate all menu items has data
    if (
      selectedCustomer?.siccode
      || parseFloat(selectedCustomer?.puntos_asignados) > 0
      || (parseFloat(selectedCustomer.credito_d) > 0 && parseFloat(selectedCustomer?.credito_m) > 0)
      || (parseFloat(selectedCustomer?.cxc) > 0 || parseFloat(selectedCustomer?.ndc_aplicadas) > 0 || parseFloat(selectedCustomer?.xamortizar) > 0)
    ) {
      setOpen(!open);
    }
  };

  return (
    <MuiThemeProvider theme={theme}>
      <div style={{ position: 'relative' }} ref={ref}>
        <IconButton
          size="small"
          square="true"
          onClick={handleClick}
          style={{ backgroundColor: open ? '#e5e5e5' : (selectedCustomer?.siccode && selectedCustomer?.siccode !== 'XAXX010101000' ? '#23a1db' : 'transparent'), borderRadius: 5 }}
          color={selectedCustomer?.siccode && selectedCustomer?.siccode !== 'XAXX010101000' ? '#fff' : ''}
        >
          <PersonPinRounded style={{ color: selectedCustomer?.siccode && selectedCustomer?.siccode !== 'XAXX010101000' ? '#fff' : '' }} />
        </IconButton>
        {
          open && (
            <Paper style={{ position: 'absolute', zIndex: 9 }}>
              <List component="nav">
                {
                selectedCustomer && selectedCustomer?.siccode && (
                  <ListItem disableRipple disableTouchRipple onClick={handleClose} style={{ height: '100%', cursor: 'default' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 2 }}>
                      <div style={{ display: 'flex' }}>
                        <span style={{ marginRight: 5, fontSize: 14 }}>
                          RFC:
                        </span>
                        <span style={{ fontWeight: 300, fontSize: 'medium' }}>{selectedCustomer?.siccode || '-'}</span>
                      </div>
                    </div>
                  </ListItem>
                )
              }
                {
                selectedCustomer?.puntos_asignados && parseFloat(selectedCustomer?.puntos_asignados) > 0 && (
                  <>
                    <Divider />
                    <ListItem disableRipple disableTouchRipple onClick={handleClose} style={{ height: '100%', cursor: 'default' }}>
                      <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 2 }}>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                          <span style={{ fontWeight: 300, fontSize: 'medium' }}>Puntos</span>
                          <span style={{ fontSize: 14, whiteSpace: 'nowrap' }}>
                            <strong>
                              Asignado:
                            </strong>
                            {' '}
                            {formatMoney(selectedCustomer?.puntos_asignados) || 0}
                          </span>
                          <span style={{ fontSize: 14, whiteSpace: 'nowrap' }}>
                            <strong>
                              Saldo:
                            </strong>
                            {' '}
                            {formatMoney(selectedCustomer?.puntos_saldo) || 0}
                          </span>
                          <span style={{ fontSize: 14, whiteSpace: 'nowrap' }}>
                            <strong>
                              Usados:
                            </strong>
                            {' '}
                            {formatMoney(selectedCustomer?.puntos_usados) || 0}
                          </span>
                        </div>
                      </div>
                    </ListItem>
                  </>
                )
              }
                {/* Créditos */}
                {
                selectedCustomer && parseFloat(selectedCustomer.credito_d) > 0 && parseFloat(selectedCustomer?.credito_m) > 0 && (
                  <>
                    <Divider />
                    <ListItem disableRipple disableTouchRipple onClick={handleClose} style={{ height: '100%', cursor: 'default' }}>
                      <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 2 }}>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                          <span style={{ fontWeight: 300, fontSize: 'medium' }}>Crédito</span>
                          <span style={{ fontSize: 14, whiteSpace: 'nowrap' }}>
                            <strong>
                              Limite de crédito:
                            </strong>
                            {' '}
                            {formatMoney(selectedCustomer?.credito_m)}
                          </span>
                          <span style={{ fontSize: 14, whiteSpace: 'nowrap' }}>
                            <strong>
                              Dias de crédito:

                            </strong>
                            {' '}
                            {selectedCustomer?.credito_d || 0}
                          </span>
                          <span style={{ fontSize: 14, whiteSpace: 'nowrap' }}>
                            <strong>
                              Deuda mas antigua

                            </strong>
                            {' '}
                            {selectedCustomer?.fecha_deuda}
                          </span>
                          <span style={{ fontSize: 14, whiteSpace: 'nowrap' }}>
                            <strong>
                              Crédito disponible:

                            </strong>
                            {' '}
                            {formatMoney(selectedCustomer?.saldo_credito) || 0}
                          </span>
                        </div>
                      </div>
                    </ListItem>
                  </>
                )
              }
                {/* Saldos */}
                {
                selectedCustomer && (parseFloat(selectedCustomer?.cxc) > 0 || parseFloat(selectedCustomer?.ndc_aplicadas) > 0 || parseFloat(selectedCustomer?.xamortizar) > 0) && (
                  <>
                    <Divider />
                    <ListItem disableRipple disableTouchRipple onClick={handleClose} style={{ height: '100%', cursor: 'default' }}>
                      <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 2 }}>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                          <span style={{ fontWeight: 300, fontSize: 'large' }}>Saldo</span>
                          {
                            parseFloat(selectedCustomer?.cxc) > 0 && (
                              <span style={{ fontSize: 14, whiteSpace: 'nowrap' }}>
                                <strong>
                                  Por Cobrar:

                                </strong>
                                {' '}
                                {formatMoney(selectedCustomer?.cxc) || 0}
                              </span>
                            )
                          }
                          {
                            selectedCustomer && selectedCustomer.ndcxaplicar && parseFloat(selectedCustomer.ndcxaplicar) > 0 && (
                              <span style={{ fontSize: 14, whiteSpace: 'nowrap' }}>
                                <strong>
                                  NDC por Aplicar:
                                </strong>
                                {' '}
                                {formatMoney(selectedCustomer?.ndcxaplicar) || 0}
                              </span>
                            )
                          }
                          {
                            selectedCustomer && parseFloat(selectedCustomer.xamortizar) > 0 && (
                              <span style={{ fontSize: 14, whiteSpace: 'nowrap' }}>
                                <strong>
                                  Anticipos a favor:
                                </strong>
                                {' '}
                                {formatMoney(selectedCustomer.xamortizar) || 0}
                              </span>
                            )
                          }
                          {
                            selectedCustomer && parseFloat(selectedCustomer.cxc_vencido) > 0 && (
                              <span style={{ fontSize: 14, whiteSpace: 'nowrap' }}>
                                <strong>
                                  Por Cobrar Vencido:
                                </strong>
                                {' '}
                                {formatMoney(selectedCustomer.cxc_vencido) || 0}
                              </span>
                            )
                          }
                        </div>
                      </div>
                    </ListItem>
                  </>
                )
              }
              </List>
            </Paper>
          )
        }
      </div>
    </MuiThemeProvider>

  );
};

export default CartSellMenu;
