import React from 'react';
import PropTypes from 'prop-types';
/* Material */
import {
  BottomNavigation,
  BottomNavigationAction,
} from '@material-ui/core';

const DiscountTabs = ({
  tab,
  navTypes,
  tanOnChange,
}) => (
  <BottomNavigation
    value={tab}
    onChange={tanOnChange}
    showLabels
  >
    {navTypes.map(type => (
      <BottomNavigationAction
        key={`key_${type.label}`}
        icon={type.icon}
        label={type.label}
      />
    ))}
  </BottomNavigation>
);

DiscountTabs.propTypes = {
  tab: PropTypes.number.isRequired,
  navTypes: PropTypes.array.isRequired,
  tanOnChange: PropTypes.func.isRequired,
};

export default DiscountTabs;
