/* eslint-disable import/no-cycle */
/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import {
  Tooltip,
  IconButton,
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  setActivePromotions, desactivePromotions, updatePromotions, resetPromotions,
} from '../../../../../redux/actions/cartActions';

const PromotionsAction = ({
  enable,
  tooltip,
  toggle,
}) => {
  const dispatch = useDispatch();
  const cart = useSelector(state => state.cart);
  const user = useSelector(state => state.authUser.user);
  const { activePromotions, products, orderSelected } = cart;
  const { config: { pos_promo_cart } } = user;
  const togglepromotions = async () => {
    if (activePromotions) {
      await dispatch(desactivePromotions());
      await dispatch(resetPromotions());
    } else {
      await dispatch(setActivePromotions());
    }
    await dispatch(updatePromotions());
    toggle();
  };


  /* React.useEffect(() => {
    if (enable) {
      if (products.length === 0 && Object.entries(orderSelected).length === 0 && pos_promo_cart === '1') {
        dispatch(setActivePromotions());
      }
    }
    if (!enable) {
      dispatch(desactivePromotions());
    }
  }, [orderSelected, enable]); */
  return (
    <Tooltip
      title={tooltip}
      placement="left"
    >
      <span>
        <IconButton
          id="action_control_sat"
          disabled
          onClick={() => {}}
          className="pos_menu_setDir"
        >
          {
            pos_promo_cart === '1' ? (
              <>
                <FontAwesomeIcon
                  icon="certificate"
                  style={{ width: '21px', heigth: '21px', color: '#23a1db' }}
                />
                <span className="font-size-10">Promociones activas</span>
              </>
            ) : (
              <>
                <FontAwesomeIcon
                  icon="certificate"
                  style={{ width: '21px', heigth: '21px' }}
                />
                <span className="font-size-10"> Promociones desactivadas</span>
              </>
            )
          }
        </IconButton>
      </span>
    </Tooltip>
  ); };

PromotionsAction.propTypes = {
  tooltip: PropTypes.string,
  toggle: PropTypes.func.isRequired,
  enable: PropTypes.bool.isRequired,
};
PromotionsAction.defaultProps = {
  tooltip: 'Activar promociones',
};

export default PromotionsAction;
