import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { formatMoney, getColorStatusSAT } from '../../../../../helpers/tools';
import Iconwidthbg from '../../../../../components/Icons/IconWidthBG/IconWidthBG';
import ListItemGeneric from '../../ListItemGeneric/ListItemGeneric';
import SatLogo from '../../../../../components/Icons/SatLogo';

const Invoice = ({ data }) => (
  <div className="cotizacion-container">
    {
                data ? data.map(item => (
                  <ListItemGeneric
                    key={item.crmid}
                    prependIcon={(
                      <Iconwidthbg
                        primaryColor={getColorStatusSAT(item.estadosat)}
                        height={27}
                        width={27}
                        classes="pr-2 pl-3"
                        square
                        rounded
                        enabled
                        actionClick={() => {}}
                      >
                        <SatLogo fill={`${data.estadosat === null ? '#B6BEC6' : 'white'}`} width="16px" height="16px" />
                      </Iconwidthbg>
)}
                    titleLeft={item.documento}
                    subTitleLeft={moment(item.modifiedtime).format('DD MMM, h:mm a')}
                    titleRight={`$${formatMoney(item.total)}`}
                    subTitleRight={`$${formatMoney(item.subtotal)}`}
                  />
                )) : null
            }

  </div>
);
Invoice.prototype = {
  data: PropTypes.array.isRequired,
};
export default Invoice;
