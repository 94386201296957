/* eslint-disable camelcase */
/* eslint-disable import/no-cycle */
import { connect } from 'react-redux';
import { openModal, closeModal } from '../../../../redux/actions/modalActions';
import {
  setActivityToOrder,
  setOrderCustomer,
} from '../../../../redux/actions/orderActions';
import {
  addItemsToTable,
  clearTable,
  chnageComment,
  changeFieldValue,
  selectToRefunded,
  selectToDelivered,
  selectEmptyToTable,
  refoundBadProduct,
  refoundChangeProduct,
} from '../../../../redux/actions/tableActions';
import {
  fetchTimbrado,
  handleMakeBill,
} from '../../../../redux/actions/TimbrarActions';
import { addDiscount } from '../../../../redux/actions/discountActions';
import {
  changeSalesOrderStatus,
  makeSell,
  deliverMoney,
  refundMoney,
  saveSell,
  refundAll,
} from '../../../../redux/actions/sellActions';

import { getOrderPdf } from '../../../../redux/actions/posActions';

import PosCartContainerAction from './PosCartContainerAction';
import { cotizar } from '../../../../redux/actions/cotizarActions';

const mapStateToProps = state => ({
  tabs: state.tabs,
  cart: state.cart,
  loads: state.loads,
  table: state.table,
  modals: state.modals,
  timbrar: state.timbrar,
  discount: state.discount,
  authUser: state.authUser,
  customer: state.customer,
});

const mapDispatchToProps = dispatch => ({
  /* Discount Dispatches */
  dispatchOpenDiscountModal: () => dispatch(openModal('discount')),
  dispatchCloseDiscountModal: () => dispatch(closeModal('discount')),
  dispatchAddDiscount: discount => dispatch(addDiscount(discount)),

  /* Checkout Dispatches */
  dispatchEditOrder: account_id => dispatch(setOrderCustomer(account_id)),
  dispatchOpenCheckOutModal: () => dispatch(openModal('checkout')),
  dispatchCloseCheckOutModal: () => dispatch(closeModal('checkout')),
  /* HOT FIX */
  dispatchChangeSalesOrderStatus: orderStatus => dispatch(changeSalesOrderStatus(orderStatus)),
  dispatchMakeSell: payments => dispatch(makeSell(payments)),
  dispatchDeliverMoney: (payments, orderId) => dispatch(deliverMoney(payments, orderId)),
  dispatchRefundMoney: (payments, orderId) => dispatch(refundMoney(payments, orderId)),

  /* CheckoutRefound Dispatches */
  dispatchOpenCheckOutRefoundModal: () => dispatch(openModal('checkoutRefound')),
  dispatchCloseCheckOutRefoundModal: () => dispatch(closeModal('checkoutRefound')),

  /* Deliver Dispatches */
  dispatchOpenDeliverModal: () => dispatch(openModal('deliver')),
  dispatchCloseDeliverModal: () => {
    dispatch(closeModal('deliver'));
    dispatch(clearTable());
  },
  dispatchGetOrderDocument: data => dispatch(getOrderPdf(data)),

  /* Refound Dispatches */
  dispatchRefundAll: object => dispatch(refundAll(object)),
  dispatchOpenRefoundModal: () => dispatch(openModal('refund')),
  dispatchCloseRefoundModal: () => {
    dispatch(closeModal('refund'));
    dispatch(clearTable());
  },

  /* Deliver and Refound Dispatches */
  dispatchSetActivityToOrder: (crmid, type) => dispatch(setActivityToOrder(crmid, type)),
  dispatchAddItemsToTable: typeAction => dispatch(addItemsToTable(typeAction)),

  /* Table Actions */
  dispatchSelectEmpty: () => dispatch(selectEmptyToTable()),
  dispatchChangeComment: comment => dispatch(chnageComment(comment)),
  dispatchChangeBadProducts: value => dispatch(refoundBadProduct(value)),
  dispatchChangeProducts: value => dispatch(refoundChangeProduct(value)),
  dispatchChangeFieldValue: obj => dispatch(changeFieldValue(obj)),
  dispatchSelectToRefunded: () => dispatch(selectToRefunded()),
  dispatchSelectToDelivered: () => dispatch(selectToDelivered()),

  /* Timbrar */
  dispatchFetchTimbrado: crmid => dispatch(fetchTimbrado(crmid)),
  dispatchHandleMakeBill: mode => dispatch(handleMakeBill(mode)),
  /* Cotizar */
  dispatchCotizar: () => dispatch(cotizar()),
  dispatchSaveSell: (orderStatus) => {
    dispatch(changeSalesOrderStatus(orderStatus));
    dispatch(saveSell());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PosCartContainerAction);
