/* eslint-disable array-callback-return */
/* eslint-disable import/no-cycle */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import './newExpenseForm.scss';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronRight, faHandHoldingUsd, faSave, faPlusCircle,
} from '@fortawesome/free-solid-svg-icons';
import {
  Collapse,
  LinearProgress,
} from '@material-ui/core';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import GetExpenseDialog from '../GetExpenseDialog';
import DetailExpenseDialog from '../DetailExpenseDialog';
import SearchDialog from '../SearchDialog';
import { openModal, closeModal } from '../../../../redux/actions/modalActions';
import ExpensesApi from '../../../../api/ExpensesApi';
import PayExpenseForm from './PayExpenseForm';
import { UploadFile } from './UploadFile';
import { AddAlmacen } from '../../../../redux/actions/expensesActions';
import ExpensesAPI from '../../../../api/ExpensesApi';


const NewExpenseForm = withRouter(({ history }) => {
  const dispatch = useDispatch();
  const authUser = useSelector(state => state.authUser);
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [type, setType] = React.useState('');
  const [openDetail, setOpenDetail] = React.useState(false);
  // const [openSearch, setOpenSearch] = React.useState(false);
  const [schema, setSchema] = React.useState(null);
  /* form */
  const [referencia, setReferencia] = React.useState('');
  const [description, setDescription] = React.useState('');
  const [total, setTotal] = React.useState();
  const [expenseId, setExpenseId] = React.useState('');
  const [canPay, setCanPay] = React.useState(false);

  const proveedor = useSelector(state => state.gastos.proveedor);
  const tipoGasto = useSelector(state => state.gastos.tipoGastoSeleccionado);
  const almacen = useSelector(state => state.gastos.almacenSeleccionado);
  const modals = useSelector(state => state.modals);

  const { user: { config: { store } } } = authUser;

  const expensesApi = new ExpensesApi();
  const getShema = async () => {
    const response = await expensesApi.getAddShemaExpense();
    const { success, result } = response;
    if (success) {
      setSchema(result[0].fields);
      result[0].fields.map((field) => {
        switch (field.name) {
          case 'referencia':
            setReferencia(field.value);
            break;
          case 'total':
            setTotal(field.value);
            break;
          default:
            break;
        }
      });
    }
  };

  const setStoreByDefault = async () => {
    const response = await expensesApi.getExpense(store.crmid);
    const { success, result } = response;
    if (success) {
      dispatch(AddAlmacen(result));
    }
  };

  React.useEffect(() => {
    getShema();
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    if (store) {
      setStoreByDefault();
    }
    // eslint-disable-next-line
  }, [store]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseDetail = () => {
    setOpenDetail(false);
  };

  const handleCloseSearch = () => {
    dispatch(closeModal('search'));
  };

  const openSearchDialog = (tipo) => {
    setType(tipo);
    dispatch(openModal('search'));
  };

  const checkCanPay = (value) => {
    setCanPay(value);
  };

  const validarPago = (pago) => {
    if (pago.referencia === '' || pago.referencia === null) {
      toast.error('El campo referencia es obligatorio');
      return false;
    }
    if (pago.total === '' || pago.total === null || pago.total === undefined) {
      toast.error('El campo total es obligatorio');
      return false;
    }

    return true;
  };

  const handleSubmit = async (e, pay = false) => {
    e.preventDefault();

    setLoading(true);
    const today = new Date();
    const date = `${today.getDate()}/${today.getMonth() + 1}/${today.getFullYear()}`;

    const nuevoGasto = {
      referencia,
      vendorid: proveedor ? proveedor.crmid : '',
      treebestdgid: tipoGasto ? tipoGasto.crmid : '',
      currency_id: 1,
      conversion_rate: 1,
      treebesalmid: almacen ? almacen.crmid : '',
      total,
      description,
      fecha: date,
    };

    if (validarPago(nuevoGasto)) {
      const response = await expensesApi.createExpense(nuevoGasto);
      const { success, result } = response;
      if (success) {
        toast.success('Gasto agregado!');
        if (pay) {
          setExpenseId(result.crmid);
        }
      }
    }
    setLoading(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case 'referencia':
        setReferencia(value);
        break;
      case 'total':
        setTotal(value);
        break;
      case 'description':
        setDescription(value);
        break;

      default:
        break;
    }
  };

  return (
    <>
      { loading && (
      <Collapse in={loading === true}>
        <LinearProgress />
      </Collapse>
      )}
      { schema === null && (
      <Collapse in={schema === null}>
        <LinearProgress />
      </Collapse>
      )}
      { schema !== null && (
      <Collapse in={schema !== null} style={{ overflow: 'auto', marginBottom: 75 }}>
        <div className="new-expense-form">
          <div className="new-expense-form__action-form">
            <form onSubmit={e => handleSubmit(e)}>
              {
              schema.map(item => (
                <div key={item.label} className="mb-3">
                  <div className="new-expense-form__action-form-custom-form-group">
                    {(item.name === 'referencia') && (
                    <div className="new-expense-form__action-form-custom-form-group mt-2">
                      <label htmlFor="" className="custom-label">{item.label}</label>
                      <input
                        className="custom-input"
                        type={item.type}
                        value={referencia}
                        name={item.name}
                        required={item.required}
                        onChange={e => handleChange(e)}
                      />
                    </div>
                    )}
                    {(item.name === 'total') && (
                    <div className="new-expense-form__action-form-custom-form-group mt-2">
                      <label htmlFor="" className="custom-label">{item.label}</label>
                      <input
                        className="custom-input"
                        type={item.type}
                        value={total}
                        name={item.name}
                        required={item.required}
                        onChange={e => handleChange(e)}
                      />
                    </div>
                    )}
                  </div>
                </div>
              ))
            }
              {
                    schema.map(item => (
                      <div key={item.name} className="d-flex flex-column">
                        {/* Vendedor */}
                        {item.name === 'vendorid' && (
                        <div className="new-expense-form__action-form-custom-form-group">
                          <FontAwesomeIcon
                            style={{
                              fontSize: 15, position: 'absolute', top: '55%', zIndex: 9, left: '95%',
                            }}
                            icon={faChevronRight}
                          />
                          <label htmlFor="" className="custom-label" />
                          <input
                            className="custom-input slim-input"
                            readOnly
                            type={item.type}
                            name={item.name}
                            placeholder="Proveedor"
                            required={item.required}
                            onChange={e => handleChange(e)}
                            value={proveedor ? proveedor.vendorname : ''}
                            onClick={() => openSearchDialog('proveedor')}
                          />
                        </div>
                        )}
                        {/* Almacen */}
                        {item.name === 'treebesalmid' && (
                        <div className="new-expense-form__action-form-custom-form-group">
                          <FontAwesomeIcon
                            style={{
                              fontSize: 15, position: 'absolute', top: '55%', zIndex: 9, left: '95%',
                            }}
                            icon={faChevronRight}
                          />
                          <label htmlFor="" className="custom-label" />
                          <input
                            readOnly
                            type="text"
                            placeholder="Almacen"
                            required={item.required}
                            className="custom-input slim-input"
                            value={almacen ? almacen.entity_label : ''}
                            onClick={() => openSearchDialog('almacen')}
                          />
                        </div>
                        )}
                      </div>
                    ))
                  }
              <ExpansionPanel classes={{ root: 'expansion-panel-expende' }} >
                <ExpansionPanelSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>Más opciones</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails classes={{ root: 'flex-column' }}>
                  {
                    schema.map(item => (
                      <div key={item.name} className="d-flex flex-column">
                        {/* Tipo de gasto */}
                        {item.name === 'treebestdgid' && (
                        <div className="new-expense-form__action-form-custom-form-group">
                          <FontAwesomeIcon
                            style={{
                              fontSize: 15, position: 'absolute', top: '55%', zIndex: 9, left: '95%',
                            }}
                            icon={faChevronRight}
                          />
                          <label htmlFor="" className="custom-label" />
                          <input
                            readOnly
                            type="text"
                            required={item.required}
                            placeholder="Tipo de gasto"
                            className="custom-input slim-input"
                            value={tipoGasto ? tipoGasto.nombre : ''}
                            onClick={() => openSearchDialog('gasto')}
                          />
                        </div>
                        )}
                        {(item.name === 'fecha' || item.name === 'currency_id' || item.name === 'conversion_rate') && (
                        <input className="custom-input" name={item.name} type={item.type} value={item.value} hidden />
                        )}
                        {(item.name === 'description') && (
                        <div className="new-expense-form__action-form-custom-form-group mt-2">
                          <label htmlFor="" className="custom-label">{item.label}</label>
                          <input className="custom-input" type={item.type} value={description} name={item.name} required={item.required} onChange={e => handleChange(e)} />
                        </div>
                        )}
                      </div>
                    ))
                  }
                </ExpansionPanelDetails>
              </ExpansionPanel>
            </form>
            <hr />
            <UploadFile expenseId={expenseId} />
            <hr />
            <PayExpenseForm granTotal={total} parcial={total} expense={expenseId} checkCanPay={checkCanPay} />

            <span className="text-muted">
              * Si desea pagar el gasto con varias cuantas debe seleccionar la cuanta y el monto a pagar utilizando el boton
              {'   '}
              <FontAwesomeIcon
                style={{
                  fontSize: 18, color: '#313C52',
                }}
                icon={faPlusCircle}
              />
              {'   '}
              antes de efectuar la acción, de lo contrario se guardara el gasto con el monto agregado en el campo total.
            </span>
            <div className="add-new-expense-form">

              <button type="button" className="add-new-expense-form__save-action-btn" onClick={e => handleSubmit(e, false)}>
                <FontAwesomeIcon
                  style={{ fontSize: 28, marginBottom: 5 }}
                  icon={faSave}
                />
                Guardar
              </button>
              <button type="button" className="add-new-expense-form__pay-action-btn" onClick={e => handleSubmit(e, true)}>
                <FontAwesomeIcon
                  style={{ fontSize: 28, marginBottom: 5 }}
                  icon={faHandHoldingUsd}
                />
                Guardar y Pagar
              </button>
            </div>
          </div>
        </div>

      </Collapse>
      )}

      <DetailExpenseDialog open={openDetail} handleClose={handleCloseDetail} />
      <GetExpenseDialog open={open} handleClose={handleClose} />
      <SearchDialog open={Boolean(modals.searchModalIsOpen)} handleClose={handleCloseSearch} type={type} />
    </>
  ); });

export default NewExpenseForm;
