import { toast } from 'react-toastify';
import AccountAPI from '../../api/AccountAPI';
import { loading, loaded } from './loadActions';

import {
  GET_UPDATES,
  GET_EMAIL_LIST,
  GET_ACCOUNT_BALANCE,
  GET_ACCOUNT_DOCUMENTS,
  GET_UPDATES_PAGINATOR,
  GET_EMAIL_LIST_PAGINATOR,
  GET_ACCOUNT_CONTACT_PHONES,
  GET_ACCOUNT_CONTACT_EMAILS,
  GET_ACCOUNT_CONTACT_ADDRESS,
  GET_ACCOUNT_DOCUMENTS_PAGINATOR,
  GET_ACCOUNT,
  GET_ACCOUNT_PAGINATOR,
} from '../types/accountActionsType';

export function getAccount(account, pagination) {
  return {
    type: pagination ? GET_ACCOUNT_PAGINATOR : GET_ACCOUNT,
    payload: account,
  };
}

export function getUpdates(updates, pagination) {
  return {
    type: pagination ? GET_UPDATES_PAGINATOR : GET_UPDATES,
    payload: updates,
  };
}

export function getEmails(emails, pagination) {
  return {
    type: pagination ? GET_EMAIL_LIST_PAGINATOR : GET_EMAIL_LIST,
    payload: emails,
  };
}

export function getAccountBalance(balance) {
  return {
    type: GET_ACCOUNT_BALANCE,
    payload: balance,
  };
}

export function getAccountContactEmails(emails) {
  return {
    type: GET_ACCOUNT_CONTACT_EMAILS,
    payload: emails,
  };
}

export function getAccountContactPhones(phones) {
  return {
    type: GET_ACCOUNT_CONTACT_PHONES,
    payload: phones,
  };
}
export function getAccountContactAddress(address) {
  return {
    type: GET_ACCOUNT_CONTACT_ADDRESS,
    payload: address,
  };
}

export function getDocuments(documents, pagination) {
  return {
    type: pagination ? GET_ACCOUNT_DOCUMENTS_PAGINATOR : GET_ACCOUNT_DOCUMENTS,
    payload: documents,
  };
}

const displayWarningErrorMessage = (message) => {
  if (message.warning) {
    toast.warn(` ❕ ${message.warning}`);
  }

  else if (message.error) {
    toast.error(` ❌ ${message.error}`);
  }
};

export function fetchAllAccounts(page = 1, search = '', accountId = 0, pagination = false) {
  const accountApi = new AccountAPI();
  return async (dispatch) => {
    dispatch(loading('update'));
    try {
      const response = await accountApi.getAll(page, search, accountId, pagination);
      if (process.env.NODE_ENV === 'development') {
        console.log('===================fetchAllAccount=================');
        console.log({ response });
        console.log('====================================');
      }
      const { success, result, message } = response;
      if (success) {
        dispatch(getAccount(result));
      }
      else {
        toast.error('Error en la api');
      }
      displayWarningErrorMessage(message);
    }
    catch (err) {
      toast.error(err);
    }

    finally {
      dispatch(loaded('update'));
    }
  };
}

export function fetchAccountUpdates(id) {
  const accountApi = new AccountAPI();
  return async (dispatch) => {
    dispatch(loading('update'));
    try {
      const response = await accountApi.getUpdatesById(id);
      if (process.env.NODE_ENV === 'development') {
        console.log('===================fetchAccountUpdates=================');
        console.log({ response });
        console.log('====================================');
      }
      const { success, result, message } = response;
      if (success) {
        dispatch(getUpdates(result));
      }
      else {
        toast.error('Error en la api');
      }
      displayWarningErrorMessage(message);
    }
    catch (err) {
      toast.error(err);
    }

    finally {
      dispatch(loaded('update'));
    }
  };
}

export function fetchEmails(page = 1, search = '', accountId = 0, pagination = false) {
  const accountApi = new AccountAPI();
  return async (dispatch) => {
    dispatch(loading('update'));
    try {
      const response = await accountApi.getListEmailsByAccount(page, search, accountId);
      if (process.env.NODE_ENV === 'development') {
        console.log('===================getListEmailsByAccount=================');
        console.log({ response });
        console.log('====================================');
      }
      const {
        success, result, pages, message,
      } = response;
      if (success) {
        dispatch(getEmails({ result, pages }, pagination));
      }
      else {
        toast.error('Error en la api');
      }
      displayWarningErrorMessage(message);
    }
    catch (err) {
      toast.error(err);
    }

    finally {
      dispatch(loaded('update'));
    }
  };
}

export function fetchAccountDocuments(page = 1, search = '', accountId = 0, pagination = false) {
  const accountApi = new AccountAPI();
  return async (dispatch) => {
    dispatch(loading('update'));
    try {
      const response = await accountApi.getAccountDocuments(page, search, accountId);
      if (process.env.NODE_ENV === 'development') {
        console.log('===================getAccountDocuments=================');
        console.log({ response });
        console.log('====================================');
      }
      const {
        success, result, pages, message,
      } = response;
      if (success) {
        dispatch(getDocuments({ result, pages }, pagination));
      }
      else {
        toast.error('Error en la api');
      }
      displayWarningErrorMessage(message);
    }

    catch (err) {
      toast.error(err);
    }

    finally {
      dispatch(loaded('update'));
    }
  };
}

export function fetchAccountBalance(accountId) {
  const accountApi = new AccountAPI();
  return async (dispatch) => {
    dispatch(loading('update'));
    try {
      const response = await accountApi.getAccountBalance(accountId);
      if (process.env.NODE_ENV === 'development') {
        console.log('===================getAccountBalance=================');
        console.log({ response });
        console.log('====================================');
      }
      const { success, result, message } = response;
      if (success) {
        dispatch(getAccountBalance(result));
      }
      else {
        toast.error('Error en la api');
      }
      displayWarningErrorMessage(message);
    }

    catch (err) {
      toast.error(err);
    }

    finally {
      dispatch(loaded('update'));
    }
  };
}

export function fetchAccountContactsEmails(accountId) {
  const accountApi = new AccountAPI();
  return async (dispatch) => {
    dispatch(loading('account'));
    try {
      const response = await accountApi.getAccountContactsEmail(accountId);
      if (process.env.NODE_ENV === 'development') {
        console.log('===================getAccountContactEmails=================');
        console.log({ response });
        console.log('====================================');
      }
      const { success, result, message } = response;
      if (success) {
        dispatch(getAccountContactEmails(result));
      }
      else {
        toast.error('Error en la api');
      }
      displayWarningErrorMessage(message);
    }

    catch (err) {
      toast.error(err);
    }

    finally {
      dispatch(loaded('account'));
    }
  };
}

export function fetchAccountContactsPhones(accountId) {
  const accountApi = new AccountAPI();
  return async (dispatch) => {
    dispatch(loading('account'));
    try {
      const response = await accountApi.getAccountContactsPhones(accountId);
      if (process.env.NODE_ENV === 'development') {
        console.log('===================getAccountContactPhones=================');
        console.log({ response });
        console.log('====================================');
      }
      const { success, result, message } = response;
      if (success) {
        dispatch(getAccountContactPhones(result));
      }
      else {
        toast.error('Error en la api');
      }
      displayWarningErrorMessage(message);
    }

    catch (err) {
      toast.error(err);
    }

    finally {
      dispatch(loaded('account'));
    }
  };
}

export function fetchAccountContactsAddress(accountId) {
  const accountApi = new AccountAPI();
  return async (dispatch) => {
    dispatch(loading('account'));
    try {
      const response = await accountApi.getAccountContactsAddress(accountId);
      if (process.env.NODE_ENV === 'development') {
        console.log('===================getAccountContactAddress=================');
        console.log({ response });
        console.log('====================================');
      }
      const { success, result, message } = response;
      if (success) {
        dispatch(getAccountContactAddress(result));
      }
      else {
        toast.error('Error en la api');
      }
      displayWarningErrorMessage(message);
    }

    catch (err) {
      toast.error(err);
    }

    finally {
      dispatch(loaded('account'));
    }
  };
}
