/* eslint-disable import/no-cycle */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React from 'react';
import PropTypes from 'prop-types';
import './ExpenseDetailForm.scss';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDollarSign } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { LinearProgress } from '@material-ui/core';
import GetExpenseDialog from '../../../GetExpenseDialog';
import { formatMoney } from '../../../../../../helpers/tools';
import { closeModal } from '../../../../../../redux/actions/modalActions';

const ExpenseDetailForm = ({ action }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const modals = useSelector(state => state.modals);
  const gasto = useSelector(state => state.gastos.gastoSeleccionado);
  const loads = useSelector(state => state.loads);

  const handleClickOpen = () => {
    if (parseInt(gasto.tfde_balance, 10) === 0) {
      toast.success(`El gasto "${gasto.referencia}" ya fue pagado `);
    } else {
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
    dispatch(closeModal('pay'));
  };

  React.useEffect(() => {
    if (modals.payModalIsOpen) {
      setOpen(true);
    }
  });


  return (
    <>
      { loads.updateIsLoading && (
      <LinearProgress />
      )}
      { !loads.updateIsLoading && gasto && (
      <div className="new-expense-form">
        <div className="new-expense-form__action-form">
          <div className="new-expense-form__action-form-custom-form-group">
            <label htmlFor="" className="custom-label">Referencia:</label>
            <input className="custom-input" type="text" placeholder="Referencia de almacen" value={`${gasto ? gasto.referencia : ''}`} readOnly />
          </div>
          <div className="new-expense-form__action-form-custom-form-group">
            <label htmlFor="" className="custom-label" />
            <input className="custom-input slim-input" type="text" placeholder="Proveedor" value={`${gasto && gasto.vendorid_label ? gasto.vendorid_label : ''}`} readOnly />
          </div>
          <div className="new-expense-form__action-form-custom-form-group">
            <label className="custom-label" />
            <input className="custom-input slim-input" type="text" placeholder="Almacen" value={`${gasto ? gasto.treebesalmid_label : ''}`} readOnly />
          </div>
          <div className="new-expense-form__action-form-custom-form-group">
            <label htmlFor="" className="custom-label" />
            <input className="custom-input slim-input" type="text" placeholder="Tipo de gasto" value={`${gasto && gasto.treebestdgid_label ? gasto.treebestdgid_label : ''}`} readOnly />
          </div>
          <div className="new-expense-form__action-form-custom-form-group mt-3">
            <label htmlFor="" className="custom-label">Descripción:</label>
            <input className="custom-input" type="text" placeholder="Gasto total" value={`${gasto ? gasto.description : ''}`} readOnly />
          </div>
          <div className="new-expense-form__action-form-custom-form-group mt-3">
            <FontAwesomeIcon
              style={{
                fontSize: 19, position: 'absolute', top: '60%', zIndex: 9, left: '92%', color: '#23A1DB',
              }}
              icon={faDollarSign}
            />
            <label htmlFor="" className="custom-label">Total:</label>
            <input className="custom-input" type="text" value={`${gasto ? formatMoney(gasto.total) : ''}`} readOnly />
          </div>
        </div>
        <div className="new-expense-form__action-btn">
          {
            // eslint-disable-next-line no-nested-ternary
            action === 'edit'
              ? (
                <button className="btn-detail" type="button" onClick={() => {}}>Editar</button>
              )
              : (formatMoney(gasto.tfde_balance) === '0.00' ? null : (
                <button className="btn-detail" type="button" onClick={() => handleClickOpen()}>Pagar</button>
              ))
          }
        </div>
      </div>
      )}
      <GetExpenseDialog open={open || Boolean(modals.payModalIsOpen)} handleClose={handleClose} />
    </>
  ); };

ExpenseDetailForm.propTypes = {
  action: PropTypes.string,
};
ExpenseDetailForm.defaultProps = {
  action: 'view',
};

export default ExpenseDetailForm;
