const appModules = {
  Quotes: 'Quotes',
  SalesOrder: 'SalesOrder',
  TreebesFdE: 'TreebesFdE',
  PurchaseOrder: 'PurchaseOrder',
  TreebesSatCFDI: 'TreebesSatCFDI',
  TreebesGTO: 'TreebesGTO',
  TreebesMdAG: 'TreebesMdAG',
  Accounts: 'Accounts',
  TreebesSolicitudesEnvio: 'TreebesSolicitudesEnvio',
};

export default appModules;
