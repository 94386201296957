/* eslint-disable no-case-declarations */
import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  Tooltip,
  IconButton,
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const SeeOnCrmAction = ({
  enable,
  tooltip,
  appLocation,
  product,
}) => {
  const domain = useSelector(state => state.authUser.domain);
  const cart = useSelector(state => state.cart);
  const tabs = useSelector(state => state.tabs);
  const customer = useSelector(state => state.customer);
  const { orderSelected } = cart;
  const { posTypeTab } = tabs;
  const { selectedCustomer } = customer;
  const goToCrm = () => {
    let url = '';
    switch (appLocation) {
      case 'client':
        url = `https://${domain}/index.php?module=Accounts&view=Detail&record=${selectedCustomer.crmid}`;
        /* url = `https://${domain}/index.php?module=Accounts&view=Detail&record=${selectedCustomer.crmid}&WPZxkAb5X28skewDnCeh6cNDE66H6IUp`; */
        break;
      case 'product':
        const productid = (product.productid && product.productid.indexOf('x') !== -1)
          ? product.productid.split('x').pop()
          : product.productid;
        url = `https://${domain}/index.php?module=TreebesProductosOC&view=Detail&record=${product.crmid || productid}`;
        break;
      case 'orden':
        if (posTypeTab === 'Historico') {
          url = `https://${domain}/index.php?module=SalesOrder&view=Detail&record=${orderSelected.crmid}`;
        }
        break;
      case 'cotizacion':
        if (posTypeTab === 'Cotizaciones') {
          url = `https://${domain}/index.php?module=Quotes&view=Detail&record=${orderSelected.crmid}`;
        }
        break;
      default:
        if (posTypeTab === 'Cotizaciones') {
          url = `https://${domain}/index.php?module=Quotes&view=Detail&record=${orderSelected.crmid}`;
        }
        if (posTypeTab === 'Historico') {
          url = `https://${domain}/index.php?module=SalesOrder&view=Detail&record=${orderSelected.crmid}`;
        }
        break;
    }
    window.open(url, '_blank');
  };

  return (
    <Tooltip
      title={tooltip}
      placement="left"
    >
      <span>
        <IconButton
          id="action_control_see"
          disabled={!enable}
          onClick={() => goToCrm()}
          className="pos_menu_setDir"
        >
          <FontAwesomeIcon
            icon="eye"
            style={{ width: '21px', heigth: '21px' }}
          />
          {
            appLocation !== 'product' && (
              <span className="font-size-10">Ver en sistema</span>
            )
          }
        </IconButton>
      </span>
    </Tooltip>
  ); };

SeeOnCrmAction.propTypes = {
  tooltip: PropTypes.string,
  enable: PropTypes.bool.isRequired,
  appLocation: PropTypes.string,
  product: PropTypes.object,
};
SeeOnCrmAction.defaultProps = {
  tooltip: 'See on crm',
  appLocation: 'General',
  product: {},
};

export default SeeOnCrmAction;
