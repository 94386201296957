import React from 'react';
import PropTypes from 'prop-types';
import {
  BottomNavigation,
  BottomNavigationAction,
  Tooltip,
} from '@material-ui/core';
import { useSelector } from 'react-redux';

const RefundTabs = ({
  navTypes,
  tab,
  tanOnChange,
}) => {
  const table = useSelector(state => state.table);
  const { items } = table;
  const disablePartial = items && items.length === 1 && parseInt(items[0].quantity, 10) === 1;
  return (
  <div className="d-flex flex-column align-items-center">
    <span className="font-size-12">Tipo de devolución:</span>
    <BottomNavigation
      value={tab}
      onChange={tanOnChange}
      showLabels
      className="refund_tabs"
    >
      {navTypes.map((type, i) => (
        <Tooltip
          key={`key_refund_${type.label}`}
          title={type.tooltip}
          placement={i === 0
            ? ('left-start')
            : ('right-start')
          }
        >
          <BottomNavigationAction
            key={`key_refund_${type.label}`}
            icon={type.icon}
            label={type.label}
            disabled={type.label === 'Parcial' ? disablePartial : false}
          />
        </Tooltip>
      ))}
    </BottomNavigation>
  </div>
);};

RefundTabs.propTypes = {
  navTypes: PropTypes.array.isRequired,
  tab: PropTypes.number.isRequired,
  tanOnChange: PropTypes.func.isRequired,
};

export default RefundTabs;
