/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import '../../../ClientPage/components/SalesDetail/SalesDetail.scss';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faShareAlt,
} from '@fortawesome/free-solid-svg-icons';
import { formatMoney } from '../../../../helpers/tools';

const WPurchaseExpand = ({ purchase, openShare, orderReceiver }) => {
  const userName = `${purchase.assigned_user_data.first_name} ${purchase.assigned_user_data.last_name}`;

  return (
    <>
      <List component="nav" aria-label="main mailbox folders" dense>
        <ListItem selected classes={{ selected: 'salesDetail-list-selected' }}>
          <ListItemText className="ml-2 text-left" primary="No. :" classes={{ primary: 'salesDetail-list-text-left' }} />
          <ListItemText className="p-0 text-right" primary={purchase.subject} classes={{ primary: 'salesDetail-list-text-right' }} />
        </ListItem>

        <ListItem selected classes={{ selected: 'salesDetail-list-selected' }}>
          <ListItemText className="ml-2 text-left" primary="Almacén:" classes={{ primary: 'salesDetail-list-text-left' }} />
          <ListItemText className="p-0 text-right" primary={purchase.treebesalmid_label} classes={{ primary: 'salesDetail-list-text-right' }} />
        </ListItem>

        <ListItem selected classes={{ selected: 'salesDetail-list-selected' }}>
          <ListItemText className="ml-2 text-left" primary="Balance:" classes={{ primary: 'salesDetail-list-text-left' }} />
          <ListItemText className="p-0 text-right" primary={`$${formatMoney(purchase.balance)}`} classes={{ primary: 'salesDetail-list-text-right' }} />
        </ListItem>

      </List>
      <List component="nav" aria-label="secondary mailbox folders" className="d-flex justify-content-around">
        <button
          type="button"
          onClick={() => openShare(purchase)}
          style={{
            backgroundColor: '#23A1DB', color: 'white', fontSize: 15, padding: '10px 20px', borderRadius: 17, border: 0,
          }}
        >
          <FontAwesomeIcon style={{ fontSize: 15 }} icon={faShareAlt} />
        </button>
        <button
          type="button"
          onClick={orderReceiver}
          style={{
            backgroundColor: '#23A1DB', color: 'white', fontSize: 15, padding: '10px 20px', borderRadius: 17, border: 0,
          }}
        >
          Recibir
        </button>
      </List>

    </>
  ); };
WPurchaseExpand.propTypes = {
  purchase: PropTypes.object.isRequired,
  openShare: PropTypes.func.isRequired,
  orderReceiver: PropTypes.func.isRequired,
};
export default WPurchaseExpand;
