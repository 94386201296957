/* eslint-disable camelcase */
/* eslint-disable import/no-cycle */
/* eslint-disable radix */
/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { IconButton, Menu, Tooltip } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTools } from '@fortawesome/free-solid-svg-icons';
import {
  shouldRefoundMoney,
  shouldRefoundProducts,
} from '../../../../../helpers/order';
/* Controls */
import PrintControls from '../PrintControls';
import ShareControls from '../ShareControls';
/* Actions */
import AddDescAction from '../components/AddDescAction';
import CloneAction from '../components/CloneAction';
import GeneralDiscountAction from '../components/GeneralDiscountAction';
import QuoteSellAction from '../components/QuoteSellAction';
import RefundAction from '../components/RefundAction';
import SaveSellAction from '../components/SaveSellAction';
import SyncCartAction from '../components/SyncCart';
import TimbrarAction from '../components/TimbrarAction';
import DiscountTaxesAction from '../components/DiscountTaxesAction';
import PromotionsAction from '../components/PromotionsAction';
import ChangeCartPrice from '../components/ChangeCartPrice';
import SeeOnCrmAction from '../components/SeeOnCrmAction';
import GoToAddressAction from '../components/GoToAddressAction';
import ShareUrl from '../components/ShareUrl';
import CustomFields from '../components/CustomFields';
import Shipping from '../components/shipping/ShippingAction';
import { setDiscountTaxes } from '../../../../../redux/actions/filtersActions';
import {
  addOrderToCart,
  addProductToCart,
  clearCart,
  updateTotals,
} from '../../../../../redux/actions/cartActions';
import { removeDiscount } from '../../../../../redux/actions/discountActions';
import { handleEditOrder } from '../../../../../redux/actions/orderActions';
import appModules from '../../../../../constant/appModules';
import ExternalDeliveryAction from '../components/ExternalDeliveryAction';
import MenuBase from '../../../../rkr2-components/Menu/MenuBase';

const ActionControls = ({
  tabs,
  cart,
  loads,
  tooltip,
  authUser,
  customer,
  keyBoard,
  dispatchCotizar,
  dispatchCloneQuote,
  dispatchSyncCart,
  dispatchMakeSell,
  dispatchOpenModal,
  dispatchEditOrder,
  dispatchFetchTimbrado,
  dispatchOpenRefundModal,
  history,
}) => {
  const dispatch = useDispatch();
  const enableEditOrder = useSelector(state => state.order.enableEditOrder);
  const currentTabs = useSelector(state => state.tabs);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [ctrl, setCrtrl] = React.useState(false);
  const [tootletip, setTooltip] = React.useState(false);
  const open = Boolean(anchorEl);
  const { user } = authUser;
  const { products } = cart;
  const arePricebooks = Boolean(user.PriceBooks && user.PriceBooks.length > 0);
  const areProducts = Boolean(products && products.length > 0);

  function handleClick(event) {
    setCrtrl(true);
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setCrtrl(false);
    setTooltip(false);
    setAnchorEl(null);
  }

  React.useEffect(() => {
    if (keyBoard && keyBoard.ctrlButton && keyBoard.altButton) {
      setCrtrl(true);
      setTooltip(true);
    } else {
      handleClose();
    }
  }, [keyBoard]);
  /* Constants */
  const { posTypeTab } = tabs;
  // eslint-disable-next-line camelcase
  const {
    user: {
      config: { sostatus, store },
      hay_promociones,
      almacenes,
    },
  } = authUser;

  const {
    total,
    balance,
    description,
    orderSelected,
    totalProducts,
    cartProductsById,
  } = cart;
  const customerCrmid = customer.selectedCustomer
    ? customer.selectedCustomer.crmid
    : '';
  const orderNo = orderSelected && orderSelected.order
    ? orderSelected.order.salesorder_no || orderSelected.order.label
    : '';
  const orderId = orderSelected && orderSelected.order ? orderSelected.order.crmid : '';
  const estadosat = orderSelected ? orderSelected.estadosat : '';
  const almacen = orderSelected ? orderSelected.cartStateWarehouse : '';
  const lastFacturaDate = orderSelected ? orderSelected.f_ultima_factura : '';
  const cfdis = orderSelected ? Number.parseInt(orderSelected.cfdis) : 0;
  /* Enables */
  const existProducts = Boolean(totalProducts > 0);
  // eslint-disable-next-line no-prototype-builtins
  const isOrder = orderSelected.order && orderSelected.order.hasOwnProperty('salesorder_no');
  // eslint-disable-next-line no-prototype-builtins
  const isQuote = orderSelected.order && orderSelected.order.hasOwnProperty('quote_no');
  const enableSaveSellandQuote = Boolean(
    !loads.orderIsLoading && posTypeTab === 'Vender' && totalProducts > 0,
  );
  const haveDescription = Boolean(description);
  const enableTimbrar = Boolean(
    isOrder
      /* && customerCrmid !== '54572' */
      && (lastFacturaDate === ''
        || lastFacturaDate == null
        || lastFacturaDate === '0000-00-00 00:00:00')
      && (estadosat === 'Sin Factura' || estadosat == null)
      && cfdis === 0,
  );
  const enableRefundMoney = Boolean(shouldRefoundMoney(balance, total));
  const enableRefundProdu = Boolean(shouldRefoundProducts(almacen || ''));
  const enableRefund = Boolean(
    isOrder && (enableRefundMoney || enableRefundProdu),
  );
  const enableSync = Boolean(totalProducts > 0 && !loads.orderIsLoading);
  const enableToSee = Boolean(
    totalProducts > 0
      && (posTypeTab === 'Historico' || posTypeTab === 'Cotizaciones'),
  );
  // eslint-disable-next-line camelcase
  const {
    user: { sin_impuestos },
  } = authUser;
  const { order } = orderSelected;
  const { selectedCustomer, defaultCustomer } = customer;

  const { pos_allowed_sostatus, pos_sell_tab } = user.config;
  const allowOrderStatus = pos_allowed_sostatus.split(' |##| ');
  const enableEdit = Boolean(
    order
      ? (order.estado_mda === 'Sin Entregar'
          || order.estado_mda === 'N/A'
          || order.estado_mda === 'Sin Recibir')
          && allowOrderStatus.includes(order.sostatus)
          && (order.estadosat === 'Cancelado'
            || order.estadosat === 'Sin Factura'
            || order.estadosat == null)
          && (order.sostatus === 'Creado' || order.sostatus === 'Apartado')
          && order.estado_fde === 'Sin Pago/Cobro'
      : false,
  );

  const enableChangeListPrice = () => {
    let isEnable = false;
    if (Object.keys(orderSelected).length) {
      if (isOrder) {
        isEnable = enableEdit;
      } else if (isQuote) {
        isEnable = true;
      }
    } else if (existProducts) {
      isEnable = true;
    }

    return isEnable;
  };

  const { shipping_id } = user;
  const getShippingProduct = products.find(
    prod => prod.crmid === shipping_id,
  );

  let enableDiscoutTaxes = false;
  const enableDiscountOrderTaxes = Boolean(sin_impuestos.SalesOrder && order);
  const enableDiscountQuoteTaxes = Boolean(sin_impuestos.Quotes && order);
  const selectStore = almacenes && almacenes.length
    ? almacenes.find(alm => alm.crmid === store.crmid)
    : null;
  const enableDiscountSellTaxes = Boolean(
    selectStore && selectStore.pos_forzaventasiniva === '1',
  );

  const disableButton = !isOrder || cart?.orderSelected?.order?.estado_mda === 'Entregado';

  if (posTypeTab === 'Historico') {
    enableDiscoutTaxes = enableDiscountOrderTaxes;
  }
  if (posTypeTab === 'Cotizaciones') {
    enableDiscoutTaxes = enableDiscountQuoteTaxes;
  }
  if (posTypeTab === 'Vender') {
    enableDiscoutTaxes = enableDiscountSellTaxes;
  }

  /* Ctrl */
  if (ctrl && anchorEl === null) {
    const element = document.querySelector('#cart-actions-btn');
    if (element) setAnchorEl(element);
  }

  const toggleTaxesDiscount = () => {
    if (currentTabs.posTypeTab === 'Vender' && !order) {
      dispatch(updateTotals());
    } else {
      dispatch(addOrderToCart(order));
      if (
        !enableEditOrder
        && Object.keys(orderSelected).length
        && currentTabs.posTypeTab === 'Historico'
      ) {
        dispatch(handleEditOrder());
      }
    }
  };

  const necesitaEnvio = () => {
    if (selectedCustomer.crmid === defaultCustomer.crmid) {
      return false;
    }
    const selectedStore = almacenes.length > 0
      ? almacenes.find(alm => alm.crmid === store.crmid)
      : null;
    if (
      selectedStore
      && selectedStore.pos_forzaenvio === '1'
      && !getShippingProduct
    ) {
      return true;
    }
    return false;
  };

  const saveEditOrder = async () => {
    if (isOrder) {
      dispatchEditOrder();
    } else {
      await dispatchMakeSell(sostatus);
      if (!pos_sell_tab) {
        dispatch(clearCart());
      }
    }
  };

  return (
    <>
      {/* <Tooltip
        open={tootletip}
        title={tooltip}
        placement="top"
        classes={{ tooltip: 'maxToolTip' }}
      >
        <span className="d-flex">
          <IconButton
            id="cart-actions-btn"
            aria-label="More"
            aria-controls="cart-actions-menu"
            aria-haspopup="true"
            onClick={handleClick}
          >
            <FontAwesomeIcon
              icon={faTools}
              style={{ fontSize: '20px' }}
            />
          </IconButton>
        </span>
      </Tooltip> */}
      {/* <Menu
        id="cart-actions-menu"
        keepMounted
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        className="cart_controls "
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        getContentAnchorEl={null}
        classes={{
          paper: `${
            currentTabs.posTypeTab === 'Historico'
              ? 'cart_controls_list'
              : 'cart_controls_list-quote'
          }`,
        }}
      > */}
      <MenuBase
        menuIcon={(
          <FontAwesomeIcon
            icon={faTools}
            style={{ fontSize: '20px' }}
          />
        )}
        styles={{ top: 52, left: '-112px' }}
        clickOutside={false}
      >
        <div style={{ display: 'flex' }}>
          <div style={{
            display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
          }}
          >
            {currentTabs.posTypeTab !== 'Cotizaciones' && (
              <SaveSellAction
                enable={enableSaveSellandQuote}
                saveSell={() => {
                  saveEditOrder();
                  handleClose();
                }}
                tooltip={isOrder ? 'Guardar Orden Editada' : 'Generar Venta'}
              />
            )}
            <PrintControls closeMain={handleClose} />
            {/* <ShareControls /> */}
            <QuoteSellAction
              enable={enableSaveSellandQuote}
              cotizar={() => {
                dispatchCotizar();
                handleClose();
                history.push('/pos/cotizaciones');
              }}
            />
            {currentTabs.posTypeTab !== 'Cotizaciones' && (
              <AddDescAction
                isOrder={isOrder}
                enable={existProducts}
                haveDescription={haveDescription}
                add={() => {
                  dispatchOpenModal('description');
                  handleClose();
                }}
                tooltip={
                  haveDescription
                    ? `Descripción: ${description}`
                    : isOrder
                      ? 'Descripción'
                      : 'Añade una descripción'
                }
              />
            )}

            <GeneralDiscountAction
              enable={enableSaveSellandQuote}
              add={() => {
                dispatch(removeDiscount());
                dispatchOpenModal('discount');
                handleClose();
              }}
            />

            {/*  {
              currentTabs.posTypeTab !== 'Cotizaciones' && (
                <SyncCartAction
                  enable={enableSync}
                  tooltip={enableSync
                    ? 'Sincronizar Carrito'
                    : 'Se necesitan productos en el carrito para sincronizarlos'
                  }
                  syncCart={() => {
                    dispatchSyncCart();
                    handleClose();
                  }}
                />
              )
            } */}

            {/* links */}
            <ShareUrl enable={arePricebooks && areProducts} />

            {currentTabs.posTypeTab === 'Historico' && (
              <>
                <TimbrarAction
                  enable={enableTimbrar}
                  timbrar={() => {
                    dispatchFetchTimbrado(orderId);
                    dispatchOpenModal('timbrar');
                    handleClose();
                  }}
                />
                <RefundAction
                  enable={enableRefund}
                  refundProducts={() => {
                    dispatchOpenRefundModal();
                    handleClose();
                  }}
                />
              </>
            )}

            {currentTabs.posTypeTab !== 'Vender' && (
              <CloneAction
                title={
                  isOrder
                    ? 'Clonar Venta'
                    : isQuote
                      ? 'Clonar Cotización'
                      : 'Clonar'
                }
                enable={isOrder || isQuote}
                saveSell={() => {
                  if (isOrder) {
                    dispatchMakeSell(sostatus);
                  }
                  if (isQuote) {
                    dispatchCloneQuote();
                  }
                  handleClose();
                }}
              />
            )}

            <DiscountTaxesAction
              enable={enableDiscoutTaxes}
              toggle={async () => {
                await dispatch(setDiscountTaxes());
                toggleTaxesDiscount();
                handleClose();
              }}
            />

            <ChangeCartPrice enable={arePricebooks && enableChangeListPrice()} />
          </div>
          <div style={{
            display: 'flex', flexDirection: 'column', justifyContent: 'start', alignItems: 'center',
          }}
          >
            {currentTabs.posTypeTab !== 'Vender' && (
              <>
                <SeeOnCrmAction
                  enable={enableToSee}
                  tooltip={
                    posTypeTab === 'Historico'
                      ? 'Ver orden en sistema'
                      : 'Ver cotización en sistema'
                  }
                />

                {/* <CustomFields
                  enable={enableToSee}
                  tooltip="Campos Personalizados"
                  module={
                    isOrder
                      ? appModules.SalesOrder
                      : isQuote
                        ? appModules.Quotes
                        : ''
                  }
                /> */}
              </>
            )}
            <GoToAddressAction enable={enableToSee} />
            <PromotionsAction
              // eslint-disable-next-line camelcase
              enable={(selectedCustomer?.cart_default !== null && selectedCustomer?.cart_default !== '0') ? false : hay_promociones}
              toggle={() => {
                handleClose();
              }}
            />
            <Shipping enable={existProducts} tooltip="Cotizar envios" />

            {currentTabs.posTypeTab === 'Historico' && (
              <ExternalDeliveryAction
                enable={disableButton}
                delivery={() => {}}
              />
            )}
          </div>
        </div>

      </MenuBase>

      {/* </Menu> */}
    </>
  );
};

ActionControls.propTypes = {
  authUser: PropTypes.object.isRequired,
  cart: PropTypes.object.isRequired,
  customer: PropTypes.object.isRequired,
  dispatchCotizar: PropTypes.func.isRequired,
  dispatchEditOrder: PropTypes.func.isRequired,
  dispatchFetchTimbrado: PropTypes.func.isRequired,
  dispatchMakeSell: PropTypes.func.isRequired,
  dispatchOpenModal: PropTypes.func.isRequired,
  dispatchOpenRefundModal: PropTypes.func.isRequired,
  dispatchSyncCart: PropTypes.func.isRequired,
  keyBoard: PropTypes.object.isRequired,
  loads: PropTypes.object.isRequired,
  tabs: PropTypes.object.isRequired,
  tooltip: PropTypes.string,
  history: PropTypes.object,
};
ActionControls.defaultProps = {
  tooltip:
    'Ctrl + Alt + G = Generar Venta \nCtrl + Alt + D = Descuentos \nCtrl + Alt + S = Timbrar Orden',
  history: {},
};

export default withRouter(ActionControls);
