import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { toolsHelper } from '../../../../../helpers';
import { convertAmountToSelectCurrency } from '../../../../../helpers/tools';

const RefundFdeTotals = ({
  methods,
  balance,
}) => {
  const cart = useSelector(state => state.cart);
  const authUser = useSelector(state => state.authUser);
  const { orderSelected: { order } } = cart;
  // eslint-disable-next-line camelcase
  const { user: { usable_currencies } } = authUser;

  const orderCurrencyId = order.currency_id.split('x').pop();

  const findCurrencyById = currencyId => usable_currencies.find(c => c.id === currencyId);

  const montoArr = methods.map((x) => {
    if (orderCurrencyId !== x.sub.currency_id) {
      const orderCurrency = findCurrencyById(orderCurrencyId);
      const currency = findCurrencyById(x.sub.currency_id);
      const montoConverted = convertAmountToSelectCurrency(currency.conversion_rate, orderCurrency.conversion_rate, x.monto);
      return montoConverted;
    }
    return parseFloat(x.monto);
  });

  const suma = montoArr.map(x => x).reduce((a, m) => (a + m), 0);
  const reduce = suma > balance ? balance : suma;
  const equal = balance - reduce;

  return (
    <div className="refund_fde_totals">
      <span>{`Balance: $${toolsHelper.formatMoney(equal)}`}</span>
      <span>{`Monto: $${toolsHelper.formatMoney(reduce)}`}</span>
      <span>Todas las monedas fueron convertidas a la moneda de la orden</span>
    </div>
  );
};


RefundFdeTotals.propTypes = {
  methods: PropTypes.array.isRequired,
  balance: PropTypes.number,
};

RefundFdeTotals.defaultProps = {
  balance: 0.0,
};

export default RefundFdeTotals;
