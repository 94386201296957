import { setDisableDiscountTaxes } from './filtersActions';

export const CHANGE_TAB = 'CHANGE_TAB';

export function changeTab(scope, value) {
  return async (dispatch) => {
    dispatch(
      {
        type: CHANGE_TAB,
        scope,
        value,
      },
    );
    if (value !== 'Vender' && scope === 'posType') {
      dispatch(setDisableDiscountTaxes());
    }
  };
}
