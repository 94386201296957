import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@material-ui/core';

const SimpleAlert = ({
  open,
  desc,
  title,
  width,
  handleClose,
  cancelButton,
  isCancellable,
  primaryAction,
  secondaryAction,
  isPrimaryAction,
  isSecondaryAction,
  primaryActionButton,
  secondaryActionButton,
}) => (
  <Dialog
    open={open}
    maxWidth={width}
    onClose={handleClose}
    aria-labelledby={`alert-dialog-${title}`}
    aria-describedby={`alert-dialog-${desc}`}
  >
    <DialogTitle
      id={`id_dialog_alert_${title}`}
    >
      {title}
    </DialogTitle>
    {desc !== '' && (
      <DialogContent>
        <DialogContentText
          id={`id_dialog_alert_${desc}`}
        >
          {desc}
        </DialogContentText>
      </DialogContent>
    )}
    <DialogActions>
      {isCancellable && (
        <Button
          autoFocus
          color="inherit"
          onClick={handleClose}
        >
          {cancelButton}
        </Button>
      )}
      {isSecondaryAction && (
        <Button
          color="secondary"
          onClick={secondaryAction}
        >
          {secondaryActionButton}
        </Button>
      )}
      {isPrimaryAction && (
        <Button
          color="primary"
          onClick={primaryAction}
        >
          {primaryActionButton}
        </Button>
      )}
    </DialogActions>
  </Dialog>
);

SimpleAlert.propTypes = {
  open: PropTypes.bool.isRequired,
  desc: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  isCancellable: PropTypes.bool.isRequired,
  primaryAction: PropTypes.func.isRequired,
  cancelButton: PropTypes.string.isRequired,
  secondaryAction: PropTypes.func.isRequired,
  isPrimaryAction: PropTypes.bool.isRequired,
  isSecondaryAction: PropTypes.bool.isRequired,
  primaryActionButton: PropTypes.string.isRequired,
  secondaryActionButton: PropTypes.string.isRequired,
};

export default SimpleAlert;
