import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMapMarkerAlt,
} from '@fortawesome/free-solid-svg-icons';
import { fetchAccountContactsAddress } from '../../../../../redux/actions/accountActions';

const ContactAddressList = withRouter(({ match, action }) => {
  const dispatch = useDispatch();
  const _iOSDevice = !!navigator.platform.match(/iPhone|iPod|iPad/);

  useEffect(() => {
    dispatch(fetchAccountContactsAddress(match.params.crmid));
    // eslint-disable-next-line
  }, []);

  const contact = useSelector(state => state.account.contact.address);
  return (
    <List classes={{ root: 'py-0' }}>
      {contact ? contact.map(item => (item.address ? (
        <a href={`${_iOSDevice ? 'http://maps.apple.com/?q='.concat(item.address) : 'https://maps.google.com/?q='.concat(item.address)}`} key={item.crmid} target="_blank" rel="noopener noreferrer">
          <ListItem button classes={{ root: 'py-0 pl-0' }}>
            <ListItemAvatar>
              <Avatar variant="square" className="modal-avatar">
                <FontAwesomeIcon style={{ fontSize: 26 }} icon={faMapMarkerAlt} />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={`${item.firstname} ${item.lastname}`} 
              secondary={item.address.length > 30 ? `${item.address.substring(0, 30)}...` : item.address}
              classes={{ primary: 'modal-primary-text', secondary: 'modal-secondary-text' }}
            />
          </ListItem>
        </a>
      )
        : (
          <ListItem button classes={{ root: 'py-0 pl-0' }} key={item.crmid}>
            <ListItemAvatar>
              <Avatar variant="square" className="modal-avatar">
                <FontAwesomeIcon style={{ fontSize: 26 }} icon={faMapMarkerAlt} />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={`${item.firstname} ${item.lastname}`} secondary="Sin direccion" classes={{ primary: 'modal-primary-text', secondary: 'modal-secondary-text' }} />
          </ListItem>
        )))
        : null
        }
    </List>
  ); });

export default ContactAddressList;
