import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Collapse, LinearProgress } from '@material-ui/core';
import { useSelector } from 'react-redux';
import ListAddress from './components/ListAddress';
import useOrder from '../../../hooks/order/useOrder';
import useCustomer from '../../../hooks/customer/useCustomer';

const Address = ({ orderId, clientId, isOrder = true }) => {
  const customer = useSelector(state => state.customer);
  const {
    loading: loadingCustomer, address, loadAddress, customerAddress, addAddress,
  } = useCustomer(customer.selectedCustomer.crmid);
  const {
    data: order, isLoading, updateOrderAddress, getOrder,
  } = useOrder(orderId, isOrder);

  const isCustomer = Boolean(Object.keys(customer.selectedCustomer).length > 0);

  useEffect(() => {
    if (clientId) {
      customerAddress(clientId);
    }
  }, [clientId]);

  useEffect(() => {
    if (loadAddress) {
      customerAddress(clientId);
    }
  }, [loadAddress, isLoading]);


  return (
    <div>
      <Collapse in={isLoading || loadingCustomer}>
        <LinearProgress />
      </Collapse>
      <Collapse in={(!isLoading || !loadingCustomer) && order} unmountOnExit>
        <ListAddress
          addAddress={addAddress}
          address={address}
          order={order}
          customer={isCustomer ? customer.selectedCustomer : {}}
          setAddress={async (invoice, shipping) => {
            await updateOrderAddress(invoice, shipping);
            getOrder();
          }}
        />
      </Collapse>
    </div>
  );
};

Address.propTypes = {
  orderId: PropTypes.string.isRequired,
  clientId: PropTypes.string.isRequired,
  isOrder: PropTypes.bool.isRequired,
};

export default Address;
