import React from 'react';
import PropTypes from 'prop-types';
/* Material */
import {} from '@material-ui/core';
import TicketsModal from './components/TicketsModal';
import TicketsModalCC from './components/TicketsModalCC';
import TicketsModalBill from './components/TicketsModalBill';
import TicketsModalRefund from './components/TicketsModalRefund';
import TicketsModalRefundC from './components/TicketsModalRefundC';

/* Components */

const Tickets = ({
  authUser,
  balance,
  cart,
  customer,
  discount,
  dispatchToggle,
  dispatchToggleTicketsCCModal,
  dispatchToggleTicketsFactura,
  dispatchToggleTicketsModal,
  dispatchCloseTicketsModal,
  loads,
  modals,
  table,
  tabs,
  timbrar,
  title,
  type,
}) => (
  <>
    {type === 'venta' && (
      <TicketsModal
        authUser={authUser}
        cart={cart}
        customer={customer}
        discount={discount}
        isLoading={Boolean(loads.ticketsIsLoading)}
        isOpen={Boolean(modals.ticketsModalIsOpen)}
        tabs={tabs}
        title={title}
        toggle={dispatchCloseTicketsModal}
      />
    )}
    {type === 'factura' && (
      <TicketsModalBill
        authUser={authUser}
        isLoading={Boolean(loads.ticketsIsLoading)}
        isOpen={Boolean(modals.ticketsFacturaModalIsOpen)}
        timbrar={timbrar}
        title={title}
        toggle={dispatchToggleTicketsFactura}
      />
    )}
    {type === 'CC' && (
      <TicketsModalCC
        authUser={authUser}
        balance={balance}
        isLoading={Boolean(loads.balanceIsLoading)}
        isOpen={Boolean(modals.ticketCCModalIsOpen)}
        title={title}
        toggle={dispatchToggleTicketsCCModal}
      />
    )}
    {type === 'ticketsRefund' && (
      <>
        <TicketsModalRefund
          authUser={authUser}
          table={table}
          isLoading={Boolean(loads.ticketsIsLoading)}
          isOpen={Boolean(modals.ticketsRefundModalIsOpen)}
          title={title}
          cart={cart}
          toggle={() => {
            dispatchToggle('ticketsRefund');
            dispatchToggle('ticketsRefund2');
          }}
        />
        <TicketsModalRefundC
          authUser={authUser}
          table={table}
          isLoading={Boolean(loads.ticketsIsLoading)}
          isOpen={Boolean(modals.ticketsRefund2ModalIsOpen)}
          title={title}
          toggle={() => dispatchToggle('ticketsRefund2')}
        />
      </>
    )}
  </>
);

Tickets.propTypes = {
  authUser: PropTypes.object.isRequired,
  balance: PropTypes.object,
  cart: PropTypes.object.isRequired,
  customer: PropTypes.object.isRequired,
  discount: PropTypes.object.isRequired,
  dispatchToggle: PropTypes.func.isRequired,
  dispatchToggleTicketsCCModal: PropTypes.func.isRequired,
  dispatchToggleTicketsFactura: PropTypes.func.isRequired,
  dispatchToggleTicketsModal: PropTypes.func.isRequired,
  dispatchCloseTicketsModal: PropTypes.func.isRequired,
  loads: PropTypes.object.isRequired,
  modals: PropTypes.object.isRequired,
  table: PropTypes.object.isRequired,
  tabs: PropTypes.object.isRequired,
  timbrar: PropTypes.object,
  title: PropTypes.string,
  type: PropTypes.string,
};
Tickets.defaultProps = {
  balance: {},
  title: 'Ticket',
  type: 'venta',
  timbrar: {},
};

export default Tickets;
