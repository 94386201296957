/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable import/no-cycle */
import React, { useEffect, useState } from 'react';
import {
  Button,
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { fetchOrderLists, fetchOrderView, setOrderListSelected } from '../../../../redux/actions/orderActions';
import OrderAPI from '../../../../api/OrderAPI';

const OrderLists = () => {
  const dispatch = useDispatch();
  const orderLists = useSelector(state => state.order.lists);
  const tabs = useSelector(state => state.tabs);
  const { order: filterOrder, quote: filterQuote } = useSelector(state => state.filters);
  const listSelected = useSelector(state => state.order.orderListSelected);
  const { default: predefined, data: list } = orderLists;
  const [selectedList, setSelectedList] = useState(null);
  const authUser = useSelector(state => state.authUser);
  const { user: { config: { store } } } = authUser;
  const orderApi = new OrderAPI();

  const selectList = async (listView) => {
    setSelectedList(listView);

    await dispatch(setOrderListSelected(listView));
    const searchParams = [];
    if (tabs.posTypeTab === 'Historico') {
      searchParams.push(['treebesalmid', 'c', `${store.name}`]);

      if (filterOrder && filterOrder?.length) {
        filterOrder.forEach((element) => {
          // eslint-disable-next-line array-callback-return
          searchParams.map((filterSearch) => {
            if (!filterSearch.includes(element[0])) {
              searchParams.push(element);
            }
          });
        });
      }
    } else if (filterQuote && filterQuote?.length) {
      filterQuote.forEach((element) => {
        if (!searchParams.length) {
          searchParams.push(element);
        } else {
          // eslint-disable-next-line array-callback-return
          searchParams.map((filterSearch) => {
            if (!filterSearch.includes(element[0])) {
              searchParams.push(element);
            }
          });
        }
      });
    }

    dispatch(fetchOrderView(listView.cvid, 1, searchParams));
    // Set order list
    if (tabs.posTypeTab === 'Historico') {
      const lastSelectedList = localStorage.getItem('orderList');
      if (!lastSelectedList) {
        localStorage.setItem('orderList', listView.cvid);
        orderApi.setDefaultListView(listView.cvid);
        return;
      }
      if (lastSelectedList !== listView.cvid) {
        localStorage.setItem('orderList', listView.cvid);
        orderApi.setDefaultListView(listView.cvid);
      }
    }
    if (tabs.posTypeTab === 'Cotizaciones') {
      const lastSelectedList = localStorage.getItem('quoteList');
      if (!lastSelectedList) {
        localStorage.setItem('quoteList', listView.cvid);
        orderApi.setDefaultListView(listView.cvid);
        return;
      }
      if (lastSelectedList !== listView.cvid) {
        localStorage.setItem('quoteList', listView.cvid);
        orderApi.setDefaultListView(listView.cvid);
      }
    }
  };

  useEffect(() => {
    if (listSelected && listSelected?.cvid !== selectedList?.cvid) {
      selectList(listSelected);
    }
  }, [listSelected]);


  const getList = () => {
    dispatch(fetchOrderLists());
  };

  useEffect(() => {
    if (tabs.posTypeTab === 'Historico' || tabs.posTypeTab === 'Cotizaciones') {
      getList();
    }
  }, [tabs.posTypeTab]);

  return (
    <div style={{
      display: 'flex', justifyContent: 'flex-end', alignItems: 'center', padding: '1rem', gap: '1rem', overflowX: 'auto', width: '100%',
    }}
    >
      <div>
        <Button disabled variant="contained">
          <span style={{ whiteSpace: 'nowrap' }}>{listSelected?.title}</span>
        </Button>
      </div>
      <div className="d-flex" style={{ gap: '0.5rem', overflowX: 'auto' }}>
        {
          list && list[0].lists.length > 0 && (
            <div className="d-flex" style={{ gap: '1rem' }}>
              {
                list && list[0].lists.map((item) => {
                  if (listSelected?.cvid !== item.cvid) {
                    return (
                      <Button variant="outlined" key={item.title} onClick={() => selectList(item)}>
                        <span style={{ whiteSpace: 'nowrap' }}>{item.title}</span>
                      </Button>
                    );
                  }
                })
              }
            </div>
          )
        }
        {
          list && list[1].lists.length > 0 && (
            <div className="d-flex" style={{ gap: '0.5rem' }}>
              {
                list && list[1].lists.map((item) => {
                  if (listSelected?.cvid !== item.cvid) {
                    return (
                      <Button variant="outlined" key={item.title} onClick={() => selectList(item)}>
                        <span style={{ whiteSpace: 'nowrap' }}>{item.title}</span>
                      </Button>
                    );
                  }
                })
              }
            </div>
          )
        }
      </div>
    </div>
  );
};

export default OrderLists;
