/* eslint-disable react/prop-types */
/* eslint-disable import/no-cycle */
import React from 'react';
import './DetailExpenseDialog.scss';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft, faEllipsisV,
} from '@fortawesome/free-solid-svg-icons';
import Typography from '@material-ui/core/Typography';
import NewExpenseForm from '../NewExpenseForm';
import Swip from '../../../../components/Swip';
import CashFlow from '../../../../components/Finance/CashFlow';
import DocumentItem from '../../../../components/Document/DocumentItem';
import DrawerTop from '../../../ClientPage/components/DrawerTop/DrawerTop';


const Transition = React.forwardRef((props, ref) => <Slide direction="down" ref={ref} {...props} />);


const documents = [
  {
    crmid: '73086',
    extension: 'pdf',
    filename: 'Contrato',
  },
  {
    crmid: '73087',
    extension: 'img',
    filename: 'IFE',
  },
  {
    crmid: '73089',
    extension: 'rar',
    filename: 'Documentacion',
  },
  {
    crmid: '73088',
    extension: 'docs',
    filename: 'Contrato 02',
  },
];
const cashflow = [
  {
    crmid: '73086',
    entity_label: null,
    createdtime: '2019-11-28 10:00:43',
    modifiedtime: '2019-11-28 10:00:43',
    fecha_realizado: '2019-11-28',
    monto: '19.37',
    documento: 'OC737',
    xmlpago: 'NO REQUERIDO',
    cuenta: 'Caja Chica',
    tfde_metodo: 'Efectivo',
    tfde_tipo: 'Ingreso',
    moneda_documento: 'MXN',
    related_to_label: 'OC737',
    empresa_label: 'JHONNY MHELO LABO',
  },
  {
    crmid: '73377',
    entity_label: 'PEDIDO SO737',
    createdtime: '2019-12-10 07:59:38',
    modifiedtime: '2019-12-10 08:03:03',
    fecha_realizado: '2019-12-10',
    monto: '5000.00',
    documento: 'SO737',
    xmlpago: 'NO REQUERIDO',
    cuenta: 'Caja pruebas',
    tfde_metodo: 'Cheque',
    tfde_tipo: 'Ingreso',
    moneda_documento: 'MXN',
    related_to_label: 'SO737',
    empresa_label: 'JHONNY MHELO LABO',
  },
];

const DetailExpenseDialog = ({ open, handleClose }) => {
  const [openrDrawer, setopenrDrawerBar] = React.useState(false);

  const handleSetopenrDrawerBarrOpen = () => {
    setopenrDrawerBar(true);
  };
  const handleSetopenrDrawerBarClose = () => {
    setopenrDrawerBar(false);
  };

  const tabs = [
    {
      icon: 'fas fas fa-info-circle',
      renderComponent: <NewExpenseForm />,
      title: 'Gasto',
    },
    {
      icon: 'fas fas fa-hand-holding-usd',
      renderComponent: <CashFlow data={cashflow} />,
      title: 'Flujo de efectivo',
    },
    {
      icon: 'fas fas fa-file-signature',
      renderComponent: 'MDA',
      title: 'Facturas',
    },
    {
      icon: 'fas fas fa-file',
      renderComponent: <DocumentItem data={documents} />,
      title: 'Documentoss',
    },
  ];

  return (
    <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition} classes={{ paperFullScreen: 'dialog-bg' }}>
      <AppBar className="appBar" color="inherit" style={{ position: 'relative' }}>
        <Toolbar variant="dense" disableGutters elevation={0} classes={{ root: 'toolbar-container' }}>
          <div className="d-flex justify-content-start align-items-center">
            <IconButton edge="start" onClick={handleClose} aria-label="close">
              <FontAwesomeIcon style={{ fontSize: 16 }} icon={faChevronLeft} />
            </IconButton>
            <Typography variant="h6" className="toolbar-title title-type">
              Gasto viaticos
            </Typography>
          </div>
          <IconButton
            color="inherit"
            size="medium"
            onClick={() => handleSetopenrDrawerBarrOpen()}
          >
            <FontAwesomeIcon className="font-size-18" style={{ width: 20 }} icon={faEllipsisV} />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Swip tabs={tabs} />
      <DrawerTop onClose={handleSetopenrDrawerBarClose} open={openrDrawer} />
    </Dialog>

  ); };

export default DetailExpenseDialog;
