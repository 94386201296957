/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-cycle */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  isMobile,
  isAndroid,
} from 'react-device-detect';

import { Container } from 'reactstrap';
import Grid from '@material-ui/core/Grid';

import {
  AppBar, Drawer, IconButton, Toolbar, Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import tabsProps from '../../propTypes/tabsProps';
import productProps from '../../propTypes/productProps';
import loadsProps from '../../propTypes/loadsProps';
import PosItemContainerAction from './components/PosItemContainerAction';
import Product from '../../components/Product';
import Timbre from '../../components/Timbre';
import Customer from '../../components/Customer/index';
import Cart from '../../components/Cart';
import LoadComponent from '../../components/Load/LoadComponent';
import ActualOrder from '../../components/ActualOrder';
import CartOrderDetail from '../../components/rkr2-components/cart-order-details';

class Pos extends PureComponent {
  static propTypes = {
    tabs: tabsProps.isRequired,
    loads: loadsProps.isRequired,
    product: productProps.isRequired,
    cart: PropTypes.object.isRequired,
    order: PropTypes.object.isRequired,
    modals: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    keyBoard: PropTypes.object.isRequired,
    authUser: PropTypes.object.isRequired,
    cotizacion: PropTypes.object.isRequired,

    dispatchChangeTabDisplayPosToProduct: PropTypes.func.isRequired,

    dispatchFetchSubProducts: PropTypes.func.isRequired,
    dispatchAddProductToCart: PropTypes.func.isRequired,
    dispatchGetProductByPage: PropTypes.func.isRequired,

    /* Modals */
    dispatchToggleModal: PropTypes.func.isRequired,

    /* news */
    dispatchFetchAll: PropTypes.func.isRequired,
    dispatchFetchAllProducts: PropTypes.func.isRequired,
    dispatchFilterAllProducts: PropTypes.func.isRequired,
    dispatchFetchAllOrders: PropTypes.func.isRequired,
    dispatchFilterAllOrders: PropTypes.func.isRequired,
    dispatchAddKeyboardItem: PropTypes.func.isRequired,
    dispatchEmptyKeyBoardItems: PropTypes.func.isRequired,
    dispatchOpenModalAndGetSubProducts: PropTypes.func.isRequired,
    dispatchSelectOrder: PropTypes.func.isRequired,
    dispatchClearCart: PropTypes.func.isRequired,

    /* Cotizaciones Actions */
    dispatchSelectCotizacion: PropTypes.func.isRequired,
    dispatchFetchAllCotizaciones: PropTypes.func.isRequired,

    dispatchFetchListOrders: PropTypes.func.isRequired,
    dispatchToggleRefresh: PropTypes.func.isRequired,
    dispatchCloseCartModal: PropTypes.func.isRequired,
    dispatchToggleDetails: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    const { dispatchChangeTabDisplayPosToProduct } = this.props;
    dispatchChangeTabDisplayPosToProduct();
    this.state = {
      parentProduct: '',
      // openDetails: false,
    };
  }

  async componentDidMount() {
    const {
      order: { preselectOrder }, dispatchSelectOrder, dispatchFetchListOrders, history, dispatchFetchAllProducts,
    } = this.props;
    const {
      location: { pathname },
    } = history;
    if (preselectOrder) {
      dispatchFetchListOrders(preselectOrder.entity_label);
      dispatchSelectOrder(preselectOrder.crmid);
    } else {
      switch (pathname) {
        case '/pos/vender':
        case '/pos':
          dispatchFetchAllProducts();
          break;
        default:
          break;
      }
      this.setListeners();
    }
  }

  componentWillReceiveProps(next) {
    const {
      modals,
      history,
      keyBoard,
      dispatchCloseModals,
      dispatchEmptyKeyBoardItems,
    } = next;
    const { location: { pathname } } = history;
    const thereModal = Boolean(Object.keys(modals).length > 0);
    if (pathname !== '/modal/' && thereModal && isAndroid) {
      window.onpopstate = () => {
        dispatchCloseModals();
      };
    }
    if (thereModal && Object.entries(modals).some(x => x[1] === true)) {
      if (keyBoard.ctrlButton) dispatchEmptyKeyBoardItems();
      this.removeListeners();
    } else {
      this.setListeners();
    }
  }

  componentWillUnmount() {
    this.removeListeners();
  }

  setListeners = async () => {
    document.addEventListener('keydown', this.keyDownFunction, false);
    document.addEventListener('keyup', this.keyUpFunction, false);
  }

  removeListeners = async () => {
    document.removeEventListener('keydown', this.keyDownFunction, false);
    document.removeEventListener('keyup', this.keyUpFunction, false);
  }

  keyDownFunction = async (e) => {
    const { ctrlKey, altKey } = e;
    let { keyCode } = e;
    keyCode = String(keyCode);
    const {
      modals,
      dispatchAddKeyboardItem,
    } = this.props;
    if (ctrlKey && altKey) {
      if (keyCode === '68') {
        const generalDiscount = document.querySelector('#action_control_discount');
        // @ts-ignore
        if (generalDiscount) generalDiscount.click();
      } else if (keyCode === '71') {
        const saveBtn = document.querySelector('#action_control_save');
        // @ts-ignore
        if (saveBtn) saveBtn.click();
      } else if (keyCode === '83') {
        const satBtn = document.querySelector('#action_control_sat');
        // @ts-ignore
        if (satBtn) satBtn.click();
      } else if (keyCode === '67') {
        const satBtn = document.querySelector('#action_control_share');
        // @ts-ignore
        if (satBtn) satBtn.click();
      }
    }
    if (ctrlKey) {
      const { keyBoard } = this.props;
      const emptyKeyBoard = Boolean(keyBoard && Object.keys(keyBoard).length === 0);
      if (emptyKeyBoard && altKey) {
        await dispatchAddKeyboardItem({ ctrlButton: true });
        await dispatchAddKeyboardItem({ altButton: true });
      }
      if (keyCode === '13') {
        const generalSubmit = document.querySelector('#key_selector_button');
        // @ts-ignore
        if (generalSubmit) generalSubmit.click();
      }
    } else {
      keyCode = String(keyCode);
      if (keyCode === '27') {
        const modalIsOpen = Object.values(modals).some(x => (x === true));
        const searchBar = document.querySelector('#search_bar_query');
        // @ts-ignore
        if (searchBar && !modalIsOpen) searchBar.focus();
      }
    }
  }

  keyUpFunction = async (e) => {
    const { key } = e;
    if (key === 'Control') {
      const {
        keyBoard,
        dispatchEmptyKeyBoardItems,
      } = this.props;
      if (keyBoard.ctrlButton) {
        dispatchEmptyKeyBoardItems();
      }
    }
  }

  renderOrderName = () => {
    const { cart: { orderSelected } } = this.props;
    const isOrder = Boolean(orderSelected?.order?.salesorder_no);
    const isQuote = Boolean(orderSelected?.order?.quote_no);
    if (isOrder) {
      return orderSelected.order?.salesorder_no === orderSelected.order?.subject ? orderSelected.order?.salesorder_no : `${orderSelected.order?.salesorder_no} - ${orderSelected.order?.subject}`;
    }
    if (isQuote) {
      return orderSelected.order?.quote_no === orderSelected.order?.subject ? orderSelected.order?.quote_no || orderSelected.order?.subject : `${orderSelected.order?.quote_no} - ${orderSelected.order?.subject}`;
    }
    return '';
  }

  setParentProduct = (parentProduct) => {
    this.setState({ parentProduct });
  }

  renderStandarView = () => {
    const {
      cart,
      history,
      authUser,
      modals,
      tabs,
      loads,
      product,
      order,
      cotizacion,
      dispatchClearCart,
      dispatchSelectOrder,
      dispatchToggleModal,
      dispatchGetProductByPage,
      dispatchAddProductToCart,
      dispatchFetchSubProducts,
      /* news */
      dispatchFetchAllProducts,
      dispatchFilterAllProducts,
      dispatchFetchAllOrders,
      dispatchFilterAllOrders,
      dispatchOpenModalAndGetSubProducts,
      dispatchSelectCotizacion,
      dispatchFetchAllCotizaciones,
      dispatchToggleRefresh,
      dispatchToggleDetails,
    } = this.props;
    const { parentProduct } = this.state;
    const {
      location: { pathname },
    } = history;
    switch (pathname) {
      case '/pos/caja':
        return (
          <Product
            tabs={tabs}
            loads={loads}
            product={product}
            getSubProducts={dispatchFetchSubProducts}
            parentProduct={parentProduct}
            setParentProduct={this.setParentProduct}
            authUser={authUser}
            modals={modals}
            getProductsByPage={dispatchGetProductByPage}
            addProducts={dispatchAddProductToCart}
            toggleModal={dispatchToggleModal}
            handleOnClick={dispatchOpenModalAndGetSubProducts}
            dispatchFetchAllProducts={dispatchFetchAllProducts}
            dispatchFilterAllProducts={dispatchFilterAllProducts}
            dispatchToggleRefresh={dispatchToggleRefresh}
          />
        );
      case '/pos/ventas':
        return (
          <ActualOrder />
        );
      case '/pos/cotizaciones':
        return (
          <ActualOrder />
        );
      case '/pos/carro':
        return (
          <>
            <Customer />
            <Cart />
            {/* <PosCartContainerAction /> */}
            <Timbre />
          </>
        );
      default:
        return (
          <Product
            tabs={tabs}
            loads={loads}
            product={product}
            getSubProducts={dispatchFetchSubProducts}
            setParentProduct={this.setParentProduct}
            parentProduct={parentProduct}
            authUser={authUser}
            modals={modals}
            getProductsByPage={dispatchGetProductByPage}
            addProducts={dispatchAddProductToCart}
            toggleModal={dispatchToggleModal}
            handleOnClick={dispatchOpenModalAndGetSubProducts}
            dispatchFetchAllProducts={dispatchFetchAllProducts}
            dispatchFilterAllProducts={dispatchFilterAllProducts}
            dispatchToggleRefresh={dispatchToggleRefresh}
          />
        );
    }
  };

  render() {
    const {
      cart,
      history,
      authUser,
      modals,
      tabs,
      loads,
      product,
      order,
      cotizacion,
      dispatchClearCart,
      dispatchToggleModal,
      dispatchSelectOrder,
      dispatchGetProductByPage,
      dispatchFetchSubProducts,
      dispatchAddProductToCart,
      /* news */
      dispatchFetchAllProducts,
      dispatchFilterAllProducts,
      dispatchFetchAllOrders,
      dispatchFilterAllOrders,
      dispatchOpenModalAndGetSubProducts,
      dispatchSelectCotizacion,
      dispatchFetchAllCotizaciones,
      dispatchToggleRefresh,
      dispatchCloseCartModal,
      dispatchToggleDetails,
    } = this.props;
    const { parentProduct } = this.state;
    const { preselectOrder } = order;
    const { openDetail, orderSelected } = cart;
    const { cartModalIsOpen, ticketsModalIsOpen } = modals;

    const {
      location: { pathname },
    } = history;
    switch (pathname) {
      case '/pos/ventas':
        tabs.posTypeTab = 'Historico';
        break;
      case '/pos/vender':
        tabs.posTypeTab = 'Vender';
        break;
      case '/pos/cotizaciones':
        tabs.posTypeTab = 'Cotizaciones';
        break;
      default:
        break;
    }
    return (
      <div className={isMobile ? 'pos w-100' : 'pos'}>
        {/* <BrowserView> */}
        <Container style={{ maxWith: '100% !important' }}>
          <Grid container spacing={8} style={{ height: '100%' }}>
            <Grid item sm={tabs.posTypeTab !== 'Vender' ? 12 : 7}>
              <PosItemContainerAction />
              {tabs.posTypeTab === 'Vender' && (
                <Product
                  tabs={tabs}
                  loads={loads}
                  product={product}
                  getSubProducts={dispatchFetchSubProducts}
                  parentProduct={parentProduct}
                  setParentProduct={this.setParentProduct}
                  authUser={authUser}
                  modals={modals}
                  preselectOrder={preselectOrder}
                  getProductsByPage={dispatchGetProductByPage}
                  addProducts={dispatchAddProductToCart}
                  toggleModal={dispatchToggleModal}
                  handleOnClick={dispatchOpenModalAndGetSubProducts}
                  dispatchFetchAllProducts={dispatchFetchAllProducts}
                  dispatchFilterAllProducts={dispatchFilterAllProducts}
                  dispatchToggleRefresh={dispatchToggleRefresh}
                />
              )}
              {tabs.posTypeTab !== 'Vender' && (
                <>
                  <ActualOrder />
                </>
              )}
            </Grid>

            {
                tabs.posTypeTab === 'Vender' && (
                  <Grid item sm={5}>
                    <section className="w-100 pos_sec_cart">
                      <Cart />
                    </section>
                  </Grid>
                )
              }
          </Grid>
        </Container>
        {/*  </BrowserView> */}


        {/* <MobileView>
          <PosMobileActionsBar history={history} pathName={window.location.pathname} />
          <div className="posMobile">
            {this.renderStandarView()}
          </div>
          <PosItemContainerActionMovile history={history} />
        </MobileView> */}
        {loads.posIsLoading && <LoadComponent />}


        <Drawer
          anchor="right"
          open={cartModalIsOpen}
          onClose={() => {
            dispatchToggleDetails(false);
            dispatchCloseCartModal();
          }}
          PaperProps={{ style: { width: openDetail ? '100%' : '50%', zIndex: 1050, transition: 'all 200ms ease 0s' } }}
          variant="persistent"
        >
          <div style={{ height: '100vh', display: 'flex', flexDirection: 'row' }}>
            <div
              tabIndex={0}
              role="button"
              style={{ height: '100vh', flex: 1 }}
            >
              <AppBar position="static" elevation={0}>
                <Toolbar style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Typography variant="h6" color="inherit" noWrap>
                    {this.renderOrderName()}
                  </Typography>
                  <div>
                    <IconButton
                      color="inherit"
                      aria-label="Open drawer"
                      onClick={() => {
                        dispatchCloseCartModal();
                        dispatchToggleDetails(false);
                      }}
                    >
                      <CloseIcon />
                    </IconButton>

                  </div>
                </Toolbar>
              </AppBar>
              <Cart />
            </div>
            <div
              className="o-detail"
              style={{
                flex: openDetail ? 1 : 0, borderLeft: '2px solid #e0e0e0', width: openDetail ? '100%' : 0, transition: 'all 200ms ease 0s', overflow: 'hidden',
              }}
            >
              <CartOrderDetail closeCart={() => { dispatchToggleDetails(false); }} />
            </div>
          </div>
        </Drawer>


      </div>
    );
  }
}

export default Pos;
