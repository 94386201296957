/* eslint-disable import/no-cycle */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
/* Material */
import {
  Button, Collapse, Dialog, DialogActions, DialogContent, LinearProgress, Slide,
} from '@material-ui/core';
/* Reactstrap */
import {
  Modal, ModalBody, ModalFooter, ModalHeader,
} from 'reactstrap';

/* ReactToPrint */
import ReactToPrint from 'react-to-print';

/* import TicketToPrint from './tickets/TicketToPrint'; */
import { fetchFde, selectOrder } from '../../../redux/actions/orderActions';
import TicketToPrint from '../../rkr2-components/tickets/TicketToPrint';
import { resetTicketData } from '../../../redux/actions/ticketAction';
import useRewardPoints from '../../../hooks/useRewardPoints';
import { removeCustomer } from '../../../redux/actions/customerActions';
import { pricebookHelper } from '../../../helpers';
import ProductAPI from '../../../api/ProductAPI';
import { fetchAllProducts } from '../../../redux/actions/productActions';

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

const TicketsModal = ({
  tabs,
  cart,
  title,
  print,
  toggle,
  isOpen,
  discount,
  customer,
  authUser,
  isLoading,
}) => {
  const [isPrinting, setIsPrinting] = useState(false);
  const [productCart, setProductCart] = useState(null);
  const ticketState = useSelector(state => state.tickets);
  const updatedCart = useSelector(state => state.cart);
  const dispatch = useDispatch();
  const toPrintRef = React.useRef();
  const discoutnExist = Boolean(
    cart.products.some(product => product.discount_percent > 0),
  );
  const fde = useSelector(state => state.order.fde);
  const ticketStore = useSelector(state => state.tickets);
  const { points, getCustomerPoints } = useRewardPoints();

  // Reset Price book and update products price
  const resetPriceBook = () => {
    const pricebook = {
      crmid: '',
      entity_label: '',
      isCustomer: false,
    };
    pricebookHelper.setStorage(pricebook);
    // Dispatch get all products
    dispatch(fetchAllProducts());
  };

  const closeModal = () => {
    dispatch(resetTicketData());
    localStorage.setItem('orderTicketId', '');
    toggle();
    dispatch(removeCustomer());
    // only reset price book if we are on tab Vender
    if (tabs.posTypeTab === 'Vender') {
      resetPriceBook();
    }
  };

  const updateFde = async () => {
    if (ticketState?.order?.crmid) {
      await dispatch(fetchFde(ticketState?.order?.crmid));
    } else {
      await dispatch(fetchFde());
    }
    if (isOpen) {
      setTimeout(() => {
        const btnPrint = document.querySelector('#button_to_print');
        // @ts-ignore
        if (btnPrint) btnPrint.click();
      }, localStorage.getItem('printMode') === 'true' ? 300 : 500);
    }
  };

  const handlePrint = async () => {
    await setIsPrinting(true);
    // toPrintRef.current.
    window.print();
    setTimeout(() => setIsPrinting(false), 2000);
  };

  React.useEffect(() => {
    const orderId = localStorage.getItem('orderTicketId');
    if (orderId && tabs.posTypeTab !== 'Vender') {
      dispatch(selectOrder(orderId));
    }
    if (isOpen && ticketState?.ticketType === 'order') {
      updateFde();
      getCustomerPoints();
    }
  }, [isOpen]);

  React.useEffect(() => {
    if (updatedCart?.products.length > 0) {
      setProductCart(updatedCart);
    }
  }, [updatedCart]);


  return (
    localStorage.getItem('printMode') === 'true' ? (
      <Dialog
        fullScreen
        open={isOpen}
        onClose={() => closeModal()}
        TransitionComponent={Transition}
      >
        <DialogContent>
          <TicketToPrint
            cart={productCart}
            ref={toPrintRef}
            discount={discount}
            authUser={authUser}
            customer={customer}
            fde={fde || []}
            ticketStore={ticketStore}
            rewards={points}
          />
        </DialogContent>
        {
          !isPrinting && (
            <DialogActions>
              <Button
                id="button_to_print1"
                color="primary"
                className="w-100"
                variant="contained"
                onClick={() => {
                  closeModal();
                }}
              >
                Cerrar
              </Button>

              <Button
                id="button_to_print1"
                color="primary"
                className="w-100"
                variant="contained"
                onClick={() => handlePrint()}
              >
                {print}
              </Button>
            </DialogActions>
          )
        }

      </Dialog>
    ) : (

      <Modal isOpen={isOpen} toggle={() => closeModal()} size="lg" className="tickets_modal">
        <ModalHeader toggle={() => closeModal()}>{title}</ModalHeader>
        <ModalBody className="tickets_modal_body w-100 d-flex justify-content-center">
          {isLoading && <LinearProgress />}
          <Collapse in={!isLoading}>
            {/*  <TicketToPrint
              tabs={tabs}
              cart={cart}
              ref={toPrintRef}
              discount={discount}
              authUser={authUser}
              customer={customer}
              discoutnExist={discoutnExist}
              fde={fde || []}
          /> */ }
            <TicketToPrint
              cart={productCart}
              ref={toPrintRef}
              discount={discount}
              authUser={authUser}
              customer={customer}
              fde={fde || []}
              ticketStore={ticketStore}
              rewards={points}
            />
          </Collapse>
        </ModalBody>
        <ModalFooter>
          <ReactToPrint
            removeAfterPrint
            copyStyles
            trigger={() => (
              <Button
                id="button_to_print"
                color="primary"
                className="w-100"
                variant="contained"
              >
                {print}
              </Button>
            )}
            content={() => toPrintRef.current}
            onAfterPrint={() => {
              closeModal();
              localStorage.setItem('cambio', 0);
            }}
          />
        </ModalFooter>
      </Modal>
    )
  );
};

TicketsModal.propTypes = {
  print: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  tabs: PropTypes.object.isRequired,
  cart: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  customer: PropTypes.object.isRequired,
  authUser: PropTypes.object.isRequired,
  discount: PropTypes.object.isRequired,
};
TicketsModal.defaultProps = {
  print: 'Imprimir Ticket',
};

export default TicketsModal;
