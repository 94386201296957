/* eslint-disable no-use-before-define */
/* eslint-disable radix */
/* eslint-disable no-param-reassign */
/* eslint-disable import/no-cycle */
/* eslint camelcase: 0 */
/* eslint-disable no-underscore-dangle */
/* eslint-disable object-curly-newline */
/* eslint-disable prefer-destructuring */

import {
  SET_DESC,
  CLEAR_CART,
  SET_COMMENT,
  ALERT_PRODUCTS,
  SET_PROMOTIONS,
  ACTIVE_PROMOTIONS,
  DESACTIVE_PROMOTIONS,
  RESET_PROMOTIONS,
  UPDATE_PROMOTIONS,
  ADD_ORDER_TO_CART,
  UPDATE_CART_STATE,
  ADD_PRODUCT_TO_CART,
  SET_SINGLE_DISCOUNT,
  VERIFY_PAYPAL_SERVICE,
  REMOVE_PRODUCT_IN_CART,
  ADD_PRODUCT_TO_CART_QTY,
  INCREASE_PRODUCT_IN_CART,
  DECREASE_PRODUCT_IN_CART,
  UPDATE_PRODUCT_QTY_IN_CART,
  UPDATE_PRODUCT_PRICE_IN_CART,
  UPDATE_SERVICE_PRICE_BY_WEIGHT_CART,
  ADD_SHIPPING_SERVICE,
  ADD_PRODUCTS_STOCK,
  CHANGE_SIN_STOCK,
  ADD_SELECTED_GUIDE,
  CLEAR_SELECTED_GUIDE,
  ADD_SELECTED_PACKING,
  CLEAR_SELECTED_PACKING,
  UPDATE_TOTALS,
  UPDATE_ORDER_CURRENCY,
  ADD_DIRECT_PRODUCT_TO_CART,
  OPEN_CART_DETAIL,
  SET_CAR_DETAIL_ACTIVE_TAB,
} from '../actions/cartActions';

import { cartHelper, toolsHelper } from '../../helpers';
import { formatMoney } from '../../helpers/tools';

const defaultState = () => ({
  products: [],
  subTotal: 0,
  totalIva: 0,
  anotherTaxes: 0,
  balance: 0,
  totalWithOutServices: 0,
  total: 0,
  totalProducts: 0,
  totalWeight: 0,
  cartProductsById: {},
  cartProductsByArray: [],
  orderSelected: {},
  alertProducts: [],
  description: '',
  lastProductAdded: {},
  pagado: 0,
  promotions: {},
  productsWidthpromotions: [],
  activePromotions: false,
  productsStock: {},
  sinStock: false,
  selectedGuide: {},
  selectedPacking: {},
  openDetail: false,
  detailTabActive: -1,
});

const addProductToCartQty = (
  state,
  { parentId, crmid, productsById, quantity, inStock, discountTaxes, discount },
) => {
  let lastProductAdded = {};
  const cartProductsById = { ...state.cartProductsById };
  const cartProductsByArray = [...state.cartProductsByArray];
  const existProductByCrmid = cartProductsByArray.find(
    x => x.crmid === crmid,
  );

  const parentImage = productsById[parentId] ? productsById[parentId].image : '';
  const productImage = productsById[parentId] && productsById[parentId].Products[crmid].image ? productsById[parentId].Products[crmid].image : '';

  if (existProductByCrmid) {
    const cartProduct = { ...existProductByCrmid };
    const { row } = cartProduct;
    if (quantity && cartProduct.quantity !== quantity) {
      cartProduct.quantity += quantity;
      cartProduct.comment = '';
      cartProductsById[row] = cartProduct;
    } else {
      cartProduct.quantity += 1;
      cartProduct.comment = '';
      cartProduct.inStock = inStock;
      cartProductsById[row] = cartProduct;
    }
  } else if (
    parentId in productsById
    && crmid in productsById[parentId].Products
  ) {
    const product = { ...productsById[parentId].Products[crmid] };
    const priceWithTaxes = cartHelper.getPriceWithTaxesUnitPrice(product);
    const row = Object.keys(cartProductsById).length + 1;
    product.quantity = quantity || 1;
    product.comment = '';
    product.inStock = inStock;
    product.discount_percent = 0;
    product.discount_amount = 0;
    product.priceWithTaxes = priceWithTaxes;
    product.row = row;
    product.image = productImage || parentImage;
    cartProductsById[row] = product;
    lastProductAdded = product;
  } else {
    return state;
  }
  const newState = cartHelper.transformCartProductsToArrayAndGetTotals(
    cartProductsById,
    discountTaxes,
    discount,
  );
  return { ...state, ...newState, cartProductsById, lastProductAdded };
};

const addDirectProductToCart = (
  state,
  { parentId, crmid, productsById, quantity, inStock, discountTaxes, discount, prod },
) => {
  const cartProductsById = { ...state.cartProductsById };
  let lastProductAdded = {};
  if (parentId in productsById && crmid in productsById[parentId].Products) {
    const product = { ...productsById[parentId].Products[crmid] };
    const priceWithTaxes = cartHelper.getPriceWithTaxesUnitPrice(product);
    const row = Object.keys(cartProductsById).length + 1;
    product.quantity = quantity || 1;
    product.comment = '';
    product.inStock = inStock;
    product.discount_percent = 0;
    product.discount_amount = 0;
    product.priceWithTaxes = priceWithTaxes;
    product.unit_price = parseFloat(prod.listprice);
    product.row = row;
    cartProductsById[row] = product;
    lastProductAdded = product;
  } else {
    return state;
  }
  const newState = cartHelper.transformCartProductsToArrayAndGetTotals(
    cartProductsById,
    discountTaxes,
    discount,
  );
  return { ...state, ...newState, cartProductsById, lastProductAdded };
};

const addProductToCart = (
  state,
  { parentId, crmid, productsById, quantity, inStock, discountTaxes, discount },
) => {
  const cartProductsById = { ...state.cartProductsById };
  let lastProductAdded = {};
  if (parentId in productsById && crmid in productsById[parentId].Products) {
    const product = { ...productsById[parentId].Products[crmid] };
    const priceWithTaxes = cartHelper.getPriceWithTaxesUnitPrice(product);
    const row = Object.keys(cartProductsById).length + 1;
    product.quantity = quantity || 1;
    product.comment = '';
    product.inStock = inStock;
    product.discount_percent = 0;
    product.discount_amount = 0;
    product.priceWithTaxes = priceWithTaxes;
    product.row = row;
    cartProductsById[row] = product;
    lastProductAdded = product;
  } else {
    return state;
  }
  const newState = cartHelper.transformCartProductsToArrayAndGetTotals(
    cartProductsById,
    discountTaxes,
    discount,
  );
  return { ...state, ...newState, cartProductsById, lastProductAdded };
};

const addOrderToCart = (state, { order, productsById, discountTaxes, discount }) => {
  clearCart(state);
  const { LineItems } = order;
  const cartProductsById = { ...state.cartProductsById };

  LineItems.forEach(async (product) => {
    const { parent_id } = product;
    const row = Object.keys(cartProductsById).length + 1;
    const productid = product.productid.indexOf('x') !== -1
      ? product.productid.split('x').pop()
      : product.productid;
    const quantity = toolsHelper.numberFormat(product.quantity);
    const price = toolsHelper.numberFormat(product.listprice, 8);
    const preDiscountAmount = quantity * (price * (product.discount_percent / 100));
    const discountAmount = parseFloat(product.discount_percent) === 0
      ? product.discount_amount
      : preDiscountAmount;
    const priceWithTaxes = cartHelper.getPriceWithTaxes(product);

    if (parent_id !== null && Number.parseInt(parent_id) !== 0) {
      if (
        parent_id in productsById
        && productid in productsById[parent_id].Products
      ) {
        cartProductsById[row] = {
          ...productsById[parent_id].Products[productid],
        };
        cartProductsById[row].quantity = quantity;
        cartProductsById[row].row = row;
        cartProductsById[row].unit_price = price;
        cartProductsById[row].priceWithTaxes = priceWithTaxes;
        cartProductsById[row].comment = product.comment;
        cartProductsById[row].discount_percent = product.discount_percent === ''
          ? 0
          : parseInt(product.discount_percent);
        cartProductsById[row].discount_amount = discountAmount;
      } else {
        cartProductsById[row] = { ...product };
        cartProductsById[row].productname = product.product_name;
        cartProductsById[row].quantity = quantity;
        cartProductsById[row].row = row;
        cartProductsById[row].unit_price = price;
        cartProductsById[row].priceWithTaxes = priceWithTaxes;
        cartProductsById[row].comment = product.comment;
        cartProductsById[row].discount_percent = product.discount_percent === ''
          ? 0
          : parseInt(product.discount_percent);
        cartProductsById[row].discount_amount = discountAmount;
      }
    } else {
      cartProductsById[row] = { ...product };
      cartProductsById[row].productname = product.product_name;
      cartProductsById[row].quantity = quantity;
      cartProductsById[row].row = row;
      cartProductsById[row].unit_price = price;
      cartProductsById[row].priceWithTaxes = priceWithTaxes;
      cartProductsById[row].comment = product.comment;
      cartProductsById[row].discount_percent = product.discount_percent === ''
        ? 0
        : parseInt(product.discount_percent);
      cartProductsById[row].discount_amount = discountAmount;
    }
  });
  const newState = cartHelper.transformCartProductsToArrayAndGetTotals(
    cartProductsById,
    discountTaxes,
    discount,
  );

  const description = order.description;
  const orderSelected = { order };
  const { treebesalmid, account_id } = order;
  orderSelected.cartStateWarehouse = order.estado_mda;
  orderSelected.almid = treebesalmid ? treebesalmid.split('x').pop() : 0;
  orderSelected.account_id = account_id.split('x').pop();
  orderSelected.balance = Number.parseFloat(order.tfde_balance);
  orderSelected.total = Number.parseFloat(order.hdnGrandTotal);
  orderSelected.entregado = order.p_entregado;
  orderSelected.status = order.sostatus;
  orderSelected.crmid = order.crmid;
  orderSelected.description = order.description;
  orderSelected.f_ultima_factura = order.f_ultima_factura;
  orderSelected.cfdis = order.cfdis;
  orderSelected.estadosat = order.estadosat;
  orderSelected.treebessatcfdiid = order.treebessatcfdiid;

  const balance = order.tfde_balance;
  const pagado = order.tfde_pagado;
  return {
    ...state,
    ...newState,
    pagado,
    balance,
    description,
    orderSelected,
    cartProductsById,
  };
};

const increaseProductInCart = (state, { row, inStock, discount, count }) => {
  const cartProductsById = { ...state.cartProductsById };
  const product = cartProductsById[row];
  if (product) {
    product.quantity += parseFloat(count);
    product.inStock = inStock;
    cartProductsById[row] = product;
    const newState = cartHelper.transformCartProductsToArrayAndGetTotals(
      cartProductsById,
      false,
      discount,
    );
    return { ...state, ...newState, cartProductsById };
  }
  return state;
};

const decreaseProductInCart = (state, { row, inStock, discount }) => {
  const cartProductsById = { ...state.cartProductsById };
  const product = cartProductsById[row];
  if (product) {
    if (product.quantity > 1) {
      product.quantity -= 1;
    } /* else {
      product.quantity = 1;
    } */

    product.inStock = inStock;
    cartProductsById[row] = product;
    const newState = cartHelper.transformCartProductsToArrayAndGetTotals(
      cartProductsById,
      false,
      discount,
    );
    return { ...state, ...newState, cartProductsById };
  }
  return state;
};

const updateProductPriceInCart = (state, { row, price, discount }) => {
  const cartProductsById = { ...state.cartProductsById };
  const product = cartProductsById[row];
  if (product) {
    cartProductsById[row].unit_price = Math.abs(price);
    cartProductsById[
      row
    ].priceWithTaxes = cartHelper.getPriceWithTaxesUnitPrice(
      cartProductsById[row],
    );
    const newState = cartHelper.transformCartProductsToArrayAndGetTotals(
      cartProductsById,
      false,
      discount,
    );
    return { ...state, ...newState, cartProductsById };
  }
  return state;
};

const updateProductQuantityInCart = (state, { row, qty, discount }) => {
  const cartProductsById = { ...state.cartProductsById };
  const product = cartProductsById[row];
  if (product) {
    if (!Number.isNaN(qty) && qty > 0) {
      cartProductsById[row].quantity = qty;
      const newState = cartHelper.transformCartProductsToArrayAndGetTotals(
        cartProductsById,
        false,
        discount,
      );
      return { ...state, ...newState, cartProductsById };
    }
    return state;
  }
  return state;
};

function updateServicePriceByWeightCart(state, { cen, discount }) {
  const cartProductsById = { ...state.cartProductsById };
  const {
    id_envio_aereo,
    envio_aereo,
    id_envio_terrestre,
    envio_terrestre,
  } = cen;
  let { totalWeight } = state;

  if (totalWeight === Math.floor(totalWeight)) {
    totalWeight = totalWeight === 0 ? 0 : totalWeight - 1;
  }

  totalWeight = Math.floor(totalWeight);
  totalWeight = totalWeight > 100 ? 100 : totalWeight;

  if (id_envio_aereo in cartProductsById) {
    const envioAereo = cartProductsById[id_envio_aereo];
    const price = envio_aereo[totalWeight] || 0;
    envioAereo.unit_price = price;
    envioAereo.totalPrice = price;
    cartProductsById[id_envio_aereo] = envioAereo;
  }

  if (id_envio_terrestre in cartProductsById) {
    const envioTerrestre = cartProductsById[id_envio_terrestre];
    const price = envio_terrestre[totalWeight] || 0;
    envioTerrestre.unit_price = price;
    envioTerrestre.totalPrice = price;
    cartProductsById[id_envio_terrestre] = envioTerrestre;
  }

  if (
    id_envio_aereo in cartProductsById
    || id_envio_terrestre in cartProductsById
  ) {
    const newState = cartHelper.transformCartProductsToArrayAndGetTotals(
      cartProductsById,
      false,
      discount,
    );
    return { ...state, ...newState, cartProductsById };
  }

  return state;
}

const removeProductInCart = (state, { row, discount }) => {
  const cartProductsById = { ...state.cartProductsById };
  if (cartProductsById[row]) {
    delete cartProductsById[row];
    const newCartProductsById = {};
    Object.entries(cartProductsById).forEach((x, i) => {
      const product = x[1];
      const newRow = i + 1;
      product.row = newRow;
      newCartProductsById[newRow] = product;
    });
    const newState = cartHelper.transformCartProductsToArrayAndGetTotals(
      newCartProductsById,
      false,
      discount,
    );
    return { ...state, ...newState, cartProductsById: newCartProductsById };
  }
  return state;
};

const clearCart = (state) => {
  const stockProducts = state.productsStock;
  const cart = defaultState();
  cart.products = [];
  cart.productsStock = stockProducts;
  return cart;
};

const togglePromotions = (state) => {
  let newState = {};
  if (state.activePromotions) {
    newState = { ...state, promotions: {}, productsWidthpromotions: [] };
  } else {
    newState = { ...state };
  }
  return { ...newState, activePromotions: !state.activePromotions };
};

const modifyWeightByPayPalService = (state) => {
  const { totalWeight } = state;
  const newState = cartHelper.calculateWeightByPaypalService(totalWeight);
  return { ...state, ...newState };
};

const alertStock = (state, { productsWithOutStock }) => {
  const alertProducts = productsWithOutStock;
  return { ...state, alertProducts };
};

const setDesc = (state, { description }) => ({ ...state, description });

const setComment = (state, { product, discount }) => {
  const { row, comment } = product;
  const { cartProductsById } = state;
  cartProductsById[row].comment = comment;
  const newState = cartHelper.transformCartProductsToArrayAndGetTotals(
    cartProductsById,
    false,
    discount,
  );
  return { ...state, ...newState, cartProductsById };
};

const updatePromotions = (state, { TreebesPromociones, products, discountTaxes, discount }) => {
  const updateProducts = [...state.products];
  const updateProductsById = { ...state.cartProductsById };
  products.map((prod) => {
    const index = updateProducts.findIndex(p => p.crmid === prod.productid);
    if (
      index !== -1
      && updateProducts[index].unit_price !== prod.TreebesPromociones_unit_price
    ) {
      updateProducts[index].unit_price = formatMoney(
        prod.TreebesPromociones_unit_price,
      );
      updateProducts[index].priceWithTaxes = prod.TreebesPromociones_unit_price_with_taxes;

      // Sele suma 1 pq hace referencia a la fila de la table q comienza en 1.
      updateProductsById[index + 1].unit_price = formatMoney(
        prod.TreebesPromociones_unit_price,
      );
      updateProductsById[index + 1].priceWithTaxes = prod.TreebesPromociones_unit_price_with_taxes;
    }
  });
  const newState = cartHelper.transformCartProductsToArrayAndGetTotals(
    updateProductsById,
    discountTaxes,
    discount,
  );

  return {
    ...state,
    ...newState,
    promotions: { ...TreebesPromociones },
    productsWidthpromotions: [...products],
    products: [...updateProducts],
    cartProductsById: { ...updateProductsById },
  };
};

const setSingleDiscount = (state, { row, percentage, amount, discount }) => {
  const cartProductsById = { ...state.cartProductsById };
  if (cartProductsById[row]) {
    if (!Number.isNaN(percentage) && percentage > 0) {
      cartProductsById[row].discount_amount = amount;
      cartProductsById[row].discount_percent = percentage;
      cartProductsById[
        row
      ].priceWithTaxes = cartHelper.getPriceWithTaxesUnitPrice(
        cartProductsById[row],
      );
    } else {
      cartProductsById[row].discount_amount = 0;
      cartProductsById[row].discount_percent = 0;
      cartProductsById[
        row
      ].priceWithTaxes = cartHelper.getPriceWithTaxesUnitPrice(
        cartProductsById[row],
      );
    }
    const newState = cartHelper.transformCartProductsToArrayAndGetTotals(
      cartProductsById,
      false,
      discount,
    );
    return { ...state, ...newState, cartProductsById };
  }
  return state;
};

// Add shipping services to cart.
const addShippingService = (state, { product, discount }) => {
  let incomingProduct = { ...product };
  const productosId = { ...state.cartProductsById };
  const index = Object.values(state.cartProductsById).findIndex(prod => prod.crmid === incomingProduct.crmid);
  if (index !== -1) {
    delete productosId[index + 1];
  }

  const priceWithTaxes = cartHelper.getPriceWithTaxesUnitPrice(incomingProduct);
  const row = Object.keys(productosId).length + 1;
  incomingProduct.quantity = incomingProduct.quantity || 1;
  incomingProduct.comment = '';
  incomingProduct.discount_percent = 0;
  incomingProduct.discount_amount = 0;
  incomingProduct.priceWithTaxes = priceWithTaxes;
  incomingProduct.row = row;
  productosId[row] = incomingProduct;

  const newState = cartHelper.transformCartProductsToArrayAndGetTotals(
    productosId,
    false,
    discount,
  );

  return {
    ...state,
    ...newState,
    cartProductsById: productosId,
  };
};

const setCartUpdate = (
  state,
  { cartProductsById, orderSelected, discountTaxes, discount },
) => {
  const newState = cartHelper.transformCartProductsToArrayAndGetTotals(
    cartProductsById,
    discountTaxes,
    discount,
  );
  return { ...state, ...newState, cartProductsById, orderSelected };
};

const updateTotals = (
  state,
  { cartProductsById, discountTaxes, discount },
) => {
  const newState = cartHelper.transformCartProductsToArrayAndGetTotals(
    cartProductsById,
    discountTaxes,
    discount,
  );
  return { ...state, ...newState, cartProductsById };
};

// Add shipping services to cart.
const updateOrderCurrency = (state, { products, cartProductsById, cartProductsByArray, orderSelected }) => ({
  ...state,
  products,
  cartProductsById,
  cartProductsByArray,
  orderSelected,
});

export default function (state = defaultState(), action) {
  const { type } = action;
  switch (type) {
    case ADD_DIRECT_PRODUCT_TO_CART:
      return addDirectProductToCart(state, action);
    case ADD_PRODUCT_TO_CART:
      return addProductToCart(state, action);
    case ADD_PRODUCT_TO_CART_QTY:
      return addProductToCartQty(state, action);
    case ADD_ORDER_TO_CART:
      return addOrderToCart(state, action);
    case INCREASE_PRODUCT_IN_CART:
      return increaseProductInCart(state, action);
    case DECREASE_PRODUCT_IN_CART:
      return decreaseProductInCart(state, action);
    case UPDATE_PRODUCT_PRICE_IN_CART:
      return updateProductPriceInCart(state, action);
    case UPDATE_SERVICE_PRICE_BY_WEIGHT_CART:
      return updateServicePriceByWeightCart(state, action);
    case UPDATE_PRODUCT_QTY_IN_CART:
      return updateProductQuantityInCart(state, action);
    case REMOVE_PRODUCT_IN_CART:
      return removeProductInCart(state, action);
    case VERIFY_PAYPAL_SERVICE:
      return modifyWeightByPayPalService(state);
    case ALERT_PRODUCTS:
      return alertStock(state, action);
    case SET_DESC:
      return setDesc(state, action);
    case SET_COMMENT:
      return setComment(state, action);
    case UPDATE_PROMOTIONS:
      return updatePromotions(state, action);
    case SET_SINGLE_DISCOUNT:
      return setSingleDiscount(state, action);
    case UPDATE_CART_STATE:
      return setCartUpdate(state, action);
    case UPDATE_TOTALS:
      return updateTotals(state, action);
    case CLEAR_CART:
      return clearCart(state);
    case SET_PROMOTIONS:
      return togglePromotions(state);
    case ACTIVE_PROMOTIONS:
      return {
        ...state,
        activePromotions: true,
      };
    case DESACTIVE_PROMOTIONS:
      return {
        ...state,
        activePromotions: false,
      };
    case RESET_PROMOTIONS:
      return {
        ...state,
        promotions: {},
        productsWidthpromotions: [],
      };
    case ADD_SHIPPING_SERVICE:
      return addShippingService(state, action);
    case ADD_PRODUCTS_STOCK:
      return {
        ...state,
        productsStock: { ...action.stock },
      };
    case CHANGE_SIN_STOCK:
      return {
        ...state,
        sinStock: action.sinStock,
      };
    case ADD_SELECTED_GUIDE:
      return {
        ...state,
        selectedGuide: action.guide,
      };
    case CLEAR_SELECTED_GUIDE:
      return {
        ...state,
        selectedGuide: {},
      };
    case ADD_SELECTED_PACKING:
      return {
        ...state,
        selectedPacking: action.packing,
      };
    case CLEAR_SELECTED_PACKING:
      return {
        ...state,
        selectedPacking: {},
      };
    case UPDATE_ORDER_CURRENCY:
      return updateOrderCurrency(state, action);
    case OPEN_CART_DETAIL:
      return {
        ...state,
        openDetail: action.open,
        detailTabActive: !action.open ? -1 : state.detailTabActive,
      };
    case SET_CAR_DETAIL_ACTIVE_TAB:
      return {
        ...state,
        openDetail: true,
        detailTabActive: action.tab,
      };
    default:
      return state;
  }
}
