/* eslint-disable camelcase */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Serialize from 'form-serialize';
import {
  Form,
  FormGroup,
  Modal,
  ModalBody,
  ModalHeader,
  UncontrolledCollapse,
  // Label,
} from 'reactstrap';

import {
  Input, InputLabel, Divider, Select, MenuItem,
} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import MaskedInput from 'react-text-mask';

import { result } from 'lodash';
import modalsProps from '../../../propTypes/modalsProps';
import loadsProps from '../../../propTypes/loadsProps';

import LoadComponent from '../../Load/LoadComponent';

/* Modal */
import CreateAddress from './CreateAddress';
import AddressList from './address/AddressList';
import CustomerAPI from '../../../api/CustomerAPI';
import { phoneHelper } from '../../../helpers';

function TextMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={['(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      // showMask
      guide={false}
    />
  );
}

TextMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

class CreateCustomer extends PureComponent {
  static propTypes = {
    modals: modalsProps.isRequired,
    loads: loadsProps.isRequired,
    customer: PropTypes.object.isRequired,
    dispatchToggleModal: PropTypes.func.isRequired,
    dispatchCreateCustomer: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);
    this.calle = null;
    this.numeroExterior = null;
    this.colonia = null;
    this.delegacion = null;
    this.estado = null;
    this.pais = null;
    this.codigoPostal = null;
    this.phone = '';
    this.state = {
      addModal: false,
      address: [],
      regimen: [],
      schema: [],
      select: {
        city: '',
        country: '',
        dir_references: '',
        direccion: '',
        latitude: '',
        longitude: '',
        neighborhood: '',
        num_ext: '',
        num_int: '',
        postcode: '',
        state: '',
        id: 0,
        factura: false,
        envio: false,
      },
    };
  }

  componentDidMount() {
    this.clientSchema();
    this.regimenList();
  }

  componentWillReceiveProps(nextProps) {
    const { modals } = this.props;
    if (modals.customerCreateModalIsOpen && !nextProps.modals.customerCreateModalIsOpen) {
      this.setState({
        address: [],
        select: {},
      });
    }
  }


  setRef = (node) => {
    if (node !== null) {
      const { name, tagName } = node;
      if (tagName === 'INPUT' && name in this) {
        this[name] = node;
      }
      else if (tagName === 'INPUT' && name === 'numExt') {
        this.numeroExterior = node;
      }
    }
  }

  toggleModal = () => {
    const { dispatchToggleModal } = this.props;
    dispatchToggleModal('customerCreate');
  }

  toggleAddModal = () => {
    this.setState(prev => ({
      addModal: !prev.addModal,
      select: {},
    }));
  }

  addAddress = (add) => {
    const { address } = this.state;
    if (!address.some(x => x.id === add.id)) {
      const _add = { ...add };
      _add.id = Math.random();
      _add.factura = Boolean(address.length === 0);
      _add.envio = Boolean(address.length === 0);
      this.setState(prev => ({
        address: [...prev.address, _add],
        addModal: false,
      }));
    } else {
      const _address = address.map((x) => {
        let el = { ...x };
        if (x.id === add.id) {
          el = { ...add };
        }
        return el;
      });
      this.setState({
        address: _address,
        addModal: false,
      });
    }
  }

  handlePhoneChange = (phoneVelue) => {
    this.setState({ phone: phoneHelper.formatPhone(phoneVelue) });
  }

  handleBooleanParam = (id, tag) => {
    const { address } = this.state;
    if (address.some(x => x.id === id)) {
      const _address = address.map(x => ({
        ...x,
        [tag]: (Boolean(x.id === id)),
      }));
      this.setState({ address: _address });
    }
  }

  onSelect = (id) => {
    const { address } = this.state;
    const element = address.find(x => x.id === id);
    if (element) {
      this.setState(prev => ({
        select: {
          ...prev.select,
          ...element,
        },
        addModal: true,
      }));
    }
  }

  onDeleteAddress = (id) => {
    const { address } = this.state;
    if (id !== '') {
      let _address = address.filter(x => x.id !== id);
      if (_address.length === 1) {
        _address = _address.map(x => ({ ...x, factura: true, envio: true }));
      }
      this.setState({
        address: _address,
      });
    }
  }

  sendCustomer = (event) => {
    event.preventDefault();
    const { dispatchCreateCustomer } = this.props;
    const customer = Serialize(event.target, { hash: true });
    const { address } = this.state;
    dispatchCreateCustomer(customer, address);
  }

  onKeyPress = (event) => {
    if (event.which === 13 /* Enter */) {
      event.preventDefault();
    }
  }

  regimenList = async () => {
    const CustomerApi = new CustomerAPI();
    const response = await CustomerApi.getRegionList();
    if (response.success && Object.keys(response.result).length) {
      this.setState({ regimen: Object.values(response.result) });
    }
  }

  clientSchema = async () => {
    const CustomerApi = new CustomerAPI();
    const response = await CustomerApi.getSchema();
    if (response.success && Object.keys(response.result).length) {
      this.setState({ schema: response.result[0].fields });
    }
  }

  requiredValue = (field) => {
    const { schema } = this.state;
    if (schema && schema.length) {
      const index = schema.findIndex(s => s.name === field && s.required);
      if (index !== -1) {
        return true;
      }
    }

    return false;
  }

  render() {
    const {
      modals,
      loads,
    } = this.props;
    const {
      addModal, address, select, regimen,
    } = this.state;
    return (
      <Modal
        backdrop="static"
        toggle={this.toggleModal}
        isOpen={modals.customerCreateModalIsOpen}
      >
        <ModalHeader toggle={this.toggleModal}>Agregar Cliente</ModalHeader>
        <ModalBody>
          <Form onKeyPress={this.onKeyPress} onSubmit={this.sendCustomer}>
            <div style={{
              display: 'flex', justifyContent: 'start', alignItems: 'center', width: '100%', gap: '1rem',
            }}
            >
              <FormGroup style={{ width: '100%' }}>
                <TextField
                  id="name"
                  label="Nombre"
                  name="nombre"
                  className="w-100"
                  required={this.requiredValue('firstname')}
                />
              </FormGroup>
              <FormGroup style={{ width: '100%' }}>
                <TextField
                  id="last-name"
                  label="Apellidos"
                  name="apellidos"
                  className="w-100"
                  required={this.requiredValue('lastname')}
                />
              </FormGroup>
            </div>
            <div style={{
              display: 'flex', justifyContent: 'start', alignItems: 'center', width: '100%', gap: '1rem',
            }}
            >
              <FormGroup style={{ width: '100%' }}>
                <InputLabel htmlFor="phone">Teléfono</InputLabel>
                <Input
                  id="phone"
                  label="Telefono"
                  name="telefono"
                  className="w-100"
                  required={this.requiredValue('phone')}
                  inputComponent={TextMaskCustom}
                  /* value={phone}
                  pattern="((\([0-9]{3}\)|[0-9]{3}-|[0-9]{3})([0-9]{3}-|[0-9]{3})[0-9]{4})"
                  onChange={e => this.handlePhoneChange(e.target.value)} */
                />
              </FormGroup>
              <FormGroup style={{ width: '100%' }}>
                <TextField
                  id="email"
                  label="Correo"
                  name="email"
                  type="email"
                  className="w-100"
                  required={this.requiredValue('email1')}
                />
              </FormGroup>
            </div>
            <div style={{
              display: 'flex', justifyContent: 'start', alignItems: 'center', width: '100%', gap: '1rem',
            }}
            >
              <FormGroup style={{ width: '100%' }}>
                <TextField
                  id="cardNumber"
                  label="Número de tarjeta de puntos"
                  name="point_card_number"
                  className="w-100"
                  required={false}
                />
              </FormGroup>
            </div>
            <>
              <div style={{
                display: 'flex', justifyContent: 'start', alignItems: 'center', width: '100%', gap: '1rem',
              }}
              >
                <FormGroup style={{ width: '100%' }}>
                  <TextField
                    id="socialReason"
                    label="Razón Social"
                    name="razonSocial"
                    className="w-100"
                    required={this.requiredValue('razon_social')}
                  />
                </FormGroup>
                <FormGroup style={{ width: '100%' }}>
                  <TextField
                    id="rfc"
                    label="RFC"
                    name="rfc"
                    className="w-100"
                    pattern="([a-zA-Z&]{3,4})(\d{2})(0[1-9]|1[1-2])([0][1-9]|[1-2][0-9]|3[0-1])([a-zA-Z0-9]{3})"
                    required={this.requiredValue('siccode')}
                  />
                </FormGroup>
              </div>
              <div style={{
                display: 'flex', justifyContent: 'start', alignItems: 'center', width: '100%', gap: '1rem',
              }}
              >
                <FormGroup style={{ width: '100%' }}>
                  <InputLabel htmlFor="regimenFiscal">Regimen Fiscal</InputLabel>
                  <Select
                    style={{ width: '100%', height: '36px !important' }}
                    native
                    onChange={() => {}}
                    required={this.requiredValue('c_regimenfiscal')}
                    inputProps={{
                      name: 'c_regimenfiscal',
                      placeholder: 'Regimen Fiscal',
                      id: 'regimenFiscal',
                    }}
                  >
                    <option value="" />
                    {
                      regimen.map(r => <option value={r}>{r}</option>)
                    }
                  </Select>
                </FormGroup>
                <FormGroup style={{ width: '100%' }}>
                  <TextField
                    style={{ width: '100%' }}
                    id="postalCode"
                    label="Codigo postal"
                    name="c_codigopostal"
                    className="w-100"
                    required={this.requiredValue('c_codigopostal')}
                  />
                </FormGroup>
              </div>
            </>
            <Button
              id="toggler"
              className="w-100"
              onClick={() => this.toggleAddModal()}
            >
              <span>
                <span className="mr-2">Añade una Dirección</span>
                <FontAwesomeIcon icon="plus" />
              </span>

            </Button>
            {address.length > 0 && (
              <div className="w-100 text-dark font-size-10">
                {`Num. direcciones: ${address.length}`}
              </div>
            )}
            <Divider />
            {address.length > 0 && (
              <AddressList
                arr={address}
                onSelect={this.onSelect}
                handleBooleanParam={this.handleBooleanParam}
                deleteAddress={this.onDeleteAddress}
              />
            )}
            <Button
              type="submit"
              color="primary"
              variant="contained"
              className="w-100 my-2"
            >
              Crear
            </Button>
          </Form>
          {
            loads.createCustomerIsLoading
              ? <LoadComponent />
              : ''
          }
        </ModalBody>
        <CreateAddress
          open={addModal}
          toggle={this.toggleAddModal}
          onSave={this.addAddress}
          title={(Object.keys(select).length === 0 || select.id === 0)
            ? 'Crear Dirección'
            : `Editar Dirección: ${select.direccion}`
          }
          {...select}
        />
      </Modal>
    );
  }
}

export default CreateCustomer;
