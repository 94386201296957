import React from 'react';
import PropTypes from 'prop-types';
// import moment from 'moment';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Collapse,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  Divider,
} from '@material-ui/core';

import ListMdaItem from './ListMdaItem';

const MdaList = ({
  mda,
  fetchMda,
  isLoading,
  isOpen,
}) => {
  const [arr, setArr] = React.useState([]);

  const initMda = (_mda) => {
    const array = [..._mda];
    let _arr = [];
    const labelArr = array.map(x => (x.entity_label));
    labelArr.forEach((x) => {
      if (!(_arr.includes(x))) {
        _arr = [..._arr, x];
      }
    });
    const mdaArr = _arr.map(x => ({
      entity_label: x,
      createdtime: array.filter(el => el.entity_label === x)[0].createdtime,
      de_documentoid_label: array.filter(el => el.entity_label === x)[0].de_documentoid_label,
      a_documentoid_label: array.filter(el => el.entity_label === x)[0].a_documentoid_label,
      items: array.filter(el => el.entity_label === x),
    }));
    setArr(mdaArr);
  };

  React.useEffect(() => {
    if (isOpen) {
      fetchMda();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  React.useEffect(() => {
    initMda(mda);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mda]);
  return (
    <div className="w-100 h-auto">
      <Collapse in={isLoading}>
        <LinearProgress />
      </Collapse>
      <Collapse in={!isLoading}>
        {arr.length > 0 ? (
          <List className="mda_list">
            {arr.map((item, i) => (
              <div key={`key_mda_${item.createdtime}`} className="w-100">
                {i !== 0 && (
                  <Divider />
                )}
                <ListMdaItem item={item} />
              </div>
            ))}
          </List>
        ) : (
          <List dense>
            <ListItem>
              <ListItemText
                primary="Sin movimientos de almacén"
                secondary="La orden no cuenta con movimientos de almacén"
              />
            </ListItem>
          </List>
        )}
      </Collapse>
    </div>
  );
};

MdaList.propTypes = {
  mda: PropTypes.array.isRequired,
  fetchMda: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

export default MdaList;
