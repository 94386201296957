/* eslint-disable no-restricted-syntax */
/* eslint-disable no-nested-ternary */
/* eslint-disable array-callback-return */
/* eslint-disable import/no-cycle */
import React, { useEffect, useState } from 'react';
import {
  Avatar,
  Collapse,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { toast } from 'react-toastify';
import ProductAPI from '../../../../api/ProductAPI';
import { formatString, createOrderRef } from '../../../../helpers/tools';
import ListMdaItem from '../../../Mda/components/ListMda/ListMdaItem';

import '../../deliver/OrderDeliver/deliver.styles.scss';
import MdaAPI from '../../../../api/MdaApi';
import OrderMda from '../../OrderMda';
import FullDialog from '../../../FullDialog';
import ReceiverTransferProducts from '../TransferReceiverProducts';

const TransferReceiver = () => {
  const history = useHistory();
  const params = useParams();
  const authUser = useSelector(state => state.authUser);

  const [isDeliver, setIsDeliver] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingMda, setLoadingMda] = useState(false);
  const [products, setProducts] = useState([]);
  const [stock, setStock] = useState([]);
  const [entity, setEntity] = useState({});
  const [order, setOrder] = useState({});
  const [mda, setMda] = useState([]);

  const productApi = new ProductAPI();
  const mdaApi = new MdaAPI();
  const {
    user: {
      config: { store, id },
    },
  } = authUser;

  const move = 'EP';
  const ref = `${move}-${createOrderRef()}-${id || '-1'}`;


  const updateBarCode = (item, barCode) => {
    const prods = [...products];
    const prodIndex = prods.findIndex(p => p.productid === item.productid);
    prods[prodIndex].codigo_barras = barCode;
    setProducts([...prods]);
  };

  const orderDeliverDetails = async () => {
    setLoading(true);
    const response = await mdaApi.transfersListToReceive(
      store.crmid,
      params.orderId,
    );
    console.log('response transfers', response);

    if (response.success === false) {
      toast.error('Error cargando la orden');
      setLoading(false);

      return;
    }

    if (response.result[0].detail?.length) {
      const formatProducts = [];
      response.result[0].detail.map((prod) => {
        formatProducts.push({
          ...prod,
          por_recibir: 0,
        });
      });
      setProducts(formatProducts);
    }

    if (response.result.length) {
      const { detail, ...rest } = response.result[0];
      const transfer = {
        ...rest,
      };

      setOrder(transfer);
    }

    setLoading(false);
  };

  const rest = (prodId) => {
    const prods = products;
    const prodIndex = prods.findIndex(p => p.treebesmdaid === prodId);
    prods[prodIndex].por_recibir -= 1;
    setProducts([...prods]);
  };

  const add = (prodId) => {
    const prods = products;
    const prodIndex = prods.findIndex(p => p.treebesmdaid === prodId);
    prods[prodIndex].por_recibir += 1;

    setProducts([...prods]);
  };

  const selectAll = () => {
    const prods = products;
    prods.map((prod, index) => {
      prods[index].por_recibir = prod.cantidad;
    });

    setProducts([...prods]);
  };

  const selectNone = () => {
    const prods = products;
    for (const prod of prods) {
      prod.por_recibir = 0;
    }

    setProducts([...prods]);
  };

  const changeInput = (value, prodId) => {
    const prods = products;
    const prodIndex = prods.findIndex(p => p.treebesmdaid === prodId);

    if (
      parseFloat(value) > prods[prodIndex].cantidad
      || parseFloat(value < 1)
    ) {
      setProducts([...prods]);
      toast.warn(
        `El valor no puede ser mayor que ${prods[prodIndex].cantidad} o menor que 0`,
      );

      return;
    }

    prods[prodIndex].por_recibir = parseFloat(value);

    setProducts([...prods]);
  };

  const receiverTransfer = async () => {
    setIsDeliver(true);
    const data = [];
    products.map((prod) => {
      const item = { mdaid: prod.treebesmdaid, qty: prod.por_recibir };
      data.push(item);
    });
    const response = await mdaApi.setTransferDeliver(data);

    if (response.message.success) {
      toast.success(response.message.success);
    }
    if (response.message.error) {
      toast.error(response.message.error);
    }
    setIsDeliver(false);
    history.goBack();
  };

  useEffect(() => {
    if (params.orderId && store.crmid) {
      orderDeliverDetails();
    }
  }, []);

  return (
    <FullDialog
      title={`Recibir Transferencia ${params?.orderId || ''}`}
      isOpen
      isLoading={isDeliver}
      onClose={() => history.goBack()}
      onSave={receiverTransfer}
      enableAction={!loading && !loadingMda && !isDeliver && products.length > 0}
    >
      <div className="deliver">
        <Collapse in={Boolean(!loading)} className="w-100">
          <Paper className="deliver__client">
            {Object.keys(order).length > 0 && (
              <ListMdaItem
                item={{
                  createdtime: order.fecha,
                  entity_label: '',
                  de_documentoid_label: params.orderId,
                  a_documentoid_label: store.name,
                }}
                isCollapse={false}
                ec="Recepción"
              />
            )}
            <Divider />
            {Object.keys(order).length > 0 && (
              <List className="w-100">
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>{order.assigned_user_label.substring(0, 1)}</Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={formatString(order.assigned_user_label)}
                    secondary="Cliente"
                  />
                </ListItem>
              </List>
            )}
          </Paper>
        </Collapse>
        <ReceiverTransferProducts
          products={products}
          changeInput={changeInput}
          rest={rest}
          add={add}
          loading={loading}
          selectAll={selectAll}
          selectNone={selectNone}
          updateBarCode={updateBarCode}
        />
      </div>
    </FullDialog>
  );
};

export default TransferReceiver;
