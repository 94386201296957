/* eslint-disable import/no-cycle */
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Collapse, List, ListItem, ListItemIcon, ListItemText,
} from '@material-ui/core';
import { AttachMoney, ExpandLess, ExpandMore } from '@material-ui/icons';
import { toast } from 'react-toastify';
import { fetchAllProducts } from '../../redux/actions/productActions';
import { fetchAllOrders } from '../../redux/actions/orderActions';
import { addProductToCart, clearCart } from '../../redux/actions/cartActions';

const Currency = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [selectedCurrency, setSelectedCurrency] = React.useState(null);
  const user = useSelector(state => state.authUser.user);
  const cart = useSelector(state => state.cart);
  const { enableEditOrder } = useSelector(state => state.order);
  const { usable_currencies: usableCurrencies } = user;
  const { orderSelected, products } = cart;

  const selectCurrency = async (currency) => {
    const currencyIDStorage = localStorage.getItem('currencyId');
    if (currencyIDStorage === currency.id) {
      return;
    }
    localStorage.setItem('currencyId', currency.id);
    const productsCart = [...products];
    setSelectedCurrency(currency);
    await dispatch(fetchAllProducts());
    await dispatch(fetchAllOrders());
    if (!Object.keys(orderSelected).length) {
      await dispatch(clearCart());
      // eslint-disable-next-line no-restricted-syntax
      productsCart.map(async (product) => {
        const { parentId, quantity, crmid } = product;
        await dispatch(addProductToCart(parentId, crmid, quantity));
      });
    }
  };

  const tryToChangeCurrency = (currency) => {
    if (enableEditOrder) {
      toast.info('Debes terminar de editar la orden para cambar la moneda');

      return;
    }

    selectCurrency(currency);
  };

  const setActualCurrency = () => {
    const currencyIDStorage = localStorage.getItem('currencyId');
    const currencySaved = (currencyIDStorage === 'undefined' || currencyIDStorage === 'null') ? '1' : currencyIDStorage;
    const moneda = usableCurrencies.find(c => c.id === currencySaved);
    if (moneda) {
      selectCurrency(moneda);
    }
  };

  const changeCurrencyToOrderCurrency = () => {
    if (Object.keys(orderSelected).length) {
      const { order } = orderSelected;
      const currencyId = order.currency_id.split('x').pop();
      const moneda = usableCurrencies.find(c => c.id === currencyId);
      if (moneda) {
        selectCurrency(moneda);
      }
    }
  };

  React.useEffect(() => {
    setActualCurrency();
  }, []);

  /* React.useEffect(() => {
    setActualCurrency();
  }, [store]);

  React.useEffect(() => {
    changeCurrencyToOrderCurrency();
  }, [orderSelected]); */

  return (
    <div>
      <List
        component="nav"
        aria-labelledby="nested-list-payMethods"
      >
        <ListItem
          dense
          button
          onClick={() => setOpen(!open)}
        >
          <ListItemIcon>
            <AttachMoney />
          </ListItemIcon>
          <ListItemText className="method_list_header" primary="Moneda" secondary={selectedCurrency?.currency_name} />
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse
          in={open}
          timeout="auto"
          unmountOnExit
          className="method_list"
        >
          <List dense component="nav" aria-label="StoreCatalog-method">
            {
              usableCurrencies.map(currency => (
                <ListItem
                  key={currency.currency_id}
                  dense
                  button
                  className="pl-4"
                  aria-label={currency.currency_code}
                  onClick={() => tryToChangeCurrency(currency)}
                  aria-haspopup="true"
                  aria-controls={`selectable_method${currency.currency_code}`}
                >
                  <ListItemText
                    primary={currency.currency_code}
                    secondary={`${currency.currency_name}`}
                  />
                </ListItem>
              ))
            }
          </List>
        </Collapse>
      </List>
    </div>
  );
};

export default Currency;
