import React, { useState } from 'react';
import './Number.scss';
import PropTypes from 'prop-types';

import { ButtonBase, Zoom } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const InputNumber = ({
  number,
  fontSize,
  onlyNumber,
  width,
  circleDiameter,
  height,
  disabledPlus,
  disabledMinus,
  onClickPlus,
  onClickMinus,
  onChange,
  onWarning,
  onDanger,
}) => {
  const [customNumber, setCustomNumber] = useState(number);
  let timeout = null;
  React.useEffect(() => {
    setCustomNumber(number < 0 ? 0 : number);
  }, [number]);
  return (
    <div className="inputContainer">
      <Zoom in={onlyNumber} mountOnEnter unmountOnExit>
        <div className="onlyNumber">
          <div
            className="onlyNumber_circle"
            style={{
              fontSize: `${fontSize}px`,
              width: circleDiameter,
              height: circleDiameter,
            }}
          >
            {number}
          </div>
        </div>
      </Zoom>
      <Zoom in={!onlyNumber} mountOnEnter unmountOnExit>
        <div
          className={`inputNumber ${
            onDanger && ('inputNumber_dang')
          } ${
            onWarning && ('inputNumber_warn')
          }`}
          style={{
            width,
            height,
          }}
        >
          <ButtonBase
            disabled={disabledMinus || !customNumber}
            onClick={() => onClickMinus()}
          >
            <FontAwesomeIcon
              icon="minus"
              style={{
                width: `${fontSize}px`,
                heigth: `${fontSize}px`,
                color: `${disabledMinus ? ('#c6c6c6') : ('#303c52')}`,
              }}
            />
          </ButtonBase>
          <input
            className="inputNumber_number"
            style={{
              fontSize: `${fontSize}px`,
              minWidth: '30px',
            }}
            disabled={disabledPlus && disabledMinus}
            min={0}
            step={0.1}
            value={customNumber}
            onFocus={e => e.target.select()}
            onChange={(e) => {
              const { target: { value } } = e;
              const customValue = value < 0 ? 0 : value;
              clearTimeout(timeout);
              setCustomNumber(customValue);
              timeout = setTimeout(() => {
                onChange(customValue);
              }, 1000);
            }}
          />
          <ButtonBase
            disabled={disabledPlus}
            onClick={() => onClickPlus()}
          >
            <FontAwesomeIcon
              icon="plus"
              style={{
                width: `${fontSize}px`,
                heigth: `${fontSize}px`,
                color: `${disabledPlus ? ('#c6c6c6') : ('#303c52')}`,
              }}
            />
          </ButtonBase>
        </div>
      </Zoom>
    </div>
  ); };

InputNumber.propTypes = {
  number: PropTypes.number,
  fontSize: PropTypes.number,
  width: PropTypes.string,
  height: PropTypes.string,
  circleDiameter: PropTypes.string,
  onlyNumber: PropTypes.bool,
  disabledPlus: PropTypes.bool,
  disabledMinus: PropTypes.bool,
  onClickPlus: PropTypes.func,
  onClickMinus: PropTypes.func,
  onChange: PropTypes.func,
  onWarning: PropTypes.bool,
  onDanger: PropTypes.bool,
};

InputNumber.defaultProps = {
  number: 0,
  fontSize: 18,
  width: '100%',
  height: '100%',
  circleDiameter: '36px',
  onlyNumber: false,
  disabledPlus: false,
  disabledMinus: false,
  onClickPlus: () => {},
  onClickMinus: () => {},
  onChange: () => {},
  onWarning: false,
  onDanger: false,
};

export default InputNumber;
