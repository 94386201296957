import React from 'react';
import PropTypes from 'prop-types';
import './CustomFields.styles.scss';
import {
  Tooltip,
  IconButton,
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Slide from '@material-ui/core/Slide';
import { faPenSquare } from '@fortawesome/free-solid-svg-icons';
import CloseIcon from '@material-ui/icons/Close';
import Fab from '@material-ui/core/Fab';
import SaveIcon from '@material-ui/icons/Save';
import CircularProgress from '@material-ui/core/CircularProgress';
import UserAPI from '../../../../../api/UserAPI';
import appModules from '../../../../../constant/appModules';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const CustomFields = ({
  enable, module = '', tooltip, title, fab = false,
}) => {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [fields, setFields] = React.useState(null);
  const userAPI = new UserAPI();
  const cart = useSelector(state => state.cart);
  const tabs = useSelector(state => state.tabs);
  const customer = useSelector(state => state.customer);
  const { orderSelected: { crmid } } = cart;
  const { posTypeTab } = tabs;
  const { selectedCustomer } = customer;

  const getFields = async () => {
    if (module !== '') {
      setLoading(true);
      let response = null;
      if (module === appModules.Accounts) {
        response = await userAPI.getCustomFields(module, selectedCustomer.crmid);
      } else {
        response = await userAPI.getCustomFields(module, crmid);
      }
      const { success, result } = response;
      if (success) {
        setFields(Object.values(result));
      }
      setLoading(false);
    }
  };

  const handleClickOpen = async () => {
    setOpen(true);
    getFields();
  };

  const handleClose = () => {
    setFields(null);
    setOpen(false);
  };

  const handleInputChange = (event, index) => {
    const { target } = event;
    const value = target.type === 'checkbox' ? (target.checked ? '1' : '0') : target.value;
    const updatedFields = [...fields];
    updatedFields[index].value = value;
    setFields([...updatedFields]);
  };

  const submitForm = async (e) => {
    e.preventDefault();
    setLoading(true);
    const newValues = {};
    fields.map((field) => {
      newValues[field.name] = field.value;
    });
    const crmId = module === appModules.Accounts ? selectedCustomer.crmid : crmid;
    if (Object.keys(newValues).length) {
      await userAPI.setCustomFields(newValues, module, crmId);
      handleClose();
    }
    setLoading(false);
  };

  return (
    <>
      {
      fab ? (
        <div className="d-flex flex-column align-items-center">

          <Fab
            style={{ backgroundColor: '#313C52', color: '#ffffff' }}
            onClick={handleClickOpen}
          >
            <FontAwesomeIcon style={{ fontSize: 22 }} icon={faPenSquare} />
          </Fab>
          <div className="mt-1 font-size-12 w-80 text-center">
            Campos Personalizados
          </div>
        </div>
      ) : (

        <Tooltip
          title={tooltip}
          placement="left"
        >

          <span>
            <IconButton
              id="action_control_sat"
              className="pos_menu_setDir"
              onClick={handleClickOpen}
              disabled={!enable}
            >
              <FontAwesomeIcon
                icon={faPenSquare}
                style={{ fontSize: '0.9em' }}
              />
              <span style={{ fontSize: 9 }}>{title}</span>
            </IconButton>
          </span>
        </Tooltip>
      )
    }

      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar style={{ position: 'relative' }}>
          <Toolbar classes={{ root: 'd-flex justify-content-between' }}>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" style={{ color: '#FFF' }}>
              Campos personalizados
            </Typography>
            <IconButton type="submit" edge="start" color="inherit" onClick={submitForm} aria-label="close">
              {
                loading && (
                  <CircularProgress style={{ color: 'white' }} />
                )
              }
              {
                !loading && (
                  <SaveIcon />
                )
              }
              {/* <CloseIcon /> */}
            </IconButton>

          </Toolbar>
        </AppBar>
        <div className="d-flex justify-content-center align-items-start" style={{ width: '100%', height: '100%', backgroundColor: '#efeff4' }}>
          <form className="mt-5" onSubmit={e => submitForm(e)} style={{ width: '80%' }}>
            <div style={{ maxWidth: 900 }}>
              <div className="row">
                {
                  fields && fields.map((field, index) => (
                    <div key={`index${index}`} className="mt-2 col-6">
                      {
                        field.type !== 'checkbox' && (
                          <label htmlFor="" className="custom-label">{field.label}</label>
                        )
                      }
                      {
                        field.type === 'picklist' && (
                          <select key={`select${index}`} name={field.name} className="custom-input custom-input-sm" onChange={e => handleInputChange(e, index)}>
                            {
                              field.picklistvalues && Object.values(field.picklistvalues).map((option, index) => (
                                <option key={`option${index}`} defaultValue={option} selected={option === field.value}>{option}</option>
                              ))
                            }
                          </select>
                        )
                      }
                      {
                        field.type === 'textarea' && (
                          <textarea className="custom-input custom-input-textarea py-2" type={field.type} defaultValue={field.value || field.defaultvalue} name={field.name} required={field.required} cols="30" rows="3" onChange={e => handleInputChange(e, index)} />
                        )
                      }
                      {
                        field.type === 'checkbox' && (
                          <div className="d-flex align-items-center">
                            <input
                              checked={field.value === '1'}
                              className=""
                              type={field.type}
                              value={field.value}
                              name={field.name}
                              required={field.required}
                              onClick={e => handleInputChange(e, index)}
                            />
                            <p className="ml-2 mt-0">{field.label}</p>
                          </div>
                        )
                      }
                      {
                        field.type !== 'picklist' && field.type !== 'textarea' && field.type !== 'checkbox' && (
                          <input className="custom-input custom-input-sm" type={field.type} defaultValue={field.value || field.defaultvalue} name={field.name} required={field.required} onChange={e => handleInputChange(e, index)} />
                        )
                      }
                    </div>
                  ))
                }
                {
                  fields && fields.length === 0 && (
                    <p>No hay campos personalizados</p>
                  )
                }
              </div>
            </div>
            {/* <Fab color="primary" type="submit" style={{ position: 'absolute', right: 20, bottom: 20 }}>
              {
                loading && (
                  <CircularProgress style={{ color: 'white' }} />
                )
              }
              {
                !loading && (
                  <SaveIcon />
                )
              }
            </Fab> */}
          </form>
        </div>
      </Dialog>
    </>
  );
};

CustomFields.propTypes = {
  tooltip: PropTypes.string,
  module: PropTypes.string.isRequired,
  enable: PropTypes.bool.isRequired,
  title: PropTypes.string,
};
CustomFields.defaultProps = {
  tooltip: '',
  title: 'Campos Personalizados',
};

export default CustomFields;
