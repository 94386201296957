import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Radio,
} from '@material-ui/core';

const AlertChangeAddress = ({
  open,
  handleClose,
  customer,
  setAddress,
}) => {
  const [arr, setArr] = React.useState([]);

  const initAddress = (_customer) => {
    const _arr = _customer.address.map((x) => {
      const _address = { ...x };
      _address.factura = false;
      _address.envio = false;
      return _address;
    });
    setArr(_arr);
  };

  React.useEffect(() => {
    if (open) {
      initAddress(customer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleBooleanParam = (id) => {
    const _arr = arr.map(x => ({
      ...x,
      factura: Boolean(x.crmid === id),
      envio: Boolean(x.crmid === id),
    }));
    setArr(_arr);
  };

  const onSubmit = (_arr) => {
    const facturaId = _arr.find(x => x.factura === true).crmid;
    const envioId = _arr.find(x => x.envio === true).crmid;
    setAddress(facturaId, envioId);
  };
  const enableByFactura = Boolean(arr.some(x => x.factura === true));
  const enableByEnvio = Boolean(arr.some(x => x.envio === true));
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Direcciones del cliente
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Notamos que el cliente nuevo anexado cuenta con direcciones.
            ¿Añadimos una dirección a la orden/cotización?
          </DialogContentText>
          <div className="w-100 h-auto d-flex align-items-center border-bottom mt-1">
            <span
              style={{ width: '20%' }}
              className="font-size-10 text-dark pr-1"
            >
              Dirección de Envío
            </span>
            <span className="font-size-10 text-dark w-50 mr-auto pl-1">
              Dirección
            </span>
          </div>
          {arr.length > 0 && (
            <div className="w-100">
              <List dense>
                {arr.map(el => (
                  <ListItem
                    key={`key_addressCustomer_${el.crmid}`}
                    dense
                    button
                    onClick={() => handleBooleanParam(el.crmid)}
                  >
                    <ListItemIcon>
                      <Radio
                        color="secondary"
                        checked={el.factura && el.envio}
                        onClick={() => handleBooleanParam(el.crmid)}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={`${el.direccion} #${el.num_ext}`}
                      secondary={`${el.city} - ${el.state}, ${el.country}`}
                    />
                  </ListItem>
                ))}
              </List>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="inherit" autoFocus>
            No
          </Button>
          <Button
            onClick={() => onSubmit(arr)}
            color="primary"
            disabled={!(enableByFactura && enableByEnvio)}
          >
            Añadir
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

AlertChangeAddress.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  setAddress: PropTypes.func,
  customer: PropTypes.object,
};

AlertChangeAddress.defaultProps = {
  open: false,
  handleClose: () => {},
  setAddress: () => {},
  customer: {},
};

export default AlertChangeAddress;
