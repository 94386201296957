/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
import {
  AppBar, Dialog, IconButton, Slide, Toolbar, Typography,
} from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
import OrderFields from './OrderFields';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const OrderCustomFields = ({
  open, handleCloseDialog, orderId, module,
}) => {
  const handleClose = () => {
    handleCloseDialog();
  };


  return (
    <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
      <AppBar style={{ position: 'relative' }}>
        <Toolbar classes={{ root: 'd-flex justify-content-between' }}>
          <p />
          <Typography variant="h6" style={{ color: '#FFF' }}>
            Campos personalizados
          </Typography>
          <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <OrderFields open={open} module={module} orderId={orderId} handleCloseDialog={handleCloseDialog} />
    </Dialog>
  );
};

OrderCustomFields.propTypes = {
  open: PropTypes.bool.isRequired,
  handleCloseDialog: PropTypes.func.isRequired,
  orderId: PropTypes.string.isRequired,
  module: PropTypes.string.isRequired,
};

export default OrderCustomFields;
