/* eslint-disable import/no-cycle */
/* eslint-disable array-callback-return */
import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import {
  Tooltip,
  IconButton,
} from '@material-ui/core';

import {
  Book,
  Bookmarks, BookmarksOutlined, Label, LabelOutlined,
} from '@material-ui/icons';
import Popover from '@material-ui/core/Popover';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { getStorage } from '../../../../../helpers/pricebook';
import { updatePriceByPricebook } from '../../../../../redux/actions/cartActions';
import { addPricebook } from '../../../../../redux/actions/priceBookActions';
import { handleEditOrder } from '../../../../../redux/actions/orderActions';

const ChangeCartPrice = ({
  tooltip,
  enable,
}) => {
  const dispatch = useDispatch();
  const user = useSelector(state => state.authUser.user);
  const cart = useSelector(state => state.cart);
  const { enableEditOrder } = useSelector(state => state.order);
  const { cartProductsByArray, orderSelected } = cart;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const select = getStorage();
  const isSelected = Boolean(Object.keys(select).length > 3);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };


  const setPricebook = async (pricebook) => {
    await dispatch(addPricebook(pricebook));
  };

  const updatePricebook = async (row, pricebook) => {
    await dispatch(updatePriceByPricebook(row, pricebook));
  };

  const changeProductPrice = async (priceBook) => {
    const crmid = priceBook ? priceBook.crmid : null;
    if (cartProductsByArray.length > 0) {
      await setPricebook(priceBook);
      if (Object.keys(orderSelected).length && !enableEditOrder) {
        await dispatch(handleEditOrder());
      }
      cartProductsByArray.map((product) => {
        updatePricebook(product.row, crmid);
      });
    }
    handleClose();
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <Tooltip
      title={tooltip}
      placement="left"
    >
      <span>
        <IconButton
          id="action_control_sat"
          className="pos_menu_setDir"
          disabled={!enable}
          onClick={handleClick}
        >
          <Book />
          <span className="font-size-10">Cambiar precio</span>
        </IconButton>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <List component="nav" aria-label="contacts">
            <ListItem
              dense
              button
              onClick={() => {
                changeProductPrice(null);
              }}
            >
              <ListItemIcon>
                {!isSelected ? (<Bookmarks />) : (<BookmarksOutlined />)}
              </ListItemIcon>
              <ListItemText
                primary="Predeterminada"
              />
            </ListItem>
            {user.PriceBooks.map(item => (
              <ListItem button dense onClick={() => changeProductPrice(item)}>
                <ListItemIcon>
                  {select && select.crmid === item.crmid ? <Label /> : <LabelOutlined /> }
                </ListItemIcon>
                <ListItemText primary={item.entity_label} />
              </ListItem>
            ))}
          </List>
        </Popover>
      </span>
    </Tooltip>
  ); };

ChangeCartPrice.propTypes = {
  tooltip: PropTypes.string,
  enable: PropTypes.bool.isRequired,
};
ChangeCartPrice.defaultProps = {
  tooltip: '',
};

export default ChangeCartPrice;
