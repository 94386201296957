/* eslint-disable no-restricted-syntax */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-unused-state */
/* eslint-disable import/no-cycle */
/* eslint-disable no-case-declarations */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community';

import './ProductTable.scss';

import productProps from '../../../propTypes/productProps';
import { haveStock } from '../../../helpers/product';

class ProductTable extends Component {
  static propTypes = {
    product: productProps.isRequired,
    isLoading: PropTypes.bool.isRequired,
    authUser: PropTypes.object.isRequired,
    addProducts: PropTypes.func.isRequired,
    handleOnClick: PropTypes.func.isRequired,
    setParentProduct: PropTypes.func.isRequired,
    dispatchFilterAllProducts: PropTypes.func.isRequired,
    dispatchToggleRefresh: PropTypes.func.isRequired,
    loads: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    const { product } = this.props;
    this.defaultColDef = {
      filter: true,
      sortable: true,
      resizable: true,
      editable: false,
    };

    this.state = {
      rowData: [...product.products],
      activeFilters: null,
    };
  }

  componentWillReceiveProps = (next) => {
    const { product, authUser } = next;
    const { filters } = product;
    const { activeFilters } = this.state;
    // Set filters
    if (filters.length > 0) {
      this.callFiltering(filters);
    }
    if (authUser.fastMode) {
      this.setState({ rowData: [...product.productOptions] });
    } else {
      this.setState({ rowData: [...product.productsByArray] });
    }
    if (activeFilters) {
      this.gridApi.setFilterModel(activeFilters);
      const filter = {};
      for (const [key, value] of Object.entries(activeFilters)) {
        const clearKey = key.replace('_1', '');
        filter[clearKey] = value.filter;
      }
      const filterProducts = { ...filter };
      localStorage.setItem('productFilters', JSON.stringify(filterProducts && filterProducts !== '{}' ? filterProducts : ''));
    } else {
      localStorage.setItem('productFilters', '');
    }
  };

  openModal = (options, crmid) => {
    const { handleOnClick } = this.props;
    handleOnClick('options', options, crmid);
  };

  onCellKeyDown = (e) => {
    const { event, rowIndex } = e;
    const { keyCode } = event;
    const topSearchBar = document.querySelector('#search_bar_query');
    switch (keyCode) {
      case 27:
        // @ts-ignore
        topSearchBar.focus();
        break;
      case 38:
        if (rowIndex === 0) {
          // @ts-ignore
          topSearchBar.focus();
        }
        break;
      default:
        break;
    }
  };

  onCellKeyPress = (row) => {
    const { keyCode } = row.event;
    const { isLoading } = this.props;
    if (!isLoading && keyCode === 13) {
      setTimeout(() => row.event.target.click(), 1000);
    }
  };

  callFiltering = async (filters) => {
    await this.gridApi.setFilterModel(null);
    const search = await filters.find(x => x.type === 'search');
    if (search !== undefined) {
      const word = search.value;
      this.gridApi.setQuickFilter(word);
      filters.forEach((element) => {
        if (element.type !== 'search') {
          const filterComp = this.gridApi.getFilterInstance(element.column);
          filterComp.setModel({ type: 'contains', filter: word });
        }
      });
    } else {
      filters.forEach((element) => {
        const filterComp = this.gridApi.getFilterInstance(element.column);
        filterComp.setModel({ type: 'contains', filter: '' });
      });
    }
    await this.gridApi.onFilterChanged();
  };

  onGridReady = (params) => {
    this.gridApi = params.api;
    const {
      columnApi: {
        columnController: { allDisplayedColumns },
      },
    } = params;
    if (allDisplayedColumns.length <= 5) {
      this.gridApi.sizeColumnsToFit();
    }
  };

  onFocusAgGrid = () => {
    const {
      columnController: { allDisplayedColumns },
    } = this.gridApi;
    const firstColumnId = allDisplayedColumns[0].colId;
    this.gridApi.setFocusedCell(0, firstColumnId);
  };

  cellClicked = (row) => {
    const {
      authUser, addProducts, setParentProduct, isLoading,
    } = this.props;
    const [product] = row.api.getSelectedRows();
    const { fastMode } = authUser;

    if (!isLoading) {
      const { Products, crmid } = product;
      const productList = fastMode ? Object.values(product.Products) : Products;
      if (productList.length > 1) {
        this.openModal(productList, crmid);
        setParentProduct(product.nombre);
      } else if (haveStock(productList[0], authUser)) {
        const element = {
          dataset: {
            crmid: productList[0].crmid,
            parentid: fastMode ? product.crmid : productList[0].parentId,
          },
        };
        addProducts(element);
      } else {
        return null;
      }
    }
    return null;
  };

  // Making backend request when filter header inside table change
  filterSearch = async () => {
    const { activeFilters } = this.state;
    const {
      dispatchFilterAllProducts,
      loads,
      dispatchToggleRefresh,
    } = this.props;
    const savedModel = this.gridApi.getFilterModel();
    // debugger;
    if (JSON.stringify(activeFilters) !== JSON.stringify(savedModel)) {
      this.setState({ activeFilters: savedModel });
      const filter = [];
      // eslint-disable-next-line no-restricted-syntax
      for (const [key, value] of Object.entries(savedModel)) {
        const clearKey = key.replace('_1', '');
        filter[clearKey] = value.filter;
      }
      const filterProducts = { ...filter };
      dispatchFilterAllProducts(JSON.stringify(filterProducts));
    } else {
      await this.setState({ activeFilters: savedModel });
    }
    if (loads.refreshIsLoading) {
      localStorage.setItem('productFilters', '');
      this.gridApi.setFilterModel(null);
      document.querySelector('#search_bar_query').value = '';
      document
        .querySelector('#search_bar_query')
        .dispatchEvent(new KeyboardEvent('keyup', { key: 'a' }));
      setTimeout(() => {
        dispatchToggleRefresh();
      }, 100);
    }
  };

  render() {
    const { rowData } = this.state;
    const { product } = this.props;
    const { columnsDefs } = product;
    let simpleColumns = [];
    // eslint-disable-next-line array-callback-return
    columnsDefs.map((column) => {
      simpleColumns = [...simpleColumns, ...column.children];
    });
    return (
      <div
        id="agProductTable"
        tabIndex={-1}
        className="ag-theme-balham table-caja"
        style={{ height: '100%', width: '100%' }}
        onFocus={() => {
          const active = document.activeElement;
          // @ts-ignore
          if (!active.hasAttribute('col-id') && active.tagName !== 'INPUT') {
            this.onFocusAgGrid();
          }
        }}
      >
        <AgGridReact
          floatingFilter
          rowData={rowData}
          rowSelection="single"
          columnDefs={simpleColumns}
          onGridReady={this.onGridReady}
          onCellClicked={this.cellClicked}
          defaultColDef={this.defaultColDef}
          onCellKeyDown={this.onCellKeyDown}
          onCellKeyPress={this.onCellKeyPress}
          onFilterChanged={this.filterSearch}
        />
      </div>
    );
  }
}

export default ProductTable;
