/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import useFetchInvoiceOrder from '../../../hooks/order/useFetchInvoiceOrder';
import InvoiceList from './components/invoice-list/InvoiceList';

const Invoice = ({ orderId }) => {
  const { invoiceList, isLoadingInvoice, errorInvoice } = useFetchInvoiceOrder(orderId);

  return errorInvoice ? (
    <div>Occurrio un error al cargar las facturas</div>
  ) : (
    <InvoiceList
      invoices={invoiceList}
      isLoading={isLoadingInvoice}
    />
  );
};

Invoice.propTypes = {
  orderId: PropTypes.string.isRequired,
};

export default Invoice;
