/* eslint-disable import/no-cycle */
import React, { useEffect } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import { LinearProgress } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import Layout from '../components/Layout';
import MainWrapper from '../components/MainWrapper';
import PrivateRoute from '../helpers/PrivateRoute';

import LogIn from '../views/Account/LogIn';
import LogOut from '../views/Account/LogOut';
import Profile from '../views/Account/Profile';

import PosView from '../views/Pos';
import Clients from '../views/Clients';

import Expenses from '../views/Expenses';
import DetailExpense from '../views/Expenses/components/DetailExpense';

import Dashboard from '../views/Dashboard';
import Citas from '../views/Citas';

import ClientPage from '../views/ClientPage';
import ClientCall from '../views/ClientCall';
import Configuration from '../views/Clients/Configuration';

import AddCustomer from '../components/Cart/mobile/AddCustomer';
import EditCustomer from '../components/Cart/mobile/EditCustomer';
import AutoLogin from '../components/LogIn/AutoLogin';

import Activities from '../views/Activities';
import ActivityDetailDialog from '../views/Activities/components/ActivityDetail';
import AddActivity from '../views/Activities/components/AddActivity';
import Warehouse from '../views/Warehouse';
import Deliver from '../components/rkr2-components/deliver/OrderDeliver/Deliver';
import Receiver from '../components/rkr2-components/receiver/OrderReceive/Receiver';
import ExternalDeliver from '../components/rkr2-components/external-delivery/ExternalDeliver';
import TransferReceiver from '../components/rkr2-components/transfers/TransferReceiver';
import ScheduleExternalDeliver from '../components/rkr2-components/schedule-external-deliver';
import TransferProcessDetail from '../components/rkr2-components/transfers/TransferReceiverProducts/components/TransferProcessDetail';
import Tickets from '../components/Tickets';
import { setTicketData } from '../redux/actions/ticketAction';

const AccountWrapped = () => (
  <>
    <Layout />
    <MainWrapper>
      <Profile />
    </MainWrapper>
  </>
);

const AccountRoutes = () => (
  <Switch>
    <Route path="/account/login" component={LogIn} />
    <Route path="/account/logout" component={LogOut} />
    <PrivateRoute path="/account/profile" component={AccountWrapped} />
    <PrivateRoute path="/account/profile/caja" component={AccountWrapped} />
    <PrivateRoute path="/account/profile/ventas" component={AccountWrapped} />
    <PrivateRoute path="/account/profile/general" component={AccountWrapped} />
  </Switch>
);
const ClientRoutes = () => (
  <Switch>
    <PrivateRoute exact path="/clients" component={Clients} />
    <PrivateRoute
      exact
      path="/clients/configuration"
      component={Configuration}
    />
    <PrivateRoute exact path="/clients/:crmid" component={ClientPage} />
    <PrivateRoute exact path="/clients/client/call" component={ClientCall} />
  </Switch>
);

const ExpensesRoutes = () => (
  <Switch>
    <PrivateRoute exact path="/expenses" component={Expenses} />
    <PrivateRoute exact path="/expenses/:crmid" component={DetailExpense} />
  </Switch>
);

const ActivityRoutes = () => (
  <Switch>
    <PrivateRoute exact path="/actividades" component={Activities} />
    <PrivateRoute exact path="/actividades/:id" component={ActivityDetailDialog} />
    <PrivateRoute exact path="/actividades/actividad/add" component={AddActivity} />
  </Switch>
);

const WarehouseRoutes = () => (
  <Switch>
    <PrivateRoute exact path="/almacenes" component={Warehouse} />
    <PrivateRoute exact path="/almacenes/entregar/:orderId" component={Deliver} />
    <PrivateRoute exact path="/almacenes/recibir/:orderId" component={Receiver} />
    <PrivateRoute exact path="/almacenes/entrega/externa/:orderId" component={ExternalDeliver} />
    <PrivateRoute exact path="/almacenes/programar/entrega/externa/:orderId" component={ScheduleExternalDeliver} />
    <PrivateRoute exact path="/almacenes/recibir/transferencia/:orderId" component={TransferReceiver} />
    <PrivateRoute exact path="/almacenes/procesar/transferencia/:orderId" component={TransferProcessDetail} />
    {/* <PrivateRoute exact path="/almacenes/entregar/:orderId" component={DeliverWrapper} /> */}
  </Switch>
);

const MainRoutes = () => {
  const {
    listIsLoading, orderIsLoading, productIsLoading, cotizacionIsLoading,
  } = useSelector(state => state.loads);
  return (
    <>
      <Layout />
      {
        (listIsLoading || orderIsLoading || productIsLoading || cotizacionIsLoading) && (
          <LinearProgress />
        )
      }
      <MainWrapper>
        <Switch>
          {/* <PrivateRoute exact path="/" component={PosView} /> */}
          <Redirect exact from="/" to="/pos" />
          <PrivateRoute exact path="/tablero" component={Dashboard} />
          <PrivateRoute exact path="/citas" component={Citas} />
          <PrivateRoute path="/addCustomer" component={AddCustomer} />
          <PrivateRoute path="/editCustomer" component={EditCustomer} />
          <PrivateRoute path="/pos" component={PosView} />
          <PrivateRoute exact path="/pos/caja" component={PosView} />
          <PrivateRoute exact path="/pos/ventas" component={PosView} />
          <PrivateRoute exact path="/pos/cotizaciones" component={PosView} />
          <PrivateRoute exact path="/pos/carro" component={PosView} />
          <PrivateRoute path="/user/profile" component={Profile} />
          <PrivateRoute path="/clients" component={ClientRoutes} />
          <PrivateRoute path="/expenses" component={ExpensesRoutes} />
          <PrivateRoute path="/actividades" component={ActivityRoutes} />
          <PrivateRoute path="/almacenes" component={WarehouseRoutes} />
          <Route path="/auto-login/:token" component={AutoLogin} />
        </Switch>
      </MainWrapper>
    </>
  ); };

const Router = () => (
  <Switch>
    <Route path="/account" component={AccountRoutes} />
    <Route path="/" component={MainRoutes} />
  </Switch>
);

export default Router;
