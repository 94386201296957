/* eslint-disable no-case-declarations */
import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import IconButtonInput from '../../../../Input/IconButton';
import CustomerAPI from '../../../../../api/CustomerAPI';

const ChangeDefaultCart = ({
  handleClose,
}) => {
  const cart = useSelector(state => state.cart);
  const tabs = useSelector(state => state.tabs);
  const customer = useSelector(state => state.customer);
  const { orderSelected } = cart;
  const { posTypeTab } = tabs;
  const { selectedCustomer } = customer;

  const rest = { width: '60px', height: '60px', iconSize: '18px' };


  const changeDefaultCart = async () => {
    if (selectedCustomer.crmid === '54572') {
      toast.warn('A Publico en General no se le asigna carrito por defecto');
      return;
    }
    const customerApi = new CustomerAPI();
    const editCustomer = {
      ...selectedCustomer,
      cart_default: orderSelected.crmid,
    };
    const response = await customerApi.edit(editCustomer);
    console.log('response', response);
    if (response.success) {
      toast.success('Carrito por defecto actualizado');
    } else {
      toast.error('No se actualizo el carrito por defecto');
    }
  };
  return (
    <IconButtonInput
      icon="shopping-cart"
      enable={selectedCustomer.cart_default !== orderSelected.crmid && posTypeTab === 'Cotizaciones'}
      onClick={() => {
        changeDefaultCart();
        handleClose();
      }}
      label="Carrito default"
      tooltip="Asignar carrito por defecto"
      {...rest}
    />
  ); };

ChangeDefaultCart.propTypes = {
  handleClose: PropTypes.func,
};
ChangeDefaultCart.defaultProps = {
  handleClose: () => {},
};

export default ChangeDefaultCart;
