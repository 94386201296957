import React from 'react';
import PropTypes from 'prop-types';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import MailOutline from '@material-ui/icons/MailOutline';

const ShareItemEmail = ({
  // eslint-disable-next-line react/prop-types
  template, shareByEmail,
}) => (
  <ListItem key={template.subject} divider>
    <ListItemText primary={template.templatename} secondary={template.description} />
    <ListItemText
      primary={
              (
                <div className="d-flex justify-content-end">
                  <IconButton edge="end" aria-label="comments" onClick={() => shareByEmail()}>
                    <MailOutline />
                  </IconButton>
                </div>
              )
            }
      classes={{ root: 'pr-0' }}
    />
  </ListItem>
);

ShareItemEmail.prototype = {
  template: PropTypes.object.isRequired,
  shareByEmail: PropTypes.func.isRequired,
};
export default ShareItemEmail;
