/* eslint-disable no-param-reassign */
/* eslint-disable no-multi-assign */
/* eslint-disable no-case-declarations */
import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCreditCard } from '@fortawesome/fontawesome-free-regular';
import { Input } from '@material-ui/core';
import { toast } from 'react-toastify';
import DollarMask from '../../../../Mask/DollarMask';
import SpeiIcon from '../../../../Icons/SpeiIcon';

const AmountPayable = ({
  title,
  monto,
  charge,
  inputEl,
  paidOut,
  onSubmit,
  payIndex,
  isLoading,
  addPayment,
  totalToPay,
  setPayIndex,
  onBlurCharge,
  changePayable,
  newPaymentTypes,
}) => {
  const returnIcon = () => {
    const actualPayment = newPaymentTypes[payIndex];
    switch (actualPayment.id) {
      case ('Transferencia (SPEI)'):
        return (<SpeiIcon />);
      case ('Tarjeta de Credito'):
        return (<FontAwesomeIcon icon={faCreditCard} />);
      case ('Tarjeta de Debito'):
        return (<FontAwesomeIcon icon="credit-card" />);
      case ('Cheque'):
        return (<FontAwesomeIcon icon="money-check" />);
      default:
        return (<FontAwesomeIcon icon="money-bill-wave" />);
    }
  };

  return (
    <>
      <div className="w-100 checkoutPayableTitle">
        <p>{monto}</p>
      </div>
      <div className="w-100 amount_payable">
        <Input
          autoFocus={false}
          id="payable_dollar"
          inputRef={inputEl}
          onBlur={onBlurCharge}
          onChange={changePayable}
          // onChange={changeInput}
          inputComponent={DollarMask}
          className="w-100 amount_payable_field"
          onKeyUp={(event) => {
            const { keyCode, target } = event;
            let newI = 0;
            switch (keyCode) {
              case 9:
                // @ts-ignore
                event.preventDefault();
                // @ts-ignore
                target.select();
                break;
              case 13:
                if (!isLoading) {
                  onSubmit();
                } else {
                  toast.warn('Espere un momento');
                }
                break;
              case 38:
                // @ts-ignore
                target.focus();
                if (payIndex > 0) {
                  newI = payIndex -= 1;
                  setPayIndex(newI);
                  event.preventDefault();
                }
                break;
              case 40:
                // @ts-ignore
                target.focus();
                if (payIndex < (newPaymentTypes.length - 1)) {
                  newI = payIndex += 1;
                  setPayIndex(newI);
                  event.preventDefault();
                }
                break;
              case 107:
                if (charge > 0) {
                  addPayment();
                } else {
                  addPayment(totalToPay);
                }
                break;
              default:
                break;
            }
            // @ts-ignore
            event.preventDefault();
          }}
          onFocus={(e) => {
            // @ts-ignore
            e.target.select();
            e.preventDefault();
          }}
          error={(charge + paidOut) <= 0}
          autoComplete="off"
        />
      </div>
    </>
  );
};

AmountPayable.propTypes = {
  title: PropTypes.string,
  monto: PropTypes.string,
  charge: PropTypes.number,
  isLoading: PropTypes.bool,
  payIndex: PropTypes.number,
  addPayment: PropTypes.func,
  totalToPay: PropTypes.number,
  onSubmit: PropTypes.func.isRequired,
  paidOut: PropTypes.number.isRequired,
  setPayIndex: PropTypes.func.isRequired,
  onBlurCharge: PropTypes.func.isRequired,
  changePayable: PropTypes.func.isRequired,
  inputEl: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.object }),
  ]).isRequired,
  newPaymentTypes: PropTypes.array.isRequired,
};

AmountPayable.defaultProps = {
  charge: 0,
  payIndex: 0,
  totalToPay: 0,
  isLoading: false,
  monto: 'Monto',
  addPayment: () => {},
  title: 'Método de Pago',
};

export default AmountPayable;
