/* eslint-disable no-unused-expressions */
import { useState } from 'react';
import { toast } from 'react-toastify';
import OrderAPI from '../../api/OrderAPI';

export default function useOrderFile(orderId) {
  const orderApi = new OrderAPI();
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isUploaded, setIsUploaded] = useState(false);

  const getOrderFile = async (page = 1, search = '') => {
    setLoading(true);
    const response = await orderApi.getFiles(orderId, page, search);
    const { success, result, message } = response;

    if (success) {
      setFiles(result);
      message.success ? toast.success(message.success) : '';
    } else {
      message.error ? toast.error(message.error) : '';
      message.warning ? toast.warn(message.warning) : '';
    }

    setLoading(false);
  };

  const uploadFile = async (newFiles) => {
    setLoading(true);
    const response = await orderApi.uploadFile(orderId, newFiles);
    const { success, message } = response;

    if (success) {
      toast.success(message.success);

      setIsUploaded(true);
      setTimeout(() => {
        setIsUploaded(false);
      }, 2000);
    } else {
      message.error ? toast.error(message.error) : '';
      message.warning ? toast.warn(message.warning) : '';
    }

    setLoading(false);
  };


  return {
    files, loading, isUploaded, getOrderFile, uploadFile,
  };
}
