import React from 'react';
import PropTypes from 'prop-types';
import {
  Tooltip,
  IconButton,
} from '@material-ui/core';
import { FileCopy } from '@material-ui/icons';

const CloneAction = ({
  title,
  enable,
  tooltip,
  saveSell,
}) => (
  <Tooltip
    title={title}
    placement="left"
  >
    <span>
      <IconButton
        id="action_control_save"
        disabled={!enable}
        onClick={saveSell}
        className="pos_menu_setDir"
      >
        <FileCopy />
        <span className="font-size-10">{title}</span>
      </IconButton>
    </span>
  </Tooltip>
);

CloneAction.propTypes = {
  tooltip: PropTypes.string,
  title: PropTypes.string,
  enable: PropTypes.bool.isRequired,
  saveSell: PropTypes.func.isRequired,
};
CloneAction.defaultProps = {
  tooltip: 'Clonar Venta',
  title: 'Clonar Venta',
};

export default CloneAction;
