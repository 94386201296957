
/* eslint-disable no-unused-vars */
/* eslint-disable no-else-return */
/* eslint-disable no-useless-return */
import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { toast } from 'react-toastify';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import Collapse from '@material-ui/core/Collapse';
import CircularProgress from '@material-ui/core/CircularProgress';
import moment from 'moment';
import './timbrar.scss';
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import SatLogo from '../../../Icons/SatLogo';
import { formatMoney } from '../../../../helpers/tools';
import TimbrarApi from '../../../../api/TimbrarApi';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const VerificarCobro = ({
  // eslint-disable-next-line react/prop-types
  open, handleClose, typo, fde, closeAll,
}) => {
  const [verifica, setVerifica] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [noPuedeTimbrar, setNoPuedeTimbrar] = React.useState(true);
  const timbrarApi = new TimbrarApi();
  const getVerificacion = async (fdeId) => {
    setLoading(true);
    const result = await timbrarApi.verificarFdeTimbre(fdeId);
    const arrayResult = Object.values(result);
    setLoading(false);
    if (arrayResult.length === 0) {
      handleClose();
      return;
    } else {
      const { pagos } = arrayResult[1];
      await setVerifica(Object.values(pagos));
      await setNoPuedeTimbrar(false);
    }
  };

  const timbraCobro = async (fdeId) => {
    await setNoPuedeTimbrar(true);
    setLoading(true);
    const result = await timbrarApi.fdeTimbraCobro(fdeId);
    setLoading(false);
    // eslint-disable-next-line no-unused-expressions
    result.success === 'ERROR' ? toast.error(result.datos) : toast.success(result.respuesta);
    await setNoPuedeTimbrar(false);
    closeAll();
  };

  React.useEffect(() => {
    if (open) {
      getVerificacion(fde);
    }
  }, [open]);
  return (
    <div>
      <Dialog fullScreen open={open} onClose={() => handleClose()} TransitionComponent={Transition}>
        <AppBar style={{ position: 'relative' }}>
          <Toolbar classes={{ root: 'tollbar-header' }}>
            <Button autoFocus color="inherit" onClick={() => {}}> </Button>
            <Typography variant="h6" classes={{ root: 'text-white' }}>
              Timbrar cobro
            </Typography>
            <IconButton edge="start" color="inherit" onClick={() => handleClose()} aria-label="close">
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <div className="center-table">
          <Collapse in={verifica === null}>
            <CircularProgress />
          </Collapse>
          <Collapse in={verifica !== null && verifica !== []}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Factura</TableCell>
                  <TableCell align="right">Timbre</TableCell>
                  <TableCell align="right">Pago</TableCell>
                  <TableCell align="right">Monto</TableCell>
                  <TableCell align="right">Parcialidad</TableCell>
                  <TableCell align="right">Pago en Docto</TableCell>
                  <TableCell align="right">Saldo</TableCell>
                </TableRow>
              </TableHead>
              {
                verifica.length > 0
                  ? (
                    <TableBody>
                      {
                    verifica.map(p => (
                      <TableRow key={Math.random()}>
                        <TableCell component="th" scope="row">{p.Factura}</TableCell>
                        <TableCell align="right">{moment(p.Timbre).format('DD MMM h:mm a')}</TableCell>
                        <TableCell align="right">{moment(p.Pago).format('DD MMM h:mm a')}</TableCell>
                        <TableCell align="right">{`${formatMoney(p.Monto)} ${p.$}`}</TableCell>
                        <TableCell align="right">{p.Parcialidad}</TableCell>
                        <TableCell align="right">{`${formatMoney(p.Monto_en_Factura)}`}</TableCell>
                        <TableCell align="right">{p.Saldo}</TableCell>
                      </TableRow>
                    ))
                    }
                    </TableBody>
                  )
                  : (
                    <TableBody>
                      <TableRow key="id">
                        <TableCell component="th" scope="row">Sin cobros</TableCell>
                        <TableCell align="right"> </TableCell>
                        <TableCell align="right"> </TableCell>
                        <TableCell align="right"> </TableCell>
                        <TableCell align="right"> </TableCell>
                        <TableCell align="right"> </TableCell>
                        <TableCell align="right"> </TableCell>
                      </TableRow>
                    </TableBody>
                  )
              }

            </Table>
            <div className="d-flex justify-content-center align-items-center mt-3">
              <div>
                {
                  !loading ? (
                    <Tooltip title="Timbrar" aria-label="add" placement="left">
                      <Fab
                        color="primary"
                        aria-label="timbrar"
                        disabled={noPuedeTimbrar}
                        style={{ position: 'absolute', right: '30px', bottom: '30px' }}
                        onClick={() => timbraCobro(fde)}
                      >
                        <SatLogo fill={noPuedeTimbrar ? '#c7c7c7' : '#fff'} style={{ position: 'absolute', height: '30px' }} />
                      </Fab>
                    </Tooltip>
                  ) : <CircularProgress />
                }
              </div>
            </div>
          </Collapse>
        </div>
      </Dialog>
    </div>
  );
};

export default VerificarCobro;
