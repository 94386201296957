/* eslint-disable react/prop-types */
/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import List from '@material-ui/core/List';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { toast } from 'react-toastify';
import ShareItem from './components/ShareItem';
import ShareItemEmail from './components/ShareItemEmail';
import { sendEmail, getOrderPdf } from '../../redux/actions/posActions';
import SaleEmailShare from '../SaleEmailShare/SaleEmailShare';
import AccountAPI from '../../api/AccountAPI';
import appModules from '../../constant/appModules';
import { loading, loaded } from '../../redux/actions/loadActions';

// eslint-disable-next-line react/prop-types
const Share = ({ data, type, appModule }) => {
  console.log('appModule', appModule);
  console.log('data', data);
  console.log('type', type);

  const accountApi = new AccountAPI();
  const dispatch = useDispatch();
  const loads = useSelector(state => state.loads);
  const template = useSelector(state => state.authUser.user.templates);
  const templateEmail = useSelector(state => state.authUser.user.templates_email);
  const domain = useSelector(state => state.authUser.domain);
  const organizationname = useSelector(state => state.authUser.user.organizationname);
  const customer = useSelector(state => state.customer);
  const { selectedCustomer } = customer;
  const [subjectU, setSubjectU] = React.useState('');
  const [moduleU, setModuleU] = React.useState(appModule);
  const [templateU, setTemplateU] = React.useState(17);
  const [openModal, setOpenModal] = React.useState(false);
  const [emailBody, setEmailBody] = React.useState();

  const sendEmailIsLoading = Boolean(loads.sendEmailIsLoading);
  let hasBill = false;
  let templateCount = false;
  switch (appModule) {
    case appModules.TreebesFdE:
      hasBill = Boolean(data && data.pagocfdiid);
      if (template.TreebesFdE && template.TreebesFdE.length > 0) {
        templateCount = true;
      }
      break;
    case appModules.SalesOrder:
      hasBill = Boolean(data && (data.estadosat || data.treebessatcfdiid));
      if (template.SalesOrder && template.SalesOrder.length > 0) {
        templateCount = true;
      }
      break;
    case appModules.Quotes:
      hasBill = Boolean(data && data.estadosat && data.treebessatcfdiid);
      if (template.Quotes && template.Quotes.length > 0) {
        templateCount = true;
      }
      break;
    case appModules.TreebesSolicitudesEnvio:
      if (template.TreebesSolicitudesEnvio && template.TreebesSolicitudesEnvio.length > 0) {
        templateCount = true;
      }
      break;
    case appModules.PurchaseOrder:
      if (template.PurchaseOrder && template.PurchaseOrder.length > 0) {
        templateCount = true;
      }
      break;
    case appModules.TreebesMdAG:
      if (template.TreebesMdAG && template.TreebesMdAG.length > 0) {
        templateCount = true;
      }
      break;

    default:
      break;
  }

  const handleClickDownloadAction = (item) => {
    dispatch(getOrderPdf({
      crmid: (item.module === 'TreebesSatCFDI' ? (data.treebessatcfdiid || data.pagocfdiid) : data.crmid || data.id),
      type: 'pdf',
      orderNo: data.entity_label || data?.order?.entity_label,
      templateid: item.templateid,
      moduleT: item.module,
      templatename: item.templatename,
    }, true));
  };


  const handleClickEmailAction = async (item, withFile = true) => {
    dispatch(loading('emailTemplate'));
    const tempId = item.plantillaemail && item.plantillaemail !== '0' ? item.plantillaemail : item.templateid;
    let idData = '';
    switch (appModule) {
      case appModules.TreebesFdE:
        idData = data.pagocfdiid ? data.pagocfdiid : data.crmid || data.id;
        break;
      case appModules.TreebesSolicitudesEnvio:
      case appModules.PurchaseOrder:
        idData = data.crmid || data.id;
        break;
      case appModules.TreebesMdAG:
        idData = data.crmid || data.id || data.referencia;
        break;
      default:
        idData = item.module === 'TreebesSatCFDI' && data.treebessatcfdiid ? data.treebessatcfdiid : data.crmid || data.id;
        break;
    }
    const module = item.module === 'Todos' ? appModule : item.module;
    const body = await accountApi.getEmailBody(module, tempId, idData);
    const { success } = body;
    dispatch(loaded('emailTemplate'));
    if (!success) {
      toast.error('La plantilla de correo que solicita no fue encontrada');
      return;
    }
    if (body.result) {
      setEmailBody(body.result);
      setSubjectU(item.subject);
      if (withFile) {
        // setTemplateU(tempId);
        setTemplateU(item.templateid);
      } else {
        // setTemplateU(0);
        setTemplateU(tempId);
      }
      setModuleU(module);
      setOpenModal(true);
    }
  };

  return (
    <>
      {
      type === 'download'
        ? (
          <div className="share-container">
            {templateCount && (
            <AppBar style={{ position: 'relative', backgroundColor: '#F6F6F7' }}>
              <Toolbar variant="dense" classes={{ root: 'tollbar-header' }}>
                <Typography variant="h6" style={{ color: '#64707A' }}>
                  PDF
                </Typography>
              </Toolbar>
            </AppBar>
            )}
            <List>
              {hasBill && template.TreebesSatCFDI.map(item => (
                <ShareItem
                  key={item.templateid}
                  template={item}
                  download={() => handleClickDownloadAction(item)}
                  url={`https://${domain}/comercia_ws/${item.module}/pdf/${item.templateid}/${item.module === 'TreebesSatCFDI' ? data.treebessatcfdiid : data.crmid || data.id}`}
                  name={`${item.subject}_${data.entity_label}`}
                  shareByEmail={() => handleClickEmailAction(item)}
                />
              ))}

              {appModule === appModules.TreebesFdE && template.TreebesFdE && template.TreebesFdE.map(item => (
                <ShareItem
                  key={item.templateid}
                  template={item}
                  download={() => handleClickDownloadAction(item)}
                  url={`https://${domain}/comercia_ws/${item.module}/pdf/${item.templateid}/${item.module === 'TreebesSatCFDI' ? data.treebessatcfdiid : data.crmid || data.id}`}
                  name={`${item.subject}_${data.entity_label}`}
                  shareByEmail={() => handleClickEmailAction(item)}
                />
              ))}

              {appModule === appModules.SalesOrder && template.SalesOrder && template.SalesOrder.map((item) => {
                return (
                  <ShareItem
                    key={item.templateid}
                    template={item}
                    download={() => handleClickDownloadAction(item)}
                    url={`https://${domain}/comercia_ws/${item.module}/pdf/${item.templateid}/${item.module === 'TreebesSatCFDI' ? data.treebessatcfdiid : data.crmid || data.id}`}
                    name={`${item.subject}_${data.entity_label}`}
                    shareByEmail={() => handleClickEmailAction(item)}
                  />
                ); })}

              {appModule === appModules.Quotes && template.Quotes && template.Quotes.map(item => (
                <ShareItem
                  key={item.templateid}
                  template={item}
                  download={() => handleClickDownloadAction(item)}
                  url={`https://${domain}/comercia_ws/${item.module}/pdf/${item.templateid}/${item.module === 'TreebesSatCFDI' ? data.treebessatcfdiid : data.crmid || data.id}`}
                  name={`${item.subject}_${data.entity_label}`}
                  shareByEmail={() => handleClickEmailAction(item)}
                />
              ))}

              {appModule === appModules.TreebesGTO && template.TreebesGTO && template.TreebesGTO.map(item => (
                <ShareItem
                  key={item.templateid}
                  template={item}
                  download={() => handleClickDownloadAction(item)}
                  url={`https://${domain}/comercia_ws/${item.module}/pdf/${item.templateid}/${item.module === 'TreebesSatCFDI' ? data.treebessatcfdiid : data.crmid || data.id}`}
                  name={`${item.subject}_${data.entity_label}`}
                  shareByEmail={() => handleClickEmailAction(item)}
                />
              ))}

              {appModule === appModules.Accounts && template.Accounts && template.Accounts.map(item => (
                <ShareItem
                  key={item.templateid}
                  template={item}
                  download={() => handleClickDownloadAction(item)}
                  url={`https://${domain}/comercia_ws/${item.module}/pdf/${item.templateid}/${item.module === 'TreebesSatCFDI' ? data.treebessatcfdiid : data.crmid || data.id}`}
                  name={`${item.subject}_${data.entity_label}`}
                  shareByEmail={() => handleClickEmailAction(item)}
                />
              ))}

              {appModule === appModules.TreebesSolicitudesEnvio && template.TreebesSolicitudesEnvio && template.TreebesSolicitudesEnvio.map(item => (
                <ShareItem
                  key={item.templateid}
                  template={item}
                  download={() => handleClickDownloadAction(item)}
                  url={`https://${domain}/comercia_ws/${item.module}/pdf/${item.templateid}/${item.module === 'TreebesSatCFDI' ? data.treebessatcfdiid : data.crmid || data.id}`}
                  name={`${item.subject}_${data.entity_label}`}
                  shareByEmail={() => handleClickEmailAction(item)}
                />
              ))}

              {appModule === appModules.PurchaseOrder && template.PurchaseOrder && template.PurchaseOrder.map(item => (
                <ShareItem
                  key={item.templateid}
                  template={item}
                  download={() => handleClickDownloadAction(item)}
                  url={`https://${domain}/comercia_ws/${item.module}/pdf/${item.templateid}/${item.module === 'TreebesSatCFDI' ? data.treebessatcfdiid : data.crmid || data.id}`}
                  name={`${item.subject}_${data.entity_label}`}
                  shareByEmail={() => handleClickEmailAction(item)}
                />
              ))}

              {appModule === appModules.TreebesMdAG && template.TreebesMdAG && template.TreebesMdAG.map(item => (
                <ShareItem
                  key={item.templateid}
                  template={item}
                  download={() => handleClickDownloadAction(item)}
                  url={`https://${domain}/comercia_ws/${item.module}/pdf/${item.templateid}/${item.module === 'TreebesSatCFDI' ? data.treebessatcfdiid : data.crmid || data.id || data.referencia}`}
                  name={`${item.subject}_${data.entity_label}`}
                  shareByEmail={() => handleClickEmailAction(item)}
                />
              ))}

            </List>
          </div>
        )
        : (
          <div className="share-container">
            <AppBar style={{ position: 'relative', backgroundColor: '#F6F6F7' }}>
              <Toolbar variant="dense" classes={{ root: 'tollbar-header' }}>
                <Typography variant="h6" style={{ color: '#64707A' }}>
                  Plantillas de correo
                </Typography>
              </Toolbar>
            </AppBar>
            <List>
              {appModule === appModules.TreebesFdE && templateEmail.TreebesFdE && templateEmail.TreebesFdE.map(item => (
                <ShareItemEmail
                  key={item.templateid}
                  template={item}
                  shareByEmail={() => handleClickEmailAction(item, false)}
                />
              ))}

              {appModule === appModules.SalesOrder && templateEmail.SalesOrder && templateEmail.SalesOrder.map(item => (
                <ShareItemEmail
                  key={item.templateid}
                  template={item}
                  shareByEmail={() => handleClickEmailAction(item, false)}
                />
              ))}

              {appModule === appModules.Quotes && templateEmail.Quotes && templateEmail.Quotes.map(item => (
                <ShareItemEmail
                  key={item.templateid}
                  template={item}
                  shareByEmail={() => handleClickEmailAction(item, false)}
                />
              ))}

              {appModule === appModules.TreebesGTO && templateEmail.TreebesGTO && templateEmail.TreebesGTO.map(item => (
                <ShareItemEmail
                  key={item.templateid}
                  template={item}
                  shareByEmail={() => handleClickEmailAction(item, false)}
                />
              ))}

              {appModule === appModules.Accounts && templateEmail.Accounts && templateEmail.Accounts.map(item => (
                <ShareItemEmail
                  key={item.templateid}
                  template={item}
                  shareByEmail={() => handleClickEmailAction(item, false)}
                />
              ))}

              {appModule === appModules.TreebesSolicitudesEnvio && templateEmail.TreebesSolicitudesEnvio && templateEmail.TreebesSolicitudesEnvio.map(item => (
                <ShareItemEmail
                  key={item.templateid}
                  template={item}
                  shareByEmail={() => handleClickEmailAction(item, false)}
                />
              ))}


              {appModule === appModules.PurchaseOrder && templateEmail.PurchaseOrder && templateEmail.PurchaseOrder.map(item => (
                <ShareItemEmail
                  key={item.templateid}
                  template={item}
                  shareByEmail={() => handleClickEmailAction(item, false)}
                />
              ))}

              {appModule === appModules.TreebesMdAG && templateEmail.TreebesMdAG && templateEmail.TreebesMdAG.map(item => (
                <ShareItemEmail
                  key={item.templateid}
                  template={item}
                  shareByEmail={() => handleClickEmailAction(item, false)}
                />
              ))}

              {templateEmail.Todos && templateEmail.Todos.map(item => (
                <ShareItemEmail
                  key={item.templateid}
                  template={item}
                  shareByEmail={() => handleClickEmailAction(item, false)}
                />
              ))}
            </List>
          </div>
        )
    }
      <SaleEmailShare
        orderNo={data.entity_label}
        subject={subjectU}
        customer={customer}
        orderDate={data.createdtime}
        onClose={() => setOpenModal(false)}
        isOpen={openModal}
        emailBody={emailBody}
        razonSocial={organizationname}
        isLoading={sendEmailIsLoading}
        onSave={async (datos) => {
          const emailInfo = {};
          const accountID = selectedCustomer.crmid;
          emailInfo[accountID] = [selectedCustomer.email1];

          const newData = {
            ...datos,
            crmid: (moduleU === 'TreebesSatCFDI' ? (data.treebessatcfdiid || data.pagocfdiid /* case of share fde */) : data.crmid || data.id),
            type: 'email',
            orderNo: data.entity_label,
            templateid: templateU,
            moduleT: moduleU,
            toemailinfo: emailInfo,
          };
          await dispatch(sendEmail(newData));
        }}
      />
    </>
  );
};

Share.prototype = {
  data: PropTypes.object.isRequired,
  appModule: PropTypes.string.isRequired,
  type: PropTypes.string,
};

Share.defaultProps = {
  type: 'download',
};

export default Share;
