/* eslint-disable import/no-cycle */
import React from 'react';
import { useSelector } from 'react-redux';
import PaginationNumber from '../PaginationNumber';

const OrdersPaginator = ({ updatePage }) => {
  const loads = useSelector(state => state.loads);
  const orderData = useSelector(state => state.order.orderData);

  const { page, pages, count } = orderData;

  return (
    <div className="paginator-container">
      {loads.orderIsLoading && <div className="loading-bg" />}
      <div className="w-100 px-4 d-flex flex-row flex-wrap align-items-center justify-content-between">
        <div className="d-flex flex-row align-items-center">
          <h4>
            <strong className="text-secondary">{count}</strong>
            {' '}
            Ordenes
          </h4>
          {page && (
            <span className="current-page d-inline-block h-100 pl-4 text-secondary">
              Página
              {' '}
              <span className="font-weight-bold">{page}</span>
              /
              {' '}
              <span className="font-weight-bold">{pages}</span>
            </span>
          )}
        </div>
        <div className="d-flex flex-row py-3 align-items-center">
          {count && (
            <PaginationNumber
              className="pagination-bar"
              currentPage={page}
              totalCount={count}
              pageSize={100}
              onPageChange={newPage => updatePage(newPage)}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default OrdersPaginator;
