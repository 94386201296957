/* eslint-disable import/no-cycle */
import { connect } from 'react-redux';
import {
  openModal,
  closeModal,
} from '../../../../../redux/actions/modalActions';
import { sendEmail, getOrderPdf } from '../../../../../redux/actions/posActions';
/* Component */
import ShareControls from './ShareControls';

const mapStateToProps = state => ({
  tabs: state.tabs,
  cart: state.cart,
  loads: state.loads,
  modals: state.modals,
  authUser: state.authUser,
  customer: state.customer,
});

const mapDispatchToProps = dispatch => ({
  /* Modal */
  dispatchOpenModal: modal => dispatch(openModal(modal)),
  dispatchCloseModal: modal => dispatch(closeModal(modal)),
  /* Email */
  dispatchSendEmail: data => dispatch(sendEmail(data)),
  /* PDF */
  dispatchGetOrderDocument: (data, mode) => dispatch(getOrderPdf(data, mode)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ShareControls);
