import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import {
  Avatar,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core';

const LayoutUserInfo = ({ authUser }) => {
  const product = useSelector(state => state.product);
  const [selectedCurrency, setSelectedCurrency] = React.useState(null);
  const { user } = authUser;
  const { currencies } = user;

  const getActiveCurrency = () => {
    const currency = localStorage.getItem('currencyId');
    const moneda = currencies.find(c => c.id === currency);
    if (moneda) {
      setSelectedCurrency(moneda);
    }
  };

  React.useEffect(() => {
    getActiveCurrency();
  }, []);

  React.useEffect(() => {
    getActiveCurrency();
  }, [product]);

  return (
    <List>
      <ListItem button component="a" href="/account/profile">
        <Avatar className="General__UserAvatarStyle">{authUser.user.last_name.charAt(0)}</Avatar>
        <ListItemText
          primary={`${authUser.user.first_name} ${authUser.user.last_name} `}
          secondary={`${selectedCurrency?.currency_symbol} - ${selectedCurrency?.currency_code}`}
        />
      </ListItem>
    </List>
  ); };

LayoutUserInfo.propTypes = {
  authUser: PropTypes.object.isRequired,
};

export default LayoutUserInfo;
