/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Badge,
  Tooltip,
  IconButton,
} from '@material-ui/core';
import {
  Comment,
  AddComment,
} from '@material-ui/icons';

const AddDescAction = ({
  add,
  enable,
  isOrder,
  tooltip,
  haveDescription,
}) => (
  <Tooltip
    title={tooltip}
    placement="left"
  >
    <span>
      <IconButton
        disabled={!enable}
        onClick={add}
      >
        <Badge
          variant="dot"
          color="primary"
          invisible={!haveDescription}
          className="flex-column align-items-center"
        >
          {enable ? (
            haveDescription
              ? <Comment />
              : (isOrder
                ? (<Comment />)
                : (<AddComment color="primary" />)
              )
          ) : (<Comment color="disabled" />)}
          <span className="font-size-10">Descripción</span>
        </Badge>
      </IconButton>
    </span>
  </Tooltip>
);

AddDescAction.propTypes = {
  tooltip: PropTypes.string,
  add: PropTypes.func.isRequired,
  enable: PropTypes.bool.isRequired,
  isOrder: PropTypes.bool.isRequired,
  haveDescription: PropTypes.bool.isRequired,
};
AddDescAction.defaultProps = {
  tooltip: 'Añade una descripción',
};

export default AddDescAction;
