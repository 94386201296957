/*
import React, { useState } from 'react';
import '../../warehouse.styles.scss';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import {
  faDolly,
  faFileInvoice,
  faHandHoldingUsd,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  Collapse,
  ListItemIcon,
  IconButton,
} from '@material-ui/core';
import { Search } from '@material-ui/icons';
import { useHistory } from 'react-router';
import Iconwidthbg from '../../../../components/Icons/IconWidthBG/IconWidthBG';
import {
  getColorStatusFDE,
  getColorStatusMDA,
  geteColorSosStatus,
} from '../../../../helpers/tools';
import ShareModal from '../../../../components/Share/components/ShareModal';
import appModules from '../../../../constant/appModules';
import MdaAPI from '../../../../api/MdaApi';
// import WDeliverExpand from './WDeliverExpand';
import { loaded, loading } from '../../../../redux/actions/loadActions';
import CustomDatePicker from '../../../../components/CustomInputs/components/custom-date-picker';

const WTransferProccess = () => {
  const history = useHistory();
  const mdaApi = new MdaAPI();
  const dispatch = useDispatch();
  const loads = useSelector(state => state.loads);
  const cart = useSelector(state => state.cart);
  const {
    orderSelected: { order },
  } = cart;
  const isLoading = Boolean(loads && loads.emailTemplateIsLoading);
  const [orders, setOrders] = React.useState([]);
  const [filteredOrders, setFilteredOrders] = React.useState([]);
  const [selectSale, setSelectSale] = React.useState(null);
  const [openShare, setOpenShare] = React.useState(false);
  const [search, setSearch] = useState('subject');

  const selectLocalOrder = (sale) => {
    if (selectSale && sale.crmid === selectSale.crmid) {
      setSelectSale(null);
    } else {
      setSelectSale(sale);
    }
  };

  const shareModal = async (item) => {
    console.log('item', item);
    await setSelectSale(item);
    setOpenShare(true);
  };

  const { pos_filtrostate, store } = useSelector(
    state => state.authUser.user.config,
  );

  const getTransferProccess = async () => {
    dispatch(loading('order'));
    const response = await mdaApi.transfersToProcess(store.crmid);
    console.log('response', response);
      const { success, result } = response;
    if (success && result.length) {
      setOrders(result);
      setFilteredOrders(result);
    }
    dispatch(loaded('order'));
  };

  const entregarOrden = async () => {
    dispatch(loading('order'));
    history.push(`/almacenes/entrega/externa/${selectSale.crmid}`);
    dispatch(loaded('order'));
  };

  const handleShareClose = () => {
    setOpenShare(false);
  };

  const makeSearch = (searchValue) => {
    const sales = [...orders];
    const filter = sales.filter(item => item[`${search}`].toLowerCase().includes(searchValue.toLowerCase()));
    setFilteredOrders([...filter]);
  };

  const handleChangeDate = (name, date) => {
    const sales = [...orders];
    if (date[0] === null && date[1] === null) {
      setFilteredOrders([...sales]);

      return;
    }

    if (date[0] !== null && date[1] !== null) {
      const filter = sales.filter(item => moment(item[`${search}`]).isBetween(
        moment(date[0]).startOf('days'),
        moment(date[1]).endOf('day'),
        undefined, '[]',
      ));
      setFilteredOrders([...filter]);
    } else {
      const filter = sales.filter(item => moment(item[`${search}`]).isBetween(
        moment(date[0]).startOf('days'),
        moment(date[0]).endOf('day'),
        undefined, '[]',
      ));
      setFilteredOrders([...filter]);
    }
  };

  React.useEffect(() => {
    getTransferProccess();
  }, [store]);

  return (
    <>
      <div className="bg-white w-100 d-flex justify-content-between align-items-center px-2 py-4 sticky-top">
        <div
          className="d-flex w-100 px-2 py-2"
          style={{ backgroundColor: '#ebebeb', borderRadius: 20 }}
        >
          <select
            style={{
              border: 0,
              borderRadius: 10,
              padding: '0 5px',
              marginRight: '0.5rem',
              outline: 'none',
            }}
            onChange={e => setSearch(e.target.value)}
          >
            <option selected={search === 'subject'} value="subject">
              Asunto
            </option>
            <option selected={search === 'accountname'} value="accountname">
              Cuanta usuario
            </option>
            <option selected={search === 'f_venta'} value="f_venta">
              Fecha venta
            </option>
          </select>
          {search === 'f_venta' ? (
            <CustomDatePicker
              style={{ height: 30 }}
              onChange={handleChangeDate}
              headerName="date"
            />
          ) : (
            <input
              autoFocus
              style={{
                border: 0,
                width: '100%',
                backgroundColor: 'transparent',
                outline: 'none',
              }}
              onChange={e => makeSearch(e.target.value)}
              type="text"
            />
          )}
          <IconButton style={{ height: 30, color: '#313c52' }}>
            <Search id="actions_search_tag" />
          </IconButton>
        </div>
      </div>

      {loads.orderIsLoading && (
        <Collapse in={loads.orderIsLoading}>
          <LinearProgress />
        </Collapse>
      )}

      <div>
        {filteredOrders.length ? (
          <div style={{ paddingBottom: 66 }}>
            {filteredOrders.map(sale => (
              <div
                key={sale.crmid}
                style={{ backgroundColor: '#fff', cursor: 'pointer' }}
              >
                <ListItem
                  divider
                  className="quote"
                  onClick={() => selectLocalOrder(sale)}
                >
                  <ListItemText
                    classes={{
                      primary: 'quote__title',
                      secondary: 'quote__subtitle',
                    }}
                    primary={sale.subject}
                    secondary={(
                      <>
                        <span>{sale.accountname}</span>
                        <br />
                        <span>
                          {moment(sale.f_venta).format('DD MMM YYYY h:mm a')}
                        </span>
                      </>
                    )}
                  />
                  <div className="d-flex flex-column">
                    <ListItemText
                      classes={{ primary: 'quote__money' }}
                      primary={sale.treebesalmid_label}
                      style={{ textAlign: 'right', padding: 0 }}
                    />
                    <div className="d-flex">
                      <ListItemIcon className="m-0">
                        <Iconwidthbg
                          primaryColor={geteColorSosStatus(sale.sostatus)}
                          secondaryColor="white"
                          width={27}
                          height={27}
                          classes="ml-2"
                          enabled
                          square
                          rounded
                          actionClick={() => {}}
                        >
                          <FontAwesomeIcon
                            style={{ fontSize: 15 }}
                            icon={faFileInvoice}
                          />
                        </Iconwidthbg>
                      </ListItemIcon>
                      <ListItemIcon className="m-0">
                        <Iconwidthbg
                          primaryColor={getColorStatusMDA(sale.estado_mda)}
                          secondaryColor="white"
                          width={27}
                          height={27}
                          classes="ml-2"
                          enabled
                          square
                          rounded
                          actionClick={() => {}}
                        >
                          <FontAwesomeIcon
                            style={{ fontSize: 15 }}
                            icon={faDolly}
                          />
                        </Iconwidthbg>
                      </ListItemIcon>
                      <ListItemIcon className="m-0">
                        <Iconwidthbg
                          primaryColor={getColorStatusFDE(sale.estado_fde)}
                          secondaryColor="white"
                          width={27}
                          height={27}
                          classes="ml-2"
                          enabled
                          square
                          rounded
                          actionClick={() => {}}
                        >
                          <FontAwesomeIcon
                            style={{ fontSize: 15 }}
                            icon={faHandHoldingUsd}
                          />
                        </Iconwidthbg>
                      </ListItemIcon>
                    </div>
                  </div>
                </ListItem>
                <Collapse
                  in={selectSale && selectSale.crmid === sale.crmid}
                  style={{ backgroundColor: '#fafafa', marginBottom: 10 }}
                >
                    <WDeliverExpand
                    sale={sale}
                    deliverSale={() => entregarOrden()}
                    openShare={shareModal}
                  />
                </Collapse>
              </div>
            ))}
          </div>
        ) : (
          <List dense>
            <ListItem classes={{ root: 'bg-white' }}>
              <ListItemText primary="No se encontraron transferenacias a procesar" />
            </ListItem>
          </List>
        )}
      </div>

       <ShareModal
        open={openShare}
        title={`Compartir ${selectSale ? selectSale.salesorder_no : ''}`}
        handleClose={handleShareClose}
        order={selectSale || {}}
        template={appModules.SalesOrder}
        isLoading={isLoading}
      />
    </>
  );
};

export default WTransferProccess;
 */

/* eslint-disable import/no-cycle */
import React, { useState } from 'react';
import moment from 'moment';
import {
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  Collapse,
} from '@material-ui/core';
import { useSelector } from 'react-redux';

import { useHistory } from 'react-router';
import ShareModal from '../../../../components/Share/components/ShareModal';
import appModules from '../../../../constant/appModules';
import MdaAPI from '../../../../api/MdaApi';
import { formatString } from '../../../../helpers/tools';
import WTransferProcessExpand from './WTransferProcessExpand';

const WTransferProccess = () => {
  const history = useHistory();
  const [purchase, setPurchase] = useState({});
  const [loading, setLoading] = useState(false);
  const [transfers, setTransfersReceive] = useState([]);

  const loads = useSelector(state => state.loads);
  const authUser = useSelector(state => state.authUser);
  const isLoading = Boolean(loads && loads.emailTemplateIsLoading);
  const [openShare, setOpenShare] = React.useState(false);

  const {
    user: {
      config: { store },
    },
  } = authUser;

  const handleShareClose = () => {
    setOpenShare(false);
  };

  const shareModal = async (item) => {
    await setPurchase(item);
    setOpenShare(true);
  };

  const selectLocalOrder = (sale) => {
    if (purchase && sale.referencia === purchase.referencia) {
      setPurchase(null);
    } else {
      setPurchase(sale);
    }
  };

  const transfersToProcess = async () => {
    setLoading(true);
    const mdaApi = new MdaAPI();
    const response = await mdaApi.transfersToProcess(store.crmid);
    const { success, result } = response;

    if (success) {
      setTransfersReceive(result);
    }

    setLoading(false);
  };

  const processTransfer = () => {
    history.push(`/almacenes/procesar/transferencia/${purchase.referencia}`);
  };

  React.useEffect(() => {
    transfersToProcess();
  }, [store]);

  return (
    <>
      {loading && (
        <Collapse in={loading}>
          <LinearProgress />
        </Collapse>
      )}

      <div>
        {transfers.length ? (
          <div style={{ paddingBottom: 66 }}>
            {transfers.map(sale => (
              <div
                key={sale.referencia}
                style={{ backgroundColor: '#fff', cursor: 'pointer' }}
              >
                <ListItem
                  divider
                  className="quote"
                  onClick={() => selectLocalOrder(sale)}
                >
                  <ListItemText
                    classes={{
                      primary: 'quote__title',
                      secondary: 'quote__subtitle',
                    }}
                    primary={sale.referencia}
                    secondary={(
                      <>
                        <span>{sale.assigned_user_label}</span>
                        <br />
                        <span>
                          {moment(sale.fecha).format('DD MMM YYYY h:mm a')}
                        </span>
                      </>
                    )}
                  />
                  <div className="d-flex flex-column">
                    <ListItemText
                      classes={{ primary: 'quote__money' }}
                      primary={formatString(sale.treebesalmid_label)}
                      style={{ textAlign: 'right', padding: 0 }}
                    />
                  </div>
                </ListItem>
                <Collapse
                  in={purchase && purchase.referencia === sale.referencia}
                  style={{ backgroundColor: '#fafafa', marginBottom: 10 }}
                >
                  <WTransferProcessExpand
                    purchase={sale}
                    openShare={shareModal}
                    processTransfer={() => processTransfer()}
                  />
                </Collapse>
              </div>
            ))}
          </div>
        ) : (
          <List dense>
            <ListItem classes={{ root: 'bg-white' }}>
              <ListItemText primary="No se encontraron transferencias" />
            </ListItem>
          </List>
        )}
      </div>
      <ShareModal
        open={openShare}
        title={`Compartir ${purchase ? purchase.entity_label : ''}`}
        handleClose={handleShareClose}
        order={purchase || {}}
        template={appModules.PurchaseOrder}
        isLoading={isLoading}
      />
    </>
  );
};

export default WTransferProccess;
