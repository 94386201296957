/* eslint-disable react/no-danger */
/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import QRCode from 'qrcode.react';
import moment from 'moment';
import { toolsHelper } from '../../../helpers';

class TicketToPrint extends React.Component {
  static propTypes = {
    desc: PropTypes.string,
    store: PropTypes.string,
    Total: PropTypes.string,
    client: PropTypes.string,
    precio: PropTypes.string,
    orgData: PropTypes.string,
    subtotal: PropTypes.string,
    quantity: PropTypes.string,
    productName: PropTypes.string,
    cart: PropTypes.object.isRequired,
    customer: PropTypes.object.isRequired,
    authUser: PropTypes.object.isRequired,
    discount: PropTypes.object.isRequired,
    fde: PropTypes.array.isRequired,
    rewards: PropTypes.object,
    ticketStore: PropTypes.array.isRequired,
  };

  static defaultProps = {
    Total: 'Total',
    precio: 'Precio',
    store: 'Sucursal',
    desc: 'Descripción ',
    subtotal: 'Subtotal: ',
    quantity: 'Cantidad ',
    productName: 'Producto ',
    client: 'Datos del cliente ',
    orgData: 'Factura en Linea en  ',
    rewards: null,
  };

  render() {
    const {
      desc,
      store,
      Total,
      client,
      precio,
      orgData,
      subtotal,
      quantity,
      productName,
      cart,
      customer,
      authUser,
      discount,
      fde,
      rewards,
      ticketStore,
    } = this.props;
    const convertDate = (customDate, lang) => {
      const date = customDate ? new Date(`${customDate}`) : new Date();
      const options = {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
      };
      let formatedDate = null;

      if (lang === 'es' || !lang) {
        formatedDate = date.toLocaleDateString('es-ES', options);
      } else {
        formatedDate = date.toLocaleDateString('en-US', options);
      }
      return formatedDate;
    };

    const { ticketType } = ticketStore;
    const { selectedCustomer } = customer;
    const {
      products,
      description,
      subTotal,
      totalIva,
      totalProducts,
      orderSelected,
    } = cart;

    const productList = !products.length
      ? ticketStore?.products || []
      : products || [];

    const subTotalG = subTotal || ticketStore?.subTotal;
    const totalIvaG = totalIva || ticketStore?.totalIva;
    const orderSelectedG = Object.keys(orderSelected).length > 0
      ? orderSelected.order
      : ticketStore?.order;

    const discoutnExist = Boolean(
      productList.some(product => product.discount_percent > 0),
    );

    const {
      user: {
        config, logoname, organizationname, customer_portal, almacenes,
      },
    } = authUser;
    const {
      phone, email1, address, accountname,
    } = selectedCustomer;
    const facturar = `${customer_portal}`;
    const currentStore = config.store.name || config.store.almacen || 'General';
    const configStore = config.store.campos || '';
    const scale = config.pos_print_size ? config.pos_print_size : '7cm';
    const { haveDiscount } = discount;
    const assigned_user = Object.keys(orderSelectedG).length > 0 && orderSelectedG?.assigned_user
      ? orderSelectedG?.assigned_user
      : '';

    const ticketRecibido = localStorage.getItem('recibido') || 0;
    const ticketCambio = localStorage.getItem('cambio') || 0;

    // Buscando almacen seleccionado
    const selectedStore = almacenes.find(
      alm => alm.crmid === config.store.crmid,
    );

    // Order currency
    const orderTaxes = orderSelectedG?.LineItems_FinalDetails
      && Object.keys(orderSelectedG?.LineItems_FinalDetails).length > 0
      ? orderSelectedG?.LineItems_FinalDetails[1]?.final_details?.taxes
      : {};
    const haveTAX = Object.values(orderTaxes).some(
      tax => (tax.taxlabel === 'TAX')
        && parseFloat(tax.amount) > 0,
    );

    const paymentTranslate = (paymentMethod) => {
      if (haveTAX) {
        switch (paymentMethod) {
          case 'Efectivo':
            return 'Cash';

          case 'Tarjeta de Debito':
            return 'Debit Card';

          case 'Tarjeta de Credito':
            return 'Credit Card';

          case 'Cheque':
            return 'Check';

          case 'Transferencia':
          case 'Transferencia (SEPI)':
            return 'Transfer';

          case 'Intermediario pagos':
            return 'Payment gateway';

          case 'LBL_PUNTOS_RECOMPENSA':
            return 'Reward points';

          default:
            return paymentMethod;
        }
      }

      return paymentMethod;
    };

    return (
      <table
        style={{ maxWidth: scale, minWidth: scale }}
        className="tickets_table"
      >
        {configStore.includes('Imagen') && (
          <thead>
            <tr>
              <th colSpan={5}>
                <img
                  src={config.store.image || logoname}
                  alt={organizationname}
                  className="tickets_table_logo"
                />
              </th>
            </tr>
          </thead>
        )}
        <thead>
          <tr>
            <th colSpan={5}>
              {`${haveTAX ? 'Store' : store} : ${currentStore}`}
            </th>
          </tr>
          <tr>
            <th className="tickets_table_type" colSpan={5}>
              {ticketType === 'order'
                ? haveTAX
                  ? 'SALE TICKET'
                  : 'TICKET DE VENTA'
                : ticketType === 'quote'
                  ? `${haveTAX ? 'QUOTE' : 'COTIZACIÓN'}# ${
                    orderSelectedG.quote_no
                  }`
                  : `${
                    haveTAX ? 'PRE-ASSORTED TICKET' : 'TICKET DE PRE-SURTIDO'
                  }`}
            </th>
          </tr>
        </thead>
        {ticketType === 'order' && configStore.includes('No. de Venta') && (
          <thead>
            <tr>
              <td className="tickets_table_client" colSpan={5}>
                {orderSelectedG.label || ''}
              </td>
            </tr>
          </thead>
        )}
        {configStore.includes('Cliente') && (
          <thead>
            <tr>
              {/* This divider line is ok for ticket */}
              <th className="text-center" colSpan={5}>
                -----------------------------------------------
              </th>
            </tr>
          </thead>
        )}
        {configStore.includes('Cliente') && (
          <thead>
            <tr>
              <th colSpan={5}>{`${haveTAX ? 'Client data' : client}`}</th>
            </tr>
          </thead>
        )}
        {configStore.includes('Cliente') && (
          <tbody>
            <tr>
              <td colSpan={5} className="tickets_table_client">
                {accountname}
              </td>
            </tr>
            {address !== ', , , , , , ' && (
              <tr>
                <td colSpan={5} className="tickets_table_client">
                  {address}
                </td>
              </tr>
            )}
            <tr>
              <td colSpan={5} className="tickets_table_client">
                {phone}
              </td>
            </tr>
            <tr>
              <td colSpan={5} className="tickets_table_client">
                {email1}
              </td>
            </tr>
            <tr>
              {/* This divider line is ok for ticket */}
              <th className="text-center" colSpan={5}>
                -----------------------------------------------
              </th>
            </tr>
          </tbody>
        )}
        {ticketType === 'product' ? (
          <thead>
            <tr>
              <th>{`${haveTAX ? 'Product' : productName}`}</th>
              <th>{`${haveTAX ? 'Quantity' : quantity}`}</th>
            </tr>
          </thead>
        ) : (
          <thead>
            <tr className="tickets_table_title_product">
              <th colSpan={2}>{`${haveTAX ? 'Product' : productName}`}</th>
              <th>{`${haveTAX ? 'Price' : precio}`}</th>
              {discoutnExist && <th>%</th>}
              <th>{Total}</th>
            </tr>
          </thead>
        )}
        {ticketType === 'product' ? (
          <tbody>
            {productList.map(product => (
              <tr key={`key_row_${product.crmid}`}>
                <td>{`${product.productname} ${product.opcion}`}</td>
                <td className="tickets_table_qty">{product.quantity}</td>
              </tr>
            ))}
            <tr>
              {/* This divider line is ok for ticket */}
              <th className="text-center" colSpan={5}>
                -----------------------------------------------
              </th>
            </tr>
          </tbody>
        ) : (
          <tbody>
            {productList.map(product => (
              <>
                {!product.productid || !product.productid.includes('96866') ? (
                  <tr key={`key_row_${product.crmid}`}>
                    <td colSpan={2} className="tickets_table_desc">
                      {`(${product.quantity}) ${product.productname}`}
                    </td>
                    <td className="tickets_table_subTotal">
                      {toolsHelper.formatMoney(product.unit_price)}
                    </td>
                    {discoutnExist && (
                      <td className="tickets_table_subTotal">
                        {product.discount_percent > 0 ? (
                          <div className="w-100 h-100 d-flex flex-column align-items-center pt-3">
                            <span>
                              {`${toolsHelper.numberFormat(
                                product.discount_percent,
                              )}%`}
                            </span>
                            <span>
                              {`(${toolsHelper.numberFormat(
                                product.discount_amount,
                              )})`}
                            </span>
                          </div>
                        ) : (
                          '0%'
                        )}
                      </td>
                    )}
                    <td className="tickets_table_totales">
                      {toolsHelper.formatMoney(
                        product.quantity * product.unit_price
                          - product.discount_amount,
                      )}
                    </td>
                  </tr>
                ) : null}
              </>
            ))}
            <tr>
              {/* This divider line is ok for ticket */}
              <th colSpan={5}>
                -----------------------------------------------
              </th>
            </tr>
          </tbody>
        )}
        {ticketType === 'order' || ticketType === 'quote' ? (
          <tbody>
            {haveDiscount && (
              <tr>
                <td className="tickets_table_subtotal" colSpan={3}>
                  {`${haveTAX ? 'Discount' : 'Descuento'} ${discount.name}: ${
                    discount.percentage !== 0 ? `(${discount.percentage}%)` : ''
                  }`}
                </td>
                <td className="tickets_table_subtotal" colSpan={3}>
                  {` $ ${toolsHelper.formatMoney(discount.total) || ''}`}
                </td>
              </tr>
            )}
            {
              productList.map(product => (
                <>
                  {
                    product.productid && product.productid.includes('96866') ? (
                      <tr>
                        <td className="tickets_table_subtotal" colSpan={2}>
                          Rewards
                        </td>
                        <td className="tickets_table_subtotal" colSpan={3}>
                          {`$ ${toolsHelper.formatMoney(product.unit_price)}`}
                        </td>
                      </tr>
                    ) : null
                }
                </>
              ))

              }
            <tr>
              <td className="tickets_table_subtotal" colSpan={2}>
                {subtotal}
              </td>
              <td className="tickets_table_subtotal" colSpan={3}>
                {`$ ${toolsHelper.formatMoney(subTotalG)}`}
              </td>
            </tr>
            {orderSelectedG.sin_impuestos !== '1' && (
              <tr>
                <td className="tickets_table_subtotal" colSpan={2}>
                  {`${haveTAX ? 'TAX' : 'Impuestos'}`}
                </td>
                <td className="tickets_table_subtotal" colSpan={3}>
                  {`$ ${toolsHelper.formatMoney(totalIvaG)}`}
                </td>
              </tr>
            )}
            <tr>
              <td className="tickets_table_subtotal" colSpan={2}>
                {Total}
              </td>
              <td className="tickets_table_total" colSpan={3}>
                {`$ ${toolsHelper.formatMoney(orderSelectedG?.total)}`}
              </td>
            </tr>
            {parseFloat(ticketCambio) > 0 && (
              <>
                <tr>
                  {/* This divider line is ok for ticket */}
                  <th className="text-center" colSpan={5}>
                    -----------------------------------------------
                  </th>
                </tr>
                <tr>
                  <td className="tickets_table_subtotal" colSpan={2}>
                    {`${haveTAX ? 'Cash tend' : 'Recibido'}`}
                  </td>
                  <td className="tickets_table_subtotal" colSpan={3}>
                    {`$ ${toolsHelper.formatMoney(ticketRecibido)}`}
                  </td>
                </tr>
                <tr>
                  <td className="tickets_table_subtotal" colSpan={2}>
                    {`${haveTAX ? 'Change due' : 'Cambio'}`}
                  </td>
                  <td className="tickets_table_subtotal" colSpan={3}>
                    {`$ ${toolsHelper.formatMoney(ticketCambio)}`}
                  </td>
                </tr>
              </>
            )}
            <tr>
              {/* This divider line is ok for ticket */}
              <th className="text-center" colSpan={5}>
                -----------------------------------------------
              </th>
            </tr>
            {ticketType !== 'quote' && (
              <>
                {orderSelectedG.tfde_balance !== '0.00000000' && (
                  <>
                    <tr>
                      <td className="tickets_table_subtotal" colSpan={2}>
                        {`${haveTAX ? 'Paid out:' : 'Pagado:'}`}
                      </td>
                      <td className="tickets_table_total" colSpan={3}>
                        {`$ ${toolsHelper.formatMoney(
                          orderSelectedG.tfde_pagado,
                        )}`}
                      </td>
                    </tr>
                    <tr>
                      <td className="tickets_table_subtotal" colSpan={2}>
                        {`${haveTAX ? 'To pay:' : 'Por pagar:'}`}
                      </td>
                      <td className="tickets_table_total" colSpan={3}>
                        {`$ ${toolsHelper.formatMoney(
                          orderSelectedG.tfde_balance,
                        )}`}
                      </td>
                    </tr>
                    <tr>
                      {/* This divider line is ok for ticket */}
                      <th className="text-center" colSpan={5}>
                        -----------------------------------------------
                      </th>
                    </tr>
                  </>
                )}
                {fde && fde.length > 0 && (
                  <>
                    <tr>
                      <td className="text-center" colSpan={4}>
                        {`${
                          haveTAX
                            ? 'Payment information'
                            : 'Información de pagos'
                        }`}
                      </td>
                    </tr>
                    {fde.map(item => (
                      <tr>
                        {
                          item.tfde_tipo !== 'Egreso' && item.tfde_metodo !== 'LBL_PUNTOS_RECOMPENSA' && (
                            <td
                              className="text-center"
                              colSpan={4}
                              style={{ fontSize: 14 }}
                            >
                              {` ${paymentTranslate(
                                item.tfde_metodo,
                              )} - $${toolsHelper.formatMoney(item.monto)} ${
                                item.moneda_flujo
                              } - ${
                                haveTAX
                                  ? convertDate(item.createdtime, 'us')
                                  : moment(item.createdtime).format('l')
                              }`}
                            </td>
                          )
                        }
                      </tr>
                    ))}
                    <tr>
                      {/* This divider line is ok for ticket */}
                      <th className="text-center" colSpan={5}>
                        -----------------------------------------------
                      </th>
                    </tr>
                  </>
                )}
                {((rewards && rewards?.puntos_saldo > 0) || fde.some(item => item.tfde_tipo === 'Egreso' && item.tfde_metodo === 'LBL_PUNTOS_RECOMPENSA')) && (
                  <>
                    <tr>
                      <td className="text-center" colSpan={4}>
                        {`${
                          haveTAX
                            ? 'Rewards points'
                            : 'Puntos recompensa'
                        }`}
                      </td>
                    </tr>
                    {fde.map(item => (
                      <tr>

                        {
                          item.tfde_tipo === 'Egreso' && item.tfde_metodo === 'LBL_PUNTOS_RECOMPENSA' && (
                            <>
                              <td>
                                {`${
                                  haveTAX
                                    ? 'Points earned'
                                    : 'Acumulados'
                                }`}
                              </td>
                              <td
                                className=""
                                colSpan={4}
                                style={{ fontSize: 14, textAlign: 'right' }}
                              >
                                {`$ ${toolsHelper.formatMoney(item.monto)}`}
                              </td>
                            </>
                          )
                        }
                      </tr>
                    ))}
                    <tr>
                      <td>
                        {`${
                          haveTAX
                            ? 'Balance'
                            : 'Saldo'
                        }`}
                      </td>
                      <td
                        className=""
                        colSpan={4}
                        style={{ fontSize: 14, textAlign: 'right' }}
                      >
                        $
                        {' '}
                        {toolsHelper.formatMoney(rewards?.puntos_saldo)}
                      </td>
                    </tr>
                    <tr>
                      {/* This divider line is ok for ticket */}
                      <th className="text-center" colSpan={5}>
                        -----------------------------------------------
                      </th>
                    </tr>
                  </>
                )}
              </>
            )}
          </tbody>
        ) : (
          <>
            <tr>
              <td className="tickets_table_subtotal" colSpan={2}>
                {`${haveTAX ? 'Total to be supplied:' : 'Total a surtir:'}`}
              </td>
              <td className="tickets_table_subtotal" colSpan={3}>
                {`${totalProducts}`}
              </td>
            </tr>
            <tr>
              {/* This divider line is ok for ticket */}
              <th className="text-center" colSpan={5}>
                -----------------------------------------------
              </th>
            </tr>
          </>
        )}
        {description !== '' && (
          <thead>
            <tr>
              <th colSpan={5}>{desc}</th>
            </tr>
          </thead>
        )}
        {description !== '' && (
          <tbody>
            <tr>
              <td colSpan={2} className="tickets_table_client">
                {description}
              </td>
            </tr>
          </tbody>
        )}
        {description !== '' && (
          <thead>
            <tr>
              {/* This divider line is ok for ticket */}
              <th className="text-center" colSpan={5}>
                -----------------------------------------------
              </th>
            </tr>
          </thead>
        )}

        {ticketType === 'order' ? (
          <tbody>
            {configStore.includes('Fecha') && (
              <tr>
                <th
                  className="font-size-11 text-center font-weight-normal"
                  colSpan={5}
                >
                  {haveTAX
                    ? convertDate(`${orderSelectedG.createdtime}`, 'us')
                    : convertDate(orderSelectedG.createdtime, 'es')}
                </th>
              </tr>
            )}
          </tbody>
        ) : (
          <tbody>
            {configStore.includes('Fecha') && (
              <tr>
                <th
                  className="font-size-11 text-center font-weight-normal"
                  colSpan={5}
                >
                  {haveTAX
                    ? convertDate(false, 'us')
                    : new Date().toLocaleString()}
                </th>
              </tr>
            )}
          </tbody>
        )}
        <tbody>
          {configStore.includes('Teléfono') && (
            <tr>
              <th
                className="font-size-11 text-center font-weight-normal"
                colSpan={5}
              >
                {`TEL. ${config.store.telefono}`}
              </th>
            </tr>
          )}
          {configStore.includes('Dirección') && (
            <tr>
              <th
                className="font-size-11 text-center font-weight-normal"
                colSpan={5}
              >
                {config.store.direccion}
              </th>
            </tr>
          )}
          {configStore.includes('Página web') && (
            <tr>
              <th
                className="font-size-11 text-center font-weight-normal"
                colSpan={5}
              >
                {config.store.url_web}
              </th>
            </tr>
          )}
          {configStore && configStore.includes('Atendido') && (
            <tr>
              <th
                className="font-size-11 text-center font-weight-normal"
                colSpan={5}
              >
                {`${
                  haveTAX ? 'Attended by' : 'Atendido por:'
                } ${assigned_user}`}
              </th>
            </tr>
          )}
        </tbody>

        {configStore.includes('Facturar en Portal de Clientes')
          && ticketType === 'order' && (
            <thead>
              <tr>
                {/* This divider line is ok for ticket */}
                <th className="text-center" colSpan={5}>
                  ----------------------------------------------
                </th>
              </tr>
              <tr>
                <th colSpan={5}>
                  {`${haveTAX ? 'Online Invoice at' : orgData} : ${facturar}`}
                </th>
              </tr>
            </thead>
        )}
        {ticketType === 'order'
          && configStore.includes('Facturar en Portal de Clientes') && (
            <thead>
              <tr>
                <th colSpan={5}>
                  <QRCode value={facturar} />
                  {/* <p>
                    {`${
                      haveTAX
                        ? 'Without ticket, there will be no refund'
                        : 'Sin ticket, no habrá devolución'
                    }`}
                  </p> */}
                </th>
              </tr>
            </thead>
        )}
        <thead>
          {selectedStore && selectedStore.tpv_leyenda_tiket && (
            <tr>
              {/* This divider line is ok for ticket */}
              <th className="text-center" colSpan={5}>
                ----------------------------------------------
              </th>
            </tr>
          )}
        </thead>
        {selectedStore && selectedStore.tpv_leyenda_tiket && (
          <thead>
            <tr>
              <th colSpan={5}>
                <div
                  style={{ width: 282 }}
                  dangerouslySetInnerHTML={{
                    __html: selectedStore.tpv_leyenda_tiket,
                  }}
                />
              </th>
            </tr>
          </thead>
        )}
      </table>
    );
  }
}

export default TicketToPrint;
