/* eslint-disable react/prop-types */
import {
  Button,
  Checkbox,
  Collapse, LinearProgress, Paper, Tooltip,
} from '@material-ui/core';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import useSound from '../../../../../hooks/sound/useSound';
import { formatString } from '../../../../../helpers/tools';
import AddBarCode from './AddBarCode';

const TransferProcessDetailProducts = ({
  products, changeInput, loading, selectAll, selectNone, selectedProducts, updateBarCode,
}) => {
  const { playSound } = useSound();
  const scrolledActionEvent = new Event('scrolledAction');
  const handleReadBarCode = (e) => {
    const barCode = localStorage.getItem('barCode') || '';
    if (e.keyCode === 13 && barCode && products.length) {
      const findIndex = products.findIndex(prod => `${prod.codigo_barras}` === barCode);
      if (findIndex !== -1) {
        const item = products[findIndex];
        console.log('item ->', item);
        localStorage.setItem('scanedProductId', item.productid);
        document.dispatchEvent(scrolledActionEvent);
        localStorage.setItem('selectedInput', item.productid);
        const selectedIndex = selectedProducts.findIndex(p => p === item.treebesmdaid);
        if (selectedIndex !== -1) {
          toast.success('Producto seleccionado');
          return;
        }
        if (item.cantidad > 0) {
          changeInput(item);
          playSound('success');
          toast.success('Producto seleccionado');
          localStorage.setItem('barCode', '');
        } else {
          playSound('error');
          toast.warn('No hay disponible en stock');
          localStorage.setItem('barCode', '');
        }
      } else {
        localStorage.setItem('scanedProductId', '');
        playSound('invalid');
        toast.error('No existe el codigo');
        localStorage.setItem('barCode', '');
      }
    } else if (e.key !== 'Enter' && e.key !== 'Shift') {
      localStorage.setItem('barCode', `${barCode}${e.key}`);
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleReadBarCode, false);
    localStorage.setItem('barCode', '');
    return () => {
      document.removeEventListener('keydown', handleReadBarCode, false);
      localStorage.setItem('barCode', '');
    };
  }, [selectedProducts]);

  useEffect(() => {
    document.addEventListener('scrolledAction', () => {
      const id = localStorage.getItem('scanedProductId');
      const element = document.getElementById(`${id}`);
      if (element) {
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'nearest',
        });
      }
      return () => {
        document.removeEventListener('scrolledAction', null, false);
      };
    });
  }, []);


  return (
    <div className="deliver__products">
      <div className="d-flex justify-content-between align-items-center mb-2">
        <h4>Productos</h4>
        <div className="d-flex">
          <Button disabled={loading} className="mr-1" variant="outlined" onClick={selectAll}>
            Seleccionar todo
          </Button>
          <Button disabled={loading} variant="outlined" onClick={selectNone}>
            Seleccionar nada
          </Button>
        </div>
      </div>
      <Collapse in={loading}>
        <LinearProgress />
      </Collapse>
      <Paper className="w-100 product-list">
        {
          products.length > 0 && products.map(item => (
            <div
              id={item.productid}
              className="refund_product"
              key={item.treebesmdaid}
              style={{
                border:
            localStorage.getItem('scanedProductId') === item?.productid
              ? '8px solid red'
              : '4px solid #efeff4',
              }}
            >
              <div className="refund_product_stock">
                {item.cantidad}
                <span>Cantidad</span>
              </div>
              <div className="refund_product_detail">
                <div className="refund_product_title">
                  <Tooltip placement="left-start" title={item.productname || ''}>
                    <span>{formatString(item.productname)}</span>
                  </Tooltip>
                </div>
                <div className="refund_product_desc">
                  {`codigo: ${item.productcode}`}
                </div>
                <div className="refund_product_desc" />
              </div>
              <div className="refund_product_qty">
                <div style={{
                  display: 'flex', justifyContent: 'start', alignItems: 'center', flexDirection: 'column',
                }}
                >
                  {/* <span>
                    Código barras:
                  </span>
                  <input className="scanInput" type="text" readOnly value={item.codigo_barras} /> */}
                  <AddBarCode item={item} updateBarCode={updateBarCode} />
                </div>
                <Checkbox
                  checked={selectedProducts.includes(item.treebesmdaid)}
                  onChange={() => changeInput(item)}
                  value={item.id}
                />
              </div>
            </div>
          ))
        }
      </Paper>
    </div>
  ); };

TransferProcessDetailProducts.propTypes = {
  products: PropTypes.array,
  selectedProducts: PropTypes.array,
  loading: PropTypes.bool,
  changeInput: PropTypes.func,
  selectAll: PropTypes.func,
  selectNone: PropTypes.func,
};

TransferProcessDetailProducts.defaultProps = {
  products: [],
  selectedProducts: [],
  loading: false,
  changeInput: () => {},
  selectAll: () => {},
  selectNone: () => {},
};

export default TransferProcessDetailProducts;
