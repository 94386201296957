import { IconButton, List, ListItem, ListItemText, Typography } from '@material-ui/core';
import { Check } from '@material-ui/icons';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { formatMoney } from '../../../../../../helpers/tools';
import './efectivo.styles.scss';

const Efectivo = ({item, changeCash, maxValue}) => {

  const [input, setInput] = useState(parseFloat(Math.abs(item.monto)));
  const changeAmount = (value) => {
    if (parseFloat(value) > maxValue) {
      toast.info(`El valor maximo de efectivo es ${formatMoney(maxValue)}`);
      return;
    }
    if (parseFloat(value) < 0) {
      toast.info('El valor minimo de efectivo es 0');
      return;
    }

    if (value) {
      setInput(value);
      item.monto = `-${value}`;
      changeCash(item);
    } else {
      setInput(0);
      item.monto = `${0}`;
      changeCash(item);
    }
  };

  const resetValue = async () => {
    await setInput(0);
    await setInput(parseFloat(Math.abs(item.monto)));
  };

  React.useEffect(() => {
    resetValue();
  }, [item]);

  return (
    <List>
      <ListItem
        className="detalle_box detalle_box_divider"
      >
        <ListItemText
          primary={(
            <>
              <Typography component="span" variant="body2" color="textPrimary">
                <div className="balance-detalles__flex-space">
                  <strong>{item.referencia}</strong>
                  <span className="detalle_cantidad">{item.banco}</span>
                  <div className="d-flex align-items-center">
                    <div className="custom-input-container ">
                      <strong>$</strong>
                      <input disabled className="cash-custom-input" max={maxValue} min={0} type="number" value={input} onChange={e => changeAmount(e.target.value)} />
                    </div>
                  </div>
                </div>
              </Typography>
            </>
      )}
        />
      </ListItem>
    </List>
  );
};

export default Efectivo;
