/* eslint-disable import/no-cycle */
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';

import reducer from './reducers/index';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const loggerMiddleware = createLogger({
  predicate: () => process.env.NODE_ENV === 'development',
});
const store = createStore(reducer, composeEnhancers(
  applyMiddleware(thunk, loggerMiddleware),
));
// const store = createStore(reducer, applyMiddleware(thunk, loggerMiddleware));

export default store;
