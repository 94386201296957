/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import {
  Tooltip,
  IconButton,
} from '@material-ui/core';
import { Save } from '@material-ui/icons';
import { toast } from 'react-toastify';
// eslint-disable-next-line import/no-cycle
import { openModal } from '../../../../../redux/actions/modalActions';

const SaveSellAction = ({
  enable,
  tooltip,
  saveSell,
}) => {
  const dispatch = useDispatch();
  const user = useSelector(state => state.authUser.user);
  const cart = useSelector(state => state.cart);
  const customer = useSelector(state => state.customer);
  const deliver = useSelector(state => state.shipping?.shippingList);
  const { selectedCustomer, defaultCustomer } = customer;
  const { config, shipping_id } = user;
  const { pos_no_crear_so, store } = config;
  const noGuardar = Boolean(pos_no_crear_so === '1');
  const canSave = noGuardar ? true : !enable;

  const save = () => {
    if (selectedCustomer.crmid === defaultCustomer.crmid) {
      saveSell();

      return;
    }
    if (store.forzaEnvio === '1') {
      const shippingIsSelected = cart.products.findIndex(prod => prod.crmid === shipping_id);
      // Total products
      const productQty = cart.products.filter(p => p.crmid !== shipping_id).reduce((acc, prod) => acc + prod.quantity, 0);
      // Total products with shipping
      const shippingProducts = [];
      deliver.map(p => p.products.map(prod => shippingProducts.push(prod)));
      const totalShippingProducts = shippingProducts.reduce((acc, prod) => acc + prod.quantity, 0);

      if (shippingIsSelected === -1 && (deliver.length === 0 || totalShippingProducts !== productQty)) {
        if (totalShippingProducts !== productQty) {
          toast.info('La cantidad de los productos en envíos debe ser la misma cantidad de productos que la orden');
          dispatch(openModal('shipping'));
          return;
        }

        toast.info('Debes seleccionar un envío antes de guardar la venta');
        dispatch(openModal('shipping'));
        return;
      }

      if (shippingIsSelected !== -1 && totalShippingProducts !== productQty) {
        toast.info('La cantidad de los productos en envíos debe ser la misma cantidad de productos que la orden');
        dispatch(openModal('shipping'));
        return;
      }
    }
    saveSell();
  };

  return (
    <Tooltip
      title={tooltip}
      placement="left"
    >
      <span>
        <IconButton
          id="action_control_save"
          disabled={canSave}
          onClick={() => save()}
          className="pos_menu_setDir"
        >
          <Save />
          <span className="font-size-10">Guardar</span>
        </IconButton>
      </span>
    </Tooltip>
  ); };

SaveSellAction.propTypes = {
  tooltip: PropTypes.string,
  enable: PropTypes.bool.isRequired,
  saveSell: PropTypes.func.isRequired,
};
SaveSellAction.defaultProps = {
  tooltip: 'Generar Venta',
};

export default SaveSellAction;
