/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt, faSignInAlt } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import {
  List,
  ListItem,
  ListItemText,
  LinearProgress,
} from '@material-ui/core';
import { formatMoney } from '../../../helpers/tools';
import IconwidthBg from '../../Icons/IconWidthBG/IconWidthBG';
import ListItemGeneric from '../../../views/ClientPage/components/ListItemGeneric/ListItemGeneric';
import { fetchListCashFlow } from '../../../redux/actions/financeActions';

const CashFlow = ({ id }) => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(fetchListCashFlow(0, '', id));
  }, []);
  const data = useSelector(state => state.finance.cashFlow.data);
  const loads = useSelector(state => state.loads);

  return (
    <div className="cotizacion-container">
      { loads.updateIsLoading && (
      <LinearProgress />
      )}
      { !loads.updateIsLoading && data.length === 0 && (
      <List dense>
        <ListItem classes={{ root: 'bg-white' }}>
          <ListItemText
            primary="Sin Flijo de efectivo"
            secondary="El gasto no cuenta con flijo de efectivo"
          />
        </ListItem>
      </List>
      )}
      { !loads.updateIsLoading && data.length > 0 && (
        data.map(item => (
          <ListItemGeneric
            key={item.crmid}
            appendIcon={item.tfde_tipo === 'Ingreso' ? (
              <IconwidthBg primaryColor="#23a1db" width={49} height={70} classes="mr-2" square><FontAwesomeIcon className="font-size-18" icon={faSignInAlt} /></IconwidthBg>
            ) : (
              <IconwidthBg primaryColor="#f05b70" width={49} height={70} classes="mr-2" square><FontAwesomeIcon className="font-size-18" icon={faSignOutAlt} /></IconwidthBg>
            )}
            titleLeft={item.documento || item.entity_label}
            subTitleLeft={item.cuenta}
            titleRight={`$${formatMoney(item.monto)}`}
            subTitleRight={moment(item.modifiedtime).format('DD MMM, h:mm a')}
          />
        ))
      )}
      {/* {

                data.length ? data.map(item => (
                  <ListItemGeneric
                    key={item.crmid}
                    appendIcon={item.tfde_tipo === 'Ingreso' ? (
                      <IconwidthBg primaryColor="#23a1db" width={49} height={70} classes="mr-2" square><FontAwesomeIcon className="font-size-18" icon={faSignInAlt} /></IconwidthBg>
                    ) : (
                      <IconwidthBg primaryColor="#f05b70" width={49} height={70} classes="mr-2" square><FontAwesomeIcon className="font-size-18" icon={faSignOutAlt} /></IconwidthBg>
                    )}
                    titleLeft={item.documento || item.entity_label}
                    subTitleLeft={item.cuenta}
                    titleRight={`$${formatMoney(item.monto)}`}
                    subTitleRight={moment(item.modifiedtime).format('DD MMM, h:mm a')}
                  />
                )) : (
                  <List dense>
                    <ListItem classes={{ root: 'bg-white' }}>
                      <ListItemText
                        primary="Sin Flijo de efectivo"
                        secondary="El gasto no cuenta con flijo de efectivo"
                      />
                    </ListItem>
                  </List>
                )
            } */}

    </div>
  );
};

CashFlow.prototype = {
  data: PropTypes.array.isRequired,
};

export default CashFlow;
