/* eslint-disable array-callback-return */
/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Typography,
  withStyles,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CreatableSelect from 'react-select/creatable';
import { toast } from 'react-toastify';
import ProductAPI from '../../../../../api/ProductAPI';
import useSound from '../../../../../hooks/sound/useSound';

const AddLotes = ({
  product, updateProduct, totalAmount, isLote, isSerial, scannedProduct, barCodes,
}) => {
  const productApi = new ProductAPI();
  const [isFull, setIsFull] = useState(false);
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // const [value, setValue] = useState();
  const [options, setOptions] = useState([]);
  const [productLotes, setProductLotes] = useState([]);
  const [productExistLotes, setProductExistLotes] = useState([]);
  const { playSound } = useSound();

  const ExpansionPanell = withStyles({
    expanded: {
      margin: 'auto',
    },
  })(ExpansionPanel);

  const createLote = (number, qty = 1, processed_quantity = 0, qtyinstock = 0, treebeslotesid = 1) => ({
    number: number.replace(/\W/g, '').toUpperCase(),
    qty,
    quantity: qty,
    processed_quantity,
    qtyinstock,
    treebeslotesid,
  });

  const createOption = (label, isFixed = false) => ({
    label,
    value: label.toLowerCase().replace(/\W/g, ''),
    isFixed,
  });

  const getExistingLotes = async () => {
    const response = await productApi.getLotesByOrderAndProduct(product.id, product.docid);
    const { success, result } = response;
    if (success) {
      setProductExistLotes(result);
    } else {
      toast.error(`Error cargando los lotes del producto ${product.producto}`);
    }
  };

  const getExistingSerie = async (serie) => {
    try {
      const response = await productApi.existSerie(product.id, serie);
      return response;
    } catch (error) {
      toast.error(`Error cargando los lotes del producto ${product.producto}`);
      return null;
    }
  };

  const handleCreate = async (inputValue) => {
    if (!barCodes.includes(inputValue)) {
      console.log('handleCreate', inputValue);
      setIsLoading(true);
      if (isLote && productExistLotes.includes(inputValue)) {
        const updatedProduct = { ...product };
        const loteIndex = updatedProduct.lotesSeries.findIndex(l => l.number === inputValue.toUpperCase());
        if (loteIndex !== -1) {
          updatedProduct.lotesSeries[loteIndex].qty += 1;
          updateProduct(updatedProduct);
          playSound('success');
        }
        return;
      }
      if (isSerial) {
        const response = await getExistingSerie(inputValue);
        // debugger;
        const { success, result } = response;

        if (success) {
          const seriesName = result.map(s => s.number);
          if (seriesName.includes(inputValue.toUpperCase())) {
            toast.warn('El valor ya existe');
            setIsLoading(false);
            playSound('error');
            return;
          }
        } else {
          toast.error('Ha ocurrido un error comprobando la serie');
          setIsLoading(false);
          return;
        }
      }
      const newOption = createOption(inputValue);
      const newLote = createLote(inputValue);
      setIsLoading(false);
      setOptions([...options, newOption]);
      // setValue(newOption);
      const updatedProduct = { ...product };
      updatedProduct.lotesSeries = [...product.lotesSeries, newLote];
      updateProduct(updatedProduct);
      playSound('success');
    }
  };

  const setDefaultLotes = () => {
    // setProductLotes([...product.TreebesLotes]);
    const defaultOptions = [];
    const defaultLotes = [];
    product.lotesSeries.map((l) => {
      const option = createOption(l.number, !!l.treebeslotesid);
      const lote = l.treebeslotesid ? createLote(l.number, l.qty || l.processed_quantity, l.processed_quantity, l.qtyinstock, l.treebeslotesid) : createLote(l.number);
      defaultOptions.push(option);
      defaultLotes.push(lote);
    });
    if (defaultOptions.length) {
      setOptions(defaultOptions);
    }
    if (defaultLotes.length) {
      setProductLotes(defaultLotes);
    }
  };

  const handleChange = (newValues) => {
    if (!newValues) {
      const changeProduct = { ...product, lotesSeries: [] };
      updateProduct(changeProduct);
      setOptions([]);
      setProductLotes([]);
      setIsFull(false);
      return;
    }

    if (newValues && newValues.length !== productLotes.length) {
      const valuesNumber = newValues.map(v => v.label);
      const updatelotes = productLotes.filter(l => valuesNumber.includes(l.number));
      const changeProduct = { ...product, lotesSeries: updatelotes };
      updateProduct(changeProduct);
    }
  };

  const checkIsFull = () => {
    if (product.lotesSeries.length) {
      let loteMount = 0;
      product.lotesSeries.map((l) => {
        loteMount += l.qty || l.processed_quantity;
      });

      if (loteMount >= totalAmount) {
        setIsFull(true);
      } else if (isFull) {
        setIsFull(false);
      }
    }
  };

  const changeLoteQty = (lote, qty) => {
    // debugger;
    const loteIndex = productLotes.findIndex(l => l.number.toUpperCase() === lote.number.toUpperCase());
    const lotesChanges = productLotes;
    lotesChanges[loteIndex].qty += qty;
    const prod = { ...product, lotesSeries: lotesChanges };
    updateProduct(prod);
  };

  useEffect(() => {
    console.log('product', product);
    if (isLote) {
      getExistingLotes();
    }
  }, []);

  useEffect(() => {
    if (product.lotesSeries.length) {
      // debugger;
      setDefaultLotes();
      checkIsFull();
    }
  }, [product]);


  return (
    <div style={{ width: '100%' }}>
      <ExpansionPanell
        classes={{ expanded: { margin: 'auto' } }}
        square
        key={Math.random()}
        expanded={localStorage.getItem('scanedProductId') === product?.id || open}
      >
        <ExpansionPanelSummary
          expandIcon={
            <ExpandMoreIcon onClick={() => setOpen(!open)} />
                }
        >
          <Typography>{isLote ? 'Lotes' : 'Series'}</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <div style={{ width: '100%' }}>
            <CreatableSelect
              isMulti
              isClearable
              isDisabled={isLoading || totalAmount === 0}
              isLoading={isLoading}
              onChange={newValue => handleChange(newValue)}
              onCreateOption={handleCreate}
              isSearchable={!isFull}
              options={options}
              defaultValue={options}
              autoFocus={(scannedProduct && scannedProduct.id === product.id) || product.id === localStorage.getItem('selectedInput')}
              onFocus={() => localStorage.setItem('selectedInput', product.id)}
              // value={value}
            />
            <table style={{ width: '100%' }}>
              <thead>
                <tr>
                  <th style={{ width: '65%' }}>{isLote ? 'Lote' : 'Serie'}</th>
                  {/* <th>Fecha</th> */}
                  {/* <th>Disponible</th> */}
                  <th>Cantidad</th>
                </tr>
              </thead>
              <tbody>
                {productLotes.length > 0 && productLotes.map(lote => (
                  <tr key={Math.random()}>
                    <td>{lote.number}</td>
                    {/*  <td>{moment(lote.date).format('DD/MM/YYYY')}</td> */}
                    {/* <td>{lote.qtyinstock || 0}</td> */}
                    <td>
                      <div style={{
                        display: 'flex', justifyContent: 'start', alignItems: 'center', gap: '5px',
                      }}
                      >
                        {
                            isLote && (
                            <button
                              style={{
                                border: `1px solid ${product.por_entregar <= 0 || lote.qty === 1 || !isLote ? 'gray' : '#24a2db'}`,
                                borderRadius: '2px',
                                backgroundColor: product.por_entregar <= 0 || lote.qty === 1 || !isLote ? 'gray' : '#24a2db',
                                color: 'white',
                                height: '24px',
                              }}
                              disabled={product.por_entregar <= 0 || lote.qty === 1 || !isLote}
                              onClick={() => changeLoteQty(lote, -1)}
                              type="button"
                            >
                              -
                            </button>
                            )
                        }
                        <input
                          style={{
                            width: 80,
                            border: '1px solid gray',
                            borderRadius: '4px',
                            padding: '0 4px',
                            height: '24px',
                          }}
                          type="number"
                          value={lote.qty}
                          min={0}
                          // onChange={() => changeLoteQty(lote, )}
                          disabled
                        />
                        {
                            isLote && (
                            <button
                              style={{
                                border: `1px solid ${!isLote || isFull ? 'gray' : '#24a2db'}`,
                                borderRadius: '2px',
                                backgroundColor: !isLote || isFull ? 'gray' : '#24a2db',
                                color: 'white',
                                height: '24px',
                              }}
                              disabled={!isLote || isFull}
                              onClick={() => changeLoteQty(lote, 1)}
                              type="button"
                            >
                              +
                            </button>
                            )
                        }
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanell>
    </div>
  );
};

export default AddLotes;
