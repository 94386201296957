import React from 'react';
import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';

import {
  Paper,
  Input,
  Button,
  Checkbox,
  InputLabel,
  withStyles,
  CssBaseline,
  FormControl,
  InputAdornment,
  FormControlLabel,
  IconButton,
} from '@material-ui/core';

import {
  LockOutlined,
  PersonOutline,
  Visibility,
  VisibilityOff,
} from '@material-ui/icons';

const styles = theme => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing.unit * 5,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing.unit,
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
  },
  fullWidth: {
    width: '100%',
  },
});

const SignIn = ({ classes, onSubmit }) => {
  const [viewPass, setViewPass] = React.useState(false);
  return (
    <div className="login_container">
      <section
        className="login_form"
      >
        <CssBaseline />
        <Paper style={isMobile ? ({ boxShadow: 'none' }) : ({})} className={classes.paper}>
          <div
            className="login_img"
            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          >
            <img style={{ height: '100%' }} src={`${process.env.PUBLIC_URL}/img/logo.svg`} alt="comercia.io" />
          </div>
          <form className={classes.form} onSubmit={onSubmit}>
            <FormControl margin="normal" fullWidth>
              <InputLabel htmlFor="domain">Cuenta</InputLabel>
              <Input
                name="domain"
                type="domain"
                id="domain"
                autoComplete="url"
              />
            </FormControl>
            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="username">Nombre Usuario</InputLabel>
              <Input
                id="username"
                name="username"
                autoComplete="username"
              // defaultValue={process.env.REACT_APP_DOMAIN === 'pruebas.comercia.io' ? 'demo' : ''}
                autoFocus
                startAdornment={
                (
                  <InputAdornment position="start">
                    <PersonOutline color="primary" />
                  </InputAdornment>
                )
              }
              />
            </FormControl>
            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="password">Contraseña</InputLabel>
              <Input
                id="password"
                name="password"
                type={viewPass ? 'text' : 'password'}
                autoComplete="current-password"
              // defaultValue={process.env.REACT_APP_DOMAIN === 'pruebas.comercia.io' ? 'demo' : ''}
                startAdornment={
                (
                  <InputAdornment position="start">
                    <LockOutlined color="primary" />
                  </InputAdornment>
                )
              }
                endAdornment={(
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="Toggle password visibility"
                      onClick={() => setViewPass(!viewPass)}
                    >
                      {viewPass ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                )}
                onBlur={() => setViewPass(false)}
              />
            </FormControl>
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" name="rememberMe" />}
              label="Recordar?"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Acceder
            </Button>
            <Button
              className="mt-0"
              type="anchor"
              fullWidth
              color="secondary"
              target="_blank"
              href="http://comercia.io/registro"
            >
              ¿No tienes cuenta?
            </Button>
          </form>
        </Paper>
      </section>
    </div>
  );
};

SignIn.propTypes = {
  classes: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default withStyles(styles)(SignIn);
