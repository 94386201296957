/* eslint-disable import/no-cycle */
/* eslint-disable no-return-assign */
/* eslint-disable no-case-declarations */
/* eslint-disable camelcase */
import { toast } from 'react-toastify';
import UserAPI from '../../api/UserAPI';
import { productHelper, userHelper } from '../../helpers';
import { loading, loaded } from './loadActions';
import { defineOrderColumns } from './orderActions';
import { defineProductColumns } from './productActions';
import { setActivePromotions, desactivePromotions } from './cartActions';
// import {dispatch} from 'rxjs/internal/observable/range';

export const AUTHENTICATE_USER = 'AUTHENTICATE_USER';
export const CHANGE_ALM_AUTH_USER = 'CHANGE_ALM_AUTH_USER';
export const CHANGE_GENERIC_BUTTON_CAJA = 'CHANGE_GENERIC_BUTTON_CAJA';
export const CHANGE_GENERIC_BUTTON_HISTORICO = 'CHANGE_GENERIC_BUTTON_HISTORICO';
export const CHANGE_SELL_ORDER_STATUS_AUTH_USER = 'CHANGE_SELL_ORDER_STATUS_AUTH_USER';
export const DEFINE_FILTERS = 'DEFINE_FILTERS';
export const DEFINE_PRINT_SIZE = 'DEFINE_PRINT_SIZE';
export const DEFINE_SELL_TAB = 'DEFINE_SELL_TAB';
export const HANDLE_CART_CONCEPTS = 'HANDLE_CART_CONCEPTS';
export const HANDLE_CHECKOUT_BILL = 'HANDLE_CHECKOUT_BILL';
export const HANDLE_FAST_MODE = 'HANDLE_FAST_MODE';
export const HANDLE_PRINT_AFTER_SELL = 'HANDLE_PRINT_AFTER_SELL';
export const HANDLE_PRINT_SIZE = 'HANDLE_PRINT_SIZE';
export const HANDLE_PROMOTION = 'HANDLE_PROMOTION';
export const HANDLE_SELL_TAB = 'HANDLE_SELL_TAB';
export const RESET_SET_FILTER = 'RESET_SET_FILTER';
export const SELECT_BC = 'SELECT_BC';
export const SELECT_STORE_AUTH_USER = 'SELECT_STORE_AUTH_USER';
export const SET_FAST_COLUMN = 'SET_FAST_COLUMN';
export const SET_FILTER = 'SET_FILTER';
export const SET_GENERAL_COLUMN_DEF = 'SET_GENERAL_COLUMN_DEF';
export const UNAUTHENTICATE_USER = 'UNAUTHENTICATE_USER';
export const VERIFY_AUTH_USER = 'VERIFY_AUTH_USER';
export const CHANGE_CONFIG_TASK_STATUS_FILTER = 'CHANGE_CONFIG_TASK_STATUS_FILTER';

const defaultDomain = 'comercia.io/0/develop';

async function _updateConfig(
  dispatch,
  config,
  newValue,
  type,
  msg = true,
  fetch = true,
) {
  const newConfig = { ...config, ...newValue };
  const posKey = Object.keys(newConfig).filter(x => x.includes('pos_'));
  let raw = {};
  posKey.forEach(x => (raw = { ...raw, [x]: newConfig[x] }));
  raw.sostatus = newConfig.sostatus;
  const userApi = new UserAPI();
  dispatch(loading('profile'));
  try {
    if (fetch) {
      const response = await userApi.updateConfig(raw);
      const { success, message } = response;
      if (msg) toast.success(message);
      if (!success) {
        toast.error(message);
      } else {
        dispatch({
          type,
          ...newValue,
        });
      }
    } else {
      dispatch({
        type,
        ...newValue,
      });
    }
  } catch (err) {
    console.log({ _updateConfig: err });
    toast.error('Error en la API');
  } finally {
    dispatch(loaded('profile'));
  }
}

export function selectStore(
  crmid,
  name,
  image = '',
  direccion,
  telefono,
  email,
  url_web,
  campos,
  direccionid,
  pos = {},
  forzaEnvio = 0,
  currencyid,
) {
  localStorage.setItem('currencyId', currencyid);
  return {
    type: SELECT_STORE_AUTH_USER,
    store: {
      crmid,
      name,
      image,
      direccion,
      telefono,
      email,
      url_web,
      campos,
      direccionid,
      pos,
      forzaEnvio,
      currencyid,
    },
  };
}

export function authenticate(token, account = defaultDomain, isRemember) {
  return async (dispatch, getState) => {
    const userApi = new UserAPI();
    dispatch(loading('authUser'));
    try {
      const domain = account;
      // const domain = userHelper.normalDomain(account);
      const response = await userApi.logIn(domain, token);
      const { success, message, user } = response;
      if (success) {
        toast.success(message);
        user.config = await userHelper.getAuthUserConfigDefault(user.config);
        user.almacenes = await userHelper.getAuthUserAlmacenesDefault(
          user.almacenes,
        );
        user.PriceBooks = user.PriceBooks.sort((a, b) => {
          if (a.entity_label > b.entity_label) return 1;
          if (a.entity_label < b.entity_label) return -1;
          return 0;
        });
        await dispatch({
          type: AUTHENTICATE_USER,
          user: {
            token,
            domain,
            isRemember,
            isAuth: true,
            user,
          },
        });
        const { config } = user;
        if (config.pos_promo_cart === '1') {
          // await dispatch(desactivePromotions());
          await dispatch(setActivePromotions());
        } /* else {
          await dispatch(setActivePromotions());
        } */
        if (!(config.estado_fde && config.estado_mda && config.state)) {
          await dispatch({ type: RESET_SET_FILTER });
          await dispatch({ type: DEFINE_FILTERS, user });
        }
        if (!(config.produColumns && config.orderColumns)) {
          await dispatch(defineProductColumns(config));
          await dispatch(defineOrderColumns(config));
        }
        const type = SET_FAST_COLUMN;
        const {
          product: { columnsDefs },
        } = getState();
        if (config.pos_fast_column) {
          const { pos_fast_column } = config;
          const value = await productHelper.getFieldProductByColumnDefs(
            columnsDefs,
            pos_fast_column,
          );
          const newValue = { post_fast_column: value };
          await _updateConfig(dispatch, config, newValue, type, false);
        } else {
          const value = await productHelper.getFieldProductByColumnDefs(
            columnsDefs,
            'Codigos de Barras',
          );
          const newValue = { post_fast_column: value };
          await _updateConfig(dispatch, config, newValue, type, false);
        }
        if (user.almacenes.length === 1) {
          const almacen = user.almacenes[0];
          await dispatch(
            selectStore(
              almacen.crmid,
              almacen.almacen,
              almacen.images[0] !== undefined ? almacen.images[0] : '',
              almacen.domicilio,
              almacen.telefono,
              almacen.email,
              almacen.url_web,
              almacen.talm_tpv_campos_en_ticket,
              almacen.treebesdireccionenvioid,
              {},
              almacen.pos_forzaenvio,
              almacen.pos_currency_id,
              almacen.treebesalmid_dev,
              almacen.treebesalmid_env,
              almacen.treebesalmid_dev_label,
              almacen.treebesalmid_env_label,
            ),
          );
        }
      } else {
        toast.error(message);
      }
    } catch (err) {
      console.log({ authenticate: err });
      toast.error('Error en la API');
    } finally {
      dispatch(loaded('authUser'));
    }
  };
}

export function verifyAuthUser() {
  return async (dispatch) => {
    const userApi = new UserAPI();
    try {
      const response = await userApi.verify();
      const { success, message, user } = response;
      if (!success) {
        toast.error(message);
      }
      dispatch({
        type: VERIFY_AUTH_USER,
        user: {
          isAuth: success,
          user,
        },
      });
    } catch {
      dispatch({
        type: VERIFY_AUTH_USER,
        user: {
          isAuth: false,
        },
      });
      toast.error('Error en la API');
    }
  };
}

export function unauthenticate() {
  return {
    type: UNAUTHENTICATE_USER,
  };
}

export function changeAlmAuthUser(pos_auto_alm) {
  return async (dispatch, getState) => {
    const { authUser } = getState();
    const { config } = authUser.user;
    const newValue = { pos_auto_alm };
    const type = CHANGE_ALM_AUTH_USER;
    _updateConfig(dispatch, config, newValue, type);
  };
}

export function changeSellOrderStatusAuthUser(sostatus) {
  return async (dispatch, getState) => {
    const { authUser } = getState();
    const { config } = authUser.user;
    const newValue = { sostatus };
    const type = CHANGE_SELL_ORDER_STATUS_AUTH_USER;
    _updateConfig(dispatch, config, newValue, type);
  };
}

export function changeGenericButtonCaja(pos_botoncaja) {
  return async (dispatch, getState) => {
    const { authUser } = getState();
    const { config } = authUser.user;
    const newValue = { pos_botoncaja };
    const type = CHANGE_GENERIC_BUTTON_CAJA;
    _updateConfig(dispatch, config, newValue, type);
  };
}

export function changeGenericButtonHistorico(pos_botonventas) {
  return async (dispatch, getState) => {
    const { authUser } = getState();
    const { config } = authUser.user;
    const newValue = { pos_botonventas };
    const type = CHANGE_GENERIC_BUTTON_HISTORICO;
    _updateConfig(dispatch, config, newValue, type);
  };
}

export function handleCartConcepts(pos_cart_concepts) {
  return async (dispatch, getState) => {
    const { authUser } = getState();
    const { config } = authUser.user;
    const newValue = { pos_cart_concepts };
    const type = HANDLE_CART_CONCEPTS;
    _updateConfig(dispatch, config, newValue, type);
  };
}

export function setOrderFilter(option) {
  return async (dispatch, getState) => {
    const { authUser } = getState();
    const { config } = authUser.user;
    const filtros = option.values.map(x => x.value);
    const { filter } = option;
    let newValue = {};
    switch (filter) {
      case 'estado_fde':
        const pos_filtrofde = filtros.join(' |##| ');
        newValue = { option, pos_filtrofde };
        break;
      case 'estado_mda':
        const pos_filtromda = filtros.join(' |##| ');
        newValue = { option, pos_filtromda };
        break;
      default:
        const pos_filtrostate = filtros.join(' |##| ');
        newValue = { option, pos_filtrostate };
    }
    const type = SET_FILTER;
    _updateConfig(dispatch, config, newValue, type);
  };
}

export function setGeneralColumnDefs(defs, product, fetch = true) {
  return async (dispatch, getState) => {
    const { authUser } = getState();
    const { config } = authUser.user;
    let columns = [];
    defs.forEach((parent) => {
      const raw = parent.children.filter(x => !x.hide);
      const arr = raw.map(x => x.headerName);
      columns = [...columns, ...arr];
    });
    let newValue = {};
    if (product) {
      const pos_cols_prods = columns.join(' |##| ');
      newValue = { defs, product, pos_cols_prods };
    } else {
      const pos_cols_ventas = columns.join(' |##| ');
      newValue = { defs, product, pos_cols_ventas };
    }
    const type = SET_GENERAL_COLUMN_DEF;
    _updateConfig(dispatch, config, newValue, type, false, fetch);
  };
}

export function handleFastMode(mode = null) {
  return async (dispatch) => {
    dispatch({ type: HANDLE_FAST_MODE, mode });
  };
}

export function handleSellTab(pos_sell_tab = false) {
  return async (dispatch, getState) => {
    const { authUser } = getState();
    const { config } = authUser.user;
    const newValue = { pos_sell_tab };
    _updateConfig(dispatch, config, newValue, HANDLE_SELL_TAB, false);

    // dispatch({ type: HANDLE_SELL_TAB, pos_sell_tab });
  };
}
/*
export function handleSellTab(mode = false) {
  console.log('mode', mode);
  return async (dispatch, getState) => {
    await dispatch({ type: HANDLE_SELL_TAB, mode });

    const { authUser } = getState();
    const { config } = authUser.user;
    const newValue = { pos_print_size };
    _updateConfig(dispatch, config, newValue, HANDLE_PRINT_SIZE, false);
  };
} */

export function handlePrintSize(pos_print_size = 7) {
  return async (dispatch, getState) => {
    const { authUser } = getState();
    const { config } = authUser.user;
    const newValue = { pos_print_size };
    _updateConfig(dispatch, config, newValue, HANDLE_PRINT_SIZE, false);
  };
}

export function handleCheckoutBill(pos_checkout_bill = 'Preguntar') {
  return async (dispatch, getState) => {
    const { authUser } = getState();
    const { config } = authUser.user;
    const newValue = { pos_checkout_bill };
    _updateConfig(dispatch, config, newValue, HANDLE_PRINT_SIZE, false);
  };
}

export function handleChangePromotions(pos_promo_cart) {
  return async (dispatch, getState) => {
    const { authUser } = getState();
    const { config } = authUser.user;
    const newValue = { pos_promo_cart };
    _updateConfig(dispatch, config, newValue, HANDLE_PROMOTION, false);
  };
}

export function handlePrintAfterSell(pos_print_after_sell = 0) {
  return async (dispatch) => {
    dispatch({ type: HANDLE_PRINT_AFTER_SELL, pos_print_after_sell });
  };
}

export function selectBC(crmid, parentMethod) {
  return async (dispatch) => {
    dispatch({
      type: SELECT_BC,
      crmid,
      parentMethod,
    });
  };
}

export function changeConfigTaskStatus(pos_filter_projecttaskstatus) {
  return async (dispatch, getState) => {
    const { authUser } = getState();
    const { config } = authUser.user;
    const newValue = { pos_filter_projecttaskstatus };
    const type = CHANGE_CONFIG_TASK_STATUS_FILTER;
    _updateConfig(dispatch, config, newValue, type);
  };
}
