import { IconButton, List, Paper } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import useOnClickOutside from '../../../hooks/useClickOutside';

const MenuBase = ({
  menuIcon, children, styles, clickOutside = true,
}) => {
  const ref = useRef();
  const [open, setOpen] = useState(false);
  useOnClickOutside(ref, () => {
    if (clickOutside) {
      setOpen(false);
    }
  });

  const handleEsc = (event) => {
    if (event.keyCode === 27) {
      setOpen(false);
    }
  };

  useEffect(() => {
    // Close when scape key is pressed listener
    document.addEventListener('keydown', handleEsc, false);
    return () => {
      document.removeEventListener('keydown', handleEsc, false);
    };
  }, []);

  return (
    <div style={{ position: 'relative' }} ref={ref}>
      <IconButton
        size="small"
        square="true"
        onClick={() => setOpen(!open)}
        style={{ backgroundColor: open ? '#e5e5e5' : 'transparent', borderRadius: 5 }}
      >
        {menuIcon}
      </IconButton>
      {
          open && (
            <Paper style={{
              position: 'absolute', zIndex: 9, ...styles,
            }}
            >
              <List component="nav">
                {children}
              </List>
            </Paper>
          )
        }
    </div>
  ); };

// PropsTypes
MenuBase.propTypes = {
  menuIcon: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  styles: PropTypes.object,
  clickOutside: PropTypes.bool,
};

MenuBase.defaultProps = {
  styles: {},
  clickOutside: true,
};

export default MenuBase;
