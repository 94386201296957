/* eslint-disable import/no-cycle */
/* eslint-disable no-console */
import { toast } from 'react-toastify';

import BalanceAPI from '../../api/BalanceAPI';
import { loading, loaded } from './loadActions';
import { openModal, closeModal } from './modalActions';
import { balanceHelper } from '../../helpers';

export const GET_ACC = 'GET_ACC';
export const GET_CC = 'GET_CC';
export const GET_MCC = 'GET_MCC';
export const SAVE_ACC = 'SAVE_ACC';
export const SAVE_CC = 'SAVE_CC';
export const SAVE_MCC = 'SAVE_MCC';
export const SET_BALANCE = 'SET_BALANCE';
export const SET_TICKET = 'SET_TICKET';

/**
 * Set ticket information
 *
 * @param   {[Object]}  info  [info Ticket Data]
 *
 */
export function setTicket(info) {
  return async (dispatch) => {
    await dispatch(loading('dialogACC'));
    try {
      await dispatch({
        type: SET_TICKET,
        info,
      });
      await dispatch(openModal('ticketCC'));
    } catch (setTicketErr) {
      console.log({ setTicketErr });
    } finally {
      await dispatch(loaded('balance'));
    }
  };
}

export function fetchBalance() {
  return async (dispatch) => {
    dispatch(loading('balance'));
    try {
      const balance = new BalanceAPI();
      const data = await balance.getBalance();
      dispatch({
        type: SET_BALANCE,
        data,
      });
    } catch (err) {
      console.log('fetchBalance err: ', err);
    } finally {
      dispatch(loaded('balance'));
    }
  };
}


/**
 *
 * @param {boolean} flag ['' to getCC and true in case to setCC]
 */
export function CC(flag = '') {
  return async (dispatch) => {
    dispatch(loading('dialogCC'));
    try {
      const balance = new BalanceAPI();
      const info = await balance.getCC(flag);
      dispatch({
        type: GET_CC,
        info,
      });
    }

    catch (err) {
      console.log('getCC err: ', err);
    }

    finally {
      dispatch(loaded('dialogCC'));
    }
  };
}

export function ACC() {
  return async (dispatch) => {
    dispatch(loading('dialogACC'));
    try {
      const balance = new BalanceAPI();
      const info = await balance.getACC();
      dispatch({
        type: GET_ACC,
        info,
      });
    } catch (err) {
      console.log('getACC err: ', err);
    } finally {
      dispatch(loaded('dialogACC'));
    }
  };
}

export function MCC() {
  return async (dispatch) => {
    dispatch(loading('dialogMCC'));
    try {
      const balance = new BalanceAPI();
      const info = await balance.getMCC();
      dispatch({
        type: GET_MCC,
        info,
      });
    } catch (err) {
      console.log('getMCC err: ', err);
    } finally {
      dispatch(loaded('dialogMCC'));
    }
  };
}

export function saveCC(data, mcc = false) {
  return async (dispatch, getState) => {
    const { selectedCheckboxes, unselectedCheckboxes } = data;
    dispatch({ type: SAVE_CC });
    dispatch(loading('dialogCC'));
    try {
      const balanceApi = new BalanceAPI();
      const response = await balanceApi.saveCC(data);
      const { datos } = response;
      const { pendientes, solicitados, referencia } = datos;
      if (pendientes !== undefined && solicitados !== undefined) {
        if (mcc) {
          toast.success('Corte de Caja Modificado');
          await dispatch(MCC());
          await dispatch(CC());
          await dispatch(fetchBalance());
        } else {
          toast.success('Corte de Caja Exitoso');
          const { balance, authUser: { user } } = getState();
          let info;
          const response = await balanceApi.getMCC();
          const { success, datos } = response;
          if (success) {
            const cortesKeys = Object.keys(datos).sort();
            const lastCorte = datos[cortesKeys[cortesKeys.length - 1]];

            const newBalance = {
              title: `${user.last_name} - ${referencia}`,
              data: lastCorte,
            };

            info = newBalance;
          } else {
            info = balanceHelper.transformCCInfoToTicket(selectedCheckboxes, balance.cc, `${user.last_name} - ${referencia}`);
          }
          await dispatch(fetchBalance());
          await dispatch(setTicket(info));
        }
      }
      else {
        toast.error('Error En El Corte De Caja');
      }
    }

    catch (err) {
      console.log('saveCC err: ', err);
    }

    finally {
      dispatch(loaded('dialogCC'));
      dispatch(closeModal('dialogCC'));
    }
  };
}

export function saveACC(data) {
  return async (dispatch, getState) => {
    const { selectedCheckboxes, title, id } = data;
    dispatch({ type: SAVE_ACC });
    dispatch(loading('dialogACC'));
    try {
      const balanceApi = new BalanceAPI();
      const response = await balanceApi.saveACC(selectedCheckboxes);
      const { success } = response;
      if (success) {
        toast.success('Corte De Caja Recibido');
        const { balance } = getState();
        const info = balanceHelper.transformACCInfoToTicket(selectedCheckboxes, balance.acc, title, id);
        await dispatch(fetchBalance());
        await dispatch(setTicket(info));
      }
      else {
        toast.error('Error En El Corte De Caja');
      }
    }

    catch (err) {
      console.log('saveACC err: ', err);
    }

    finally {
      dispatch(loaded('dialogACC'));
      dispatch(closeModal('dialogACC'));
    }
  };
}
