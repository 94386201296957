/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileInvoice, faFileInvoiceDollar } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import {
  ListItem, ListItemText, Button, List, ListItemIcon, Collapse,
} from '@material-ui/core';
import { formatMoney, getColorStatusQuote } from '../../../../../helpers/tools';
import Iconwidthbg from '../../../../../components/Icons/IconWidthBG/IconWidthBG';
// eslint-disable-next-line import/no-cycle
import { selectOrder } from '../../../../../redux/actions/orderActions';
import './css/quotes.style.scss';
import { selectCotizacion } from '../../../../../redux/actions/cotizarActions';
import { changeTab } from '../../../../../redux/actions/tabActions';
import ShareModal from '../../../../../components/Share/components/ShareModal';
import appModules from '../../../../../constant/appModules';

const Cotizacion = ({ data }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const loads = useSelector(state => state.loads);

  const [selectedQuote, setSelectedQuote] = React.useState(null);
  const [open, setOpen] = React.useState(false);

  const isLoading = Boolean(loads && loads.emailTemplateIsLoading);
  // eslint-disable-next-line react/prop-types
  const title = `Compartir Cotización ${selectedQuote?.quote_no}`;

  const selectQuote = async (quote) => {
    if (selectedQuote && quote.crmid === selectedQuote.crmid) {
      setSelectedQuote(null);
    } else {
      setSelectedQuote(quote);
    }
  };

  const openQuotesDetail = async (crmid) => {
    await history.push('/pos/cotizacio');
    await dispatch(changeTab('posType', 'Cotizaciones'));
    await dispatch(selectCotizacion(crmid));
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className="cotizacion-container">
      <div>
        <List classes={{ root: 'bg-white p-0' }}>
          {
          data.length ? data.map(quote => (
            <>
              <ListItem divider className="quote" key={quote.crmid} onClick={() => selectQuote(quote)}>
                <ListItemText
                  classes={{ primary: 'quote__title', secondary: 'quote__subtitle' }}
                  primary={quote.quote_no}
                  secondary={(
                    <div>
                      <span>{quote.accountname}</span>
                      <br />
                      <span>{moment(quote.modifiedtime).format('DD MMM YYYY h:mm a')}</span>
                    </div>
                  )}
                />
                <ListItemText classes={{ primary: 'quote__money' }} primary={`$${formatMoney(quote.hdnGrandTotal)}`} />
                <ListItemIcon className="m-0">
                  <Iconwidthbg
                    primaryColor={getColorStatusQuote(quote.quotestage)}
                    secondaryColor="white"
                    width={27}
                    height={27}
                    classes="ml-2"
                    enabled
                    square
                    rounded
                    actionClick={() => {}}
                  >
                    <FontAwesomeIcon style={{ fontSize: 15 }} icon={faFileInvoiceDollar} />
                  </Iconwidthbg>
                </ListItemIcon>
              </ListItem>
              <Collapse in={selectedQuote && selectedQuote.crmid === quote.crmid} style={{ backgroundColor: '#f7f7f7' }}>
                <List>
                  <ListItem divider className="quote">
                    <ListItemText style={{ width: '50%' }} classes={{ secondary: 'quote-detail__subtitle quote-detail__subtitle-left' }} secondary="Estado: " />
                    <ListItemText
                      classes={{ secondary: 'quote-detail__subtitle quote-detail__subtitle-right' }}
                      secondary={
                        quote.quotestage
                      }
                    />
                    <ListItemIcon className="m-0">
                      <Iconwidthbg
                        primaryColor={getColorStatusQuote(quote.quotestage)}
                        secondaryColor="white"
                        width={27}
                        height={27}
                        classes="ml-2"
                        enabled
                        square
                        rounded
                        actionClick={() => {}}
                      >
                        <FontAwesomeIcon style={{ fontSize: 15 }} icon={faFileInvoiceDollar} />
                      </Iconwidthbg>
                    </ListItemIcon>
                  </ListItem>
                  <ListItem divider className="quote">
                    <ListItemText style={{ width: '50%' }} classes={{ secondary: 'quote-detail__subtitle quote-detail__subtitle-left' }} secondary="Cliente:" />
                    <ListItemText classes={{ secondary: 'quote-detail__subtitle quote-detail__subtitle-right' }} secondary={quote.accountname} />
                  </ListItem>
                  <ListItem divider className="quote">
                    <ListItemText style={{ width: '50%' }} classes={{ secondary: 'quote-detail__subtitle quote-detail__subtitle-left' }} secondary="Asignado a:" />
                    <ListItemText classes={{ secondary: 'quote-detail__subtitle quote-detail__subtitle-right' }} secondary={quote.assigned_user} />
                  </ListItem>
                  <ListItem className="quote">
                    <Button style={{ width: '100%', marginRight: '20px' }} variant="contained" color="primary" onClick={() => openQuotesDetail(quote.crmid)}>
                      Detalles
                    </Button>

                    <Button style={{ width: '100%' }} variant="contained" color="primary" onClick={() => handleClickOpen()}>
                      Compartir
                    </Button>
                  </ListItem>
                </List>
              </Collapse>
            </>
          ))
            : () => (
              <ListItem>
                <ListItemText primary="Sin cotizaciones" />
              </ListItem>
            )
        }
        </List>
      </div>

      <ShareModal
        open={open}
        title={title}
        handleClose={handleClose}
        order={selectedQuote || {}}
        template={appModules.Quotes}
        isLoading={isLoading}
      />
    </div>
  ); };

Cotizacion.prototype = {
  data: PropTypes.array.isRequired,
};
export default Cotizacion;
