/* eslint-disable camelcase */
import { paginate, wordInString } from './tools';

export const paginateOrders = (ordersArray, totalItemsForPage, index = 0) => {
  const _paginateOrders = paginate(ordersArray, totalItemsForPage, index);
  const { page, indexPage, totalPages } = _paginateOrders;
  const paginatedOrders = page;
  const actualPage = indexPage + 1;
  return {
    paginatedOrders,
    actualPage,
    totalPages,
  };
};

export const findOrders = (orders, word) => {
  const result = orders.filter(order => wordInString(word, order.crmid)
  || wordInString(word, order.estado_fde)
  || wordInString(word, order.salesorder_no)
  || wordInString(word, order.estado_mda)
  || wordInString(word, order.sostatus));
  return result;
};

/**
 * Return orders filtering by categories
 * @param {Array} orders Array of all the elements to be filter
 * @param {Array} words Array of objects that contain word of filtering and attribute
 */
export const filterOrders = (orders, words) => {
  let elements = [];
  words.forEach((item) => {
    const res = orders.filter(order => wordInString(item.value, order[`${item.word}`]));
    elements = res;
  });
  return elements;
};

export const filterOrderByAttribute = (orders, word, attribute) => {
  const result = orders.filter(order => wordInString(word, order[attribute]));
  return result;
};

export const shouldCharge = (balance) => {
  if (Number.isNaN(balance)) {
    return true;
  }
  return Boolean(balance > 0);
};

export const shouldDeliver = _mda => (
  _mda.toLowerCase() !== 'entregado'
);

export const shouldRefoundMoney = (balance, total) => (
  balance < total
);

export const shouldRefoundProducts = _fde => (
  _fde.toLowerCase() === 'entregado' || _fde.toLowerCase() === 'parcialmente entregado'
);

/**
 * Return the items sorted by params
 * @param {Array} items The collection of items that will be sorted
 * @param {Object} filters Params that will be applyed in the array
 */
export const applyFilterOrder = (items, filters) => {
  const {
    fdeFilterValue,
    mdaFilterValue,
    stateFilterValue,
  } = filters;
  const enableFde = Boolean(fdeFilterValue.length > 0);
  const enableMda = Boolean(mdaFilterValue.length > 0);
  const enableSta = Boolean(stateFilterValue.length > 0);
  const res = items.filter((item) => {
    const dataFde = fdeFilterValue.some(filter => (filter.value !== [] && filter.value === item.estado_fde));
    const fde = enableFde ? (dataFde) : (false);
    const dataMda = mdaFilterValue.some(filter => (filter.value !== [] && filter.value === item.estado_mda));
    const mda = enableMda ? (dataMda) : (false);
    const dataState = stateFilterValue.some(filter => (filter.value !== [] && filter.value === item.sostatus));
    const sta = enableSta ? (dataState) : (false);
    if (fde && mda && sta) {
      return true;
    } return false;
  });
  return res;
};

const defaultEstadoFde = () => [
  {
    label: 'Sin Pago/Cobro',
    value: 'Sin Pago/Cobro',
    column: 'estado_fde',
  },
  {
    label: 'Parcialmente Pagado/Cobrado',
    value: 'Parcialmente Pagado/Cobrado',
    column: 'estado_fde',
  },
  {
    label: 'Pagado/Cobrado',
    value: 'Pagado/Cobrado',
    column: 'estado_fde',
  },
];

const defaultEstadoMda = () => [
  {
    label: 'Sin Entregar',
    value: 'Sin Entregar',
    column: 'estado_mda',
  },
  {
    label: 'Parcialmente Entregado',
    value: 'Parcialmente Entregado',
    column: 'estado_mda',
  },
  {
    label: 'Entregado',
    value: 'Entregado',
    column: 'estado_mda',
  },
];

const defaultState = arr => arr.map(x => ({ label: x.value, value: x.value, column: 'state' }));

export const defineFiltersFields = (user) => {
  const { config, sostatus } = user;
  const { pos_filtrofde, pos_filtromda, pos_filtrostate } = config;
  const estado_fde = pos_filtrofde
    ? pos_filtrofde.split(' |##| ').map(x => ({ label: x, value: x, column: 'estado_fde' }))
    : defaultEstadoFde();
  const estado_mda = pos_filtromda
    ? pos_filtromda.split(' |##| ').map(x => ({ label: x, value: x, column: 'estado_mda' }))
    : defaultEstadoMda();
  const state = pos_filtrostate
    ? pos_filtrostate.split(' |##| ').map(x => ({ label: x, value: x, column: 'state' }))
    : defaultState(sostatus);
  const res = {
    ...config,
    estado_fde,
    estado_mda,
    state,
  };
  return res;
};

/**
 * Return a discount state by a order
 * @param {Object} foundOrder Order to get discount values
 */
export const getDiscountStateFromOrder = (foundOrder) => {
  const {
    hdnSubTotal,
    hdnDiscountAmount,
    hdnDiscountPercent,
  } = foundOrder;
  const moneyValue = (parseFloat(hdnDiscountAmount.replace(/,/g, '')) || 0);
  const perceValue = (parseFloat(hdnDiscountPercent) || 0);
  const totalToPay = Number.parseFloat(hdnSubTotal);
  const totalPercentage = totalToPay * (perceValue / 100);
  const totalDiscount = totalPercentage + moneyValue;
  return {
    fixed: moneyValue,
    percentage: perceValue,
    total: totalDiscount,
  };
};

export const getFdeFilters = (conf = null, tag) => {
  if (conf) {
    const arr = conf.includes(' |##| ') ? conf.split(' |##| ') : [conf];
    let elements = [];
    arr.forEach((x) => {
      const str = `${tag}${x.split(' ').join('%20')}`;
      elements = [...elements, str];
    });
    elements = [...elements, `${tag}N/A`];
    return elements.join('&');
  }
  return '';
};

export const getStateFilters = (conf) => {
  if (conf && conf !== '') {
    const arr = conf.split(' |##| ');
    const elements = arr.map((x) => {
      switch (x) {
        case 'Creado':
        case 'Created':
          return 'sostatus[]=Created';
        case 'Apartado':
        case 'Approved':
          return 'sostatus[]=Approved';
        case 'Autorizado':
        case 'Delivered':
          return 'sostatus[]=Delivered';
        case 'Cancelado':
        case 'Cancelled':
          return 'sostatus[]=Cancelled';
        default:
          return '';
      }
    });
    return elements.join('&');
  }
  return '';
};

export default {
  applyFilterOrder,
  defineFiltersFields,
  filterOrders,
  findOrders,
  getDiscountStateFromOrder,
  getFdeFilters,
  getStateFilters,
  paginateOrders,
  shouldDeliver,
  shouldRefoundMoney,
  shouldRefoundProducts,
};
