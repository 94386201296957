import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPhone,
  faMobile,
} from '@fortawesome/free-solid-svg-icons';
import EmptyContact from './emptyContact';

const Phone = (contact) => {
  const {
    phone, mobile, firstname, lastname, crmid,
  } = contact.contact;

  return (
    <>
      <>
        {phone ? (
          <a href={`tel:${phone}`} target="_blank" rel="noopener noreferrer" key={`${crmid}${phone}`}>
            <ListItem button classes={{ root: 'py-0 pl-0' }}>
              <ListItemAvatar>
                <Avatar variant="square" className="modal-avatar">
                  <FontAwesomeIcon style={{ fontSize: 26 }} icon={faPhone} />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={`${firstname} ${lastname}`} secondary={phone} classes={{ primary: 'modal-primary-text', secondary: 'modal-secondary-text' }} />
            </ListItem>
          </a>
        )
          : (
            <EmptyContact action="phone" />
          )}

        {
            mobile ? (
              <a href={`tel:${mobile}`} key={`${mobile}${crmid}`} target="_blank" rel="noopener noreferrer">
                <ListItem button classes={{ root: 'py-0 pl-0' }}>
                  <ListItemAvatar>
                    <Avatar variant="square" className="modal-avatar">
                      <FontAwesomeIcon style={{ fontSize: 26 }} icon={faMobile} />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={`${firstname} ${lastname}`} secondary={mobile} classes={{ primary: 'modal-primary-text', secondary: 'modal-secondary-text' }} />
                </ListItem>
              </a>
            ) : null
          }
      </>
    </>
  );
};
Phone.prototype = {
  contact: PropTypes.object,
};
export default Phone;
