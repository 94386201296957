import React from 'react';
import PropTypes from 'prop-types';
/* MaskedInput */
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';


export default function TextMaskCustom(props) {
  const { inputRef, ...other } = props;

  const dollarMask = createNumberMask({
    prefix: '$ ',
    suffix: '',
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: ',',
    allowDecimal: true,
    decimalSymbol: '.',
    decimalLimit: 4,
    allowNegative: false,
    allowLeadingZeroes: false,
    integerLimit: null,
  });

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={dollarMask}
      placeholderChar={'\u2000'}
    />
  );
}

TextMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
};
