/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
export const transformBalance = (datos) => {
  const format = Object.keys(datos).map((key) => {
    const campos = datos[key];
    const { TOTAL, ...sobras } = campos;

    const metodos = Object.keys(sobras).map(id => ({
      label: id,
      value: sobras[id],
    }));

    return { label: key, TOTAL, metodos };
  });

  return format;
};

export const transformCCInfo = (datos) => {
  const newDatos = Object.keys(datos).map((key) => {
    const type = { ...datos[key] };

    type.Efectivo = Object.keys(type.Efectivo).map((optionKey) => {
      const field = type.Efectivo[optionKey];
      if (typeof field === 'object') {
        field.id = optionKey;
        return { ...field };
      }
      return null;
    }).filter(el => el != null);

    return { ...type, label: key };
  });

  return newDatos;
};


const updateTotalAmount = (movimientos = []) => {
  let monto = 0;
  movimientos.forEach((mov) => {
    monto += mov?.total;
  });

  return monto;
};

const updateDetallesMonto = (detalles = []) => {
  let monto = 0;
  detalles.forEach((detalle) => {
    monto += parseFloat(detalle?.monto);
  });

  return monto;
};

export const transformCCInfoToTicket = (arr, state, title) => {
  const elements = [...arr];

  let data = [];
  state.forEach((el) => {
    let movimientos = [];
    el.movimientos.forEach((mov) => {
      let detalles = [];
      detalles = mov.detalles.filter(x => elements.includes(x.id) || x.mantener_efe === true);
      if (detalles.length > 0) {
        // movimientos = [...movimientos, { ...mov, detalles }];
        movimientos = [
          ...movimientos,
          {
            ...mov,
            cantidad: detalles.length,
            total: updateDetallesMonto(detalles),
            detalles,
          },
        ];
      }
    });
    if (movimientos.length > 0) {
      data = [...data, ({ ...el, movimientos })];
    }
  });
  // Fix data values
  const { movimientos } = data[0];
  data = {
    ...data[0],
    cantidad: movimientos.length,
    total: updateTotalAmount(movimientos),
  };
  return ({ data: [data], title });
};


export const transformACCInfoToTicket = (arr, state, title, id) => {
  let data = [];
  const elements = [...arr];
  const elementUser = state.find(el => el.id === id);
  if (elementUser) {
    elementUser.monedas.forEach((el) => {
      let movimientos = [];
      el.movimientos.forEach((mov) => {
        let detalles = [];
        detalles = mov.detalles.filter(x => elements.includes(x.id));
        if (detalles.length > 0) {
          movimientos = [...movimientos, { ...mov, detalles }];
        }
      });
      if (movimientos.length > 0) {
        data = [...data, ({ ...el, movimientos })];
      }
    });
  }
  return ({ data, title });
};

export const validateCheckbox = (checkboxes, id) => (
  checkboxes[id] === undefined ? true : checkboxes[id]
);

export default {
  transformACCInfoToTicket,
  transformBalance,
  transformCCInfo,
  transformCCInfoToTicket,
};
