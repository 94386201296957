/* eslint-disable no-case-declarations */
/* eslint-disable import/no-cycle */

import { SET_SHIPPING_DATA, CLEAR_SHIPPING_DATA, DELETE_SHIPPING_DATA } from '../types/shippingActions.type';


const getDefaultState = () => ({
  shippingList: [],
});


export default function (state = getDefaultState(), action) {
  const { type } = action;
  switch (type) {
    case SET_SHIPPING_DATA:
      return { ...state, shippingList: [...state.shippingList, action.payload] };
    case DELETE_SHIPPING_DATA:
      const newShipping = state.shippingList.filter(shipping => shipping.id !== action.payload.id);
      return { ...state, shippingList: [...newShipping] };
    case CLEAR_SHIPPING_DATA:
      return { ...state, shippingList: [] };
    default:
      return state;
  }
}
