import { useState, useEffect } from 'react';
import OrderAPI from '../../api/OrderAPI';

export default function useOrder(orderId, isOrder = true) {
  const orderApi = new OrderAPI();
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const getOrder = async () => {
    setIsLoading(true);
    const response = await orderApi.getOrder(orderId);
    const { success, result } = response;

    if (success) {
      setData(result);
    } else {
      setError('Mientras cargabamos la orden ocurrió un error');
    }

    setIsLoading(false);
  };

  const updateOrderAddress = async (invoiceAddress = null, shippingAddress = null) => {
    setIsLoading(true);
    const response = await orderApi.updateOrderAddress(isOrder, orderId, invoiceAddress, shippingAddress);
    const { success, result } = response;

    if (success) {
      setData(result);
    } else {
      setError('Mientras cargabamos la orden ocurrió un error');
    }

    setIsLoading(false);
  };

  useEffect(() => {
    getOrder();
  }, [orderId]);

  return {
    data, isLoading, error, updateOrderAddress, getOrder,
  };
}
