/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  setOrderFilter,
  changeGenericButtonHistorico,
} from '../../../../../../redux/actions/authUserActions';
import {
  configSellColumnDef,
} from '../../../../../../redux/actions/orderActions';

/* Components */
import ConfVenta from '../components/ConfVenta';
import ConfFiltrosVentas from '../components/ConfFiltrosVentas';
import ConfColumnasVentas from '../components/ConfColumnasVentas';

const Ventas = ({
  loads,
  order,
  authUser,
  dispatchColumnDef,
  dispatchSetOrderFilter,
  dispatchChangeGenericButtonHistorico,
}) => (
  <div className="settings_general">
    <ConfVenta
      loads={loads}
      authUser={authUser}
      dispatchChangeGenericButtonHistorico={dispatchChangeGenericButtonHistorico}
    />
    {/* <ConfColumnasVentas
      loads={loads}
      order={order}
      authUser={authUser}
      dispatchColumnDef={dispatchColumnDef}
    />
    <ConfFiltrosVentas
      loads={loads}
      authUser={authUser}
      dispatchSetOrderFilter={dispatchSetOrderFilter}
    /> */}
  </div>
);

Ventas.propTypes = {
  loads: PropTypes.object.isRequired,
  order: PropTypes.object.isRequired,
  authUser: PropTypes.object.isRequired,
  dispatchColumnDef: PropTypes.func.isRequired,
  dispatchSetOrderFilter: PropTypes.func.isRequired,
  dispatchChangeGenericButtonHistorico: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  order: state.order,
  loads: state.loads,
  authUser: state.authUser,
});

const mapDispatchToProps = dispatch => ({
  dispatchColumnDef: field => dispatch(configSellColumnDef(field)),
  dispatchSetOrderFilter: option => dispatch(setOrderFilter(option)),
  dispatchChangeGenericButtonHistorico: label => dispatch(changeGenericButtonHistorico(label.value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Ventas);
