/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Tooltip,
} from '@material-ui/core';
import { toolsHelper } from '../../../../helpers';
import Flag from '../../../Flag';
import SatLogo from '../../../Cart/components/controls/components/SatLogo';
import DownloadAPI from '../../../../api/DownloadAPI';
import { useSelector } from 'react-redux';

const ListFacturasItem = ({ item }) => {
  const { domain } = useSelector(state => state.authUser);
  const date = new Date(item.fechatimbrado);
  const day = date.toLocaleDateString('es-ES', { day: 'numeric' });
  const month = date.toLocaleDateString('es-ES', { month: 'long' });
  const clock = date.toLocaleDateString('es-ES', {
    hour: 'numeric',
    minute: 'numeric',
  });
  const dateDisplay = `${day} ${month} ${clock.substring(10, clock.length)}`;

  const setBackground = () => {
    switch (item.estadosat) {
      case 'Vigente':
        return '#23A1DB';
      case 'Cancelado':
        return '#F05B70';
      default:
        return '#F6F6F7';
    }
  };

  const setColor = () => {
    switch (item.estadosat) {
      case 'Vigente':
        return '#FFFFFF';
      case 'Cancelado':
        return '#FFFFFF';
      default:
        return '#B6BEC6';
    }
  };

  const downloadFile = async (item) => {
    const name = item.xml.split('/').pop();
    const downloadApi = new DownloadAPI();
    const url = `https://${domain}/comercia_ws/download-from-path?path=${item.xml}`;
    await downloadApi.download(url, name);
  };

  return (
    <div className="w-100">
      <div className="p-2 w-100 d-flex align-items-center justify-content-between">
        <div className="w-100 d-flex align-items-center justify-content-between">
          <div className="w-100 d-flex flex-column">
            <span className="factura_item_title">{`${item.entity_label.substring(0, 8)}`}</span>
            <span className="factura_item_date">{dateDisplay}</span>
          </div>
          <div className="w-100 d-flex flex-column align-items-end">
            <span
              className={
                item.estadosat === 'Cancelado'
                  ? 'factura_item_total_cancelado'
                  : 'factura_item_total'
              }
            >
              {`$${toolsHelper.formatMoney(item.total)}`}
            </span>
            <span className="factura_item_date">
              {`$${toolsHelper.formatMoney(item.tfde_balance)}`}
            </span>
          </div>
        </div>
        <div className="ml-2 d-flex align-items-center justify-content-center">
          <Flag
            backgroundColor={setBackground()}
            color={setColor()}
            icon={<SatLogo className="factura_icon" fill={setColor()} />}
          />
        </div>
        <div className="ml-2 d-flex align-items-center justify-content-center">
          <Tooltip
            title="Descargar xml"
          >
            <div onClick={() => downloadFile(item)}>
              <Flag
                backgroundColor={setBackground()}
                color={setColor()}
                icon={<FontAwesomeIcon icon="file-download" style={{ width: '21px', height: '21px', color: 'white' }} />}
              />
            </div>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

ListFacturasItem.propTypes = {
  item: PropTypes.object.isRequired,
};

export default ListFacturasItem;
