/* eslint-disable no-unused-expressions */
import { useState } from 'react';
import { toast } from 'react-toastify';
import AddressAPI from '../../api/AddressAPI';

export default function useCustomer() {
  const addressApi = new AddressAPI();
  const [loading, setLoading] = useState(false);
  const [loadAddress, setLoadAddress] = useState(false);
  const [address, setAddress] = useState([]);

  const customerAddress = async (customerId) => {
    setLoading(true);
    const response = await addressApi.get(customerId);
    const { success, result, message } = response;

    if (success) {
      setAddress(result);
      message.success ? toast.success(message.success) : '';
    } else {
      message.error ? toast.error(message.error) : '';
      message.warning ? toast.warn(message.warning) : '';
    }

    setLoading(false);
  };

  const addAddress = async (customerId, newAddress) => {
    setLoading(true);
    const buildAddress = { ...newAddress, relatedtoid: customerId };
    const response = await addressApi.createAddress(buildAddress);
    const { success, message } = response;

    if (success) {
      setLoadAddress(true);
      setTimeout(() => {
        setLoadAddress(false);
      }, 2000);
      message.success ? toast.success(message.success) : '';
    } else {
      message.error ? toast.error(message.error) : '';
      message.warning ? toast.warn(message.warning) : '';
    }

    setLoading(false);
  };

  return {
    loading, address, loadAddress, customerAddress, addAddress,
  };
}
