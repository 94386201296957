/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import useDebounce from '../../../../hooks/use-debounce';

const Lote = ({
  lote, add, product,
}) => {
  const [quantity, setQuantity] = useState(lote.qty || 0);
  const debouncevalue = useDebounce(quantity, 500);

  const addRest = (value) => {
    // debugger;
    if (value > 0 && lote.qty === lote.qtyinstock) {
      toast.warn('Ha alcanzado el máximo del stock del lote');
      return;
    }

    if (value < 0 && lote.qty === 0) {
      return;
    }

    setQuantity(lote.qty + value);
  };

  useEffect(() => {
    if (quantity !== lote.qty) {
      if (quantity <= lote.qtyinstock && quantity <= product.cantidad) {
        add(lote.treebeslotesid, quantity);
      } else {
        toast.warn('No puede superar la cantidad disponible o la cantidad a entregar');
        add(lote.treebeslotesid, lote.qty);
      }
    }
  }, [debouncevalue]);


  return (
    <tr>
      <td>{lote.number}</td>
      <td>--</td>
      <td>{lote.qtyinstock}</td>
      <td>
        <div style={{
          display: 'flex', justifyContent: 'start', alignItems: 'center', gap: '5px',
        }}
        >
          <button
            style={{
              border: `1px solid ${product.por_entregar <= 0 ? 'gray' : '#24a2db'}`,
              borderRadius: '2px',
              backgroundColor: product.por_entregar <= 0 ? 'gray' : '#24a2db',
              color: 'white',
              height: '24px',
            }}
            disabled={product.por_entregar <= 0}
            onClick={() => addRest(-1)}
            type="button"
          >
            -
          </button>
          <input
            style={{
              width: 80,
              border: '1px solid gray',
              borderRadius: '4px',
              padding: '0 4px',
              height: '24px',
            }}
            type="number"
            value={quantity}
            min={0}
            onChange={(e) => {
              setQuantity(e.target.valueAsNumber);
            }}
          />
          <button
            style={{
              border: `1px solid ${product.por_entregar >= product.cantidad || lote.qty === lote.qtyinstock ? 'gray' : '#24a2db'}`,
              borderRadius: '2px',
              backgroundColor: product.por_entregar >= product.cantidad || lote.qty === lote.qtyinstock ? 'gray' : '#24a2db',
              color: 'white',
              height: '24px',
            }}
            disabled={product.por_entregar >= product.cantidad || lote.qty === lote.qtyinstock}
            onClick={() => addRest(1)}
            type="button"
          >
            +
          </button>
        </div>
      </td>
    </tr>
  ); };

export default Lote;
