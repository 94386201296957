import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEnvelope, faDownload,
} from '@fortawesome/free-solid-svg-icons';
import IconButton from '@material-ui/core/IconButton';
import { isMobile } from 'react-device-detect';

const ShareItem = ({
  // eslint-disable-next-line react/prop-types
  template, shareByEmail, download, url, name,
}) => {
  const user = useSelector(state => state.authUser);
  // eslint-disable-next-line react/prop-types
  const { templatename, description, subject } = template;
  return (
    <ListItem key={subject} divider>
      <ListItemText primary={templatename} secondary={description} />
      <ListItemText
        primary={
              (
                <div className="d-flex justify-content-end">
                  <IconButton edge="end" aria-label="comments" onClick={() => shareByEmail()}>
                    <FontAwesomeIcon style={{ fontSize: 15 }} icon={faEnvelope} />
                  </IconButton>
                  {
                        isMobile
                          ? (
                            <a href={`pdf://download?token=${user.token}&nombre=${name}&url=${url}`}>
                              <IconButton edge="end" aria-label="comments">
                                <FontAwesomeIcon style={{ fontSize: 15 }} icon={faDownload} />
                              </IconButton>
                            </a>
                          )
                          : (
                            <IconButton edge="end" aria-label="comments" onClick={() => download()}>
                              <FontAwesomeIcon style={{ fontSize: 15 }} icon={faDownload} />
                            </IconButton>
                          )

                  }
                </div>
              )
            }
        classes={{ root: 'pr-0' }}
      />
    </ListItem>
  ); };

ShareItem.prototype = {
  template: PropTypes.object.isRequired,
  shareByEmail: PropTypes.func.isRequired,
  download: PropTypes.func.isRequired,
  url: PropTypes.string.isRequired,
  name: PropTypes.string,
};

ShareItem.defaultProps = {
  name: 'COMERCIA_VENTA',
};
export default ShareItem;
