import React from 'react';
import PropTypes from 'prop-types';
/* Material */
import { Divider } from '@material-ui/core';

/* Mask */
import DiscountAmounts from './DiscountAmounts';

const DiscountInput = ({
  tab,
  type,
  title,
  submit,
  specific,
  moneyInput,
  perceInput,
  setDiscount,
  onChangeInput,
}) => (
  <>
    <div className="w-100 checkoutGeneralTitle">{title}</div>
    <div className="w-100 d-flex align-items-center justify-content-between">
      <div className="w-100 h-100 justifycontent-center, align-items-center">
        <DiscountAmounts
          tab={tab}
          type={type}
          submit={submit}
          specific={specific}
          moneyInput={moneyInput}
          perceInput={perceInput}
          setDiscount={setDiscount}
          onChangeInput={onChangeInput}
        />
      </div>
    </div>
    <Divider />
  </>
);

DiscountInput.propTypes = {
  type: PropTypes.bool,
  title: PropTypes.string,
  tab: PropTypes.number.isRequired,
  submit: PropTypes.func.isRequired,
  specific: PropTypes.func.isRequired,
  setDiscount: PropTypes.func.isRequired,
  onChangeInput: PropTypes.func.isRequired,
  moneyInput: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]).isRequired,
  perceInput: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]).isRequired,
};
DiscountInput.defaultProps = {
  type: false,
  title: 'Cantidades: ',
};

export default DiscountInput;
