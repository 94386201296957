import React from 'react';
import './CommentsContainer.scss';
import { useSelector } from 'react-redux';
// import PropTypes from 'prop-types';

import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import Divider from '@material-ui/core/Divider';
import Card from '@material-ui/core/Card';
import List from '@material-ui/core/List';
import CommentsItem from './CommentItem/CommentItem';
import CommentInput from './CommentInput/CommentInput';
import CommentsAPI from '../../../../api/CommentsAPI';


const CommentsContainer = ({ module, relatedTo, limitHeight = true }) => {
  const user = useSelector(state => state.authUser.user);
  const { config: { id } } = user;
  const commentsApi = new CommentsAPI();
  const [commentsData, setCommentsData] = React.useState([]);
  const [activeComment, setActiveComment] = React.useState(null);

  const getComments = async () => {
    const response = await commentsApi.getAllComments(module, relatedTo);
    const { success, result } = response;
    if (success) {
      await setCommentsData(result);
    }
  };

  const changeActiveComment = (commentId) => {
    const commen = commentsData.find(comment => comment.crmid === commentId);
    setActiveComment(commen);
  };

  const saveComment = async (commentText, action = 'create') => {
    if (action === 'edit' && activeComment !== null && Object.keys(activeComment).length) {
      activeComment.commentcontent = commentText;
      const resp = await commentsApi.editComment(module, relatedTo, activeComment);
      const { success } = resp;
      if (success) {
        await getComments();
      }
    } else {
      const comment = {
        entity_label: commentText,
        related_to: relatedTo,
        parent_comments: 0,
        commentcontent: commentText,
        userid: id,
      };
      const response = await commentsApi.addComment(module, relatedTo, comment);
      const { success, result } = response;
      if (success) {
        setCommentsData([result, ...commentsData]);
      }
    }
  };

  React.useEffect(() => {
    if (module && relatedTo) {
      getComments();
    }
  }, []);

  return (
    <Card className="mt-2 w-100">
      <h3 className="m-2 d-flex justify-content-center SummaryContainer_title">Comentarios</h3>
      <Divider />
      <List style={{ maxHeight: limitHeight ? 280 : '100%', overflow: 'auto' }}>
        {
          commentsData.length
            ? (
              commentsData.map(comment => (
                <>
                  <CommentsItem commentId={comment.crmid} name={comment.assigned_user} time={comment.createdtime} comment={comment.commentcontent} setActiveComment={changeActiveComment} />
                  <Divider />
                </>
              ))
            )
            : (
              <ListItem alignItems="flex-start">
                <ListItemText
                  primary="Sin comentarios"
                />
              </ListItem>
            )
        }
        {/* <CommentsItem name="Beatriz Smith" time="4 Jul 10:30am" comment="Lorem ipsum dolor sit amet, consectetur adipisicing elit. Repudiandae, impedit!" />
        <Divider />
        <CommentsItem name="Yenifer Lopez" time="10 Oct 12:30am" comment="Lorem ipsum dolor sit amet, consectetur adipisicing elit. Repudiandae, impedit!" /> */}
      </List>
      <CommentInput saveComment={saveComment} comment={activeComment} />
    </Card>
  ); };

// CommentsContainer.prototype = {};
export default CommentsContainer;
