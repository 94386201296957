import React from 'react';
import PropTypes from 'prop-types';
/* Material */
import { Input } from '@material-ui/core';

/* Mask */
import Percentage from './mask/PercentageMask';
import Dollar from './mask/DollarMask';

const DiscountAmounts = ({
  tab,
  type,
  perc,
  symbol,
  submit,
  specific,
  moneyInput,
  perceInput,
  setDiscount,
  onChangeInput,
}) => (
  <>
    <div className="w-100 h-100 d-flex align-items-center justify-content-around">
      {type && (
        <div className="w-100 amount_payable_discount">
          <div className="amount_payable_discount_symbol">{symbol}</div>
          <div className="amount_payable_discount_fieldDis">
            <Input
              autoComplete="off"
              id="amount_dollar"
              name="dollar"
              inputRef={moneyInput}
              className="w-100 discountAmount"
              placeholder="Monto"
              inputComponent={Dollar}
              onChange={() => onChangeInput()}
              autoFocus
              onKeyUp={(e) => {
                const { keyCode } = e;
                if (keyCode === 13 && tab === 0) {
                  submit();
                } else if (keyCode === 37) {
                  const btn = document.querySelector('#btn_perce');
                  // @ts-ignore
                  if (btn) btn.click();
                  setDiscount(0);
                }
              }}
            />
          </div>
        </div>
      )}
      {!type && (
        <div className="w-100 amount_payable_discount">
          <div className="amount_payable_discount_symbol">{perc}</div>
          <div className="amount_payable_discount_fieldDis">
            <Input
              id="amount_percentage"
              name="percentage"
              inputRef={perceInput}
              className="w-100 discountAmount"
              placeholder="Porcentaje"
              // inputComponent={Percentage}
              step="0.01"
              type="number"
              max={100}
              onChange={(e) => {
                const { target: { value } } = e;
                const percentage = (parseFloat(value) || 0);
                if (percentage > 100) {
                  perceInput.current.value = 100;
                }
                if (percentage < 0) {
                  perceInput.current.value = 0;
                }
                if (percentage >= 0 && percentage <= 100) {
                  onChangeInput();
                }
              }}
              autoFocus
              onKeyUp={(e) => {
                const { keyCode } = e;
                if (keyCode === 13) {
                  if (tab === 0) {
                    submit();
                  } else {
                    specific(false);
                  }
                } else if (keyCode === 39) {
                  const btn = document.querySelector('#btn_amount');
                  // @ts-ignore
                  if (btn) btn.click();
                  setDiscount(0);
                }
              }}
            />
          </div>
        </div>
      )}
    </div>
  </>
);

DiscountAmounts.propTypes = {
  type: PropTypes.bool,
  perc: PropTypes.string,
  symbol: PropTypes.string,
  tab: PropTypes.number.isRequired,
  submit: PropTypes.func.isRequired,
  specific: PropTypes.func.isRequired,
  setDiscount: PropTypes.func.isRequired,
  onChangeInput: PropTypes.func.isRequired,
  moneyInput: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]).isRequired,
  perceInput: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]).isRequired,
};
DiscountAmounts.defaultProps = {
  perc: '%',
  type: false,
  symbol: '$',
};

export default DiscountAmounts;
