import React from 'react';
import PropTypes from 'prop-types';
import {
  Collapse,
  ButtonBase,
} from '@material-ui/core';
import { KeyboardArrowDown } from '@material-ui/icons';

import Mda from '../../../../Mda';

const DeliverMda = ({
  isShow,
  loading,
  openMda,
  setOpenMda,
}) => (
  <div className="deliver_mda">
    {!loading && (
    <div className="refund_fde_head border-bottom">
      <span>Movimientos de la orden de venta (Historico)</span>
      <ButtonBase
        className="p-1"
        onClick={() => setOpenMda(!openMda)}
      >
        <KeyboardArrowDown className={openMda ? 'mda_drop' : 'mda_drop noDropDown'} />
      </ButtonBase>
    </div>
    )}
    <Collapse in={openMda && !loading} className="w-100">
      <div className="deliver_mda_list">
        <Mda type="list" isShow={isShow} />
      </div>
    </Collapse>
  </div>
);

DeliverMda.propTypes = {
  isShow: PropTypes.bool,
  loading: PropTypes.bool,
  openMda: PropTypes.bool,
  setOpenMda: PropTypes.func,
};

DeliverMda.defaultProps = {
  isShow: false,
  loading: false,
  openMda: false,
  setOpenMda: () => {},
};

export default DeliverMda;
