/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import './activities.style.scss';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  Collapse,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core';

import { fetchAllTasks, setStatusFilter } from '../../redux/actions/taskActions';
import FabBtn from '../Expenses/components/FabBtn';
import ActivityListItem from './components/ActivityListItem';
// eslint-disable-next-line import/no-cycle
import SearchHeader from './components/SearchHeader';

const Activities = ({ history }) => {
  const dispatch = useDispatch();
  const tasksStore = useSelector(state => state.tasks);
  const authUser = useSelector(state => state.authUser.user);
  const loads = useSelector(state => state.loads);

  const {
    tasks, taskSearchCriteria, projects, projectFilter, statusFilter, userFilter,
  } = tasksStore;
  const {
    config: {
      pos_filter_projecttaskstatus: configStatusFilter,
    },
    pos_filter_projecttaskstatus_values: status,
  } = authUser;

  const getStatusByLabel = (valor) => {
    let statusObject = null;
    if (status && status.length) {
      const idx = status.findIndex(stat => stat.label === valor);
      if (idx !== -1) {
        statusObject = status[idx];
      }
    }

    return statusObject;
  };


  const formatStatusSelected = (stringFormatted) => {
    const evaluatedString = stringFormatted;
    let statusList = '';
    if (Array.isArray(status) && status.length && evaluatedString) {
      for (let i = 0; i < status.length; i++) {
        if (evaluatedString.includes(status[i].value)) {
          statusList = statusList.concat(status[i].label, ',');
        }
      }
    }

    return statusList.substr(0, statusList.length - 1);
  };

  // Get id of project selected Ex: 'Abierto,Cancelado'
  const getTaskStatusSelected = () => {
    let statusList = '';
    const arrayOfFilters = formatStatusSelected(configStatusFilter);
    const conficFilters = arrayOfFilters.split(',');

    if (conficFilters && conficFilters.length) {
      for (let i = 0; i < conficFilters.length; i++) {
        const statusObject = getStatusByLabel(conficFilters[i]);
        if (i === 0 || statusList === '') {
          statusList = statusObject ? statusObject.value : '';
        } else if (statusObject) {
          statusList = `${statusList},${statusObject.value}`;
        }
      }
    }

    return statusList;
  };

  // Get id of project selected Ex: 'Abierto,Cancelado'
  const getTaskUsersList = () => {
    let userList = '';

    if (userFilter && userFilter.length) {
      for (let i = 0; i < userFilter.length; i++) {
        if (i === 0 || userList === '') {
          userList = `${userFilter[i].trim()}`;
        } else {
          userList = `${userList},${userFilter[i].trim()}`;
        }
      }
    }

    return userList;
  };

  // Get task status selected Ex: '1234,5698'
  const getListOfProjectIds = () => {
    let projectIds = '';

    if (projects && projects.length) {
      for (let i = 0; i < projects.length; i++) {
        if (projectFilter.length && projectFilter.includes(projects[i].projectname)) {
          if (i === 0 || projectIds === '') {
            projectIds = `${projects[i].crmid}`;
          } else {
            projectIds = `${projectIds},${projects[i].crmid}`;
          }
        }
      }
    }

    return projectIds;
  };

  const handleSearchTasks = () => {
    const projectsIds = getListOfProjectIds();
    const statusList = getTaskStatusSelected();
    const userList = getTaskUsersList();
    const searchCol = {};
    if (taskSearchCriteria !== '') {
      searchCol.projecttaskname = taskSearchCriteria;
    }
    if (statusList !== '') {
      searchCol.projecttaskstatus = statusList.split(',')[0];
    }
    if (userList !== '') {
      searchCol.assigned_user = userList.split(',')[0];
    }
    if (projectsIds !== '') {
      searchCol.projectid = projectsIds.split(',')[0];
    }
    dispatch(fetchAllTasks(1, searchCol));
  };

  React.useEffect(() => {
    handleSearchTasks();
  }, [taskSearchCriteria, projectFilter, statusFilter, userFilter]);

  React.useEffect(() => {
    if (configStatusFilter === '') {
      dispatch(setStatusFilter([]));
    } else if (configStatusFilter) {
      const arrayOfFilters = formatStatusSelected(configStatusFilter);
      dispatch(setStatusFilter(arrayOfFilters.split(',')));
    }
  }, [configStatusFilter]);

  /*   React.useEffect(() => {
    if (configStatusFilter && configStatusFilter !== '') {
      const arrayOfFilters = formatStatusSelected(configStatusFilter);
      dispatch(setStatusFilter(arrayOfFilters.split(',')));
    } else {
      handleSearchTasks();
    }
  }, []); */


  const handleClickAdd = () => {
    history.push('/actividades/actividad/add');
  };

  return (
    <div className="d-flex justify-content-center">
      <div
        className="expenses-container"
        style={{
          overflow: 'auto',
          position: 'relative',
          marginBottom: 70,
          width: 520,
          height: '85vh',
        }}
      >
        <SearchHeader />
        <Collapse in={loads.taskIsLoading}>
          <LinearProgress />
        </Collapse>
        {!loads.tasksIsLoading && tasks.length > 0 && (
          tasks.map(item => (
            <div key={`${item.crmid}_${Math.random()}`}>
              <ActivityListItem item={item} />
            </div>
          ))
        )}
        <Collapse in={tasks.length === 0}>
          <List dense>
            <ListItem classes={{ root: 'bg-white' }}>
              <ListItemText
                primary="Sin actividades"
                secondary="No se encontraron actividades"
              />
            </ListItem>
          </List>
        </Collapse>
        <FabBtn clickAction={handleClickAdd} />
      </div>
    </div>
  );
};


export default withRouter(Activities);
