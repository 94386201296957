/* eslint-disable import/no-cycle */
/* eslint-disable radix */
/* eslint-disable no-nested-ternary */
import React from 'react';
import '../share.scss';
import PropTypes from 'prop-types';
import { IconButton } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import CircularProgress from '@material-ui/core/CircularProgress';
import Fab from '@material-ui/core/Fab';
import Collapse from '@material-ui/core/Collapse';
/* Components */
import Share from '../index';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const ShareModal = ({
  open,
  title,
  handleClose,
  order,
  template,
  isLoading,
}) => (
  <Dialog fullScreen open={open} onClose={() => handleClose()} TransitionComponent={Transition}>
    <AppBar style={{ position: 'relative' }}>
      <Toolbar classes={{ root: 'tollbar-header' }}>
        <Button autoFocus color="inherit" onClick={() => {}}> </Button>
        <Typography variant="h6" classes={{ root: 'text-white' }}>
          {title}
        </Typography>
        <IconButton autoFocus={false} edge="start" color="inherit" onClick={() => handleClose()} aria-label="close">
          <CloseIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
    <div className="center-table">
      <div className="center-list">
        <Share data={order} type="download" appModule={template} />
      </div>
      <div className="center-list">
        <Share data={order} type="email" appModule={template} />
      </div>
    </div>
    <Collapse in={isLoading} style={{ right: '32px', position: 'fixed', bottom: '15px' }}>
      <Fab color="primary">
        <CircularProgress style={{ color: '#fff' }} />
      </Fab>
    </Collapse>
  </Dialog>
);

ShareModal.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  order: PropTypes.object.isRequired,
  template: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default ShareModal;
