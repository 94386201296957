import React from 'react';
import SearchBtn from '../../../../components/Search/components/SearBtn';
import TaskFilter from '../TaskFilter';

const SearchHeader = () => (
  <div className="filter-header d-flex justify-content-between align-items-center bg-white p-2 sticky-top">
    <div className="w-100 pr-2">
      <SearchBtn type="task" />
    </div>
    <TaskFilter />
  </div>
);

export default SearchHeader;
