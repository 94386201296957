import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CircularProgress from '@material-ui/core/CircularProgress';
import ProductAPI from '../../../api/ProductAPI';

const StockModal = ({ product, stocks }) => {
  const [productData, setProductData] = React.useState({});
  const [loadData, setLoadData] = React.useState(false);
  const productApi = new ProductAPI();

  const getStockData = async () => {
    setLoadData(true);
    const productid = (product.productid && product.productid.indexOf('x') !== -1)
      ? product.productid.split('x').pop()
      : product.productid;
    const productDataQuantity = await productApi.getProduct(product.crmid || productid);
    if (productDataQuantity.result) {
      setProductData(productDataQuantity.result);
    }
    setLoadData(false);
  };

  const showProductStocQuantity = (almacenId) => {
    let quantity = 0;
    if (productData) {
      if (Object.prototype.hasOwnProperty.call(productData, `talm${almacenId}_qtyinstock`)) {
        quantity = Math.ceil(productData[`talm${almacenId}_qtyinstock`]);
      }
    }
    return quantity;
  };

  React.useEffect(() => {
    getStockData();
  }, [product]);

  const StyledTableCell = withStyles(theme => ({
    head: {
      backgroundColor: theme.palette.action.hover,
      color: '#313C52',
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);

  return (
    <Table aria-label="customized table">
      <TableHead>
        <TableRow>
          <StyledTableCell style={{ fontSize: 16, fontWeight: 600 }}>Almacén</StyledTableCell>
          <StyledTableCell align="right" style={{ fontSize: 16, fontWeight: 600 }}>Disponible</StyledTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {
          stocks.map(stock => (
            <TableRow key={stock.crmid}>
              <StyledTableCell component="th" scope="row">
                {stock.almacen}
              </StyledTableCell>
              <StyledTableCell align="right">
                {
                !loadData ? showProductStocQuantity(stock.crmid) : (<CircularProgress size={20} />)
              }
              </StyledTableCell>
            </TableRow>
          ))
        }
      </TableBody>
    </Table>
  );
};

StockModal.propTypes = {
  product: PropTypes.object.isRequired,
  stocks: PropTypes.array.isRequired,
};

export default StockModal;
