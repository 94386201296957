/* eslint-disable import/no-cycle */
/* eslint-disable camelcase */
/* eslint-disable object-curly-newline */

import React from 'react';
import PropTypes from 'prop-types';

import { useDispatch, useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';
import { Grid, Button } from '@material-ui/core';
import { Save } from '@material-ui/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withRouter } from 'react-router-dom';

import loadsProps from '../../../../propTypes/loadsProps';
import modalsProps from '../../../../propTypes/modalsProps';
import authUserProps from '../../../../propTypes/authUserProps';
import {
  shouldCharge,
  shouldDeliver,
  shouldRefoundMoney,
} from '../../../../helpers/order';

import CheckOut from '../../../../components/CheckOut';
import Deliver from '../../../../components/Deliver';
import Refund from '../../../../components/Refund';
import DeliverDialog from '../../../../components/Deliver/components/DeliverDialog';
import { toast } from 'react-toastify';
import { openModal } from '../../../../redux/actions/modalActions';

const PosCartContainerAction = ({
  authUser,
  cart,
  ctrlPressed,
  customer,
  discount,
  dispatchAddItemsToTable,
  dispatchChangeComment,
  dispatchChangeFieldValue,
  dispatchChangeSalesOrderStatus,
  dispatchCloseCheckOutModal,
  dispatchCloseCheckOutRefoundModal,
  dispatchCloseDeliverModal,
  dispatchCloseRefoundModal,
  dispatchCotizar,
  dispatchDeliverMoney,
  dispatchEditOrder,
  dispatchGetOrderDocument,
  dispatchHandleMakeBill,
  dispatchMakeSell,
  dispatchOpenCheckOutModal,
  dispatchOpenCheckOutRefoundModal,
  dispatchOpenDeliverModal,
  dispatchOpenRefoundModal,
  dispatchRefundAll,
  dispatchRefundMoney,
  dispatchSaveSell,
  dispatchSelectEmpty,
  dispatchSelectToDelivered,
  dispatchSelectToRefunded,
  dispatchSetActivityToOrder,
  dispatchChangeBadProducts,
  dispatchChangeProducts,
  loads,
  modals,
  table,
  tabs,
  timbrar,
  history,
}) => {
  const dispatch = useDispatch();
  const { makeBill } = timbrar;
  const { posTypeTab } = tabs;
  const { orderSelected, totalProducts } = cart;
  const almacen = orderSelected.cartStateWarehouse || '';
  const { sostatus, pos_botoncaja, pos_botonventas, pos_deliver_out_stock, pos_sell_tab } = authUser.user.config;
  const { pos_checkout_bill, store } = authUser.user.config;
  const deliver = useSelector(state => state.shipping?.shippingList);

  const { shipping_id } = authUser.user;
  const orderStatuses = authUser.user.sostatus;
  const paymentTypes = authUser.user.metodos_pagos;
  const { enableAction } = table;
  const mostrarCotizar = Boolean(pos_botoncaja === 'Cotizar');
  const mostrarCobrar = Boolean(pos_botoncaja === 'Cobrar');
  const mostrarGuardar = Boolean(pos_botoncaja === 'Guardar');
  const mostrarHistorico = pos_botonventas !== undefined ? pos_botonventas : 'Ambas';

  const { balance, total, order, status } = orderSelected;
  const currentOrderStatus = authUser.user.config.sostatus;
  const productsInOrder = Boolean(totalProducts > 0);
  const orderNo = cart.orderSelected && cart.orderSelected.order ? cart.orderSelected.order.salesorder_no || cart.orderSelected.order.label : '';
  const isOrder = posTypeTab === 'Historico' && Boolean(orderNo);
  const withDiscount = (Number.parseFloat(cart.total));
  const generalBalance = (withDiscount - Number.parseFloat(cart.pagado));
  const orderExist = Boolean(Object.keys(orderSelected).length > 0);
  const deliverOutStock = Boolean(pos_deliver_out_stock === '1');

  const { orderIsLoading } = loads;

  const renderDeliverDalog = () => (
    <DeliverDialog
      table={table}
      loads={loads}
      authUser={authUser}
      dispatchSelectEmpty={dispatchSelectEmpty}
      dispatchChangeComment={dispatchChangeComment}
      dispatchChangeFieldValue={dispatchChangeFieldValue}
      dispatchSelectToRefunded={dispatchSelectToRefunded}
      dispatchSelectToDelivered={dispatchSelectToDelivered}
      deliverEnableAction={Boolean(enableAction || deliverOutStock)}
      getOrderDocument={dispatchGetOrderDocument}
      isOpen={Boolean(modals.deliverModalIsOpen)}
      onClose={dispatchCloseDeliverModal}
      isLoading={Boolean(loads.orderIsLoading)}
      title={isOrder ? (`Entregar a cliente de la Orden: ${orderNo}`) : ('Entrega')}
      onSave={() => {
        dispatchSetActivityToOrder(
          cart.orderSelected.crmid,
          'Entregar',
        );
      }}
    />
  );
  return (
    <div className="w-100">
      <div
        className={
            isMobile
              ? 'py-2 px-3 h-100 d-flex align-items-center '
              : 'h-100 w-100 d-flex align-items-end'
          }
      >
        {tabs.posTypeTab === 'Vender' && (
          <>
              {mostrarCotizar && (
                <>
                  <Button
                    id="key_selector_button"
                    className="w-100 checkOutButton_cotizar"
                    color="secondary"
                    variant="contained"
                    disabled={cart.totalProducts <= 0}
                    onClick={dispatchCotizar}
                  >
                    <FontAwesomeIcon icon="file-invoice-dollar" />
                    <span>Cotizar</span>
                  </Button>
                  {renderDeliverDalog()}
                </>
              )}
              {mostrarCobrar && (
                <>
                  <CheckOut
                    dispatchEditOrder={dispatchEditOrder}
                    cart={cart}
                    customer={customer}
                    isOpen={Boolean(modals.checkoutModalIsOpen)}
                    onOpen={dispatchOpenCheckOutModal}
                    onClose={dispatchCloseCheckOutModal}
                    isLoading={Boolean(loads.checkoutIsLoading)}
                    onSave={async (checkOut) => {
                      // eslint-disable-next-line no-shadow
                      const { currentOrderStatus, payments } = checkOut;
                      dispatchChangeSalesOrderStatus(currentOrderStatus);
                      await dispatchMakeSell(payments);
                      if (pos_sell_tab) {
                        history.push('/pos/ventas');
                      }
                    }}
                    canSell={cart.totalProducts > 0}
                    totalToPay={generalBalance}
                    defaultOrderStatus={sostatus}
                    orderStatuses={orderStatuses}
                    paymentTypes={paymentTypes}
                    ctrlPressed={ctrlPressed}
                    makeBill={makeBill}
                    handleMakeBill={dispatchHandleMakeBill}
                    posCheckoutBill={pos_checkout_bill}
                  />
                </>
              )}

              {mostrarGuardar && (
                <>
                  <Button
                    className="w-100 checkOutButton_save"
                    color="primary"
                    variant="contained"
                    disabled={cart.totalProducts <= 0}
                    onClick={() => {
                      if (store.forzaEnvio === '1') {
                        const shippingIsSelected = cart.products.findIndex(prod => prod.crmid === shipping_id);
                        // Total products
                        const productQty = cart.products.filter(p => p.crmid !== shipping_id).reduce((acc, prod) => acc + prod.quantity, 0);
                        // Total products with shipping
                        const shippingProducts = [];
                        deliver.map(p => p.products.map(prod => shippingProducts.push(prod)));
                        const totalShippingProducts = shippingProducts.reduce((acc, prod) => acc + prod.quantity, 0);

                        if (shippingIsSelected === -1 && (deliver.length === 0 || totalShippingProducts !== productQty)) {
                          if (totalShippingProducts !== productQty) {
                            toast.info('La cantidad de los productos en envíos debe ser la misma cantidad de productos que la orden');
                          } else {
                            toast.info('Debes seleccionar un envío  antes de guardar');
                          }
                          dispatch(openModal('shipping'));

                          return;
                        }
                        if (shippingIsSelected !== -1 && totalShippingProducts !== productQty) {
                          toast.info('La cantidad de los productos en envíos debe ser la misma cantidad de productos que la orden');
                          dispatch(openModal('shipping'));
                          return;
                        }
                      }
                      dispatchSaveSell(currentOrderStatus);
                      history.push('/pos/ventas');
                    }}
                  >
                    <Save className="m-auto" />
                    <span>Guardar</span>
                  </Button>
                  {renderDeliverDalog()}
                </>
              )}
              {!(mostrarCobrar || mostrarCotizar || mostrarGuardar) && (
                <>
                  <CheckOut
                    dispatchEditOrder={dispatchEditOrder}
                    cart={cart}
                    customer={customer}
                    isOpen={Boolean(modals.checkoutModalIsOpen)}
                    onOpen={dispatchOpenCheckOutModal}
                    onClose={dispatchCloseCheckOutModal}
                    isLoading={Boolean(loads.checkoutIsLoading)}
                    onSave={(checkOut) => {
                      // eslint-disable-next-line no-shadow
                      const { currentOrderStatus, payments } = checkOut;
                      dispatchChangeSalesOrderStatus(currentOrderStatus);
                      dispatchMakeSell(payments);
                    }}
                    canSell={cart.totalProducts > 0}
                    totalToPay={generalBalance}
                    defaultOrderStatus={sostatus}
                    orderStatuses={orderStatuses}
                    paymentTypes={paymentTypes}
                    ctrlPressed={ctrlPressed}
                    makeBill={makeBill}
                    handleMakeBill={dispatchHandleMakeBill}
                    posCheckoutBill={pos_checkout_bill}
                  />
                  {renderDeliverDalog()}
                </>
              )}
          </>
        )}
        {tabs.posTypeTab === 'Historico' && (
        <div className="salesOffer_container actions_order_product w-100 h-100">
          <div className="w-100 h-100 d-flex justify-content-around align-items-end">
            {shouldDeliver(almacen) && productsInOrder && (mostrarHistorico === 'Ambas' || mostrarHistorico === 'Entregar') && (
            <div className="w-100 h-100 d-flex align-items-end px-1">
              <Deliver
                table={table}
                loads={loads}
                authUser={authUser}
                dispatchSelectEmpty={dispatchSelectEmpty}
                dispatchChangeComment={dispatchChangeComment}
                dispatchChangeFieldValue={dispatchChangeFieldValue}
                dispatchSelectToRefunded={dispatchSelectToRefunded}
                dispatchSelectToDelivered={dispatchSelectToDelivered}
                deliverEnableAction={Boolean(enableAction || deliverOutStock)}
                getOrderDocument={dispatchGetOrderDocument}
                isOpen={Boolean(modals.deliverModalIsOpen)}
                onOpen={() => {
                  dispatchOpenDeliverModal();
                  dispatchAddItemsToTable('deliver');
                }}
                onClose={dispatchCloseDeliverModal}
                isLoading={Boolean(loads.orderIsLoading)}
                onSave={() => {
                  dispatchSetActivityToOrder(
                    cart.orderSelected.crmid,
                    'Entregar',
                  );
                }}
                disabled={cart.totalProducts < 1 || status === 'Cancelado'}
                title={`Entregar a cliente de la Orden: ${orderNo}`}
              />
            </div>
            )}
            {shouldCharge(balance) && productsInOrder && (mostrarHistorico === 'Ambas' || mostrarHistorico === 'Cobrar') && (
            <div className="w-100 h-100 d-flex align-items-end px-1">
              <CheckOut
                dispatchEditOrder={dispatchEditOrder}
                cart={cart}
                customer={customer}
                isOpen={Boolean(modals.checkoutModalIsOpen)}
                onOpen={dispatchOpenCheckOutModal}
                onClose={dispatchCloseCheckOutModal}
                isLoading={Boolean(loads.checkoutIsLoading)}
                onSave={(checkOut) => {
                  const { payments } = checkOut;
                  const orderId = cart.orderSelected.crmid;
                  dispatchDeliverMoney(payments, orderId);
                }}
                canSell={cart.totalProducts > 0 && status !== 'Cancelado'}
                totalToPay={
                        cart.orderSelected.order
                          ? Number.parseFloat(
                            generalBalance,
                          )
                          : 0
                      }
                defaultOrderStatus={sostatus}
                orderStatuses={orderStatuses}
                paymentTypes={paymentTypes}
                modalTitle={`Cobrar Orden: ${order.customerno || order.salesorder_no}`}
                ctrlPressed={ctrlPressed}
                makeBill={makeBill}
                handleMakeBill={dispatchHandleMakeBill}
                posCheckoutBill={pos_checkout_bill}
              />
            </div>
            )}
            {shouldRefoundMoney(balance, total) && (
            <Grid item sm={6} style={{ display: 'none' }}>
              <CheckOut
                dispatchEditOrder={dispatchEditOrder}
                cart={cart}
                customer={customer}
                isOpen={Boolean(modals.checkoutRefoundModalIsOpen)}
                onOpen={dispatchOpenCheckOutRefoundModal}
                onClose={dispatchCloseCheckOutRefoundModal}
                isLoading={Boolean(loads.checkoutRefoundIsLoading)}
                onSave={(checkOut) => {
                  const { payments } = checkOut;
                  const orderId = cart.orderSelected.crmid;
                  dispatchRefundMoney(payments, orderId);
                }}
                canSell={cart.totalProducts > 0}
                totalToPay={
                        cart.orderSelected.order
                          ? Number.parseFloat(
                            cart.orderSelected.order.tfde_pagado,
                          )
                          : 0
                      }
                type="refund"
                title="Devolver Dinero"
                color="secondary"
                paymentTypes={paymentTypes}
                modalTitle={`Devolver Orden: ${order.customerno}`}
                ctrlPressed={ctrlPressed}
                makeBill={makeBill}
                handleMakeBill={dispatchHandleMakeBill}
                posCheckoutBill={pos_checkout_bill}
              />
            </Grid>
            )}
            {orderExist && (
            <Grid item sm={6} style={{ display: 'none' }}>
              <Refund
                table={table}
                loads={loads}
                authUser={authUser}
                refundAll={dispatchRefundAll}
                dispatchSelectEmpty={dispatchSelectEmpty}
                isOpen={Boolean(modals.refundModalIsOpen)}
                dispatchChangeComment={dispatchChangeComment}
                dispatchChangeFieldValue={dispatchChangeFieldValue}
                dispatchSelectToRefunded={dispatchSelectToRefunded}
                dispatchSelectToDelivered={dispatchSelectToDelivered}
                dispatchChangeBadProducts={dispatchChangeBadProducts}
                dispatchChangeProducts={dispatchChangeProducts}
                onOpen={() => {
                  dispatchOpenRefoundModal();
                  dispatchAddItemsToTable('refund');
                }}
                onClose={dispatchCloseRefoundModal}
                isLoading={Boolean(loads.orderIsLoading)}
                onSave={() => {
                  dispatchSetActivityToOrder(
                    cart.orderSelected.crmid,
                    'Devolver Productos',
                  );
                }}
                disabled={cart.totalProducts < 1}
                refundEnableAction={enableAction}
                title={`Devolución a cliente de la orden: ${order.salesorder_no}`}
              />
            </Grid>
            )}
          </div>
        </div>
        )}
        {tabs.posTypeTab === 'Cotizaciones' && (
        <Button
          id="key_selector_button"
          className="w-100 checkOutButton_save"
          color="primary"
          variant="contained"
          disabled={cart.totalProducts <= 0 || orderIsLoading}
          onClick={() => {
            dispatchSaveSell('Created');
            /* dispatchSaveSell(currentOrderStatus); */
            history.push('/pos/ventas');
          }}
        >
          <FontAwesomeIcon icon="file-invoice" />
          <span>Generar Venta</span>
        </Button>
        )}
      </div>
    </div>
  );
};

PosCartContainerAction.propTypes = {
  authUser: authUserProps.isRequired,
  cart: PropTypes.object.isRequired,
  ctrlPressed: PropTypes.bool.isRequired,
  customer: PropTypes.object.isRequired,
  discount: PropTypes.object.isRequired,
  dispatchAddItemsToTable: PropTypes.func.isRequired,
  dispatchChangeComment: PropTypes.func.isRequired,
  dispatchChangeFieldValue: PropTypes.func.isRequired,
  dispatchChangeSalesOrderStatus: PropTypes.func.isRequired,
  dispatchEditOrder: PropTypes.func.isRequired,
  dispatchCloseCheckOutModal: PropTypes.func.isRequired,
  dispatchCloseCheckOutRefoundModal: PropTypes.func.isRequired,
  dispatchCloseDeliverModal: PropTypes.func.isRequired,
  dispatchCloseRefoundModal: PropTypes.func.isRequired,
  dispatchCotizar: PropTypes.func.isRequired,
  dispatchDeliverMoney: PropTypes.func.isRequired,
  dispatchGetOrderDocument: PropTypes.func.isRequired,
  dispatchHandleMakeBill: PropTypes.func.isRequired,
  dispatchMakeSell: PropTypes.func.isRequired,
  dispatchOpenCheckOutModal: PropTypes.func.isRequired,
  dispatchOpenCheckOutRefoundModal: PropTypes.func.isRequired,
  dispatchOpenDeliverModal: PropTypes.func.isRequired,
  dispatchOpenRefoundModal: PropTypes.func.isRequired,
  dispatchRefundAll: PropTypes.func.isRequired,
  dispatchRefundMoney: PropTypes.func.isRequired,
  dispatchSaveSell: PropTypes.func.isRequired,
  dispatchSelectEmpty: PropTypes.func.isRequired,
  dispatchSelectToDelivered: PropTypes.func.isRequired,
  dispatchSelectToRefunded: PropTypes.func.isRequired,
  dispatchSetActivityToOrder: PropTypes.func.isRequired,
  loads: loadsProps.isRequired,
  modals: modalsProps.isRequired,
  table: PropTypes.object.isRequired,
  tabs: PropTypes.object.isRequired,
  timbrar: PropTypes.object.isRequired,
  history: PropTypes.object,
};

PosCartContainerAction.defaultProps = {
  history: {},
};


export default withRouter(PosCartContainerAction);
