/* eslint-disable no-case-declarations */
/* eslint-disable no-param-reassign */
/* eslint-disable import/no-cycle */
/* eslint-disable object-curly-newline */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import './ProductOption.scss';
import { isAndroid } from 'react-device-detect';
import { history } from '../../../views/App/index';

import productOptionProps from '../../../propTypes/productOptionProps';
import authUserProps from '../../../propTypes/authUserProps';

import FullDialog from '../../FullDialog';

import { haveStock } from '../../../helpers/product';
import ProductOptionMobile from './ProductOptionMobile/index';

class ProductOptions extends PureComponent {
  static propTypes = {
    options: productOptionProps.isRequired,
    authUser: authUserProps.isRequired,
    modals: PropTypes.object.isRequired,
    loads: PropTypes.object.isRequired,
    toggleModal: PropTypes.func.isRequired,
    addProducts: PropTypes.func.isRequired,
    getSubProducts: PropTypes.func.isRequired,
    parentProduct: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      enableAction: false,
      crmid: [],
      select: -1,
    };
  }

  componentWillReceiveProps(next) {
    const { modals } = next;
    if (modals.optionsModalIsOpen) {
      document.addEventListener('keydown', this.handleKeyDownToSelection);
    } else {
      document.removeEventListener('keydown', this.handleKeyDownToSelection);
    }
  }

  addElement = (item) => {
    const enableAction = true;
    item.quantity = 1;
    this.setState(prevState => ({ crmid: [...prevState.crmid, item], enableAction }));
  };

  focusQty = (product) => {
    const element = document.querySelector(`#key_${product.crmid}`);
    // @ts-ignore
    if (element) element.focus();
  };

  focusElement = (element) => {
    if (element) {
      element.focus();
      return true;
    }
    return false;
  }

  removeElement = (item) => {
    let enableAction = true;
    const { crmid } = this.state;
    const state = crmid.filter(el => el.crmid !== item.crmid);
    if (state.length === 0) enableAction = false;
    this.setState({ crmid: state, enableAction });
  };

  addInStock = (id) => {
    const { crmid } = this.state;
    const state = crmid.map((x) => {
      if (x.crmid === id) {
        const newValue = x.quantity + 1;
        x.quantity = newValue;
      }
      return x;
    });
    this.setState({ crmid: state });
  };

  handleOnChangeInStock = (id, value) => {
    const { crmid } = this.state;
    const state = crmid.map((x) => {
      if (x.crmid === id) {
        x.quantity = Number.parseFloat(value);
      }
      return x;
    });
    this.setState({ crmid: state });
  };

  removeInStock = (id) => {
    const { crmid } = this.state;
    const state = crmid.map((x) => {
      if (x.crmid === id) {
        const newValue = x.quantity - 1;
        x.quantity = newValue;
      }
      return x;
    });
    this.setState({ crmid: state });
  };

  addSingleProductToCart = async (product) => {
    const { authUser, addProducts, toggleModal } = this.props;
    if (haveStock(product, authUser)) {
      const dataset = {
        crmid: product.crmid,
        parentid: product.parentId,
        quantity: product.quantity || 1,
      };

      await addProducts({ dataset });
    }
    if (isAndroid) {
      history.push('/pos/caja');
    } else {
      history.push('/');
    }
    this.setState({ select: 0 });
    await toggleModal('options');
  }

  addProductsToCart = () => {
    const { crmid } = this.state;
    const { authUser, toggleModal, addProducts } = this.props;
    crmid.forEach(async (product) => {
      if (product.quantity <= 0) product.quantity = 1;
      if (haveStock(product, authUser)) {
        const dataset = {
          crmid: product.crmid,
          parentid: product.parentId,
          quantity: product.quantity,
        };
        await addProducts({ dataset });
      }
    });
    toggleModal('options');
    this.setState({ crmid: [], enableAction: false });
  }

  closeDialogAndReset = () => {
    const { toggleModal } = this.props;
    this.setState({ crmid: [], enableAction: false });
    toggleModal('options');
  }

  /* Selection */
  handleKeyDownToSelection = async (e) => {
    let { keyCode } = e;
    const { options, authUser } = this.props;
    const { crmid, select } = this.state;
    const { fastMode } = authUser;
    const max = options.length;
    const product = options[select];
    keyCode = String(keyCode);
    switch (keyCode) {
      case '13':
        if (select !== -1) {
          if (!(crmid.includes(product))) {
            await this.addElement(product);
          } else {
            if (crmid.length > 1) {
              await this.addProductsToCart();
            } else {
              await this.addSingleProductToCart(product);
            }
            await this.setState({ select: 0, crmid: [] });
            if (!fastMode) await this.focusQty(product);
          }
        }
        break;
      case '27':
        this.setState({ select: -1 });
        const active = document.activeElement;
        if (active.tagName.toLocaleLowerCase() === 'input') {
          // @ts-ignore
          document.activeElement.blur();
        } else {
          this.closeDialogAndReset();
          history.push('/');
        }
        break;
      case '46':
        if (crmid.includes(product)) {
          await this.removeElement(product);
        }
        break;
      case '38':
        e.preventDefault();
        if (select === 0) {
          this.setState({
            select: max - 1,
          });
        } else {
          this.setState(prevState => ({
            select: prevState.select - 1,
          }));
        }
        // @ts-ignore
        document.activeElement.blur();
        break;
      case '40':
        e.preventDefault();
        if (select === (max - 1)) {
          this.setState({
            select: 0,
          });
        } else {
          this.setState(prevState => ({
            select: prevState.select + 1,
          }));
        }
        // @ts-ignore
        document.activeElement.blur();
        break;
      default:
        break;
    }
  }

  render() {
    const { modals, loads, options, parentProduct, getSubProducts } = this.props;
    const { crmid, enableAction, select } = this.state;
    return (
      <FullDialog
        color="primary"
        isOpen={Boolean(modals.optionsModalIsOpen)}
        isLoading={Boolean(loads.productIsLoading)}
        title={`Seleccionar opción para: ${parentProduct}`}
        onClose={() => {
          this.setState({ select: -1 });
          this.closeDialogAndReset();
          history.push('/');
        }}
        onSave={() => {
          this.setState({ select: 0 });
          this.addProductsToCart();
        }}
        enableAction={enableAction}
      >
        <ProductOptionMobile
          addElement={this.addElement}
          addInStock={this.addInStock}
          addSingleProductToCart={this.addSingleProductToCart}
          crmid={crmid}
          focusQty={this.focusQty}
          handleOnChangeInStock={this.handleOnChangeInStock}
          isReloading={Boolean(loads.subProductsIsLoading)}
          options={options}
          getSubProducts={getSubProducts}
          removeElement={this.removeElement}
          removeInStock={this.removeInStock}
          select={select}
        />
      </FullDialog>
    );
  }
}

export default ProductOptions;
