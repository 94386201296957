/* eslint-disable react/prop-types */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Avatar,
  Badge,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DeleteIcon from '@material-ui/icons/Delete';
import { formatMoney } from '../../../helpers/tools';
import { deleteShipping } from '../../../redux/actions/shippingActions';
import useOnClickOutside from '../../../hooks/useClickOutside';

const styles = theme => ({
  badge: {
    top: '13px',
    right: 10,
    // The border color match the background color.
    border: `2px solid ${
      theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[900]
    }`,
  },
});

const CartShippingAction = (props) => {
  const { classes } = props;
  const ref = useRef();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const shipping = useSelector(state => state.shipping);
  const { shippingList } = shipping;
  useOnClickOutside(ref, () => setOpen(false));

  const removeShip = (ship) => {
    dispatch(deleteShipping(ship));
  };
  return (
    <div ref={ref} className="cart-deliver" style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Badge badgeContent={shippingList.length} color="secondary" classes={{ badge: classes.badge }}>
        <IconButton
          id="customer-actions-btn"
          aria-label="More"
          aria-controls="customer-actions-menu"
          aria-haspopup="true"
          onClick={() => setOpen(!open)}
        >
          <FontAwesomeIcon
            icon="truck"
            style={{ width: '20px', heigth: '20px' }}
          />
        </IconButton>
      </Badge>
      {open && (
        <div
          className="list-popup"
          style={{
            position: 'absolute',
            // backgroundColor: '#f0f0f0',
            minWidth: '390px',
            left: '0',
            top: '40px',
            transform: 'translate(-50%, 0)',
            zIndex: 9,
            borderRadius: '8px',
            padding: '10px',
          }}
        >
          <Paper elevation={1}>
            <div
              className="caret"
              style={{
                height: '15px',
                width: '15px',
                backgroundColor: 'whitesmoke',
                transform: 'rotate(45deg)',
                position: 'absolute',
                top: '2px',
                zIndex: -1,
                left: 'calc(50% + 12.5px)',
                boxShadow: '0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)',
              }}
            />
            <List>
              {shippingList.length > 0
                    && shippingList.map((product, index) => (
                      <>
                        <ListItem key={`pr_${index}`}>
                          <Avatar src={product.carrier_logo_url} alt="image" />
                          <ListItemText
                            primary={product?.opcion}
                            secondary={(
                              <>
                                <span>
                                  $
                                  {' '}
                                  {formatMoney(product?.unit_price)}
                                </span>
                                {' | '}
                                <span>
                                  cant:
                                  {' '}
                                  {product?.products?.length || 0}
                                </span>
                              </>
                            )}
                          />
                          <ListItemSecondaryAction>
                            <IconButton aria-label="Delete" onClick={() => removeShip(product)}>
                              <DeleteIcon />
                            </IconButton>
                          </ListItemSecondaryAction>
                        </ListItem>
                        {
                            shippingList.length - 1 !== index
                            && <Divider />
                        }
                      </>
                    ))}
              {shippingList.length === 0 && (
                <ListItem>
                  <ListItemText primary="No hay productos de envío" />
                </ListItem>
              )}
            </List>
          </Paper>
        </div>
      )}
    </div>
  );
};

export default withStyles(styles)(CartShippingAction);
