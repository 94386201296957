export const GET_UPDATES = 'GET_UPDATES';
export const GET_EMAIL_LIST = 'GET_EMAIL_LIST';
export const GET_ACCOUNT_BALANCE = 'GET_ACCOUNT_BALANCE';
export const GET_ACCOUNT_CONTACT_EMAILS = 'GET_ACCOUNT_CONTACT_EMAILS';
export const GET_ACCOUNT_CONTACT_PHONES = 'GET_ACCOUNT_CONTACT_PHONES';
export const GET_ACCOUNT_CONTACT_ADDRESS = 'GET_ACCOUNT_CONTACT_ADDRESS';
export const GET_ACCOUNT_DOCUMENTS = 'GET_ACCOUNT_DOCUMENTS';

export const GET_UPDATES_PAGINATOR = 'GET_UPDATES_PAGINATOR';
export const GET_EMAIL_LIST_PAGINATOR = 'GET_EMAIL_LIST_PAGINATOR';
export const GET_ACCOUNT_DOCUMENTS_PAGINATOR = 'GET_ACCOUNT_DOCUMENTS_PAGINATOR';

export const GET_ACCOUNT = 'GET_ACCOUNT';
export const GET_ACCOUNT_PAGINATOR = 'GET_ACCOUNT_PAGINATOR';
