import {
  SET_SEARCH, SET_ORDER, SET_DISCOUNT_TAXES, DISABLE_DISCOUNT_TAXES, SET_FILTER_VALUE, SET_ORDER_FILTERS, SET_QUOTES_FILTERS,
} from '../types/filterActions.type';

export function changeSearchFilter(searchValue) {
  return {
    type: SET_SEARCH,
    payload: searchValue,
  };
}

export function changeOrderFilter(orderValue) {
  return {
    type: SET_ORDER,
    payload: orderValue,
  };
}

export function changeFilter(filterValue) {
  return {
    type: SET_FILTER_VALUE,
    payload: filterValue,
  };
}

export function toggleDiscountTaxes() {
  return {
    type: SET_DISCOUNT_TAXES,
  };
}

export function disableDiscountTaxes() {
  return {
    type: DISABLE_DISCOUNT_TAXES,
  };
}

export function setOrderFilters(filters) {
  return {
    type: SET_ORDER_FILTERS,
    payload: filters,
  };
}

export function setQuoteFilters(filters) {
  return {
    type: SET_QUOTES_FILTERS,
    payload: filters,
  };
}


export const setSearchFilter = searchValue => async (dispatch) => {
  dispatch(changeSearchFilter(searchValue));
};

export const setOrderFilter = orderValue => async (dispatch) => {
  dispatch(changeOrderFilter(orderValue));
};

export const setFilterValue = filterValue => async (dispatch) => {
  dispatch(changeFilter(filterValue));
};

export const setDiscountTaxes = () => async (dispatch) => {
  dispatch(toggleDiscountTaxes());
};

export const setDisableDiscountTaxes = () => async (dispatch) => {
  dispatch(disableDiscountTaxes());
};

export const setActiveOrderFilters = filters => async (dispatch) => {
  dispatch(setOrderFilters(filters));
};

export const setActiveQuoteFilters = filters => async (dispatch) => {
  dispatch(setQuoteFilters(filters));
};
