import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  Tooltip,
  IconButton,
} from '@material-ui/core';

import { toast } from 'react-toastify';
import TimbrarApi from '../../../../../api/TimbrarApi';
import SatLogo from './SatLogo';

const TimbrarAction = ({
  enable,
  tooltip,
  timbrar,
}) => {
  const config = useSelector(state => state.authUser.user.config);
  const cart = useSelector(state => state.cart);
  const { orderSelected: { crmid } } = cart;
  const { pos_no_facturar } = config;
  const canMakeInvoice = pos_no_facturar === '1' ? true : !enable;
  const timbrarApi = new TimbrarApi();

  const chackInvoice = async () => {
    if (cart.orderSelected) {
      const response = await timbrarApi.getList(crmid);
      const { result } = response;
      if (result.length === 0) {
        timbrar();
      } else {
        toast.warn('Esa orden ya fue timbrada');
      }
    }
  };


  return (
    <Tooltip
      title={tooltip}
      placement="left"
    >
      <span>
        <IconButton
          id="action_control_sat"
          disabled={canMakeInvoice}
          onClick={() => chackInvoice()}
          className="pos_menu_setDir"
        >
          <SatLogo fill={!canMakeInvoice ? '#303c52' : '#c7c7c7'} />
          <span className="font-size-10">Timbrar</span>
        </IconButton>
      </span>
    </Tooltip>
  ); };

TimbrarAction.propTypes = {
  tooltip: PropTypes.string,
  enable: PropTypes.bool.isRequired,
  timbrar: PropTypes.func.isRequired,
};
TimbrarAction.defaultProps = {
  tooltip: 'Timbrar Orden',
};

export default TimbrarAction;
