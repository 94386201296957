/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import '../../../ClientPage/components/SalesDetail/SalesDetail.scss';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faShareAlt,
} from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { formatMoney } from '../../../../helpers/tools';


const WSalesExpand = ({
  sale, openShare, openGuide, deliverSale,
}) => {
  const loads = useSelector(state => state.loads);
  return (
    <>
      <List component="nav" aria-label="main mailbox folders" dense>
        <ListItem divider selected classes={{ selected: 'salesDetail-list-selected' }}>
          <ListItemText className="ml-2 text-left" primary="No. Orden:" classes={{ primary: 'salesDetail-list-text-left' }} />
          <ListItemText className="p-0 text-right" primary={sale.salesorder_no} classes={{ primary: 'salesDetail-list-text-right' }} />
        </ListItem>

        <ListItem divider classes={{ selected: 'salesDetail-list-selected' }}>
          <ListItemText className="ml-2 text-left" primary="Guías envío:" classes={{ primary: 'salesDetail-list-text-left' }} />
          <ListItemText className="p-0 text-right" primary={sale.guias_envio ? sale.guias_envio : '-'} classes={{ primary: 'salesDetail-list-text-right' }} />
        </ListItem>

        <ListItem divider classes={{ selected: 'salesDetail-list-selected' }}>
          <ListItemText className="ml-2 text-left" primary="Almacenista:" classes={{ primary: 'salesDetail-list-text-left' }} />
          <ListItemText className="p-0 text-right" primary={sale.almacenista ? sale.almacenista : '-'} classes={{ primary: 'salesDetail-list-text-right' }} />
        </ListItem>

        <ListItem divider classes={{ selected: 'salesDetail-list-selected' }}>
          <ListItemText className="ml-2 text-left" primary="Fecha venta:" classes={{ primary: 'salesDetail-list-text-left' }} />
          <ListItemText className="p-0 text-right" primary={sale.f_venta ? moment(sale.f_venta).format('DD MMM YYYY h:mm a') : '-'} classes={{ primary: 'salesDetail-list-text-right' }} />
        </ListItem>

        <ListItem divider classes={{ selected: 'salesDetail-list-selected' }}>
          <ListItemText className="ml-2 text-left" primary="Fecha apartada:" classes={{ primary: 'salesDetail-list-text-left' }} />
          <ListItemText className="p-0 text-right" primary={sale.f_estado_aparta ? moment(sale.f_estado_aparta).format('DD MMM YYYY h:mm a') : '-'} classes={{ primary: 'salesDetail-list-text-right' }} />
        </ListItem>

        <ListItem divider classes={{ selected: 'salesDetail-list-selected' }}>
          <ListItemText className="ml-2 text-left" primary="Fecha autorizada:" classes={{ primary: 'salesDetail-list-text-left' }} />
          <ListItemText className="p-0 text-right" primary={sale.f_estado_autori ? moment(sale.f_estado_autori).format('DD MMM YYYY h:mm a') : '-'} classes={{ primary: 'salesDetail-list-text-right' }} />
        </ListItem>

        <ListItem divider classes={{ selected: 'salesDetail-list-selected' }}>
          <ListItemText className="ml-2 text-left" primary="Almacén:" classes={{ primary: 'salesDetail-list-text-left' }} />
          <ListItemText className="p-0 text-right" primary={sale.treebesalmid_label} classes={{ primary: 'salesDetail-list-text-right' }} />
        </ListItem>

        <ListItem divider classes={{ selected: 'salesDetail-list-selected' }}>
          <ListItemText className="ml-2 text-left" primary="Balance:" classes={{ primary: 'salesDetail-list-text-left' }} />
          <ListItemText className="p-0 text-right" primary={`$${formatMoney(sale.tfde_balance)}`} classes={{ primary: 'salesDetail-list-text-right' }} />
        </ListItem>
      </List>

      <List component="nav" aria-label="secondary mailbox folders" className="d-flex justify-content-around">
        <button
          type="button"
          disabled={loads.orderIsLoading}
          onClick={() => openShare(true)}
          style={{
            backgroundColor: loads.orderIsLoading ? '#b7bfc6' : '#23A1DB', color: 'white', fontSize: 15, padding: '10px 20px', borderRadius: 17, border: 0,
          }}
        >
          <FontAwesomeIcon style={{ fontSize: 15 }} icon={faShareAlt} />
        </button>
        <button
          type="button"
          disabled={loads.orderIsLoading}
          onClick={() => openGuide(true)}
          style={{
            backgroundColor: loads.orderIsLoading ? '#b7bfc6' : '#23A1DB', color: 'white', fontSize: 15, padding: '10px 20px', borderRadius: 17, border: 0,
          }}
        >
          Guias
        </button>
        <button
          type="button"
          onClick={(e) => {
            e.preventDefault();
            deliverSale(true);
          }}
          disabled={sale.estado_mda === 'Entregado' || loads.orderIsLoading}
          style={{
            backgroundColor: sale.estado_mda === 'Entregado' ? '#b7bfc6' : '#23A1DB', color: 'white', fontSize: 15, padding: '10px 20px', borderRadius: 17, border: 0,
          }}
        >
          Entregar
        </button>
      </List>
    </>
  ); };

WSalesExpand.propType = {
  sale: PropTypes.object.isRequired,
  openShare: PropTypes.func.isRequired,
  openGuide: PropTypes.func.isRequired,
  deliverSale: PropTypes.func.isRequired,
};

export default WSalesExpand;
