import fetch from 'cross-fetch';
import appModules from '../constant/appModules';
import ConfigurationAPI from './ConfigurationAPI';

const formatFilters = (searchParams) => {
  let counter = 0;
  const filters = [...searchParams];
  const singleFilters = [];
  filters.forEach((filter) => {
    if (!singleFilters.length) {
      singleFilters.push(filter);
      return;
    }
    singleFilters.map((currentFilter) => {
      if (currentFilter.includes(filter[0])) {
        counter += 1;
      }
    });
    if (counter === 0) {
      singleFilters.push(filter);
    } else {
      counter = 0;
    }
  });

  return singleFilters;
};
class OrderAPI extends ConfigurationAPI {
  getAll = async (fde = '', mda = '', sos = '') => {
    try {
      let params = '';
      const existParams = `${
        fde !== '' || mda !== '' || sos !== '' ? '?' : ''
      }`;
      if (existParams === '?') {
        const fdeString = fde !== '' ? `${fde}&` : '';
        const mdaString = mda !== '' ? `${mda}&` : '';
        const sosString = sos !== '' ? `${sos}&` : '';
        params = params.concat(existParams, fdeString, mdaString, sosString);
      }
      const response = await fetch(
        `${this.url}/list/SalesOrder${params}`,
        this.credentials,
      );
      const json = await response.json();
      return { ...json };
    } catch {
      return { succes: false, result: [], message: 'Error en la API' };
    }
  };

  editOrder = async (
    cart,
    discount,
    customer,
    payments = [],
    sostatus,
    factura = null,
    envio = null,
    filters,
  ) => {
    this.credentials.method = 'PUT';
    const { discountTaxes } = filters;
    const {
      orderSelected: { crmid },
    } = cart;
    const { promotions, activePromotions } = cart;
    const totalCart = cart.total;
    const totalDiscount = discount.total;
    const totalToPay = Number((totalCart - totalDiscount).toFixed(2));

    let products = [];
    cart.products.forEach((product) => {
      const productid = product.productid && product.productid.indexOf('x') !== -1
        ? product.productid.split('x').pop()
        : product.productid;
      const custom = {
        option: [
          {
            product_option_value_id: product.crmid || productid,
          },
        ],
        discount_percent: product.discount_percent,
        price:
          product.priceWithTaxes === 0
            ? 0.01
            : product.priceWithTaxes / (1 - product.discount_percent / 100),
        pu: product.unit_price,
        product_id: product.parentId,
        productid: product.crmid || productid,
        quantity: product.quantity,
        comment: product.comment,
      };
      products.push(custom);
    });
    /**
     * Working on promotions
     */
    if (activePromotions) {
      products = products.filter(prod => prod.price > 0.02);
      if (promotions && promotions.free_products) {
        promotions.free_products.forEach((product) => {
          const promotionProduct = {
            option: [
              {
                product_option_value_id: product.productid,
              },
            ],
            discount_percent: 0,
            price: product.unit_price,
            pu: product.unit_price,
            product_id: null,
            quantity: product.quantity,
            productid: product.productid,
            comment: '',
          };
          products.push(promotionProduct);
        });
      }
    }

    const data = {
      crmid,
      products,
      sin_impuestos: discountTaxes ? 1 : 0,
      subtotal: cart.subTotal,
      total: totalToPay,
      account_no: customer.selectedCustomer.account_no,
      tpv_payment: payments,
      tpv_discount_amount: discount.fixed,
      tpv_discount_percentage: discount.percentage,
      order_status: sostatus.id,
      comment: cart.description,
      treebesdireccionenvioid: envio,
      treebesdireccionfacturaid: factura,
      TreebesPromociones_Aplicadas:
        promotions && promotions.applied_promos
          ? Object.keys(promotions.applied_promos)
          : [],
    };

    this.credentials.body = JSON.stringify(data);

    const response = await fetch(`${this.url}/SalesOrder`, this.credentials);
    try {
      const json = await response.json();
      return json;
    } catch (err) {
      return {
        success: false,
        status: response.status,
        message: response.error.message,
      };
    }
  };

  editOrderAddress = async (
    isOrder = true,
    orderId,
    factura = null,
    envio = null,
  ) => {
    this.credentials.method = 'PUT';

    const data = {
      crmid: orderId,
      treebesdireccionenvioid: envio,
      treebesdireccionfacturaid: factura,
    };

    this.credentials.body = JSON.stringify(data);

    const response = await fetch(
      `${this.url}/${isOrder ? 'SalesOrder' : 'Quotes'}`,
      this.credentials,
    );
    try {
      const json = await response.json();
      return json;
    } catch (err) {
      return {
        success: false,
        status: response.status,
        message: response.error.message,
      };
    }
  };

  updateOrderAddress = async (
    isOrder = true,
    orderId,
    factura = null,
    envio = null,
  ) => {
    this.credentials.method = 'PUT';

    const response = await fetch(
      `${this.url}/${isOrder ? 'SalesOrder' : 'Quotes'}/UpdateAddress?crmid=${orderId}&treebesdireccionenvioid=${envio}&treebesdireccionfacturaid=${factura}`,
      this.credentials,
    );
    try {
      const json = await response.json();
      return json;
    } catch (err) {
      return {
        success: false,
        status: response.status,
        message: response.error.message,
      };
    }
  };

  setStatus = async (crmid, sostatus, isQuote = false) => {
    this.credentials.method = 'PUT';
    let data = null;
    if (isQuote) {
      data = {
        crmid,
        quotestage: sostatus,
      };
    } else {
      data = {
        crmid,
        sostatus,
      };
    }
    this.credentials.body = JSON.stringify(data);
    try {
      let response = null;
      if (isQuote) {
        response = await fetch(
          `${this.url}/Quotes/updateStatus`,
          this.credentials,
        );
      } else {
        response = await fetch(
          `${this.url}/SalesOrder/updateStatus`,
          this.credentials,
        );
      }
      const json = await response.json();
      return json;
    } catch {
      return { succes: false, result: [], message: 'Error en la API' };
    }
  };

  setAccount = async (crmid, accountid, module = appModules.SalesOrder) => {
    this.credentials.method = 'PUT';
    const data = {
      crmid,
      accountid,
    };
    this.credentials.body = JSON.stringify(data);
    try {
      const response = await fetch(
        `${this.url}/${module}/updateAccount`,
        this.credentials,
      );
      const json = await response.json();
      return json;
    } catch {
      return { succes: false, result: [], message: 'Error en la API' };
    }
  };

  getFiles = async (crmid, page = 1, search = '') => {
    try {
      this.credentials.method = 'GET';
      const response = await fetch(
        `${this.url}/list/Documents?related_to=${crmid}&page=${page}&search=${search}`,
        this.credentials,
      );
      const json = await response.json();
      return json;
    } catch (err) {
      return { succes: false, result: [], message: err };
    }
  };

  uploadFile = async (crmid, files) => {
    this.credentials.method = 'POST';
    const data = {
      salesorderid: crmid,
      files,
    };
    this.credentials.body = JSON.stringify(data);
    try {
      const response = await fetch(
        `${this.url}/SalesOrder/document`,
        this.credentials,
      );
      const json = await response.json();
      return json;
    } catch {
      return {
        succes: false,
        result: [],
        message: 'Error al subir archivo(s)',
      };
    }
  };

  deleteFile = async (crmid) => {
    this.credentials.method = 'DELETE';
    try {
      const response = await fetch(
        `${this.url}/Documents/${crmid}`,
        this.credentials,
      );
      const json = await response.json();
      return json;
    } catch (err) {
      return {
        succes: false,
        result: [],
        message: `Error al borra un archivo: ${err}`,
      };
    }
  };

  shippingCuots = async (data) => {
    const controller = new AbortController();
    this.credentials.method = 'POST';
    this.credentials.body = JSON.stringify(data);
    this.credentials.signal = controller.signal;
    try {
      const response = await fetch(`${this.url}/get/Rates`, this.credentials);
      controller.abort();
      const json = await response.json();
      return json;
    } catch {
      return {
        succes: false,
        result: [],
        message: 'Error al realizar cotizacion',
      };
    }
  };

  getOrder = async (id, almacenId) => {
    try {
      this.credentials.headers.almacen = almacenId;
      this.credentials.method = 'GET';
      const response = await fetch(`${this.url}/id/${id}`, this.credentials);
      const json = await response.json();
      return json;
    } catch {
      return { success: false, message: 'Error en el Host' };
    }
  };

  getList = async (isOrder = true) => {
    try {
      const url = `${this.url}/${
        isOrder ? 'SalesOrder' : 'Quotes'
      }/custom_views`;
      const response = await fetch(url, this.credentials);
      const json = await response.json();
      return { ...json };
    } catch {
      return { succes: false, result: [], message: 'Error en la API' };
    }
  };

  getListView = async (
    isOrder = true,
    cvid,
    page = 1,
    searchParams = [],
    orderBy = 'createdtime',
    sortorder = 'DESC',
  ) => {
    try {
      const filterFormated = formatFilters(searchParams);
      const url = `${this.url}/${
        isOrder ? 'SalesOrder' : 'Quotes'
      }/custom_view/${cvid}?page=${page}&search_params=${
        searchParams.length ? JSON.stringify([filterFormated]) : []
      }&orderby=${orderBy}&sortorder=${sortorder.toUpperCase()}`;

      const response = await fetch(url, this.credentials);
      const json = await response.json();
      return { ...json };
    } catch {
      return { succes: false, result: [], message: 'Error en la API' };
    }
  };

  setDefaultListView = async (listId) => {
    try {
      this.credentials.method = 'PUT';
      const data = {
        id: listId,
      };

      this.credentials.body = JSON.stringify(data);

      const url = `${this.url}/CustomView/SetDefault/${listId}`;
      const response = await fetch(url, this.credentials);
      const json = await response.json();
      return { ...json };
    } catch {
      return { succes: false, result: [], message: 'Error en la API' };
    }
  };

  getOrderDetail = async (orderId) => {
    try {
      const response = await fetch(
        `${this.url}/id/${orderId}`,
        this.credentials,
      );
      const json = await response.json();
      return { ...json };
    } catch {
      return { succes: false, result: [], message: 'Error en la API' };
    }
  };


  backOrder = async (
    orderId,
  ) => {
    this.credentials.method = 'PUT';

    /* const data = {};

    this.credentials.body = JSON.stringify(data); */

    const response = await fetch(
      `${this.url}/SalesOrder/${orderId}/backorder`,
      this.credentials,
    );
    try {
      const json = await response.json();
      return json;
    } catch (err) {
      return {
        success: false,
        status: response.status,
        message: response.error.message,
      };
    }
  };

  trimDeliver = async (
    orderId,
  ) => {
    this.credentials.method = 'PUT';

    /* const data = {};

    this.credentials.body = JSON.stringify(data); */

    const response = await fetch(
      `${this.url}/SalesOrder/${orderId}/trim-to-delivered`,
      this.credentials,
    );
    try {
      const json = await response.json();
      return json;
    } catch (err) {
      return {
        success: false,
        status: response.status,
        message: response.error.message,
      };
    }
  };
}

export default OrderAPI;
