/* eslint-disable import/named */
import React, { useEffect, useRef, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, LinearProgress } from '@material-ui/core';
import PropTypes from 'prop-types';
import Uploader from './components/Uploader/Uploader';
import ListFiles from './components/ListFiles';
import useOrderFile from '../../../hooks/order/useOrderFile';
import useFile from '../../../hooks/file/useFile';
import { toBase64 } from '../../../helpers/file-reader';

const File = ({ orderId }) => {
  const dragRef = useRef();
  const [dragFiles, setDragFiles] = useState();
  const [openFile, setOpenFile] = useState();
  const { isRemoved, download, deleteFile, preview } = useFile();
  const {
    loading, isUploaded, files, getOrderFile, uploadFile,
  } = useOrderFile(orderId);

  async function handleUpload() {
    if (dragFiles.length > 0) {
      let arr = [];
      await Object.keys(dragFiles).forEach(async (key) => {
        const meta = dragFiles[key];
        const { name } = meta;
        toBase64(meta).then(async (f) => {
          arr = [{ name, file: f }];
          await uploadFile(arr);
        });
      });
    }
    setOpenFile(false);
    setDragFiles(null);
  }

  const draggableSection = () => {
    dragRef.current.addEventListener('dragover', (e) => {
      e.preventDefault();
      dragRef.current.style.border = 'dashed';
    });

    dragRef.current.addEventListener('drop', (event) => {
      dragRef.current.style.border = '';
      const { files: dragedFiles } = event.dataTransfer;
      setDragFiles(dragedFiles);
      setOpenFile(true);
      event.preventDefault();
    });

    dragRef.current.addEventListener('dragleave', (event) => {
      dragRef.current.style.border = '';
      event.preventDefault();
    });
  };

  useEffect(() => {
    getOrderFile();
  }, []);

  useEffect(() => {
    if (isRemoved || isUploaded) {
      getOrderFile();
    }
  }, [isRemoved, isUploaded]);

  useEffect(() => {
    draggableSection();
    return () => {
      if (dragRef?.current) {
        dragRef.current.removeEventListener('dragleave', () => {});
        dragRef.current.removeEventListener('dragover', () => {});
        dragRef.current.removeEventListener('drop', () => {});
      }
    };
  });
  function handleCloseFile() {
    setOpenFile(false);
    setDragFiles(null);
  }

  return (
    <div style={{ height: '100%' }}>
      {
        loading && <LinearProgress />
      }
      <Uploader upload={uploadFile} />
      <Divider className="my-1" />
      <div ref={dragRef} style={{ height: '100%' }}>
        <ListFiles
          files={files}
          loading={loading}
          download={download}
          count={files.length}
          remove={deleteFile}
        />
      </div>
      <Dialog
        open={openFile}
        onClose={handleCloseFile}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Agregar archivo a la orden
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Esta seguro que quiere agregar el archivo?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseFile} color="primary">
            Cancelar
          </Button>
          <Button
            type="button"
            onClick={handleUpload}
            color="primary"
            autoFocus
          >
            {loading ? 'Subiendo' : 'Aceptar'}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  ); };

File.propTypes = {
  orderId: PropTypes.string.isRequired,
};

export default File;
