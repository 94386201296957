import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';
import {
  Fab,
  Zoom,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import InputButton from '../../../../Input/Button';

const RefundConfirm = ({
  tab,
  loading,
  showButton,
  tooltip,
  onSubmit,
  disabled,
}) => {
  const [open, setOpen] = useState(false);
  if (isMobile) {
    return (
      <div className="refund_confirm">
        <Zoom
          in={showButton}
          unmountOnExit
        >
          <Tooltip
            title={tooltip}
          >
            <Fab
              color={tab === 1 ? 'primary' : 'secondary'}
              onClick={() => onSubmit()}
              disabled={disabled}
            >
              <FontAwesomeIcon
                icon="dolly"
                style={{ width: '28px', height: '28px' }}
              />
            </Fab>
          </Tooltip>
        </Zoom>
      </div>
    );
  }
  return (
    <>
      <div className="refund_confirm_desktop">
        <InputButton
          title={`Realizar Devolución ${tab === 0 ? 'Total' : 'Parcial'}`}
          click={() => {
            if (tab === 0) {
              onSubmit();
            } else {
              setOpen(true);
            }
          }}
          disabled={disabled}
          loading={loading}
        />
      </div>
      <Dialog open={open} onClose={() => setOpen(false)} aria-labelledby="simple-dialog-title">
        <DialogTitle id="simple-dialog-title">Devolución Parcial</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Al hacer una devolucion parcial la venta original sera modificada eliminando los productos que va a devolver.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            No
          </Button>
          <Button
            onClick={() => {
              onSubmit();
              setOpen(false);
            }}
            color="primary"
            autoFocus
          >
            Si
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

RefundConfirm.propTypes = {
  tab: PropTypes.number.isRequired,
  showButton: PropTypes.bool,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  tooltip: PropTypes.string,
  onSubmit: PropTypes.func,
};

RefundConfirm.defaultProps = {
  showButton: true,
  loading: false,
  disabled: false,
  tooltip: 'Realizar devolución',
  onSubmit: () => {},
};

export default RefundConfirm;
