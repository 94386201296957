import {
  CircularProgress,
  Dialog, DialogContent, DialogTitle, Table, TableBody, TableCell, TableHead, TableRow, withStyles,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ExpensesAPI from '../../api/ExpensesApi';
import ProductAPI from '../../api/ProductAPI';
import { updateItems, updateStore } from '../../redux/actions/tableActions';

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.action.hover,
    color: '#313C52',
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const PubilcWerehouseDialog = ({ open, handleClose }) => {
  const [isLoadingStores, setIsLoadingStores] = useState(false);
  const orderSelected = useSelector(state => state.cart?.orderSelected);
  const dispatch = useDispatch();
  const [almacenes, setAlmacenes] = useState([]);

  const getStores = async () => {
    setIsLoadingStores(true);
    const { almid } = orderSelected;
    const storeApi = new ExpensesAPI();
    const response = await storeApi.getAlmacen();

    const { success, result } = response;
    if (success) {
      const publicStore = result.filter(store => store.public_inventory === '1' && store.crmid !== almid);
      setAlmacenes(publicStore);
    }
    setIsLoadingStores(false);
  };

  const selectStore = async (store) => {
    const { crmid } = orderSelected;
    const productApi = new ProductAPI();
    const response = await productApi.getOrderDetail(crmid, crmid, store.crmid, 'SCP');

    if (response.stock) {
      dispatch(updateStore(store));
      dispatch(updateItems(response.stock));
      handleClose();
    }
  };

  useEffect(() => {
    if (open) {
      getStores();
    }
  }, [open]);


  return (
    <Dialog
      open={open}
      onClose={handleClose}
      disableBackdropClick
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Seleccione un almacenes públicos</DialogTitle>
      <DialogContent>
        <div>
          {
            isLoadingStores
              ? (<CircularProgress />)
              : (
                <div>
                  {
                  almacenes && almacenes.length
                    ? (
                      <Table aria-label="customized table">
                        <TableHead>
                          <TableRow>
                            <StyledTableCell style={{ fontSize: 16, fontWeight: 600 }}>Almacénes</StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {
                          almacenes.map(stock => (
                            <TableRow key={stock.crmid} onClick={() => selectStore(stock)} style={{ cursor: 'pointer' }}>
                              <StyledTableCell component="th" scope="row">
                                {stock.almacen}
                              </StyledTableCell>
                            </TableRow>
                          ))
                        }
                        </TableBody>
                      </Table>
                    )
                    : (
                      <span>
                        No se encontraron almacénes
                      </span>
                    )
                }
                </div>
              )
          }
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default PubilcWerehouseDialog;
