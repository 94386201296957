/* eslint-disable import/prefer-default-export */

import {
  SET_SHIPPING_DATA, CLEAR_SHIPPING_DATA, DELETE_SHIPPING_DATA,
} from '../types/shippingActions.type';

export function addShipping(shipping) {
  return {
    type: SET_SHIPPING_DATA,
    payload: shipping,
  };
}
export function deleteShipping(shipping) {
  return {
    type: DELETE_SHIPPING_DATA,
    payload: shipping,
  };
}

export function clearShipping() {
  return {
    type: CLEAR_SHIPPING_DATA,
  };
}
