/* eslint-disable import/no-cycle */
/* eslint-disable object-curly-newline */

import React from 'react';
import PropTypes from 'prop-types';

import { useSelector } from 'react-redux';
import WharehouseForm from '../../Wharehouse/components/WharehouseForm';
import FullDialog from '../../FullDialog';

const RefundDialog = ({
  authUser,
  dispatchChangeComment,
  dispatchChangeFieldValue,
  dispatchSelectEmpty,
  dispatchSelectToDelivered,
  dispatchSelectToRefunded,
  dispatchChangeBadProducts,
  dispatchChangeProducts,
  isLoading,
  isOpen,
  loads,
  onClose,
  onSave,
  refundAll,
  table,
  title,
}) => {
  const cart = useSelector(state => state.cart);
  return (
    <FullDialog
      color="secondary"
      enableAction={false}
      isLoading={isLoading}
      isOpen={isOpen}
      onClose={onClose}
      onSave={onSave}
      title={title}
    >
      <WharehouseForm
        activity="refund"
        authUser={authUser}
        dispatchChangeComment={dispatchChangeComment}
        dispatchChangeFieldValue={dispatchChangeFieldValue}
        dispatchSelectEmpty={dispatchSelectEmpty}
        dispatchSelectToDelivered={dispatchSelectToDelivered}
        dispatchSelectToRefunded={dispatchSelectToRefunded}
        dispatchChangeBadProducts={dispatchChangeBadProducts}
        dispatchChangeProducts={dispatchChangeProducts}
        loads={loads}
        refundAll={refundAll}
        table={table}
        cart={cart}
      />
    </FullDialog>
  ); };

RefundDialog.propTypes = {
  authUser: PropTypes.object.isRequired,
  dispatchChangeComment: PropTypes.func.isRequired,
  dispatchChangeFieldValue: PropTypes.func.isRequired,
  dispatchSelectEmpty: PropTypes.func.isRequired,
  dispatchSelectToDelivered: PropTypes.func.isRequired,
  dispatchSelectToRefunded: PropTypes.func.isRequired,
  dispatchChangeBadProducts: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
  loads: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  refundAll: PropTypes.func.isRequired,
  table: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
};

export default RefundDialog;
