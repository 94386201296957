/* eslint-disable consistent-return */
/* eslint-disable import/no-cycle */
/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable radix */
/* eslint-disable no-param-reassign */
/* eslint camelcase: 0 */
/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */

import { getItem } from './storage';
import {
  paginate,
  numberFormat,
  wordInString,
  filterInCollection,
} from './tools';

const _normalizeOption = (_option, parentId, exist) => {
  const option = { ..._option };

  /* Temporal */
  const cartProductsById = getItem('cartProductsById');

  option.qtyinstock = numberFormat(option.qtyinstock);
  option.unit_price = numberFormat(option.unit_price, 6);
  option.tax1 = numberFormat(option.tax1);
  option.tax2 = numberFormat(option.tax2);
  option.tax3 = numberFormat(option.tax3);
  option.tax4 = numberFormat(option.tax4);
  option.tax5 = numberFormat(option.tax5);
  option.tax6 = numberFormat(option.tax6);
  option.tax7 = numberFormat(option.tax7);
  option.weight = numberFormat(option.weight);

  /* Extra */
  const { unit_price, tax1, crmid } = option;
  let taxIva = tax1;
  if (taxIva === undefined) taxIva = 0;
  option.totalPrice = (unit_price * (1 + (taxIva / 100) || 0));
  option.totalPrice = numberFormat(option.totalPrice);
  option.parentId = parentId;
  option.sin_existencia = Boolean(exist);

  if (crmid in cartProductsById) {
    option.qtyinstock = cartProductsById[crmid].qtyinstock - cartProductsById[crmid].quantity;
  }

  return option;
};

const _normalizeProduct = (_product) => {
  const product = { ..._product };
  const noImage = `${process.env.PUBLIC_URL}/img/no_image.jpg`;
  product.unit_price = numberFormat(product.unit_price, 6);
  product.image = product.images[0] || noImage;
  return product;
};

export const normalizeProducts = products => (
  new Promise((resolve) => {
    const productsById = { ...products };

    for (const productId in productsById) {
      let product = productsById[productId];
      product = _normalizeProduct(product);

      const productOptionsById = product.Products;
      const { crmid, sin_existencia } = product;

      for (const optionId in productOptionsById) {
        let option = productOptionsById[optionId];
        option = _normalizeOption(option, crmid, sin_existencia);
        productOptionsById[optionId] = option;
      }

      product.Products = productOptionsById;
      productsById[productId] = product;
    }

    resolve(productsById);
  })
);

export const makeProductsArray = productsById => (
  Object.keys(productsById)
    .map((productId) => {
      const product = { ...productsById[productId] };
      product.Products = Object.keys(product.Products).map(optionId => product.Products[optionId]);
      const qtyInStock = product.Products.map(x => x.qtyinstock);
      const reducer = qtyInStock.reduce((a, b) => (a + b));
      product.existencia = reducer;
      if (product.Products[0].codigo_barras !== '.') {
        const codigoBarras = product.Products.map(x => x.codigo_barras);
        product.codigo_barras = codigoBarras.join(' - ');
      }
      return product;
    })
);

export const haveStock = (product, authUser) => {
  const { qtyinstock, sin_existencia } = product;
  return (authUser.user.config.pos_sininv || sin_existencia) || qtyinstock > 0;
};

/**
 * Return if exist stock in BD, it return a Promise, its need a prev fetch on ProductsAPI.get
 * @param {object} result Result on ProductsAPI.get
 * @param {string} crmid Option VTiger ID
 * @param {Number} quantity Entry quantity
 * @param {object} cart Cart state
 */
export const haveStockBD = async (result, crmid, quantity = null, cart) => {
  await new Promise(resolve => setTimeout(resolve));
  return new Promise(async (resolve, reject) => {
    const { success, message } = result;

    if (success) {
      const { result: { qtyinstock, qtyindemand, inventario } } = result;
      const inStock = Number.parseInt(inventario) - Number.parseInt(qtyindemand);
      const { cartProductsByArray } = cart;
      const qty = quantity || 1;
      const productsArray = cartProductsByArray.filter(x => (x.crmid === crmid)).map(x => x.quantity);
      let sumQty = 0;
      if (productsArray.length === 0) {
        sumQty = qty;
      } else if (productsArray.length === 1) {
        sumQty = productsArray[0] + qty;
      } else sumQty = productsArray.reduce((a, b) => (a + b), qty);
      // @ts-ignore
      if (Number.parseInt(sumQty) <= inStock) {
        resolve({ status: true, sumQty, inStock });
      } else {
        resolve({ status: false, sumQty, inStock });
      }
    } else {
      reject(message);
    }
  });
};

export const haveProductStock = (product, cart) => {
  // eslint-disable-next-line no-nested-ternary
  const productid = product.crmid ? product.crmid : (product.productid.indexOf('x') !== -1
    ? product.productid.split('x').pop()
    : product.productid);
  const { qtyinstock, quantity } = product;
  const inStock = Number.parseInt(qtyinstock);
  const { cartProductsByArray } = cart;
  const qty = quantity || 1;
  const productsArray = cartProductsByArray.filter(x => (x.crmid === productid)).map(x => x.quantity);
  let sumQty = 0;
  if (productsArray.length === 0) {
    sumQty = qty;
  } else if (productsArray.length === 1) {
    sumQty = productsArray[0] + qty;
  } else sumQty = productsArray.reduce((a, b) => (a + b), qty);
  // @ts-ignore
  if (Number.parseInt(sumQty) <= inStock) {
    return { status: true, sumQty, inStock };
  }

  return { status: false, sumQty, inStock };
};

export const findProducts = (products, word) => {
  const foundProducts = products.filter(product => (
    wordInString(word, product.nombre)
    || wordInString(word, product.categorias)
    || wordInString(word, product.marca)
  ));
  return foundProducts;
};

export const paginatedProducts = (productsByArray, totalItemsForPage, index = 0) => {
  const _paginatedProducts = paginate(productsByArray, totalItemsForPage, index);
  const { page, indexPage, totalPages } = _paginatedProducts;
  const products = page;
  const actualPage = indexPage + 1;
  return {
    products,
    actualPage,
    totalPages,
  };
};

export const subtractStock = (products, productsById, cartProductsById = {}) => {
  const subtractProducts = products.map((_option) => {
    const option = { ..._option };
    const { parentId, crmid } = option;

    if (crmid in cartProductsById) {
      option.qtyinstock = numberFormat(cartProductsById[crmid].qtyinstock) - numberFormat(cartProductsById[crmid].quantity);
    }

    else if (parentId in productsById && crmid in productsById[parentId].Products) {
      option.qtyinstock = numberFormat(productsById[parentId].Products[crmid].qtyinstock);
    }

    return option;
  });

  return subtractProducts;
};

export const mappingOptions = ({
  row,
  sRow,
  sCol,
  column,
  keyCode,
  prevState,
}) => {
  switch (keyCode) {
    case 39:
      if (sCol !== 3 && sRow !== row) {
        return { sCol: prevState.sCol + 1 };
      }
      if (sCol !== column && sRow === row) {
        return { sCol: prevState.sCol + 1 };
      }
      break;
    case 37:
      if (sCol !== 1) {
        return { sCol: prevState.sCol - 1 };
      }
      break;
    case 38:
      if (sRow !== 1) {
        return { sRow: prevState.sRow - 1 };
      }
      break;
    case 40:
      if (sRow !== row) {
        return { sRow: prevState.sRow + 1 };
      }
      if ((sCol === 3 || sCol === 2) && sRow === (row - 1)) {
        return { sCol: column, sRow: row };
      }
      break;
    default:
      return { sCol: 1, sRow: 1 };
  }
};

export const makeProductChildrens = (products) => {
  let productChildren = [];
  products.forEach((item) => {
    const children = Object.keys(item.Products).map(opt => item.Products[opt]);
    children.forEach((option) => {
      option.nombre = option.productname;
      option.existencia = option.qtyinstock;
    });
    productChildren = [...productChildren, ...children];
  });
  return { productChildren, length: productChildren.length };
};

export const getChildrenProducts = (collection, param, element) => filterInCollection(collection, param, element);

/**
 * Return column definition by columndefs schema
 * @param {Object} schema redux/state.product.columndefs
 * @param {string} headerName headerColumn to find
 */
export const getFieldProductByColumnDefs = (schema, headerName) => {
  let arr = [];
  schema.forEach((parent) => {
    const { children } = parent;
    arr = [...arr, ...children];
  });
  return arr.find(x => x.headerName === headerName);
};

export const getProductPriceWithTaxes = (product) => {
  const iva = ((product.tax1) ? numberFormat(product.tax1, 6) : (0));
  const ieps = ((product.tax2 && product.tax2 !== 0) ? numberFormat(product.tax2, 6) : (0));
  const isr = ((product.tax3 && product.tax3 !== 0) ? numberFormat(product.tax3, 6) : (0));
  /* const ivaRet = ((product.tax4 && product.tax4 !== 0) ? numberFormat(product.tax4, 6) : (0));
  const isrRet = ((product.tax5 && product.tax5 !== 0) ? numberFormat(product.tax5, 6) : (0)); */

  const totalPrice = product.unit_price;

  const productIEPS = numberFormat((totalPrice * (ieps / 100)), 6);
  const productISR = numberFormat((totalPrice * (isr / 100)), 6);

  /**
     * IVA: is a compound tax
     * compound tax = (product price + simple taxes(ieps, isr)) * compound tax(iva) / 100
     */
  const priceWithTaxes = (Number.isNaN(iva) || iva === undefined) ? 0 : numberFormat(((totalPrice + productIEPS + productISR) * (iva / 100)), 6);

  return totalPrice + priceWithTaxes;
};

export default {
  haveStock,
  haveStockBD,
  findProducts,
  subtractStock,
  mappingOptions,
  haveProductStock,
  normalizeProducts,
  makeProductsArray,
  paginatedProducts,
  getChildrenProducts,
  makeProductChildrens,
  getFieldProductByColumnDefs,
  getProductPriceWithTaxes,
};
