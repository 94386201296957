/* eslint-disable import/no-cycle */
import { connect } from 'react-redux';
/* Actions */
import { closeModal, toggleModal } from '../../redux/actions/modalActions';
/* Component */
import Tickets from './Tickets';

const mapStateToProps = state => ({
  authUser: state.authUser,
  balance: state.balance,
  cart: state.cart,
  customer: state.customer,
  discount: state.discount,
  loads: state.loads,
  modals: state.modals,
  tabs: state.tabs,
  timbrar: state.timbrar,
  table: state.table,
  // cotizacion: state.cotizacion,
});

const mapDispatchToProps = dispatch => ({
  dispatchToggleTicketsModal: () => dispatch(toggleModal('tickets')),
  dispatchCloseTicketsModal: () => dispatch(closeModal('tickets')),
  dispatchToggleTicketsCCModal: () => dispatch(toggleModal('ticketCC')),
  dispatchToggleTicketsFactura: () => dispatch(toggleModal('ticketsFactura')),
  dispatchToggle: modal => dispatch(toggleModal(modal)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Tickets);
