/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import '../../../ClientPage/components/SalesDetail/SalesDetail.scss';
import List from '@material-ui/core/List';
import { useSelector } from 'react-redux';
import {
  faShareAlt,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const WTransferExpand = ({ receiveTransfer, openShare, purchase }) => {
  const loads = useSelector(state => state.loads);
  return (
    <>
      <List
        style={{ backgroundColor: '#f6f6f6' }}
        component="nav"
        aria-label="secondary mailbox folders"
        className="d-flex justify-content-around"
      >
        <button
          type="button"
          disabled={loads.orderIsLoading}
          onClick={() => openShare(purchase)}
          style={{
            backgroundColor: loads.orderIsLoading ? '#b7bfc6' : '#23A1DB', color: 'white', fontSize: 15, padding: '10px 20px', borderRadius: 17, border: 0,
          }}
        >
          <FontAwesomeIcon style={{ fontSize: 15 }} icon={faShareAlt} />
        </button>
        <button
          type="button"
          onClick={(e) => {
            e.preventDefault();
            receiveTransfer();
          }}
          disabled={loads.orderIsLoading}
          style={{
            backgroundColor: '#23A1DB',
            color: 'white',
            fontSize: 15,
            padding: '10px 20px',
            borderRadius: 17,
            border: 0,
          }}
        >
          Recibir
        </button>
      </List>
    </>
  );
};

WTransferExpand.propType = {
  sale: PropTypes.object.isRequired,
  openShare: PropTypes.func.isRequired,
  receiveTransfer: PropTypes.func.isRequired,
};

export default WTransferExpand;
