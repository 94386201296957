/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';

import { MobileView, BrowserView } from 'react-device-detect';

import {
  Tab,
  Tabs,
  List,
  Card,
  AppBar,
  Avatar,
  Chip,
  ListItem,
  ListItemText,
  ListItemAvatar,
  ListItemSecondaryAction,
} from '@material-ui/core';

import {
  ChevronRight,
  Face,
  StoreMallDirectory,
} from '@material-ui/icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import SwipeableViews from 'react-swipeable-views';
/* Components */
import Status from './components/Status';

/* Panels */
import Caja from './Panels/Caja';
import Ventas from './Panels/Ventas';
import General from './Panels/General';

const Cosntructor = ({
  history,
  authUser,
  PanelInfo,
  generalTitle,
}) => {
  /* State */
  const [value, setValue] = React.useState(0);

  /* Effects */

  /* Functions */
  function handleChange(event, newValue) {
    setValue(newValue);
  }

  function handleChangeIndex(index) {
    setValue(index);
  }

  function renderPanel() {
    const {
      location: { pathname },
    } = history;
    switch (pathname) {
      case '/account/profile/ventas':
        return <Ventas />;
      case '/account/profile/caja':
        return <Caja />;
      default:
        return <General />;
    }
  }

  const {
    location: { pathname },
  } = history;

  const { user: { config } } = authUser;

  /* Render */
  return (
    <>
      <MobileView>
        <div className="w-100 settings_container">
          <Status authUser={authUser} />
          <Card className="w-100 h-100">
            <AppBar position="static" color="primary">
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                variant="fullWidth"
                aria-label="settings-fichas"
              >
                <Tab label="General" />
                <Tab label="Caja" />
                <Tab label="Ventas" />
                <Tab label="Cotizaciones" />
              </Tabs>
            </AppBar>
            <SwipeableViews
              index={value}
              className="w-100 h-100"
              onChangeIndex={handleChangeIndex}
            >
              <div className="w-100 h-100">Panel 1</div>
              <div className="w-100 h-100">Panel 2</div>
              <div className="w-100 h-100">Panel 3</div>
              <div className="w-100 h-100">Panel 4</div>
            </SwipeableViews>
          </Card>
        </div>
      </MobileView>
      <BrowserView>
        <div className="settings_browser">
          <div className="settings_rightBar">
            <Status authUser={authUser} />
            <Card>
              <div className="settings__title px-4 py-2">{generalTitle}</div>
              <List className="settings_list">
                {PanelInfo.map(item => (
                  <ListItem
                    button
                    divider
                    key={`key_panel_item_${item.title}`}
                    onClick={() => history.push(item.path)}
                  >
                    <ListItemAvatar>
                      <Avatar>{item.icon}</Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={item.title}
                      secondary={item.subTitle}
                      color={item.path === pathname ? 'primary' : 'default'}
                    />
                    {item.path === pathname && (
                      <ListItemSecondaryAction>
                        <ChevronRight />
                      </ListItemSecondaryAction>
                    )}
                  </ListItem>
                ))}
              </List>
            </Card>
          </div>
          <div className="settings_body ml-2">
            <Chip
              className="mb-2"
              color={config.pos_config_alm ? 'primary' : 'secondary'}
              icon={config.pos_config_alm ? <StoreMallDirectory /> : <Face />}
              label={config.pos_config_alm ? `Configuraciones del almacén: ${config.store.name}` : 'Configuraciones del Usuario'}
            />
            {renderPanel()}
          </div>
        </div>
      </BrowserView>
    </>
  );
};

Cosntructor.propTypes = {
  PanelInfo: PropTypes.array,
  generalTitle: PropTypes.string,
  history: PropTypes.object.isRequired,
  authUser: PropTypes.object.isRequired,
};

Cosntructor.defaultProps = {
  generalTitle: 'Configuraciones',
  PanelInfo: [
    {
      title: 'Configuración General',
      subTitle: 'Propiedades Generales',
      icon: <FontAwesomeIcon icon="user" />,
      path: '/account/profile/general',
    },
    {
      title: 'Caja',
      subTitle: 'Productos, Entregas, Cobro ...',
      icon: <FontAwesomeIcon icon="cash-register" />,
      path: '/account/profile/caja',
    },
    {
      title: 'Ventas',
      subTitle: 'Ventas sin existencias, Estados de Ordenes ...',
      icon: <FontAwesomeIcon icon="file-invoice-dollar" />,
      path: '/account/profile/ventas',
    },
  ],
};

export default Cosntructor;
