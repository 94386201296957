import React from 'react';
import PropTypes from 'prop-types';
// import { useSelector } from 'react-redux';
/* Material */
import {} from '@material-ui/core';
import {
  List,
  Language,
} from '@material-ui/icons';
/* Device */
import { isMobile } from 'react-device-detect';
/* Components */
import DiscountTabs from './components/DiscountTabs';
// import DiscountTotal from './components/DiscountTotal';
import DiscountInput from './components/DiscountInput';
// import DiscountSubmit from './components/DiscountSubmit';
// import DiscountBalance from './components/DiscountBalance';

const DiscountConstructor = ({
  onSave,
  navTypes,
  products,
  totalToPay,
  dispatchSetDiscount,
}) => {
  /* const cart = useSelector(state => state.cart); */
  const mainCont = isMobile
    ? 'w-100 d-flex flex-column px-2 py-1 minHeightMobile'
    : 'w-100 d-flex flex-column';
  /* const enableModifyPrices = Boolean(products.some(product => product.discount_percent > 0)); */
  /* Hooks */
  const [tab, setTab] = React.useState(1);
  const [type, setType] = React.useState(false);
  const [discount, setDiscount] = React.useState(0);
  const moneyInput = React.useRef();
  const perceInput = React.useRef();
  const enableSubmit = Boolean(discount <= totalToPay && discount !== 0);
  React.useEffect(() => {
    const percInput = document.querySelector('#amount_percentage');
    // @ts-ignore
    if (percInput) percInput.focus();
  }, []);
  /* Functions */
  // @ts-ignore
  const tanOnChange = (e, newValue) => {
    setTab(newValue);
    if (type) setType(false);
    if (newValue === 1) {
      const perceAmount = document.querySelector('#amount_percentage');
      // @ts-ignore
      if (perceAmount) perceAmount.focus();
    }
  };
  const resetMoney = () => {
    // @ts-ignore
    if (moneyInput) moneyInput.current.value = '';
  };
  const resetPerce = () => {
    // @ts-ignore
    if (perceInput) perceInput.current.value = '';
  };
  const onChangeInput = () => {
    if (tab === 0) {
      let perce = '0';
      let money = '0';
      if (!type) {
        // @ts-ignore
        perce = perceInput.current.value;
      } else {
        // @ts-ignore
        money = moneyInput.current.value;
      }
      const moneyValue = (parseFloat(money.replace(/,/g, '')) || 0);
      const perceValue = (parseFloat(perce) || 0);

      /* const totalPercentage = cart.subTotal * (perceValue / 100) * (1 + ((cart.totalIva + cart.anotherTaxes) / cart.subTotal)); */
      const totalPercentage = totalToPay * (perceValue / 100);
      const totalDiscount = totalPercentage + moneyValue;
      setDiscount(totalDiscount);
    } else {
      // @ts-ignore
      const perce = perceInput.current.value;
      const perceValue = (parseFloat(perce) || 0);

      const totalPercentage = totalToPay * (perceValue / 100);
      setDiscount(totalPercentage);
    }
  };
  const submitGlobalDiscount = () => {
    let perce = '0';
    let money = '0';
    if (!type) {
      // @ts-ignore
      perce = perceInput.current.value;
    } else {
      // @ts-ignore
      money = moneyInput.current.value;
    }
    const moneyValue = (parseFloat(money.replace(/,/g, '')) || 0);
    const perceValue = (parseFloat(perce) || 0);
    const result = {
      fixed: moneyValue,
      percentage: perceValue,
      total: discount,
    };
    onSave(result);
  };
  const submitSpecificDiscount = (show = false) => {
    // @ts-ignore
    const perce = perceInput.current.value;
    const perceValue = (parseFloat(perce) || 0);
    dispatchSetDiscount(perceValue, show);
  };
  const submit = (show) => {
    if (enableSubmit) {
      if (tab === 0) {
        submitGlobalDiscount();
      } else {
        submitSpecificDiscount(show);
      }
    }
  };
  return (
    <div className={mainCont}>
      <DiscountTabs
        tab={tab}
        navTypes={navTypes}
        tanOnChange={tanOnChange}
      />
      <DiscountInput
        tab={tab}
        type={type}
        submit={submit}
        setType={setType}
        moneyInput={moneyInput}
        perceInput={perceInput}
        resetMoney={resetMoney}
        resetPerce={resetPerce}
        setDiscount={setDiscount}
        onChangeInput={onChangeInput}
        specific={show => submitSpecificDiscount(show)}
      />
      {/*  <DiscountTotal
        tab={tab}
        totalToPay={totalToPay}
      />
      <DiscountBalance
        tab={tab}
        discount={discount}
        totalToPay={totalToPay}
      />
      <DiscountSubmit
        tab={tab}
        submit={submit}
        discount={discount}
        enableSubmit={enableSubmit}
        enable={Boolean(discount !== 0)}
        enableModifyPrices={enableModifyPrices}
        specific={show => submitSpecificDiscount(show)}
      /> */}
    </div>
  );
};

DiscountConstructor.propTypes = {
  navTypes: PropTypes.array,
  onSave: PropTypes.func.isRequired,
  products: PropTypes.array.isRequired,
  totalToPay: PropTypes.number.isRequired,
  dispatchSetDiscount: PropTypes.func.isRequired,
};
DiscountConstructor.defaultProps = {
  navTypes: [
    {
      label: 'Global',
      icon: <Language />,
    },
    {
      label: 'Partida',
      icon: <List />,
    },
  ],
};

export default DiscountConstructor;
