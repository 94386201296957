import {
  Checkbox,
  List, ListItem, ListItemSecondaryAction, ListItemText, Radio,
} from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { setStatusFilter } from '../../../../../../redux/actions/taskActions';
import { changeConfigTaskStatus } from '../../../../../../redux/actions/authUserActions';

function TaskStatusFilter({ openComponent, handleClickOpenComponent, selectedFilters = [] }) {
  const dispatch = useDispatch();
  const [statusSelected, setStatusSelected] = React.useState('');
  // const [statusSelected, setStatusSelected] = React.useState([...selectedFilters]);
  const user = useSelector(state => state.authUser.user);
  const {
    config: {
      pos_filter_projecttaskstatus: configStatusFilter,
    },
    pos_filter_projecttaskstatus_values: status,
  } = user;

  const formatStatusSelected = (stringFormatted) => {
    const evaluatedString = stringFormatted || statusSelected;
    let statusList = '';
    if (Array.isArray(status) && status.length) {
      for (let i = 0; i < status.length; i++) {
        if (evaluatedString.includes(status[i].value)) {
          statusList = statusList.concat(status[i].label, ',');
        }
      }
    }

    return statusList.substr(0, statusList.length - 1);
  };

  const convertToStingHashtagFormat = (arrayStatus) => {
    let statusList = '';

    if (Array.isArray(arrayStatus) && arrayStatus.length) {
      for (let i = 0; i < arrayStatus.length; i++) {
        if (i === arrayStatus.length - 1) {
          statusList = statusList.concat(arrayStatus[i]);
        } else {
          statusList = statusList.concat(arrayStatus[i], ' |##| ');
        }
      }
    }

    return statusList;
  };

  const addStatus = (item) => {
    let newStatus = statusSelected.split(' |##| ');
    if (statusSelected.includes(item.value)) {
      newStatus = newStatus.filter(s => s !== item.value);
    } else {
      newStatus = [...newStatus, item.value];
    }

    const stringFormatted = convertToStingHashtagFormat(newStatus);
    setStatusSelected(stringFormatted);
    dispatch(changeConfigTaskStatus(stringFormatted));
  };

  const addStatusSingle = (item) => {
    let newStatus = statusSelected.split(' |##| ');
    if (statusSelected.includes(item.value)) {
      newStatus = newStatus.filter(s => s !== item.value);
    } else {
      newStatus = [item.value];
    }

    const stringFormatted = convertToStingHashtagFormat(newStatus);
    setStatusSelected(stringFormatted);
    dispatch(changeConfigTaskStatus(stringFormatted));
  };

  React.useEffect(() => {
    if (configStatusFilter && configStatusFilter !== '') {
      setStatusSelected(configStatusFilter);
    }
  }, []);

  return (
    <List component="nav" classes={{ root: 'filter-list' }} key={Math.random()}>
      <ListItem button onClick={() => handleClickOpenComponent('filtrar')}>
        <ListItemText primary="Estados:" secondary={formatStatusSelected()} classes={{ primary: 'filter-item-title', secondary: 'filter-item-subtitle' }} />
        <ListItemText primary={openComponent === 'filtrar' ? <ExpandLess /> : <ExpandMore />} classes={{ root: 'pr-0', primary: 'd-flex justify-content-end' }} />
      </ListItem>
      <Collapse in={openComponent === 'filtrar'} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {
            status.map(item => (
              <ListItem button divider classes={{ root: 'filter-item-bg' }} key={item} onClick={() => addStatus(item)}>
                <ListItemText primary={item.label} classes={{ primary: 'filter-item-subItem' }} />
                <ListItemSecondaryAction>
                  <Checkbox
                    color="primary"
                    checked={statusSelected.includes(item.value)}
                    onChange={() => addStatusSingle(item)}
                    classes={{ root: 'filter-item-checkbox', colorPrimary: 'filter-item-checkbox', checked: 'filter-item-checkbox' }}
                  />
                </ListItemSecondaryAction>
              </ListItem>
            ))
          }
        </List>
      </Collapse>
    </List>
  );
}

TaskStatusFilter.propTypes = {
  openComponent: PropTypes.string.isRequired,
  handleClickOpenComponent: PropTypes.func.isRequired,
  selectedFilters: PropTypes.array.isRequired,
};

export default TaskStatusFilter;
