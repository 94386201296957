/* eslint-disable import/no-cycle */
import { Paper } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import OrderTable from './components/OrderTable';

const ActualOrder = () => {
  const modals = useSelector(state => state.modals);
  const { ticketsModalIsOpen } = modals;
  return (
    <Paper>
      {
        ticketsModalIsOpen && localStorage.getItem('printMode') === 'true' ? null : (
          <OrderTable />
        )
      }
    </Paper>
  );
};

export default ActualOrder;
