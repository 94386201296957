import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  Tooltip,
  IconButton,
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const GoToAddressAction = ({
  enable,
  tooltip,
}) => {
  const cart = useSelector(state => state.cart);
  const { orderSelected: { order } } = cart;
  const goToMap = () => {
    const url = `https://www.google.com/maps/search/?api=1&query=${order.ship_street || ''}, ${order.ship_pobox || ''}, ${order.ship_country || ''}, ${order.ship_state || ''}, ${order.ship_code || ''}`;
    window.open(url, '_blank');
  };

  return (
    <Tooltip
      title={tooltip}
      placement="left"
    >
      <span>
        <IconButton
          id="action_control_see"
          disabled={!enable}
          onClick={() => goToMap()}
          className="pos_menu_setDir"
        >
          <FontAwesomeIcon
            icon="map-marker-alt"
            style={{ width: '21px', heigth: '21px' }}
          />
          <span className="font-size-10">Google map</span>
        </IconButton>
      </span>
    </Tooltip>
  ); };

GoToAddressAction.propTypes = {
  tooltip: PropTypes.string,
  enable: PropTypes.bool.isRequired,
};
GoToAddressAction.defaultProps = {
  tooltip: 'Ver dirección en google map',
};

export default GoToAddressAction;
