import React from 'react';
import PropTypes from 'prop-types';
import {
  CircularProgress,
  Fab,
  Tooltip,
} from '@material-ui/core';
import { Cached } from '@material-ui/icons';

const ProductOptionsReloadQty = ({ loading, reload, title }) => (
  <Tooltip title={title}>
    <span>
      <Fab color="primary" disabled={loading} onClick={() => reload()}>
        {loading ? (<CircularProgress />) : (<Cached />)}
      </Fab>
    </span>
  </Tooltip>
);

ProductOptionsReloadQty.propTypes = {
  loading: PropTypes.bool,
  reload: PropTypes.func,
  title: PropTypes.string,
};
ProductOptionsReloadQty.defaultProps = {
  loading: false,
  reload: () => {},
  title: 'Recargar cantidades de las opciones',
};

export default ProductOptionsReloadQty;
