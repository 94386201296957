import { useState, useEffect } from 'react';
import MdaAPI from '../../api/MdaApi';

export default function useFetchOrderMda(orderId) {
  const [mda, setMda] = useState([]);
  const [errorMda, setErrorMda] = useState(null);
  const [isLoadingMda, setIsLoadingMda] = useState(false);

  const getOrderFde = async () => {
    setIsLoadingMda(true);
    const mdaApi = new MdaAPI();
    const response = await mdaApi.get(orderId);
    const { success, result } = response;

    if (success) {
      setMda(result);
    } else {
      setErrorMda('Mientras cargabamos los flujos de efectivo ocurrió un error');
    }

    setIsLoadingMda(false);
  };

  useEffect(() => {
    getOrderFde();
  }, [orderId]);

  return { mda, isLoadingMda, errorMda };
}
