import React from 'react';
import PropTypes from 'prop-types';
/* Material */
import { IconButton, Collapse, Button } from '@material-ui/core';
import { RemoveCircle, Add } from '@material-ui/icons';

/* Transition Group */
import TransitionGroup from 'react-transition-group/TransitionGroup';
import { Flip } from 'react-reveal';

import { toolsHelper } from '../../../../../helpers';

const groupProps = {
  appear: true,
  enter: true,
  exit: true,
};

const AmountRegistry = ({
  title,
  charge,
  payments,
  onSubmit,
  addPayment,
  totalToPay,
  removePayment,
}) => (
  <>
    <Collapse in={payments.length > 0}>
      <div className="w-100 checkoutGeneralTitle">{title}</div>
    </Collapse>
    <Collapse in={(charge !== totalToPay) && (charge > 0)} className="mb-2">
      <div className="w-100 pt-4 d-flex justify-content-center add_register_button">
        <Button
          id="button_add_payment"
          autoFocus
          tabIndex={-1}
          className="w-100"
          variant="contained"
          onClick={() => addPayment()}
        >
          Añadir Partida
          <Add fontSize="small" />
        </Button>
      </div>
    </Collapse>
    <div className="w-100 h-auto amount_registry">
      <TransitionGroup {...groupProps}>
        {payments.map((payment, i) => (
          <Flip
            key={`key_${payment.metodo}_${payment.tcybid}_${payment.monto}`}
            collapse
            bottom
          >
            <div
              id={`key_${payment.metodo}_${payment.tcybid}_${payment.monto}`}
              role="button"
              className="w-100 amount_registry_item"
              data-tab={i}
              tabIndex={0}
              onKeyDown={(e) => {
                const { keyCode } = e;
                if (keyCode === 46) {
                  removePayment(i);
                  const metaInput = document.querySelector('#payable_dollar');
                  // @ts-ignore
                  if (metaInput) metaInput.focus();
                } else if (keyCode === 13) {
                  onSubmit();
                }
              }}
            >
              <div className="amount_registry_item_method">
                {payment.metodo === 'LBL_PUNTOS_RECOMPENSA' ? 'Puntos' : payment.metodo}
              </div>
              <div className="amount_registry_item_cb">
                {payment.referencia}
              </div>
              <div className="amount_registry_item_money">
                {`$ ${toolsHelper.formatMoney(payment.monto)}`}
              </div>
              <IconButton
                className="remove_register_button"
                onClick={() => removePayment(i)}
                tabIndex={-1}
              >
                <RemoveCircle />
              </IconButton>
            </div>
          </Flip>
        ))}
      </TransitionGroup>
    </div>
  </>
);

AmountRegistry.propTypes = {
  title: PropTypes.string,
  charge: PropTypes.number,
  totalToPay: PropTypes.number,
  onSubmit: PropTypes.func.isRequired,
  payments: PropTypes.array.isRequired,
  addPayment: PropTypes.func.isRequired,
  removePayment: PropTypes.func.isRequired,
};

AmountRegistry.defaultProps = {
  charge: 0,
  totalToPay: 0,
  title: 'Registro',
};

export default AmountRegistry;
