import React from 'react';
import PropTypes from 'prop-types';
import useFetchOrderMda from '../../../hooks/order/useFetchOrderMda';
import MdaList from './components/mda-list/MdaList';

const Mda = ({ orderId }) => {
  const { mda, isLoadingMda, errorMda } = useFetchOrderMda(orderId);

  return errorMda ? (
    <div>Occurrio un error al cargar los movimientos de almacén</div>
  ) : (
    <MdaList mda={mda} isLoading={isLoadingMda} />
  );
};

Mda.propTypes = {
  orderId: PropTypes.string.isRequired,
};

export default Mda;
