/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import { Detector } from 'react-detect-offline';

import {
  List,
  Tooltip,
  ListItem,
  Collapse,
  ListItemText,
} from '@material-ui/core';

import Success from './Connection/success.svg';
import Danger from './Connection/danger.svg';

// @ts-ignore
const LayoutUserInfo = ({ authUser }) => (
  <Detector
    // polling={{ url: `//${authUser.domain}` }}
    render={({ online }) => (
      <List>
        <Tooltip
          placement="left"
          title={online
            ? 'Se encuentra conectado a Internet'
            : 'Conexión perdida, verifique que su dispositivo se encuentra conectado a internet'
          }
        >
          <ListItem dense>
            <div className="d-flex flex-column">
              <Collapse in={online}>
                <img src={Success} alt="connection" />
              </Collapse>
              <Collapse in={!online}>
                <img src={Danger} alt="connection" />
              </Collapse>
            </div>
            <ListItemText
              primary="Conexión"
              secondary={online ? `Conectado a ${authUser.domain.split('/').pop()}` : 'Desconectado'}
            />
          </ListItem>
        </Tooltip>
      </List>
    )}
  />
);

LayoutUserInfo.propTypes = {
  authUser: PropTypes.object.isRequired,
};

export default LayoutUserInfo;
