import React from 'react';
import PropTypes from 'prop-types';
import {
  Tooltip,
  IconButton,
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const QuoteSellAction = ({
  enable,
  tooltip,
  cotizar,
}) => (
  <Tooltip
    title={tooltip}
    placement="left"
  >
    <span>
      <IconButton
        disabled={!enable}
        onClick={cotizar}
        className="pos_menu_setDir"
      >
        <FontAwesomeIcon icon="file-invoice-dollar" />
        <span className="font-size-10">Cotización</span>
      </IconButton>
    </span>
  </Tooltip>
);

QuoteSellAction.propTypes = {
  tooltip: PropTypes.string,
  enable: PropTypes.bool.isRequired,
  cotizar: PropTypes.func.isRequired,
};
QuoteSellAction.defaultProps = {
  tooltip: 'Generar Cotización',
};

export default QuoteSellAction;
