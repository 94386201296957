import { RESET_TICKET_DATA, SET_TICKET_DATA } from '../types/ticketActions.type';

const getDefaultState = () => ({
  customer: {},
  order: {},
  products: [],
  subTotal: 0,
  totalIva: 0,
  description: '',
  ticketType: '',
});

const setTicketDate = (state, action) => {
  const restStore = {
    ...action.payload,
  };
  return {
    ...state,
    ...restStore,
  }; };

export default function (state = getDefaultState(), action) {
  const { type } = action;
  switch (type) {
    case SET_TICKET_DATA:
      return setTicketDate(state, action);
    case RESET_TICKET_DATA:
      return { ...getDefaultState() };
    default:
      return state;
  }
}
