import React from 'react';
import PropTypes from 'prop-types';
import './ListFiles.scss';
import {
  Collapse,
  LinearProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import { Warning } from '@material-ui/icons';

import ItemFile from './ItemFile';

const ListFiles = ({
  download,
  files,
  filesEmptyLabel,
  filesEmptySubLabel,
  loading,
  remove,
}) => (
  <div className="w-100 h-auto">
    <Collapse in={loading}>
      <LinearProgress color="primary" />
    </Collapse>
    <Collapse in={!loading}>
      {files.length > 0 ? (
        <List>
          {files.map(el => (
            <ItemFile
              download={download}
              file={el}
              key={`key_file_${el.crmid}`}
              remove={remove}
            />
          ))}
        </List>
      ) : (
        <div className="w-100 font-size-20 text-gray">
          <List>
            <ListItem>
              <ListItemIcon>
                <Warning />
              </ListItemIcon>
              <ListItemText
                primary={filesEmptyLabel}
                secondary={filesEmptySubLabel}
              />
            </ListItem>
          </List>
        </div>
      )}
    </Collapse>
  </div>
);

ListFiles.propTypes = {
  download: PropTypes.func,
  files: PropTypes.array,
  filesEmptyLabel: PropTypes.string,
  filesEmptySubLabel: PropTypes.string,
  loading: PropTypes.bool,
  remove: PropTypes.func,
};

ListFiles.defaultProps = {
  download: () => {},
  files: [],
  filesEmptyLabel: 'Sin archivos',
  filesEmptySubLabel: 'No cuenta con archivos anexos',
  loading: false,
  remove: () => {},
};

export default ListFiles;
