/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  Tooltip,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@material-ui/core';

import { CheckBox, CheckBoxOutlineBlank } from '@material-ui/icons';

const DiscountTaxesAction = ({
  enable,
  tooltip,
  toggle,
}) => {
  const discountTaxes = useSelector(state => state.filters.discountTaxes);
  const cart = useSelector(state => state.cart);
  const { orderSelected: { order } } = cart;
  const tabs = useSelector(state => state.tabs);
  const user = useSelector(state => state.authUser.user);
  const { enableEditOrder } = useSelector(state => state.order);
  const { config: { pos_no_sin_impuestos, store }, almacenes } = user;
  const selectStore = (almacenes && almacenes.length) ? almacenes.find(alm => alm.crmid === store.crmid) : null;
  const [open, setOpen] = React.useState(false);
  // const canDisableTaxes = selectStore?.pos_forzaventasiniva === '1' ? false : pos_no_sin_impuestos === '1' ? true : !enable;
  const canDisableTaxes = pos_no_sin_impuestos === '0';

  function handleClickOpen() {
    if (enableEditOrder) {
      setOpen(true);
    } else {
      toggle();
    }
  }

  function handleClose() {
    setOpen(false);
  }

  function acceptChanges() {
    setOpen(false);
    toggle();
  }

  React.useEffect(() => {
    if (selectStore?.pos_forzaventasiniva === '1' && pos_no_sin_impuestos === '1' && tabs.posTypeTab === 'Vender') {
      toggle();
    }
  }, [tabs.posTypeTab]);

  return (
    <>
      <Tooltip
        title={tooltip}
        placement="left"
      >
        <span>
          <IconButton
            id="action_control_sat"
            disabled={canDisableTaxes}
            onClick={handleClickOpen}
            className="pos_menu_setDir"
          >
            {
              discountTaxes ? (<CheckBox />) : (<CheckBoxOutlineBlank />)
            }
            <span className="font-size-10">Descontar impuestos</span>
          </IconButton>
        </span>
      </Tooltip>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Descontar impuestos
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Si realizó algun cambio en algun producto perderá este cambio ya que el cálculo de impuestos se realizará a la orden como estaba en su estado original.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button onClick={acceptChanges} color="primary" autoFocus>
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  ); };

DiscountTaxesAction.propTypes = {
  tooltip: PropTypes.string,
  enable: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
};
DiscountTaxesAction.defaultProps = {
  tooltip: 'Descontar/agregar impuestos',
};

export default DiscountTaxesAction;
