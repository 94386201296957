import React from 'react';
import PropTypes from 'prop-types';
import productOptionProps from '../../../../propTypes/productOptionProps';

import ProductOptionMobileBack from './components/ProductOptionMobileBack';

const ProductOptionMobile = ({
  addElement,
  addInStock,
  addSingleProductToCart,
  crmid,
  focusQty,
  getSubProducts,
  handleOnChangeInStock,
  isReloading,
  options,
  removeElement,
  removeInStock,
  select,
}) => (
  <ProductOptionMobileBack
    addElement={addElement}
    addInStock={addInStock}
    addSingleProductToCart={addSingleProductToCart}
    crmid={crmid}
    focusQty={focusQty}
    getSubProducts={getSubProducts}
    handleOnChangeInStock={handleOnChangeInStock}
    isReloading={isReloading}
    options={options}
    removeElement={removeElement}
    removeInStock={removeInStock}
    select={select}
  />
);

ProductOptionMobile.propTypes = {
  addElement: PropTypes.func.isRequired,
  addInStock: PropTypes.func.isRequired,
  addSingleProductToCart: PropTypes.func.isRequired,
  crmid: PropTypes.array.isRequired,
  focusQty: PropTypes.func.isRequired,
  getSubProducts: PropTypes.func.isRequired,
  handleOnChangeInStock: PropTypes.func.isRequired,
  isReloading: PropTypes.bool.isRequired,
  options: productOptionProps.isRequired,
  removeElement: PropTypes.func.isRequired,
  removeInStock: PropTypes.func.isRequired,
  select: PropTypes.number.isRequired,
};

export default ProductOptionMobile;
