/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Popover } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboardCheck, faUser } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { CalendarToday, Schedule } from '@material-ui/icons';
import { useLongPress } from 'use-long-press';
import TaskApi from '../../../../api/TaskApi';
import Flag from '../../../../components/Flag';
import Iconwidthbg from '../../../../components/Icons/IconWidthBG/IconWidthBG';
import { getColorTaskStatus } from '../../../../helpers/tools';
import { selectTasks, updateTaskInState } from '../../../../redux/actions/taskActions';
import ListItemGeneric from '../../../ClientPage/components/ListItemGeneric/ListItemGeneric';

const ActivityListItem = ({ item }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const divRef = React.useRef();
  const activityStatus = useSelector(state => state.authUser.user.pos_filter_projecttaskstatus_values);

  const [selectedTask, setSelectedTask] = React.useState(null);
  const [selectedStatus, setSelectedStatus] = React.useState({});
  const [anchorEl, setAchorEl] = React.useState(null);
  const openStatus = Boolean(anchorEl);

  const getStatusByLabel = (statusLabel) => {
    let st = {};
    if (activityStatus.length) {
      st = activityStatus.find(s => s.value.trim() === statusLabel || s.label.trim() === statusLabel);
    }

    return st;
  };

  const updateTask = async (task, status) => {
    const taskApi = new TaskApi();
    const response = await taskApi.editTask(task);
    const { success, result } = response;
    if (success) {
      setSelectedStatus(status);
      dispatch(updateTaskInState(result));
      toast.info(`${task.projecttaskname || 'Tarea'} actualizada a estado ${status.label}`);
    } else {
      toast.info('No se pudo editar la tarea');
    }
  };

  const updateTaskStatus = async (statuss) => {
    if (Object.keys(item).length && item.projecttaskstatus !== statuss.value) {
      const editedTask = { ...item };
      editedTask.projecttaskstatus = statuss.value;
      await updateTask(editedTask, statuss);
    }
  };

  const setActivityStatus = (status) => {
    updateTaskStatus(status);
  };

  const getSelectedTaskFromApi = async (taskId) => {
    const taskApi = new TaskApi();
    const response = await taskApi.getObjectById(taskId);
    const { success, result } = response;
    if (success) {
      dispatch(selectTasks(result));
    }
  };

  const selectTaskItem = async (task) => {
    await getSelectedTaskFromApi(task.crmid);
    history.push(`/actividades/${task.crmid}`);
  };

  const bind = useLongPress(() => {
    setAchorEl(divRef.current);
    setSelectedTask(item);
  });

  const changeStatusOnClick = async () => {
    await setSelectedTask(item);
    let newStatus = null;
    // eslint-disable-next-line default-case
    switch (item.projecttaskstatus) {
      case 'Creado':
        newStatus = getStatusByLabel('Abierto');
        if (newStatus) {
          await updateTaskStatus(newStatus);
        }
        break;
      case 'Abierto':
        newStatus = getStatusByLabel('En progreso');
        if (newStatus) {
          await updateTaskStatus(newStatus);
        }
        break;
      case 'En progreso':
        newStatus = getStatusByLabel('Terminada');
        if (newStatus) {
          await updateTaskStatus(newStatus);
        }
        break;
      case 'Terminada':
        newStatus = getStatusByLabel('Diferido');
        if (newStatus) {
          await updateTaskStatus(newStatus);
        }
        break;
      case 'Diferido':
        newStatus = getStatusByLabel('Cancelado');
        if (newStatus) {
          await updateTaskStatus(newStatus);
        }
        break;
    }
  };

  React.useEffect(() => {
    setSelectedStatus(getStatusByLabel(item.projecttaskstatus));
  }, []);

  return (
    <div ref={divRef}>
      <ListItemGeneric
        key={Math.random()}
        titleLeft={(<span className="w-100 d-block" onClick={() => selectTaskItem(item)}>{item.projecttaskname}</span>)}
        subTitleLeft={(
          <div onClick={() => selectTaskItem(item)} className="d-flex align-items-center flex-wrap">
            <span className="mr-2">{item.projectid_label}</span>
            <div className="d-flex">
              {
                      item.enddate && (
                        <span className="mr-2 activity-indicator" style={{ whiteSpace: 'nowrap', fontSize: 12 }}>
                          <CalendarToday style={{ fontSize: 12, marginRight: 3, verticalAlign: 'text-bottom' }} />
                          {moment(item.enddate).format('DD MMM')}
                        </span>
                      )
                    }
              {
                      item.projecttaskprogress && (
                        <span className="mr-2 activity-indicator" style={{ whiteSpace: 'nowrap', fontSize: 12 }}>
                          <Schedule style={{ fontSize: 12, marginRight: 3, verticalAlign: 'text-bottom' }} />
                          {item.projecttaskprogress}
                        </span>
                      )
                    }
              <span className="mr-2 activity-indicator" style={{ whiteSpace: 'nowrap', fontSize: 12 }}>
                <FontAwesomeIcon style={{ fontSize: 12, marginRight: 3, verticalAlign: 'text-bottom' }} icon={faUser} />
                {item.assigned_user}
              </span>
            </div>
          </div>
              )}
        subTitleRight={(
          <div
            {...bind}
            onClick={(e) => {
              changeStatusOnClick();
            }}
          >
            <Iconwidthbg
              primaryColor={getColorTaskStatus(selectedStatus?.label?.trim())}
              secondaryColor="white"
              width={40}
              height={40}
              classes="ml-2"
              enabled
              square
              rounded
              actionClick={() => {}}
            >
              <FontAwesomeIcon style={{ fontSize: 20 }} icon={faClipboardCheck} />
            </Iconwidthbg>
          </div>
                  )}
      />

      {/* Popover */}
      <Popover
        id="status-popover"
        open={openStatus}
        anchorEl={anchorEl}
        onClose={() => setAchorEl(null)}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        className="detailInfo_customer_paper"
      >
        <div
          className="detailInfo_customer_popover detailInfo_customer_popoend flex-wrap"
        >
          {
          activityStatus.map(stat => (
            <span key={`key_stat_${stat}_${Math.random()}`}>
              <Flag
                size="1x"
                icon="clipboard-check"
                ariaOwns={openStatus ? 'status-popover' : undefined}
                color="white"
                backgroundColor={getColorTaskStatus(stat.label)}
                onClick={() => {
                  setActivityStatus(stat);
                  setAchorEl(null);
                }}
              />
              <span>
                {stat.label === 'En progreso' ? 'Progreso' : stat.label}
              </span>
            </span>
          ))
          }
        </div>
      </Popover>
    </div>
  ); };

ActivityListItem.propTypes = {
  item: PropTypes.object.isRequired,
};

export default ActivityListItem;
