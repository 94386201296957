import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';

const CustomSelect = ({
  options, isMulti, handleChange, name, defaulValue,
}) => {
  const [initialValue, setInitialValue] = useState([]);

  const changeOptions = (e, fieldName) => {
    if (!e) {
      setInitialValue([]);
    }
    handleChange(e, fieldName);
  };

  useEffect(() => {
    setInitialValue(defaulValue);
  }, [defaulValue]);

  return (
    <div style={{ minWidth: 150 }}>
      <Select
        in
        isClearable={false}
        isSearchable={false}
        options={options}
        isMulti={isMulti}
        value={initialValue}
        onChange={e => changeOptions(e, name)}
      />
    </div>
  ); };

CustomSelect.propTypes = {
  options: PropTypes.array.isRequired,
  isMulti: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  defaulValue: PropTypes.array.isRequired,
};

export default CustomSelect;
