/* eslint-disable radix */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Menu,
  Tooltip,
  IconButton,
} from '@material-ui/core';
import {
  Print,
} from '@material-ui/icons';
/* Actions */
import { useDispatch, useSelector } from 'react-redux';
import TicketsAction from '../components/TicketsActions';
import TicketFacturaAction from '../components/TicketFacturaAction';
import { setTicketData } from '../../../../../redux/actions/ticketAction';

const PrintControls = ({
  cart,
  closeMain,
  disableTooltip,
  dispatchOpenBill,
  dispatchOpenModal,
  loads,
  surtidoTooltip,
  tabs,
  tooltip,
  ventaTooltip,
}) => {
  const dispatch = useDispatch();
  const customer = useSelector(state => state.customer);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  /* Constants */
  const {
    totalProducts, orderSelected, products, subTotal, totalIva, description,
  } = cart;
  const { posTypeTab } = tabs;
  const { selectedCustomer } = customer;

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }


  const handleOpen = async () => {
    switch (posTypeTab) {
      case 'Historico':
        await dispatch(setTicketData(selectedCustomer, orderSelected.order, products, subTotal, totalIva, description || '', 'order'));
        break;
      case 'Vender':
        await dispatch(setTicketData(selectedCustomer, {}, products, subTotal, totalIva, description || '', 'product'));
        break;
      case 'Cotizaciones':
        await dispatch(setTicketData(selectedCustomer, orderSelected.order, products, subTotal, totalIva, description || '', 'quote'));
        break;
      default:
        break;
    }
    dispatchOpenModal('tickets');
    handleClose();
  };

  /* Enables and Flags */
  const existProducts = Boolean(totalProducts > 0);
  const sellTicket = Boolean(posTypeTab === 'Historico');
  const treebessatcfdiid = (orderSelected) ? (orderSelected.treebessatcfdiid) : ('');
  const enableFactura = Boolean(treebessatcfdiid !== '');

  return (
    <>
      <Tooltip placement="left" title={existProducts ? tooltip : disableTooltip}>
        <span>
          <IconButton
            aria-label="More"
            aria-controls="cart-print-menu"
            aria-haspopup="true"
            onClick={handleClick}
            disabled={!existProducts}
            className="pos_menu_setDir"
          >
            <Print />
            <span className="font-size-10">Imprimibles</span>
          </IconButton>
        </span>
      </Tooltip>
      <Menu
        id="cart-print-menu"
        keepMounted
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        className="cart_controls cart_horizontal"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        getContentAnchorEl={null}
      >
        <TicketsAction
          open={() => handleOpen()}
          enable={existProducts}
          tooltip={sellTicket ? ventaTooltip : surtidoTooltip}
        />
        <TicketFacturaAction
          open={() => {
            dispatchOpenBill(treebessatcfdiid);
            handleClose();
            closeMain();
          }}
          enable={enableFactura}
          loading={Boolean(loads.timbrarIsLoading)}
        />
      </Menu>
    </>
  );
};

PrintControls.propTypes = {
  cart: PropTypes.object.isRequired,
  disableTooltip: PropTypes.string,
  closeMain: PropTypes.func.isRequired,
  dispatchOpenBill: PropTypes.func.isRequired,
  dispatchOpenModal: PropTypes.func.isRequired,
  loads: PropTypes.object.isRequired,
  surtidoTooltip: PropTypes.string,
  tabs: PropTypes.object.isRequired,
  tooltip: PropTypes.string,
  ventaTooltip: PropTypes.string,
};
PrintControls.defaultProps = {
  tooltip: 'Imprimibles',
  ventaTooltip: 'Ticket de Venta',
  surtidoTooltip: 'Ticket de Presurtido',
  disableTooltip: 'Debe haber una Orden de Venta o Cotización seleccionada, al menos productos en el carrito',
};

export default PrintControls;
