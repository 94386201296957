/* eslint-disable no-unused-expressions */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable camelcase */
/* eslint-disable import/no-cycle */
import React from 'react';
import { toast } from 'react-toastify';
import TimbrarApi from '../../api/TimbrarApi';
import {
  openModal,
  closeModal,
} from './modalActions';
import { changeTab } from './tabActions';
import {
  selectOrder,
  fetchAllOrders,
} from './orderActions';
import { loading, loaded } from './loadActions';

export const FETCH_TIMBRADO = 'FETCH_TIMBRADO';
export const HANDLE_MAKEBILL = 'HANDLE_MAKEBILL';
export const PRINT_BILL = 'PRINT_BILL';
export const RESET_TIMBRADO = 'RESET_TIMBRADO';
export const SAVE_TIMBRADO = 'SAVE_TIMBRADO';

const ID = 'timbrar';

export const resetTimbrado = () => (
  async (dispatch) => {
    dispatch({ type: RESET_TIMBRADO });
  }
);


export const printTicketFactura = (crmid) => {
  const timbrarApi = new TimbrarApi();
  return async (dispatch) => {
    await dispatch(loading(ID));
    timbrarApi.getTicket(crmid)
      .then(async (response) => {
        if (!response.success) {
          const { message } = response;
          /* toast.warn(response.message.warning || response.message.error); */
          message.error ? toast.error(message.error) : '';
          message.warning ? toast.warn(message.warning) : '';
        } else {
          const { result } = response;
          await dispatch({
            type: PRINT_BILL,
            result,
          });
          await (dispatch(openModal('ticketsFactura')));
        }
      }).catch(() => {
        dispatch(loaded(ID));
      });
  };
};

export const fetchTimbrado = crmid => (
  async (dispatch) => {
    dispatch(openModal(ID));
    dispatch(loading(ID));
    const timbrarApi = new TimbrarApi();
    const response = await timbrarApi.get(crmid);
    const { datos } = response;
    if (response.success !== 'ERROR' && datos) {
      dispatch({
        type: FETCH_TIMBRADO,
        response: { ...datos, crmid },
      });
    } else {
      dispatch(closeModal(ID));
      toast.warn(datos);
    }
    dispatch(loaded(ID));
  }
);

export const saveTimbrado = timbre => (
  async (dispatch, getState) => {
    dispatch(loading(ID));
    try {
      const timbrarApi = new TimbrarApi();
      const response = await timbrarApi.save(timbre);
      const resStr = Object.entries(response)
        .map(x => x[1])
        .join('')
        .match(/{(.*)}/)
        .pop();
      const json = JSON.parse((`{${resStr}}`));
      const { datos, success, respuesta } = json;
      if (success === 'OK') {
        const { authUser } = getState();
        await dispatch(fetchAllOrders());
        const { pos_sell_tab } = authUser.user.config;
        if (pos_sell_tab) {
          await dispatch(selectOrder(timbre.crmid));
          await dispatch(changeTab('posType', 'Historico'));
          toast.success('Tu orden ya esta timbrada');
        } else {
          toast.success('Orden Timbrada');
        }
        await dispatch(printTicketFactura(json.datos));
      } else {
        toast.warn(datos || respuesta.codigo_mf_texto, { autoClose: false });
      }
    } catch (err) {
      toast.warn(`Hubo un error durante el proceso timbrado de la orden ${timbre.crmid}`);
      console.log('err: ', err);
    } finally {
      dispatch({ type: SAVE_TIMBRADO });
      dispatch(loaded(ID));
      dispatch(closeModal(ID));
    }
  }
);

export const handleMakeBill = makeBill => (
  async (dispatch) => {
    dispatch(loading(ID));
    dispatch({ type: HANDLE_MAKEBILL, makeBill });
    dispatch(loaded(ID));
  }
);
