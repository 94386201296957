/* eslint-disable no-use-before-define */
/* eslint-disable radix */
/* eslint-disable camelcase */
/* eslint-disable no-nested-ternary */
// @ts-nocheck
/* eslint-disable jsx-a11y/no-autofocus */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import {
  Fade,
  Chip,
  Input,
  Popover,
  Tooltip,
  TableRow,
  TableCell,
  TextField,
  IconButton,
} from '@material-ui/core';

// Modal
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { DebounceInput } from 'react-debounce-input';

import {
  // Add,
  Done,
  // Remove,
  AddRounded,
} from '@material-ui/icons';
import { isAndroid, isMobile, isTablet } from 'react-device-detect';
import { formatMoney } from '../../../helpers/tools';
import {
  getTotalPrice,
  canDecreaseProductInCart,
} from '../../../helpers/cart';

/* Components */
import CartListOptions from './CartListOptions';
import Percentage from '../../Discount/_components/constructor/components/mask/PercentageMask';
// eslint-disable-next-line import/no-cycle
import { changeSinStock, updateProductPriceInCart, updateProductQuantityInCart } from '../../../redux/actions/cartActions';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);


const CartListRow = ({
  authUser,
  classes,
  discoutnExist,
  dispatchDecreaseProductInCart,
  dispatchIncreaseProductInCart,
  dispatchRemoveProductInCart,
  dispatchSetComment,
  dispatchSetSingleDiscount,
  dispatchUpdateProductPriceInCart,
  dispatchUpdateProductQtyInCart,
  getPriceByPricebook,
  domain,
  enableAutoFocus,
  enableEditOrder,
  handleKeyTopSearchBar,
  orderSelected,
  posTypeTab,
  product,
  rowClasses,
}) => {
  const dispatch = useDispatch();
  // Modal
  const [open, setOpen] = React.useState(false);

  /* Hooks */
  const [singleDiscount, setSingleDiscount] = React.useState(0);
  const [discountAnchor, setDiscountAnchor] = React.useState(null);
  const [commentZone, setCommentZone] = React.useState(false);
  const [comment, setComment] = React.useState('');
  const [promo, setPromo] = React.useState(false);
  const [inputValue, setInputValue] = React.useState(0);
  const [productStock, setProductStock] = React.useState({});
  const [qtyTotal, setQtyTotal] = React.useState({});
  const cart = useSelector(state => state.cart);
  const { products } = useSelector(state => state.product);
  const order = useSelector(state => state.order);
  const user = useSelector(state => state.authUser.user);
  const guide = useSelector(state => state.authUser.user);
  const shipping = useSelector(state => state.shipping);
  const { shippingList } = shipping;
  const [totalShippingAmount, setTotalShippingAmount] = useState(0);
  const debounceRef = useRef();
  const {
    productsWidthpromotions, productsStock, cartProductsByArray, selectedGuide,
  } = cart;
  // eslint-disable-next-line camelcase
  // eslint-disable-next-line no-unused-vars
  const { shipping_id, config: { pos_sininv } } = user;
  const isOrder = Boolean(JSON.stringify(orderSelected) === JSON.stringify({}));
  const isEditOrder = Boolean(order?.enableEditOrder && orderSelected.status !== 'Cancelado');

  const productid = product.crmid ? product.crmid : ((product.productid && product.productid.indexOf('x') !== -1)
    ? product.productid.split('x').pop()
    : product.productid);

  const hasPromotions = () => {
    let promotion = false;
    if (productsWidthpromotions && productsWidthpromotions.length) {
      const index = productsWidthpromotions.findIndex(prod => prod.productid === product.crmid);
      if (index !== -1) {
        promotion = productsWidthpromotions[index].TreebesPromociones.length > 0 || productsWidthpromotions[index].TreebesPromociones_unit_price_name !== null;
      }
    }
    setPromo(promotion);
  };

  const calculateStock = () => {
    let qty = 0;
    cart.products.map((prod) => {
      const currentProductId = prod.crmid ? prod.crmid : ((prod.productid && prod.productid.indexOf('x') !== -1)
        ? prod.productid.split('x').pop()
        : prod.productid);

      if (currentProductId === productid) {
        // eslint-disable-next-line radix
        qty += Number.parseInt(prod.quantity);
      }
    });

    if ((orderSelected.status === 'Creado' || isOrder) && productsStock[productid]?.qtyinstock - qty < 0) {
      dispatch(changeSinStock(true));
    }
    setProductStock(productsStock[productid]);
    setQtyTotal(qty);
  };


  const updateProductQty = (newValue) => {
    const qty = parseFloat(newValue);
    if (qty >= 0.1) {
      setInputValue(qty);
      dispatch(updateProductQuantityInCart(product.row, qty));
    } else {
      setInputValue(1);
      dispatch(updateProductQuantityInCart(product.row, 1));
    }
  };

  const handleChangeInput = (e) => {
    e.persist();

    if (debounceRef.current) {
      clearTimeout(debounceRef.current);
    }

    debounceRef.current = setTimeout(() => {
      updateProductQty(e.target.value);
    }, 1000);
  };

  React.useEffect(() => {
    setInputValue(product.quantity);
  }, [product]);

  React.useEffect(() => {
    setComment(product.comment);
    hasPromotions();
  }, [product.comment, productsWidthpromotions]);

  React.useEffect(() => {
    calculateStock();
  }, [cart.productsStock]);

  /* Functions */
  const handleComment = (e) => {
    const { target: { value } } = e;
    setComment(value);
  };
  const handleKeyPress = async (e) => {
    const { charCode } = e;
    const { crmid } = product;
    if (charCode === 13) {
      await dispatchSetComment({ crmid, comment });
      await setCommentZone(false);
    }
  };
  const fetchComment = async () => {
    const { row } = product;
    await dispatchSetComment({ row, comment });
    await setCommentZone(false);
  };
  const onBlurComment = () => fetchComment();
  const removeDiscount = () => {
    dispatchSetSingleDiscount(product.row, 0);
  };
  function setSingleDiscountOnChange(e) {
    const { target: { value } } = e;
    const percentage = (parseFloat(value) || 0);
    setSingleDiscount(percentage);
  }
  function setSingleDiscountOnBlur() {
    const { row } = product;
    const percentage = singleDiscount;
    dispatchSetSingleDiscount(row, percentage);
  }
  function setSingleDiscountKeyPress(e) {
    const { charCode } = e;
    if (charCode === 13) {
      setDiscountAnchor(null);
    }
  }
  const openPop = Boolean(discountAnchor);
  const idPop = openPop ? 'discount-popover' : undefined;
  const edit = (isOrder || enableEditOrder);
  const existDiscount = Boolean(product.discount_percent > 0);
  const { config } = authUser.user;
  /* Flags */
  const enablePrice = Boolean(posTypeTab === 'Vender' || (!existDiscount && enableEditOrder));

  // Modal
  const handleClose = () => {
    setOpen(false);
  };

  const handleAccept = async () => {
    const { crmid, row } = product;
    await dispatchSetComment({ crmid, comment, row });
    handleClose();
  };
  let timeout = null;
  let countTimeout = null;

  const getProductImage = (crmid) => {
    const index = products.findIndex(item => item.crmid === crmid);

    if (index !== -1 && products[index].images.length) {
      return products[index].images[0];
    }

    const ProductIndex = cartProductsByArray.findIndex(item => item.crmid === crmid);
    if (ProductIndex !== -1 && cartProductsByArray[ProductIndex].productcode === 'ENVIO' && Object.keys(selectedGuide) > 0) {
      return selectedGuide.carrier_logo_url;
    }

    return `${process.env.PUBLIC_URL}/img/no_image.jpg`;
  };

  /* useEffect(() => {
    if (shippingList.length > 0 && (product.crmid === shipping_id || productid === shipping_id)) {
      const totalShippings = shippingList.length > 0 ? shippingList.reduce((acc, item) => acc + item.priceWithTaxes, 0) : 0;
      dispatch(updateProductPriceInCart(product.row, parseFloat(totalShippings).toFixed(2)));
    }
  }, [shipping]); */

  return (
    <>
      <TableRow
        tabIndex={0}
        className={`${
          (!pos_sininv && (orderSelected.status === 'Creado' || isOrder || isEditOrder) && ((Number.parseInt(productStock?.qtyinstock)) - qtyTotal) < 0)
            ? `${rowClasses[product.crmid || productid]} alertRow`
            : rowClasses[product.crmid || productid]}
         
          ${promo ? 'has-promotion' : 'no-promotion'}
        }`
      }
        onKeyUp={handleKeyTopSearchBar}
        data-crmid={product.crmid || productid}
        data-row={product.row}
      >
        {commentZone && (
        <TableCell
          colSpan={4}
          align="center"
          className="m-0"
        >
          <TextField
            autoFocus
            disabled={!edit}
            value={comment}
            variant="outlined"
            multiline
            className="w-100 h-100 pr-4"
            placeholder="Escriba un comentario al producto"
            InputProps={{
              endAdornment: (
                <Fade in={comment !== product.comment}>
                  <IconButton
                    onClick={() => fetchComment()}
                  >
                    <AddRounded />
                  </IconButton>
                </Fade>
              ),
            }}
            onChange={handleComment}
            onKeyPress={handleKeyPress}
            /* onBlur={onBlurComment} */
          />
        </TableCell>
        )}
        {!commentZone && (
        <TableCell
          style={{
            maxWidth: '12vw',
            textAlign: 'left',
            paddingLeft: '0.4em',
            textOverflow: 'ellipsis',
          }}
          className={classes.noPadding}
        >
          <Tooltip title={`${product.productname || ''} ${product.opcion || ''}`} placement="left-start">
            <span>
              <div className="d-flex">
                {
                  product.productcode === 'ENVIO'
                    ? (
                      <img style={{ width: 50, borderRadius: 5, marginRight: 2 }} alt="im" src={`${process.env.PUBLIC_URL}/img/no_image.jpg`} />
                    )
                    : (
                      <img style={{ width: 50, borderRadius: 5, marginRight: 2 }} src={product.image ? product.image : getProductImage(product.parentId)} onError={`this.onerror=null;this.src=${process.env.PUBLIC_URL}/img/no_image.jpg;`} alt="Imagen del producto" />
                    )
                }

                <div>
                  <div
                    className="d-flex"
                    style={{
                      maxWidth: '12vw',
                      textAlign: 'left',
                      paddingLeft: '0.4em',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                    }}
                  >
                    {product.productname}
                  </div>
                  <div
                    style={{
                      maxWidth: '12vw',
                      textAlign: 'left',
                      paddingLeft: '0.4em',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                    }}
                  >
                    { product.tpo_id_label}
                  </div>
                  <div className="ml-2">
                    {product.productcode}
                  </div>
                </div>
              </div>
            </span>
          </Tooltip>
        </TableCell>
        )}
        {!commentZone && (
        <TableCell
          className={classes.noPadding}
        >
          <div className="cart_input cart_input_qty">
            {(posTypeTab === 'Vender' || enableEditOrder) && !(productid === shipping_id && product.index !== 'gastos_de_envio_por_cotizar') && (
              <button
                tabIndex={-1}
                type="button"
                className="cart__increase-decrease-product"
                data-crmid={product.crmid || productid}
                data-row={product.row}
                onClick={
                  canDecreaseProductInCart(product)
                    ? dispatchDecreaseProductInCart
                    : () => {}
                }
                disabled={canDecreaseProductInCart(product) === false}
              >
                <>-</>
              </button>
            )}
            <DebounceInput
              type="number"
              step="1"
              // defaultValue={inputValue}
              value={Math.abs(product.quantity)}
              debounceTimeout={700}
              onChange={handleChangeInput}
              onFocus={event => event.target.select()}
              data-crmid={product.crmid || productid}
              data-row={product.row}
              data-selection="selection"
              disabled={(product.crmid === shipping_id || productid === shipping_id) ? true : !(posTypeTab === 'Vender' || enableEditOrder)}
              autoFocus={
                localStorage.getItem('printMode') === 'true' ? false
                  : (domain !== 'comercia.tornillosguadalajara.com' ? (enableAutoFocus) : (true))
              }
            />
            {/* <input
              id={`key_${product.crmid || productid}`}
              type="number"
              step=".01"
              value={inputValue}
              // onFocus={event => event.target.select()}
              onChange={(e) => handleChangeInput(e)}
              data-crmid={product.crmid || productid}
              data-row={product.row}
              data-selection="selection"
              disabled={(product.crmid === shipping_id || productid === shipping_id) ? true : !(posTypeTab === 'Vender' || enableEditOrder)}
              autoFocus={
                domain !== 'comercia.tornillosguadalajara.com' ? (enableAutoFocus) : (true)
              }
              form="novalidatedform"
            /> */}
            {(posTypeTab === 'Vender' || enableEditOrder) && !(productid === shipping_id && product.index !== 'gastos_de_envio_por_cotizar') && (
              <button
                tabIndex={-1}
                type="button"
                className="cart__increase-decrease-product"
                data-crmid={product.crmid || productid}
                data-row={product.row}
                data-count={0}
                onClick={(e) => {
                  e.persist();
                  e.target.dataset.count = (parseInt(e.target.dataset.count) || 0) + 1;
                  clearTimeout(timeout);
                  clearTimeout(countTimeout);
                  timeout = setTimeout(
                    dispatchIncreaseProductInCart,
                    900, e,
                  );
                  countTimeout = setTimeout(
                    () => { e.target.dataset.count = 0; },
                    900, e,
                  );
                }}
                // disabled={!!((product.crmid === shipping_id || productid === shipping_id))}
              >
                <>+</>
              </button>
            )}
          </div>
          {((!pos_sininv && (orderSelected.status === 'Creado' || isOrder || isEditOrder) && productStock?.qtyinstock - qtyTotal < 0)) && (
            <Chip
              // eslint-disable-next-line radix
              label={`Stock: ${(Number.parseInt(productStock.qtyinstock))}`}
              variant="outlined"
              color="primary"
              className="chip_instock"
            />
          )}
        </TableCell>
        )}
        {!commentZone && (
        <TableCell
          className={classes.noPadding}
        >
          <Tooltip title={`Precio Unitario con Descuento: $${formatMoney(Math.abs(product.unit_price) - (product.discount_amount / product.quantity))}`}>
            {/* <input
              type="number"
              // form="novalidatedform"
              data-row={product.row}
              // eslint-disable-next-line camelcase
              disabled={config.pos_can_change_price === '0' ? true : !enablePrice}
              value={parseFloat(product.unit_price).toFixed(2)}
              data-crmid={product.crmid || productid}
              onFocus={event => event.target.select()}
              placeholder={Math.abs(product.unit_price)}
              className="cart_input cart_input_price"
              onChange={dispatchUpdateProductPriceInCart}
              min={0}
            /> */}
            <DebounceInput
              type="number"
              step="1"
            // form="novalidatedform"
              data-row={product.row}
            // eslint-disable-next-line camelcase
              disabled={config.pos_can_change_price === '0' ? true : !enablePrice}
              value={product.unit_price}
              data-crmid={product.crmid || productid}
              onFocus={event => event.target.select()}
              placeholder={Math.abs(product.unit_price)}
              className="cart_input cart_input_price"
              onChange={dispatchUpdateProductPriceInCart}
              min={0}
              debounceTimeout={700}
            />
          </Tooltip>
        </TableCell>
        )}
        {!commentZone && (discoutnExist) && (
        <TableCell
          className={classes.noPadding}
        >
          {product.discount_percent > 0 ? (
            <Tooltip title={`Precio sin descuento: $${formatMoney(product.unit_price * product.quantity)}`}>
              {edit ? (
                <Chip
                  clickable
                  size="small"
                  color="primary"
                  variant="outlined"
                  onDelete={removeDiscount}
                  label={`${product.discount_percent} %`}
                  onClick={(e) => {
                    setDiscountAnchor(e.currentTarget);
                  }}
                />
              ) : (
                <Chip
                  size="small"
                  icon={<Done />}
                  color="secondary"
                  label={`${product.discount_percent} %`}
                />
              )}
            </Tooltip>
          ) : (
            <Chip
              size="small"
              variant="outlined"
              label={`${product.discount_percent} %`}
            />
          )}
        </TableCell>
        )}
        {!commentZone && (
        <TableCell
          className={classes.noPadding}
        >
          <Tooltip title={`Precio con Impuestos: $${formatMoney(product.priceWithTaxes * product.quantity)}`}>
            <span>
              {`$ ${formatMoney(getTotalPrice(product))}`}
            </span>
          </Tooltip>
        </TableCell>
        )}
        <TableCell
          align="right"
          style={{ width: '8vw' }}
          className={classes.noPadding}
        >
          <CartListOptions
            user={authUser.user}
            edit={edit}
            product={product}
            commentZone={open}
            setCommentZone={setOpen}
            getPriceByPricebook={getPriceByPricebook}
            dispatchSetSingleDiscount={dispatchSetSingleDiscount}
            dispatchRemoveProductInCart={dispatchRemoveProductInCart}
          />
        </TableCell>
        <Popover
          id={idPop}
          open={openPop}
          anchorEl={discountAnchor}
          onClose={() => setDiscountAnchor(null)}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'left',
          }}
        >
          <div className="p-1">
            <Input
              style={{
                textAlign: 'right',
                width: '50px',
              }}
              autoFocus
              inputComponent={Percentage}
              defaultValue="Descuento %"
              inputProps={{
                'aria-label': 'description',
              }}
              onBlur={setSingleDiscountOnBlur}
              onChange={setSingleDiscountOnChange}
              onKeyPress={setSingleDiscountKeyPress}
            />
          </div>
        </Popover>
      </TableRow>

      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">Agregar comentario al producto</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <strong>{product.productname}</strong>
            <TextField
              autoFocus
              disabled={!edit}
              value={comment}
              variant="outlined"
              multiline
              className="w-100 h-100 pt-2"
              defaultValue={product.comment}
              placeholder="Escriba un comentario al producto"
              onChange={handleComment}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button disabled={!edit} onClick={handleAccept} color="primary">
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

CartListRow.propTypes = {
  authUser: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  discoutnExist: PropTypes.bool.isRequired,
  dispatchDecreaseProductInCart: PropTypes.func.isRequired,
  dispatchIncreaseProductInCart: PropTypes.func.isRequired,
  dispatchRemoveProductInCart: PropTypes.func.isRequired,
  dispatchSetComment: PropTypes.func.isRequired,
  dispatchSetSingleDiscount: PropTypes.func.isRequired,
  dispatchUpdateProductPriceInCart: PropTypes.func.isRequired,
  dispatchUpdateProductQtyInCart: PropTypes.func.isRequired,
  domain: PropTypes.string.isRequired,
  enableAutoFocus: PropTypes.bool.isRequired,
  enableEditOrder: PropTypes.bool.isRequired,
  handleKeyTopSearchBar: PropTypes.func.isRequired,
  getPriceByPricebook: PropTypes.func.isRequired,
  orderSelected: PropTypes.object.isRequired,
  posTypeTab: PropTypes.string.isRequired,
  product: PropTypes.object.isRequired,
  rowClasses: PropTypes.object.isRequired,
};
CartListRow.defaultProps = {};

export default CartListRow;
