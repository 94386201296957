/* eslint-disable no-case-declarations */
/* eslint-disable import/no-cycle */
import {
  ADD_ITEMS_TO_TABLE,
  CHANGE_COMMENT,
  CHANGE_FIELD,
  CLEAR_TABLE,
  RESET_TO_ORIGIN,
  SELECT_EMPTY_TO_TABLE,
  SELECT_TO_DELIVERED,
  SELECT_TO_REFUNDED,
  SET_REFUND_TICKET,
  UPDATE_STORE,
  UPDATE_ITEMS,
  UPDATE_BAD_PRODUCTS,
  UPDATE_CHANGE_PRODUCTS,
} from '../actions/tableActions';

import { tableHelper } from '../../helpers';

/* Default State */
const date = new Date();
const defaultState = () => ({
  ref: '',
  comments: '',
  date: '',
  store: { name: '...' },
  account: { id: '', nombre: '...' },
  toSell: '',
  items: [],
  enableAction: false,
  totalDiscount: 0,
  balance: 0,
  total: 0,
  ticket: {
    client: 'PUBLICO EN GENERAL (EL ORIGINAL)',
    comments: '',
    date: date.toLocaleString(),
    payments: [],
    from: 'Almacén',
    mda: [],
    ref: '',
    title: 'Devolución',
    to: 'Orden 00001',
  },
  badProducts: 'false',
  changeProducts: 'false',
});

/* Functions */

const addItemsToTable = (state, {
  typeAction,
  response,
  store,
  cartProductsByArray,
  discount,
  balance,
  total,
  id,
}) => {
  const { json } = response;
  const newState = tableHelper.transformTableItems(json, typeAction, store, cartProductsByArray, discount, balance, total, id);
  return { ...state, ...newState };
};

const emptyAll = (state, { table }) => {
  const { items } = table;
  const newState = tableHelper.emptyFieldsItems(items);
  return { ...state, ...newState };
};

const selectToDelivered = (state, { table }) => {
  const { items } = table;
  const newState = tableHelper.fillToDeliveredItems(items);
  return { ...state, ...newState };
};

const selectToRefunded = (state, { table }) => {
  const { items } = table;
  const newState = tableHelper.fillToRefundedItems(items);
  return { ...state, ...newState };
};

const clearTable = () => {
  const table = defaultState();
  table.items = [];
  return table;
};

const changeField = (state, { obj, table }) => {
  const { items } = table;
  const { crmdi, value } = obj;
  const newArray = [];
  let enableAction = true;
  items.forEach((item) => {
    const newItem = item;
    if (item.crmid === crmdi) {
      // eslint-disable-next-line no-restricted-globals
      if (value === '') newItem.field = 0;
      else newItem.field = parseFloat(value);
    }
    // eslint-disable-next-line no-restricted-globals
    if ((newItem.field < 0 || isNaN(newItem.field) || newItem.field > newItem.limit || newItem.field > newItem.qtyinstock) && Math.sign(newItem.qtyinstock) !== -1) {
      enableAction = false;
    }
    newArray.push(newItem);
  });
  const allEmpty = items.filter(newProduct => newProduct.field === 0);
  if (allEmpty.length === items.length) enableAction = false;
  const newState = { items: newArray, enableAction };
  return { ...state, ...newState };
};

const changeComent = (state, { word }) => {
  const newState = {};
  newState.comments = word;
  return { ...state, ...newState };
};

const resetToOrigin = (state) => {
  const { items } = state;
  let arr = [];
  items.forEach((item) => { arr = [...arr, { ...item, field: item.origin }]; });
  return { ...state, items: arr };
};

const changeStore = (state, action) => ({ ...state, store: { ...action.store } });
const changeItems = (state, action) => ({ ...state, items: [...action.items] });
const updateBadProducts = (state, action) => ({ ...state, badProducts: action.value });
const updateChangeProducts = (state, action) => ({ ...state, changeProducts: action.value });


/* Dispatcher */

export default function (state = defaultState(), action) {
  const { type } = action;
  switch (type) {
    case ADD_ITEMS_TO_TABLE:
      return addItemsToTable(state, action);
    case SELECT_EMPTY_TO_TABLE:
      return emptyAll(state, action);
    case SELECT_TO_DELIVERED:
      return selectToDelivered(state, action);
    case SELECT_TO_REFUNDED:
      return selectToRefunded(state, action);
    case CHANGE_FIELD:
      return changeField(state, action);
    case CLEAR_TABLE:
      return clearTable();
    case CHANGE_COMMENT:
      return changeComent(state, action);
    case RESET_TO_ORIGIN:
      return resetToOrigin(state);
    case UPDATE_STORE:
      return changeStore(state, action);
    case UPDATE_ITEMS:
      return changeItems(state, action);
    case UPDATE_BAD_PRODUCTS:
      return updateBadProducts(state, action);
    case UPDATE_CHANGE_PRODUCTS:
      return updateChangeProducts(state, action);
    case SET_REFUND_TICKET: {
      const { ticket } = action;
      return ({ ...state, ticket });
    }
    default:
      return state;
  }
}
