/* eslint-disable import/no-cycle */
import React, { Component } from 'react';
import './index.scss';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import SwipeableViews from 'react-swipeable-views';
import HeaderTop from './components/HeaderTop';
import SwipMenu from './components/SwipMenu/SwipMenu';
import SectionTitle from './components/SectionTitle/SectionTitle';
import LoadComponent from '../../components/Load/LoadComponent';
import {
  fetchSalesOrder, fetchSalesOrderSummary, fetchListInvoice, fetchListMda, fetchListCashFlow, fetchListCreditNotes, fetchDataResume, fetchListQuotes,
} from '../../redux/actions/financeActions';
import { fetchAccountUpdates, fetchEmails } from '../../redux/actions/accountActions';
import { fetchClientDetail } from '../../redux/actions/clientActions';
import ScrollPagination from '../../components/ScrollPaginator/ScrollPaginator';
import ComingSoon from '../../components/ComingSoon/ComingSoon';

import EmailContainer from './components/EmailContainer/EmailContainer';
import SummaryContainer from './components/SummaryContainer/SummaryContainer';
import FinanceContainer from './components/FinanceContainer/FinanceContainer';
import UpdatesContainer from './components/UpdatesContainer/UpdatesContainer';
import DocumentsContainer from './components/DocumentsContainer/DocumentsContainer';

// import SupportContainer from './components/SupportContainer/SupportContainer';
// import ProjectsContainer from './components/ProjectsContainer/ProjectsContainer';
// const EmailContainer = React.lazy(() => import('./components/EmailContainer/EmailContainer'));

const title = ['Resumen', 'Actualizaciones', 'Eventos', 'Finanzas', 'Proyectos', 'Correo', 'Soporte', 'Documentos'];

class ClientPage extends Component {
  constructor(props) {
    const { match, dispatch } = props;

    super(props);
    this.myRef = React.createRef();
    this.state = {
      activeTab: 0,
      selectedCart: null,
      preLoad: false,
    };
    dispatch(fetchClientDetail(match.params.crmid));
  }

    handleChange = (event, activeTab) => {
      this.handleChangeIndex(activeTab);
      // this.setState({ activeTab });
    };

    onTapCartItem = (cart) => {
      this.setState({ selectedCart: cart });
    };

    onClearCartItem = () => {
      this.setState({ selectedCart: null });
    };

    fetchFinanceData = () => {
      const { crmid } = this.props.client.selectClient;
      const { dispatch } = this.props;
      dispatch(fetchListQuotes('', '', crmid));
      // dispatch(fetchSalesOrder('', '', crmid));
      dispatch(fetchSalesOrderSummary('', '', crmid));
      dispatch(fetchListInvoice('', '', crmid));
      dispatch(fetchListMda('', '', crmid));
      dispatch(fetchListCreditNotes('', '', crmid));
      dispatch(fetchListCashFlow('', '', crmid));
    };

    onSwipeAction = (e) => {
      const deltaX = Math.abs(e.deltaX);
      const { activeTab } = this.state;
      if (deltaX < 100) return false;
      switch (e.dir) {
        case 'Left':
          const v1 = activeTab === 7 ? 0 : activeTab + 1;
          // this.setState({ activeTab: v1 });
          this.handleChangeIndex(v1);
          break;
        case 'Right':
          const v = activeTab === 0 ? 7 : activeTab - 1;
          // this.setState({ activeTab: v });
          this.handleChangeIndex(v);
          break;
        default:
          break;
      }
    };

    handleChangeIndex = (index) => {
      const { crmid } = this.props.client.selectClient;
      const { dispatch } = this.props;
      this.setState({ activeTab: index });
      switch (index) {
        case 0:
          dispatch(fetchDataResume());
          break;
        case 1:
          dispatch(fetchAccountUpdates(crmid));
          break;
        case 2:

          break;
        case 3:
          this.fetchFinanceData();
          break;
        case 4:
          // dispatch(fetchDataResume());
          break;
        case 5:
          dispatch(fetchEmails('', '', crmid));
          break;
        case 6:
          // dispatch(fetchDataResume());
          break;
        case 7:

          break;

        default:
          break;
      }
    };

    render() {
      const { activeTab } = this.state;
      const {
        history, loads, account, client,
      } = this.props;

      return (
        <div
          className="clients-detail-container"
          style={{
            width: 520,
            position: 'absolute',
            left: '50%',
            marginLeft: -260,
            maxHeight: '90%',
            overflow: 'auto',
          }}
        >
          <div id="top-menu" className="sticky-top bg-white">
            <HeaderTop history={history} client={client.selectClient} id="head" />
            <SwipMenu activeTab={activeTab} handleChange={this.handleChange} onSwipeAction={this.onSwipeAction} load={loads.resumeIsLoading} />
            <SectionTitle title={title[activeTab]} />
          </div>
          <div className="d-flex flex-column p-0 m-0 w-100">
            {loads.resumeIsLoading && <LoadComponent />}
            {loads.updateIsLoading && <LoadComponent />}
            {loads.emailIsLoading && <LoadComponent /> }
            <SwipeableViews
              index={activeTab}
              className="d-flex flex-column f-1 no-scroll"
              onChangeIndex={this.handleChangeIndex}
            >
              <div className="tab-1 mx-2">
                {activeTab === 0 ? (<SummaryContainer client={client.selectClient} />) : null}
              </div>

              <div className="tab-2">
                <UpdatesContainer />
              </div>

              <div className="tab-3">
                <ComingSoon />
              </div>
              <div className="tab-4">
                {activeTab === 3 ? (<FinanceContainer />) : null}
              </div>
              <div className="tab-5">
                {/* <ProjectsContainer /> */}
                <ComingSoon />
              </div>
              <div className="tab-6">
                {
                  activeTab === 5
                    ? (
                      <ScrollPagination
                        allItems={account.emailList.data}
                        totalPages={account.emailList.pages}
                        itemsPerPage={25}
                        currentPage={1}
                        childComponent={<EmailContainer />}
                        action={fetchEmails}
                        clientId={client.selectClient.crmid}
                      />
                    )
                    : null
                }

              </div>
              <div className="tab-7">
                {/* <SupportContainer /> */}
                <ComingSoon />
              </div>
              <div className="tab-8">
                {
                  activeTab === 7
                    ? (
                      <DocumentsContainer />
                    )
                    : null
                }

              </div>
            </SwipeableViews>
          </div>
        </div>

      );
    }
}

const mapStateToProps = state => ({
  loads: state.loads,
  client: state.client,
  account: state.account,
});
export default withRouter(connect(mapStateToProps)(ClientPage));
