/* eslint-disable no-unused-expressions */
/* eslint-disable radix */
/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import Select from 'react-select';
import CCTipo from '../cc/components/tipo';
import FullDialog from '../../../FullDialog';

const ACC = ({
  corteDeCaja, isLoading, isOpen, onClose, onSave,
}) => {
  /* State */
  const [elementUser, setElement] = React.useState({ monedas: [], nombre: '' });
  const [users, setUsers] = React.useState([]);
  const [opciones, setOpciones] = React.useState([]);
  /* Functions */
  const setInitElements = (input) => {
    let arr = [...input];
    arr = arr.map((user) => {
      const monedas = user.monedas.map((type) => {
        const el = { ...type };
        el.enable = true;
        el.id = `id_${el.label}`;
        el.type = 'tipo';
        el.movimientos = type.movimientos ? type.movimientos.map((method) => {
          const _method = { ...method };
          _method.enable = true;
          _method.id = `id_${el.label}_${method.label}`;
          _method.type = 'metodo';
          _method.detalles = method.detalles.map((detail) => {
            const _detail = { ...detail };
            _detail.enable = true;
            _detail.type = 'detalle';
            return _detail;
          });
          return _method;
        }) : type.documentos.map((method) => {
          const _method = { ...method };
          _method.enable = true;
          _method.id = `id_${el.label}_${method.id}`;
          _method.type = 'docs';
          _method.detalles = [];
          return _method;
        });
        return el;
      });
      return ({ ...user, monedas });
    });
    const _opciones = arr.map(x => ({ value: x.id, label: x.nombre }));
    setUsers(arr);
    setOpciones(_opciones);
    setElement(arr[0]);
  };

  const checkTipo = (id) => {
    let monedas = [];
    monedas = elementUser.monedas.map((x) => {
      const el = { ...x };
      if (el.id === id) {
        const flag = !x.enable;
        el.enable = flag;
        el.movimientos = x.movimientos.map((mov) => {
          const movimiento = { ...mov };
          movimiento.enable = flag;
          movimiento.detalles = mov.detalles.map((det) => {
            const detail = { ...det };
            detail.enable = flag;
            return detail;
          });
          return movimiento;
        });
      }
      return el;
    });
    const newElement = { ...elementUser, monedas };
    setElement(newElement);
  };

  const checkMovimiento = (id) => {
    let monedas = [];
    monedas = elementUser.monedas.map((x) => {
      const el = { ...x };
      el.enable = true;
      el.movimientos = x.movimientos.map((mov) => {
        const movimiento = { ...mov };
        if (movimiento.id === id) {
          const flag = !mov.enable;
          movimiento.enable = flag;
          movimiento.detalles = mov.detalles.map((det) => {
            const detail = { ...det };
            detail.enable = flag;
            return detail;
          });
        }
        return movimiento;
      });
      return el;
    });
    const newElement = { ...elementUser, monedas };
    setElement(newElement);
  };


  const checkDocs = (id) => {
    let monedas = [];
    monedas = elementUser.monedas.map((x) => {
      const el = { ...x };
      el.enable = true;
      el.movimientos = x.movimientos.map((mov) => {
        const movimiento = { ...mov };
        if (movimiento.id === id) {
          const flag = !mov.enable;
          movimiento.enable = flag;
        }
        return movimiento;
      });
      return el;
    });
    const newElement = { ...elementUser, monedas };
    setElement(newElement);
  };

  const checkDetalle = (id) => {
    let monedas = [];
    monedas = elementUser.monedas.map((x) => {
      const el = { ...x };
      el.movimientos = x.movimientos.map((mov) => {
        const movimiento = { ...mov };
        movimiento.detalles = mov.detalles.map((det) => {
          const detail = { ...det };
          if (detail.id === parseInt(id)) {
            const flag = !det.enable;
            detail.enable = flag;
          }
          return detail;
        });
        if (movimiento.detalles.every(det => det.enable === false)) {
          movimiento.enable = false;
        } else {
          movimiento.enable = true;
        }
        return movimiento;
      });
      if (el.movimientos.every(mov => mov.enable === false)) {
        el.enable = false;
      } else {
        el.enable = true;
      }
      return el;
    });
    const newElement = { ...elementUser, monedas };
    setElement(newElement);
  };

  const onClickCheck = (target) => {
    const { kind, id } = target.dataset;
    switch (kind) {
      case 'tipo':
        checkTipo(id);
        break;
      case 'metodo':
        checkMovimiento(id);
        break;
      case 'detalle':
        checkDetalle(id);
        break;
      case 'docs':
        checkDocs(id);
        break;
      default:
        break;
    }
  };

  const onChange = (select) => {
    let user = {};
    if (select) {
      const { value } = select;
      user = users.find(x => x.id === value);
    }
    setElement(user);
  };

  const onSaveCC = (_elementUsers) => {
    let selectedCheckboxes = [];
    _elementUsers.monedas.forEach((el) => {
      el.movimientos.forEach((mov) => {
        if (el.label === 'DOCS') {
          const id = mov.id.split('_').pop();
          const parseId = parseInt(id);
          mov.enable ? selectedCheckboxes.push(parseId) : null;
        }
        const order = mov.detalles.filter(det => det.enable === true).map(x => x.id);
        selectedCheckboxes = [...selectedCheckboxes, ...order];
      });
    });
    // console.log('selectedCheckboxes', selectedCheckboxes);
    onSave({
      selectedCheckboxes,
      title: elementUser.nombre,
      id: elementUser.id,
    });
  };

  /* Effect */
  React.useEffect(() => {
    if (!isLoading) {
      setInitElements(corteDeCaja);
    }
  }, [isLoading, corteDeCaja]);
  return (
    <FullDialog
      title="Recibir un Corte de Caja"
      isOpen={isOpen}
      isLoading={isLoading}
      onClose={onClose}
      onSave={elementUser ? () => onSaveCC(elementUser) : () => {}}
      enableAction={!isLoading}
      color="secondary"
    >
      {(!isLoading && Boolean(elementUser !== undefined && elementUser.monedas.length > 0)) ? (
        <div className="balance__contenedor">
          <div className="w-100 d-flex justify-content-center align-items-center">
            <Select
              className="basic-single user_search"
              classNamePrefix="select"
              isClearable
              isSearchable
              name="usuarios"
              options={opciones}
              onChange={onChange}
            />
          </div>
          <h1 className="my-1 text-center">
            { elementUser.nombre || '' }
          </h1>
          <CCTipo elements={elementUser.monedas} click={onClickCheck} />
        </div>
      ) : (
        <div className="balance__contenedor d-flex align-items-center justify-content-center">
          <Typography className="ml-2" variant="h6" color="inherit">
            Sin datos para corte de caja
          </Typography>
        </div>
      )}
    </FullDialog>
  );
};

ACC.propTypes = {
  corteDeCaja: PropTypes.array,
  isLoading: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

ACC.defaultProps = {
  corteDeCaja: [],
};

export default ACC;
