/* eslint-disable prefer-promise-reject-errors */
import fetch from 'cross-fetch';
import ConfigurationAPI from './ConfigurationAPI';

class TimbrarApi extends ConfigurationAPI {
  constructor() {
    super();
    delete this.credentials.headers.pricebook;
  }

  getList = async (id) => {
    const url = `${this.url}/list/TreebesFacturas?related_to=${id}`;
    try {
      const response = await fetch(url, this.credentials);
      const json = await response.json();
      return { ...json };
    } catch (getErr) {
      return {
        success: false,
        result: [],
        message: `Error al crear una dirección: ${getErr}`,
      };
    }
  }

  get = async (crmid) => {
    const url = `${this.url}/TreebesSatCFDI/TimbraParcial?funcion=timbraparcial&desdeApp=Simonalamona&crmid=${crmid}`;
    this.credentials.method = 'POST';
    try {
      const response = await fetch(url, this.credentials);
      const json = await response.json();
      return { ...json };
    }

    catch {
      return { succes: false, result: [], message: 'Error en la API' };
    }
  };

  save = async (timbre) => {
    const url = `${this.url}/Timbra`;
    const {
      crmid,
      formapago,
      metodopago,
      uso,
    } = timbre;
    const data = {
      desdeApp: 'Simonalamona',
      organization_id: 1,
      salesorderid: crmid,
      uso,
      forma: formapago,
      metodo: metodopago,
    };
    this.credentials.method = 'POST';
    this.credentials.body = JSON.stringify(data);

    try {
      const response = await fetch(url, this.credentials);
      const json = await response.text();
      return { ...json };
    } catch (err) {
      console.log('err: ', err);
      return { succes: false, result: [], message: 'Error en la API' };
    }
  };

  xml = async (url, label) => new Promise(async (resolve, reject) => {
    try {
      const el = document.createElement('a');
      el.href = url;
      el.setAttribute('download', url.substring(url.lastIndexOf('/') + 1, url.length));
      document.body.appendChild(el);
      el.click();
    } catch (err) {
      console.log('err download xml: ', err);
      reject();
    }
  })

  getTicket = async crmid => new Promise(async (resolve, reject) => {
    try {
      const url = `${this.url}/id/${crmid}`;
      const response = await fetch(url, this.credentials);
      const json = await response.json();
      resolve(json);
    } catch (err) {
      console.log('err download xml: ', err);
      reject(err);
    }
  })

  /* Verificar cobros */
  verificarFdeTimbre = async (crmid) => {
    const url = `${this.url}/TreebesFdE/VerificaTimbraCobros`;
    this.credentials.method = 'POST';
    this.credentials.body = JSON.stringify({ pagosids: crmid });
    try {
      const response = await fetch(url, this.credentials);
      const json = await response.json();
      return { ...json };
    }

    catch {
      return { succes: false, result: [], message: 'Error en la API' };
    }
  };

  /* Timbar cobros */
  fdeTimbraCobro = async (crmid) => {
    const url = `${this.url}/TreebesFdE/TimbraCobros`;
    this.credentials.method = 'POST';
    this.credentials.body = JSON.stringify({ pagosids: crmid });
    try {
      const response = await fetch(url, this.credentials);
      const json = await response.json();
      return { ...json };
    }

    catch {
      return { succes: false, result: [], message: 'Error en la API' };
    }
  };
}

export default TimbrarApi;
