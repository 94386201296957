import { useState, useEffect } from 'react';
import TimbrarApi from '../../api/TimbrarApi';

export default function useFetchInvoiceOrder(orderId) {
  const [invoiceList, setInvoiceList] = useState([]);
  const [errorInvoice, setErrorInvoice] = useState(null);
  const [isLoadingInvoice, setIsLoadingInvoice] = useState(false);

  const getOrderFde = async () => {
    setIsLoadingInvoice(true);
    const timbrarApi = new TimbrarApi();
    const response = await timbrarApi.getList(orderId);
    const { success, result } = response;

    if (success) {
      setInvoiceList(result);
    } else {
      setErrorInvoice('Mientras cargabamos las facturas ocurrió un error');
    }

    setIsLoadingInvoice(false);
  };

  useEffect(() => {
    getOrderFde();
  }, [orderId]);

  return { invoiceList, isLoadingInvoice, errorInvoice };
}
