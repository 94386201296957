/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  AppBar, Button, CircularProgress, Dialog, Fab, IconButton, Slide, Toolbar, Typography,
} from '@material-ui/core';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import CloseIcon from '@material-ui/icons/Close';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import UserAPI from '../../api/UserAPI';
import WhatsappIcon from '../Icons/WhatsappIcon';
import './payment-links.scss';
import OrderAPI from '../../api/OrderAPI';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const PaymentLinks = ({ open, handleClose, orderId }) => {
  const history = useHistory();
  const customer = useSelector(state => state.customer);
  // const cart = useSelector(state => state.cart);
  const user = useSelector(state => state.authUser.user);
  // const { products, orderSelected: { order } } = cart;
  const { config } = user;
  const { selectedCustomer } = customer;
  const [showLink, setShowLink] = React.useState(false);

  const [url, setUrl] = React.useState('');
  const [products, setProducts] = React.useState([]);
  const [order, setOrder] = React.useState(null);
  const [hasLogin, setHasLogin] = React.useState(false);
  const [loadingCart, setloadingCart] = React.useState(false);
  const [loadingSell, setloadingSell] = React.useState(false);
  const [loadingHome, setloadingHome] = React.useState(false);

  const { pos_no_enlaceslogin } = config;
  const canMakeLogin = Boolean(pos_no_enlaceslogin === '1');

  const handleCloseModal = () => {
    setHasLogin(false);
    setUrl('');
    setShowLink(false);
    handleClose();
  };

  const handleChangeCheckbox = () => {
    setHasLogin(!hasLogin);
  };

  const generateUrl = async (action) => {
    let data = null;
    switch (action) {
      case 'Carrito':
        setloadingCart(true);
        if (products.length !== 0) {
          const productsItems = {};
          products.map((product) => {
            const productCrmid = product.crmid
              ? product.crmid
              : product.productid.indexOf('x') !== -1
                ? product.productid.split('x').pop()
                : product.productid;
            productsItems[`${productCrmid}`] = {
              accountid: selectedCustomer.crmid || config.id, tpoc_id: `${product.parentId || product.parent_id}`, productid: `${productCrmid}`, quantity: `${product.quantity}`,
            };
          });
          data = {
            accountid: hasLogin ? selectedCustomer.crmid : config.id,
            rel: productsItems,
            type: 'Cart',
            invited: hasLogin,
            source: 'TPV',
            userid: config.id,
          };
        } else {
          toast.error('Debes tener productos en el carrito antes de generar la url');
          return;
        }
        break;
      case 'Venta':
        setloadingSell(true);
        if (order) {
          data = {
            accountid: selectedCustomer.crmid,
            rel: `${order.crmid}`,
            type: 'Payment',
            invited: true,
            source: 'TPV',
            userid: config.id,
          };
        } else {
          toast.error('Debes seleccionar una venta antes de generar la url');
          return;
        }
        break;
      case 'Home':
        setloadingHome(true);
        data = {
          accountid: hasLogin ? selectedCustomer.crmid : config.id,
          rel: '',
          type: 'Accounts',
          invited: hasLogin,
          source: 'TPV',
          userid: config.id,
        };
        break;

      default:
        toast.error('Debes seleccionar un destino');
        return;
    }

    const userAPI = new UserAPI();
    const { success, result } = await userAPI.generateUrl(data);

    switch (action) {
      case 'Carrito':
        setloadingCart(false);
        break;
      case 'Venta':
        setloadingSell(false);
        break;
      case 'Home':
        setloadingHome(false);
        break;

      default:
        return;
    }

    if (success) {
      setUrl(result.url);
      setShowLink(true);
    } else {
      console.log('error obteniendo la url');
    }
  };

  const copyToClipboard = () => {
    /* Get the text field */
    const copyText = document.getElementById('urlId');
    /* Select the text field */
    copyText.select();
    copyText.setSelectionRange(0, 99999);

    /* Copy the text inside the text field */
    document.execCommand('copy');

    /* Alert the copied text */
    toast.success(`Url copiada : ${copyText.value}`);
  };

  const redirectToWhatsapp = () => {
    window.open(`https://wa.me/?text=${url}`, '_blank');
  };

  const orderDetail = async () => {
    const orderApi = new OrderAPI();
    const response = await orderApi.getOrderDetail(orderId);
    console.log('responseeeeee', response);
    const { success, result } = response;
    if (success && result && result.LineItems) {
      const prod = [];
      result.LineItems.map((item) => {
        prod.push({
          crmid: item.productid.split('x').pop(),
          parentId: item.parent_id,
          quantity: parseFloat(item.quantity),
        });
      });

      setProducts(prod);
      setOrder(result);
    }
  };

  useEffect(() => {
    if (orderId) {
      orderDetail();
    }
  }, [orderId]);


  return (
    <Dialog fullScreen open={open} onClose={handleCloseModal} TransitionComponent={Transition}>
      <AppBar style={{ position: 'relative' }}>
        <Toolbar variant="dense" classes={{ root: 'd-flex justify-content-between' }}>
          <p />
          <Typography variant="h6" style={{ color: '#FFF' }}>
            Compartir enlace
          </Typography>
          <IconButton edge="start" color="inherit" onClick={handleCloseModal} aria-label="close">
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <div className="d-flex justify-content-center align-items-center" style={{ width: '100%' }}>
        <div className="" style={{ width: 320 }}>

          <AppBar style={{ position: 'relative', backgroundColor: '#F6F6F7', marginTop: 20 }}>
            <Toolbar variant="dense" classes={{ root: 'tollbar-header' }}>
              <Typography variant="h6" style={{ color: '#64707A' }}>
                Enlaces
              </Typography>
            </Toolbar>
          </AppBar>
          {!showLink && (
          <div className="d-flex flex-column justify-content-between my-4">
            {
            selectedCustomer.accountname !== 'PUBLICO EN GENERAL' && (
              <div className="d-flex justify-content-between my-4">
                <h4>Activar login</h4>
                <div className="d-flex justify-content-between flex-column">
                  <input className="align-self-end mb-1" defaultChecked={hasLogin} onChange={handleChangeCheckbox} type="checkbox" />
                  <span>El enlace durará 30 min</span>
                </div>
              </div>
            )
          }
            <div className="d-flex justify-content-around align-items-center my-4">

              <button disabled={products.length === 0} type="button" className="option-btn d-flex flex-column justify-content-center align-items-center" onClick={() => generateUrl('Carrito')}>
                {
                    !loadingCart && (
                      <FontAwesomeIcon size="3x" icon="shopping-cart" className="mb-1" />
                    )
                  }
                {
                    loadingCart && (
                      <CircularProgress />
                    )
                  }
                Carrito
              </button>
              {
                history?.location.pathname.includes('/pos/venta') && order && (
                <button type="button" className="option-btn d-flex flex-column justify-content-center align-items-center" onClick={() => generateUrl('Venta')}>
                  {
                    !loadingSell && (
                      <FontAwesomeIcon size="3x" icon="hand-holding-usd" className="mb-1" />
                    )
                  }
                  {
                    loadingSell && (
                      <CircularProgress />
                    )
                  }
                  Venta
                </button>
                )
            }
              <button type="button" className="option-btn d-flex flex-column justify-content-center align-items-center" onClick={() => generateUrl('Home')}>
                {
                  !loadingHome && (
                    <FontAwesomeIcon size="3x" icon="home" className="mb-1" />
                  )
                }
                {
                  loadingHome && (
                    <CircularProgress />
                  )
                }
                Home
              </button>
            </div>
          </div>
          )}
          {showLink && (
          <div className="success my-4">
            <div className="my-4" style={{ position: 'relative' }}>
              <input
                onClick={copyToClipboard}
                type="text"
                readOnly
                id="urlId"
                value={url}
                style={{
                  width: '100%',
                  borderRadius: '10px',
                  height: '45px',
                  border: '1px solid #e6e6e6',
                  backgroundColor: '#f6f6f7',
                  padding: '0 10px',
                }}
              />
              <FontAwesomeIcon
                icon={faCopy}
                onClick={copyToClipboard}
                style={{
                  position: 'absolute',
                  fontSize: '25px',
                  right: '8px',
                  top: '8px',
                  cursor: 'pointer',
                }}
              />
            </div>
            <div className="d-flex justify-content-between align-items-center my-4">
              <span style={{ fontSize: 18 }}>Compartir por:</span>
              <Fab variant="extended" onClick={redirectToWhatsapp}>
                <WhatsappIcon fill="#0000008a" style={{ width: 18 }} />
              </Fab>
            </div>
            <Button variant="contained" style={{ backgroundColor: '#f6f6f7', color: 'black', width: '100%' }} className="my-5" onClick={() => setShowLink(false)}>
              Nuevo enlace
            </Button>
          </div>
          )}
        </div>

      </div>
    </Dialog>
  );
};

export default PaymentLinks;
