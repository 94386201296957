/* eslint-disable import/no-cycle */
import { connect } from 'react-redux';

/* Main Component */
import Comment from './Comment';

/* Redux Func */
const mapStateToProps = state => ({
  cart: state.cart,
  loads: state.loads,
  modals: state.modals,
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Comment);
