/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable import/no-cycle */
/* eslint-disable no-undef */
/* eslint-disable camelcase */
import React, { useState } from 'react';
import '../../warehouse.styles.scss';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import {
  faDolly,
  faFileInvoice,
  faHandHoldingUsd,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  Collapse,
  ListItemIcon,
  IconButton,
} from '@material-ui/core';
import { useHistory } from 'react-router';
import { Search } from '@material-ui/icons';
import Iconwidthbg from '../../../../components/Icons/IconWidthBG/IconWidthBG';
import {
  formatMoney,
  getColorStatusFDE,
  getColorStatusMDA,
  geteColorSosStatus,
} from '../../../../helpers/tools';
import WSalesExpand from './WSalesExpand';
import { fetchAllOrders, selectOrder } from '../../../../redux/actions/orderActions';
import ShareModal from '../../../../components/Share/components/ShareModal';
import GenericFullModal from '../../../../components/GenericFullModal';
import ShippingGuideList from '../../../../components/ShippingGuide/ShippingGuideList';
import appModules from '../../../../constant/appModules';
import MdaAPI from '../../../../api/MdaApi';
import CustomDatePicker from '../../../../components/CustomInputs/components/custom-date-picker';
import FilterFab from '../../../Pos/components/PosMobileActionsBar/components/FilterFab';
import { loaded, loading } from '../../../../redux/actions/loadActions';

const WSales = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const loads = useSelector(state => state.loads);
  const cart = useSelector(state => state.cart);
  const authUser = useSelector(state => state.authUser);
  const { orderSelected: { order } } = cart;
  const isLoading = Boolean(loads && loads.emailTemplateIsLoading);
  const [salesOrders, setSalesOrders] = React.useState([]);
  const [filteredOrders, setFilteredOrders] = React.useState([]);
  const [selectSale, setSelectSale] = React.useState(order);
  const salesOrdersSummary = useSelector(state => state.order.orders);

  const [openShare, setOpenShare] = React.useState(false);
  const [openGuide, setOpenGuide] = React.useState(false);

  const [search, setSearch] = useState('subject');

  const { user: { config: { store } } } = authUser;


  const selectLocalOrder = (sale) => {
    if (selectSale && sale.crmid === selectSale.crmid) {
      setSelectSale(null);
    } else {
      setSelectSale(sale);
    }
  };

  const handleShareClose = () => {
    setOpenShare(false);
    setOpenGuide(false);
  };

  const entregarOrden = () => {
    history.push(`/almacenes/entregar/${selectSale.crmid}`);
  };


  const { pos_filtrostate } = useSelector(
    state => state.authUser.user.config,
  );

  const returnFilteredOrders = async () => {
    const filters = pos_filtrostate ? pos_filtrostate.split(' |##| ') : [];
    const ordersByFilter = salesOrders.filter(item => filters.includes(item.sostatus));
    setFilteredOrders([...ordersByFilter]);
  };

  const getSalesToDeliver = async () => {
    dispatch(loading('order'));
    const mdaApi = new MdaAPI();
    const response = await mdaApi.salesToDeliver(store.crmid);
    const { success, result } = response;

    if (success) {
      setSalesOrders(result);
      setFilteredOrders(result);
    }
    dispatch(loaded('order'));
  };

  const makeSearch = (searchValue) => {
    const filter = salesOrders.filter(item => item[`${search}`].toLowerCase().includes(searchValue.toLowerCase()));
    setFilteredOrders([...filter]);
  };


  const handleChangeDate = (name, date) => {
    const sales = [...salesOrders];
    if (date[0] === null && date[1] === null) {
      setFilteredOrders([...sales]);

      return;
    }

    if (date[0] !== null && date[1] !== null) {
      const filter = sales.filter(item => moment(item[`${search}`]).isBetween(moment(date[0]).startOf('days'), moment(date[1]).endOf('day'), undefined, '[]'));
      setFilteredOrders([...filter]);
    } else {
      const filter = sales.filter(item => moment(item[`${search}`]).isBetween(moment(date[0]).startOf('days'), moment(date[0]).endOf('day'), undefined, '[]'));
      setFilteredOrders([...filter]);
    }
  };

  React.useEffect(() => {
    getSalesToDeliver();
  }, [store]);

  React.useEffect(() => {
    returnFilteredOrders();
  }, [pos_filtrostate, salesOrdersSummary]);

  return (
    <>
      <div className="bg-white w-100 d-flex justify-content-between align-items-center px-2 py-4 sticky-top">
        <div className="d-flex w-100 px-2 py-2" style={{ backgroundColor: '#ebebeb', borderRadius: 20 }}>
          <select
            style={{
              border: 0,
              borderRadius: 10,
              padding: '0 5px',
              marginRight: '0.5rem',
              outline: 'none',
            }}
            onChange={e => setSearch(e.target.value)}
          >
            <option selected={search === 'subject'} value="subject">Asunto</option>
            <option selected={search === 'accountname'} value="accountname">Cuanta usuario</option>
            <option selected={search === 'assigned_user'} value="assigned_user">Usuario asignado</option>
            <option selected={search === 'salesorder_no'} value="salesorder_no">No. orden</option>
            <option selected={search === 'almacenista'} value="almacenista">Almacenista</option>
            <option selected={search === 'razon_social'} value="razon_social">Razón social</option>
            <option selected={search === 'f_estado_autori'} value="f_estado_autori">Fecha autorizado</option>
          </select>
          {
            search === 'f_estado_autori'
              ? (
                <CustomDatePicker
                  style={{ height: 30 }}
                  onChange={handleChangeDate}
                  headerName="date"
                />
              )
              : (
                <input
                  autoFocus
                  style={{
                    border: 0,
                    width: '100%',
                    backgroundColor: 'transparent',
                    outline: 'none',
                  }}
                  onChange={e => makeSearch(e.target.value)}
                  type="text"
                />
              )
          }
          <IconButton style={{ height: 30, color: '#313c52' }}>
            <Search id="actions_search_tag" />
          </IconButton>
        </div>
      </div>

      {loads.orderIsLoading && (
      <Collapse in={loads.orderIsLoading}>
        <LinearProgress />
      </Collapse>
      )}

      <div>
        {filteredOrders.length ? (

          <div style={{ paddingBottom: 66 }}>
            {filteredOrders.map(sale => (
              <div key={sale.crmid} style={{ backgroundColor: '#fff', cursor: 'pointer' }}>
                <ListItem divider className="quote" onClick={() => selectLocalOrder(sale)}>
                  <ListItemText
                    classes={{ primary: 'quote__title', secondary: 'quote__subtitle' }}
                    primary={sale.subject}
                    secondary={(
                      <>
                        <span>
                          No. Venta:
                          {' '}
                          {sale.salesorder_no}
                        </span>
                        <br />
                        <span>{sale.accountname}</span>
                        <br />
                        <span>{moment(sale.f_estado_autori).format('DD MMM YYYY h:mm a')}</span>
                      </>
                    )}
                  />
                  <div className="d-flex flex-column">
                    <ListItemText classes={{ primary: 'quote__money' }} primary={sale.assigned_user} style={{ textAlign: 'right', padding: 0 }} />
                    <div className="d-flex justify-content-end">
                      <ListItemIcon className="m-0">
                        <Iconwidthbg
                          primaryColor={geteColorSosStatus(sale.sostatus)}
                          secondaryColor="white"
                          width={27}
                          height={27}
                          classes="ml-2"
                          enabled
                          square
                          rounded
                          actionClick={() => {}}
                        >
                          <FontAwesomeIcon
                            style={{ fontSize: 15 }}
                            icon={faFileInvoice}
                          />
                        </Iconwidthbg>
                      </ListItemIcon>
                      <ListItemIcon className="m-0">
                        <Iconwidthbg
                          primaryColor={getColorStatusMDA(sale.estado_mda)}
                          secondaryColor="white"
                          width={27}
                          height={27}
                          classes="ml-2"
                          enabled
                          square
                          rounded
                          actionClick={() => {}}
                        >
                          <FontAwesomeIcon
                            style={{ fontSize: 15 }}
                            icon={faDolly}
                          />
                        </Iconwidthbg>
                      </ListItemIcon>
                      <ListItemIcon className="m-0">
                        <Iconwidthbg
                          primaryColor={getColorStatusFDE(sale.estado_fde)}
                          secondaryColor="white"
                          width={27}
                          height={27}
                          classes="ml-2"
                          enabled
                          square
                          rounded
                          actionClick={() => {}}
                        >
                          <FontAwesomeIcon
                            style={{ fontSize: 15 }}
                            icon={faHandHoldingUsd}
                          />
                        </Iconwidthbg>
                      </ListItemIcon>
                    </div>
                  </div>
                </ListItem>
                <Collapse in={selectSale && selectSale.crmid === sale.crmid} style={{ backgroundColor: '#fafafa', marginBottom: 10 }}>
                  <WSalesExpand sale={sale} openShare={setOpenShare} openGuide={setOpenGuide} deliverSale={entregarOrden} />
                </Collapse>
              </div>
            ))}
          </div>
        ) : (
          <List dense>
            <ListItem classes={{ root: 'bg-white' }}>
              <ListItemText
                primary="No se encontraron ventas"
              />
            </ListItem>
          </List>
        )}
      </div>

      <ShareModal
        open={openShare}
        title={`Compartir venta ${selectSale ? selectSale?.entity_label : ''}`}
        handleClose={handleShareClose}
        order={selectSale || {}}
        template={appModules.SalesOrder}
        isLoading={isLoading}
      />

      <GenericFullModal
        open={openGuide}
        title={`Venta ${selectSale ? selectSale?.entity_label : ''}`}
        handleClose={handleShareClose}
        componentToShow={<ShippingGuideList itemCrmid={selectSale?.crmid} />}
      />

    </>
  );
};

export default WSales;
