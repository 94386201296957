import React from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  ModalHeader,
  ModalBody,
} from 'reactstrap';

const BsModal = ({
  title, isOpen, onClose, children,
}) => (
  <Modal
    isOpen={isOpen}
    toggle={onClose}
    size="xl"
  >
    <ModalHeader toggle={onClose}>{title}</ModalHeader>
    <ModalBody>
      {children}
    </ModalBody>
  </Modal>
);


BsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
};

export default BsModal;
