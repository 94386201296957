import React from 'react';
import PropTypes from 'prop-types';
import {
  Tooltip,
  IconButton,
} from '@material-ui/core';
import { Sync } from '@material-ui/icons';

const SyncCartAction = ({
  enable,
  tooltip,
  syncCart,
}) => (
  <Tooltip
    title={tooltip}
    placement="left"
  >
    <span>
      <IconButton
        id="action_control_save"
        disabled={!enable}
        onClick={syncCart}
        className="pos_menu_setDir"
      >
        <Sync />
        <span className="font-size-10">Sincronizar Carrito</span>
      </IconButton>
    </span>
  </Tooltip>
);

SyncCartAction.propTypes = {
  tooltip: PropTypes.string,
  enable: PropTypes.bool.isRequired,
  syncCart: PropTypes.func.isRequired,
};
SyncCartAction.defaultProps = {
  tooltip: 'Sincronizar carrito',
};

export default SyncCartAction;
