// eslint-disable-next-line import/no-cycle
import { updateTotals } from './cartActions';

export const ADD_DISCOUNT = 'ADD_DISCOUNT';
export const CHANGE_DISCOUNT = 'CHANGE_DISCOUNT';
export const UPDATE_TOTAL_DISCOUNT = 'UPDATE_TOTAL_DISCOUNT';
export const REMOVE_DISCOUNT = 'REMOVE_DISCOUNT';

export function addDiscount(discount) {
  return async (dispatch, getState) => {
    const { cart } = getState();
    const discountWithoutTaxes = {
      fixed: discount.fixed,
      percentage: discount.percentage,
      total: cart.subTotal * (discount.percentage / 100),
    };
    dispatch({
      type: ADD_DISCOUNT,
      discount: discountWithoutTaxes,
    });
  };
}

export function changeDiscount(discount) {
  return {
    type: CHANGE_DISCOUNT,
    discount,
  };
}

export function updateTotalDiscount(total) {
  return (dispatch, getState) => {
    const { cart } = getState();
    const { orderSelected: { order } } = cart;
    const orderDiscountPercent = parseFloat(order?.hdnDiscountPercent) || 0;
    dispatch({
      type: UPDATE_TOTAL_DISCOUNT,
      total: ((total || cart.total) - ((cart.anotherTaxes || 0) + cart.totalIva)) / (1 - orderDiscountPercent / 100),
    });
  };
}

export function removeDiscount() {
  return async (dispatch) => {
    await dispatch({
      type: REMOVE_DISCOUNT,
    });

    dispatch(updateTotals());
  };
}
