/* eslint-disable import/no-cycle */

import React from 'react';
import PropTypes from 'prop-types';
import {
  Tooltip,
  IconButton,
} from '@material-ui/core';
import ShareOutlined from '@material-ui/icons/ShareOutlined';
/* Components */
import ShareModal from '../../../../Share/components/ShareModal';
import appModules from '../../../../../constant/appModules';


const ShareControls = ({
  tabs,
  cart,
  loads,
  tooltip,
  disabledLabel,
}) => {
  const isLoading = Boolean(loads && loads.emailTemplateIsLoading);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }
  function handleClose() {
    setAnchorEl(null);
  }
  /* Constants */
  const { posTypeTab } = tabs;
  const { orderSelected } = cart;
  let template = '';
  let modalTitle = '';

  switch (posTypeTab) {
    case 'Historico':
      template = appModules.SalesOrder;
      modalTitle = 'Orden';
      break;
    case 'Cotizaciones':
      template = appModules.Quotes;
      modalTitle = 'Cotización';
      break;

    default:
      break;
  }
  const order = (orderSelected && orderSelected.order)
    ? orderSelected.order
    : {};
  const orderNo = (orderSelected && orderSelected.order)
    ? (orderSelected.order.salesorder_no || orderSelected.order.label)
    : '';
  const title = `Compartir ${modalTitle} ${orderNo}`;
  /* Enables */
  const isOrderSelected = Boolean(Object.keys(orderSelected).length > 0);
  const enableShareButton = Boolean(posTypeTab !== 'Vender' && isOrderSelected);
  return (
    <>
      <Tooltip placement="left" title={(enableShareButton) ? (tooltip) : (disabledLabel)}>
        <span>
          <IconButton
            aria-label="share"
            aria-controls="cart-share-menu"
            aria-haspopup="true"
            onClick={handleClick}
            disabled={!enableShareButton}
            className="pos_menu_setDir"
            id="action_control_share"
          >
            <ShareOutlined />
            <span className="font-size-10">Compartir</span>
          </IconButton>
        </span>
      </Tooltip>
      <ShareModal
        open={open}
        title={title}
        handleClose={handleClose}
        order={order}
        template={template}
        isLoading={isLoading}
      />
    </>
  );
};

ShareControls.propTypes = {
  tooltip: PropTypes.string,
  disabledLabel: PropTypes.string,
  cart: PropTypes.object.isRequired,
  tabs: PropTypes.object.isRequired,
  loads: PropTypes.object.isRequired,
};
ShareControls.defaultProps = {
  tooltip: 'Compartir',
  disabledLabel: 'Selecciona una Orden de Venta o una Cotización primero',
};

export default ShareControls;
