/* eslint-disable no-restricted-properties */
/* eslint-disable radix */
/* eslint-disable react/prefer-stateless-function */
import React from 'react';
import QRCode from 'qrcode.react';
import PropTypes from 'prop-types';

import { toolsHelper } from '../../../../helpers';

class TicketBillToPrint extends React.Component {
  static propTypes = {
    authUser: PropTypes.object.isRequired,
    timbrar: PropTypes.object.isRequired,
    orderNumber: PropTypes.string.isRequired,
  };

  static defaultProps = {};

  getTotalRound = n => (n.toFixed(2));

  render() {
    const {
      timbrar,
      authUser,
      orderNumber,
    } = this.props;
    const {
      user: {
        config,
        logoname,
        organizationname,
      },
    } = authUser;
    const scale = config.pos_print_size ? config.pos_print_size : '7cm';


    const { ticket } = timbrar;
    const totalIva = (
      Object.keys(ticket.impuestos).length > 0 ? (
        ticket.impuestos._attr.totalimpuestostrasladados
      ) : (0.0)
    );
    let forQr = 'https://verificacfdi.facturaelectronica.sat.gob.mx/default.aspx?';
    if (Object.keys(ticket).length > 0) {
      const forId = ticket.uuid ? `id=${ticket.uuid}` : '';
      const forRe = ticket.emisor_rfc ? `&re=${ticket.emisor_rfc}` : '';
      const forRr = ticket.receptor_rfc ? `&rr=${ticket.receptor_rfc}` : '';
      const forTt = ticket.total ? `&tt=${this.getTotalRound(parseFloat(ticket.total))}` : '';
      const forFe = ticket.sellocfd ? `&fe=${ticket.sellocfd.substring(ticket.sellocfd.length - 8, ticket.sellocfd.length)}` : '';
      forQr = forQr.concat(forId, forFe, forRe, forRr, forTt);
    }
    return (
      <table style={{ maxWidth: scale, minWidth: scale }} className="tickets_table">
        <thead>
          <tr>
            <th colSpan={5}>
              <img
                src={config.store.image || logoname}
                alt={organizationname}
                className="tickets_table_logo"
              />
            </th>
          </tr>
        </thead>
        <thead>
          <tr>
            <th className="tickets_table_type" colSpan={5}>
              {`FACTURA ${ticket.serie}${ticket.folio}`}
            </th>
          </tr>
          <tr>
            <th className="tickets_table_type" colSpan={5}>
              {`ORDER:  ${orderNumber}`}
            </th>
          </tr>
        </thead>
        <thead>
          <tr>
            <th colSpan={5}>----------------------------</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colSpan={5} className="tickets_table_clientBill">
              {`Emisión: ${ticket.createdtime}`}
            </td>
          </tr>
          <tr>
            <td colSpan={5} className="tickets_table_clientBill">
              {`Estado: ${ticket.estadosat}`}
            </td>
          </tr>
        </tbody>
        <thead>
          <tr>
            <th colSpan={5}>----------------------------</th>
          </tr>
        </thead>
        <thead>
          <tr>
            <th colSpan={2} className="tickets_table_clientBillH">Receptor</th>
            <th>|</th>
            <th colSpan={2} className="tickets_table_clientBillH">Emisor</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colSpan={2} width="40%" className="tickets_table_clientBillH">
              {ticket.receptor_nombre}
            </td>
            <td className="text-center">|</td>
            <td colSpan={2} width="40%" className="tickets_table_clientBillH">
              {ticket.emisor_nombre}
            </td>
          </tr>
          <tr>
            <td colSpan={2} width="40%" className="tickets_table_clientBillH">
              {ticket.receptor_rfc}
            </td>
            <td className="text-center">|</td>
            <td colSpan={2} width="40%" className="tickets_table_clientBillH">
              {ticket.emisor_rfc}
            </td>
          </tr>
        </tbody>
        <thead>
          <tr>
            <th colSpan={5}>----------------------------</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colSpan={5} className="tickets_table_clientBill">
              {`Uso de CFDI: ${ticket.receptor_uso}`}
            </td>
          </tr>
          <tr>
            <td colSpan={5} className="tickets_table_clientBill">
              {`Metodo de Pago: ${ticket.metodopago}`}
            </td>
          </tr>
          <tr>
            <td colSpan={5} className="tickets_table_clientBill">
              {`Forma de Pago: ${ticket.formapago}`}
            </td>
          </tr>
        </tbody>
        <thead>
          <tr>
            <th colSpan={5}>----------------------------</th>
          </tr>
        </thead>
        <thead>
          <tr className="bill_table_head">
            <th>
              Cantidad
            </th>
            <th colSpan={2}>
              Concepto
            </th>
            <th>
              Precio
              Unitario
            </th>
            <th>
              Importe
            </th>
          </tr>
        </thead>
        <tbody>
          {ticket.conceptos.map(concept => (
            <tr
              key={`key_${concept._attr.claveprodserv}_${concept._attr.cantidad}_${concept._attr.claveprodserv}_${Math.random()}`}
              className="rowTh"
            >
              <td style={{ width: '1.6em', fontSize: '14px' }}>
                <div>{concept._attr.cantidad}</div>
              </td>
              <td className="lineTh" colSpan={2}>
                <div className="w-100 font-size-15 font-weight-bold titleTh">{concept._attr.noidentificacion}</div>
                <div className="w-100 font-size-14 titleTh">{concept._attr.claveprodserv}</div>
                <div className="w-100 font-size-12 titleTh">{concept._attr.claveunidad}</div>
                <div className="bill_desc">{concept._attr.descripcion}</div>
              </td>
              <td>
                <div>{`$${toolsHelper.formatMoney(concept._attr.valorunitario)}`}</div>
              </td>
              <td>
                <div className="font-weight-bold text-right">
                  {`$${toolsHelper.formatMoney(concept._attr.importe)}`}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
        <tbody className="mt-2">
          <tr>
            <td className="tickets_table_subtotal" colSpan={2}>Sub-Total: </td>
            <td className="tickets_table_subtotal" colSpan={3}>
              {`$ ${toolsHelper.formatMoney(ticket.subtotal)}`}
            </td>
          </tr>
          <tr>
            <td className="tickets_table_subtotal" colSpan={2}>IVA-total:</td>
            <td className="tickets_table_subtotal" colSpan={3}>
              {`$ ${toolsHelper.formatMoney(totalIva)}`}
            </td>
          </tr>
          <tr>
            <td className="tickets_table_subtotal" colSpan={2}>Total:</td>
            <td className="tickets_table_total" colSpan={3}>
              {`$ ${ticket.total}`}
            </td>
          </tr>
        </tbody>
        <thead>
          <tr>
            <th colSpan={5}>----------------------------</th>
          </tr>
        </thead>
        <thead>
          <tr>
            <th colSpan={5}>
              <QRCode value={forQr} size={164} />
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colSpan={5} className="tickets_table_clientBill">
              <div className="w-100">UUID</div>
              <div className="w-100 text-area-container font-size-9">{ticket.uuid}</div>
            </td>
          </tr>
          <tr>
            <td colSpan={5} className="tickets_table_clientBill">
              <div className="w-100">No de Serie del Certificado:</div>
              <div className="w-100 text-area-container font-size-9">{ticket.nocertificado}</div>
            </td>
          </tr>
          <tr>
            <td colSpan={5} className="tickets_table_clientBill">
              <div className="w-100">No de Serie del Certificado del SAT:</div>
              <div className="w-100 text-area-container font-size-9">
                {ticket.nocertificadosat}
              </div>
            </td>
          </tr>
          <tr>
            <td colSpan={5} className="tickets_table_clientBill">
              <div className="w-100">Sello digital del CFDI:</div>
              <div className="text-area-container font-size-9" style={{ width: scale }}>
                {ticket.sello}
              </div>
            </td>
          </tr>
          <tr>
            <td colSpan={5} className="tickets_table_clientBill">
              <div className="w-100">Sello del SAT</div>
              <div className="text-area-container font-size-9" style={{ width: scale }}>
                {ticket.sellosat}
              </div>
            </td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td colSpan={5} className="tickets_table_type">
              Cadena original del complemento de certificación del SAT:
            </td>
          </tr>
          <tr>
            <td colSpan={5} className="tickets_table_clientBill">
              <div className="text-area-container font-size-9" style={{ width: scale }}>
                {ticket.certificado}
              </div>
            </td>
          </tr>
        </tbody>
        <thead>
          <tr>
            <th colSpan={5}>--------------------------------------</th>
          </tr>
        </thead>
      </table>
    );
  }
}

export default TicketBillToPrint;
