/* eslint-disable import/no-cycle */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-console */
import { toast } from 'react-toastify';
import { loading, loaded } from './loadActions';
import { toggleModal } from './modalActions';
import ExpensesApi from '../../api/ExpensesApi';
import DownloadAPI from '../../api/DownloadAPI';
import DocumentsAPI from '../../api/DocumentsApi';

import {
  GET_EXPENSE,
  GET_VENDOR,
  GET_VENDORS,
  GET_EXPENSES,
  GET_ALMACEN,
  GET_ALMACENES,
  GET_EXPENSE_TYPE,
  GET_EXPENSES_TYPE,
  GET_VENDORS_PAGINATOR,
  GET_EXPENSES_PAGINATOR,
  GET_ALMACENES_PAGINATOR,
  GET_EXPENSES_TYPE_PAGINATOR,
  GET_INVOICES_BY_EXPENSE_PROVIDER,
  GET_INVOICES_BY_EXPENSE_PROVIDER_PAGINATOR,
  GET_EXPENSES_DOCUMENTS,
  GET_EXPENSES_DOCUMENTS_PAGINATOR,
} from '../types/expensesActionsType';
import { getDocumentsByTask } from './taskActions';

export function AddExpenses(expenses, pagination) {
  return {
    type: pagination ? GET_EXPENSES_PAGINATOR : GET_EXPENSES,
    payload: expenses,
  };
}

export function AddSelectExpense(expense) {
  return {
    type: GET_EXPENSE,
    payload: expense,
  };
}

export function AddExpenseInvoicesbyProvider(invoices, pagination) {
  return {
    type: pagination ? GET_INVOICES_BY_EXPENSE_PROVIDER_PAGINATOR : GET_INVOICES_BY_EXPENSE_PROVIDER,
    payload: invoices,
  };
}

export function AddVendors(vendors, pagination) {
  return {
    type: pagination ? GET_VENDORS_PAGINATOR : GET_VENDORS,
    payload: vendors,
  };
}

export function AddVendor(vendor) {
  return {
    type: GET_VENDOR,
    payload: vendor,
  };
}


export function AddExpensesType(expensesType, pagination) {
  return {
    type: pagination ? GET_EXPENSES_TYPE_PAGINATOR : GET_EXPENSES_TYPE,
    payload: expensesType,
  };
}

export function AddSelectExpenseType(expenseType) {
  return {
    type: GET_EXPENSE_TYPE,
    payload: expenseType,
  };
}

export function AddAlmacenes(almacenes, pagination) {
  return {
    type: pagination ? GET_ALMACENES_PAGINATOR : GET_ALMACENES,
    payload: almacenes,
  };
}

export function AddAlmacen(almacen) {
  return {
    type: GET_ALMACEN,
    payload: almacen,
  };
}

export function GetExpenseDocuments(documents, pagination) {
  return {
    type: pagination ? GET_EXPENSES_DOCUMENTS_PAGINATOR : GET_EXPENSES_DOCUMENTS,
    payload: documents,
  };
}

export const fetchListExpenses = (page = 1, search = '', accountId = 0, pagination = false) => async (dispatch) => {
  const expensesApi = new ExpensesApi();
  dispatch(loading('update'));
  try {
    const response = await expensesApi.getExpensesList(page, search, accountId);
    const { success, result, pages } = response;
    if (process.env.NODE_ENV === 'development') {
      console.log('===================fetchListExpenses=================');
      console.log({ result });
      console.log('====================================');
    }
    if (success) {
      dispatch(AddExpenses({ result, pages }, pagination));
    }
    else {
      console.error('Error obteniendo datos de gastos');
      // toast.error('Error obteniendo datos de cotización');
    }
  } catch (err) {
    toast.error(err);
  }
  finally {
    dispatch(loaded('update'));
  }
};

export const fetchSelectedExpense = id => async (dispatch) => {
  const expensesApi = new ExpensesApi();
  dispatch(loading('update'));
  try {
    const response = await expensesApi.getExpense(id);
    const { success, result } = response;
    if (process.env.NODE_ENV === 'development') {
      console.log('===================fetchSelectedExpense=================');
      console.log({ result });
      console.log('====================================');
    }
    if (success) {
      dispatch(AddSelectExpense(result));
    }
    else {
      console.error('Error obteniendo datos de gastos');
      // toast.error('Error obteniendo datos de cotización');
    }
  } catch (err) {
    toast.error(err);
  }
  finally {
    dispatch(loaded('update'));
  }
};

export const fetchListExpensesInvoices = (page = 1, search = '', accountId = 0, pagination = false) => async (dispatch) => {
  const expensesApi = new ExpensesApi();
  dispatch(loading('update'));
  try {
    const response = await expensesApi.getExpenseInvoicesByProvider(page, search, accountId);
    const { success, result, pages } = response;
    if (process.env.NODE_ENV === 'development') {
      console.log('===================fetchListExpensesInvoices=================');
      console.log({ result });
      console.log('====================================');
    }
    if (success) {
      dispatch(AddExpenseInvoicesbyProvider({ result, pages }, pagination));
    }
    else {
      console.error('Error obteniendo datos de gastos');
      // toast.error('Error obteniendo datos de cotización');
    }
  } catch (err) {
    toast.error(err);
  }
  finally {
    dispatch(loaded('update'));
  }
};

export const fetchListVendors = (page = 1, search = '', pagination = false) => async (dispatch) => {
  const expensesApi = new ExpensesApi();
  dispatch(loading('update'));
  try {
    const response = await expensesApi.getVendors(page, search);
    const { success, result, pages } = response;
    if (process.env.NODE_ENV === 'development') {
      console.log('===================fetchListVendors=================');
      console.log({ result });
      console.log('====================================');
    }
    if (success) {
      dispatch(AddVendors({ result, pages }, pagination));
    }
    else {
      console.error('Error obteniendo datos de gastos');
      // toast.error('Error obteniendo datos de cotización');
    }
  } catch (err) {
    toast.error(err);
  }
  finally {
    dispatch(loaded('update'));
  }
};

export const fetchVendor = vendor => async (dispatch) => {
  try {
    if (vendor) {
      dispatch(AddVendor(vendor));
    }
    else {
      console.error('Error obteniendo datos de proveedor');
      // toast.error('Error obteniendo datos de cotización');
    }
  } catch (err) {
    toast.error(err);
  }
  finally {
    dispatch(toggleModal('search'));
  }
};

// Expenses

export const fetchListExpensesType = (page = 1, search = '', pagination = false) => async (dispatch) => {
  const expensesApi = new ExpensesApi();
  dispatch(loading('update'));
  try {
    const response = await expensesApi.getExpensesType(page, search);
    const { success, result, pages } = response;
    if (process.env.NODE_ENV === 'development') {
      console.log('===================fetchListExpensesType=================');
      console.log({ result });
      console.log('====================================');
    }
    if (success) {
      dispatch(AddExpensesType({ result, pages }, pagination));
    }
    else {
      console.error('Error obteniendo datos de tipos de gastos');
      // toast.error('Error obteniendo datos de cotización');
    }
  } catch (err) {
    toast.error(err);
  }
  finally {
    dispatch(loaded('update'));
  }
};


export const fetchSelectedExpenseType = expenseType => async (dispatch) => {
  try {
    if (expenseType) {
      dispatch(AddSelectExpenseType(expenseType));
    }
    else {
      console.error('Error obteniendo datos de tipo de gasto');
      // toast.error('Error obteniendo datos de cotización');
    }
  } catch (err) {
    toast.error(err);
  }
  finally {
    dispatch(toggleModal('search'));
  }
};


// Almacen

export const fetchListAlmacen = (page = 1, search = '', pagination = false) => async (dispatch) => {

  const expensesApi = new ExpensesApi();
  dispatch(loading('update'));
  try {
    const response = await expensesApi.getAlmacen(page, search);
    const { success, result, pages } = response;
    if (process.env.NODE_ENV === 'development') {
      console.log('===================fetchListExpensesType=================');
      console.log({ result });
      console.log('====================================');
    }
    if (success) {
      dispatch(AddAlmacenes({ result, pages }, pagination));
    }
    else {
      console.error('Error obteniendo datos de tipos de gastos');
      // toast.error('Error obteniendo datos de cotización');
    }
  } catch (err) {
    toast.error(err);
  }
  finally {
    dispatch(loaded('update'));
  }
};

export const fetchSelectedAlmacen = almacen => async (dispatch) => {
  try {
    if (almacen) {
      dispatch(AddAlmacen(almacen));
    }
    else {
      console.error('Error obteniendo datos de tipo de gasto');
      // toast.error('Error obteniendo datos de cotización');
    }
  } catch (err) {
    toast.error(err);
  }
  finally {
    dispatch(toggleModal('search'));
  }
};

// Documents
export function downloadFile(url, name) {
  return async (dispatch) => {
    await dispatch(loading('order'));
    try {
      const downloadApi = new DownloadAPI();
      await downloadApi.download(url, name);
    } catch (err) {
      console.log({ downloadFile: err });
    } finally {
      await dispatch(loaded('order'));
    }
  };
}

export function getDocumentsByExpense(page = 1, search = '', pagination = false) {
  const documentsAPI = new DocumentsAPI();
  return async (dispatch, getState) => {
    await dispatch(loading('order'));
    try {
      const { gastos } = getState();
      const { gastoSeleccionado } = gastos;
      if (Object.keys(gastoSeleccionado).length > 0) {
        const { crmid } = gastoSeleccionado;
        const response = await documentsAPI.getDocuments(crmid, page, search);
        const {
          success, result, pages, count, message,
        } = response;
        if (success) {
          dispatch(GetExpenseDocuments({ result, pages, count }, pagination));
        } else {
          /*  toast.warn(response.message.warning); */
          message.error ? toast.error(message.error) : '';
          message.warning ? toast.warn(message.warning) : '';
        }
      } else {
        toast.info('Seleccione un gasto primero');
      }
    } catch (err) {
      console.log({ getFilesByOrderErr: err });
    } finally {
      await dispatch(loaded('order'));
    }
  };
}

export function deleteDocument(crmid, label) {
  return async (dispatch) => {
    await dispatch(loading('order'));
    try {
      const documentsAPI = new DocumentsAPI();
      const response = await documentsAPI.deleteDocument(crmid);
      if (response.success) {
        await dispatch(getDocumentsByExpense(1, ''));
        await dispatch(getDocumentsByTask(1, ''));
        await toast.success(`Archivo: ${label} eliminado`);
      } else {
        const { message } = response;
        /* toast.warning(response.message.error || response.message.warning); */
        message.error ? toast.error(message.error) : '';
        message.warning ? toast.warn(message.warning) : '';
      }
    } catch (err) {
      console.log({ deleteFile: err });
    } finally {
      await dispatch(loaded('order'));
    }
  };
}

export function uploadFilesToDocument(files, id = '') {
  const documentsAPI = new DocumentsAPI();
  return async (dispatch, getState) => {
    let res = null;
    dispatch(loading('order'));
    try {
      if (id !== '') {
        res = await documentsAPI.uploadFileToDocuments(id, files);
      } else {
        const { gastos } = getState();
        const { gastoSeleccionado: { crmid } } = gastos;
        res = await documentsAPI.uploadFileToDocuments(crmid, files);
      }
      const { success, message } = res;
      if (success) {
        toast.success(res.message.success);
        await dispatch(getDocumentsByExpense(1, ''));
      } else {
        /* toast.warn(res.message.error); */
        message.error ? toast.error(message.error) : '';
        message.warning ? toast.warn(message.warning) : '';
      }
    } catch (err) {
      console.log('uploadFiles err: ', err);
    } finally {
      dispatch(loaded('order'));
    }
  };
}
