/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';

import DiscountModal from './_components/DiscountModal';
// import DiscountDialog from './components/DiscountDialog';

const Discount = ({
  isOpen,
  onSave,
  onClose,
  products,
  isLoading,
  totalToPay,
  dispatchSetDiscount,
}) => (
  <>
    <DiscountModal
      onSave={onSave}
      isOpen={isOpen}
      toggle={onClose}
      products={products}
      isLoading={isLoading}
      totalToPay={totalToPay}
      dispatchSetDiscount={dispatchSetDiscount}
    />
  </>
);

Discount.propTypes = {
  onSave: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  products: PropTypes.array.isRequired,
  totalToPay: PropTypes.number.isRequired,
  dispatchSetDiscount: PropTypes.func.isRequired,
};

Discount.defaultProps = {};

export default Discount;
