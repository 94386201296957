/* eslint-disable no-prototype-builtins */
/* eslint-disable react/prop-types */
import {
  AppBar, IconButton, Toolbar, Typography,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import React from 'react';
import { isAndroid, isMobile, isTablet } from 'react-device-detect';
import { useSelector } from 'react-redux';

const OrderDetailAppbar = ({ closeCart }) => {
  const cart = useSelector(state => state.cart);
  const { detailTabActive } = cart;
  const tabName = [
    'Archivos',
    'Direcciones',
    'Facturas',
    'Flujo Efectivo',
    'Mov. almacen',
    'Guías',
    'Camp. personalizados',
    'Comentarios',
  ];
  return (
    <AppBar position="static" elevation={0}>
      <Toolbar style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h6" color="inherit" noWrap>
          {`${detailTabActive !== -1 ? tabName[detailTabActive] : 'Detalles '} ` }
        </Typography>
        <div>
          {/* <IconButton
          color="inherit"
          aria-label="Open drawer"
          onClick={() => close()}
        >
          <ArrowLeft />
        </IconButton> */}
          <IconButton
            color="inherit"
            aria-label="Open drawer"
            onClick={() => closeCart()}
          >
            <Close />
          </IconButton>

        </div>
      </Toolbar>
    </AppBar>
  ); };

export default OrderDetailAppbar;
