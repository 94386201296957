/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable react/no-unknown-property */
/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Button, CircularProgress, Fab } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import appModules from '../../constant/appModules';
import UserAPI from '../../api/UserAPI';

const OrderFields = ({
  open, orderId, handleCloseDialog, module, isModal = false,
}) => {
  const [loading, setLoading] = React.useState(false);
  const [fields, setFields] = React.useState(null);
  const userAPI = new UserAPI();
  const customer = useSelector(state => state.customer);
  const { selectedCustomer } = customer;
  let timerId;

  const inputRef = useRef(null);

  const handleClose = () => {
    if (isModal) {
      handleCloseDialog();
      setFields(null);
    }
  };

  const submitForm = async (e) => {
    e.preventDefault();
    setLoading(true);
    const newValues = {};
    fields.map((field) => {
      newValues[field.name] = field.value;
    });
    const crmId = module === appModules.Accounts ? selectedCustomer.crmid : orderId;
    await userAPI.setCustomFields(newValues, module, crmId);
    setLoading(false);
    handleClose();
  };

  const handleInputChange = (event, index) => {
    localStorage.setItem('inputIndex', index);
    inputRef.current = event.target;
    if (event.target.type === 'checkbox') {
      const { target } = event;
      const value = target.type === 'checkbox' ? (target.checked ? '1' : '0') : target.value;
      const updatedFields = [...fields];
      updatedFields[index].value = value;
      setFields([...updatedFields]);
      target.focus();
      return;
    }
    event.persist();
    clearTimeout(timerId);
    timerId = setTimeout(() => {
      const { target } = event;
      const value = target.type === 'checkbox' ? (target.checked ? '1' : '0') : target.value;
      const updatedFields = [...fields];
      updatedFields[index].value = value;
      setFields([...updatedFields]);
      target.focus();
    }, 1000);
  };

  const getFields = async () => {
    if (module !== '') {
      setLoading(true);
      let response = null;
      if (module === appModules.Accounts) {
        response = await userAPI.getCustomFields(
          module,
          selectedCustomer.crmid,
        );
      } else {
        response = await userAPI.getCustomFields(module, orderId);
      }
      const { success, result } = response;
      if (success) {
        setFields(Object.values(result));
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    if (open) {
      getFields();
    }
  }, [open]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [fields]);

  useEffect(() => () => {
    localStorage.removeItem('inputIndex');
  }, []);

  return (
    <div
      className="d-flex justify-content-center align-items-start"
      style={{ width: '100%', height: '100%', backgroundColor: isModal ? '#efeff4' : 'white' }}
    >
      <form
        className="mt-5"
        onSubmit={e => submitForm(e)}
        style={{ width: '80%' }}
      >
        <div style={{ maxWidth: 900 }}>
          <div className="row">
            {fields
              && fields.map((field, index) => (
                <div key={`${Math.random()}_${index}`} className="mt-2 col-6">
                  {field.type !== 'checkbox' && (
                    <label htmlFor="" className="custom-label">
                      {field.label}
                    </label>
                  )}
                  {field.type === 'picklist' && (
                    <select
                      key={`select${index}`}
                      name={field.name}
                      className="custom-input custom-input-sm"
                      onChange={e => handleInputChange(e, index)}
                      style={{ backgroundColor: !isModal ? '#efeff4' : 'white' }}
                    >
                      {field.picklistvalues
                        && Object.values(field.picklistvalues).map(
                          (option, idx) => (
                            <option
                              key={`option${idx}`}
                              defaultValue={option}
                              selected={option === field.value}
                            >
                              {option}
                            </option>
                          ),
                        )}
                    </select>
                  )}
                  {field.type === 'textarea' && (
                    <textarea
                      className="custom-input custom-input-textarea py-2"
                      type={field.type}
                      defaultValue={field.value || field.defaultvalue}
                      name={field.name}
                      required={field.required}
                      cols="30"
                      rows="3"
                      onChange={e => handleInputChange(e, index)}
                      style={{ backgroundColor: !isModal ? '#efeff4' : 'white' }}
                      key={`textarea${index}`}
                    />
                  )}
                  {field.type === 'checkbox' && (
                    <div className="d-flex align-items-center">
                      <input
                        checked={field.value === '1'}
                        className=""
                        type={field.type}
                        value={field.value}
                        name={field.name}
                        required={field.required}
                        onClick={e => handleInputChange(e, index)}
                        style={{ backgroundColor: !isModal ? '#efeff4' : 'white' }}
                        key={`checkbox${index}`}
                      />
                      <p className="ml-2 mt-0">{field.label}</p>
                    </div>
                  )}
                  {field.type !== 'picklist'
                    && field.type !== 'textarea'
                    && field.type !== 'checkbox' && (
                      <input
                        className="custom-input custom-input-sm"
                        type={field.type}
                        defaultValue={field.value || field.defaultvalue}
                        name={field.name}
                        required={field.required}
                        onChange={e => handleInputChange(e, index)}
                        style={{ backgroundColor: !isModal ? '#efeff4' : 'white' }}
                        autoFocus={index === Number(localStorage.getItem('inputIndex'))}
                        key={`input${index}`}
                      />
                  )}
                </div>
              ))}
            {fields && fields.length === 0 && (
              <p>No hay campos personalizados</p>
            )}
          </div>
        </div>
        {
            !isModal ? (
              <div style={{
                padding: 20,
                display: 'flex',
                justifyContent: 'flex-end',
              }}
              >
                {loading && <CircularProgress />}
                {!loading && (
                  <Button variant="contained" type="submit">
                    <>
                      <span> Guardar</span>
                    </>
                  </Button>
                )}
              </div>
            ) : (
              <Fab
                color="primary"
                type="submit"
                style={{ position: 'absolute', right: 20, bottom: 20 }}
              >
                {loading && <CircularProgress style={{ color: 'white' }} />}
                {!loading && <SaveIcon />}
              </Fab>
            )
        }
      </form>
    </div>
  );
};

export default OrderFields;
