/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Collapse } from '@material-ui/core';
import SearchCustomer from '../../../../Customer/components/SearchCustomer';

const AmountCustomer = ({
  billSpan,
  customer,
  cart,
  handleMakeBill,
  dispatchEditOrder,
  makeBill,
  posCheckoutBill,
}) => {
  const setCheck = (billState) => {
    handleMakeBill(billState);
    if (!billState) {
      const el = document.querySelector('#select_customer_searchbar');
      // @ts-ignore
      if (el) el.focus();
    }
  };

  const single = {
    background: '#626c75',
    borderRadius: 5,
    color: 'white',
    display: 'flex',
    fontSize: '1.2em',
    height: '100%',
    maxHeight: '6vh',
    padding: 5,
  };

  const control = {
    backgroundColor: '#f5f9fa',
    border: '0.15em solid #b7bfc6',
    borderRadius: '0.6em',
    minHeight: '4.8vh',
  };

  return (
    <>
      <div className="amount_customer">
        <SearchCustomer
          single={single}
          control={control}
          orderSelected={cart.orderSelected}
          dispatchEditOrder={dispatchEditOrder}
        />
        {posCheckoutBill === 'Preguntar' && customer.selectedCustomer.account_no !== 'ACC1' && (
          <div
            style={{ width: '30%' }}
            className="d-flex justify-content-end"
          >
            <span
              className={!makeBill ? 'spanBill' : 'spanBillSelected'}
            >
              {billSpan}
            </span>
            <Checkbox
              id="check_add_bill"
              tabIndex={0}
              checked={makeBill}
              onClick={() => setCheck(!makeBill)}
              onKeyDown={(e) => {
                e.preventDefault();
                const { keyCode } = e;
                if (keyCode === 13) {
                  setCheck(!makeBill);
                } else if (keyCode === 9) {
                  const el = document.querySelector('#payable_dollar');
                  // @ts-ignore
                  if (el) el.focus();
                }
              }}
            />
          </div>
        )}
      </div>
      <Collapse className="w-100" in={customer.selectedCustomer.account_no === 'ACC1'}>
        <div className="w-100 font-size-10">
          Público en general no factura
        </div>
      </Collapse>
    </>
  );
};

AmountCustomer.propTypes = {
  billSpan: PropTypes.string,
  customer: PropTypes.object.isRequired,
  cart: PropTypes.object.isRequired,
  handleMakeBill: PropTypes.func.isRequired,
  dispatchEditOrder: PropTypes.func.isRequired,
  makeBill: PropTypes.bool.isRequired,
  posCheckoutBill: PropTypes.string.isRequired,
};
AmountCustomer.defaultProps = {
  billSpan: 'Facturar',
};

export default AmountCustomer;
