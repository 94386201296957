/* eslint-disable import/no-cycle */
import { connect } from 'react-redux';
import { closeModal } from '../../redux/actions/modalActions';
import {
  deleteFile,
  downloadFile,
  getFilesByOrder,
  uploadFilesToOrder,
} from '../../redux/actions/orderActions';
// Expenses
import { getDocumentsByExpense, deleteDocument, uploadFilesToDocument } from '../../redux/actions/expensesActions';

/* Main Component */
import File from './constructor';
import { getDocumentsByTask, uploadFilesToTask } from '../../redux/actions/taskActions';
/* Redux Functiosn */
const mapStateToProps = (state) => {
  const { order, loads, gastos, tasks } = state;
  const { files } = order;
  const { documentos } = gastos;
  const { documentos: documents } = tasks;
  return ({
    loading: Boolean(loads.orderIsLoading),
    modals: state.modals,
    files,
    documentos,
    documents,
  });
};

const mapDispatchToProps = dispatch => ({
  dispatchCloseDiscountModal: () => dispatch(closeModal('file')),
  dispatchUploadFilesToOrder: files => dispatch(uploadFilesToOrder(files)),
  dispatchUploadFilesToDocument: files => dispatch(uploadFilesToDocument(files)),
  dispatchUploadFilesToTask: files => dispatch(uploadFilesToTask(files)),
  dispatchGetFilesByOrder: (page, search) => dispatch(getFilesByOrder(page, search)),
  dispatchDownloadFile: (url, name) => dispatch(downloadFile(url, name)),
  dispatchDeleteFile: (crmid, label) => dispatch(deleteFile(crmid, label)),
  dispatchGetDocumentsByExpense: (page, search) => dispatch(getDocumentsByExpense(page, search)),
  dispatchDeleteDocuments: (crmid, label) => dispatch(deleteDocument(crmid, label)),
  dispatchGetDocumentsByTask: (page, search) => dispatch(getDocumentsByTask(page, search)),
});

export default connect(mapStateToProps, mapDispatchToProps)(File);
