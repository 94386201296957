/* eslint-disable no-unused-expressions */
/* eslint-disable no-lonely-if */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable consistent-return */
/* eslint-disable eqeqeq */
/* eslint-disable import/no-cycle */
/* eslint-disable no-return-assign */
/* eslint-disable no-param-reassign */
/* eslint-disable no-case-declarations */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
/* Device */
import { isAndroid, isMobile, isTablet } from 'react-device-detect';

/* Components */
import AmountPayable from './components/AmountPayable';
import AmountCustomer from './components/AmountCustomer';
import AmountRegistry from './components/AmountRegistry';
import AmountTendered from './components/AmountTendered';
import AmountCheckOut from './components/AmountCheckOut';
import MethodCheckOut from './components/MethodCheckOut';
import CartCurrencyMessage from '../../../Cart/components/CartCurrencyMessage';
import BankCheckOut from './components/BankCheckOut';
import ClientAPI from '../../../../api/ClientAPI';

const CheckOutConstructor = ({
  cart,
  customer,
  defaultOrderStatus,
  dispatchEditOrder,
  handleMakeBill,
  isLoading,
  makeBill,
  onSave,
  orderStatuses,
  paymentTypes,
  posCheckoutBill,
  totalToPay,
}) => {
  const customerInStore = useSelector(state => state.customer);
  const authUser = useSelector(state => state.authUser);
  const {
    user: { currencies, metodos_pagos: metodoPagos, config },
  } = authUser;
  const { selectedCustomer } = customerInStore;

  const [rewardsPoints, setRewardsPoints] = useState(null);
  const [rewardsMethod, setRewardsMethod] = useState(null);

  // Order Selected
  const { orderSelected } = cart;
  const isOrder = Boolean(Object.keys(orderSelected).length > 0);

  /* new */
  const [payIndex, setPayIndex] = React.useState(0);

  /* Payable State */
  const inputEl = React.useRef(null);
  const [charge, setCharge] = React.useState(0);
  const [payable, setPayable] = React.useState('');

  /* Registry State */
  const [payments, setPayments] = React.useState([]);

  /* Registry Tendered */
  const [paidOut, setPaidOut] = React.useState(0);

  /* Registry Tendered */
  const [statusOrder, setStatusOrder] = React.useState(defaultOrderStatus);
  const [statusIndex, SetStatusIndex] = React.useState(
    orderStatuses.findIndex(x => x.id === statusOrder),
  );

  /* Payment selected */
  const [payment, setPayment] = React.useState(null);

  /* Bank selected */
  const [bank, setBank] = React.useState(null);

  function isLetter(c) {
    return c.toLowerCase() !== c.toUpperCase();
  }

  const setPayableValue = (val) => {
    let value = val;
    if (!isLetter(value) && !Number.isNaN(value)) {
      value = value.replace(/[$_'_,]/g, '');
      value = Number(value).toString();
      let newValue = Number.parseFloat(value);
      if (value === '.') {
        newValue = 0.0;
      }
      if (value === '') {
        // @ts-ignore
        setPayable(newValue);
        setCharge(newValue);
        // @ts-ignore
        newValue = '';
      }
      // @ts-ignore
      setPayable(newValue);
      setCharge(newValue);
    }
  };

  const changePayable = () => {
    if (inputEl) {
      const { value } = inputEl.current;

      if (bank.tcyb_tipo === 'Puntos Recompensa' && payment.method === 'LBL_PUNTOS_RECOMPENSA') {
        const parseAmount = parseFloat(value.replace('$', ''));
        if (parseAmount > rewardsPoints.puntos_saldo) {
          toast.error('No tienes puntos suficientes para cubrir ese monto');
          inputEl.current.value = rewardsPoints.puntos_saldo;
          return;
        }
      }
      setPayableValue(value);
    }
  };

  /* General Functions */

  const resetWorkFlowToCheckout = () => {
    setCharge(0);
    setPayable('');
    if (inputEl) {
      inputEl.current.value = '';
      // @ts-ignore
      inputEl.current.focus();
    }
  };

  /* Payable Functions */
  const onBlurCharge = () => {
    const newCharge = Number.parseFloat(payable);
    // @ts-ignore
    setPayable(newCharge);
  };

  const formatPaymentTypes = () => {
    let arr = [];
    paymentTypes.forEach((type, index) => {
      const { body } = type;
      if (body) {
        const cbSelected = body.filter(account => account.select)[0];
        const cb = { ...cbSelected, id: type.method, index };
        arr = [...arr, cb];
      }
    });
    return arr;
  };

  const handleSetPayIndex = (index) => {
    setPayIndex(index);
  };

  const getCurrencyConvertRate = () => {
    try {
      let convertRate = 1;
      let activeCurrencyId;
      if (isOrder) {
        const { order } = orderSelected;
        const orderCUrrencyId = order.currency_id.split('x').pop();
        activeCurrencyId = orderCUrrencyId;
      } else {
        activeCurrencyId = localStorage.getItem('currencyId');
      }
      const activeCurrency = currencies.find(c => c.id === activeCurrencyId);

      // If not currency_id exist
      if (!bank?.currency_id || bank?.currency_id === activeCurrencyId) {
        return convertRate;
      }

      const paymentMethodSelectedCurrency = currencies.find(
        c => c.id === bank?.currency_id,
      );

      if (activeCurrency && paymentMethodSelectedCurrency) {
        convertRate = Number.parseFloat(paymentMethodSelectedCurrency.conversion_rate)
          / Number.parseFloat(activeCurrency.conversion_rate);
      }

      return convertRate;
    } catch (error) {
      toast.error(
        'Error calculando la conversion de moneda, asegurese de haber seleccionado el metodo de pago y un banco o caja',
      );
    }
  };

  const convertMoney = amount => amount * getCurrencyConvertRate();

  const getConvertRate = (fromCurrencyId, toCurrencyId) => {
    try {
      // Get actual currency
      const fromCurrency = currencies.find(p => p.id == fromCurrencyId);
      // Get currency to convert
      const toCurrency = currencies.find(p => p.id == toCurrencyId);

      return (
        Number.parseFloat(fromCurrency.conversion_rate)
        / Number.parseFloat(toCurrency?.conversion_rate || 1)
      );
    } catch (error) {
      toast.error('Error calculando la conversion de moneda');
      // eslint-disable-next-line no-console
      console.log(error);
      // toast.error('2- Error calculando la conversion de moneda');
    }
  };

  // Calculate total of payments in only one currency, in this case the currency of selected oay method
  const getPaymentsTotalInOneCurrency = () => {
    try {
      let paymentSavedTotal = 0;
      if (payments.length) {
        // eslint-disable-next-line no-restricted-syntax
        for (const pay of payments) {
          // Find select pay method currency

          const conversionRate = getConvertRate(
            pay.currency_id,
            bank?.currency_id,
          );
          paymentSavedTotal += pay.monto / conversionRate;
        }
      }
      return paymentSavedTotal;
    } catch (error) {
      toast.warn('Ocurrio un error intentando convertir la moneda');

      return null;
    }
  };

  const getRewardPoints = async () => {
    await setRewardsPoints(null);
    await setRewardsMethod(null);

    const clientApi = new ClientAPI();
    const response = await clientApi.getRewardPoints(selectedCustomer.crmid);
    if (response.success && response.result.puntos_asignados > 0) {
      const rewardMethod = {
        method: 'LBL_PUNTOS_RECOMPENSA',
        key: metodoPagos.length,
        body: [
          {
            crmid: selectedCustomer.crmid, // Selected Customer crmid
            currency_code: response.currency_code,
            currency_id: response.currency_id,
            entity_label: 'Puntos Recompensa',
            referencia: 'Puntos Recompensa',
            tcyb_tipo: 'Puntos Recompensa',
          },
        ],
      };
      setRewardsMethod(rewardMethod);
      setRewardsPoints(response.result);
    }
  };

  React.useEffect(() => {
    if (selectedCustomer) {
      setPayments([]);
      getRewardPoints();
    }
  }, [selectedCustomer]);

  /* Once mounted */
  React.useEffect(() => {
    const convertTotalToPay = convertMoney(totalToPay);
    // @ts-ignore
    const newCharge = Number.parseFloat(convertTotalToPay);
    setCharge(newCharge);
    setPayable(convertTotalToPay);
    const metaInput = document.querySelector('#payable_dollar');
    if (metaInput) {
      if (newCharge) {
        // @ts-ignore
        metaInput.value = newCharge;
        setTimeout(async () => {
          // @ts-ignore
          // metaInput.select();
          setPayable(convertTotalToPay);
          await setCharge(newCharge);
        }, 100);
      } else {
        // @ts-ignore
        // metaInput.focus();
      }
    }
  }, [totalToPay]);

  /* Once payIndex change */
  React.useEffect(() => {
    // Calculate add payments
    const paymentSavedTotal = getPaymentsTotalInOneCurrency();
    const totalToPayConvert = convertMoney(totalToPay);
    const convertTotalToPay = totalToPayConvert - paymentSavedTotal;
    // @ts-ignore
    const newCharge = Number.parseFloat(convertTotalToPay);
    setCharge(newCharge);
    setPayable(convertTotalToPay);
    setPaidOut(paymentSavedTotal);
    const metaInput = document.querySelector('#payable_dollar');
    // debugger;
    if (metaInput) {
      if (newCharge) {
        // @ts-ignore
        metaInput.value = newCharge;
        setTimeout(async () => {
          if (
            bank
            && bank.tcyb_tipo === 'Puntos Recompensa'
            && rewardsPoints
            // && rewardsPoints.puntos_saldo
          ) {
            // debugger;
            inputEl.current.value = `${
              rewardsPoints.puntos_saldo > newCharge
                ? newCharge
                : rewardsPoints.puntos_saldo
            }`;
            setPayableValue(inputEl.current.value);
            localStorage.getItem('printMode') === 'true' ? null : metaInput.select();
            setPayable(rewardsPoints.puntos_saldo);
            // await setCharge(rewardsPoints.puntos_saldo);
          } else {
            // @ts-ignore
            localStorage.getItem('printMode') === 'true' ? null : metaInput.select();
            setPayable(convertTotalToPay);
            await setCharge(newCharge);
          }
        }, 100);
      } else {
        // @ts-ignore
        if (localStorage.getItem('printMode') === 'false' || !localStorage.getItem('printMode')) {
          metaInput.focus();
        }
      }
    }
  }, [bank]);

  /* Method Functions */
  const newPaymentTypes = formatPaymentTypes();

  /* Registry Functions */

  const addPayment = (inputValue = null) => {
    try {
      // @ts-ignore
      const newMoney = inputValue
        ? Number.parseFloat(inputValue)
        : Number.parseFloat(charge);
      if (
        payment.method === 'LBL_PUNTOS_RECOMPENSA'
        && newMoney > rewardsPoints.puntos_saldo
      ) {
        toast.error('No tienes puntos suficientes para cubrir ese monto');
        return;
      }

      if (
        payment.method === 'LBL_PUNTOS_RECOMPENSA'
        && newMoney <= rewardsPoints.puntos_saldo
      ) {
        rewardsPoints.puntos_saldo -= newMoney;
      }

      let newPayment = {};
      let array = [];
      if (payment && newMoney > 0 && bank) {
        const exist = payments.find(
          x => x.metodo === payment.method && x.referencia === bank?.referencia,
        );
        if (exist && payment.method === 'Efectivo') {
          const paymentsMap = payments.map((x) => {
            const item = { ...x };
            if (
              item.metodo === exist.metodo
              && item.referencia === exist.referencia
            ) {
              item.monto += newMoney;
            }
            return item;
          });
          array = [...paymentsMap];
        } else {
          newPayment = {
            monto: newMoney,
            // @ts-ignore
            metodo: payment.method, // Metodo Ex: Efectivo, tarjeta credito
            // @ts-ignore
            tcybid: bank?.crmid, // Id de banco o caja
            referencia: bank?.referencia, // Banco ex: Santander, HSBC, Caja chica
            currency_id: bank?.currency_id, // Moneda
          };
          array = [...payments, newPayment];
        }
        if (newPaymentTypes.length > 0) {
          setPayments(array);
          resetWorkFlowToCheckout();
        }
      } else {
        toast.warn(
          'Asegurese de haber seleccionado un metodo de pago, una caja o banco y de haber agregado el monto que quiere adicionar',
        );
      }
    } catch (error) {
      toast.error('Error intentando agregar el pago');
    }
  };

  const removePayment = (index) => {
    const actualPayments = [...payments];
    if (payments[index].metodo === 'LBL_PUNTOS_RECOMPENSA') {
      rewardsPoints.puntos_saldo += payments[index].monto;
    }
    actualPayments.splice(index, 1);
    setPayments(actualPayments);
  };

  /* Tendered Functions */
  React.useEffect(() => {
    const totalPayOut = getPaymentsTotalInOneCurrency();
    setPaidOut(totalPayOut);
  }, [payments]);

  /* Checkout Function (Submit) */

  const beforeSubmit = arr => new Promise((resolve, reject) => {
    let array = [...arr];
    const needFix = Boolean(paidOut + charge > convertMoney(totalToPay));
    const existEfectivo = Boolean(array.some(x => x.metodo === 'Efectivo'));
    if (needFix) {
      const balance = Math.abs(
        convertMoney(totalToPay) - (paidOut + charge),
      ).toFixed(2);
      if (existEfectivo) {
        array = [];
        array = arr.map((x) => {
          if (x.metodo === 'Efectivo') {
            if (x.monto >= balance) {
              x.monto = Math.abs(x.monto - balance);
            } else {
              toast.warn(
                'Atención: El suma del cambio supera a la cantidad en efectivo, revise las partidad agregadas',
              );
              reject();
            }
            // @ts-ignore
          }
          return x;
        });
        resolve({ payments: array, currentOrderStatus: statusOrder });
      } else {
        toast.info(
          'Atención: la suma de los métodos de pago elegidos debe ser exacta',
        );
        reject();
      }
    }
    resolve({ payments: array, currentOrderStatus: statusOrder });
  });

  const onSubmit = async () => {
    if (!isLoading) {
      if (charge > 0) {
        if (charge > totalToPay) {
          localStorage.setItem('recibido', charge);
          localStorage.setItem('cambio', charge - totalToPay);
        } else {
          localStorage.setItem('cambio', 0);
        }
        // @ts-ignore
        const newMoney = Number.parseFloat(charge);
        let newPayment = {};
        let array = [];
        if (payment && newMoney > 0 && bank) {
          const exist = payments.find(
            x => x.metodo === payment.method && x.referencia === bank?.referencia,
          );
          if (exist) {
            const paymentsMap = payments.map((x) => {
              const item = { ...x };
              if (
                item.metodo === exist.metodo
                && item.referencia === exist.referencia
              ) {
                item.monto += newMoney;
              }
              return item;
            });
            array = [...paymentsMap];
          } else {
            if (
              payment.method === 'LBL_PUNTOS_RECOMPENSA'
              && newMoney > rewardsPoints?.puntos_saldo
            ) {
              toast.error('No cuenta con puntos suficientes');
              return;
            }
            newPayment = {
              monto: newMoney,
              // @ts-ignore
              metodo: payment.method,
              // @ts-ignore
              tcybid: bank?.crmid,
              referencia: bank?.referencia,
            };
            array = [...payments, newPayment];
          }
          if (newPaymentTypes.length > 0) {
            beforeSubmit(array).then(response => onSave(response));
          }
        } else {
          toast.warn('Error intentando cobrar la orden');
        }
      } else {
        let montoTotal = 0;
        payments.forEach((pay) => {
          montoTotal += pay.monto;
        });
        if (montoTotal === 0) {
          toast.error('Debes ingresar un monto para cobrar la orden');
          return;
        }
        if (montoTotal > totalToPay) {
          localStorage.setItem('recibido', montoTotal);
          localStorage.setItem('cambio', montoTotal - totalToPay);
        } else {
          localStorage.setItem('cambio', 0);
        }
        beforeSubmit(payments).then(response => onSave(response));
      }
    } else {
      toast.warn(
        'Ya estamos procesando un cobro, favor de esperar a que termine',
      );
    }
  };

  const setStatusByIndex = (index) => {
    const newStatus = orderStatuses[index].id;
    setStatusOrder(newStatus);
    SetStatusIndex(index);
  };

  const selectPaymentMethod = (pay) => {
    setPayment(pay);
    try {
      if (pay.body && pay.body.length === 1) {
        setBank(pay.body[0]);
      } else {
        setBank(null);
      }
    } catch (error) {
      toast.error('Error seleccionando el banco o caja');
    }
  };

  const selectBank = async (banc) => {
    await setBank(null);
    await setBank(banc);
  };

  /* CSS */
  const mainCont = isMobile
    ? 'w-100 d-flex flex-column px-2 py-1 minHeightMobile'
    : 'w-100 d-flex flex-column';
  const inputMoney = paidOut + charge;
  return (
    <div className={mainCont}>
      <AmountCustomer
        cart={cart}
        customer={customer}
        handleMakeBill={handleMakeBill}
        makeBill={makeBill}
        posCheckoutBill={posCheckoutBill}
        dispatchEditOrder={dispatchEditOrder}
      />
      {bank && <CartCurrencyMessage bank={bank} />}
      <MethodCheckOut
        payment={payment}
        selectPayment={pay => selectPaymentMethod(pay)}
        rewardsMethod={rewardsMethod}
      />
      {payment && (
        <BankCheckOut
          payment={payment}
          bank={bank}
          setBank={banc => selectBank(banc)}
          setPayIndex={handleSetPayIndex}
          rewardsPoints={rewardsPoints}
        />
      )}

      {payment && (bank || payments.length !== 0) && (
        <>
          <AmountPayable
            charge={charge}
            inputEl={inputEl}
            paidOut={paidOut}
            onSubmit={onSubmit}
            payIndex={payIndex}
            isLoading={isLoading}
            addPayment={addPayment}
            onBlurCharge={onBlurCharge}
            changePayable={changePayable}
            setPayIndex={handleSetPayIndex}
            newPaymentTypes={newPaymentTypes}
            totalToPay={Math.abs(convertMoney(totalToPay) - inputMoney)}
          />
          <AmountRegistry
            charge={charge}
            payments={payments}
            onSubmit={onSubmit}
            totalToPay={convertMoney(totalToPay)}
            addPayment={addPayment}
            removePayment={removePayment}
          />
          <AmountTendered
            charge={charge}
            paidOut={paidOut}
            totalToPay={convertMoney(totalToPay)}
          />
          {
          config.pos_permite_cobro_parcial
          && config.pos_permite_cobro_parcial === '1' ? (
            <AmountCheckOut
              onSubmit={onSubmit}
              statusIndex={statusIndex}
              statusOrder={statusOrder}
              orderStatuses={orderStatuses}
              enableSubmit={Boolean(charge + paidOut > 0)}
              setStatusOrder={setStatusOrder}
              SetStatusIndex={setStatusByIndex}
            />
            )
            : config.pos_permite_cobro_parcial
          && config.pos_permite_cobro_parcial === '0'
          && Number(totalToPay.toFixed(2)) < Number((paidOut + charge).toFixed(2)) ? ( // Add toFixed(2) to avoid arithmetic floating point error
            <AmountCheckOut
              onSubmit={onSubmit}
              statusIndex={statusIndex}
              statusOrder={statusOrder}
              orderStatuses={orderStatuses}
              enableSubmit={Boolean(charge + paidOut > 0)}
              setStatusOrder={setStatusOrder}
              SetStatusIndex={setStatusByIndex}
            />
              )
              : Number(Math.abs(Number(totalToPay.toFixed(2)) - Number((paidOut + charge).toFixed(2)))) > 0 ? null : (
                <AmountCheckOut
                  onSubmit={onSubmit}
                  statusIndex={statusIndex}
                  statusOrder={statusOrder}
                  orderStatuses={orderStatuses}
                  enableSubmit={Boolean(charge + paidOut > 0)}
                  setStatusOrder={setStatusOrder}
                  SetStatusIndex={setStatusByIndex}
                />
              )}

          {/* Use this to debug prices
          <div style={{display: 'flex', flexDirection: 'column'}}>
            <span>
              totalToPay:
              {Number(totalToPay.toFixed(2))}
            </span>
            <span>
              paidOut:
              {paidOut}
            </span>
            <span>
              charge:
              {charge}
            </span>
            <span>
              paidOut + charge:
              {Number((paidOut + charge).toFixed(2))}
            </span>
          </div> */}
        </>
      )}
    </div>
  );
};

CheckOutConstructor.propTypes = {
  cart: PropTypes.object.isRequired,
  customer: PropTypes.object.isRequired,
  defaultOrderStatus: PropTypes.string.isRequired,
  handleMakeBill: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  makeBill: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
  dispatchEditOrder: PropTypes.func.isRequired,
  orderStatuses: PropTypes.array.isRequired,
  paymentTypes: PropTypes.array.isRequired,
  posCheckoutBill: PropTypes.string.isRequired,
  totalToPay: PropTypes.number.isRequired,
};

CheckOutConstructor.defaultProps = {
  isLoading: false,
};

export default CheckOutConstructor;
