/* eslint-disable import/no-cycle */
import { connect } from 'react-redux';
import { openModal } from '../../../../../redux/actions/modalActions';
import { printTicketFactura } from '../../../../../redux/actions/TimbrarActions';
/* Component */
import PrintControls from './PrintControls';

const mapStateToProps = state => ({
  tabs: state.tabs,
  cart: state.cart,
  loads: state.loads,
  authUser: state.authUser,
});

const mapDispatchToProps = dispatch => ({
  /* Modal */
  dispatchOpenModal: modal => dispatch(openModal(modal)),
  dispatchOpenBill: async (bill) => {
    await dispatch(printTicketFactura(bill));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PrintControls);
