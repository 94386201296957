/* eslint-disable no-restricted-properties */
import lodash from 'lodash';

export const numberFormat = (value, fixed = 2) => {
  /**
   * Number('3,336.21') = NaN asi se estaba convirtiendo los valore a numeros y funciona bien cuando solo tiene .
   * ej: 335.05 pero ya cuando traen , no jala
   */
  const priceWithoutComma = `${value}`.replace(',', '');
  let number = parseFloat(priceWithoutComma);
  number = Number(number);
  if (!Number.isNaN(number)) {
    /* number = number.toFixed(fixed);
    number = Number.parseFloat(number); */
    const factorOf10 = Math.pow(10, fixed);
    number = Math.round(number * factorOf10) / factorOf10;
    return number;
  }
  return value;
};

export const getParentElement = (element, objetive = 'BUTTON') => {
  let target = element;
  const type = objetive.toUpperCase();
  while (target && target.nodeName !== type) {
    target = target.parentElement;
  }
  return target;
};

export const wordInString = (_word, _string) => {
  const word = _word ? _word.toString().toLowerCase().trim() : '';
  const string = _string ? _string.toString().toLowerCase().trim() : '';
  return string.indexOf(word) !== -1;
};

export const paginate = (array, elements, index = -1) => {
  const pagedArray = lodash.chunk(array, elements);
  const result = (index < 0 || index >= pagedArray.length) ? pagedArray : pagedArray[index];
  return {
    pages: pagedArray,
    page: result,
    totalPages: pagedArray.length,
    indexPage: index < 0 ? 0 : index,
  };
};

/* Json to array usign keys as a array element */
export const jsonToArray = json => (
  Object.keys(json).map(_ => ({ ...json[_] }))
);

/* Default format: 000,000.00 */
export const formatMoney = (amount, decimalCount = 2, decimal = '.', thousands = ',') => {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? '-' : '';

    const i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
    const j = (i.length > 3) ? i.length % 3 : 0;

    return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, `$1${thousands}`) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : '');
  } catch (e) {
    console.log(e);
  }
};

export const stringToJson = (str) => {
  try {
    if (str !== '') return JSON.parse(str);
    return {};
  } catch (err) {
    console.log({ stringToJsonErr: err });
    return {};
  }
};


/**
 * Generate a random number with a max value
 * @param {Integer} max
 */
export const generateRandomNumber = max => Math.floor(Math.random() * Math.floor(max));

/**
 * Generate Categories (no-duplicate array) from a list of elements and a common attribute
 * @param {Array} items [Array of elements]
 * @param {String} comp [Common attribute]
 */
export const getCategories = (items, comp) => {
  const array = items.map(e => e[comp]);
  const newItems = array.filter((item, index) => array.indexOf(item) >= index);
  return newItems;
};

/**
 * Generate categories that are concat by a spacial character (default |)
 * @param {Array} items [Array of elements]
 * @param {String} comp [Word of comparation]
 */
export const getCategoriesByString = (items, comp) => {
  const elements = [];
  const array = items.map(e => e[comp].split('|'));
  array.forEach((arr) => { arr.map(x => elements.push(x)); });
  return elements.filter((item, index) => elements.indexOf(item) >= index);
};

/**
 * Generate an array by a object iteration fixed by keys
 * @param {object} obj [Object to iterate]
 */

export const getArrayWithKeyByJson = (obj) => {
  const array = [];
  Object.entries(obj).forEach((item) => {
    const label = item[0];
    const values = item[1];
    array.push({ label, values });
  });
  return array;
};

export const removeSpecialCharacters = string => string.replace(/[^a-zA-Z0-9]/g, '');

/**
 * Filter depends by predicate
 * @param {Array} collection Collection to inspect
 * @param {String} param That includes the element
 * @param {String} element To find
 */
export const filterInCollection = (collection, param, element) => lodash.filter(collection, x => x[param].trim().toLowerCase() === element.trim().toLowerCase());

/**
 * Check if the input string is not an empty a lot of posibillities
 * @param {String} string string to evaluate
 */
export const stringIsEmpty = (string) => {
  const s = string;
  return Boolean(
    s !== ''
    && s !== '0'
    && Number.parseFloat(s) !== 0.0
    && s !== undefined,
  );
};

export const formatBytes = (bytes, decimals) => {
  if (bytes === 0) return '0 Bytes';
  const k = 1024;
  const dm = decimals <= 0 ? 0 : decimals || 2;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};


export const geteColorSosStatus = (state) => {
  let color = '';
  switch (state) {
    case 'Created':
      color = '#dee2e6';
      break;
    case 'Autorizado':
    case 'Delivered':
      color = '#23A1DB';
      break;
    case 'Apartado':
    case 'Approved':
      color = '#F7C379';
      break;
    case 'Cancelado':
    case 'Cancelled':
      color = '#F05B70';
      break;
    default:
      color = '#dee2e6';
      break;
  }
  return color;
};

export const getColorStatusFDE = (state) => {
  let color = '';
  switch (state) {
    case 'Sin Pago/Cobro':
      color = '#dee2e6';
      break;
    case 'Parcialmente Pagado/Cobrado':
      color = '#F7C379';
      break;
    case 'Pagado/Cobrado':
      color = '#23A1DB';
      break;
    default:
      color = '#dee2e6';
      break;
  }
  return color;
};

export const getColorStatusMDA = (state) => {
  let color = '';
  switch (state) {
    case 'Sin Entregar':
      color = '#dee2e6';
      break;
    case 'Parcialmente Entregado':
      color = '#F7C379';
      break;
    case 'Entregado':
      color = '#23A1DB';
      break;
    default:
      color = '#dee2e6';
      break;
  }
  return color;
};

export const getColorStatusSAT = (state) => {
  let color = '';
  switch (state) {
    case 'null':
      color = '#dee2e6';
      break;
    case 'Vigente':
      color = '#23A1DB';
      break;
    case 'Cancelado':
      color = '#F05B70';
      break;
    default:
      color = '#dee2e6';
      break;
  }
  return color;
};


export const getColorStatusQuote = (state) => {
  let color = '';
  switch (state) {
    case 'Created':
    case 'Creado':
      color = '#d8d8d8';
      break;
    case 'Delivered':
    case 'Autorizado':
      color = '#23a1db';
      break;
    case 'Reviewed':
    case 'Revisado':
      color = '#8152a0';
      break;
    case 'Accepted':
    case 'Aceptado':
      color = '#F7C379';
      break;
    case 'Rejected':
    case 'Rechazado':
      color = '#F05B70';
      break;
    default:
      color = '#d8d8d8';
      break;
  }
  return color;
};

export const getColorStatusGuide = (state) => {
  let color = '';
  switch (state) {
    case 'Entregado':
    case 'En tránsito':
    case 'Envío generado':
    case 'Recogida en tienda':
      color = '#23a1db';
      break;

    case 'Aceptado':
    case 'Incidencia':
    case 'Almacenado':
    case 'En elaboración':
    case 'Reservación en proceso':
    case 'Información de aduanas pendiente':
    case 'En proceso de liberación de aduana':
    case 'Esperando confirmación del transportista':
    case 'Guía solicitada':
    case 'Recoleción programada':
      color = '#F7C379';
      break;

    case 'Cancelado':
    case 'Error de reserva':
      color = '#F05B70';
      break;
    default:
      color = '#d8d8d8';
      break;
  }
  return color;
};

export const getColorInternalStatusGuide = (state) => {
  let color = '';
  switch (state) {
    case '1- Sin autorizar':
    case '2- Por asignar':
      color = '#c8c7cc';
      break;

    case '3- Preparando recoleccion':
    case '4- Recoleccion Pendiente':
    case '5- En transito':
      color = '#f7c379';
      break;

    case '6- Entregado':
    case '7- Entregado parcial':
      color = '#23a1db';
      break;

    case '9- Cancelado':
    case '10- Perdido':
    case '8- Entrega en Falso / No Entregado':
      color = '#f05b70';
      break;
    default:
      color = '#d8d8d8';
      break;
  }
  return color;
};


export const getColorTaskStatus = (state) => {
  let color = '';
  switch (state) {
    case 'Creado':
      color = '#bdc3c7';
      break;
    case 'Abierto':
      color = '#23A1DB';
      break;
    case 'En progreso':
      color = '#F7C379';
      break;
    case 'Completed':
    case 'Terminada':
      color = '#3B9648';
      break;
    case 'Diferido':
      color = '#337ab7';
      break;
    case 'Cancelado':
      color = '#F05B70';
      break;
    default:
      color = '#B6BEC6';
      break;
  }
  return color;
};

export const convertAmountToSelectCurrency = (conversionRateCurrencyFrom, conversionRateCurrencyTo, amount) => {
  if (conversionRateCurrencyTo === '1' || conversionRateCurrencyTo === 1 || conversionRateCurrencyTo === '1.00000') {
    return parseFloat(amount) / parseFloat(conversionRateCurrencyFrom);
  }

  if (conversionRateCurrencyFrom === '1' || conversionRateCurrencyFrom === 1 || conversionRateCurrencyFrom === '1.00000') {
    return parseFloat(amount) * parseFloat(conversionRateCurrencyTo);
  }
  const conversionRate = parseFloat(conversionRateCurrencyFrom) / parseFloat(conversionRateCurrencyTo);
  const amountConverted = parseFloat(amount) * conversionRate;
  return amountConverted;
};

export const formatString = stringValue => stringValue.replace('&Aacute;', 'Á')
  .replace('&aacute;', 'á')
  .replace('&Eacute;', 'É')
  .replace('&eacute;', 'é')
  .replace('&Iacute;', 'Í')
  .replace('&iacute;', 'í')
  .replace('&Oacute;', 'Ó')
  .replace('&oacute;', 'ó')
  .replace('&Uacute;', 'Ú')
  .replace('&uacute;', 'ú')
  .replace('&amp;', '&')
  .replace('&ntilde;', 'ñ')
  .replace('&rdquo;', '"')
  .replace('&quot;', "'");

export const createOrderRef = () => {
  const actual = new Date();
  const seconds = `0${actual.getSeconds()}`.slice(-2);
  const minutes = `0${actual.getMinutes()}`.slice(-2);
  const hours = `0${actual.getHours()}`.slice(-2);
  const day = `0${actual.getDate()}`.slice(-2);
  const month = `0${actual.getMonth() + 1}`.slice(-2);
  const year = actual.getFullYear();
  const ref = `${year}${month}${day}${hours}${minutes}${seconds}`;

  return ref;
};


export default {
  filterInCollection,
  formatBytes,
  formatMoney,
  generateRandomNumber,
  getArrayWithKeyByJson,
  getCategories,
  getCategoriesByString,
  getParentElement,
  jsonToArray,
  numberFormat,
  paginate,
  stringIsEmpty,
  stringToJson,
  wordInString,
  convertAmountToSelectCurrency,
  formatString,
  createOrderRef,
  getColorInternalStatusGuide,
};
