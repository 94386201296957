import {
  SET_SEARCH, SET_ORDER, SET_DISCOUNT_TAXES, DISABLE_DISCOUNT_TAXES, SET_FILTER_VALUE, SET_ORDER_FILTERS, SET_QUOTES_FILTERS,
} from '../types/filterActions.type';

const INITIAL_STATE = {
  searchBy: 'Cuenta',
  orderBy: 'Mas nueva',
  filterBy: '',
  wichFilterChange: '',
  discountTaxes: false,
  order: [],
  quote: [],
};

const setOrderFilter = (state, action) => {
  let counter = 0;
  const filters = [...action.payload];
  const singleFilters = [];
  filters.forEach((filter) => {
    if (!singleFilters.length) {
      singleFilters.push(filter);
      return;
    }
    singleFilters.map((currentFilter) => {
      if (currentFilter.includes(filter[0])) {
        counter += 1;
      }
    });
    if (counter === 0) {
      singleFilters.push(filter);
    } else {
      counter = 0;
    }
  });

  return {
    ...state,
    order: [...singleFilters],
  };
};

const filterReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_SEARCH:
      return {
        ...state,
        searchBy: action.payload,
        wichFilterChange: 'search',
      };
    case SET_ORDER:
      return {
        ...state,
        orderBy: action.payload,
        wichFilterChange: 'order',
      };
    case SET_FILTER_VALUE:
      return {
        ...state,
        filterBy: action.payload,
        wichFilterChange: 'filter',
      };
    case SET_DISCOUNT_TAXES:
      return {
        ...state,
        discountTaxes: !state.discountTaxes,
      };
    case DISABLE_DISCOUNT_TAXES:
      return {
        ...state,
        discountTaxes: false,
      };
    case SET_ORDER_FILTERS:
      return setOrderFilter(state, action);
    case SET_QUOTES_FILTERS:
      return {
        ...state,
        quote: action.payload,
      };

    default:
      return state;
  }
};
export default filterReducer;
