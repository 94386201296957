/* eslint-disable import/no-cycle */
import React from 'react';
import { useSelector } from 'react-redux';
import TimbrarApi from '../../../../../api/TimbrarApi';
import './timbrar.scss';
import ShareModal from '../../../../Share/components/ShareModal';
import appModules from '../../../../../constant/appModules';

const Sharemenu = ({
  // eslint-disable-next-line react/prop-types
  open, handleClose, typo, fde,
}) => {
  const title = 'Compartir cobro';
  const timbrarApi = new TimbrarApi();
  const loads = useSelector(state => state.loads);
  const isLoading = Boolean(loads && loads.emailTemplateIsLoading);
  const getVerificacion = async (fdeId) => {
    await timbrarApi.verificarFdeTimbre(fdeId);
  };

  React.useEffect(() => {
    if (fde && typo === 'Timbrar') {
      getVerificacion(fde);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fde]);
  return (
    <div>
      <ShareModal
        open={open}
        title={title}
        handleClose={handleClose}
        order={fde || {}}
        template={appModules.TreebesFdE}
        isLoading={isLoading}
      />
    </div>
  );
};

export default Sharemenu;
