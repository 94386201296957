import fetch from 'cross-fetch';
import ConfigurationAPI from './ConfigurationAPI';

class FdeAPI extends ConfigurationAPI {
    get = async (id) => {
      const url = `${this.url}/list/TreebesFdE?related_to=${id}`;
      try {
        const response = await fetch(url, this.credentials);
        const json = await response.json();
        return { ...json };
      } catch (getErr) {
        return {
          success: false,
          result: [],
          message: `Error al crear una dirección: ${getErr}`,
        };
      }
    }

  put = async (body) => {
    const url = `${this.url}/TreebesFdE`;
    this.credentials.method = 'PUT';
    this.credentials.body = JSON.stringify(body);
    try {
      const response = await fetch(url, this.credentials);
      const json = await response.json();
      return { ...json };
    } catch (err) {
      return {
        success: false,
        result: [],
        message: `Error al editar un fde: ${err}`,
      };
    }
  };

  getFdeById = async (id) => {
    try {
      // TreebesFdE/schema/84819?fields_only=1&schema_mode=edit
      const response = await fetch(`${this.url}/TreebesFdE/schema/${id}?fields_only=1&schema_mode=edit`, this.credentials);
      const json = await response.json();
      return json;
    } catch {
      return { success: false, message: 'Error en el Host' };
    }
  }
}

export default FdeAPI;
