/* eslint-disable react/prop-types */
/* eslint-disable import/no-cycle */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faShoppingBasket,
  faFileInvoice,
  faHandHoldingUsd,
  faFileInvoiceDollar,
  faDolly,
  faFileSignature,
  faChevronLeft,
  faWallet,
} from '@fortawesome/free-solid-svg-icons';
import Badge from '@material-ui/core/Badge';
import Divider from '@material-ui/core/Divider';
import './financeContainer.scss';

import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import LinearProgress from '@material-ui/core/LinearProgress';
import Slide from '@material-ui/core/Slide';
import Iconwidthbg from '../../../../components/Icons/IconWidthBG/IconWidthBG';
import Cotizacion from './components/Cotizacion';
import Sales from './components/Sales';
import CashFlow from './components/CashFlow';
import Invoice from './components/Invoice';
import HeaderTitle from '../HeaderTitle/HeaderTitle';
import ScrollPagination from '../../../../components/ScrollPaginator/ScrollPaginator';
import {
  fetchSalesOrderSummary, fetchListInvoice, fetchListCashFlow, fetchListCreditNotes, fetchListQuotes, fetchListInvoiceTransfers,
} from '../../../../redux/actions/financeActions';
import Mda from '../../../../components/Mda';
import { loading, loaded } from '../../../../redux/actions/loadActions';

const Transition = React.forwardRef((props, ref) => <Slide direction="down" ref={ref} {...props} />);

const FinanceContainer = (props) => {
  const head = document.getElementById('top-menu');
  const headHeight = head.getBoundingClientRect().top + head.offsetHeight - 45;
  const {
    finance: {
      salesOrdersSummary, invoices, transfers, creditNotes, cashFlow, quotes, mdas,
    }, client: { selectClient }, loads: { ventasIsLoading }, dispatchLoading, dispatchLoaded,
  } = props;

  const data = [
    { title: 'Cotizaciones', icon: <FontAwesomeIcon style={{ fontSize: 26 }} icon={faFileInvoice} />, data: quotes.data },
    { title: 'Ventas', icon: <FontAwesomeIcon style={{ fontSize: 26 }} icon={faShoppingBasket} />, data: salesOrdersSummary.data },
    { title: 'Facturas', icon: <FontAwesomeIcon style={{ fontSize: 26 }} icon={faFileSignature} />, data: invoices.data },
    { title: 'Traslados', icon: <FontAwesomeIcon style={{ fontSize: 26 }} icon={faWallet} />, data: transfers.data },
    { title: 'Notas de credito', icon: <FontAwesomeIcon style={{ fontSize: 26 }} icon={faFileInvoiceDollar} />, data: creditNotes.data },
    { title: 'Flujo de efectivo', icon: <FontAwesomeIcon style={{ fontSize: 26 }} icon={faHandHoldingUsd} />, data: cashFlow.data },
    { title: 'Movimientos de almacen', icon: <FontAwesomeIcon style={{ fontSize: 26 }} icon={faDolly} />, data: mdas.data },
  ];
  const [open, setOpen] = React.useState('');

  const handleClickOpen = (title) => {
    setOpen(title);
    dispatchLoading();
    setTimeout(() => {
      dispatchLoaded();
    }, 1000);
  };

  const handleClose = () => {
    setOpen('');
  };


  return (
    <div className="finance-container">

      <List dense component="nav">
        {
            data.map(item => (
              <div key={item.title}>
                <ListItem
                  className={`d-flex justify-content-between align-items-center p-0 list-item-bg ${item.data != null ? '' : 'loading'}`}
                  button={!((item.data === null || item.data.length <= 0))}
                  onClick={(item.data === null || item.data.length <= 0) ? null : () => handleClickOpen(item.title)}
                >
                  <div className="d-flex align-items-center">
                    <Iconwidthbg primaryColor="#313c52" secondaryColor="#fff" enabled={false} square>
                      {item.icon}
                    </Iconwidthbg>
                    <span className="section-title ml-2">{item.title}</span>
                  </div>
                  {
                      item.data
                        ? <Badge badgeContent={item.data != null ? item.data.length : 0} showZero className="mr-4 bgGreyScale" classes={{ badge: 'badge-color' }}> </Badge>
                        : <CircularProgress className="mr-4" size={24} />
                  }
                </ListItem>
                {
                  item.title === 'Ventas' ? (<div>{ ventasIsLoading ? <LinearProgress /> : null }</div>) : null
                }
                <Divider />
              </div>
            ))
        }

      </List>
      <Dialog
        fullScreen
        open={(open !== '')}
        onClose={handleClose}
        TransitionComponent={Transition}
        BackdropProps={{ classes: { root: 'backdrop-transparent' } }}
        classes={{
          root: 'down-zIndex',
          /* container: 'dialog-bg', */
          paper: 'dialog-bg',
        }}
        style={{
          top: headHeight,
          width: 520,
          position: 'absolute',
          left: '50%',
          marginLeft: -260,
          boxShadow: 'none',
        }}
      >
        <AppBar className="appbar" style={{ position: 'relative' }}>
          <Toolbar variant="dense" disableGutters elevation={0}>
            <IconButton edge="start" onClick={handleClose} aria-label="close" style={{ position: 'absolute' }}>
              <FontAwesomeIcon style={{ fontSize: 16 }} icon={faChevronLeft} />
            </IconButton>
            <HeaderTitle title={open} />
          </Toolbar>
        </AppBar>
        <div clasname="list-content" style={{ height: 'inherit', overflow: 'auto' }}>

          <List classes={{ root: 'py-0' }} id="render-list">
            {
              open === 'Cotizaciones'
                ? (
                  <ScrollPagination
                    allItems={quotes.data}
                    totalPages={quotes.pages}
                    itemsPerPage={25}
                    currentPage={1}
                    childComponent={<Cotizacion />}
                    action={fetchListQuotes}
                    clientId={selectClient.crmid || ''}
                  />
                )
                : null
            }
            {
              open === 'Ventas'
                ? (
                  <ScrollPagination
                    allItems={salesOrdersSummary.data}
                    totalPages={salesOrdersSummary.pages}
                    itemsPerPage={25}
                    currentPage={1}
                    childComponent={<Sales />}
                    action={fetchSalesOrderSummary}
                    clientId={selectClient.crmid || ''}
                  />
                )
                : null
            }
            {
               open === 'Facturas'
                 ? (
                   <ScrollPagination
                     allItems={invoices.data}
                     totalPages={invoices.pages}
                     itemsPerPage={25}
                     currentPage={1}
                     childComponent={<Invoice />}
                     action={fetchListInvoice}
                     clientId={selectClient.crmid || ''}
                   />
                 )
                 : null
            }
            {
               open === 'Traslados'
                 ? (
                   <ScrollPagination
                     allItems={transfers.data}
                     totalPages={transfers.pages}
                     itemsPerPage={25}
                     currentPage={1}
                     childComponent={<Invoice />}
                     action={fetchListInvoiceTransfers}
                     clientId={selectClient.crmid || ''}
                   />
                 )
                 : null
            }
            {
               open === 'Notas de credito'
                 ? (
                   <ScrollPagination
                     allItems={creditNotes.data}
                     totalPages={creditNotes.pages}
                     itemsPerPage={25}
                     currentPage={1}
                     childComponent={<Invoice />}
                     action={fetchListCreditNotes}
                     clientId={selectClient.crmid || ''}
                   />
                 )
                 : null
            }
            {
               open === 'Flujo de efectivo'
                 ? (
                   <ScrollPagination
                     allItems={cashFlow.data}
                     totalPages={cashFlow.pages}
                     itemsPerPage={25}
                     currentPage={1}
                     childComponent={<CashFlow />}
                     action={fetchListCashFlow}
                     clientId={selectClient.crmid || ''}
                   />
                 )
                 : null
            }
            {
              open === 'Movimientos de almacen'
                ? (
                  <Mda type="clients" />
                )
                : null
            }
          </List>
        </div>
      </Dialog>
    </div>
  );
};

const mapStateToProps = state => ({
  finance: state.finance,
  client: state.client,
  loads: state.loads,
});
const mapDispatchToProps = dispatch => ({
  dispatchLoading: () => dispatch(loading('ventas')),
  dispatchLoaded: () => dispatch(loaded('ventas')),
});
FinanceContainer.prototype = {
  finance: PropTypes.object.isRequired,
};
export default connect(mapStateToProps, mapDispatchToProps)(FinanceContainer);
