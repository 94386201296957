/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import useFdeOrder from '../../../hooks/order/useFetchFdeOrder';
import useOrder from '../../../hooks/order/useOrder';
import FdeList from './components/FdeList/FdeList';

const Fde = ({ orderId }) => {
  const { fdeList, isLoadingFde, errorFde } = useFdeOrder(orderId);
  const { data, isLoading, error } = useOrder(orderId);

  const loading = Boolean(isLoading || isLoadingFde);

  return (
    (error || errorFde) ? (<div>Occurrio un error al cargar los flujos de efectivos</div>) : <FdeList fde={fdeList} isLoading={loading} order={data} />
  );
};

Fde.propTypes = {
  orderId: PropTypes.string.isRequired,
};

export default Fde;
