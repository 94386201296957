/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';

import { toolsHelper } from '../../../../helpers';
import { convertAmountToSelectCurrency } from '../../../../helpers/tools';

class TicketRefundPrint extends React.PureComponent {
  static propTypes = {
    authUser: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    order: PropTypes.object.isRequired,
  };

  render() {
    const {
      authUser, data, title, order,
    } = this.props;
    const {
      user: {
        config, logoname, organizationname, usable_currencies,
      },
    } = authUser;

    let orderCurrencyId;
    if (order) {
      // eslint-disable-next-line prefer-destructuring
      orderCurrencyId = order.currency_id.split('x').pop();
    } else {
      orderCurrencyId = localStorage.getItem('currencyId');
    }

    const orderCurrency = usable_currencies.find(c => c.id === orderCurrencyId);
    const sameCurrencyMontoArray = data.payments.map((x) => {
      if (orderCurrency.id !== x.sub.currency_id) {
        const selectionCurrency = usable_currencies.find(c => c.id === x.sub.currency_id);
        const montoConverted = convertAmountToSelectCurrency(selectionCurrency.conversion_rate, orderCurrency.conversion_rate, x.monto);
        return montoConverted;
      }
      return parseFloat(x.monto);
    });

    const scale = config.pos_print_size ? config.pos_print_size : '7cm';
    const reduce = sameCurrencyMontoArray.map(x => x).reduce((a, m) => (a + m), 0);
    const pReduce = data.mda.map(x => x.field).reduce((a, m) => (a + m), 0);
    return (
      <table
        style={{ maxWidth: scale, minWidth: scale }}
        className="ticketsCC_table"
      >
        <thead>
          <tr>
            <th colSpan={5}>
              <img
                src={config.store.image || logoname}
                alt={organizationname}
                className="ticketsCC_table_logo"
              />
            </th>
          </tr>
        </thead>
        <thead>
          <tr>
            <th className="tickets_table_type" colSpan={5}>
              {title}
            </th>
          </tr>
        </thead>
        <thead>
          <tr>
            <th colSpan={5}>----------------------------</th>
          </tr>
        </thead>
        <thead>
          <tr>
            <th colSpan={2} className="tickets_table_clientBillH">Orden</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colSpan={2} width="40%" className="tickets_table_clientBillH">
              {order.salesorder_no}
            </td>
          </tr>
        </tbody>
        <thead>
          <tr>
            <th colSpan={5}>----------------------------</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colSpan={5} className="tickets_table_clientBill">
              {`Ref: ${data.ref}`}
            </td>
          </tr>
          <tr>
            <td colSpan={5} className="tickets_table_clientBill">
              {`Emisión: ${data.date}`}
            </td>
          </tr>
        </tbody>
        <thead>
          <tr>
            <th colSpan={5}>----------------------------</th>
          </tr>
        </thead>
        {data.payments.length > 0 && (
        <table className="ml-3">
          <tbody>
            <tr>
              <td colSpan={5}>
                <span className="font-weight-bold mr-1">Flujo de Efectivo devuelto:</span>
              </td>
            </tr>
            <tr>
              <th
                style={{
                  minWidth: scale,
                  maxWidth: scale,
                }}
                colSpan={6}
              >
                <div className="w-100 d-flex justify-content-between font-size-12 border-bottom pb-1 mb-2">
                  <div className="ml-4">Método:</div>
                  <div className="">Monto:</div>
                </div>
              </th>
            </tr>
            {data.payments.map(pay => (
              <table
                className="table_detalles"
                style={{
                  maxWidth: scale,
                  minWidth: scale,
                  marginLeft: '2em',
                  marginTop: '0.4em',
                  marginBottom: '0.4em',
                }}
                key={`key_payment_ticket_${pay.id}`}
              >
                <tbody>
                  <tr className="font-size-13">
                    <td>
                      <div className="w-100 font-weight-bold font-size-14">{pay.metodo}</div>
                    </td>
                    <td>
                      <div className="w-100 text-right font-weight-bold font-size-16">
                        {`$${toolsHelper.formatMoney(pay.monto)} ${pay.sub.currency_code}`}
                      </div>
                      <div className="w-100 text-right" />
                    </td>
                  </tr>
                </tbody>
              </table>
            ))}
            <table
              className="w-100 ml-3"
              style={{
                maxWidth: scale,
                minWidth: scale,
                marginTop: '0.4em',
                marginBottom: '0.4em',
              }}
            >
              <tbody>
                <tr>
                  <td colSpan={5} className="w-100 text-right font-size-13">
                    {`Total: $${toolsHelper.formatMoney(reduce)}  ${orderCurrency.currency_code}`}
                  </td>
                </tr>
              </tbody>
            </table>
          </tbody>
        </table>
        )}
        {data.mda.length > 0 && (
          <table className="ml-3">
            <tbody className="w-100">
              <tr>
                <td colSpan={5}>
                  <span className="font-weight-bold mr-1">Productos devueltos:</span>
                </td>
              </tr>
              <tr>
                <th
                  style={{
                    minWidth: scale,
                    maxWidth: scale,
                  }}
                  colSpan={6}
                >
                  <div className="w-100 d-flex justify-content-between font-size-12 border-bottom pb-1 mb-2">
                    <div className="ml-4">Producto:</div>
                    <div className="">Cantidad:</div>
                  </div>
                </th>
              </tr>
              {data.mda.map(prod => (
                <table
                  className="table_detalles"
                  style={{
                    maxWidth: scale,
                    minWidth: scale,
                    marginLeft: '2em',
                    marginTop: '0.4em',
                    marginBottom: '0.4em',
                  }}
                  key={`key_prod_ticket_${prod.crmid}`}
                >
                  <tbody>
                    <tr className="font-size-14">
                      <td>
                        <div className="w-100 font-weight-bold font-size-14">{prod.productname}</div>
                        <div className="w-100">{`$${toolsHelper.formatMoney(prod.price)}`}</div>
                      </td>
                      <td>
                        <div className="w-100 text-right font-weight-bold font-size-16">
                          {prod.field}
                        </div>
                        <div className="w-100 text-right" />
                      </td>
                    </tr>
                  </tbody>
                </table>
              ))}
              <table
                className="w-100 ml-3"
                style={{
                  maxWidth: scale,
                  minWidth: scale,
                  marginTop: '0.4em',
                  marginBottom: '0.4em',
                }}
              >
                <tbody>
                  <tr>
                    <td colSpan={5} className="w-100 text-right font-size-13">
                      {`Total: ${pReduce}`}
                    </td>
                  </tr>
                </tbody>
              </table>
            </tbody>
          </table>
        )}
      </table>
    );
  }
}

export default TicketRefundPrint;
