/* eslint-disable camelcase */

import fetch from 'cross-fetch';
import ConfigurationAPI from './ConfigurationAPI';

class SellAPI extends ConfigurationAPI {
  create = async (
    cart,
    discount,
    customer,
    sostatus,
    payments,
    deliver = false,
    filters,
    convertRate = 1,
  ) => {
    const { discountTaxes } = filters;
    const totalCart = cart.total/* * convertRate */;
    const totalDiscount = discount.total/* * convertRate */;
    const totalToPay = Number((totalCart - totalDiscount).toFixed(2));
    const { promotions, orderSelected } = cart;
    const isQuote = !!(orderSelected?.order && orderSelected?.order.hasOwnProperty('quote_no'));

    const products = [];
    cart.products.forEach((product) => {
      const productid = product.productid && product.productid.indexOf('x') !== -1
        ? product.productid.split('x').pop()
        : product.productid;
      const custom = {
        option: [
          {
            product_option_value_id: product.crmid || productid,
          },
        ],
        discount_percent: product.discount_percent,
        price: (product.priceWithTaxes /* * convertRate */) / (1 - product.discount_percent / 100),
        pu: product.unit_price /* * convertRate */,
        product_id: product.parentId,
        quantity: product.quantity,
        productid: product.crmid || productid,
        comment: product.comment,
      };
      products.push(custom);
    });

    /**
     * Working on promotions
     */
    if (promotions && promotions.free_products) {
      promotions.free_products.forEach((product) => {
        const promotionProduct = {
          option: [
            {
              product_option_value_id: product.productid,
            },
          ],
          discount_percent: 0,
          price: product.unit_price /* * convertRate */,
          pu: product.unit_price /* * convertRate */,
          product_id: null,
          quantity: product.quantity,
          productid: product.productid,
          comment: '',
        };
        products.push(promotionProduct);
      });
    }

    const data = {
      products,
      sin_impuestos: discountTaxes ? 1 : 0,
      subtotal: cart.subTotal /* * convertRate */,
      total: totalToPay,
      account_no: customer.selectedCustomer.account_no,
      tpv_payment: payments,
      tpv_discount_amount: discount.fixed,
      tpv_discount_percentage: discount.percentage,
      order_status: sostatus,
      comment: cart.description,
      treebesdireccionenvioid:
        customer.selectedCustomer.treebesdireccionenvioid,
      treebesdireccionfacturaid:
        customer.selectedCustomer.treebesdireccionfacturaid,
      deliver,
      TreebesPromociones_Aplicadas:
        promotions && promotions.applied_promos
          ? Object.keys(promotions.applied_promos)
          : [],
    };

    if (isQuote) {
      data.quote_id = orderSelected.crmid;
    }


    this.credentials.method = 'POST';
    this.credentials.headers['currency-id'] = localStorage.getItem('currencyId');
    this.credentials.body = JSON.stringify(data);
    const response = await fetch(`${this.url}/SalesOrder`, this.credentials);
    try {
      const json = await response.json();
      return json;
    } catch (err) {
      return {
        success: false,
        status: response.status,
        message: response.error.message,
      };
    }
  };

  _fde = async (type, payments, related_to) => {
    const data = {
      type,
      flow: payments,
      related_to,
    };

    this.credentials.method = 'POST';
    this.credentials.body = JSON.stringify(data);

    try {
      const response = await fetch(`${this.url}/TreebesFdE`, this.credentials);
      const json = await response.json();
      const information = json.success ? 'Envio con exito' : json.error.message;
      const message = { ...json.message, information };
      return { ...json, message };
    } catch {
      return { success: false, message: 'Error en el Host' };
    }
  };

  deliver = async (payments, related_to) => {
    const result = await this._fde('I', payments, related_to);
    return result;
  };

  refund = async (payments, related_to) => {
    const result = await this._fde('O', payments, related_to);
    return result;
  };
}

export default SellAPI;
