import {
  Checkbox,
  List, ListItem, ListItemSecondaryAction, ListItemText,
} from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { setProjectFilter, setStatusFilter } from '../../../../../../redux/actions/taskActions';


function TaskProjectFilter({ openComponent, handleClickOpenComponent, selectedProjects = [] }) {
  const dispatch = useDispatch();
  const [projectsSelected, setProjectsSelected] = React.useState([...selectedProjects]);
  const projects = useSelector(state => state.tasks.projects);

  const formatSProjectSelected = () => {
    let statusList = '';

    if (Array.isArray(projectsSelected) && projectsSelected.length) {
      for (let i = 0; i < projectsSelected.length; i++) {
        statusList = statusList.concat(projectsSelected[i], ',');
      }
    }

    return statusList;
  };

  const addProject = (item) => {
    let newProjects = [];
    if (projectsSelected.includes(item.projectname)) {
      newProjects = projectsSelected.filter(s => s !== item.projectname);
    } else {
      newProjects = [...projectsSelected, item.projectname];
    }
    setProjectsSelected([...newProjects]);
    dispatch(setProjectFilter(newProjects));
  };

  const addProjectSingle = (item) => {
    let newProjects = [];
    if (projectsSelected.includes(item.projectname)) {
      newProjects = projectsSelected.filter(s => s !== item.projectname);
    } else {
      newProjects = [item.projectname];
    }
    setProjectsSelected([...newProjects]);
    dispatch(setProjectFilter(newProjects));
  };

  return (
    <List component="nav" classes={{ root: 'filter-list' }} key={Math.random()}>
      <ListItem button onClick={() => handleClickOpenComponent('projects')}>
        <ListItemText primary="Proyectos:" secondary={formatSProjectSelected()} classes={{ primary: 'filter-item-title', secondary: 'filter-item-subtitle' }} />
        <ListItemText primary={openComponent === 'projects' ? <ExpandLess /> : <ExpandMore />} classes={{ root: 'pr-0', primary: 'd-flex justify-content-end' }} />
      </ListItem>
      <Collapse in={openComponent === 'projects'} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {
            projects.map(item => (
              <ListItem key={Math.random()} button divider classes={{ root: 'filter-item-bg' }} onClick={() => addProject(item)}>
                <ListItemText primary={item.projectname} classes={{ primary: 'filter-item-subItem' }} />
                <ListItemSecondaryAction>
                  <Checkbox
                    color="primary"
                    checked={projectsSelected.includes(item.projectname)}
                    onChange={() => addProjectSingle(item)}
                    classes={{ root: 'filter-item-checkbox', colorPrimary: 'filter-item-checkbox', checked: 'filter-item-checkbox' }}
                  />
                </ListItemSecondaryAction>
              </ListItem>
            ))
          }
        </List>
      </Collapse>
    </List>
  );
}

TaskProjectFilter.prototype = {
  openComponent: PropTypes.string.isRequired,
  handleClickOpenComponent: PropTypes.func.isRequired,
};

export default TaskProjectFilter;
