import React from 'react';
import PropTypes from 'prop-types';
/* Material */
import {
  Button,
  Collapse,
  LinearProgress,
} from '@material-ui/core';
/* Reactstrap */
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';

/* ReactToPrint */
import ReactToPrint from 'react-to-print';

import { useSelector } from 'react-redux';
import TicketRefundPrint from './tickets/TicketRefundPrint';

const TicketsModalRefund = ({
  authUser,
  table,
  isLoading,
  isOpen,
  print,
  title,
  toggle,
  cart,
}) => {
  const firstTicketRef = React.useRef();
  const { ticket } = table;
  const { orderSelected: { order } } = cart;
  React.useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        const btnPrint = document.querySelector('#button_to_print');
        // @ts-ignore
        if (btnPrint) btnPrint.click();
      }, 500);
    }
  }, [isOpen]);
  return (
    <Modal isOpen={isOpen} toggle={toggle} size="sm" className="tickets_modal">
      <ModalHeader toggle={toggle}>{title}</ModalHeader>
      <ModalBody className="tickets_modal_body w-100 d-flex justify-content-center">
        {isLoading && <LinearProgress />}
        <Collapse in={!isLoading}>
          <TicketRefundPrint
            ref={firstTicketRef}
            authUser={authUser}
            data={ticket}
            title={ticket.title}
            order={order}
          />
        </Collapse>
      </ModalBody>
      <ModalFooter className="w-100 d-flex flex-column">
        <ReactToPrint
          removeAfterPrint
          copyStyles
          trigger={() => (
            <Button
              id="button_to_print"
              color="primary"
              className="w-100"
              variant="contained"
            >
              {print}
            </Button>
          )}
          content={() => firstTicketRef.current}
          onAfterPrint={() => toggle()}
        />
      </ModalFooter>
    </Modal>
  );
};

TicketsModalRefund.propTypes = {
  authUser: PropTypes.object.isRequired,
  table: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
  print: PropTypes.string,
  title: PropTypes.string.isRequired,
  toggle: PropTypes.func.isRequired,
  cart: PropTypes.object.isRequired,
};
TicketsModalRefund.defaultProps = {
  print: 'Ticket de devolución para usuario ...',
};

export default TicketsModalRefund;
