import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  IconButton,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  ListItemIcon,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@material-ui/core';
import { Delete, Description } from '@material-ui/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toolsHelper } from '../../../../../helpers';
import useFile from '../../../../../hooks/file/useFile';
import { toast } from 'react-toastify';

const ItemFile = ({ file, download, remove }) => {
  const { preview } = useFile();
  const [existFile, setExistFile] = useState();
  const [open, setOpen] = useState();
  const realSize = toolsHelper.formatBytes(file.filesize);
  const secundTitle = `${realSize} - ${moment(file.createdtime).fromNow()}`;

  const renderIcon = (extension) => {
    const ext = extension.toLowerCase();
    switch (ext) {
      case 'pdf':
        return 'file-pdf';
      case 'png':
        return 'file-image';
      case 'jpg':
        return 'file-image';
      default:
        return 'file';
    }
  };

  /* const getFileUrl = async () => {
    return preview(file.url);
  }; */

  return (
    <>
      <ListItem button onClick={() => download(file.url, file.filename)}>
        <ListItemIcon className="file_icon">
          <FontAwesomeIcon icon={renderIcon(file.extension)} />
        </ListItemIcon>
        <ListItemText
          className="file_text"
          primary={file.filename}
          secondary={secundTitle}
        />
        <ListItemSecondaryAction>
          <IconButton
            onClick={async () => {
              toast.info('Cargando archivo...');
              const fileUrl = await preview(file.url);
              await setExistFile(fileUrl);
              setOpen(true);
              // window.open(fileUrl);
            }}
          >
            <Description />
          </IconButton>
          <IconButton onClick={() => remove(file.crmid)}>
            <Delete />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Previsualización del archivo
        </DialogTitle>
        <DialogContent>
          {existFile && (
            <>
              {['png', 'jpg', 'jpeg'].includes(file.extension) ? (
                <img src={existFile} alt="" />
              ) : (
                <embed
                  type={`application/${file.extension}`}
                  src={existFile}
                  style={{ minHeight: '500px' }}
                  className="w-full min-h-full"
                />
              )}
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

ItemFile.propTypes = {
  download: PropTypes.func.isRequired,
  file: PropTypes.object.isRequired,
  remove: PropTypes.func.isRequired,
};

export default ItemFile;
