/* eslint-disable camelcase */
/* eslint-disable no-case-declarations */
/* eslint-disable import/no-cycle */
/* eslint-disable object-curly-newline */

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
} from '@material-ui/core';
import { withRouter } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';

/* Components */
import Comment from '../../Comment';
import {
  fetchTimbrado,
  handleMakeBill,
} from '../../../redux/actions/TimbrarActions';
import {
  selectOrder,
  handleEditOrder,
  changeOrderStatus,
  setOrderCustomer,
} from '../../../redux/actions/orderActions';
import {
  adjustStock,
  clearCart,
  decreaseProductInCart,
  increaseProductInCart,
  removeProductInCart,
  setComment,
  setDesc,
  setSingleDiscount,
  updatePriceByPricebook,
  updateProductPriceInCart,
  updateProductQuantityInCart,
  desactivePromotions,
} from '../../../redux/actions/cartActions';
import {
  saveSell,
  makeSell,
  editOrder,
  changeSalesOrderStatus,
} from '../../../redux/actions/sellActions';
import { changeTab } from '../../../redux/actions/tabActions';
import { addItemsToTable } from '../../../redux/actions/tableActions';
import { sendEmail, getOrderPdf } from '../../../redux/actions/posActions';
import { openModal, closeModal } from '../../../redux/actions/modalActions';
import { cotizar, editarCotizacion } from '../../../redux/actions/cotizarActions';
import { changeSellOrderStatusAuthUser } from '../../../redux/actions/authUserActions';

// import { cartProps } from '../../../propTypes/cartProps';
import authUserProps from '../../../propTypes/authUserProps';
/* Helpers */
import { shouldRefoundMoney, shouldRefoundProducts } from '../../../helpers/order';
/* Inherit components */
import CartState from './CartState';
import CartListRow from './CartListRow';
import Promotions from '../../Promotions';

const styles = theme => ({
  paperRoot: {
    padding: 25,
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
    height: '55vh',
    overflowY: 'auto',
  },
  noPadding: {
    padding: 0,
    paddingTop: 15,
    paddingBottom: 15,
  },
});

class CartList extends PureComponent {
  static propTypes = {
    /* Store States */
    authUser: authUserProps.isRequired,
    cart: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    customer: PropTypes.object.isRequired,
    isCollapse: PropTypes.bool.isRequired,
    loads: PropTypes.object.isRequired,
    modals: PropTypes.object.isRequired,
    order: PropTypes.object.isRequired,
    tabs: PropTypes.object.isRequired,
    timbrar: PropTypes.object.isRequired,
    /* Order */
    dispatchChangeSalesOrderStatus: PropTypes.func.isRequired,
    dispatchEditOrder: PropTypes.func.isRequired,
    dispatchSelectOrder: PropTypes.func.isRequired,
    /* Cart and Sell */
    dispatchAdjustStock: PropTypes.func.isRequired,
    dispatchChangeSellOrderStatusAuthUser: PropTypes.func.isRequired,
    dispatchCreateSell: PropTypes.func.isRequired,
    dispatchDecreaseProductInCart: PropTypes.func.isRequired,
    dispatchGetOrderDocument: PropTypes.func.isRequired,
    dispatchHandleEditOrder: PropTypes.func.isRequired,
    dispatchIncreaseProductInCart: PropTypes.func.isRequired,
    dispatchMakeSell: PropTypes.func.isRequired,
    dispatchRemoveProductInCart: PropTypes.func.isRequired,
    dispatchSendEmail: PropTypes.func.isRequired,
    dispatchSetComment: PropTypes.func.isRequired,
    dispatchSetDesc: PropTypes.func.isRequired,
    dispatchSetOrderStatus: PropTypes.func.isRequired,
    dispatchUpdateProductPriceInCart: PropTypes.func.isRequired,
    dispatchUpdateProductQtyInCart: PropTypes.func.isRequired,
    dispatchSetOrderCustomer: PropTypes.func.isRequired,
    getPriceByPricebook: PropTypes.func.isRequired,
    /* Timbrar */
    dispatchFetchTimbrado: PropTypes.func.isRequired,
    dispatchHandleMakeBill: PropTypes.func.isRequired,
    /* Cotizaciones */
    dispatchCotizar: PropTypes.func.isRequired,
    dispatchEditarCotizacion: PropTypes.func.isRequired,
    /* Discount */
    dispatchSetSingleDiscount: PropTypes.func.isRequired,
    /* Layout */
    dispatchChangeTabToHistorico: PropTypes.func.isRequired,
    dispatchChangeTabToSell: PropTypes.func.isRequired,
    dispatchDisablePromotions: PropTypes.func.isRequired,
    /* Modal */
    dispatchCloseCheckOutModal: PropTypes.func.isRequired,
    dispatchCloseDescriptionModal: PropTypes.func.isRequired,
    dispatchCloseSendEmaillModal: PropTypes.func.isRequired,
    dispatchOpenCheckOutModal: PropTypes.func.isRequired,
    dispatchOpenCheckOutRefunModal: PropTypes.func.isRequired,
    dispatchOpenDescriptionModal: PropTypes.func.isRequired,
    dispatchOpenDiscountModal: PropTypes.func.isRequired,
    dispatchOpenFileModal: PropTypes.func.isRequired,
    dispatchOpenRefundModal: PropTypes.func.isRequired,
    dispatchOpenSendEmaillModal: PropTypes.func.isRequired,
    dispatchOpenTicketsModal: PropTypes.func.isRequired,
    dispatchOpenTimbrarModal: PropTypes.func.isRequired,
    history: PropTypes.object,
  }

  static defaultProps = {
    history: {},
  }

  constructor(...props) {
    // @ts-ignore
    super(...props);
    this.state = { rowClasses: {} };
  }

  componentDidMount() {
    const { cart: { orderSelected }, tabs: { posTypeTab }, dispatchClearCart, order: { enableEditOrder } } = this.props;
    if (posTypeTab === 'Vender' && Object.keys(orderSelected).length !== 0 && !enableEditOrder) {
      dispatchClearCart();
    }
  }

  componentWillReceiveProps(nextProps) {
    const { cart } = this.props;
    const { rowClasses } = this.state;
    let newRowClsses = {};

    const oldCart = { ...cart };
    const nextCart = { ...nextProps.cart };

    const oldCartProductsById = { ...oldCart.cartProductsById };

    nextCart.products.forEach((product) => {
      const { crmid } = product;

      /* is new */
      if (!(crmid in rowClasses)) {
        newRowClsses = { ...newRowClsses, [crmid]: 'tRow-fix animated pulse' };
      }

      /* exist */
      else {
        newRowClsses = { ...newRowClsses, [crmid]: 'tRow-fix animated slideInDown' };
      }

      if (crmid in oldCartProductsById) {
        const oldProduct = oldCartProductsById[crmid];
        /* update */
        if (oldProduct.quantity !== product.quantity) {
          newRowClsses = { ...newRowClsses, [crmid]: 'tRow-fix animated pulse' };
        }
      }
    });

    this.setState({ rowClasses: newRowClsses });
  }

  componentDidUpdate() {
    const { cart: { orderSelected }, tabs: { posTypeTab }, dispatchClearCart, order: { enableEditOrder } } = this.props;
    if (posTypeTab === 'Vender' && Object.keys(orderSelected).length !== 0 && !enableEditOrder) {
      dispatchClearCart();
    }
  }


  handleKeyTopSearchBar = async (e) => {
    const {
      authUser,
      dispatchMakeSell,
      dispatchRemoveProductInCart,
      dispatchIncreaseProductInCart,
      dispatchDecreaseProductInCart,
    } = this.props;
    e.stopPropagation();
    let { keyCode } = e;
    const { ctrlKey, shiftKey, currentTarget } = e;
    keyCode = String(keyCode);
    const topSearchBar = document.querySelector('#search_bar_query');
    const active = document.activeElement;
    if (ctrlKey) {
      if (ctrlKey && keyCode === '13') {
        const checkoutButton = document.querySelector('#key_selector_button');
        // @ts-ignore
        checkoutButton.focus();
      } else if (shiftKey && keyCode === '72') {
        const currentOrderStatus = authUser.user.config.sostatus;
        await dispatchMakeSell(currentOrderStatus);
      }
    } else {
      switch (keyCode) {
        case '13':
          const isEscaped = Boolean(active.tagName === 'INPUT');
          if (topSearchBar && isEscaped) {
            e.stopPropagation();
            // @ts-ignore
            await topSearchBar.focus();
            // @ts-ignore
            // await topSearchBar.select();
          }
          break;
        case '27':
          if (topSearchBar) {
            // @ts-ignore
            await topSearchBar.focus();
            // @ts-ignore
            await topSearchBar.select();
          }
          break;
        case '46':
          const id = currentTarget.getAttribute('data-row');
          await dispatchRemoveProductInCart(id);
          break;
        case '107':
          await dispatchIncreaseProductInCart(e);
          break;
        case '109':
          await dispatchDecreaseProductInCart(e);
          break;
        default:
          break;
      }
    }
  }

  render() {
    const {
      cart,
      cart: { orderSelected },
      tabs,
      loads,
      modals,
      classes,
      timbrar: { makeBill },
      authUser,
      customer,
      isCollapse,
      dispatchSetDesc,
      dispatchCotizar,
      dispatchMakeSell,
      dispatchSendEmail,
      dispatchEditOrder,
      dispatchSetComment,
      dispatchCreateSell,
      dispatchAdjustStock,
      getPriceByPricebook,
      dispatchSelectOrder,
      dispatchFetchTimbrado,
      dispatchHandleMakeBill,
      dispatchSetOrderStatus,
      dispatchOpenRefundModal,
      dispatchHandleEditOrder,
      dispatchChangeTabToSell,
      dispatchSetOrderCustomer,
      dispatchEditarCotizacion,
      dispatchGetOrderDocument,
      dispatchSetSingleDiscount,
      dispatchRemoveProductInCart,
      dispatchChangeTabToHistorico,
      dispatchIncreaseProductInCart,
      dispatchDecreaseProductInCart,
      dispatchUpdateProductQtyInCart,
      dispatchChangeSalesOrderStatus,
      dispatchUpdateProductPriceInCart,
      dispatchChangeSellOrderStatusAuthUser,
      /* Modal */
      dispatchOpenFileModal,
      dispatchOpenTicketsModal,
      dispatchOpenTimbrarModal,
      dispatchOpenCheckOutModal,
      dispatchOpenDiscountModal,
      dispatchCloseCheckOutModal,
      dispatchOpenSendEmaillModal,
      dispatchCloseSendEmaillModal,
      dispatchOpenDescriptionModal,
      dispatchCloseDescriptionModal,
      dispatchOpenCheckOutRefunModal,
      dispatchDisablePromotions,
      history,
      dispatchClearCart,
    } = this.props;
    const { rowClasses } = this.state;
    const { fastMode, domain } = authUser;
    const { order } = orderSelected;
    // eslint-disable-next-line no-prototype-builtins
    const isQuote = order && order.hasOwnProperty('quote_no');
    const orderStatuses = isQuote ? authUser.user.quotestage : authUser.user.sostatus;
    const currentOrderStatus = authUser.user.config.sostatus;
    const { pos_checkout_bill, pos_allowed_sostatus } = authUser.user.config;
    const allowOrderStatus = pos_allowed_sostatus.split(' |##| ');

    const orderNo = cart.orderSelected && cart.orderSelected.order ? (cart.orderSelected.order.salesorder_no || cart.orderSelected.order.label) : '';
    const orderId = cart.orderSelected && cart.orderSelected.order ? cart.orderSelected.order.crmid : '';
    const orderDate = cart.orderSelected && cart.orderSelected.order ? (cart.orderSelected.order.createdtime) : '';

    const almacen = cart.orderSelected ? cart.orderSelected.cartStateWarehouse : '';
    const balance = cart.orderSelected ? cart.orderSelected.balance : 0;
    const total = cart.orderSelected ? cart.orderSelected.total : 0;

    const { posTypeTab } = tabs;
    const { alertProducts } = cart;
    // const isAddingCart = Boolean(posTypeTab === 'Vender' && totalProducts > 0);


    const isCotizacion = Boolean(posTypeTab === 'Cotizaciones');
    // eslint-disable-next-line react/destructuring-assignment
    const { enableEditOrder } = this.props.order;

    const enableEdit = Boolean(
      order ? ((order.estado_mda === 'Sin Entregar' || order.estado_mda === 'N/A' || order.estado_mda === 'Sin Recibir')
          && allowOrderStatus.includes(order.sostatus)
          && (order.estadosat === 'Cancelado' || order.estadosat === 'Sin Factura' || order.estadosat == null)
          && (order.sostatus === 'Creado' || order.sostatus === 'Apartado')
          && order.estado_fde === 'Sin Pago/Cobro'
      ) : false,
    );
    const enableEditByFlag = Boolean(
      JSON.stringify(orderSelected) === JSON.stringify({})
      || (order.estado_fde === 'Sin Pago/Cobro'
        && order.estado_mda === 'Sin Entregar'
          && order.sostatus === 'Creado'
          && enableEditOrder),
    );

    const discoutnExist = Boolean(cart.products.some(product => product.discount_percent > 0));

    return (
      <div className={isCollapse ? 'cartListCollapse' : 'cart_container_list'}>
        <div className="cart-state-__container">
          <CartState
            cart={cart}
            tabs={tabs}
            loads={loads}
            modals={modals}
            orderNo={orderNo}
            orderId={orderId}
            customer={customer}
            makeBill={makeBill}
            authUser={authUser}
            orderDate={orderDate}
            enableEdit={enableEdit}
            editOrder={enableEditOrder}
            alertProducts={alertProducts}
            orderStatuses={orderStatuses}
            handleMakeBill={dispatchHandleMakeBill}
            currentOrderStatus={currentOrderStatus}
            dispatchAdjustStock={dispatchAdjustStock}
            dispatchOpenDiscountModal={dispatchOpenDiscountModal}
            shouldRefoundMoney={shouldRefoundMoney(balance, total)}
            shouldRefoundProducts={shouldRefoundProducts(almacen || '')}
            changeCurrentOrderStatus={(ordeStatus) => {
              dispatchChangeSellOrderStatusAuthUser(ordeStatus);
            }}
            timbrar={() => {
              dispatchFetchTimbrado(orderId);
            }}
            openRefundModal={dispatchOpenRefundModal}
            openCheckOutRefunModal={dispatchOpenCheckOutRefunModal}
            saveSell={() => {
              dispatchMakeSell(currentOrderStatus);
            }}
            posType={tabs.posTypeTab}
            sendEmail={dispatchSendEmail}
            sendEmailIsLoading={Boolean(loads.sendEmailIsLoading)}
            sendEmailIsOpen={Boolean(modals.sendEmailModalIsOpen)}
            openEmailModal={() => dispatchOpenSendEmaillModal()}
            closeMailModal={dispatchCloseSendEmaillModal}
            dispatchCotizar={dispatchCotizar}
            dispatchCreateSell={dispatchCreateSell}
            dispatchChangeSalesOrderStatus={dispatchChangeSalesOrderStatus}
            dispatchOpenCheckOutModal={dispatchOpenCheckOutModal}
            dispatchCloseCheckOutModal={dispatchCloseCheckOutModal}
            dispatchSetOrderStatus={dispatchSetOrderStatus}
            dispatchEditOrder={dispatchEditOrder}
            editarCotizacion={dispatchEditarCotizacion}
            getDocumentOrder={dispatchGetOrderDocument}
            handleEditOrder={async () => {
              dispatchDisablePromotions();
              // dispatchClearCart();
              await dispatchHandleEditOrder();
              if (enableEditOrder) {
                await dispatchChangeTabToHistorico();
                history.push('/pos/ventas');
                await dispatchSelectOrder(orderId);
              } else {
                if (posTypeTab === 'Cotizaciones') {
                  await dispatchChangeTabToSell();
                  history.push('/pos/vender');
                }
                if (posTypeTab === 'Historico') {
                  await dispatchChangeTabToSell();
                  history.push('/pos/vender');
                }
              }
            }}
            posCheckoutBill={pos_checkout_bill}
            dispatchOpenFileModal={dispatchOpenFileModal}
            openDescriptionModal={dispatchOpenDescriptionModal}
            dispatchOpenTicketsModal={dispatchOpenTicketsModal}
            dispatchOpenTimbrarModal={dispatchOpenTimbrarModal}
            dispatchSetOrderCustomer={dispatchSetOrderCustomer}
          />
        </div>
        <Table className="cart_list_table">
          <TableHead>
            <TableRow style={{ height: '24px' }}>
              <TableCell
                align="center"
                style={{ width: '12vw' }}
              >
                Nombre
              </TableCell>
              <TableCell
                align="center"
              >
                Cantidad
              </TableCell>
              <TableCell
                align="center"
              >
                Precio
              </TableCell>
              {discoutnExist && (
                <TableCell
                  align="center"
                >
                  Descuento
                </TableCell>
              )}
              <TableCell
                align="center"
              >
                Total
              </TableCell>
              <TableCell
                align="center"
                style={{ width: '8vw' }}
              />
            </TableRow>
          </TableHead>
        </Table>
        <div
          id="overflow-comercia"
          className="w-100"
        >
          <Table className="cart_list_table">
            <TableBody>
              {
                cart.products.map(product => (
                  <CartListRow
                    key={`CartList-${product.parentId}-${product.crmid}-${product.row}`}
                    domain={domain}
                    classes={classes}
                    product={product}
                    authUser={authUser}
                    posTypeTab={posTypeTab}
                    rowClasses={rowClasses}
                    isCotizacion={isCotizacion}
                    discoutnExist={discoutnExist}
                    alertProducts={alertProducts}
                    orderSelected={orderSelected}
                    enableEditOrder={enableEditOrder}
                    enableAutoFocus={Boolean(!fastMode)}
                    enableEditByFlag={enableEditByFlag}
                    dispatchSetComment={dispatchSetComment}
                    getPriceByPricebook={getPriceByPricebook}
                    handleKeyTopSearchBar={this.handleKeyTopSearchBar}
                    dispatchSetSingleDiscount={dispatchSetSingleDiscount}
                    dispatchRemoveProductInCart={dispatchRemoveProductInCart}
                    dispatchIncreaseProductInCart={dispatchIncreaseProductInCart}
                    dispatchDecreaseProductInCart={dispatchDecreaseProductInCart}
                    dispatchUpdateProductQtyInCart={dispatchUpdateProductQtyInCart}
                    dispatchUpdateProductPriceInCart={dispatchUpdateProductPriceInCart}
                  />
                ))
              }
            </TableBody>
          </Table>
          <Table className="cart_list_table">
            <TableBody>
              <Promotions />
            </TableBody>
          </Table>
        </div>
        {/* General Description */}
        <Comment
          title="Descripción de la Orden"
          close={dispatchCloseDescriptionModal}
          isOpen={Boolean(modals.descriptionModalIsOpen)}
          description={cart.description}
          sendDesc={dispatchSetDesc}
          enableEditOrder={(orderNo) ? (enableEditOrder) : (true)}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  order: state.order,
  cart: state.cart,
  tabs: state.tabs,
  loads: state.loads,
  modals: state.modals,
  timbrar: state.timbrar,
  authUser: state.authUser,
  customer: state.customer,
});

const mapDispatchToProps = dispatch => ({
  dispatchSetOrderStatus: sostatus => dispatch(changeOrderStatus(sostatus)),
  dispatchRemoveProductInCart: (row) => {
    dispatch(removeProductInCart(row));
  },
  dispatchIncreaseProductInCart: (event) => {
    const { row, count } = event.target.dataset;
    dispatch(increaseProductInCart(row, parseFloat(count) || 1));
  },
  dispatchDecreaseProductInCart: (event) => {
    const { row } = event.target.dataset;
    dispatch(decreaseProductInCart(row));
  },
  dispatchClearCart: () => dispatch(clearCart()),
  dispatchUpdateProductPriceInCart: (event) => {
    const { row } = event.target.dataset;
    const price = Number(event.target.value);
    dispatch(updateProductPriceInCart(row, Math.abs(price)));
  },
  dispatchUpdateProductQtyInCart: (event) => {
    const { row, newValue } = event.target.dataset;
    const qty = newValue ? parseFloat(newValue) : parseFloat(event.target.value);
    dispatch(updateProductQuantityInCart(row, qty >= 0.1 ? qty : 1));
  },

  dispatchFetchTimbrado: crmid => dispatch(fetchTimbrado(crmid)),
  dispatchChangeSellOrderStatusAuthUser: orderStatus => dispatch(changeSellOrderStatusAuthUser(orderStatus)),

  dispatchOpenRefundModal: () => {
    dispatch(openModal('refund'));
    dispatch(addItemsToTable('refund'));
  },
  dispatchOpenCheckOutRefunModal: () => dispatch(openModal('checkoutRefound')),
  dispatchMakeSell: (orderStatus) => {
    dispatch(changeSalesOrderStatus(orderStatus));
    dispatch(saveSell());
  },
  getPriceByPricebook: (row, pricebook) => dispatch(updatePriceByPricebook(row, pricebook)),
  /* EDIT ORDER */
  dispatchEditOrder: () => dispatch(editOrder()),
  dispatchAdjustStock: () => dispatch(adjustStock()),
  dispatchHandleEditOrder: () => dispatch(handleEditOrder()),
  dispatchChangeTabToSell: () => dispatch(changeTab('posType', 'Vender')),
  dispatchChangeTabToHistorico: () => dispatch(changeTab('posType', 'Historico')),
  dispatchSelectOrder: crmid => dispatch(selectOrder(crmid)),

  dispatchOpenSendEmaillModal: () => dispatch(openModal('sendEmail')),
  dispatchCloseSendEmaillModal: () => dispatch(closeModal('sendEmail')),
  dispatchSendEmail: data => dispatch(sendEmail(data)),

  dispatchCotizar: () => dispatch(cotizar()),
  dispatchEditarCotizacion: () => dispatch(editarCotizacion()),
  dispatchCreateSell: payments => dispatch(makeSell(payments)),
  dispatchChangeSalesOrderStatus: orderStatus => dispatch(changeSalesOrderStatus(orderStatus)),
  /* Checkout Dispatches */
  dispatchSetOrderCustomer: account_id => dispatch(setOrderCustomer(account_id)),
  dispatchOpenCheckOutModal: () => dispatch(openModal('checkout')),
  dispatchCloseCheckOutModal: () => dispatch(closeModal('checkout')),

  /* Download Documents */
  dispatchGetOrderDocument: data => dispatch(getOrderPdf(data)),

  /* Description */
  dispatchOpenDescriptionModal: () => dispatch(openModal('description')),
  dispatchCloseDescriptionModal: () => dispatch(closeModal('description')),
  dispatchSetDesc: description => dispatch(setDesc(description)),

  /* Comments */
  dispatchSetComment: product => dispatch(setComment(product)),

  /* Tickets */
  dispatchOpenTicketsModal: () => dispatch(openModal('tickets')),

  /* Discount */
  dispatchOpenDiscountModal: () => dispatch(openModal('discount')),
  dispatchSetSingleDiscount: (row, percentage) => dispatch(setSingleDiscount(row, percentage)),

  /* Timbrar */
  dispatchOpenTimbrarModal: () => dispatch(openModal('timbrar')),
  dispatchHandleMakeBill: mode => dispatch(handleMakeBill(mode)),
  /* Files */
  dispatchOpenFileModal: () => dispatch(openModal('file')),

  /* Disable promotions */
  dispatchDisablePromotions: () => dispatch(desactivePromotions()),
});

// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withRouter(CartList)));
