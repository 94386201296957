/* eslint-disable array-callback-return */
/* eslint-disable react/button-has-type */
/* eslint-disable react/no-danger */
import React, { PureComponent, Fragment } from 'react';
import './SaleEmailShare.scss';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import {
  TextField,
  IconButton,
  Chip,
  CircularProgress,
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import AddIcon from '@material-ui/icons/Add';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import DoneIcon from '@material-ui/icons/Done';

class SaleEmailShare extends PureComponent {
  static propTypes = {
    onSave: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
  }

  constructor(props) {
    super(props);
    this.state = {
      emails: [],
      email: '',
      subject: '',
      body: '',
      emailError: false,
      editBody: false,
    };
  }

  componentWillReceiveProps(next) {
    console.log('next', next);
    const {
      customer: { selectedCustomer },
      orderNo,
      razonSocial,
      // eslint-disable-next-line no-unused-vars
      orderDate,
      emailBody,
    } = next;
    const subject = (`${next.subject} de ${razonSocial || ''} para ${orderNo}`);
    const body = emailBody;
    /* const body = (`Te hacemos llegar: ${next.subject} de ${razonSocial || ''} - ${orderNo} con fecha de creación: ${orderDate}`); */
    const mail = selectedCustomer.email1;
    const newEmails = [mail];
    this.setState({ emails: newEmails, subject, body });
  }

  isEmail = (email) => {
    const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return regex.test(String(email).toLocaleLowerCase());
  }

  addEmail = () => {
    const { emails, email } = this.state;
    if (this.isEmail(email)) {
      const newEmails = [...emails, email];
      this.setState({ emails: newEmails, email: '' });
    } else {
      this.setState({ emailError: true });
    }
  }

  addEmailOnEnter = (event) => {
    const { keyCode } = event;
    if (keyCode === 13) {
      this.addEmail();
    }
  }

  changeStateText = id => (event) => {
    const { target } = event;
    this.setState({ [id]: target.value, emailError: false });
  }

  removeEmail = (index) => {
    const { emails } = this.state;
    const newEmails = [...emails];
    newEmails.splice(index, 1);
    this.setState({ emails: newEmails });
  }

  renderForm = () => {
    const {
      onClose,
      onSave,
      isLoading,
    } = this.props;
    const {
      emailError,
      emails,
      email,
      subject,
      body,
      editBody,
    } = this.state;
    return (
      <div className="w-100 h-auto d-flex justify-content-between align-items-center flex-column">
        <AppBar style={{
          backgroundColor: '#f6f6f7', height: 'auto', borderBottom: 'none', position: 'relative',
        }}
        >
          <Toolbar className="d-flex justify-content-between" classes={{ root: 'toolbar' }}>
            <Typography variant="h6" style={{ color: '#64707a', fontSize: '20px' }}>
              Compartir
            </Typography>
            <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
              <FontAwesomeIcon style={{ fontSize: 20 }} color="#64707a" icon={faTimes} />
            </IconButton>
          </Toolbar>
        </AppBar>
        <div className="p-4 w-100" style={{ marginTop: 48 }}>
          <div className="cart-state__email-container">
            <div className="w-100 d-flex justify-content-between align-items-center">
              <TextField
                id="standard-email"
                className="w-100"
                placeholder="ejemplo@dominio.comz"
                label="E-mail"
                type="email"
                value={email}
                onChange={this.changeStateText('email')}
                onKeyUp={this.addEmailOnEnter}
                error={emailError}
              />
              <IconButton
                aria-label="Add"
                onClick={this.addEmail}
              >
                <AddIcon fontSize="small" />
              </IconButton>
            </div>
            <div className="my-3 w-100">
              <h4>Para:</h4>
              <div
                className="cart-state__emails-container"
              >
                {
                   // eslint-disable-next-line consistent-return
                   emails.map((_email, index) => {
                     if (this.isEmail(_email)) {
                       return (
                         <Chip
                           key={`key_${_email}`}
                           label={_email}
                           onDelete={() => this.removeEmail(index)}
                         />
                       );
                     }
                   })
                }
              </div>
            </div>
          </div>
          <div className="w-100 mt-3 mb-3">
            <TextField
              id="standard-subject"
              placeholder="Asunto"
              label="Asunto"
              className="w-100"
              value={subject}
              onChange={this.changeStateText('subject')}
            />
          </div>
          <div className="mt-3 mb-3">
            {
              editBody
                ? (
                  <>
                    <Button
                      style={{ float: 'right' }}
                      size="small"
                      onClick={() => this.setState({ editBody: false })}
                    >
                      <DoneIcon fontSize="small" />
                      Listo
                    </Button>
                    <TextField
                      id="standard-subject"
                      placeholder="Cuerpo"
                      label="Cuerpo"
                      className="w-100"
                      value={body}
                      onChange={this.changeStateText('body')}
                      multiline
                      rows={6}
                      autoFocus
                    />
                  </>
                )
                : (
                  <>
                    <div className="d-flex justify-content-between align-items-center">
                      <span>
                        Cuerpo
                      </span>
                      {
                        body === ''
                          ? (
                            <Button
                              style={{ float: 'right' }}
                              size="small"
                              onClick={() => this.setState({ editBody: true })}
                            >
                              <EditIcon fontSize="small" />
                              Editar
                            </Button>
                          )
                          : null
                      }
                    </div>
                    <br />
                    <Fragment>
                      <div
                        style={{
                          position: 'static',
                          margin: '0 20px',
                          overflow: 'auto',
                          padding: '0 20px',
                        }}
                        dangerouslySetInnerHTML={{ __html: body }}
                      />
                    </Fragment>
                  </>
                )
            }

          </div>
        </div>
        <div>
          {isLoading
            ? <CircularProgress />
            : (
              <Button
                className=" btn-details mb-2"
                onClick={async () => {
                  const { ...data } = this.state;
                  await onSave(data);
                  onClose();
                }}
              >
                Enviar
              </Button>
            )
          }

        </div>
      </div>
    );
  };

  render() {
    const {
      isOpen,
      onClose,
    } = this.props;
    return (
      <Dialog open={isOpen} onClose={onClose} backdrop="static" fullScreen>
        {this.renderForm()}
      </Dialog>
    );
  }
}

export default SaleEmailShare;
