/* eslint-disable no-prototype-builtins */
/* eslint-disable import/no-cycle */
import React from 'react';
import SwipeableViews from 'react-swipeable-views';
import { useDispatch, useSelector } from 'react-redux';
import {
  faFileSignature,
  faDolly,
  faFolderOpen,
  faHandHoldingUsd,
  faMapMarkerAlt,
  faPenSquare,
  faTruck,
  faComment,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Paper } from '@material-ui/core';
import SwipeOrderOptions from './components/swipe-options';
import OrderDetailAppbar from './components/order-detail-app-bar';
import File from '../file/File';
import Invoice from '../invoice/Invoice';
import ShippingGuideList from '../../ShippingGuide/ShippingGuideList';
import Fde from '../fde/Fde';
import Address from '../address/Address';
import Mda from '../mda/Mda';
import { openDetailTab } from '../../../redux/actions/cartActions';
import OrderFields from '../../OrderCustomFields/OrderFields';
import appModules from '../../../constant/appModules';
import CommentsContainer from '../../../views/ClientPage/components/CommentsContainer/CommentsContainer';

const CartOrderDetail = ({ closeCart }) => {
  const dispatch = useDispatch();
  const tabs = useSelector(state => state.tabs);
  const cart = useSelector(state => state.cart);
  const { orderSelected, detailTabActive } = cart;
  const customer = useSelector(state => state.customer);
  const { selectedCustomer } = customer;
  const isQuote = Boolean(orderSelected.order && orderSelected.order.hasOwnProperty('quote_no')) || false;

  const handleChangeIndex = () => {};
  const handleChange = (event, tab) => {
    // setActiveTab(tab);
    dispatch(openDetailTab(tab));
  };

  const optionList = isQuote ? [
    {
      icon: faFolderOpen,
      text: 'Archivos',
      indicatorTab: 0,
    },
  ] : [
    {
      icon: faFolderOpen,
      text: 'Archivos',
      indicatorTab: 0,
    },
    {
      icon: faMapMarkerAlt,
      text: 'Direcciones',
      indicatorTab: 1,
    },
    {
      icon: faFileSignature,
      text: 'Facturas',
      indicatorTab: 2,
    },
    {
      icon: faHandHoldingUsd,
      text: 'Flujo Efectivo',
      indicatorTab: 3,
    },
    {
      icon: faDolly,
      text: 'Mov. almacen',
      indicatorTab: 4,
    },
    {
      icon: faTruck,
      text: 'Guías',
      indicatorTab: 5,
    },
    {
      icon: faPenSquare,
      text: 'Camp. personalizados',
      indicatorTab: 6,
    },
    {
      icon: faComment,
      text: 'Comentarios',
      indicatorTab: 7,
    },
  ];

  return (
    <div>
      <OrderDetailAppbar closeCart={closeCart} />
      {detailTabActive !== -1 && (
        <div>
          <SwipeOrderOptions
            activeTab={detailTabActive}
            handleChange={handleChange}
            load={false}
            onSwipeAction={() => {}}
          />
          <SwipeableViews
            index={detailTabActive}
            className="d-flex flex-column f-1 no-scroll"
            onChangeIndex={handleChangeIndex}
          >
            <div className="tab-1 mx-2 my-2" style={{ height: 'calc(100vh - 248px)' }}>
              {detailTabActive === 0 && <File orderId={orderSelected.crmid} />}
            </div>
            <div className="tab-2 mx-2 my-2" style={{ display: isQuote ? 'none' : 'flex', height: 'calc(100vh - 145px)' }}>
              {detailTabActive === 1 && (
              <Address
                clientId={selectedCustomer.crmid}
                orderId={orderSelected.crmid}
              />
              )}
            </div>
            <div className="tab-3 mx-2 my-2" style={{ display: isQuote ? 'none' : 'flex' }}>
              {detailTabActive === 2 && (
              <Invoice orderId={orderSelected.crmid} />
              )}
            </div>
            <div className="tab-3 mx-2 my-2" style={{ display: isQuote ? 'none' : 'flex' }}>
              {detailTabActive === 3 && <Fde orderId={orderSelected?.crmid} />}
            </div>
            <div className="tab-4 mx-2 my-2" style={{ display: isQuote ? 'none' : 'flex' }}>
              {detailTabActive === 4 && <Mda orderId={orderSelected.crmid} />}
            </div>
            <div className="tab-5 mx-2 my-2" style={{ display: isQuote ? 'none' : 'flex', height: 'calc(100vh - 248px)' }}>
              {detailTabActive === 5 && (
              <ShippingGuideList orderId={orderSelected?.crmid} />
              )}
            </div>
            <div className="tab-6 mx-2 my-2" style={{ display: isQuote ? 'none' : 'flex' }}>
              {detailTabActive === 6 && (
              <OrderFields
                open
                handleCloseDialog={() => {}}
                orderId={orderSelected.crmid}
                module={
                          tabs.posTypeTab === 'Historico'
                            ? appModules.SalesOrder
                            : appModules.Quotes
                        }
              />
              )}
            </div>
            <div className="tab-6 mx-2 my-2" style={{ height: 'calc(100vh - 210px)', display: isQuote ? 'none' : 'flex' }}>
              {detailTabActive === 7 && (
              <CommentsContainer
                relatedTo={orderSelected.crmid}
                module="ModComments"
                limitHeight={false}
              />
              )}
            </div>
          </SwipeableViews>
        </div>
      )}
      {detailTabActive === -1 && (
        <div
          style={{
            display: 'grid',
            placeItems: 'center',
          }}
        >
          <div
            style={{
              padding: 20,
              borderRadius: 5,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexWrap: 'wrap',
              gap: 15,
            }}
          >
            {optionList.map(option => (
              <Paper
                key={option.indicatorTab}
                onClick={() => dispatch(openDetailTab(option.indicatorTab))}
              >
                <div
                  style={{
                    cursor: 'pointer',
                    height: 95,
                    width: 170,
                    padding: 20,
                    borderRadius: 5,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                  }}
                >
                  <FontAwesomeIcon
                    style={{ fontSize: 35 }}
                    color="#313C52"
                    icon={option.icon}
                  />
                  <span>{option.text}</span>
                </div>
              </Paper>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default CartOrderDetail;
