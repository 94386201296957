/* eslint-disable camelcase */
/* eslint-disable import/no-cycle */
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
/* Component */
import EditCustomer from '../../../Customer/components/EditCustomer';
/* Actions */
import {
  addAddresForCustomer,
  deleteAddressByCustomer,
  editAddresForCustomer,
  editCustomer,
  editEnvioFactura,
  fetchAddressByCustomer,
} from '../../../../redux/actions/customerActions';

const mapStateToProps = ({
  cart,
  loads,
  modals,
  customer,
}) => {
  const { selectedCustomer, address } = customer;
  const title = `Editar al cliente: ${selectedCustomer.accountname}`;

  return ({
    address,
    title,
    cart,
    loads,
    modals,
    selectedCustomer,
  });
};

const mapDispatchToProps = dispatch => ({
  deleteAddress: (customerId, crmid, label) => dispatch(deleteAddressByCustomer(customerId, crmid, label)),
  dispatchAddAddressByCustomer: (id, address) => dispatch(addAddresForCustomer(id, address)),
  dispatchEditAddressByCustomer: (id, address) => dispatch(editAddresForCustomer(id, address)),
  dispatchEditCustomer: customer => dispatch(editCustomer(customer)),
  dispatchEditEnvioFactura: customer => dispatch(editEnvioFactura(customer)),
  dispatchGetAddressCustomer: id => dispatch(fetchAddressByCustomer(id)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EditCustomer));
