/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import './activity.style.scss';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import { Popover } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboardCheck, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import moment from 'moment';
import { Collapse } from 'reactstrap';
import Flag from '../../../../components/Flag';
import Iconwidthbg from '../../../../components/Icons/IconWidthBG/IconWidthBG';
import CommentsContainer from '../../../ClientPage/components/CommentsContainer/CommentsContainer';
import {
  getColorTaskStatus,
} from '../../../../helpers/tools';
import TaskApi from '../../../../api/TaskApi';
import { selectTasks } from '../../../../redux/actions/taskActions';
import CountUpStatic from '../../../../components/CountUp/CountUpStatic';

const Activity = () => {
  const dispatch = useDispatch();
  const taskApi = new TaskApi();
  const { id } = useParams();
  const tasks = useSelector(state => state.tasks);
  const { selectedTask, startTimer } = tasks;

  const activityStatus = useSelector(state => state.authUser.user.pos_filter_projecttaskstatus_values);
  const selectedClient = useSelector(state => state.tasks.selectedUser);

  const noImage = `${process.env.PUBLIC_URL}/img/ava.png`;
  const [anchorEl, setAchorEl] = React.useState(null);
  const [projects, setProjects] = React.useState([]);
  const [project, setProject] = React.useState([]);
  const [selectedStatus, setSelectedStatus] = React.useState({});
  const [priority, setPriority] = React.useState(selectedTask.projecttaskpriority);
  const [endDate, setEndDate] = React.useState(selectedTask.enddate);
  const open = Boolean(anchorEl);

  const getStatusByLabel = (statusLabel) => {
    let st = {};
    if (activityStatus.length) {
      st = activityStatus.find(s => s.value.trim() === statusLabel || s.label.trim() === statusLabel);
    }

    return st;
  };

  const getAllProjects = async () => {
    const response = await taskApi.getAllProjects();
    const { success, result } = response;
    if (success && result.length) {
      setProjects(result);
    }
  };


  const updateTask = async (task) => {
    const response = await taskApi.editTask(task);
    const { success } = response;
    if (success) {
      toast.info('Tarea actualizada');
    } else {
      toast.info('No se pudo editar la tarea');
    }
  };

  const updateTaskStatus = async (status) => {
    if (Object.keys(selectedTask).length && selectedTask.projecttaskstatus !== status) {
      const editedTask = { ...selectedTask };
      editedTask.projecttaskpriority = priority;
      editedTask.projecttaskstatus = status.value;
      if (endDate) {
        editedTask.enddate = endDate;
      }
      await updateTask(editedTask);
    }
  };

  const updateTaskPriority = async (status) => {
    if (Object.keys(selectedTask).length && selectedTask.projecttaskpriority !== status) {
      const editedTask = { ...selectedTask };
      editedTask.projecttaskpriority = status;
      editedTask.projecttaskstatus = selectedStatus.value;
      editedTask.enddate = endDate;
      await updateTask(editedTask);
    }
  };

  const setActivityStatus = (status) => {
    setSelectedStatus(status);
  };

  const changeTaskPriority = async (event) => {
    event.persist();
    await setPriority(event.target.value);
    await updateTaskPriority(event.target.value);
  };

  const changeTaskDate = async (event) => {
    event.persist();
    const isBeforeToday = moment(event.target.value).isBefore(moment().toDate());
    if (isBeforeToday) {
      toast.error('La fecha debe ser posterior a la actual');
      return;
    }
    const endDateChange = event.target.value;
    setEndDate(endDateChange);
    let editedTask = null;
    if (Object.keys(selectedTask).length) {
      editedTask = { ...selectedTask };
      editedTask.projecttaskpriority = priority;
      editedTask.projecttaskstatus = selectedStatus.value;
      editedTask.enddate = endDateChange;
      await updateTask(editedTask);
    }
  };

  const getSelectedTaskFromApi = async (taskId) => {
    const response = await taskApi.getObjectById(taskId);
    const { success, result } = response;
    if (success) {
      dispatch(selectTasks(result));
      setActivityStatus(getStatusByLabel(result.projecttaskstatus));
      setPriority(result.projecttaskpriority);
      setEndDate(result.enddate);
    }
  };

  const loadTaskData = () => {
    // Load task by url id
    if (!Object.keys(selectedTask).length && id) {
      getSelectedTaskFromApi(id);
    } else {
      setActivityStatus(getStatusByLabel(selectedTask.projecttaskstatus));
    }

    // Load projects
    getAllProjects();
  };

  React.useEffect(() => {
    loadTaskData();
  }, []);

  React.useEffect(() => {
    getSelectedTaskFromApi(id);
  }, [id]);

  return (
    <>
      <Collapse isOpen={!startTimer}>
        <div className="d-flex justify-content-center align-items-center w-100 p-2" style={{ minHeight: 60 }}>
          <CountUpStatic hoursMinSecs={{ hours: 0, minutes: 0, seconds: 0 }} />
        </div>
      </Collapse>
      <div className="activity-container p-3">
        <h3 className="header-title mb-3">{selectedTask.projecttaskname}</h3>
        <div className="activity-detail d-flex justify-content-between align-item-center mb-4">
          <div className="d-flex justify-content-start align-item-center w-100 mr-2">
            <Avatar src={noImage} />
            <div className="d-flex flex-column ml-3">
              <span className="sub-title">Asignado a</span>
              <span className="title">{selectedTask.assigned_user}</span>
            </div>
          </div>
          <div
            className="status-container ml-2"
            onClick={e => setAchorEl(e.currentTarget)}
          >
            <Iconwidthbg
              primaryColor={getColorTaskStatus(selectedStatus?.label)}
              secondaryColor="white"
              width={27}
              height={27}
              classes="ml-2"
              enabled
              square
              rounded
              actionClick={() => {}}
            >
              <FontAwesomeIcon style={{ fontSize: 15 }} icon={faClipboardCheck} />
            </Iconwidthbg>
            <span className="title">{selectedStatus?.label}</span>
            <FontAwesomeIcon style={{ fontSize: 15, color: '#313C52' }} icon={faChevronDown} />
          </div>
        </div>

        <div className="activity-detail d-flex justify-content-between align-item-center mb-4">
          <div className="d-flex flex-column custom-input-group mr-2">
            <span className="sub-title">Proyecto</span>
            <select disabled id="proyecto" className="custom-input-group__inside-input">
              <option value="">Buscando ...</option>
              {
                projects.map(pr => (
                  <option selected={selectedTask.projectid === pr.crmid} value={pr.crmid} className="title">{pr.projectname}</option>
                ))
              }
            </select>
          </div>
          <div className="d-flex flex-column custom-input-group ml-2">
            <span className="sub-title">Fecha fin</span>
            <input className="title custom-input-group__inside-input w-100" type="date" value={endDate} onChange={e => changeTaskDate(e)} />
          </div>
        </div>

        <div className="activity-detail d-flex justify-content-between align-item-center mb-4">
          <div className="d-flex flex-column custom-input-group mr-2">
            <span className="sub-title">Prioridad</span>
            <select id="proyecto" className="custom-input-group__inside-input" onChange={e => changeTaskPriority(e)}>
              <option value="" className="title">Seleccionar</option>
              <option selected={priority === 'Alto'} value="Alto" className="title">Alta</option>
              <option selected={priority === 'Medio'} value="Medio" className="title">Media</option>
              <option selected={priority === 'Bajo'} value="Bajo" className="title">Baja</option>
            </select>
          </div>
          <div className="d-flex flex-column justify-content-center w-100 ml-2">
            <span className="sub-title">Horas acumuladas</span>
            <span className="title">{selectedTask.projecttaskhours}</span>
          </div>
        </div>

        <div className="mb-3">
          <p className="sub-title">Descripción</p>
          <p className="title text-justify" dangerouslySetInnerHTML={{ __html: `${selectedTask.description}` }} />
        </div>
      </div>

      <CommentsContainer module="ModComments" relatedTo={id} />

      {/* Popover */}
      <Popover
        id="status-popover"
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAchorEl(null)}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        className="detailInfo_customer_paper"
      >
        <div
          className="detailInfo_customer_popover detailInfo_customer_popoend flex-wrap"
        >
          {
          activityStatus.map(stat => (
            <span key={`key_stat_${stat}`}>
              <Flag
                size="1x"
                icon="clipboard-check"
                ariaOwns={open ? 'status-popover' : undefined}
                color="white"
                backgroundColor={getColorTaskStatus(stat.label)}
                onClick={() => {
                  setActivityStatus(stat);
                  updateTaskStatus(stat);
                  setAchorEl(null);
                }}
              />
              <span>
                {stat.label === 'En progreso' ? 'Progreso' : stat.label}
              </span>
            </span>
          ))
          }
        </div>
      </Popover>
    </>
  );
};

export default Activity;
