import React from 'react';
import PropTypes from 'prop-types';
import {
  Tooltip,
  IconButton,
} from '@material-ui/core';
import SatLogo from './SatLogo';

const TicketFacturaAction = ({
  disableTooltip,
  enable,
  open,
  tooltip,
}) => (
  <Tooltip
    title={enable ? tooltip : disableTooltip}
    placement="bottom"
  >
    <span>
      <IconButton
        disabled={!enable}
        onClick={open}
        className="pos_menu_setDir"
      >
        <SatLogo fill={enable ? '#303c52' : '#c7c7c7'} />
        <span className="font-size-10">Timbrar</span>
      </IconButton>
    </span>
  </Tooltip>
);

TicketFacturaAction.propTypes = {
  disableTooltip: PropTypes.string,
  enable: PropTypes.bool.isRequired,
  open: PropTypes.func.isRequired,
  tooltip: PropTypes.string,
};
TicketFacturaAction.defaultProps = {
  tooltip: 'Imprimir Ticket Factura',
  disableTooltip: 'Debe haber al menos un CFDI asociado a la orden de venta',
};

export default TicketFacturaAction;
