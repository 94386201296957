/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
// eslint-disable-next-line import/no-cycle
import { authenticate } from '../../redux/actions/authUserActions';

const AutoLogin = ({ match, history }) => {
  const dispatch = useDispatch();
  const credentials = match.params.token;

  const getDecodeData = async (token) => {
    const a = 3;
    const b = -4;
    const c = 3;
    const d = -4;
    const e = 0;
    const f = 4;
    const g = 7;

    const passcom1Str = window.atob(token);
    const passcom1 = passcom1Str.substring(a, (passcom1Str.length + b));
    const passcom2Str = window.atob(passcom1);
    const passcom2 = passcom2Str.substring(c, (passcom2Str.length + d));

    const passcomInit = passcom2.substring(e, f);
    const passcomFin = passcom2.substring(g);

    const data = window.atob(passcomInit.concat(passcomFin));
    const decodeToken = data.split(':');
    const credential = btoa(`${decodeToken[0]}:${decodeToken[1]}`);

    const response = await fetch(
      `https://comercia.io/ingreso/index.php?funcion=dameSite&cuenta=${decodeToken[2]}`,
    );
    const { ok, status } = response;

    if (!ok || status !== 200) {
      toast.error(`No se encontro el dominio: ${decodeToken[2]}`);
      history.push('/account/login');
      return;
    }
    const realDomain = await response.text();

    dispatch(authenticate(credential, realDomain, false));
    history.push('/account/login');
  };

  useEffect(() => {
    getDecodeData(credentials);
  }, []);

  return (
    null
  );
};

export default AutoLogin;
